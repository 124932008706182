import Proptypes from 'prop-types';
// import moment from 'moment';
import numeral from 'numeral';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Link,
  IconButton
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Eye as EyeIcon } from '../../../icons/eye';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'cardname',
    label: 'TRANSPORTISTA'
  },
  {
    id: 'codzon',
    label: 'ZONA'
  },
  {
    id: 'totaldoc',
    label: '#DOCS'
  },
  {
    id: 'montototal',
    label: 'TOTAL'
  },
  {
    id: 'sname',
    label: 'ESTADO'
  }
];

export const DeliveryCreateGrTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const { appUrlBase } = useApp();

  const detailRoute = `${appUrlBase}/delivery/create-gr/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1, marginLeft: 3 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow hover key={document.codusu}>
                <TableCell>
                  #{document.id}
                  <IconButton>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${detailRoute}${document.id}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      <EyeIcon />
                    </Link>
                  </IconButton>
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.cardname}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.codzon}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.totaldoc).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.docentry).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.tdoyStatusName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

DeliveryCreateGrTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

import { useApp } from '../../hooks/use-app';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GoalMenu } from '../../components/common/goal-menu';
import { GoalMenuItem } from '../../components/common/goal-menu-item';
import BusinessIcon from '@mui/icons-material/Business';
import InventoryIcon from '@mui/icons-material/Inventory';

export default function ShortcutPopover() {
  const { appUrlBase } = useApp();

  return (
    <GoalMenu startIcon={<AddCircleIcon color="primary" fontSize="large" />} iconOnly={true}>
      <GoalMenuItem
        label="Socio de negocio"
        to={`${appUrlBase}/busines-partners/new`}
        startIcon={<BusinessIcon />}
      />
      <GoalMenuItem
        label="Producto"
        to={`${appUrlBase}/inventory/products/new`}
        startIcon={<InventoryIcon />}
      />
    </GoalMenu>
  );
}

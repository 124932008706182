import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Status } from '../../status';
import { useApp } from '../../../hooks/use-app';
import { Eye as EyeIcon } from '../../../icons/eye';

const columns = [
  {
    id: '_id',
    label: '_ID'
  },
  {
    id: 'ObjectName',
    label: 'Nombre del objeto'
  },
  {
    id: 'ObjectCode',
    label: 'Código de objeto'
  },
  {
    id: 'processed',
    label: 'Procesada'
  },
  {
    id: 'prepared',
    label: 'Preparada'
  },
  {
    id: 'created_at',
    label: 'Creada en'
  },
  {
    id: 'updated_at',
    label: 'Actualizada en'
  }
];

const statusColumn = [
  { color: 'success.main', label: 'Si', value: '1' },
  { color: 'error.main', label: 'No', value: '0' }
];

export const BulksTable = (props) => {
  const {
    error,
    bulks = [],
    bulksCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !bulks?.length);

  const { appUrlBase } = useApp();

  const detailRoute = `${appUrlBase}/automations/bulks/`;

  return (
    <Card variant="outlined">
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <Scrollbar>
          <Table sx={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={`bulk-column-${column.id}`}>
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bulks.map((bulk) => {
                const statusProc = statusColumn.find((status) => status.value == bulk.processed);
                const statusPrep = statusColumn.find((status) => status.value == bulk.prepared);
                return (
                  <TableRow hover key={`bulk-item-${bulk._id}`}>
                    <TableCell
                      sx={{
                        minWidth: 150
                      }}
                    >
                      <Typography color="textSecondary" variant="body2">
                        ...{bulk._id.slice(-8)}
                        <Tooltip title="Ver detalle">
                          <IconButton>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`${detailRoute}${bulk._id}`}
                              underline="none"
                              variant="subtitle2"
                            >
                              <EyeIcon />
                            </Link>
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    <TableCell>{bulk.ObjectName}</TableCell>
                    <TableCell>{bulk.ObjectCode}</TableCell>
                    <TableCell>
                      <Status color={statusProc?.color} label={statusProc?.label} />
                    </TableCell>
                    <TableCell>
                      <Status color={statusPrep?.color} label={statusPrep?.label} />
                    </TableCell>
                    <TableCell>{bulk.created_at}</TableCell>
                    <TableCell>{bulk.updated_at}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
        {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {displayUnavailable && (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={isLoading}
          onPageChange={onPageChange}
          page={page}
          rowsCount={bulksCount}
        />
      </Box>
    </Card>
  );
};

BulksTable.propTypes = {
  bulks: Proptypes.array,
  bulksCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

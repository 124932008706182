import Proptypes from 'prop-types';
import { useEffect, useState } from 'react';
import numeral from 'numeral';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { YesNo } from '../../yes-no';
import { CustomerModal } from './customer-modal';
import GadminActions from '../../../contexts/gadmin-actions';
import { RenderIf } from '../../render-if';
import UnassignForm from './form/unassign-form';
import { DocumentLineDetailDialog } from '../../purchases/common/document-line-detail-dialog';
import { useDialog } from '../../../hooks/use-dialog';
import { useAuth } from '../../../hooks/use-auth';
import { AssignForm } from './form/assign-form';
import { AllowedGuard } from '../../../hooks/use-allowed';

const columns = [
  {
    id: 'positionName',
    label: 'Puesto'
  },
  {
    id: 'typeName',
    label: 'Tipo'
  },
  {
    id: 'documentNumber',
    label: 'DNI'
  },
  {
    id: 'cardName',
    label: 'Empleado'
  },
  {
    id: 'confirmed_by',
    label: 'Confirmado por'
  },
  {
    id: 'authorized_by',
    label: 'Autorizado por'
  },
  {
    id: 'additional',
    label: 'Adicional'
  },
  {
    id: 'value',
    label: 'Value'
  },
  {
    id: 'actions',
    label: ''
  }
];

export const CustomerDeliveryTeamsTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate',
    handleRefresh
  } = props;
  const [open, onClose] = useState(false);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const permission2 = GadminActions.gCC_TDOY_TEAM_UNASSIGN;

  const initialReasons = {
    reasonId: ''
  };

  const currentLine = {};
  const [editMode, setEditMode] = useState(false);
  const { hasPermission } = useAuth();
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const handleExitedDialog = () => {};

  useEffect(() => {
    setEditMode(!!currentLine && hasPermission(GadminActions.gCC_TDOY_TEAM_ASSIGN));
  }, [hasPermission, currentLine]);

  const [itemData, setitemEdit] = useState(null);
  const handleClickAssig = (id) => {
    handleOpenEditDialog();
    const item = documents.filter((obj) => obj.id === id);
    setitemEdit(item[0]);
  };

  const handleClickUnassig = (id) => {
    onClose(!open);
    const item = documents.filter((obj) => obj.id === id);
    setitemEdit(item[0]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow
                hover
                key={document.id}
                selected={
                  !!selectedDocuments.find(
                    (selectedCustomer) => selectedCustomer === document.docEntry
                  )
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find(
                        (selectedCustomer) => selectedCustomer === document.id
                      )
                    }
                    onChange={(event) => onSelect(event, document.id)}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.positionName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.typeName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.documentNumber}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.cardCode}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {document.cardName}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 180 }}>
                  <YesNo value={document.confirmed} />
                  {document.confirmed_by && (
                    <Typography color="textSecondary" variant="body2">
                      {document.confirmed_by}
                    </Typography>
                  )}
                  {document.confirmed_at && (
                    <Typography color="textSecondary" variant="body2">
                      {document.confirmed_at}
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 180 }}>
                  {document.authorized_by ? (
                    <Typography color="textSecondary" variant="body2">
                      {document.authorized_by}
                    </Typography>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                  {document.authorized_at ? (
                    <Typography color="textSecondary" variant="body2">
                      {document.authorized_at}
                    </Typography>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <YesNo value={document.additional} />
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.value).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <RenderIf condition={document.canEdit === 'Y'}>
                  <TableCell>
                    {document.cardName === null && document.cardCode === '' ? (
                      <AllowedGuard permission={GadminActions.gCC_TDOY_TEAM_ASSIGN}>
                        <Button onClick={() => handleClickAssig(document.id)}>Asignar</Button>
                      </AllowedGuard>
                    ) : (
                      <Button onClick={() => handleClickUnassig(document.id)}>Designar</Button>
                    )}
                  </TableCell>
                </RenderIf>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
      <CustomerModal open={open} onClose={onClose} permission={permission2}>
        <UnassignForm
          dataModal={itemData}
          title="Designar"
          initialValues={initialReasons}
          onClose={onClose}
          handleRefresh={handleRefresh}
        />
      </CustomerModal>
      <DocumentLineDetailDialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        onExited={handleExitedDialog}
        currentLine={itemData}
        onAfterSave={handleRefresh}
        Component={AssignForm}
        title="Asignar"
        editable={editMode}
      />
    </Box>
  );
};

CustomerDeliveryTeamsTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  handleRefresh: Proptypes.func
};

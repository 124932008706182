import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// import { format } from 'date-fns';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { DateField } from '../../date-field';
import { receiptFeeApi as invoiceApi } from '../../../api/receipt-fee';
import {
  warehouseList,
  // documnetTypeList,
  documnetStatus,
  documentTypeConstants,
  purchaseView
} from '../../../api/purchase-definitions';
import { RenderIf } from '../../render-if';
import { AutocompleteField } from '../../autocomplete-field';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { purchaseHelperApi } from '../../../api/purchase-helper';
import { appFormats } from '../../../api/app-definitions';
import { useFormSaver } from '../../../hooks/use-form-saver';
import { useFormChecker } from '../../../hooks/use-form-checker';

// import useItemChanges from '../../../hooks/use-item-change';

export const ReceiptFeesEdit = (props) => {
  const { backendDateFormat, frontDateFormat } = appFormats;
  const { invoice, setEditMode, onAfterSave } = props;

  const mounted = useMounted();
  const { currencySymbol } = invoice;
  const [editPaymentTerm, setEditPaymentTerm] = useState(true);
  const [editWarehouse, setEditWarehouse] = useState(true);
  // const [editDocumentType, setEditDocumentType] = useState(true);
  const [isService, setIsService] = useState(false);
  const [editTaxDate, setEditTaxDate] = useState(true);
  const [isNote, setIsNote] = useState(false);
  const [editAccount, setEditAccount] = useState(true);

  const handleAfterSave = () => {
    onAfterSave?.();
    handleClose();
  };
  const handleClose = () => {
    setEditMode(false);
  };
  // const [document, setDocument] = useState(invoice);
  // const mounted = useMounted();

  useEffect(() => {
    const draftState = invoice.status === documnetStatus.Borrador;
    setEditWarehouse(draftState && invoice.docType === documentTypeConstants.Item);
    // setEditDocumentType(draftState);
    setEditPaymentTerm(draftState);
    setEditTaxDate(draftState);
    setEditAccount(draftState);
    setIsNote(true);
    setIsService(invoice.docType === documentTypeConstants.Service);
  }, [invoice]);

  const [ocrCodeList, ocrCodeListRefresh] = useData({
    sourceApi: purchaseHelperApi.getServiceCostingCode,
    apiParameter: { view: purchaseView.PURCHASE_FEES },
    loadingMessage: 'Cargando centros de costo',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  const [accountCodeList, accountCodeListRefresh] = useData({
    sourceApi: purchaseHelperApi.getServiceAccounts,
    apiParameter: { view: purchaseView.PURCHASE_FEES },
    loadingMessage: 'Cargando cuentas contables',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  const [sedeConfigList, sedeConfigListRefresh] = useData({
    sourceApi: purchaseHelperApi.getSedesConfig,
    apiParameter: { view: purchaseView.PURCHASE_FEES },
    loadingMessage: 'Cargando configuración de sedes',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  const [paymentTermList, paymentTermListRefresh] = useData({
    sourceApi: purchaseHelperApi.getServicePaymentTerm,
    apiParameter: { view: purchaseView.PURCHASE_FEES },
    loadingMessage: 'Cargando términos de pago',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    ocrCodeListRefresh();
    accountCodeListRefresh();
    sedeConfigListRefresh();
    paymentTermListRefresh();
  }, []);

  const [handleUpdate] = useFormSaver({
    originalData: invoice,
    apiUpdateHandler: invoiceApi.updateInvoice,
    id: 'docEntry',
    handleAfterSave
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupNum: invoice?.groupNum || -1,
      whsCode: invoice?.whsCode || '',
      docType: invoice?.docType || '',
      comments: invoice?.comments || '',
      // docDate: new Date(invoice?.docDate || invoice?.taxDate) || null,
      // docDate: moment(invoice?.docDate || invoice?.taxDate).toDate() || null,
      docDate: invoice?.docDate || invoice?.taxDate || null,
      account: invoice?.account || '',
      reason: invoice?.reason || null,
      ocrCode: invoice?.ocrCode || '',
      series: invoice?.series || -1,
      ownerUser: invoice?.ownerUser || '',
      seriesConfig: invoice?.seriesConfig || -1,
      cashAccount: invoice?.cashAccount || '',
      processPayment: invoice?.processPayment || 'N',
      submit: null
    },
    validationSchema: Yup.object().shape({
      groupNum: Yup.string().max(255).required('Término de pago es requerido'),
      docDate: Yup.date().required('Término de pago es requerido'),
      comments: Yup.string().min(10).max(255).required('Comentario es requerido'),
      whsCode: Yup.string().when('docType', {
        is: documentTypeConstants.item,
        then: Yup.string().required('Almacén es requerido')
      }),
      docType: Yup.string().oneOf(['I', 'S']).required('Tipo de documento es requerido'),
      account: Yup.string()
        .max(20)
        .when('docType', {
          is: documentTypeConstants.service,
          then: Yup.string().required('Cuenta es requerida')
        }),
      reason: Yup.string().when('docType', {
        is: documentTypeConstants.service,
        then: Yup.string().nullable().required('Concepto es requerido')
      }),
      ocrCode: Yup.string().when('docType', {
        is: documentTypeConstants.item,
        then: Yup.string().required('Almacén es requerido')
      })
    }),
    onSubmit: async (values, helpers) => {
      await handleUpdate({ values, helpers });
    }
  });
  const [checkForm] = useFormChecker({ formik, setSaving: formik.setSubmitting });
  const whsField = (
    <RenderIf
      condition={editWarehouse}
      no={<PropertyListItem label="Almacén" value={`${invoice.whsCode} - ${invoice.whsName}`} />}
    >
      <PropertyListItem label="Almacén">
        <InputField
          error={Boolean(formik.touched.whsCode && formik.errors.whsCode)}
          helperText={formik.touched.whsCode && formik.errors.whsCode}
          name="whsCode"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          select
          value={formik.values.whsCode}
          disabled
        >
          {warehouseList.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </InputField>
      </PropertyListItem>
    </RenderIf>
  );
  const paymentField = (
    <RenderIf
      condition={editPaymentTerm}
      no={<PropertyListItem label="Término de pago" value={`${invoice.paymentName}`} />}
    >
      <PropertyListItem label="Término de pago" isForm>
        <RenderIf
          condition={!!paymentTermList.data && paymentTermList.data.length > 0}
          no={paymentTermList.message || 'Cargando'}
        >
          <AutocompleteField
            error={Boolean(formik.touched.groupNum && formik.errors.groupNum)}
            helperText={formik.touched.groupNum && formik.errors.groupNum}
            options={paymentTermList.data || [{ label: 'Cargando', id: 'Cargando' }]}
            name="groupNum"
            placeholder="Seleccione un término de pago"
            onBlur={formik.handleBlur}
            onChange={(e, value) => {
              formik.setFieldValue('groupNum', value?.id || '');
            }}
            label="Término de pago"
            fullWidth
            value={
              paymentTermList.data?.find((item) => item.id === formik.values.groupNum) || {
                label: 'Cargando nombre',
                id: formik.values.groupNum
              }
            }
          />
        </RenderIf>
      </PropertyListItem>
    </RenderIf>
  );
  return (
    <Card variant="outlined">
      <CardHeader title="Detalle de comprobante" />
      <Divider />
      <form
        onSubmit={async (values, helpers) => {
          checkForm();
          formik.handleSubmit(values, helpers);
        }}
      >
        <Grid container>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Proveedor"
                value={`${invoice.cardCode} - ${invoice.cardName}`}
              />
              {/* <PropertyListItem label="Tipo de documento" value={`${invoice.paymentName}`} /> */}

              {paymentField}
              <RenderIf
                condition={editAccount}
                no={
                  <PropertyListItem
                    label="Cuenta contable"
                    value={`${invoice.account} ${invoice.acctName}`}
                  />
                }
              >
                <PropertyListItem label="Concepto" isForm>
                  <RenderIf
                    condition={!!accountCodeList.data && accountCodeList.data.length > 0}
                    no={accountCodeList.message || 'Cargando'}
                  >
                    <AutocompleteField
                      error={Boolean(formik.touched.account && formik.errors.account)}
                      helperText={formik.touched.account && formik.errors.account}
                      options={accountCodeList.data || [{ label: 'Cargando', id: 'Cargando' }]}
                      name="account"
                      placeholder="Seleccione una cuenta contable"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('account', value?.id || '');
                      }}
                      label="Cuenta contable"
                      fullWidth
                      value={
                        accountCodeList.data?.find((item) => item.id === formik.values.account) || {
                          label: 'Cargando nombre',
                          id: formik.values.account
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Notes" value={`“${invoice.note}”`} />}
              >
                <PropertyListItem label="Concepto">
                  <InputField
                    error={Boolean(formik.touched.reason && formik.errors.reason)}
                    helperText={formik.touched.reason && formik.errors.reason}
                    name="reason"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.reason}
                    sx={{ minWidth: 300 }}
                  />
                </PropertyListItem>
              </RenderIf>
              {isService ? '' : whsField}
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem label="Numero" value={`#${invoice.document}`} />
              <PropertyListItem
                label="Fecha de emisión"
                value={moment(invoice.taxDate).format(frontDateFormat)}
              />

              {isNote ? '' : paymentField}

              <RenderIf condition={!isNote}>
                <PropertyListItem
                  label="Fecha de Vencimiento"
                  value={moment(invoice.docDueDate).format(frontDateFormat)}
                />
              </RenderIf>
              <RenderIf
                condition={editTaxDate}
                no={
                  <PropertyListItem
                    label="Fecha de Contabilización"
                    value={moment(invoice.docDate).format(frontDateFormat)}
                  />
                }
              >
                <PropertyListItem label="Fecha de Contabilización">
                  <DateField
                    error={Boolean(formik.touched.docDate && formik.errors.docDate)}
                    helperText={formik.touched.docDate && formik.errors.docDate}
                    name="docDate"
                    onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    onChange={(date) => {
                      formik.setFieldValue('docDate', moment(date).format(backendDateFormat));
                    }}
                    // value={formik.values.docDate}
                    value={moment(formik.values.docDate).toDate()}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Notes" value={`“${invoice.note}”`} />}
              >
                <PropertyListItem label="Glosa">
                  <InputField
                    error={Boolean(formik.touched.comments && formik.errors.comments)}
                    helperText={formik.touched.comments && formik.errors.comments}
                    name="comments"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.comments}
                    sx={{ minWidth: 300 }}
                  />
                </PropertyListItem>
              </RenderIf>
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Centro de beneficio" value={`#${invoice.ocrName}`} />}
              >
                <PropertyListItem label="Centro de beneficio" isForm>
                  <RenderIf
                    condition={!!ocrCodeList.data && ocrCodeList.data.length > 0}
                    no={ocrCodeList.message || 'Cargando'}
                  >
                    <AutocompleteField
                      error={Boolean(formik.touched.ocrCode && formik.errors.ocrCode)}
                      helperText={formik.touched.ocrCode && formik.errors.ocrCode}
                      options={ocrCodeList.data || [{ label: 'Cargando', id: '' }]}
                      name="ocrCode"
                      placeholder="Seleccione un centro de beneficio"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('ocrCode', value?.id || '');
                      }}
                      label="Centro de costo"
                      fullWidth
                      value={
                        ocrCodeList.data?.find((item) => item.id === formik.values.ocrCode) || {
                          label: 'Cargando nombre',
                          id: formik.values.ocrCode
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Pagar" value={`#${invoice.ocrName}`} />}
              >
                <PropertyListItem label="Pagar" align="right">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.processPayment === 'Y'}
                        onChange={(event) =>
                          formik.setFieldValue('processPayment', event.target.checked ? 'Y' : 'N')
                        }
                        name="processPayment"
                      />
                    }
                    label="Pagar inmediatamente"
                  />
                </PropertyListItem>
              </RenderIf>

              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Sede" value={`#${invoice.acctName}`} />}
              >
                <PropertyListItem label="Sede" isForm>
                  <RenderIf
                    condition={!!sedeConfigList.data && sedeConfigList.data.length > 0}
                    no={sedeConfigList.message || 'Cargando'}
                  >
                    <AutocompleteField
                      error={Boolean(formik.touched.seriesConfig && formik.errors.seriesConfig)}
                      helperText={formik.touched.seriesConfig && formik.errors.seriesConfig}
                      options={sedeConfigList.data || [{ label: 'Cargando', id: 'Cargando' }]}
                      name="seriesConfig"
                      placeholder="Seleccione una sede"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        const sedeConfig = sedeConfigList.data?.find((i) => i.id === value?.id);
                        if (sedeConfig) {
                          formik.setFieldValue('cashAccount', sedeConfig.cashAccount);
                          formik.setFieldValue('series', sedeConfig.series);
                          formik.setFieldValue('branch', sedeConfig.sede);
                        }
                        formik.setFieldValue('seriesConfig', value?.id || '');
                      }}
                      label="Sede"
                      fullWidth
                      value={
                        sedeConfigList.data?.find(
                          (item) => item.id === formik.values.seriesConfig
                        ) || {
                          label: 'Cargando nombre',
                          id: formik.values.seriesConfig
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>

              <PropertyListItem
                label="Exonerado"
                value={numeral(invoice.sumExonerada).format(`${currencySymbol}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Importe"
                value={numeral(invoice.docTotal).format(`${'S/'}0,0.00`)}
                align="right"
              />
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}
            <AllowedGuard permission={GadminActions.G2_PURCHASE_RRHH_UP}>
              <LoadingButton
                color="primary"
                size="large"
                startIcon={<PlusIcon />}
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
              >
                Guardar
              </LoadingButton>
            </AllowedGuard>
            <Button
              color="secondary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={() => {
                setEditMode(false);
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

ReceiptFeesEdit.propTypes = {
  invoice: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func
};

import Keycloak from 'keycloak-js';

const initOptions = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
};
const keycloak = new Keycloak(initOptions);

export default keycloak;

const ConstantAddressType = {
  Shipping: 'S',
  Billing: 'B'
};

const ConstantAddressTypeName = {
  Shipping: 'Dirección de entrega',
  Billing: 'Dirección fiscal'
};

export { ConstantAddressType, ConstantAddressTypeName };

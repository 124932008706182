import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { NumberColor } from '../../number-color';
import { Sumary } from '../../sumary';

export const JournalLinesGroupTable = (props) => {
  const { journalInfo } = props;
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Cuenta</TableCell>
              <TableCell>Referencia</TableCell>
              <TableCell>Débito</TableCell>
              <TableCell>Crédito</TableCell>
              <TableCell>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {journalInfo?.grouped?.map((item, index) => (
              <TableRow key={item.line}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.Code}</TableCell>
                <TableCell>{item.Account}</TableCell>
                <TableCell>
                  <NumberColor value={item.Debit} format="0.00" />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.Credit} format="0.00" />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.Balance} format="0.00" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Sumary values={journalInfo?.grouped?.lenght} column="gTotal" />
      </Scrollbar>
    </div>
  );
};

JournalLinesGroupTable.propTypes = {
  journalInfo: PropTypes.object.isRequired
};

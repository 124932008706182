import { useParams } from 'react-router-dom';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { customerApi } from '../../../api/customer';
import { useSplit } from '../../../hooks/use-split';
import { CustomerArticleTable } from '../customer/customer-article-table';

const BusinessPartnerArticles = () => {
  const { cardcode: cardcodeQuery } = useParams();
  const mounted = useMounted();
  const { code: cardcode } = useSplit(cardcodeQuery);
  const [documentState, handleRefresh] = useData({
    sourceApi: customerApi.getCustomerSalesOrders,
    apiParameter: { cardcode },
    loadingMessage: 'Cargando informacion del Socio de negocio',
    mounted
  });
  const data = [
    {
      id: 1,
      type: 'I',
      cod: 'PSJ101005',
      name: 'Animalitos Tren San Jorge x500Gr.',
      inac: 'N',
      buy: 'Y',
      sale: 'Y',
      mobile: '50302',
      cod_maker: 'ANIMALITOS TREN X 4 - SAN JORGE',
      group: 'Tradicional',
      available: '',
      prec_u: '3.81',
      prec_p: '3.61',
      percep_exist: 'NO',
      min_percep: '0.00',
      percep: '0.00',
      detrac_exist: 'NO',
      min_detrac: '0.00',
      detrac: '0.00',
      inventory: 'NO',
      category: '',
      family: '',
      brand: 'SAN JORGE',
      line: '',
      plus: 'NO'
    },
    {
      id: 2,
      type: 'I',
      cod: 'PSJ101006',
      name: 'Animalitos Tren San Jorge x500Gr.',
      inac: 'N',
      buy: 'Y',
      sale: 'Y',
      mobile: '50302',
      cod_maker: 'ANIMALITOS TREN X 4 - SAN JORGE',
      group: 'Tradicional',
      available: '',
      prec_u: '3.81',
      prec_p: '3.61',
      percep_exist: 'NO',
      min_percep: '0.00',
      percep: '0.00',
      detrac_exist: 'NO',
      min_detrac: '0.00',
      detrac: '0.00',
      inventory: 'NO',
      category: '',
      family: '',
      brand: 'SAN JORGE',
      line: '',
      plus: 'NO'
    }
  ];

  return (
    <CustomerArticleTable
      documentState={documentState}
      onRefresh={handleRefresh}
      documents={data}
    />
  );
};

export { BusinessPartnerArticles };

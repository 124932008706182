import PropTypes from 'prop-types';
import GoalButton from './goal-button';
import { Upload as UploadIcon } from '../icons/upload';

import { useApp } from '../hooks/use-app';
import { DocumentAppendDialog } from './purchases/common/document-add';
import { useDialog } from '../hooks/use-dialog';
import { defaultDataFileFormats } from '../api/goal/importer/importer-definition';
import { goalServicesApi } from '../api/goal/services/goal-services';

export const GoalUploadButton = ({
  startIcon,
  label,
  loading = false,
  color = 'primary',
  variant = 'contained',
  disabled = false,
  children,
  mimeTypes = defaultDataFileFormats,
  onAfterSubmit = () => {},
  uploaderApi,
  onUpoload,
  documentName
}) => {
  const { showHelp } = useApp();
  const handleExitedDialog = () => {};
  const [appendDialogOpen, handleOpenAppendDialog, handleCloseAppendDialog] = useDialog();

  const uploadApi = goalServicesApi.postDocumentForm;
  const handleOpen = () => {
    handleOpenAppendDialog();
  };

  return (
    <>
      <GoalButton
        color={color}
        startIcon={startIcon || <UploadIcon />}
        loading={loading}
        size={showHelp ? 'small' : 'large'}
        label={label}
        // sx={{ order: 1 }}
        sx={{ px: 2, py: 2, mx: 0.8 }}
        variant={variant}
        onClick={handleOpen}
        disabled={disabled}
      >
        {showHelp || variant == 'text' ? children ?? label : ''}
      </GoalButton>
      <DocumentAppendDialog
        onClose={handleCloseAppendDialog}
        onExited={handleExitedDialog}
        afterSubmit={onAfterSubmit}
        uploaderApi={uploaderApi ?? uploadApi}
        open={appendDialogOpen}
        typeDocument={mimeTypes}
        hoverDocument="Seleccione un archivo"
        titleDocument="Agregar archivo de datos"
        handleDocuments={onUpoload}
        nameDocument={documentName}
        documentApi
        maxFiles={1}
      />
    </>
  );
};

GoalUploadButton.propTypes = {
  startIcon: PropTypes.element,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  mimeTypes: PropTypes.array,
  onAfterSubmit: PropTypes.func,
  uploaderApi: PropTypes.func,
  onUpoload: PropTypes.func.isRequired,
  documentName: PropTypes.string
};

import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { customeOrderApi } from '../../../api/customer-order';
// import { OrderInfoPayment } from '../components/orderInfos/orderInfo-payment';
// import { OrderInfoPaymentHistory } from '../components/orderInfos/orderInfo-payment-history';
// import { OrderInfoSendNotification } from '../components/orderInfos/orderInfo-send-notification';
import { Status } from '../../../components/status';
import { useMounted } from '../../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import { useApp } from '../../../hooks/use-app';
// import { OrderInfoEdit } from '../../../components/purchases/orderInfos/orderInfo-edit';
// import { InvoicReference } from '../../../components/purchases/orderInfos/orderInfo-reference';
// import { OrderInfoLog } from '../../../components/promo/assigned/assigned-log';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { RenderIf } from '../../../components/render-if';
import LoadingContent from '../../../components/common/loading-content';
import { DocumentLog } from '../../../components/purchases/common/document-log';
import { CustomerOrderDetails } from '../../../components/document/orders/customer-order-details';
import { CustomerOrderLines } from '../../../components/document/orders/customer-order-lines';
import GoalRecordLink from '../../../components/goal-record-link';
import { useData } from '../../../hooks/use-data';

export const CustomerOrder = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();
  const [editMode, setEditmode] = useState(false);

  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!orderInfoState.data) return;
    if (['D'].includes(orderInfoState.data.status)) {
      setEditmode(true);
    }
  };

  const [orderInfoState, refreshOrderInfo] = useData({
    sourceApi: customeOrderApi.getOrderDetail,
    apiParameter: { id },
    loadingMessage: 'Cargando documento',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });

  useEffect(() => {
    refreshOrderInfo({ id });
  }, [id]);

  const renderContent = () => {
    if (orderInfoState.isLoading || orderInfoState.error || !orderInfoState.data) {
      return <LoadingContent loadingText={orderInfoState.message} error={orderInfoState.error} />;
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <GoalRecordLink to={'/sales/orders'} label={'Pedidos'} />
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to={`${appUrlBase}/sales/orders/`}
              variant="text"
            >
              Pedidos
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              # {orderInfoState.data?.IdPedido}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={orderInfoState.data?.statusName} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <CustomerOrderDetails
                orderInfo={orderInfoState.data}
                setEditMode={handleEdit}
                onRefresh={refreshOrderInfo}
              />
            </Grid>
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={['F', 'G', 'D'].includes(orderInfoState.data.EstadoMigrado)}>
                <Grid item xs={12}>
                  <GoalRecordLink
                    to={`/promo/assigneds/${orderInfoState.data.IdPedido}`}
                    label="Ver detalle de promociones"
                    variant={'button'}
                  />
                </Grid>
              </RenderIf>
            </AllowedGuard>
            <RenderIf condition={!!orderInfoState.data.lines}>
              <Grid item xs={12}>
                <CustomerOrderLines orderInfo={orderInfoState.data} />
              </Grid>
            </RenderIf>
            <Grid item xs={12}>
              <DocumentLog document={{ objType: 124, docEntry: orderInfoState.data.IdPedido }} />
            </Grid>
            {/* <Grid item xs={12}>
              <InvoicReference orderInfo={orderInfoState.data} />
            </Grid> */}
            <Grid item xs={12}>
              {/* <OrderInfoPayment orderInfo={orderInfoState.data} /> */}
            </Grid>
            {/* <RenderIf condition={orderInfoState.data.lines}>
              <Grid item xs={12}>
                <OrderInfoOrderPreviewTable orderInfo={orderInfoState.data} />
              </Grid>
            </RenderIf> */}

            {/* 
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={orderInfoState.hasPromo}>
                <Grid item xs={12}>
                  <OrderInfoEvals orderInfo={orderInfoState.data.promo} />
                </Grid>
              </RenderIf>
            </AllowedGuard>
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={orderInfoState.hasPromo}>
                <Grid item xs={12}>
                  <OrderInfoTiming orderInfo={orderInfoState.data.promo} />
                </Grid>
              </RenderIf>
            </AllowedGuard> */}
          </Grid>
          {/* <Grid
            container
            item
            lg={8}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <OrderInfoLog orderInfo={orderInfoState.data} />
            </Grid>
          </Grid> */}
          <Grid
            container
            item
            lg={4}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
          >
            <Grid item xs={12}>
              {/* <OrderInfoSendNotification orderInfo={orderInfoState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <OrderInfoPaymentHistory /> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de pedido del cliente')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

import PropTypes from 'prop-types';
import moment from 'moment';
// import { format } from 'date-fns';
import {
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // Link,
  Checkbox
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';

export const GrItemsTable = ({
  requestData: documentState,
  onSelect,
  onSelectAll,
  selectedItems,
  selectData,
  setSelectData,
  handleClearSelectedRuc,
  isLoading,
  error
}) => {
  if (isLoading || error) {
    return <LoadingContent loadingText="Cargando" error={error} />;
  }
  const handleSelect = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      onSelect(e, item.id);
      setSelectData([item]);
    } else {
      handleClearSelectedRuc();
      setSelectData([]);
    }
  };

  return (
    <>
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={
                    documentState.length > 0 && selectedItems.length === documentState.length
                  }
                  //   disabled={documentState.isLoading}
                  disabled
                  indeterminate={
                    selectedItems.length > 0 && selectedItems.length < documentState.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {/* <TableCell>#</TableCell> */}
              <TableCell>ID</TableCell>
              <TableCell>CODIGO</TableCell>
              <TableCell>NOMBRE</TableCell>
              <TableCell>FECHA</TableCell>
              <TableCell>DIRECCION</TableCell>
              <TableCell>UBIGEO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState?.map((item, index) => (
              <TableRow
                key={item.id}
                hover
                selected={!!selectData.find((selectedItem) => selectedItem === item.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={!!selectData.find((selectedItem) => selectedItem.id === item.id)}
                    onChange={(event) => handleSelect(event, item)}
                  />
                </TableCell>
                {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.company_id}</TableCell>
                <TableCell>{item.company_name}</TableCell>
                <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell> direccion</TableCell>
                <TableCell>ubigeo</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </>
  );
};

GrItemsTable.propTypes = {
  requestData: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedItems: PropTypes.array,
  selectData: PropTypes.array,
  setSelectData: PropTypes.func,
  handleClearSelectedRuc: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

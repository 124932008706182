import { CircularProgress, ListItemIcon, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from '../confirmation-dialog';
import { useState } from 'react';
import { GoalListIcon } from '../icon/goal-list-icon';
import { SyncProblem } from '@mui/icons-material';

export const GoalMenu = (props) => {
  const {
    onClick,
    needConfirmation,
    children,
    icon = <SyncProblem />,
    confirmation = {},
    onCancel,
    onClose
  } = props;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenConfirmationDialog = () => {
    setLoading(true);
    setOpenConfirmDialog(true);
  };

  // on cancel
  const handleCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
    onCancel?.();
    setLoading(false);
    onClose?.();
  };

  const handleClick = async () => {
    setLoading(true);
    await onClick?.();
    setLoading(false);
    onClose?.();
  };

  const loadingContent = (
    <MenuItem disabled>
      <ListItemIcon>
        <CircularProgress />
      </ListItemIcon>
      Procesando
    </MenuItem>
  );

  if (!needConfirmation) {
    return loading ? (
      loadingContent
    ) : (
      <MenuItem onClick={handleClick}>
        <GoalListIcon icon={icon} />
        {children}
      </MenuItem>
    );
  }
  return (
    <>
      {loading ? (
        loadingContent
      ) : (
        <MenuItem onClick={handleOpenConfirmationDialog}>
          <GoalListIcon icon={icon} />
          {children}
        </MenuItem>
      )}
      <ConfirmationDialog
        message={confirmation.message ?? '¿Está seguro de realizar esta acción?'}
        onCancel={handleCancelConfirmationDialog}
        onConfirm={handleClick}
        open={openConfirmDialog}
        title={confirmation.title ?? 'Confirmación importante'}
        variant="warning"
      />
    </>
  );
};

GoalMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  needConfirmation: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
  confirmation: PropTypes.shape({
    message: PropTypes.string,
    title: PropTypes.string
  }),
  onCancel: PropTypes.func,
  onClose: PropTypes.func
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
import { customerReturnsApi } from '../../../api/customer-return';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Query } from '../../query';
import { FilterDialog } from '../../filter-dialog';
import GadminActions from '../../../contexts/gadmin-actions';
import { CustomerReturnViews } from '../../../api/customer-return-definitions';
import { BulkActionsMenu } from '../../bulk-actions-menu';

/**
 * Status
 * D Borrador
 * L Autorizado
 * G Generado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 * E Cancelado
 */

const views = [
  {
    label: 'Mostrar todos',
    value: 'all'
  },
  {
    label: 'Borrador',
    value: 'D'
  },
  {
    label: 'Autorizado',
    value: 'L'
  },
  {
    label: 'Programado',
    value: 'F'
  },
  {
    label: 'En reparto',
    value: 'W'
  },
  {
    label: 'Liquidado',
    value: 'Q'
  },
  {
    label: 'Contabilizado',
    value: 'T'
  },
  {
    label: 'Prepagado',
    value: 'R'
  },
  {
    label: 'Cerrado',
    value: 'C'
  },
  {
    label: 'Anulados',
    value: 'E'
  }
];

const filterProperties = [
  {
    label: 'Fecha de registro',
    name: 'registerDate',
    type: 'date'
  },
  {
    label: 'Fechas de programación',
    name: 'programDate',
    type: 'date'
  },
  {
    label: 'Total',
    name: 'docTotal',
    type: 'string'
  },
  {
    label: 'Almacén',
    name: 'whsName',
    type: 'string'
  },
  {
    label: 'Mesa',
    name: 'mesaName',
    type: 'string'
  },
  {
    label: 'Vendedor',
    name: 'slpName',
    type: 'string'
  },
  {
    label: 'Estado',
    name: 'statusName',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const CustomerReturnsFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = 'all',
    onRefresh
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [menuActions, setMenuActions] = useState([]);

  const afterProcessHandler = () => {
    // if (result) {
    //   if (menuItem.action === DeliveryVehicleActions.CANCEL) {
    //     onFiltersApply();
    //   }
    // }
    // }
    onRefresh?.();
  };
  useEffect(() => {
    const actions = [];

    if ([CustomerReturnViews.DRAFT].includes(view)) {
      actions.push({
        action: GadminActions.G2_CD_AUTH,
        label: 'Autorizar',
        prompt: '¿Está seguro que desea autorizar los documentos seleccionados?'
      });
    }
    if ([CustomerReturnViews.AUTHORIZED].includes(view)) {
      actions.push({
        action: GadminActions.G2_CD_PROGRAM,
        label: 'Programar',
        prompt: '¿Está seguro que desea programar los documentos seleccionados?'
      });
    }
    if ([CustomerReturnViews.PROGRAMMED].includes(view)) {
      actions.push({
        action: GadminActions.G2_CD_GEN,
        label: 'Generar',
        prompt: '¿Está seguro que desea generar los documentos seleccionados?'
      });
    }
    if ([CustomerReturnViews.IN_DELIVERY].includes(view)) {
      actions.push({
        action: GadminActions.G2_CD_LIQ,
        label: 'Liquidar',
        prompt: '¿Está seguro que desea liquidar los documentos seleccionados?'
      });
    }
    if (
      [
        CustomerReturnViews.DRAFT,
        CustomerReturnViews.AUTHORIZED,
        CustomerReturnViews.IN_DELIVERY,
        CustomerReturnViews.LIQUIDED
      ].includes(view)
    ) {
      actions.push({
        action: GadminActions.G2_CD_CANCEL,
        label: 'Anular',
        prompt: '¿Está seguro que desea anular los documentos seleccionados?'
      });
    }
    if ([CustomerReturnViews.CANCELED].includes(view)) {
      actions.push({
        action: GadminActions.G2_CD_REACTIVE,
        label: 'Reactivar',
        prompt: '¿Está seguro que desea reactivar los documentos seleccionados?'
      });
    }
    setMenuActions(actions);
  }, [view]);

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <BulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            selectedItems={selectedDocuments}
            selectedCount={selectedDocuments?.length}
            afterProcessHandler={afterProcessHandler}
            apiHandler={customerReturnsApi.processCustomerReturn}
          />
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

CustomerReturnsFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.string,
  onRefresh: PropTypes.func
};

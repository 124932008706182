import PropTypes from 'prop-types';

const InmediateLink = ({ to }) => {
  window.location.href = to;
  return null;
};
InmediateLink.propTypes = {
  to: PropTypes.string.isRequired
};
export default InmediateLink;

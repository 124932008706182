const orderStatus = [
  { color: 'info.main', label: 'Borrador', value: 'D' },
  { color: 'warning.main', label: 'Autorizado', value: 'L' },
  { color: 'warning.main', label: 'Digitando', value: 'W' },
  { color: 'success.main', label: 'Pedido SAP', value: 'F' },
  { color: 'success.main', label: 'Facturado', value: 'G' },
  { color: 'warning.main', label: 'Pendiente', value: 'X' },
  { color: 'success.main', label: 'Sin Stock', value: 'S' },
  { color: 'error.main', label: 'Anulado', value: 'A' }
];

const statusVariants = [
  { color: 'info.main', label: 'Borrador', value: 'D' },
  { color: 'warning.main', label: 'Autorizado', value: 'L' },
  { color: 'warning.main', label: 'Digitando', value: 'W' },
  { color: 'success.main', label: 'Pedido SAP', value: 'F' },
  { color: 'success.main', label: 'Facturado', value: 'G' },
  { color: 'success.main', label: 'Abierto', value: 'O' },
  { color: 'success.main', label: 'Cerrado', value: 'C' },
  { color: 'warning.main', label: 'Pendiente', value: 'X' },
  { color: 'success.main', label: 'Sin Stock', value: 'S' },
  { color: 'error.main', label: 'Cancelado', value: 'E' },
  { color: 'error.main', label: 'Anulado', value: 'A' }
];

const OrderOpennedView = {
  VEW_ALL: 'A',
  VEW_PROGRAMMED: 'P',
  VEW_NO_PROGRAMMED: 'NP'
};

const OrderOpennedViews = [
  {
    label: 'Mostrar todos',
    value: OrderOpennedView.VEW_ALL
  },
  {
    label: 'Programados',
    value: OrderOpennedView.VEW_PROGRAMMED
  },
  {
    label: 'No Programados',
    value: OrderOpennedView.VEW_NO_PROGRAMMED
  }
];
const ordersView = {
  VIEW_DRAFT: 'D',
  VIEW_AUTHORIZED: 'L',
  VIEW_CANCELED: 'A',
  VIEW_DISCARTED: 'E',
  VIEW_PENDING: 'X',
  VIEW_SAP_ORDER: 'F',
  VIEW_SAP_INVOICE: 'G',
  VIEW_SAP_PROGRAMED: 'P',
  VIEW_WITHOUT_STOCK: 'S',
  VIEW_ALL: 'all',
  VIEW_WRITING: 'E'
};

const OrderViews = [
  {
    label: 'Mostrar todos',
    value: 'all'
  },
  {
    label: 'Digitando',
    value: 'W'
  },
  {
    label: 'Retenido',
    value: 'D'
  },
  {
    label: 'Pendiente',
    value: 'X'
  },
  {
    label: 'Autorizado',
    value: 'L'
  },
  {
    label: 'Pedido SAP',
    value: 'F'
  },
  {
    label: 'Programado',
    value: 'P'
  },
  {
    label: 'Facturado',
    value: 'G'
  },
  {
    label: 'Sin Stock',
    value: 'S'
  },
  {
    label: 'Anulado',
    value: 'A'
  },
  {
    label: 'Cancelado',
    value: 'E'
  }
];

const OrderFilterProperties = [
  {
    label: 'Número pedido',
    name: 'id',
    type: 'number'
  },
  {
    label: 'Código Cliente',
    name: 'CodigoCliente',
    type: 'string'
  },
  {
    label: 'Fecha de pedido',
    name: 'FechaPedido',
    type: 'date'
  },
  {
    label: 'Fechas de despacho',
    name: 'fecdsp',
    type: 'date'
  },
  {
    label: 'Total Pedido',
    name: 'TotalPedido',
    type: 'string'
  },
  {
    label: 'Código Almacén',
    name: 'CodigoAlmacen',
    type: 'string'
  },
  {
    label: 'Código Zona',
    name: 'CodigoZona',
    type: 'string'
  },
  {
    label: 'Código Mesa',
    name: 'CodigoMesa',
    type: 'string'
  },
  {
    label: 'Almacén',
    name: 'whsName',
    type: 'string'
  },
  {
    label: 'Mesa',
    name: 'mesaName',
    type: 'string'
  },
  {
    label: 'Vendedor',
    name: 'slpName',
    type: 'string'
  },
  {
    label: 'Estado',
    name: 'EstadoMigrado',
    type: 'string'
  },
  {
    label: 'Hoja de reparto',
    name: 'codprog',
    type: 'number'
  }
];

const orderBulkActions = { CANCEL: 'cancel', AUTHORIZE: 'auth' };

export {
  orderStatus,
  statusVariants,
  OrderOpennedView,
  OrderViews,
  OrderFilterProperties,
  OrderOpennedViews,
  ordersView,
  orderBulkActions
};

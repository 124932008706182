import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
import { Box, Button, Card, DialogActions, DialogTitle, Grid, InputLabel } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useApp } from '../../../hooks/use-app';
import { InputField } from '../../input-field';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { useSelection } from '../../../hooks/use-selection';
import { PropertyList } from '../../property-list';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { useQuery } from '../../../hooks/use-query';
import { useMounted } from '../../../hooks/use-mounted';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { QueryCustomerModal } from '../../../containers/promotions/query-customer-modal';
import { SurveyItemsTable } from './survey-item-table';
import { AutocompleteField } from '../../autocomplete-field';
import { documentStatusName, documentStatusViews } from '../../../api/app-definitions';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import useFormikMultiSelect from '../../../hooks/use-formik-multiselect';
import MultiSelectField from '../../common/multi-select-field';
import { goalInventoryApi } from '../../../api/goal/inventory/goal-inventory';

export const SurveySurveyForm = ({ title = '', initialValues = {}, onClose, onRefreshList }) => {
  const [selectData, setSelectData] = useState([]);
  const { showNotify, showError, currentCompany } = useApp();

  const [selectedItems, handleSelect, handleSelectAll, handleClearSelected] = useSelection([]);
  const [applyToListData, setApplyToListData] = useState([]);
  const [applyToListDataOriginal, setApplyToDataOriginal] = useState([]);

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [applyToData] = await Promise.all([goalInventoryApi.getLabelsResources()]);

    if (applyToData.success && applyToData.data.length !== 0) {
      setApplyToListData(applyToData.data);
      setApplyToDataOriginal(applyToData.data);
    }
  };

  const handleSetQuestions = (data) => {
    // if (!!selectedItems && selectedItems.length > 0) {
    //   formik.setFieldValue('selectQuestions', data);
    // } else {
    //   showNotify('No se ha seleccionado filas', 'warning');
    // }

    const newData = data.map((obj) => ({
      id: obj.id,
      _id: obj._id,
      _rev: obj._rev,
      title: obj.title,
      type_question: obj.type_question,
      question: obj.question,
      options: obj.options,
      correct: obj.correct,
      score: obj.score
      // apply_to: obj.apply_to
    }));
    // console.log('handleSetQuestions', newData);
    formik.setFieldValue('selectQuestions', newData);
  };

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany,
      title: initialValues.title || '',
      date: initialValues.date || moment().format('YYYY-MM-DD'),
      selectQuestions: initialValues.selectQuestions || [],
      sales_team_id: initialValues.sales_team_id || '',
      sales_team_info: initialValues.sales_team_info || '',
      point: initialValues.point || 0,
      apply_to: initialValues.apply_to || [],
      status: initialValues.status || documentStatusViews.VIEW_DRAFT,
      statusName: initialValues.status_name || documentStatusName.VIEW_DRAFT_NAME
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      if (formik.values.selectQuestions.length === 0) {
        showNotify('No se ha seleccionado una pregunta', 'warning');
        return;
      }
      console.log(values);
      const totalScore = values.selectQuestions.reduce((total, item) => total + item.score, 0);
      if (formik.values.point === totalScore) {
        const response = await goalCustomeSurveyApi.postSurveySurveyForm(values);
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          onRefreshList();
          onClose(false);
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      } else {
        showError(
          `Los puntos totales de las preguntas ${totalScore} no coinciden con el total requerido ${formik.values.point} `
        );
      }
    }
  });

  const { handleMultiSelectChange, getOptionsName, handleOptionsDelete } =
    useFormikMultiSelect(formik);

  const query = useQuery();
  const mounted = useMounted();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };
  useEffect(() => {
    if (queried.query) handleSetController.setSearchText({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    ,
    ,
    ,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomeSurveyApi.getSurveyList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
  });

  const salesTeamList = [
    { code: 'T1', name: 'Team 1' },
    { code: 'T2', name: 'Team 2' },
    { code: 'T3', name: 'Team 3' }
  ];

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información de Encuesta
            </InputLabel>
            <PropertyList>
              <CompanyInputDisabled currentCompany={currentCompany} />
              <InputField
                label="Título"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                sx={{ marginBottom: '1em' }}
                message="El título es un identificador unico, que describe esta encuesta"
              />
              <InputField
                name="date"
                label="Fecha"
                type="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                inputProps={{
                  min: moment().add(0, 'day').format('YYYY-MM-DD')
                }}
                sx={{ marginBottom: '1em', height: '62px' }}
                message="La fecha nos indica cuando empezara la encuesta"
              />
              <AutocompleteField
                label="Equipo de ventas"
                name="sales_team_info"
                placeholder="Seleccione equipo de ventas"
                fullWidth
                options={salesTeamList || [{ name: 'Cargando', code: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('sales_team_id', value?.code);
                  formik.setFieldValue('sales_team_info', value);
                }}
                value={formik.values.sales_team_info}
                sx={{ marginBottom: '1em' }}
                error={formik.touched.sales_team_id && Boolean(formik.errors.sales_team_id)}
                helperText={formik.touched.sales_team_id && formik.errors.sales_team_id}
              />
              <InputField
                label="Puntos"
                name="point"
                type="number"
                value={formik.values.point}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.point && Boolean(formik.errors.point)}
                helperText={formik.touched.point && formik.errors.point}
                sx={{ marginBottom: '1em' }}
                message="Puntos totales que tendra la encuesta"
              />
              <MultiSelectField
                label="Aplicar en"
                name="apply_to"
                value={applyToListDataOriginal.length === 0 ? [] : formik.values.apply_to}
                onChange={(e) =>
                  handleMultiSelectChange(e, 'apply_to', setApplyToListData, true, applyToListData)
                }
                error={formik.touched.apply_to && Boolean(formik.errors.apply_to)}
                options={applyToListDataOriginal}
                getOptionName={(value) => getOptionsName(value.code, applyToListDataOriginal)}
                onDeleteOption={(value) =>
                  handleOptionsDelete(
                    value,
                    'apply_to',
                    formik.values.apply_to,
                    setApplyToListData,
                    true,
                    applyToListData
                  )
                }
              />
            </PropertyList>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <QueryCustomerModal
                disabled={returnsState.isLoading}
                onQueryChange={handleQueryChange}
                query={controller.query}
                handleRefresh={handleRefresh}
                title="Seleccionar preguntas"
              />
              <SurveyItemsTable
                requestData={returnsState.documents}
                disabled={returnsState.isLoading}
                onSelect={handleSelect}
                onSelectAll={handleSelectAll}
                selectedItems={selectedItems}
                setSelectData={setSelectData}
                selectData={selectData}
                handleClearSelected={handleClearSelected}
                isLoading={returnsState.isLoading}
                error={returnsState.error}
                onQueryChange={handleQueryChange}
                query={controller.query}
                handleSetQuestions={handleSetQuestions}
              />
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2,
            marginLeft: 1
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

SurveySurveyForm.propTypes = {
  initialValues: Proptypes.object,
  onClose: Proptypes.func,
  title: Proptypes.string,
  onRefreshList: Proptypes.func
};

import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
import { applyFilters } from '../utils/apply-filters';
import { applyPagination } from '../utils/apply-pagination';
import { applySort } from '../utils/apply-sort';
import { cacheEnabled } from './app-definitions';
import { OrderOpennedView } from './customer-order-definitions';
import { dataLoader } from './data-loader';

/* eslint class-methods-use-this:0 */

class CustomeOrderApi {
  async getCustomeOrders(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,

      mainFilter
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.gCC_BROWSE_VISITA_DIA,
      cacheItem: 'customer-orders',
      apiParams: {
        type: 'diary',
        view,
        payload: { filters, sort, sortBy, page, query, reload, orderDate: mainFilter?.orderDate }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'CodigoCliente' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardName' },
        { type: 'string', name: 'slpName' },
        { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  async getCustomerUnauthorizedOrders(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,

      mainFilter
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.gCC_BROWSE_VISITA_DIA,
      cacheItem: 'unauthorized-customer-orders',
      apiParams: {
        type: 'unauthorized-orders',
        view,
        payload: { filters, sort, sortBy, page, query, reload, orderDate: mainFilter?.orderDate }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'CodigoCliente' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardName' },
        { type: 'string', name: 'slpName' },
        { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  async getCustomeOrders1(options) {
    const { filters, sort, sortBy, page, query, view, reload, orderDate } = options;
    const cacheItem = 'customer-orders';
    let returnsData = [];
    if (cacheEnabled) {
      returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
    }
    if (reload || !returnsData) {
      const response = await GadminRequest(GadminActions.gCC_BROWSE_VISITA_DIA, {
        type: 'diary',
        view,
        payload: { orderDate }
      });
      const { data } = response;
      returnsData = data;
      if (cacheEnabled) {
        localStorage.setItem(cacheItem, JSON.stringify(returnsData));
      }
    }
    if (!returnsData || !Array.isArray(returnsData))
      return {
        documents: [],
        documentsCount: 0
      };

    const queriedInvoices = returnsData.filter((_invoice) => {
      // If query exists, it looks only in customer id field
      if (
        !!query &&
        !(
          _invoice.id?.toString().toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoCliente?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoAlmacen?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.cardName?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.slpName?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoZona?.toLowerCase().includes(query.toLowerCase())
        )
      ) {
        return false;
      }

      // No need to look for any resource fields
      if (typeof view === 'undefined' || view === 'all') {
        return true;
      }
      // In this case, the view represents the resource status
      return _invoice.EstadoMigrado === view;
    });
    const filteredInvoices = applyFilters(queriedInvoices, filters);
    const sortedInvoices = applySort(filteredInvoices, sort, sortBy);
    const paginatedInvoices = applyPagination(sortedInvoices, page);

    return Promise.resolve({
      documents: paginatedInvoices,
      documentsCount: filteredInvoices.length
    });
  }

  async getCustomeOrdersCanceled(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;
    const cacheItem = 'customer-orders-canceled';
    let returnsData = [];
    if (cacheEnabled) {
      returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
    }
    if (reload || !returnsData) {
      const response = await GadminRequest(GadminActions.gCC_BROWSE_VISITA_DIA, {
        type: 'canceled'
      });
      const { data } = response;
      returnsData = data;
      if (cacheEnabled) {
        localStorage.setItem(cacheItem, JSON.stringify(returnsData));
      }
    }
    if (!returnsData)
      return {
        documents: [],
        documentsCount: 0
      };

    const queriedInvoices = returnsData.filter((_invoice) => {
      // If query exists, it looks only in customer id field
      if (
        !!query &&
        !(
          _invoice.id?.toString().toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoCliente?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoAlmacen?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.cardName?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.slpName?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoZona?.toLowerCase().includes(query.toLowerCase())
        )
      ) {
        return false;
      }

      // No need to look for any resource fields
      if (typeof view === 'undefined' || view === 'all') {
        return true;
      }
      // In this case, the view represents the resource status
      return _invoice.EstadoMigrado === view;
    });
    const filteredInvoices = applyFilters(queriedInvoices, filters);
    const sortedInvoices = applySort(filteredInvoices, sort, sortBy);
    const paginatedInvoices = applyPagination(sortedInvoices, page);

    return {
      documents: paginatedInvoices,
      documentsCount: filteredInvoices.length
    };
  }

  async getCustomeOrdersOpenned(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;
    const cacheItem = 'customer-orders-openned';
    let returnsData = [];
    if (cacheEnabled) {
      returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
    }
    if (reload || !returnsData) {
      const response = await GadminRequest(GadminActions.gCC_BROWSE_VISITA_DIA, {
        type: 'openned'
      });
      const { data } = response;
      returnsData = data;
      if (cacheEnabled) {
        localStorage.setItem(cacheItem, JSON.stringify(returnsData));
      }
    }
    if (!returnsData)
      return {
        documents: [],
        documentsCount: 0
      };

    const queriedInvoices = returnsData.filter((_invoice) => {
      // If query exists, it looks only in customer id field
      if (
        !!query &&
        !(
          _invoice.id?.toString().toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoCliente?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoAlmacen?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.cardName?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.slpName?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.CodigoZona?.toLowerCase().includes(query.toLowerCase())
        )
      ) {
        return false;
      }

      // No need to look for any resource fields
      if (typeof view === 'undefined' || view === OrderOpennedView.VEW_ALL) {
        return true;
      }
      if (view === OrderOpennedView.VEW_PROGRAMMED) {
        return !!_invoice.codProg;
      }
      if (view === OrderOpennedView.VEW_NO_PROGRAMMED) {
        return !_invoice.codProg;
      }
      return false;
    });
    const filteredInvoices = applyFilters(queriedInvoices, filters);
    const sortedInvoices = applySort(filteredInvoices, sort, sortBy);
    const paginatedInvoices = applyPagination(sortedInvoices, page);
    return {
      documents: paginatedInvoices,
      documentsCount: filteredInvoices.length
    };
  }

  async getOrdersStats(payload) {
    const response = await GadminRequest(GadminActions.gCC_BROWSE_VISITA_DIA, {
      type: 'stats',
      payload
    });
    return response;
  }

  async getStatMinDate() {
    const response = await GadminRequest(GadminActions.gCC_BROWSE_VISITA_DIA, {
      type: 'stats-min-date'
    });
    return Promise.resolve(response.data);
  }

  async getStatAllCount() {
    const response = await GadminRequest(GadminActions.gCC_BROWSE_VISITA_DIA, {
      type: 'stats-all-count'
    });
    return Promise.resolve(response.data);
  }

  async getOrderDetail({ id }) {
    const response = await GadminRequest(GadminActions.gCC_BROWSE_VISITA_DIA, {
      type: 'detail',
      payload: { id }
    });
    return Promise.resolve(response.data);
  }

  async processOrderBulk(payload) {
    const response = await GadminRequest(GadminActions.G2_DOCUMENT_BULK, {
      payload
    });
    return response;
  }

  async getOrderDraft({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `automations/mobile-orders/${id}?companyId=${companyId}`
    });
    return response;
  }
}

export const customeOrderApi = new CustomeOrderApi();

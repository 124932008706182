import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Skeleton, Typography } from '@mui/material';
import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../icons/arrow-left';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../icons/exclamation-outlined';
import { Eye as EyeIcon } from '../../icons/eye';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { DocumentReference } from '../../components/purchases/common/document-reference';
import { DocumentLog } from '../../components/purchases/common/document-log';
import { useDialog } from '../../hooks/use-dialog';
import { ReceiptFeesEdit } from '../../components/purchases/receipt-fees/receipt-fees-edit';
import { CustomerReturnDetails } from '../../components/document/returns/customer-return-detail';
import { CustomerReturnLineItems } from '../../components/document/returns/customer-return-line-items';
import { CustomerReturnPrintingDialog } from '../../components/document/printing/customer-return/customer-return-printing-dialog';
import { customerReturnsApi } from '../../api/customer-return';
import { CustomerReturnSourceLineItems } from '../../components/document/returns/customer-return-source-line-items';

export const CustomerReturn = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();
  const [documentState, setDocumentState] = useState({ isLoading: true });
  const [editMode, setEditmode] = useState(false);
  const [isNote, setIsNote] = useState(true);
  const [pdfViewerDialogOpen, handleOpenPdfViewerDialog, handleClosePdfViewerDialog] = useDialog();

  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!documentState.data) return;
    if (['D'].includes(documentState.data.status)) {
      setEditmode(true);
    }
  };

  const getDocument = useCallback(async () => {
    setDocumentState(() => ({ isLoading: true }));
    setIsNote(false);
    try {
      const result = await customerReturnsApi.getCustomerReturn(id);

      if (mounted.current) {
        setDocumentState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setDocumentState(() => ({
          isLoading: false,
          // error: err.message
          error: err.message ? err.message : 'Error del servidor'
        }));
      }
    }
  }, []);

  useEffect(() => {
    getDocument().catch(console.error);
  }, []);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => {
    if (documentState.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (documentState.error || !documentState.data) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              {documentState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to={`${appUrlBase}/sales/returns/`}
              variant="text"
            >
              Ordenes de recojo
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              #{documentState.data?.id}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="primary"
                size="large"
                startIcon={<EyeIcon />}
                onClick={handleOpenPdfViewerDialog}
                variant="contained"
              >
                PDF
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={documentState.data?.statusName} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              {editMode ? (
                <ReceiptFeesEdit document={documentState.data} setEditMode={setEditmode} />
              ) : (
                <CustomerReturnDetails document={documentState.data} setEditMode={handleEdit} />
              )}
            </Grid>
            <Grid item xs={12}>
              {isNote ? <DocumentReference document={documentState.data} /> : ''}
            </Grid>
            <Grid item xs={12}>
              {/* <DocumentPayment document={documentState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              <CustomerReturnLineItems document={documentState.data} />
            </Grid>
            <Grid item xs={12}>
              <CustomerReturnSourceLineItems document={documentState.data} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <DocumentLog
                document={{
                  objType: documentState.data.objType,
                  docEntry: documentState.data.docEntry
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={4}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
          >
            <Grid item xs={12}>
              {/* <DocumentSendNotification document={documentState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <DocumentPaymentHistory /> */}
            </Grid>
          </Grid>
        </Grid>
        <CustomerReturnPrintingDialog
          onClose={handleClosePdfViewerDialog}
          open={pdfViewerDialogOpen}
          entity={{ objType: documentState.data?.objType, docEntry: documentState.data?.docEntry }}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de comprobante')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

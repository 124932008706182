import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { useMounted } from '../../../hooks/use-mounted';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider } from '@mui/material';
import BulkQueuesTable from '../../../components/automations/bulks/bulk-queues-table';
import { useData } from '../../../hooks/use-data';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import LoadingContent from '../../../components/common/loading-content';
import { bulksApi } from '../../../api/managment/bulks';

const BulkQueues = () => {
  const { id } = useParams();
  const { title, currentCompany } = useApp();
  const mounted = useMounted();

  const [data, setData] = useState(null);

  const [documentState, handleRefresh] = useData({
    sourceApi: bulksApi.getQueuesOfOneBulk,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: `Cargando Queues del Bulk ${id}`,
    mounted
  });

  useEffect(() => {
    setData(documentState.data);
  }, [documentState]);

  if (documentState.isLoading || documentState.error || !documentState.data) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <>
      <Helmet>
        <title>{title(`Queues del Bulk con id ${id}`)} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              padding: '1rem'
            }}
          >
            <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={() => handleRefresh({ companyId: currentCompany, bulkId: id })}
                  variant="contained"
                >
                  Refresh
                </Button>
              </Box>
            </AllowedGuard>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
            {data && data.length > 0 && <BulkQueuesTable data={data} />}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default BulkQueues;

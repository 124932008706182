import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
// import { AllowedGuard } from '../hooks/use-allowed';
import { ConfirmationDialog } from './confirmation-dialog';
import { useApp } from '../hooks/use-app';
// import GadminActions from '../contexts/gadmin-actions';

export const MultiActionsMenu = (props) => {
  const {
    disabled,
    selectedItems,
    selectedCount,
    menuActions: menuActionsProp,
    afterProcessHandler,
    handleUpdateScore = () => {}
  } = props;

  const { showNotify, showError } = useApp();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [menuActions, setMenuActions] = useState([]);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');

  useEffect(() => {
    setMenuActions(menuActionsProp);
  }, [menuActionsProp]);

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    handleClose();
  };
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
    handleClose();
  };
  const onConfirmConfirmationDialog = async () => {
    // const promises = selectedItems.map(async (id) => {
    //   const response = await currentMenuItem.apiHandler(id);
    //   if (response.success) {
    //     return true;
    //   }
    //   return false;
    // });

    const promises = selectedItems.map(async (id) => {
      let response;
      if (currentMenuItem.idDetail) {
        response = await currentMenuItem.apiHandler(currentMenuItem.idDetail, id);
      } else {
        response = await currentMenuItem.apiHandler(id);
      }
      if (response.success) {
        return true;
      }
      return false;
    });
    Promise.all(promises)
      .then((results) => {
        const allSuccess = results.every((success) => success === true);
        if (allSuccess) {
          showNotify('Cambio exitoso');
          handleUpdateScore(selectedItems);
          afterProcessHandler();
        } else {
          showError('No se procesó la acción');
        }
      })
      .catch((error) => {
        console.error('Error al procesar las llamadas a la API:', error);
        showError('No se procesó la acción');
      });
    handleCloseDialog();
  };

  const handleOpenConfirmationDialog = (menuItem) => {
    setCurrentMenuItem(menuItem);
    setConfirmDialogMessage(menuItem.prompt);
    setOpenConfirmDialog(true);
  };

  return (
    <>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        startIcon={<ChevronDownIcon />}
        variant="outlined"
        sx={{
          display: selectedCount > 0 ? 'flex' : 'none',
          order: {
            sm: 1,
            xs: 1
          }
        }}
      >
        Acciones Masivas
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {menuActions.map((item, index) => (
          //   <AllowedGuard permission={item.permission || item.action} key={index}>
          <MenuItem
            key={index}
            onClick={() => {
              handleOpenConfirmationDialog(item);
            }}
          >{`${item.label} (${selectedCount})`}</MenuItem>
          //   </AllowedGuard>
        ))}
        {menuActions.length === 0 && <MenuItem>No hay acciones</MenuItem>}
      </Menu>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={onCancelConfirmationDialog}
        onConfirm={onConfirmConfirmationDialog}
        open={openConfirmDialog}
        title="Confirmación importante"
        variant="warning"
      />
    </>
  );
};

MultiActionsMenu.propTypes = {
  disabled: PropTypes.bool,
  menuActions: PropTypes.array.isRequired,
  afterProcessHandler: PropTypes.func.isRequired,
  selectedCount: PropTypes.number,
  selectedItems: PropTypes.array,
  handleUpdateScore: PropTypes.func
};

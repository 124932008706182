import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import GadminActions from '../../contexts/gadmin-actions';
import { useApp } from '../../hooks/use-app';
import { useTabs } from '../../hooks/use-tabs';

const defaultTabs = [
  {
    href: '',
    label: 'Resumen',
    permission: GadminActions.gCC_TDOY_SUMARY
  },
  {
    href: '/channel',
    label: 'Canales',
    permission: GadminActions.gCC_TDOY_SUMARY
  },
  {
    href: '/zones',
    label: 'Zonas',
    permission: GadminActions.gCC_TDOY_DET
  },
  {
    href: '/prices',
    label: 'Precios',
    permission: GadminActions.gCC_TDOY_DET
  },
  {
    href: '/routes',
    label: 'Rutas',
    permission: GadminActions.gCC_TDOY_DET
  },
  {
    href: '/sellers',
    label: 'Vendedores',
    permission: GadminActions.gCC_TDOY_DET
  },
  {
    href: '/providers',
    label: 'Proveedores',
    permission: GadminActions.gCC_TDOY_DET
  },
  {
    href: '/brands',
    label: 'Marcas',
    permission: GadminActions.gCC_TDOY_DET
  },
  {
    href: '/products',
    label: 'Productos',
    permission: GadminActions.gCC_TDOY_DET
  }
];

export const SalesTeamsTab = () => {
  const app = useApp();
  const { id } = useParams();
  const { appUrlBase } = useApp();

  const { renderTabs } = useTabs({
    basePath: `${appUrlBase}/commercial/sales-teams/${id}`,
    backPath: {
      path: `${appUrlBase}/commercial/sales-teams`,
      label: 'volver (Mesas)'
    },
    tabs: defaultTabs
  });

  return (
    <>
      <Helmet>
        <title>{app.title('Mesas')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderTabs()}
        </Container>
      </Box>
    </>
  );
};

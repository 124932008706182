import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { useApp } from '../../../hooks/use-app';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'driver',
    label: 'Conductor'
  },
  {
    id: 'color',
    label: 'Color'
  }
];
/**
 * docTotal, registeredValue, programedValue, vatSum
 * Status
 * D Borrador
 * L Autorizado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 */

export const CustomerDeliveryPlanningTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const { appUrlBase } = useApp();
  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);
  const detailRoute = `${appUrlBase}/delivery/monitoring/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column, index) => (
                <TableCell key={index}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow
                hover
                key={index}
                selected={
                  !!selectedDocuments.find(
                    (selectedCustomer) => selectedCustomer === document.docEntry
                  )
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find(
                        (selectedCustomer) => selectedCustomer === document.id
                      )
                    }
                    onChange={(event) => onSelect(event, document.id)}
                  />
                </TableCell>
                {false && (
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${detailRoute}${document.id}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      #{document.id}
                    </Link>
                  </TableCell>
                )}
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    #{document.id}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.driver?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    <span
                      style={{
                        backgroundColor: document?.color,
                        color: '#fff',
                        padding: '3px 15px'
                      }}
                    >
                      {document?.color}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

CustomerDeliveryPlanningTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

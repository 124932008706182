import { useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  // FormHelperText,
  Grid,
  MenuItem,
  Tab
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { PropertyList } from '../property-list';
import { InputField } from '../input-field';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { Plus as PlusIcon } from '../../icons/plus';
import { Trash as TrashIcon } from '../../icons/trash';
import { useApp } from '../../hooks/use-app';
import { goalImporterApi } from '../../api/goal/importer/goal-importer-api';
import Scrollbar from '../Scrollbar';
import { X as XIcon } from '../../icons/x';
import { Check as CheckIcon } from '../../icons/check';
import { ImportArgumentsTable } from './import-arguments-table';
import { ImportLinksTable } from './import-links-table';
import {
  dataTypeData,
  dataTypeEnum,
  defaultDataFileFormats
} from '../../api/goal/importer/importer-definition';
// import Scrollbar from '../Scrollbar';

export const ImportForm = ({
  title,
  initialValues = {},
  onClose,
  update = false,
  handleRefresh
}) => {
  const { showNotify, showError } = useApp();
  const [tabValue, setTabValue] = useState('argument');
  const [argumentsValue, setArgumentsValue] = useState(initialValues.arguments || []);
  const [input, setInput] = useState({
    data_type: '',
    name: '',
    min: 0,
    max: 0,
    required: true,
    typeDocument: '',
    defaultValue: defaultDataFileFormats.join(','),
    title: ''
  });
  const [linkValue, setlinkValues] = useState(initialValues.links || []);
  const [inputLink, setInputLink] = useState({
    name: '',
    title_download: '',
    title: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [isEditingLink, setIsEditingLink] = useState(false);
  const [editIndexLink, setEditIndexLink] = useState(null);

  const handleAfterSave = () => {
    handleRefresh();
    onClose(false);
  };

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      name: initialValues.name || '',
      title: initialValues.title || '',
      permission: initialValues.permission || '',
      path: initialValues.path || '',
      require_company: initialValues.require_company || false,
      arguments: initialValues.arguments || [{ data_type: '', name: '' }],
      links: initialValues.links || []
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      path: Yup.string().required('Este campo es obligatorio'),
      require_company: Yup.boolean()
    }),
    onSubmit: async (values) => {
      values.arguments = argumentsValue;
      values.links = linkValue;
      values.arguments.forEach((arg) => {
        if (
          [
            dataTypeEnum.NUMBER,
            dataTypeEnum.TEXT,
            dataTypeEnum.BOLEANO,
            dataTypeEnum.ARCHIVE
          ].includes(arg.data_type)
        ) {
          if (arg.min === 0 || arg.min === '0') {
            arg.min = null;
          }
          if (arg.max === 0 || arg.max === '0') {
            arg.max = null;
          }
        }
      });

      if (update) {
        const data = { ...values, _id: initialValues._id, _rev: initialValues._rev };
        const response = await goalImporterApi.updateManagerTemplate(initialValues._id, data);
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleAfterSave();
        } else {
          showError({
            message: response?.message || 'No se proceso la acción...',
            detail: response?.errors
          });
        }
      } else {
        const isValid = values.arguments.every((arg) => arg.name !== '' && arg.data_type !== '');
        if (isValid) {
          const response = await goalImporterApi.postManagerTemplate({
            values
          });
          if (response.success) {
            showNotify(`${response?.message}. Cambio exitoso `);
            handleAfterSave();
          } else {
            showError({
              message: response?.message || 'No se proceso la acción...',
              detail: response?.errors
            });
          }
        } else {
          showError('Hay campos vacios importantes, en Nombre del parametro o Tipo de dato');
        }
      }
    }
  });
  const handleAddItem = () => {
    if (input.min !== 0 && input.max !== 0 && input.max !== null && input.max !== null) {
      if (input.min > input.max) {
        showError('El valor mínimo no puede ser mayor que el valor máximo');
        return;
      }
      if (input.min === input.max) {
        showError('El valor mínimo no puede ser igual al valor máximo');
        return;
      }
    }
    if (isEditing) {
      const newTodos = [...argumentsValue];
      newTodos[editIndex] = input;
      setArgumentsValue(newTodos);
      setIsEditing(false);
      setEditIndex(null);
    } else {
      setArgumentsValue([...argumentsValue, input]);
    }
    setInput({ data_type: '', name: '', min: 0, max: 0, required: true, typeDocument: '' });
  };

  const handleDeleteItem = (index) => {
    const newTodos = [...argumentsValue];
    newTodos.splice(index, 1);
    setArgumentsValue(newTodos);
  };

  const handleEditItem = (index) => {
    setIsEditing(true);
    setEditIndex(index);
    setInput(() => ({
      data_type: argumentsValue[index].data_type ?? dataTypeEnum.TEXT,
      name: argumentsValue[index].name ?? '',
      min: argumentsValue[index].min ?? 0,
      max: argumentsValue[index].max ?? 0,
      required: argumentsValue[index].required ?? false,
      typeDocument: argumentsValue[index].typeDocument ?? '',
      defaultValue: argumentsValue[index].defaultValue ?? '',
      title: argumentsValue[index].title ?? ''
    }));
  };

  const handleCanceled = () => {
    setIsEditing(false);
    setEditIndex(null);
    setInput({ data_type: '', name: '', min: 0, max: 0, required: true, typeDocument: '' });
  };

  const handleInputChange = (e) => {
    // setInput({ ...input, [e.target.name]: e.target.value });
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setInput({ ...input, [e.target.name]: value });
  };

  const handleAddItemLink = () => {
    if (isEditingLink) {
      const newLinks = [...linkValue];
      newLinks[editIndexLink] = inputLink;
      setlinkValues(newLinks);
      setIsEditingLink(false);
      setEditIndexLink(null);
    } else {
      setlinkValues([...linkValue, inputLink]);
    }
    setInputLink({ name: '', title_download: '', title: '' });
  };

  const handleDeleteItemLink = (index) => {
    const newLinks = [...linkValue];
    newLinks.splice(index, 1);
    setlinkValues(newLinks);
  };

  const handleEditItemLink = (index) => {
    setIsEditingLink(true);
    setEditIndexLink(index);
    setInputLink(linkValue[index]);
  };

  const handleCanceledLink = () => {
    setIsEditingLink(false);
    setEditIndexLink(null);
    setInputLink({ name: '', title_download: '', title: '' });
  };

  const handleInputChangeLink = (e) => {
    setInputLink({ ...inputLink, [e.target.name]: e.target.value });
  };

  const isAddDisabled = !input.data_type || !input.name;
  const isAddDisabledLink = !inputLink.name || !inputLink.title;

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2,
            padding: 5
          }}
        >
          <Grid item xs={6}>
            <PropertyList>
              <InputField
                label="Nombre"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                sx={{ marginBottom: '1em', minWidth: '150px' }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <InputField
                label="Path"
                name="path"
                value={formik.values.path}
                onChange={formik.handleChange}
                fullWidth
                sx={{ marginBottom: '1em', minWidth: '150px' }}
                error={formik.touched.path && Boolean(formik.errors.path)}
                helperText={formik.touched.path && formik.errors.path}
              />
              <InputField
                label="Permiso"
                name="permission"
                value={formik.values.permission}
                onChange={formik.handleChange}
                fullWidth
                sx={{ marginBottom: '1em', minWidth: '150px' }}
                error={formik.touched.permission && Boolean(formik.errors.permission)}
                helperText={formik.touched.permission && formik.errors.permission}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.require_company}
                    onChange={formik.handleChange}
                    name="require_company"
                    color="secondary"
                  />
                }
                label="¿Requerir empresa?"
                sx={{ marginBottom: 1 }}
              />
            </PropertyList>
          </Grid>
        </Grid>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
              <Tab label="Parametros" value="argument" />
              <Tab label="Enlaces" value="link" />
            </TabList>
          </Box>
          <TabPanel value="argument">
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <InputField
                  name="title"
                  label="Título"
                  value={input.title || ''}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ marginBottom: '1em', minWidth: '150px' }}
                />
                <InputField
                  name="name"
                  label="Nombre"
                  value={input.name}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ marginBottom: '1em', minWidth: '150px' }}
                />
                <InputField
                  select
                  label="Tipo de dato"
                  name="data_type"
                  value={input.data_type}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ marginBottom: '1em', minWidth: '150px' }}
                >
                  {dataTypeData.map((item) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.name}
                    </MenuItem>
                  ))}
                </InputField>

                {input.data_type === dataTypeEnum.TEXT && (
                  <>
                    <InputField
                      name="min"
                      label="Minimo de letras"
                      type="number"
                      value={input.min || 0}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ marginBottom: '1em', minWidth: '150px' }}
                    />
                    <InputField
                      name="max"
                      label="Máximo de letras"
                      type="number"
                      value={input.max || 0}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ marginBottom: '1em', minWidth: '150px' }}
                    />
                  </>
                )}

                {input.data_type === dataTypeEnum.DATE && (
                  <>
                    <InputField
                      name="min"
                      label="Desde"
                      type="number"
                      value={input.min}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ marginBottom: '1em', minWidth: '150px' }}
                    />
                    <InputField
                      name="max"
                      label="Hasta"
                      type="number"
                      value={input.max}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ marginBottom: '1em', minWidth: '150px' }}
                    />
                  </>
                )}

                {input.data_type === dataTypeEnum.NUMBER && (
                  <>
                    <InputField
                      name="min"
                      label="Número minimo"
                      type="number"
                      value={input.min || 0}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ marginBottom: '1em', minWidth: '150px' }}
                    />
                    <InputField
                      name="max"
                      label="Número máximo"
                      type="number"
                      value={input.max || 0}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ marginBottom: '1em', minWidth: '150px' }}
                    />
                  </>
                )}

                {input.data_type === dataTypeEnum.ARCHIVE && (
                  <InputField
                    name="typeDocument"
                    label="Tipo de documento"
                    value={input.typeDocument}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ marginBottom: '1em', minWidth: '150px' }}
                  />
                )}
                {input.data_type !== dataTypeEnum.BOLEANO ? (
                  <InputField
                    name="defaultValue"
                    label="Predeterminado"
                    value={input.defaultValue}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ marginBottom: '1em', minWidth: '150px' }}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={input.defaultValue}
                        onChange={handleInputChange}
                        name="defaultValue"
                        color="secondary"
                      />
                    }
                    label="Por defecto"
                    sx={{ marginBottom: 1 }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input.required}
                      onChange={handleInputChange}
                      // onChange={(target) => {
                      //   console.log('input.required', input.required);
                      // }}
                      name="required"
                      color="secondary"
                    />
                  }
                  label="¿Requerido?"
                  sx={{ marginBottom: 1 }}
                />
                <Button
                  size="medium"
                  variant="contained"
                  onClick={handleAddItem}
                  disabled={isAddDisabled}
                  startIcon={isEditing ? <CheckIcon /> : <PlusIcon />}
                  sx={{
                    marginTop: 2,
                    maxHeight: '3em'
                  }}
                />
                {/* {isEditing ? 'Editar' : 'Agregar'}
                </Button> */}
                {isEditing && (
                  <Button
                    color="secondary"
                    size="medium"
                    variant="contained"
                    onClick={handleCanceled}
                    startIcon={<XIcon />}
                    sx={{
                      marginTop: 2,
                      maxHeight: '3em'
                    }}
                  />
                )}
                {/* Cancelar
                  </Button> */}
              </Box>
              <Card>
                <Scrollbar>
                  <ImportArgumentsTable
                    onEdit={handleEditItem}
                    onDelete={handleDeleteItem}
                    values={argumentsValue}
                  />
                </Scrollbar>
              </Card>
            </Grid>
          </TabPanel>
          <TabPanel value="link">
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {/* <InputField
                  name="title"
                  label="URL"
                  value={inputLink.title}
                  onChange={handleInputChangeLink}
                  fullWidth
                  sx={{ marginBottom: '1em', minWidth: '150px' }}
                /> */}
                <InputField
                  name="name"
                  label="URL"
                  value={inputLink.name}
                  onChange={handleInputChangeLink}
                  fullWidth
                  sx={{ marginBottom: '1em', minWidth: '150px' }}
                />
                <InputField
                  name="title_download"
                  label="Nombre de descarga"
                  value={inputLink.title_download}
                  onChange={handleInputChangeLink}
                  fullWidth
                  sx={{ marginBottom: '1em', minWidth: '150px' }}
                />
                <InputField
                  name="title"
                  label="Título"
                  value={inputLink.title}
                  onChange={handleInputChangeLink}
                  fullWidth
                  sx={{ marginBottom: '1em', minWidth: '150px' }}
                />
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddItemLink}
                  disabled={isAddDisabledLink}
                  startIcon={isEditingLink ? <CheckIcon /> : <PlusIcon />}
                  sx={{
                    marginTop: 2,
                    maxHeight: '3em'
                  }}
                />
                {/* {isEditingLink ? 'Editar' : 'Agregar'}
                </Button> */}
                {isEditingLink && (
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={handleCanceledLink}
                    startIcon={<XIcon />}
                    sx={{
                      marginTop: 2,
                      maxHeight: '3em'
                    }}
                  />
                  //   Cancelar
                  // </Button>
                )}
              </Box>
              <Card>
                <Scrollbar>
                  <ImportLinksTable
                    onEdit={handleEditItemLink}
                    onDelete={handleDeleteItemLink}
                    values={linkValue}
                  />
                </Scrollbar>
              </Card>
            </Grid>
          </TabPanel>
        </TabContext>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={update ? <PencilIcon /> : <PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

ImportForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  handleRefresh: Proptypes.func
};

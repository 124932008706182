import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { CustomerReturnTable } from './customer-return-table';

export const CustomerReturnLineItems = (props) => {
  const { document } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Líneas" />
      <Divider />
      <CustomerReturnTable document={document} />
    </Card>
  );
};

CustomerReturnLineItems.propTypes = {
  document: PropTypes.object.isRequired
};

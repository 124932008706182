import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from '@mui/material';
import ContestAwardsForm from './contest-awards-form';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import { useApp } from '../../hooks/use-app';

export const ContestAwards = ({ contestIdList = {}, handleRefreshDetail }) => {
  const { currentCompany } = useApp();
  const [configAwards, setConfigAwards] = useState({
    'max-ranges': 3,
    'range-types': []
  });
  const [typeList, setTypeList] = useState([]);
  const [employeePosition, setEmployeePosition] = useState([]);

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [configAwards, typeList, employeePositionList] = await Promise.all([
      goalCustomeContestApi.getConfigAwards(),
      goalCustomeContestApi.getTypeList(),
      goalCustomeContestApi.getEmployeePositions({ companyId: currentCompany })
    ]);
    if (configAwards.success && configAwards.data.length !== 0) {
      setConfigAwards(configAwards.data);
    }
    if (typeList.success && typeList.data.length !== 0) {
      setTypeList(typeList.data);
    }
    if (employeePositionList.success && employeePositionList.data.length !== 0) {
      setEmployeePosition(employeePositionList.data);
    }
  };

  return (
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <ContestAwardsForm
        title=""
        contestState={contestIdList}
        configAwardsState={configAwards}
        typeState={typeList}
        handleRefreshDetail={handleRefreshDetail}
        employeePositionList={employeePosition}
        listAwards={contestIdList.awards}
      />
      <Divider />
    </Card>
  );
};

ContestAwards.propTypes = {
  contestIdList: PropTypes.object,
  handleRefreshDetail: PropTypes.func
};

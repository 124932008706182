import { useCallback, useEffect, useState } from 'react';
// import { format } from 'date-fns';
import { Card } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';

import { financialReportApi } from '../../../api/financial-reports';
import { useMounted } from '../../../hooks/use-mounted';
// import { ConfirmationDialog } from '../../confirmation-dialog';
// import { useApp } from '../../../hooks/use-app';
import { PendingsFaceleTable } from './pendings-table-facele';
import { GoalAccordion } from '../../goal-accordion';

export const SentsFacele = () => {
  //   const { showNotify } = useApp();
  const mounted = useMounted();
  //   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({ reload: true });
  //   const handleOpenAppendDialog = () => {
  //     setOpenConfirmDialog(true);
  //   };
  const handleRefresh = () => {
    setController({ ...controller, reload: true });
  };
  //   const onCancelConfirmationDialog = () => {
  //     setOpenConfirmDialog(false);
  //   };

  //   const processOrderHandler = useCallback(async () => {
  //     try {
  //       const response = await financialReportApi.fixFEPendings();
  //       if (response) showNotify(response.message);
  //     } catch (error) {
  //       console.error(error);
  //       showNotify(error, 'error');
  //     }
  //     setOpenConfirmDialog(false);
  //     handleRefresh();
  //   }, []);

  const getDocument = useCallback(async () => {
    if (controller.reload) {
      setDocumentState(() => ({
        ...documentState,
        isLoading: true,
        message: 'Cargando enviados a Facele'
      }));
      try {
        const result = await financialReportApi.getFESentsFacele();
        if (mounted.current) {
          setDocumentState(() => ({
            ...result,
            isLoading: false,
            reload: false
          }));
        }
      } catch (err) {
        console.error(err);

        if (mounted.current) {
          setDocumentState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <Card variant="outlined">
      <GoalAccordion
        title={'Enviados'}
        onRefresh={documentState.data?.count == 0 ? handleRefresh : null}
        defaultExpanded={true}
      >
        <Scrollbar>
          <PendingsFaceleTable
            data={documentState}
            label="Enviados a Facele"
            onRefresh={handleRefresh}
            //   onFix={handleOpenAppendDialog}
            linkTo={`/accounting/fe/sents-facele`}
          />
          {/* <ConfirmationDialog
          message="Esta seguro de autocorregir los Registros"
          onCancel={onCancelConfirmationDialog}
          onConfirm={processOrderHandler}
          open={openConfirmDialog}
          title="Autoreparar Comprobantes"
          variant="warning"
        /> */}
        </Scrollbar>
      </GoalAccordion>
    </Card>
  );
};

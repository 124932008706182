import PropTypes from 'prop-types';
import { Box, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { LoadingDialog } from '../loading-dialog';
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useApp } from '../../hooks/use-app';

const GoalMenuItem = ({
  startIcon,
  onClick,
  label,
  disabled = false,
  children,
  helpText = '',
  showLoading = true,
  to,
  checked = false
}) => {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const { appUrlBase } = useApp();

  const isFullPath = to && to.startsWith('http');

  const linkTo = isFullPath ? to : `${appUrlBase}${to}`;

  const handleClicked = async () => {
    if (loading) return;
    setLoading(true);
    setIsDisabled(true);
    await onClick();
    setLoading(false);
    setIsDisabled(disabled);
  };

  return (
    <Tooltip title={helpText}>
      {to ? (
        <MenuItem disabled={isDisabled} component={RouterLink} to={linkTo}>
          <>
            {startIcon}
            <Box sx={{ flexGrow: 1, ml: 2, mr: 1 }}>{children || label || '-'}</Box>
            {checked && <CheckIcon />}
          </>
        </MenuItem>
      ) : (
        <MenuItem disabled={isDisabled} onClick={handleClicked}>
          <>
            {startIcon}
            <Box sx={{ flexGrow: 1, ml: 2, mr: 1 }}>
              {loading && 'Loading...'}
              {!loading && (children || label || '-')}
            </Box>
            {checked && <CheckIcon />}
            {showLoading && (
              <LoadingDialog message={'Procesando....'} open={loading} title={label} />
            )}
          </>
        </MenuItem>
      )}
    </Tooltip>
  );
};

GoalMenuItem.propTypes = {
  startIcon: PropTypes.element,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.elementType,
  to: PropTypes.string,
  iconOnly: PropTypes.bool,
  showText: PropTypes.bool,
  helpText: PropTypes.string,
  showLoading: PropTypes.bool,
  checked: PropTypes.bool
};

export default GoalMenuItem;
export { GoalMenuItem };

import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalServicesApi {
  async postImageForm({ values }) {
    // console.log('values', values);
    const response = await dataLoader.loadGoalPost({
      action: '/services/media-server/encoded',
      changes: values
    });
    return response;
  }

  async postDocumentForm(values) {
    const formData = new FormData();

    formData.append('file', values.files[0]);
    formData.append('name', values.files[0].name);
    formData.append('type', values.files[0].type);
    formData.append('application', 'GOAL');

    const response = await dataLoader.loadGoalFormData({
      action: '/services/media-server/save-bulk',
      changes: formData
    });
    return response;
  }
}

export const goalServicesApi = new GoalServicesApi();

export const useBolean = (value) => {
  if (!value) {
    return false;
  }
  return ['y', '1', 'true', 'yes', 'on', 'Y'].includes(value.toString().toLowerCase());
};

export const isValidPositive = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (!value || isNaN(value)) {
    return false;
  }
  return value > 0;
};

import { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Grid, Typography } from '@mui/material';
// import { queueApi } from '../../api/managment/queue';
// import { QueuesFilter } from '../../components/managment/queues/queues-filter';

// import { QueuesStats } from '../../components/managment/queues/queues-stats';
// import { QueuesTable } from '../../components/managment/queues/queues-table';
// // import { useMounted } from '../../hooks/use-mounted';
// // import { useSelection } from '../../hooks/use-selection';
// import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import GadminActions from '../../contexts/gadmin-actions';
import { AllowedGuard } from '../../hooks/use-allowed';
// import { useQuery } from '../../hooks/use-query';
import { ConfirmationDialog } from '../../components/confirmation-dialog';
import { accountingActions } from '../../components/accounting/accounting-actions';
import { financialReportApi } from '../../api/financial-reports';
import { XCircle as TrashIcon } from '../../icons/x-circle';
import { RenderIf } from '../../components/render-if';
import { PendingsFacele } from '../../components/financial/fe-status/pendings-facele';
import { PendingsSAP } from '../../components/financial/fe-status/pendings-sap';
import { SentsFacele } from '../../components/financial/fe-status/sents-facele';
import { GREPendingsFacele } from '../../components/financial/gre-status/gre-pendigs-facele';

// import { useDialog } from '../../hooks/use-dialog';

export const AccountingDashboard = () => {
  const { showNotify } = useApp();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDialogAction, setOpenConfirmDialogAction] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('Confirmar');
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };
  const handleGenerateTargetJournals = async () => {
    setConfirmDialogMessage('¿Está seguro de generar los archivos de asientos de destino?');
    setOpenConfirmDialogAction(accountingActions.GENERATE_TARGET_JOURNALS);
    setOpenConfirmDialog(true);
  };

  const processHandler = useCallback(async () => {
    try {
      let response = '';
      switch (openConfirmDialogAction) {
        case accountingActions.GENERATE_TARGET_JOURNALS:
          response = await financialReportApi.generateTargetJournals();
          break;
        default:
      }
      showNotify(response?.message);
    } catch (error) {
      showNotify(error, 'error');
    }
    setOpenConfirmDialog(false);
  }, [openConfirmDialogAction]);

  const app = useApp();
  return (
    <>
      <Helmet>
        <title>{app.title('Administración')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Dashboard Comprobantes
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </Box>
          <PendingsSAP />
          <PendingsFacele />
          <GREPendingsFacele />
          <SentsFacele />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>

          <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
            <RenderIf condition={1 === 0}>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  color="primary"
                  onClick={handleGenerateTargetJournals}
                  startIcon={<TrashIcon />}
                  size="large"
                  sx={{ order: 3 }}
                  variant="contained"
                >
                  Generar archivos de asientos
                </Button>
              </Grid>
            </RenderIf>
          </AllowedGuard>

          <ConfirmationDialog
            message={confirmDialogMessage}
            onCancel={onCancelConfirmationDialog}
            onConfirm={processHandler}
            open={openConfirmDialog}
            title="Confirmación importante"
            variant="warning"
          />
        </Container>
      </Box>
    </>
  );
};

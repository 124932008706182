import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { useDialog } from '../../../hooks/use-dialog';
import { CustomerReturnLineEdit } from './customer-return-line-edit';
import { NumberColor } from '../../number-color';
import { useApp } from '../../../hooks/use-app';

export const CustomerReturnTable = (props) => {
  const { document } = props;
  const [currentLine, setCurrentLine] = useState({});
  const { appUrlBase } = useApp();

  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const handleRefresh = () => {};
  const handleExitedDialog = () => {};
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell>Unidad</TableCell>
              <TableCell>Cant x Unidad</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Devuelto</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Motivo</TableCell>
              <TableCell>Orígen</TableCell>
              <TableCell>Ver Compras</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {document?.lines?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{numeral(item.lineNum).format('0')}</TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="subtitle2">
                      <Link
                        color="inherit"
                        // component={RouterLink}
                        component="button"
                        onClick={() => {
                          setCurrentLine(item);
                          handleOpenEditDialog();
                        }}
                        underline="hover"
                        variant="subtitle2"
                      >
                        {item.itemCode}
                      </Link>
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.frgItem}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.itemName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.unitMeasure}</TableCell>
                <TableCell>{numeral(item.numPerMeasure).format('0')}</TableCell>
                <TableCell>{numeral(item.openQuantity).format('0.0')}</TableCell>
                <TableCell>
                  <NumberColor value={item.quantity} />
                </TableCell>
                <TableCell>{numeral(item.price).format('0.0000')}</TableCell>
                <TableCell>{numeral(item.gTotal).format('0.00')}</TableCell>
                <TableCell>{item.baseUnit}</TableCell>
                <TableCell>{item.reasonName}</TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="subtitle2">
                      {item.serieBase}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.numberBase}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    underline="hover"
                    variant="subtitle2"
                    to={`${appUrlBase}/busines-partners/customers/${
                      document.cardCode
                    }/product-purchases?item=${item.itemCode}&date=${moment(
                      document.registerDate
                    ).format('YYYY-MM-DD')}`}
                  >
                    ...
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CustomerReturnLineEdit
          onClose={handleCloseEditDialog}
          onExited={handleExitedDialog}
          afterSubmit={handleRefresh}
          open={editDialogOpen}
          lineState={currentLine}
          refreshLine={setCurrentLine}
          document={document}
        />
      </Scrollbar>
    </div>
  );
};

CustomerReturnTable.propTypes = {
  document: PropTypes.object.isRequired
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Query } from '../../query';
import { FilterDialog } from '../../filter-dialog';
// import { CustomerReturnsBulkActionsMenu } from './customer-returns-bulk-actions';
// import GadminActions from '../../../contexts/gadmin-actions';
// import { useApp } from '../../../hooks/use-app';

/**
 * Status
 * D Borrador
 * L Autorizado
 * G Generado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 * E Cancelado
 */

const views = [
  {
    label: 'Mostrar todos',
    value: 'all'
  },
  {
    label: 'Cañete',
    value: 'CN'
  },
  {
    label: 'Chincha',
    value: 'CH'
  },
  {
    label: 'Pisco',
    value: 'PS'
  },
  {
    label: 'Ica',
    value: 'IC'
  },
  {
    label: 'Nasca',
    value: 'NZ'
  },
  {
    label: 'Ayacucho',
    value: 'AY'
  },
  {
    label: 'Andahuaylas',
    value: 'AN'
  }
];

const filterProperties = [
  {
    label: 'Fecha',
    name: 'fecha',
    type: 'date'
  },
  {
    label: 'Codigo JR',
    name: 'cardcode',
    type: 'string'
  },
  {
    label: 'Jefe de reparto',
    name: 'cardname',
    type: 'string'
  },
  {
    label: 'Placa',
    name: 'placa',
    type: 'string'
  },
  {
    label: 'Código Zona',
    name: 'sede',
    type: 'string'
  },
  {
    label: 'División',
    name: 'tiporeparto',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const AuxiliariesCurrentFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = 'all'
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  //   const [menuActions, setMenuActions] = useState([]);
  //   const { showNotify } = useApp();

  //   useEffect(() => {
  //     setMenuActions([]);
  //     if (['D'].includes(view)) {
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_AUTH]);
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_CANCEL]);
  //     }
  //     if (['L'].includes(view)) {
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_GEN]);
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_CANCEL]);
  //     }
  //     if (['G'].includes(view)) {
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_PROGRAM]);
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_CANCEL]);
  //     }
  //     if (['W'].includes(view)) {
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_LIQ]);
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_CANCEL]);
  //     }
  //     if (['E'].includes(view)) {
  //       setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_REACTIVE]);
  //     }
  //   }, [view]);

  //   const hanudleProcess = async (action, items) => {
  //     try {
  //       const response = await customerReturnsApi.processCustomerReturn({ action, items });
  //       const { count } = response;
  //       if (count !== selectedDocuments.length) {
  //         showNotify(`procesado  ${response?.count}. Algunos registros no se procesaron `, 'warning');
  //       } else showNotify(`procesado  ${count}`);
  //     } catch (error) {
  //       showNotify(`Error al procesar  ${error}`, 'error');
  //       console.error(error);
  //     }
  //   };

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          {/* <CustomerReturnsBulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            onProgram={async () => {
              await handleProcess(GadminActions.G2_CD_PROGRAM, selectedDocuments);
            }}
            onCancel={async () => {
              await handleProcess(GadminActions.G2_CD_CANCEL, selectedDocuments);
            }}
            onAuthorize={async () => {
              await handleProcess(GadminActions.G2_CD_AUTH, selectedDocuments);
            }}
            onGenerate={async () => {
              await handleProcess(GadminActions.G2_CD_GEN, selectedDocuments);
            }}
            onLiquidate={async () => {
              await handleProcess(GadminActions.G2_CD_LIQ, selectedDocuments);
            }}
            onReactive={async () => {
              await handleProcess(GadminActions.G2_CD_REACTIVE, selectedDocuments);
            }}
            selectedCount={selectedDocuments.length}
            sx={{
              display: selectedDocuments.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          /> */}
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

AuxiliariesCurrentFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.string
};

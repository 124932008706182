import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'column',
    flexFlow: 'row wrap',
    marginTop: 2
  },
  companyContainer: {
    flexDirection: 'row',
    marginTop: 20
  },
  reportCompany: {
    color: '#000',
    letterSpacing: 1,
    fontSize: 9,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  reportTitle: {
    color: '#000',
    letterSpacing: 1,
    fontSize: 10,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
});
const CustomerReturnTitle = ({ title }) => (
  <>
    <View style={styles.titleContainer}>
      <Text style={styles.reportCompany}>DS ROMA SAC</Text>
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>{title}</Text>
    </View>
  </>
);

CustomerReturnTitle.propTypes = {
  title: PropTypes.string
};

export default CustomerReturnTitle;

// routes
import { useKeycloak } from '@react-keycloak/web';
import Router from './routes';
import './styles/App.css';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App() {
  const { initialized } = useKeycloak();

  const content = Router();

  return (
    <ThemeConfig>
      <ScrollToTop />
      {initialized && content}
    </ThemeConfig>
  );
}

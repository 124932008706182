import usergroupFilled from '@iconify/icons-ant-design/usergroup-add';
// material
import { Box, Button, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { dashboardApi } from '../../../api/dashboard';
import LoadingContent from '../../common/loading-content';
import { useData } from '../../../hooks/use-data';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { useMounted } from '../../../hooks/use-mounted';
import { Widget } from '../../common/widget';
import { IconWrapper } from '../../common/icon-wrapper';

export default function CustomersStatics() {
  const mounted = useMounted();

  const [openCustomersStatics, handleRefresh] = useData({
    sourceApi: dashboardApi.getCustomerStatics,
    loadingMessage: 'Cargando clientes nuevos',
    mounted
  });

  if (openCustomersStatics.isLoading || openCustomersStatics.error || !openCustomersStatics.data) {
    return (
      <LoadingContent
        loadingText={openCustomersStatics.message}
        error={openCustomersStatics.error}
      />
    );
  }

  return (
    <Widget>
      <Box
        md={6}
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          }
        }}
      >
        <IconWrapper icon={usergroupFilled} />
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Clientes nuevos
        </Typography>
        {/* <CardHeader title="Clientes nuevos" /> */}
        <Button
          color="primary"
          size="medium"
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          variant="text"
        />
      </Box>

      <Typography variant="h5">{fShortenNumber(openCustomersStatics.data?.all || 0)}</Typography>
      <Typography variant="h5">
        {fShortenNumber(openCustomersStatics.data?.current || 0)}
      </Typography>
    </Widget>
  );
}

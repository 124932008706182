import { useParams } from 'react-router-dom';

import { Download as DownloadIcon } from '../../icons/download';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { Box, Container, Divider, Typography } from '@mui/material';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import { ContestQuota } from './contest-quota';
import LoadingContent from '../common/loading-content';
import { ContestQuotaSeller } from './contest-quota-seller';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { useDownloadFile } from '../../hooks/use-download-file';
import { useApp } from '../../hooks/use-app';
import GoalButton from '../goal-button';
import { GoalUploadButton } from '../goal-upload-button';

export const ContestContainerQuota = () => {
  const { id } = useParams();

  const mounted = useMounted();
  const { currentCompany, showHelp, showNotify, showError } = useApp();
  const [detailQuotaState, handleRefreshDetail] = useData({
    sourceApi: goalCustomeContestApi.getContestQuotas,
    apiParameter: { id, companyId: currentCompany },
    loadingMessage: 'Cargando informacion del concurso',
    mounted
  });

  const { download } = useDownloadFile({
    apiDefinition: goalCustomeContestApi.getContestQuotasDownload,
    apiParameters: { id, companyId: currentCompany }
  });

  const handleUpload = async (values, uploaded) => {
    const response = await goalCustomeContestApi.postContestQuotasUpload({
      id,
      companyId: currentCompany,
      company_id: currentCompany,
      ...uploaded
    });
    if (response.success) {
      showNotify(response.message ?? 'Archivo subido correctamente');
      handleRefreshDetail();
    } else {
      showError(response.message ?? 'Error al subir el archivo');
    }
  };

  if (detailQuotaState.isLoading || detailQuotaState.error) {
    return <LoadingContent loadingText={detailQuotaState.message} error={detailQuotaState.error} />;
  }

  if (!detailQuotaState.success) {
    return (
      <LoadingContent loadingText={detailQuotaState.message} error={detailQuotaState.message} />
    );
  }

  if (detailQuotaState.data === null || detailQuotaState.data.length === 0) {
    return <LoadingContent loadingText="No hay datos" error={detailQuotaState.error} />;
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1,
        marginBottom: 3
      }}
    >
      <Container
        maxWidth="zl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              {showHelp ? 'Cuotas' : ''}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <GoalButton startIcon={<DownloadIcon />} onClick={download} label="Descargar" />
            <GoalUploadButton
              loading={detailQuotaState.isLoading}
              disabled={false}
              onUpoload={handleUpload}
              documentName={detailQuotaState.data?.name || 'Consurso'}
              label="Subir"
            />
            <GoalButton
              startIcon={<RefreshIcon />}
              loading={detailQuotaState.isLoading}
              onClick={() => handleRefreshDetail()}
              label="Refrescar"
            />
          </Box>
        </Box>
        {detailQuotaState?.data.quata_by === 'P' && (
          <ContestQuota
            contestIdList={detailQuotaState?.data}
            handleRefreshDetail={() => handleRefreshDetail(id)}
          />
        )}
        {detailQuotaState?.data.quata_by === 'SE' && (
          <ContestQuotaSeller quotas={detailQuotaState.data?.quotas} />
        )}
        <Divider />
      </Container>
    </Box>
  );
};

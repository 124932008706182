import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { Cloud as InfoIcon } from '../icons/cloud';
import { useEffect, useState } from 'react';

const icons = {
  info: <InfoIcon color="info" />
};

export const LoadingDialog = (props) => {
  const { message = 'Esta acción no se puede deshacer', title, loading, ...other } = props;

  const [open, setOpen] = useState(true);

  const handleClose = async () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!loading) {
      handleClose();
    }
  }, [loading]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {icons['info']}
        <Typography color="inherit" sx={{ ml: 2 }} variant="inherit">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          minHeight: '150px' // Optional: to ensure some height for centering
        }}
      >
        <Typography color="textPrimary" variant="body1">
          {message}
        </Typography>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

LoadingDialog.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'warning', 'info']),
  loading: PropTypes.bool,
  setLoading: PropTypes.func
};

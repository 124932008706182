export const equalOperator = {
  label: 'igual a',
  typesAccepted: ['number', 'string'],
  value: 'equal'
};

export const notEqualOperator = {
  label: 'diferente a',
  typesAccepted: ['number', 'string'],
  value: 'notEqual'
};

export const containsOperator = {
  label: 'contiene',
  typesAccepted: ['string'],
  value: 'contains'
};

export const notContainsOperator = {
  label: 'no contiene',
  typesAccepted: ['string'],
  value: 'notContains'
};

export const startsWithOperator = {
  label: 'empieza con',
  typesAccepted: ['string'],
  value: 'startsWith'
};

export const endsWithOperator = {
  label: 'termina en',
  typesAccepted: ['string'],
  value: 'endsWith'
};

export const greaterThanOperator = {
  label: 'mayor que',
  typesAccepted: ['number'],
  value: 'greaterThan'
};

export const lessThanOperator = {
  label: 'menor que',
  typesAccepted: ['number'],
  value: 'lessThan'
};

export const isAfterOperator = {
  label: 'Después de',
  typesAccepted: ['date'],
  value: 'isAfter'
};

export const isBeforeOperator = {
  label: 'antes de',
  typesAccepted: ['date'],
  value: 'isBefore'
};

export const isBlankOperator = {
  label: 'vacío',
  typesAccepted: ['blank'],
  value: 'isBlank'
};

export const isPresentOperator = {
  label: 'no vacío',
  typesAccepted: ['blank'],
  value: 'isPresent'
};

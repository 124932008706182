import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import numeral from 'numeral';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';

export const PromoAssignedEvalsTable = (props) => {
  const { promoAssigned } = props;
  if (!promoAssigned.evaluations) return <LoadingContent loadingText="No hay información" />;

  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Promoción</TableCell>
              <TableCell>Linea</TableCell>
              <TableCell>Tipo Minimo</TableCell>
              <TableCell>Valor Mínimo</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Cant Promo</TableCell>
              <TableCell>Precio Base</TableCell>
              <TableCell>Descuento</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Sub Total</TableCell>
              <TableCell>Igv</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promoAssigned.evaluations?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.scope}</TableCell>
                <TableCell>{numeral(item.promoId).format('0')}</TableCell>
                <TableCell>{item.lineId}</TableCell>
                <TableCell>{item.minType}</TableCell>
                <TableCell>{item.minValue}</TableCell>
                <TableCell>{item.itemCode}</TableCell>
                <TableCell>{numeral(item.ordered).format('0.0')}</TableCell>
                <TableCell>{numeral(item.orderedValue).format('0.0')}</TableCell>
                <TableCell>{numeral(item.promoId).format('0.0')}</TableCell>
                <TableCell>{numeral(item.priceBefDi).format('0.00')}</TableCell>
                <TableCell>{numeral(item.discPrcnt).format('0.00')}</TableCell>
                <TableCell>{numeral(item.price).format('0.00')}</TableCell>
                <TableCell>{numeral(item.lineTotal).format('0.00')}</TableCell>
                <TableCell>{numeral(item.vatSum).format('0.00')}</TableCell>
                <TableCell>{numeral(item.gTotal).format('0.00')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

PromoAssignedEvalsTable.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};

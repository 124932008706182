import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalSalesTeamsApi {
  async getSalesTeamsList(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, id } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/sales/master/sales-team?companyId=${id}`,
      cacheItem: 'sales-teams-list',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'statusName' },
        { type: 'string', name: 'company_name' },
        { type: 'string', name: 'type_name' },
        { type: 'string', name: 'type_source_name' },
        { type: 'string', name: 'period_name' },
        { type: 'string', name: 'measure_name' },
        { type: 'number', name: 'min_coverage' },
        { type: 'number', name: 'min_customer' }
      ],
      ...options
    });
  }

  async getSalesTeamsListId(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, id } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/sales/master/sales-team/${id}`,
      cacheItem: 'sales-teams-list',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'statusName' },
        { type: 'string', name: 'company_name' },
        { type: 'string', name: 'type_name' },
        { type: 'string', name: 'type_source_name' },
        { type: 'string', name: 'period_name' },
        { type: 'string', name: 'measure_name' },
        { type: 'number', name: 'min_coverage' },
        { type: 'number', name: 'min_customer' }
      ],
      ...options
    });
  }

  // get team by id
  async getSalesTeamsId(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/sales/master/sales-team/${id}`
    });
    return response;
  }

  // upload image
  async postSalesTeamImage({ id, companyId, ...values }) {
    const response = await dataLoader.loadGoalPost({
      action: `/sales/master/sales-team/${id}/upload-image?companyId=${companyId}`,
      changes: values
    });
    return response;
  }

  async postSalesTeamsForm({ values, companyId }) {
    const response = await dataLoader.loadGoalPost({
      action: `/sales/master/sales-team?companyId=${companyId}`,
      changes: values
    });
    return response;
  }

  async updateSalesTeamsForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/sales/master/sales-team/${id}`,
      changes: data
    });
    // console.log(data);
    return response;
  }

  async getSalesTeamsConfig(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/sales/master/sales-team/config?companyId=${id}`
    });
    return response;
  }

  async getManagerResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/divisions?companyId=${companyId}`
    });
    return response;
  }

  async getCustomerChannelsResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/divisions?companyId=${companyId}`
    });
    return response;
  }

  async getSimpleListWithLikeFilter(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      companyId,
      searchText,
      idsToExclude = ''
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `sales/master/sales-team/simple-list-with-like-filter`,
      cacheItem: 'business-partners',
      apiParams: {
        companyId,
        idsToExclude,
        type: 'list-business-partners',
        view,
        searchText,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'full_name' }
      ],
      ...options
    });
  }

  async getSalesTeamBySupplier({ companyId, supplierId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/sales/master/sales-team/list-by-supplier?companyId=${companyId}&supplier_id=${supplierId}`
    });
    return response;
  }
}

export const goalSalesTeamsApi = new GoalSalesTeamsApi();

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { useDialog } from '../../../hooks/use-dialog';
import { DocumetLogDialog } from '../../common/document-log-dialog';
import { PDFViewerdDialog } from '../../common/pdf-viewer-dialog';
import { XMLViewerdDialog } from '../../common/xml-viewer-dialog';

// import { invoiceApi } from '../../../api/invoice';

// eslint-disable-next-line no-unused-vars
import { documentViews, mediaType } from '../../../api/purchase-definitions';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { RenderIf } from '../../render-if';
import { Eye as EyeIcon } from '../../../icons/eye';
import { JournalPrintingDialog } from '../../purchases/printing/journal-printing-dialog';
import { FePendingsView } from '../../../api/financial-definitions';
import { financialReportApi } from '../../../api/financial-reports';

export const PendingsMenu = (props) => {
  const { invoice, detailPath, apiHandler, afterProcessHandler, containerView } = props;
  const navigate = useNavigate();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const [pdfViewerDialogOpen, handleOpenPdfViewerDialog, handleClosePdfViewerDialog] = useDialog();
  const [xmlViewerDialogOpen, handleOpenXmlViewerDialog, handleCloseXmlViewerDialog] = useDialog();
  const [
    journalPrintingDialogOpen,
    handleOpenJournalPrintingDialog,
    handleCloseJournalPrintingDialog
  ] = useDialog();
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { showNotify, showError } = useApp();
  const [menuActions, setMenuActions] = useState([]);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    handleClose();
  };
  const detailPathWithId = `${detailPath}/${invoice?.transid}`;
  const currentPath = useLocation().pathname;
  const isCurrentPath = currentPath === detailPathWithId;

  const handleAfterProcess = () => {
    afterProcessHandler?.();
  };

  const handleExitedDialog = () => {};

  const handleEdit = () => {
    handleClose();
    navigate(detailPathWithId);
  };
  const handleReport = () => {
    handleClose();
    handleOpenPdfViewerDialog();
  };
  const handleXml = () => {
    handleClose();
    handleOpenXmlViewerDialog();
  };

  // const handleRemoveFacele = async () => {
  //   const response = await financialReportApi.faceleRemove({
  //     objType: invoice.objtype,
  //     docEntry: invoice.docentry
  //   });
  //   if (response.success) {
  //     showNotify(response.message);
  //     handleAfterProcess();
  //   } else {
  //     showError(response.message);
  //   }
  // };
  //         // $request["ClientId"], $request["ObjType"], $request["DocEntry"]

  const handleCheckCPE = async () => {
    const response = await financialReportApi.validateCPE({
      ClientId: invoice.clientid,
      ObjType: invoice.objtype,
      DocEntry: invoice.docentry || 0
    });
    if (response.success) {
      showNotify(response.message);
      handleAfterProcess();
    } else {
      showError(response.message);
    }
  };

  const handleShowLog = () => {
    handleClose();
    handleOpenEditDialog();
  };
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
    handleClose();
  };

  const onConfirmConfirmationDialog = async () => {
    try {
      const response = await currentMenuItem.apiHandler({
        type: currentMenuItem.action,
        ...currentMenuItem.apiParameters
      });
      if (response.success) {
        showNotify(response.message);
        handleAfterProcess();
      } else {
        showError(`${response?.message}. Algunos registros no se procesaron `);
      }
    } catch (error) {
      showNotify(`Error al procesar  ${error}`, 'error');
      console.error(error);
    }
    handleCloseDialog();
  };

  const handleOpenConfirmationDialog = (menuItem) => {
    setCurrentMenuItem(menuItem);
    setConfirmDialogMessage(menuItem.prompt);
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    const actions = [];

    if ([FePendingsView.VIEW_PENDING_FACELE].includes(containerView)) {
      actions.push({
        permission: GadminActions.gCC_FACELE_SEND,
        apiHandler: financialReportApi.faceleRemove,
        apiParameters: {
          objType: invoice.objtype,
          docEntry: invoice.docentry
        },
        label: 'Reset  FE',
        prompt: '¿Está seguro que desea reenviar el comprobante?'
      });
    }

    setMenuActions(actions);
  }, [invoice.status]);

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <DotsVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <RenderIf condition={!isCurrentPath}>
          <MenuItem onClick={handleEdit}>
            <EyeIcon />
            Detalle
          </MenuItem>
        </RenderIf>
        <MenuItem onClick={handleShowLog}>Sucesos</MenuItem>
        <RenderIf condition={containerView === FePendingsView.VIEW_PENDING_FACELE}>
          {/* <MenuItem onClick={handleRemoveFacele}>Reenviar Facele</MenuItem> */}
        </RenderIf>
        <AllowedGuard permission={GadminActions.gCC_DET_ORDER_FAC}>
          <MenuItem onClick={handleReport}>PDF</MenuItem>
          <MenuItem onClick={handleXml}>XML</MenuItem>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_ACCT_JOURNAL_VIEW}>
          <RenderIf condition={(invoice?.transid || -1) !== -1}>
            <MenuItem onClick={handleOpenJournalPrintingDialog}>Asiento</MenuItem>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_GPRINT_VALIDATE_RECEIPT}>
          <MenuItem onClick={handleCheckCPE}>Validar en SUNAT</MenuItem>
        </AllowedGuard>
        {menuActions.map((item, index) => (
          <AllowedGuard permission={item.permission || item.action} key={index}>
            <MenuItem
              key={index}
              onClick={() => {
                handleOpenConfirmationDialog(item);
              }}
            >{`${item.label}`}</MenuItem>
          </AllowedGuard>
        ))}
      </Menu>
      <PDFViewerdDialog
        onClose={handleClosePdfViewerDialog}
        open={pdfViewerDialogOpen}
        apiHandle={apiHandler.getInvoiceMedia}
        apiParams={{ objType: invoice.objType, docEntry: invoice.docEntry, type: mediaType.PDF }}
      />
      <XMLViewerdDialog
        onClose={handleCloseXmlViewerDialog}
        open={xmlViewerDialogOpen}
        apiHandle={apiHandler.getInvoiceMedia}
        apiParams={{ objType: invoice.objType, docEntry: invoice.docEntry, type: mediaType.XML }}
      />
      <DocumetLogDialog
        onClose={handleCloseEditDialog}
        onExited={handleExitedDialog}
        open={editDialogOpen}
        objType={invoice.objtype || 0}
        docEntry={invoice.docentry || 0}
      />
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={onCancelConfirmationDialog}
        onConfirm={onConfirmConfirmationDialog}
        open={openConfirmDialog}
        title="Confirmación importante"
        variant="warning"
      />
      <JournalPrintingDialog
        onClose={handleCloseJournalPrintingDialog}
        open={journalPrintingDialogOpen}
        entity={{
          objType: invoice?.objtype,
          docEntry: invoice?.docentry,
          transId: invoice?.transid
        }}
        apiHandler={financialReportApi.journalDetail}
        apiParameter={invoice?.transid}
      />
    </>
  );
};

PendingsMenu.propTypes = {
  invoice: PropTypes.object,
  detailPath: PropTypes.string.isRequired,
  apiHandler: PropTypes.object.isRequired,
  afterProcessHandler: PropTypes.func,
  containerView: PropTypes.string.isRequired
};

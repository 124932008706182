import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
import { purchaseOptions } from './purchase-definitions';
/* eslint class-methods-use-this:0 */
class PurchaseHelperApi {
  async getServiceBusinessPartnerAccounts(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_OPTS, {
      type: purchaseOptions.BP_ACCOUNT_LIST,
      payload
    });
    return response;
  }

  async getServiceAccounts(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_OPTS, {
      type: purchaseOptions.ACCOUNT_LIST,
      payload
    });
    return response;
  }

  async getServiceCostingCode(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_OPTS, {
      type: purchaseOptions.COSTING_CODE_LIST,
      payload
    });
    return response;
  }

  async getServiceTaxList(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_OPTS, {
      type: purchaseOptions.TAX_CODE_LIST,
      payload
    });
    return response;
  }

  async getServiceBrachs(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_OPTS, {
      type: purchaseOptions.BRANCH_LIST,
      payload
    });
    return response;
  }

  async getServicePaymentTerm(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_OPTS, {
      type: purchaseOptions.PAYMENT_TERM_LIST,
      payload
    });
    return response;
  }

  async getSedesConfig(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_OPTS, {
      type: purchaseOptions.SEDE_CONFIG,
      payload
    });
    return response;
  }
}

export const purchaseHelperApi = new PurchaseHelperApi();

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
import { customeOrderApi } from '../../../api/customer-order';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Query } from '../../query';
import { FilterDialog } from '../../filter-dialog';
import {
  ordersView,
  OrderViews as views,
  OrderFilterProperties as filterProperties,
  orderBulkActions
} from '../../../api/customer-order-definitions';
import { BulkActionsMenu } from '../../bulk-actions-menu';
import GadminActions from '../../../contexts/gadmin-actions';

// import { CustomerReturnsBulkActionsMenu } from './customer-returns-bulk-actions';
// import GadminActions from '../../../contexts/gadmin-actions';
// import { useApp } from '../../../hooks/use-app';

/**
 * Status
 * D Borrador
 * L Autorizado
 * G Generado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 * E Cancelado
 */

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const CustomerOrdersFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = 'all',
    onRefresh
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [menuActions, setMenuActions] = useState([]);
  const afterProcessHandler = () => {
    // if (result) {
    //   if (menuItem.action === DeliveryVehicleActions.CANCEL) {
    //     onFiltersApply();
    //   }
    // }
    // }
    onRefresh?.();
  };

  useEffect(() => {
    const actions = [];

    if (
      [
        ordersView.VIEW_DRAFT,
        ordersView.VIEW_AUTHORIZED,
        ordersView.VIEW_PENDING,
        ordersView.VIEW_SAP_ORDER
      ].includes(view)
    ) {
      actions.push({
        action: orderBulkActions.CANCEL,
        permission: GadminActions.gCC_SAVE_ANULAR_PEDIDO_RET,
        label: 'Anular',
        prompt: '¿Está seguro que desea anular lod documentos seleccionados?'
      });
    }

    // if ([ordersView.VIEW_DRAFT].includes(view)) {
    //   actions.push({
    //     action: GadminActions.gCC_PURCHASE_FFEE_CAB_VAL,
    //     label: 'Validar',
    //     prompt: '¿Está seguro que desea validar los documentos seleccionados?'
    //   });
    // }
    // if ([ordersView.VIEW_CANCELED].includes(view)) {
    //   actions.push({
    //     action: GadminActions.G2_PURCHASE_REACTIVE,
    //     label: 'Reactivar',
    //     prompt: '¿Está seguro que desea reactivar los documentos seleccionados?'
    //   });
    // }
    // if ([ordersView.VIEW_VALIDATED].includes(view)) {
    //   actions = [
    //     ...actions,
    //     {
    //       action: GadminActions.gCC_PURCHASE_FFEE_CONFIRM,
    //       label: 'Confirmar',
    //       prompt: '¿Está seguro que desea confirmar los documentos seleccionados?'
    //     },
    //     {
    //       action: GadminActions.gCC_PURCHASE_FFEE_NOCONFIRM,
    //       label: 'NO Confirmar',
    //       prompt: '¿Está seguro que desea NO confirmar los documentos seleccionados?'
    //     }
    //   ];
    // }
    // if ([ordersView.VIEW_PROCESING].includes(view)) {
    //   actions = [
    //     ...actions,
    //     {
    //       action: GadminActions.gCC_GPROC_QUEUE_UP,
    //       label: 'Encolar',
    //       prompt: '¿Está seguro que desea re-encolar los documentos seleccionados?'
    //     }
    //   ];
    // }
    setMenuActions(actions);
  }, [view]);

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <BulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            selectedItems={selectedDocuments}
            selectedCount={selectedDocuments.length}
            afterProcessHandler={afterProcessHandler}
            apiHandler={customeOrderApi.processOrderBulk}
            sx={{
              display: selectedDocuments.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          />
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

CustomerOrdersFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.string,
  onRefresh: PropTypes.func
};

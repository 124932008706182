import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { NumberColor } from '../../number-color';

export const CustomerReturnSourceTable = (props) => {
  const { document } = props;
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell>Unidad</TableCell>
              <TableCell>Cant x Unidad</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Devuelto</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Motivo</TableCell>
              <TableCell>Orígen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {document?.source?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{numeral(item.lineNum).format('0')}</TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="subtitle2">
                      {item.itemCode}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.frgItem}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.itemName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.unitMeasure}</TableCell>
                <TableCell>{numeral(item.numPerMeasure).format('0')}</TableCell>
                <TableCell>{numeral(item.openQuantity).format('0.0')}</TableCell>
                <TableCell>
                  <NumberColor value={item.quantity} />
                </TableCell>
                <TableCell>{numeral(item.price).format('0.0000')}</TableCell>
                <TableCell>{numeral(item.gTotal).format('0.00')}</TableCell>
                <TableCell>{item.baseUnit}</TableCell>
                <TableCell>{item.reasonName}</TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="subtitle2">
                      {item.serieBase}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.numberBase}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

CustomerReturnSourceTable.propTypes = {
  document: PropTypes.object.isRequired
};

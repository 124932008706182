import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number
};
export default function Logo({ minWidth = 120, minHeight = 60, sx }) {
  // return <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />;
  return (
    <Box
      component="img"
      src="/static/goal.svg"
      sx={{ width: minWidth, height: minHeight, ...sx }}
    />
  );
}

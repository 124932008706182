import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
// import { customerReturnsApi } from '../../../api/customer-return';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Query } from '../../query';
import { FilterDialog } from '../../filter-dialog';
// import { CustomerReturnsBulkActionsMenu } from './customer-returns-bulk-actions';
// import GadminActions from '../../../contexts/gadmin-actions';
// import { useApp } from '../../../hooks/use-app';

const views = [
  {
    label: 'Mostrar todos',
    value: 'all'
  }
];

const filterProperties = [
  {
    label: 'Proveedor',
    name: 'CardName',
    type: 'string'
  },
  {
    label: 'Producto',
    name: 'ItemName',
    type: 'string'
  },
  {
    label: 'Codigo Producto',
    name: 'ItemCode',
    type: 'string'
  },
  {
    label: 'Línea',
    name: 'lineName',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const CustomerReturnsProductsFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    query,
    selectedProducts = [],
    view = 'all'
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  // const [menuActions, setMenuActions] = useState([]);
  // const { showNotify } = useApp();
  // useEffect(() => {
  //   setMenuActions([]);
  //   if (['D'].includes(view)) {
  //     setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_AUTH]);
  //     setMenuActions((menuActions) => [...menuActions, GadminActions.G2_CD_CANCEL]);
  //   }
  // }, [view]);

  // const handleProcess = async (action, items) => {
  //   try {
  //     const response = await customerReturnsApi.processCustomerReturn({ action, items });
  //     const { count } = response;
  //     if (count !== selectedDocuments.length) {
  //       showNotify(`procesado  ${response?.count}. Algunos registros no se procesaron `, 'warning');
  //     } else showNotify(`procesado  ${count}`);
  //   } catch (error) {
  //     showNotify(`Error al procesar  ${error}`, 'error');
  //     console.error(error);
  //   }
  // };

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedProducts.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          {/* <CustomerReturnsBulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            onProgram={async () => {
              await handleProcess(GadminActions.G2_CD_PROGRAM, selectedDocuments);
            }}
            onCancel={async () => {
              await handleProcess(GadminActions.G2_CD_CANCEL, selectedDocuments);
            }}
            onAuthorize={async () => {
              await handleProcess(GadminActions.G2_CD_AUTH, selectedDocuments);
            }}
            onGenerate={async () => {
              await handleProcess(GadminActions.G2_CD_GEN, selectedDocuments);
            }}
            onLiquidate={async () => {
              await handleProcess(GadminActions.G2_CD_LIQ, selectedDocuments);
            }}
            onReactive={async () => {
              await handleProcess(GadminActions.G2_CD_REACTIVE, selectedDocuments);
            }}
            selectedCount={selectedDocuments.length}
            sx={{
              display: selectedDocuments.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          /> */}

          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

CustomerReturnsProductsFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedProducts: PropTypes.array,
  view: PropTypes.string
};

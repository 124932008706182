// AB	ABANCAY
// AN	ANDAHUAYLAS
// AP	AYACUCHO PROVINCIAS
// AV	AYACUCHO VRAEM
// AY	AYACUCHO
// CH	CHINCHA
// CN	CAÑETE
// IC	ICA
// LI	LIMA
// NZ	NAZCA
// PS	PISCO

const PENDING = 'PE';
const COMPLETE = 'CO';
const PARTIAL = 'PA';
const CANCLED = 'CA';

export const MonitoringViews = [
  {
    label: 'Todos',
    value: 'all'
  },
  {
    label: 'Cañete',
    value: 'CN'
  },
  {
    label: 'Chincha',
    value: 'CH'
  },
  {
    label: 'Pisco',
    value: 'PS'
  },
  {
    label: 'Ica',
    value: 'IC'
  },
  //   {
  //     label: 'Lima',
  //     value: 'LI'
  //   },
  {
    label: 'Nazca',
    value: 'NZ'
  },
  {
    label: 'Ayacucho',
    value: 'AY'
  },
  {
    label: 'Ayacucho Provincia',
    value: 'AP'
  },

  {
    label: 'Ayacucho Vraem',
    value: 'AV'
  },

  //   {
  //     label: 'ABANCAY',
  //     value: 'AB'
  //   },
  {
    label: 'Andahuaylas',
    value: 'AN'
  }
];

export const DocumentsViews = [
  {
    label: 'Todos',
    value: 'all'
  },
  {
    label: 'Entregados',
    value: COMPLETE
  },
  {
    label: 'Anulados',
    value: CANCLED
  },
  {
    label: 'Parciales',
    value: PARTIAL
  },
  {
    label: 'Pendientes',
    value: PENDING
  }
];

export const SheetsViews = [
  {
    label: 'Todos',
    value: 'all'
  },
  {
    label: 'Liquidados',
    value: 'O'
  },
  {
    label: 'Pendientes',
    value: 'C'
  }
];

export const TeamsViews = [
  {
    label: 'Todos',
    value: 'all'
  },
  {
    label: 'Internos',
    value: 'I'
  },
  {
    label: 'Externos',
    value: 'E'
  }
];

import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Box
} from '@mui/material';
import moment from 'moment';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { TableSearchBar } from '../../TableSearchBar';
import { useEffect, useState } from 'react';

export const DocumentLogTable = ({ documentState }) => {
  const { displayLoading, displayError, displayUnavailable } = documentState;
  const [lines, setLines] = useState(documentState.data);
  const [allLines, setAllLines] = useState(documentState.data);

  useEffect(() => {
    setLines(documentState.data);
    setAllLines(documentState.data);
  }, [documentState.data]);

  return (
    <Card variant="outlined">
      <TableSearchBar lines={allLines} setLines={setLines} searchFields={['message']} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',

          maxHeight: 500,
          overflowY: 'auto'
        }}
      >
        <Scrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell sx={{ minWidth: 300 }}>Mensaje</TableCell>
                <TableCell>Fecha y hora</TableCell>
                <TableCell>Usuario</TableCell>
                <TableCell>Aplicación</TableCell>
                <TableCell>Instancia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lines?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.docentry}</TableCell>
                  <TableCell>{item.message}</TableCell>
                  <TableCell>{moment(item.createdate).format('HH:mm:ss DD/MM/YYYY')}</TableCell>
                  <TableCell>{item.createuser}</TableCell>
                  <TableCell>{item.createprog}</TableCell>
                  <TableCell>{item.memo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
        {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={documentState.error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {displayUnavailable && (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        <Divider sx={{ mt: 'auto' }} />
      </Box>
    </Card>
  );
};
// prop type validation
DocumentLogTable.propTypes = {
  documentState: PropTypes.object.isRequired
};

import { useEffect } from 'react';
// material
import { Card, Stack, Container, Typography, TableContainer } from '@mui/material';
import { useLocation } from 'react-router-dom';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

import { useQuery } from '../hooks/use-query';
import { useDataBrowser } from '../hooks/use-data-browser';

import { useMounted } from '../hooks/use-mounted';
import { SearchTable } from '../components/common/search-table';
import { SearchFilter } from '../components/common/search-filter';
import { goalSearchApi } from '../api/goal/search/goal-search';

export default function Search() {
  const mounted = useMounted();
  const query = useQuery();
  const location = useLocation();

  const params = location.search;

  const paramsArray = params.split('&');
  const paramsApi = {};
  paramsArray.forEach((param) => {
    const [key, value] = param.split('=');
    paramsApi[key] = value;
  });

  const companyId = paramsApi?.companyId;
  const entityId = paramsApi?.entityId;

  const queried = {
    query: query.get('query') || '',
    entityId: query.get('entityId') || 0,
    uuid: query.get('uuid') || '0'
  };
  const [
    documentState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalSearchApi.getSearchItems2,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      companyId,
      searchText: {
        companyId,
        query: queried.query,
        entityId
      }
    }
    // onPostRefresh: handleClearSelected
  });

  useEffect(() => {
    if (queried.query)
      handleSetController.setSearchText({ companyId, query: queried.query, entityId });
  }, [queried.query, companyId, entityId, queried.entityId, queried.uuid]);

  return (
    <Page title="Búsqueda | GOAL">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Buscando : {queried.query && `: ${queried.query}`}
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <SearchFilter
                disabled={documentState.isLoading}
                filters={controller.filters}
                onFiltersApply={handleFiltersApply}
                onFiltersClear={handleFiltersClear}
                onQueryChange={handleQueryChange}
                onViewChange={handleViewChange}
                onRefresh={handleRefresh}
                query={controller.query}
                view={controller.view}
              />
              <SearchTable
                error={documentState.error}
                items={documentState.documents}
                itemsCount={documentState.documentsCount}
                isLoading={documentState.isLoading}
                onPageChange={handlePageChange}
                onSortChange={handleSortChange}
                page={controller.page + 1}
                sort={controller.sort}
                sortBy={controller.sortBy}
                documentState={documentState}
              />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

const DeliveryVehicleActions = {
  DEACTIVATE_VEHICLES: 'VEHICLES_DEACTIVATE',
  ACTIVATE_VEHICLES: 'VEHICLES_ACTIVATE'
};

const DeliveryVehicleDriverActions = {
  DEACTIVATE_VEHICLE_DRIVERS: 'vehicle-drivers-deactivate',
  ACTIVATE_VEHICLE_DRIVERS: 'vehicle-drivers-activate'
};

export { DeliveryVehicleActions, DeliveryVehicleDriverActions };

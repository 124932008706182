import { cacheEnabled } from './app-definitions';
import { GadminRequest } from '../services/gadmin';
import { applyFilters } from '../utils/apply-filters';
import { applySort } from '../utils/apply-sort';
import { applyPagination } from '../utils/apply-pagination';
import { backendService } from '../services/backend-service';
/* eslint no-plusplus: 0 */
/* eslint class-methods-use-this:0 */

class DataLoader {
  async load({ action, cacheItem, apiParams, apiResponse, apiData, reload }) {
    let returnsData = null;
    let returnsApiResponse = { ...apiResponse };
    if (reload) {
      if (cacheEnabled) {
        returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
      }

      if (!returnsData) {
        const response = await GadminRequest(action, apiParams);
        const { data } = response;
        returnsApiResponse = { ...apiResponse, ...response };
        returnsData = data;
        if (cacheEnabled) {
          localStorage.setItem(cacheItem, JSON.stringify(returnsData));
        }
      }
    } else {
      returnsData = apiData;
    }
    return { returnsData, returnsApiResponse };
  }

  async loadGoal({ action, cacheItem, apiParams, apiResponse, apiData, reload }) {
    let returnsData = null;
    let returnsApiResponse = { ...apiResponse };
    if (reload) {
      if (cacheEnabled) {
        returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
      }

      if (!returnsData) {
        const response = await backendService.get(action, {
          params: {
            ...apiParams
          }
        });
        const { data } = response;
        returnsApiResponse = { ...apiResponse, ...response };
        returnsData = data;
        if (cacheEnabled) {
          localStorage.setItem(cacheItem, JSON.stringify(returnsData));
        }
      }
    } else {
      returnsData = apiData;
    }
    return { returnsData, returnsApiResponse };
  }

  async loadGoalGetOptions({ action, cacheItem, apiParams, apiResponse, apiData, reload }) {
    let returnsData = null;
    let returnsApiResponse = { ...apiResponse };
    if (reload) {
      if (cacheEnabled) {
        returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
      }

      if (!returnsData) {
        const response = await backendService.get(action, {
          params: {
            ...apiParams.payload
          }
        });
        const { data } = response;
        returnsApiResponse = { ...apiResponse, ...response };
        returnsData = data;
        if (cacheEnabled) {
          localStorage.setItem(cacheItem, JSON.stringify(returnsData));
        }
      }
    } else {
      returnsData = apiData;
    }
    return { returnsData, returnsApiResponse };
  }

  async loadGoalPost({ action, changes }) {
    const response = await backendService.post(action, changes);
    // console.log(response);
    return response;
  }

  async loadGoalFormData({ action, changes }) {
    const response = await backendService.post(action, changes, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    // console.log(response);
    return response;
  }

  async loadGoalUpdate({ action, changes }) {
    const response = await backendService.put(action, changes);
    // console.log(response);
    return response;
  }

  // goalUpdate
  async goalPut({ path, changes }) {
    return await this.loadGoalUpdate({ action: path, changes });
  }

  async loadGoalDelete({ action }) {
    const response = await backendService.delete(action);
    // console.log(response);
    return response;
  }

  // goalDelete
  async goalDelete({ path, data }) {
    const paramsString = this.paramsAsString(data);
    const url = `${path}${paramsString ? `?${paramsString}` : ''}`;
    const response = await backendService.delete(url);
    return response;
  }

  // goal restore
  async goalRestore({ path, data }) {
    return await this.goalPut({ action: path, changes: data });
  }

  paramsAsString(params) {
    const query = params
      ? Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&')
      : '';
    return query;
  }

  async loadGoalGetData({ action, params }) {
    const paramsString = this.paramsAsString(params);
    const url = `${action}${paramsString ? `?${paramsString}` : ''}`;
    const response = await backendService.get(url);
    return response;
  }

  // goal get
  async goalGet({ action, params }) {
    return await this.loadGoalGetData({ action, params });
  }

  // goal post
  async goalPost({ action, data }) {
    return await this.loadGoalPost({ action, changes: data });
  }

  //  fucntion check if is number

  // eslint-disable-next-line no-restricted-globals
  isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0);

  async search({ rows, searchText, searchFields, view, viewField }) {
    // check if searchText is number
    // if yes, check if row[searchFields[index].name] is number
    const numberSearch = this.isNumber(searchText);

    return rows.filter((row) => {
      // If query exists, it looks only in customer id field
      if (!!searchText && searchFields) {
        let qualified = 0;
        for (let index = 0; index < searchFields.length; index++) {
          let isAccepted = true;

          //  if isnumeric searchText
          //  if isnumeric row[searchFields[index].name]

          switch (searchFields[index].type) {
            case 'string':
              isAccepted =
                isAccepted &&
                row[searchFields[index].name]?.toLowerCase().includes(searchText.toLowerCase());
              break;
            case 'number':
              isAccepted =
                isAccepted &&
                numberSearch &&
                this.isNumber(row[searchFields[index].name]) &&
                // eslint-disable-next-line eqeqeq
                row[searchFields[index].name] == searchText;
              break;
            default:
              break;
          }
          qualified += isAccepted ? 1 : 0;
        }
        if (!qualified) return false;
      }

      if (typeof view === 'undefined' || view === 'all') {
        return true;
      }
      // No need to look for any resource fields
      // In this case, the view represents the resource status
      if (viewField) {
        return row[viewField] === view;
      }
      return true;
    });
  }

  returnEmpty(returnsApiResponse) {
    return {
      documents: [],
      documentsCount: 0,
      apiResponse: returnsApiResponse,
      success: returnsApiResponse.success,
      message: returnsApiResponse.message,
      code: returnsApiResponse.code
    };
  }

  async returnData({
    returnsData,
    returnsApiResponse,
    searchFields,
    query,
    filters,
    sort,
    sortBy,
    page,
    view,
    viewField
  }) {
    const queriedInvoices = await this.search({
      rows: returnsData,
      searchText: query,
      searchFields,
      view,
      viewField
    });
    const filteredInvoices = applyFilters(queriedInvoices, filters);
    const sortedInvoices = applySort(filteredInvoices, sort, sortBy);
    const paginatedInvoices = applyPagination(sortedInvoices, page);

    return {
      documents: paginatedInvoices,
      documentsCount: filteredInvoices.length,
      apiData: returnsData,
      apiResponse: returnsApiResponse,
      success: returnsApiResponse.success,
      message: returnsApiResponse.message,
      code: returnsApiResponse.code
    };
  }
}

export const dataLoader = new DataLoader();

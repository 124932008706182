import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { financialReportApi } from '../../../api/financial-reports';
import { Status } from '../../../components/status';
import { useMounted } from '../../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import { useApp } from '../../../hooks/use-app';
// import { AllowedGuard } from '../../../hooks/use-allowed';
// import GadminActions from '../../../contexts/gadmin-actions';
import LoadingContent from '../../../components/common/loading-content';
import { useDownloadFile } from '../../../hooks/use-download-file';
import { JournalHeader } from '../../../components/financial/journal/journal-header';
import { JournalLines } from '../../../components/financial/journal/journal-lines';
import { Eye as EyeIcon } from '../../../icons/eye';
import { useDialog } from '../../../hooks/use-dialog';
import { JournalPrintingDialog } from '../../../components/purchases/printing/journal-printing-dialog';
import { useData } from '../../../hooks/use-data';
import { goalJournalsApi } from '../../../api/goal/financial/journals';
import { JournalLinesGroupTable } from '../../../components/financial/journal/journal-lines-group';

export const Journal = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, currentCompany } = useApp();
  // const [receiptInfoState, setReceiptInfoState] = useState({ isLoading: true });
  const [
    journalPrintingDialogOpen,
    handleOpenJournalPrintingDialog,
    handleCloseJournalPrintingDialog
  ] = useDialog();

  const [journalState, refreshJournal] = useData({
    sourceApi: goalJournalsApi.getJournal,
    apiParameter: {
      transid: id,
      companyId: currentCompany
    },
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });
  const [receiptInfoState, refreshReceiptInfoState] = useData({
    sourceApi: financialReportApi.journalDetail,
    apiParameter: id,
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });
  useEffect(() => {
    refreshReceiptInfoState(id);
    refreshJournal({ transid: id, companyId: currentCompany });
  }, [id]);

  const navigate = useNavigate();

  const { download } = useDownloadFile({
    apiDefinition: financialReportApi.journalDetailXls,
    apiParameters: {
      transid: id
    }
  });

  const renderContent = () => {
    if (receiptInfoState.isLoading || receiptInfoState.error || !receiptInfoState.data) {
      return (
        <LoadingContent loadingText={receiptInfoState.message} error={receiptInfoState.error} />
      );
    }
    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component="button"
              startIcon={<ArrowLeftIcon />}
              onClick={() => navigate(-1)}
              variant="text"
            >
              Regresar
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              # SAP {receiptInfoState.data?.SeriesName}-{receiptInfoState.data?.DocNum}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="primary"
                // component={RouterLink}
                size="large"
                startIcon={<EyeIcon />}
                onClick={handleOpenJournalPrintingDialog}
                variant="contained"
              >
                Asiento
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={receiptInfoState.data?.memo} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <JournalHeader journalInfo={receiptInfoState.data} />
            </Grid>
            {/* <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={['F', 'G', 'D'].includes(receiptInfoState.data.EstadoMigrado)}>
                <Grid item xs={12}>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${appUrlBase}/${'promo/assigneds/'}${receiptInfoState.data.IdPedido}`}
                    underline="none"
                    variant="subtitle2"
                  >
                    Ver detalle de promociones
                  </Link>
                </Grid>
              </RenderIf>
            </AllowedGuard> */}
            <Grid item xs={12}>
              <JournalLines journalInfo={receiptInfoState.data} onDownload={download} />
            </Grid>

            <Grid item xs={12}>
              <JournalLinesGroupTable journalInfo={journalState.data} />
            </Grid>
          </Grid>
        </Grid>
        <JournalPrintingDialog
          onClose={handleCloseJournalPrintingDialog}
          open={journalPrintingDialogOpen}
          entity={{
            objType: receiptInfoState.data?.transType,
            docEntry: receiptInfoState.data?.createdBy,
            transId: id
          }}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Asiento Diario')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

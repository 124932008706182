import PropTypes from 'prop-types';
import { InputLabel, Select } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const SelectInputField = (props) => {
  const { inputProps, children, sx, ...other } = props;

  return (
    <Select
      inputProps={{
        ...inputProps,
        sx: {
          backgroundColor: 'background.paper',
          // borderWidth: 1,
          // borderStyle: 'solid',
          // borderColor: 'neutral.300',
          borderRadius: 1,
          alignItems: 'center',
          display: 'flex',
          fontSize: 12,
          height: 'unset',
          lineHeight: 2.3,
          px: 1.5,
          py: 0.75,
          '&.MuiInputBase-inputAdornedStart': {
            pl: 0
          },
          '&.Mui-disabled': {
            backgroundColor: 'action.disabledBackground',
            boxShadow: 'none',
            borderColor: alpha('#D6DBE1', 0.5)
          },
          '&:hover': {
            backgroundColor: 'background.paper',
            // borderWidth: 1,
            // borderStyle: 'solid',
            // borderColor: 'neutral.300',
            borderRadius: 1
          },
          '&:focus': {
            borderRadius: 1,
            // borderWidth: 1,
            // borderStyle: 'solid',
            // borderColor: 'neutral.300',
            backgroundColor: 'background.paper',
            boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
          },
          ...sx
        }
      }}
      variant="filled"
      {...other}
    >
      {children}
    </Select>
  );
};

SelectInputField.propTypes = {
  inputProps: PropTypes.object,
  sx: PropTypes.object,
  children: PropTypes.node
};

export const InputLabelField = (props) => {
  const { title } = props;

  return (
    <InputLabel
      sx={{
        color: 'text.primary',
        fontSize: 12,
        fontWeight: 500,
        mb: 0.5,
        position: 'relative',
        transform: 'none'
      }}
      htmlFor="grouped-native-select"
    >
      {title}
    </InputLabel>
  );
};

InputLabelField.propTypes = {
  title: PropTypes.string
};

import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Grid
} from '@mui/material';
import { InputField } from '../input-field';

const CustomInputComponent = ({ type, formik }) => {
  const handleAgregarOpcion = () => {
    const opciones = [...formik.values.options, ''];
    formik.setFieldValue('options', opciones);
  };

  const handleInputChange = (index, event) => {
    const opciones = [...formik.values.options];
    opciones[index] = event.target.value;
    formik.setFieldValue('options', opciones);
  };

  const handleEliminarOpcion = (index) => {
    const opciones = [...formik.values.options];
    opciones.splice(index, 1);
    formik.setFieldValue('options', opciones);
  };

  const handleAgregarRespuesta = () => {
    const correct = [...formik.values.correct, ''];
    formik.setFieldValue('correct', correct);
  };

  const handleInputChangeCorrect = (index, event) => {
    const correct = [...formik.values.correct];
    correct[index] = event.target.value;
    formik.setFieldValue('correct', correct);
  };

  const handleEliminarRespuesta = (index) => {
    const correct = [...formik.values.correct];
    correct.splice(index, 1);
    formik.setFieldValue('correct', correct);
  };

  switch (type) {
    case 'boolean':
      return (
        <FormControl>
          <FormLabel id="bolean-correct">Respuesta</FormLabel>
          <RadioGroup
            aria-labelledby="bolean-correct"
            defaultValue="false"
            name="correct"
            value={formik.values.correct}
            onChange={formik.handleChange}
          >
            <FormControlLabel value="verdad" control={<Radio />} label="Verdad" />
            <FormControlLabel value="false" control={<Radio />} label="Falso" />
          </RadioGroup>
        </FormControl>
      );
    case 'select':
      return (
        <>
          {formik.values.options.map((opcion, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={6}>
                <InputField
                  label={`Opción ${index + 1}`}
                  name={`options[${index}]`}
                  value={opcion}
                  onChange={(event) => handleInputChange(index, event)}
                  fullWidth
                  sx={{
                    marginBottom: 1
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleEliminarOpcion(index)}
                >
                  Eliminar Opción
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={6}>
            <Button variant="outlined" color="secondary" onClick={handleAgregarOpcion}>
              Agregar Opción
            </Button>
          </Grid>
          <br />
          <Grid item xs={6}>
            <InputField
              label="Respuesta"
              name="correct"
              value={formik.values.correct}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>
        </>
      );
    case 'multiselect':
      return (
        <>
          {formik.values.options.map((opcion, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={6} key={index}>
                <InputField
                  label={`Opción ${index + 1}`}
                  name={`options[${index}]`}
                  value={opcion}
                  onChange={(event) => handleInputChange(index, event)}
                  fullWidth
                  sx={{
                    marginBottom: 1
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button color="primary" onClick={() => handleEliminarOpcion(index)}>
                  Eliminar Opción
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="outlined" color="secondary" onClick={handleAgregarOpcion}>
              Agregar Opción
            </Button>
          </Grid>
          <br />
          <br />
          {Array.isArray(formik.values.correct) &&
            formik.values.correct.map((opcion, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6}>
                  <InputField
                    label={`Respuesta ${index + 1}`}
                    name={`correct[${index}]`}
                    value={opcion}
                    onChange={(event) => handleInputChangeCorrect(index, event)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEliminarRespuesta(index)}
                  >
                    Eliminar Respuesta
                  </Button>
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button variant="outlined" color="secondary" onClick={handleAgregarRespuesta}>
              Agregar Respuesta
            </Button>
          </Grid>
        </>
      );
    case 'text':
      return (
        <InputField
          label="Respuesta"
          name="correct"
          value="---"
          onChange={formik.handleChange}
          sx={{ opacity: 0 }}
        />
      );
    case 'range':
      return (
        <>
          <Grid item xs={6}>
            <InputField
              type="number"
              label="Rango Minimo"
              name="options[0]"
              value={formik.values.options[0]}
              onChange={(event) => handleInputChange(0, event)}
              fullWidth
              sx={{
                marginBottom: 1
              }}
              error={formik.touched.options?.[0] && Boolean(formik.errors.options?.[0])}
              helperText={formik.touched.options?.[0] && formik.errors.options?.[0]}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="number"
              label="Rango Máximo"
              name="options[1]"
              value={formik.values.options[1]}
              onChange={(event) => handleInputChange(1, event)}
              fullWidth
              sx={{
                marginBottom: 1
              }}
              error={formik.touched.options?.[1] && Boolean(formik.errors.options?.[1])}
              helperText={formik.touched.options?.[1] && formik.errors.options?.[1]}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="number"
              label="Respuesta"
              name="correct"
              value={formik.values.correct}
              onChange={formik.handleChange}
              fullWidth
            />
          </Grid>
        </>
      );
    default:
      return null;
  }
};

CustomInputComponent.propTypes = {
  type: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

export default CustomInputComponent;

import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { NCServiceTable } from './nc-service-table';

export const NCServiceLineItems = (props) => {
  const { invoice } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Líneas" />
      <Divider />
      <NCServiceTable invoice={invoice} />
    </Card>
  );
};

NCServiceLineItems.propTypes = {
  invoice: PropTypes.object.isRequired
};

import Proptypes from 'prop-types';

const RenderIf = ({ children, condition, no }) => (!condition ? no : children);

RenderIf.propTypes = {
  children: Proptypes.node,
  condition: Proptypes.oneOfType([Proptypes.bool, Proptypes.string]),
  no: Proptypes.node
};

export { RenderIf };

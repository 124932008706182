import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Checkbox, Divider, FormHelperText, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { InputField } from '../input-field';
import { Plus as PlusIcon } from '../../icons/plus';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
// import { useMounted } from '../../../hooks/use-mounted';
import { RenderIf } from '../render-if';
import { useMounted } from '../../hooks/use-mounted';

import { useData } from '../../hooks/use-data';
import { goalDexApi } from '../../api/goal/dex/goal-dex';
import { useFormSaver } from '../../hooks/use-form-saver';
import { YesNo } from '../yes-no';

// import useItemChanges from '../../../hooks/use-item-change';

// const reportTypeList = [
//   { id: 'Y', name: 'Anual' },
//   { id: 'M', name: 'Mensual' },
//   { id: 'D', name: 'Diario' }
// ];

export const DexReportEdit = (props) => {
  const {
    currentLine = {},
    editable,
    onAfterSave,
    dialogMode = true,
    dialogSaving = false,
    setDialogSaving,
    isNew = false
  } = props;

  const mounted = useMounted();

  // const [reportTypeList, refreshReportTypeList] = useData({
  const [reportTypeList] = useData({
    sourceApi: goalDexApi.getDexReportTypes,
    loadingMessage: 'Cargando Tipos de reporte',
    mounted
  });

  const [reportCompanyList] = useData({
    sourceApi: goalDexApi.getDexReportCompanies,
    loadingMessage: 'Cargando empresas',
    mounted
  });
  const [reportConnectionList] = useData({
    sourceApi: goalDexApi.getDexReportConnecions,
    loadingMessage: 'Cargando connexiones',
    mounted
  });
  const [saving, setSaving] = useState(false);
  const handleAfterSave = () => {
    setSaving(false);
    handleClose();
    onAfterSave?.();
  };
  const handleClose = () => {
    if (dialogMode) {
      setDialogSaving(false);
    }
  };

  const [handleUpdate, handleAdd] = useFormSaver({
    originalData: currentLine,
    apiUpdateHandler: goalDexApi.updateDexReportForm,
    apiAddHandler: goalDexApi.postDexReportForm,
    id: '_id',
    hashField: '_rev',
    handleAfterSave,
    includeNewValues: true
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: currentLine?.code || '',
      name: currentLine?.name || '',
      type: currentLine?.type || 0,
      permission: currentLine?.permission || '',
      query: currentLine?.query || '',
      parameter: currentLine?.parameter || '',
      submit: null,
      company: currentLine?.company || 0,
      companyName: currentLine?.companyName || '',
      typeName: currentLine?.typeName || '',
      fixedFileName: currentLine?.fixedFileName || false,
      fileName: currentLine?.fileName || '',
      sheetName: currentLine?.sheetName || '',
      tableName: currentLine?.tableName || '',
      path: currentLine?.path || '/run-report',
      connection: currentLine?.connection || 0,
      aditionalParams: currentLine?.aditionalParams || ''
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().min(5).max(20).required('Código es requerido'),
      name: Yup.string().min(10).max(100).required('nombre es requerido'),
      type: Yup.number().required('Tipo es requerido'),
      permission: Yup.string().min(10).max(255).required('Permiso es requerido'),
      query: Yup.string().min(10).max(2048).required('Query es requerido'),
      parameter: Yup.string().min(3).max(512)
    }),
    onSubmit: async (values, helpers) => {
      if (isNew) await handleAdd({ values, helpers });
      else await handleUpdate({ values, helpers });
    }
  });

  useEffect(() => {
    if (dialogSaving && !formik.isSubmitting) {
      formik.submitForm();
    }
    if (dialogSaving) {
      setSaving(false);
      setDialogSaving(false);
    }
  }, [dialogSaving]);

  return (
    <Card variant="outlined">
      {/* <CardHeader title="Detalle de comprobante" /> */}
      <Divider />
      <form
        onSubmit={async (values, helpers) => {
          await formik.handleSubmit(values, helpers);
        }}
      >
        <Grid container>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem label="ID" value={`${currentLine.id}`} align="right" />
              <ListedItem
                repository={reportCompanyList}
                editable={editable}
                formik={formik}
                code="company"
                label="Empresa"
                onChange={(event) => {
                  const type = reportCompanyList.data.find(
                    (type) => type.id === event.target.value
                  );
                  formik.setFieldValue('companyName', type?.name);
                  formik.handleChange(event);
                }}
              />
              <ListedItem
                repository={reportTypeList}
                editable={editable}
                formik={formik}
                code="type"
                label="Tipo"
                onChange={(event) => {
                  const type = reportTypeList.data.find((type) => type.id === event.target.value);
                  formik.setFieldValue('typeName', type?.name);
                  formik.handleChange(event);
                }}
              />

              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Codigo" value={`${currentLine.code}`} />}
              >
                <PropertyListItem label="Codigo">
                  <InputField
                    error={Boolean(formik.touched.code && formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    fullWidth
                    sx={{ minWidth: 280 }}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Nombre" value={`${currentLine.name}`} />}
              >
                <PropertyListItem label="Nombre">
                  <InputField
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    fullWidth
                    sx={{ minWidth: 380 }}
                  />
                </PropertyListItem>
              </RenderIf>
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <ListedItem
                repository={reportConnectionList}
                editable={editable}
                formik={formik}
                code="connection"
                label="Conexión"
                onChange={(event) => {
                  const connection = reportConnectionList.data.find(
                    (connection) => connection.id === event.target.value
                  );
                  formik.setFieldValue('connectionName', connection?.name);
                  formik.handleChange(event);
                }}
              />
              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Query" value={`${currentLine.query}`} />}
              >
                <PropertyListItem label="Query">
                  <InputField
                    error={Boolean(formik.touched.query && formik.errors.query)}
                    helperText={formik.touched.query && formik.errors.query}
                    name="query"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.query}
                    fullWidth
                    multiline
                    rows={6}
                    sx={{ minWidth: 280 }}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Parametros" value={`${currentLine.parameter}`} />}
              >
                <PropertyListItem label="Parametros">
                  <InputField
                    error={Boolean(formik.touched.parameter && formik.errors.parameter)}
                    helperText={formik.touched.parameter && formik.errors.parameter}
                    name="parameter"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.parameter}
                    fullWidth
                    // multiline
                    // rows={4}
                    sx={{ minWidth: 280 }}
                  />
                </PropertyListItem>
              </RenderIf>{' '}
              <RenderIf
                condition={editable}
                no={
                  <PropertyListItem
                    label="Parametros adicionales"
                    value={`${currentLine.aditionalParams}`}
                  />
                }
              >
                <PropertyListItem label="Parametros adicionales">
                  <InputField
                    error={Boolean(
                      formik.touched.aditionalParams && formik.errors.parametaditionalParamser
                    )}
                    helperText={formik.touched.aditionalParams && formik.errors.aditionalParams}
                    name="aditionalParams"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.aditionalParams}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ minWidth: 280 }}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Permisos" value={`${currentLine.permission}`} />}
              >
                <PropertyListItem label="Permisos">
                  <InputField
                    error={Boolean(formik.touched.permission && formik.errors.permission)}
                    helperText={formik.touched.permission && formik.errors.permission}
                    name="permission"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.permission}
                    fullWidth
                    sx={{ minWidth: 280 }}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Path" value={`${currentLine.path}`} />}
              >
                <PropertyListItem label="Path">
                  <InputField
                    error={Boolean(formik.touched.path && formik.errors.path)}
                    helperText={formik.touched.path && formik.errors.path}
                    name="path"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.path}
                    fullWidth
                    sx={{ minWidth: 280 }}
                  />
                </PropertyListItem>
              </RenderIf>
            </PropertyList>
          </Grid>

          <Grid item md={4} xs={12}>
            <PropertyList>
              <RenderIf
                condition={editable}
                no={
                  <PropertyListItem
                    label="Archivo fijo"
                    // value={<YesNo value={currentLine.fixedFileName} />}
                  >
                    <YesNo value={document.confirmed} />
                  </PropertyListItem>
                }
              >
                <PropertyListItem label="Archivo fijo">
                  <Checkbox
                    checked={formik.values.fixedFileName}
                    onChange={formik.handleChange}
                    name="fixedFileName"
                    color="secondary"
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf condition={formik.values.fixedFileName}>
                <RenderIf
                  condition={editable}
                  no={
                    <PropertyListItem label="Nombre de archivo" value={`${currentLine.fileName}`} />
                  }
                >
                  <PropertyListItem label="Nombre de archivo">
                    <InputField
                      error={Boolean(formik.touched.fileName && formik.errors.fileName)}
                      helperText={formik.touched.fileName && formik.errors.fileName}
                      name="fileName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.fileName}
                      fullWidth
                      sx={{ minWidth: 280 }}
                    />
                  </PropertyListItem>
                </RenderIf>
              </RenderIf>
              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Hoja" value={`${currentLine.sheetName}`} />}
              >
                <PropertyListItem label="Hoja">
                  <InputField
                    error={Boolean(formik.touched.sheetName && formik.errors.sheetName)}
                    helperText={formik.touched.sheetName && formik.errors.sheetName}
                    name="sheetName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.sheetName}
                    fullWidth
                    sx={{ minWidth: 280 }}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editable}
                no={<PropertyListItem label="Tabla" value={`${currentLine.tableName}`} />}
              >
                <PropertyListItem label="Tabla">
                  <InputField
                    error={Boolean(formik.touched.tableName && formik.errors.tableName)}
                    helperText={formik.touched.tableName && formik.errors.tableName}
                    name="tableName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.tableName}
                    fullWidth
                    sx={{ minWidth: 280 }}
                  />
                </PropertyListItem>
              </RenderIf>
            </PropertyList>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item md={4} xs={12}>
            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}
            <RenderIf condition={!dialogMode}>
              <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={saving}
                >
                  Guardar
                </LoadingButton>
              </AllowedGuard>
            </RenderIf>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

DexReportEdit.propTypes = {
  currentLine: PropTypes.object,
  editable: PropTypes.bool,
  onAfterSave: PropTypes.func.isRequired,
  dialogMode: PropTypes.bool,
  dialogSaving: PropTypes.bool,
  setDialogSaving: PropTypes.func,
  isNew: PropTypes.bool
};

const ListedItem = ({
  code,
  label,
  valueString,
  repository,
  editable,
  onChange,
  formik,
  ...others
}) => {
  if (!repository || !editable || !formik)
    return <PropertyListItem label={label} value={valueString ?? 'Vacío'} />;
  const { sx } = others;
  const existsInList = repository?.data?.find((item) => item.id === formik.values[code]);
  if (!existsInList) {
    // formik.setFieldValue(code, 0);
  }
  return (
    <PropertyListItem label={label}>
      {!repository.isLoading && repository.success && (
        <RenderIf
          condition={editable && !repository.isLoading}
          no={<PropertyListItem label={label} value={label} />}
        >
          <InputField
            error={Boolean(formik.touched[code] && formik.errors[code])}
            helperText={formik.touched[code] && formik.errors[code]}
            select
            name={code}
            value={formik.values[code]}
            onChange={onChange}
            sx={sx ?? { minWidth: 280 }}
          >
            <MenuItem value={!existsInList ? formik.values[code] : 0}>
              Seleccione...{label}
            </MenuItem>
            {repository.isLoading || !repository.data || repository.data.length === 0 ? (
              <MenuItem value="">No hay {label}</MenuItem>
            ) : (
              repository.data.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))
            )}
          </InputField>
        </RenderIf>
      )}
    </PropertyListItem>
  );
};
ListedItem.propTypes = {
  repository: PropTypes.object,
  editable: PropTypes.bool,
  formik: PropTypes.object,
  mounted: PropTypes.bool,
  code: PropTypes.string,
  label: PropTypes.string,
  valueString: PropTypes.string,
  onChange: PropTypes.func
};

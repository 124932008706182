import { filter } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

// material
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Skeleton
} from '@mui/material';
// components
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../icons/exclamation-outlined';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { GadminRequest } from '../../services/gadmin';
import GadminActions from '../../contexts/gadmin-actions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'docnum', label: 'Numero', alignRight: false },
  { id: 'numref', label: 'Referencia', alignRight: false },
  { id: 'tipo', label: 'Tipo', alignRight: false },
  { id: 'errinfo', label: 'Descripción', alignRight: false },
  { id: 'fecha', label: 'Fecha', alignRight: false },
  { id: 'idprog', label: 'Interface', alignRight: false },
  { id: 'iduser', label: 'Usuario', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const filtered = filter(
      array,
      (_user) => _user.errinfo.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    return filtered;
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PageLog() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [filterTyping, setFilterTyping] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const app = useApp();
  const mounted = useMounted();
  const [lineState, setLineState] = useState({ isLoading: true });

  const getLines = useCallback(async () => {
    setLineState(() => ({ isLoading: true }));

    try {
      const result = await GadminRequest(GadminActions.G2_APP_LOG, {});
      if (mounted.current) {
        setLineState(() => ({
          isLoading: false,
          data: result.data
        }));
      }
    } catch (err) {
      if (mounted.current) {
        setLineState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  useEffect(() => {
    getLines().catch(console.error);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilterTyping(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterName]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lineState.data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterTyping(true);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers?.length) : 0;

  //   const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const filteredUsers = applySortFilter(
    lineState.data,
    getComparator(order, orderBy),
    filterTyping ? null : filterName
  );
  const isUserNotFound = filteredUsers.length === 0;

  const renderContent = () => {
    if (lineState.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
            Cargando
          </Typography>
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (lineState.error) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              {lineState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={lineState.data?.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { id, docnum, numref, tipo, errinfo, fecha, idprog, iduser } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {docnum}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{numref}</TableCell>
                        <TableCell align="left">{tipo}</TableCell>
                        <TableCell align="left">{errinfo}</TableCell>
                        <TableCell align="left">{fecha}</TableCell>
                        <TableCell align="left">{idprog}</TableCell>
                        <TableCell align="left">{iduser}</TableCell>
                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}
                        {/* <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(status === 'banned' && 'error') || 'success'}
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell> */}

                        {/* <TableCell align="right">
                          <UserMoreMenu />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    );
  };

  return (
    <Page title={app.title('Sucesos del sistema')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Visor de sucesos
          </Typography>
        </Stack>
        {renderContent()}
      </Container>
    </Page>
  );
}

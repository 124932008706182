import { useParams } from 'react-router-dom';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { customerApi } from '../../../api/customer';
import { useSplit } from '../../../hooks/use-split';
import { CustomerElectricInvoiceTable } from '../customer/customer-electric-invoice-table';

const BusinessPartnerElectricInvoice = () => {
  const { cardcode: cardcodeQuery } = useParams();
  const mounted = useMounted();
  const { code: cardcode } = useSplit(cardcodeQuery);
  const [documentState, handleRefresh] = useData({
    sourceApi: customerApi.getCustomerSalesOrders,
    apiParameter: { cardcode },
    loadingMessage: 'Cargando informacion del Socio de negocio',
    mounted
  });
  const data = [
    {
      id: 1,
      number: '20023403',
      warehouse: '',
      date: '2019-11-21',
      supplier: 'PANADERIA',
      ruc: '20100093830',
      document: 'F043-01-00080723',
      est: 'Descartado',
      amount: '0.00',
      igv: '0.00',
      isc: '0.00',
      discount: '0.00',
      op_grav: '0.00',
      op_ngrav: '0.00',
      op_exo: '0.00',
      tg: '0.00',
      boni: '0.00',
      tax: '0.00',
      frecep: '',
      message: 'OK'
    },
    {
      id: 2,
      number: '20023401',
      warehouse: '',
      date: '2019-11-21',
      supplier: 'PANADERIA',
      ruc: '20100093830',
      document: 'F043-01-00080723',
      est: 'Descartado',
      amount: '0.00',
      igv: '0.00',
      isc: '0.00',
      discount: '0.00',
      op_grav: '0.00',
      op_ngrav: '0.00',
      op_exo: '0.00',
      tg: '0.00',
      boni: '0.00',
      tax: '0.00',
      frecep: '',
      message: 'OK'
    }
  ];

  return (
    <CustomerElectricInvoiceTable
      documentState={documentState}
      onRefresh={handleRefresh}
      documents={data}
    />
  );
};

export { BusinessPartnerElectricInvoice };

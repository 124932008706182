import { Box, Button, Card, CardHeader, Container, Divider } from '@mui/material';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import LoadingContent from '../../common/loading-content';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { GrTabDetailTable } from './gr-tabs/gr-tab-detail-table ';
import { GrTabDocument } from './gr-tabs/gr-tab-document';
import { useParams } from 'react-router-dom';
import { goalCustomeDeleveryMonitoringApi } from '../../../api/goal/monitoring/goal-customer-delivery-monitoring';

export const DeliveryCreateGrDetail = () => {
  const { id } = useParams();
  const mounted = useMounted();

  const [contestState, handleRefresh] = useData({
    sourceApi: goalCustomeDeleveryMonitoringApi.getCustomeDeliveryMonitoringById,
    apiParameter: { id: id },
    loadingMessage: 'Cargando concursos recientes',
    mounted
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="zl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
              <Button
                color="primary"
                size="large"
                startIcon={<Refresh />}
                onClick={handleRefresh}
                variant="contained"
              />
            </AllowedGuard>
          </Box>
        </Box>
        <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
          {!contestState.isLoading && contestState.success ? <GrTabDocument /> : <LoadingContent />}
        </Card>
        <Box sx={{ py: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
              <Button
                color="primary"
                size="large"
                startIcon={<Refresh />}
                onClick={handleRefresh}
                variant="contained"
              />
            </AllowedGuard>
          </Box>
        </Box>
        <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
          <CardHeader title="Linea" />
          <Divider />
          {!contestState.isLoading && contestState.success ? (
            <GrTabDetailTable />
          ) : (
            <LoadingContent />
          )}
        </Card>
      </Container>
    </Box>
  );
};

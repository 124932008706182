import { dataLoader } from '../../data-loader';

class ProductUnitMeasuresApi {
  async getProductUnitMeasuresForSelect({ companyId, productId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `inventory/master/product-unit-measures?companyId=${companyId}&search=${productId}&search_by=product_id`
    });
    return response;
  }
}

export const productUnitMeasuresApi = new ProductUnitMeasuresApi();

import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// import { format } from 'date-fns';
import { Card, Typography, CardHeader, Divider, Grid } from '@mui/material';
// import { Box, Button, Container, Grid, Skeleton, Typography } from '@mui/material';

import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import LoadingContent from '../../common/loading-content';

export const DocumentReference = (props) => {
  const { invoice } = props;
  const { currency, currencySymbol, reference } = invoice;
  if (!reference) {
    return <LoadingContent loadingText="Cargando referencias" />;
  }
  return (
    <Card variant="outlined">
      <CardHeader title="Comprobante de referencia" />
      <Divider />
      <Grid container>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Tipo"
              //   value={`${reference.receiptType === '01' ? 'Factura' : reference.receiptType}-${
              //     reference.seriesName
              //   }-${reference.docNum}`}
            >
              <Typography color="inherit" variant="body2">
                {reference.receiptType === '01' ? 'Factura' : reference.receiptType}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {`${reference.seriesName}-${reference.docNum}`}
              </Typography>
            </PropertyListItem>
            <PropertyListItem
              label="Proveedor"
              value={`${invoice.cardCode} - ${invoice.cardName}`}
            />
            <PropertyListItem label="Moneda" value={`${currency}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Serie" value={`${reference.serie}`} />
            <PropertyListItem label="Número" value={`${reference.number}`} />
            <PropertyListItem
              label="Importe"
              value={numeral(reference.docTotal).format(`${currencySymbol} 0,0.00`)}
              //   align="right"
            />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Fecha de emisión"
              value={moment(reference.taxDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Vencimiento"
              value={moment(reference.docDueDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Contabilización"
              value={moment(reference.docDate).format('DD/MM/YYYY')}
            />
          </PropertyList>
        </Grid>
      </Grid>
    </Card>
  );
};

DocumentReference.propTypes = {
  invoice: PropTypes.object.isRequired
};

// import PropTypes from 'prop-types';
import { Grid, InputLabel, Typography } from '@mui/material';
import { PropertyList } from '../../../property-list';
import { PropertyListItem } from '../../../property-list-item';
import { formatValueFuntion } from '../../../../hooks/use-format-value';

export const GrTabDataDelivery = () => {
  const item = {
    clientid: 8, // codigo de empresa    --  Seleccionar Empresa
    objtype: '13', // no mostrar
    docentry: 9516282, // no mostrar
    conductornumerolicencia: 'S45285605',
    conductortipoidentidad: 1, // dni
    conductornumeroidentidad: '45285605',
    destinatariotipoidentidad: 1,
    destinatarionumeroidentidad: '40405539',
    destinatarionombre: 'TENORIO AYALA, AYDE',
    remitentetipocomprobante: '03',
    remitenteseriecomprobante: 'B051',
    remitentecorrelativocomprobante: '00358146',
    remitentetipoidentidad: 6,
    remitentenumeroidentidad: '20537004381',
    transportistaruc: '20537004381',
    transportistarazonsocial: 'DISTRIBUCIONES Y SERVICIOS ROMA SAC',
    trasladocontenerdornumero: '',
    trasladofecha: '2023-08-25T00:00:00',
    trasladonumerobultos: 1,
    trasladomodalidad: 2,
    trasladomotivo: 1,
    trasladomotivodescripcion: 'VENTA',
    trasladopuertocodigo: '',
    trasladopesobrutokg: 0.0,
    ubigeollegada: '050101',
    puntollegada: 'AV. ESTUDIANTES SN. (PANPACANGALLO)',
    urbanizacionllegada: '-',
    departamentollegada: 'AYACUCHO',
    provinciallegada: 'HUAMANGA',
    distritollegada: 'AYACUCHO',
    ubigeopartida: '050101',
    puntopartida: 'AV EJERCITO NRO. 1085  AYACUCHO - HUAMANGA - AYACUCHO',
    urbanizacionpartida: '-',
    departamentopartida: 'AYACUCHO',
    provinciapartida: 'HUAMANGA',
    distritopartida: 'AYACUCHO',
    vehiculomarca: 'VOLKSWAGEN',
    vehiculoplaca: 'BEE-838',
    vehiculocertificado: '',
    DriverFirstName: null,
    DriverLastName: null,
    DespatchAdviceId: 172351, // no mandar
    trasladofechafin: '2023-08-27T00:00:00'
  };

  return (
    <Grid container>
      {/* <Grid item xs={12}>
        <PropertyList>
          <PropertyListItem label="Empresa">
            <Typography color="textSecondary" variant="body2">
              {item.clientid || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid> */}
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información Transportista
            </InputLabel>
          </PropertyListItem>
          <PropertyListItem label="Empresa">
            <Typography color="textSecondary" variant="body2">
              {item.clientid || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Tipo de identidad">
            <Typography color="textSecondary" variant="body2">
              {item.conductortipoidentidad || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="N. Identidad">
            <Typography color="textSecondary" variant="body2">
              {item.conductornumeroidentidad || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Nombre del conductor">
            <Typography color="textSecondary" variant="body2">
              {item.DriverFirstName || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Apellido del conductor">
            <Typography color="textSecondary" variant="body2">
              {item.DriverLastName || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="N. Licencia">
            <Typography color="textSecondary" variant="body2">
              {item.conductornumerolicencia || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="RUC">
            <Typography color="textSecondary" variant="body2">
              {item.transportistaruc || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Razón social">
            <Typography color="textSecondary" variant="body2">
              {item.transportistarazonsocial || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Marca de Vehículo">
            <Typography color="textSecondary" variant="body2">
              {item.vehiculomarca || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Placa">
            <Typography color="textSecondary" variant="body2">
              {item.vehiculoplaca || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Vehículo certificado">
            <Typography color="textSecondary" variant="body2">
              {item.vehiculocertificado || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información Remitente
            </InputLabel>
          </PropertyListItem>
          <PropertyListItem label="Comprobante">
            <Typography color="textSecondary" variant="body2">
              {item.remitentetipocomprobante || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Serie comprobante">
            <Typography color="textSecondary" variant="body2">
              {item.remitenteseriecomprobante || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Correlativo comprobante">
            <Typography color="textSecondary" variant="body2">
              {item.remitentecorrelativocomprobante || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Ubigeo">
            <Typography color="textSecondary" variant="body2">
              {item.ubigeopartida || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Punto de partida">
            <Typography color="textSecondary" variant="body2">
              {item.puntopartida || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Urbanización">
            <Typography color="textSecondary" variant="body2">
              {item.urbanizacionpartida || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Departamento">
            <Typography color="textSecondary" variant="body2">
              {item.departamentopartida || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Provincia">
            <Typography color="textSecondary" variant="body2">
              {item.provinciapartida || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Distrito">
            <Typography color="textSecondary" variant="body2">
              {item.distritopartida || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información Destinatario
            </InputLabel>
          </PropertyListItem>
          <PropertyListItem label="Tipo de identidad">
            <Typography color="textSecondary" variant="body2">
              {item.destinatariotipoidentidad || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="N. Identidad">
            <Typography color="textSecondary" variant="body2">
              {item.destinatarionumeroidentidad || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Nombre del destinatario">
            <Typography color="textSecondary" variant="body2">
              {item.destinatarionombre || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Ubigeo">
            <Typography color="textSecondary" variant="body2">
              {item.ubigeollegada || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Punto de llegada">
            <Typography color="textSecondary" variant="body2">
              {item.puntollegada || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Urbanización">
            <Typography color="textSecondary" variant="body2">
              {item.urbanizacionllegada || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Departamento">
            <Typography color="textSecondary" variant="body2">
              {item.departamentollegada || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Provincia">
            <Typography color="textSecondary" variant="body2">
              {item.provinciallegada || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Distrito">
            <Typography color="textSecondary" variant="body2">
              {item.distritollegada || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información Traslado
            </InputLabel>
          </PropertyListItem>
          <PropertyListItem label="Modalidad">
            <Typography color="textSecondary" variant="body2">
              {item.trasladomodalidad || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="N.de bultos">
            <Typography color="textSecondary" variant="body2">
              {item.trasladonumerobultos || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Peso Bruto">
            <Typography color="textSecondary" variant="body2">
              {item.trasladopesobrutokg || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Inicio de traslado">
            <Typography color="textSecondary" variant="body2">
              {formatValueFuntion(item.trasladofecha, 'DD/MM/YYYY', true)}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Fin de traslado">
            <Typography color="textSecondary" variant="body2">
              {formatValueFuntion(item.trasladofechafin, 'DD/MM/YYYY', true)}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Motivo">
            <Typography color="textSecondary" variant="body2">
              {item.trasladomotivo || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
    </Grid>
  );
};

// GrTabDocument.propTypes = {
//   item: PropTypes.object
// };

import { useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useApp } from '../../../hooks/use-app';
import { useAuth } from '../../../hooks/use-auth';
import { PropertyListItem } from '../../property-list-item';
import { PropertyList } from '../../property-list';
import { Status } from '../../status';
import { StatusColor } from '../../contest/contest-definition';
import { CommonImagePost } from '../../common/common-image-post';
import useImageToBase64 from '../../../hooks/use-image-to-base-64';
import { goalCustomeContestApi } from '../../../api/goal/contest/goal-customer-contest';
import GoalImageGallery from '../../common/goal-image-gallery';

export const DeliveryVehicleDetail = ({ dataVehicle = {} }) => {
  const [updateImage, setUpdateImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const { showError } = useApp();
  const { account } = useAuth();

  const {
    base64Image: base64ImageFile,
    previewImage: previewImageFile,
    resetImage: resetImageFile
  } = useImageToBase64(selectedImage);

  const handleImagenChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handlePostImage = async () => {
    const dataImage = {
      data: base64ImageFile,
      name: selectedImage.name,
      type: selectedImage.type,
      application: 'GOAL',
      folder: 'document',
      owner: account?.userName
    };
    const response = await goalCustomeContestApi.postImageForm({ values: dataImage });
    if (response.data) {
      resetImageFile();
      setImageLoading(true);
      setCurrentImage(response.data.data);
    } else {
      setImageLoading(false);
      setCurrentImage(null);
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  return (
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <CardHeader title="Detalle del vehículo" />
      <PropertyListItem label="">
        <Status color={StatusColor('A')} label={dataVehicle.status} />
      </PropertyListItem>
      <Divider />
      <Grid container>
        <Grid item md={6} xs={12}>
          <PropertyList>
            <Box sx={{ marginTop: 3, marginLeft: 3 }}>
              {!updateImage ? (
                <>
                  <GoalImageGallery
                    images={[
                      {
                        id: 80,
                        width: 1,
                        height: 1,
                        title: 'Vehículo',
                        image_code: 'RkV-7v.b3334b5d-5562-4df5-84c8-2326450a1e01'
                      }
                    ]}
                    sizeImage="500px"
                  />
                  <Button variant="contained" onClick={() => setUpdateImage(true)}>
                    Cambiar imagen
                  </Button>
                </>
              ) : (
                <>
                  <CommonImagePost
                    previewImageFile={previewImageFile}
                    handleImagenChange={handleImagenChange}
                    selectedImage={selectedImage}
                    resetImageFile={resetImageFile}
                    handlePostImage={handlePostImage}
                    imageLoading={imageLoading}
                    currentImage={currentImage}
                  />
                  <Button color="secondary" onClick={() => setUpdateImage(false)}>
                    Cancelar
                  </Button>
                </>
              )}
            </Box>
            <PropertyListItem>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold',
                  backgroundColor: '#ccc',
                  padding: '5px 20px'
                }}
              >
                Identificación del vehículo
              </InputLabel>
            </PropertyListItem>
            <PropertyListItem label="Nombre del vehículo" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.name}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="NIV" align="right">
              <Box sx={{ display: 'flex', gap: 3 }}>
                <Typography color="textSecondary" variant="body2" align="left">
                  {dataVehicle.niv || '---'}
                </Typography>
                {/* <Typography color="textSecondary" variant="body2" align="left">
                  <Button variant="contained">Decodificar</Button>
                </Typography> */}
              </Box>
            </PropertyListItem>
            <PropertyListItem label="Código de barras" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.barcode}
              </Typography>
            </PropertyListItem>
            <PropertyListItem>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold',
                  backgroundColor: '#ccc',
                  padding: '5px 20px'
                }}
              >
                Especificaciones básicas
              </InputLabel>
            </PropertyListItem>
            <PropertyListItem label="Fabricante" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.make}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Modelo" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.model}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Reducir" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.reduce || '---'}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Año" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.year}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Color" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                BLANCO
              </Typography>
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item md={6} xs={12}>
          <PropertyList>
            <PropertyListItem>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold',
                  backgroundColor: '#ccc',
                  padding: '5px 20px'
                }}
              >
                Clasificacíon
              </InputLabel>
            </PropertyListItem>
            <PropertyListItem label="Tipo de Vehículo" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.vehicle_type}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Departamento" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.department}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Grupo" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                TR Ica
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Tipo de gasolina" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                GNV
              </Typography>
            </PropertyListItem>
            <PropertyListItem>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold',
                  backgroundColor: '#ccc',
                  padding: '5px 20px'
                }}
              >
                Placas
              </InputLabel>
            </PropertyListItem>
            <PropertyListItem label="Número de la placa" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                ------
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Tipo de placa" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                ----
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Fecha de renovación" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                ----
              </Typography>
            </PropertyListItem>
            <PropertyListItem>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold',
                  backgroundColor: '#ccc',
                  padding: '5px 20px'
                }}
              >
                Odómetros
              </InputLabel>
            </PropertyListItem>
            <PropertyListItem>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 5,
                  color: 'black',
                  fontWeight: 'bold',
                  backgroundColor: '#ccc',
                  padding: '5px 20px'
                }}
              >
                principal
              </InputLabel>
            </PropertyListItem>
            <PropertyListItem sx={{ marginLeft: 8 }}>
              <FormControl sx={{ display: 'flex' }}>
                <FormLabel id="odometro">Odómetro principal</FormLabel>
                <RadioGroup sx={{ marginLeft: 8 }} aria-labelledby="odometro" defaultValue="k">
                  <FormControlLabel value="m" control={<Radio disabled />} label="Millas" />
                  <FormControlLabel value="k" control={<Radio disabled />} label="Kilómetros" />
                  <FormControlLabel value="h" control={<Radio disabled />} label="Horas" />
                </RadioGroup>
              </FormControl>
            </PropertyListItem>
            <PropertyListItem sx={{ marginLeft: 8 }} label="Lectura actual" align="right">
              <Typography color="textSecondary" variant="body2" align="left">
                {dataVehicle.odometer}
              </Typography>
            </PropertyListItem>
            <PropertyListItem>
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 5,
                  color: 'black',
                  fontWeight: 'bold',
                  backgroundColor: '#ccc',
                  padding: '5px 20px'
                }}
              >
                secundario
              </InputLabel>
            </PropertyListItem>
            <PropertyListItem sx={{ marginLeft: 8 }}>
              <Typography color="textSecondary" variant="body2" align="left">
                <FormControlLabel
                  control={<Checkbox checked={false} color="secondary" />}
                  label="¿Tiene un odómetro secundario?"
                />
              </Typography>
            </PropertyListItem>
          </PropertyList>
        </Grid>
      </Grid>
    </Card>
  );
};

DeliveryVehicleDetail.propTypes = {
  dataVehicle: Proptypes.object
};

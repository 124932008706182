import { useState } from 'react';
import Proptypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
  Typography
} from '@mui/material';
import { Icon } from '@iconify/react';
import circleIcon from '@iconify/icons-eva/checkmark-circle-2-fill';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { PropertyList } from '../property-list';
import { AutocompleteField } from '../autocomplete-field';
import { PropertyListItem } from '../property-list-item';
import { RenderIf } from '../render-if';
import { X } from '../../icons/x';
import { ContestQuotaDetail } from './contest-quota-detail';
import { InputField } from '../input-field';
import { DocumentAppendDialog } from '../purchases/common/document-add';
import { useDialog } from '../../hooks/use-dialog';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import { CommonHelpText } from '../common/common-help-text';

const ContestQuotaForm = ({
  title,
  initialValues = {},
  contestState,
  employeePositionList,
  listQuota,
  quotaByList,
  handleRefreshDetail
}) => {
  const { showNotify, showError } = useApp();
  const [selectAction, setSelectAction] = useState('');
  const [selectItem, setSelectedItem] = useState(null);
  const [editMode, setEditmode] = useState(false);
  const [appendDialogOpen, handleOpenAppendDialog, handleCloseAppendDialog] = useDialog();
  const handleExitedDialog = () => {};

  const uniqueId = uuidv4();

  const formik = useFormik({
    initialValues: {
      value: initialValues.value || 0,
      employee_position: initialValues.employee_position || '',
      employee_position_code: initialValues.employee_position_code || '',
      employee_position_id: initialValues.employee_position_id || '',
      type: initialValues.type || '',
      active: initialValues.active || true,
      id: initialValues.id || uniqueId
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      if (selectAction === 'edit-form') {
        const index = contestState.quotas.findIndex((quota) => quota.id === values.id);
        if (index !== -1) {
          contestState.quotas[index] = values;
        }
        const response = await goalCustomeContestApi.postContestForm({ values: contestState });
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleRefreshDetail();
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      } else {
        const createQuota = {
          ...contestState,
          quotas: [...contestState.quotas, values]
        };
        const response = await goalCustomeContestApi.postContestForm({ values: createQuota });
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleRefreshDetail();
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      }
    }
  });

  const onSelection = (item) => {
    setSelectAction('edit-form');
    setSelectedItem(item);
    formik.setFieldValue('id', item.id);
    formik.setFieldValue('value', item.value);
    formik.setFieldValue('employee_position', item.employee_position);
    formik.setFieldValue('employee_position_code', item.employee_position_code);
    formik.setFieldValue('employee_position_id', item.employee_position_id);
    formik.setFieldValue('type', item.type);
  };

  const handleReset = () => {
    formik.setFieldValue('value', 0);
    formik.setFieldValue('employee_position_code', '');
    formik.setFieldValue('employee_position_id', '');
    formik.setFieldValue('employee_position', '');
    formik.setFieldValue('type', '');
  };

  const handleCancel = () => {
    setEditmode(false);
    setSelectAction('');
  };

  const handleGenerateTemplate = () => {
    console.log('id contest', contestState?._id);
    console.log('id employee', formik.values.employee_position_id);
  };

  const handleInitial = () => {
    setEditmode(false);
    setSelectedItem(null);
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item md={6} xs={12}>
            <PropertyList>
              <Box
                sx={{
                  height: 'auto',
                  flexGrow: 1,
                  maxWidth: '100%',
                  overflowY: 'auto',
                  background: '#fff',
                  paddingX: 1,
                  paddingY: 4,
                  borderRadius: 2,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'neutral.300',
                  boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
                  marginTop: 5
                }}
                px={2}
              >
                <PropertyList>
                  <PropertyListItem
                    label=""
                    sx={{
                      px: 3,
                      py: 0
                    }}
                  >
                    <Typography
                      sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                      onClick={handleInitial}
                    >
                      Cuotas
                    </Typography>

                    <List>
                      {listQuota.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemButton onClick={() => onSelection(item)}>
                            <ListItemIcon>
                              <Icon icon={circleIcon} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                              primary={`${item.value} - ${item.employee_position.name}`}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </PropertyListItem>
                </PropertyList>
              </Box>
            </PropertyList>
          </Grid>

          <Grid item xs={12} sm={6}>
            <RenderIf
              condition={editMode}
              no={
                <ContestQuotaDetail
                  setEditMode={setEditmode}
                  item={selectItem}
                  setSelectAction={setSelectAction}
                  handleReset={handleReset}
                  contestState={contestState}
                />
              }
            >
              <AutocompleteField
                label="Puesto"
                name="employee_position"
                placeholder="Seleccione puesto"
                fullWidth
                options={employeePositionList || [{ name: 'Cargando', code: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('employee_position_code', value?.code);
                  formik.setFieldValue('employee_position_id', value?.id);
                  formik.setFieldValue('employee_position', value);
                }}
                value={formik.values.employee_position}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.employee_position_code &&
                  Boolean(formik.errors.employee_position_code)
                }
                helperText={
                  formik.touched.employee_position_code && formik.errors.employee_position_code
                }
                disabled={selectAction === 'edit-form'}
              />
              <InputField
                select
                label="Tipo"
                name="type"
                value={formik.values.type}
                fullWidth
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                sx={{ marginBottom: '1em' }}
              >
                {quotaByList.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </InputField>
              <InputField
                name="value"
                label="Valor"
                type="number"
                value={formik.values.value}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.value && Boolean(formik.errors.value)}
                helperText={formik.touched.value && formik.errors.value}
                sx={{ marginBottom: '1em' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 5
                }}
              >
                <Box
                  sx={{
                    display: 'flex'
                  }}
                >
                  <Button onClick={handleOpenAppendDialog}>Cargar plantilla</Button>
                  <CommonHelpText message="La opción 'Cargar plantilla' simplifica el proceso de subir un archivo Excel y conectarlo a la cuota correspondiente, facilitando la vinculación de datos con la cuota de manera eficiente." />
                </Box>
                <Box
                  sx={{
                    display: 'flex'
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleGenerateTemplate}
                    disabled={formik.values.employee_position_id === ''}
                  >
                    Generar plantilla
                  </Button>
                  <CommonHelpText message="Generar una plantilla, nos ayuda a descargar un excel, este botón de habilitara cuando se seleccione un puesto" />
                </Box>
              </Box>

              {selectAction === 'edit-form' && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.active}
                      onChange={formik.handleChange}
                      name="active"
                    />
                  }
                  label={formik.values.active ? 'Activo' : 'Desactivado'}
                />
              )}
            </RenderIf>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                {editMode && selectAction !== 'create-form' && (
                  <>
                    <LoadingButton
                      color="primary"
                      size="large"
                      startIcon={<PencilIcon />}
                      variant="contained"
                      type="submit"
                      loading={formik.isSubmitting}
                      disabled={selectAction !== 'edit-form'}
                    >
                      Guardar
                    </LoadingButton>
                    <Button
                      color="secondary"
                      size="large"
                      startIcon={<X />}
                      onClick={() => setEditmode(false)}
                      sx={{
                        marginLeft: 1
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                )}
                {selectAction === 'create-form' && (
                  <LoadingButton
                    color="primary"
                    size="large"
                    startIcon={<PlusIcon />}
                    variant="contained"
                    loading={formik.isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </LoadingButton>
                )}
                {selectAction === 'create-form' && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<X />}
                    onClick={handleCancel}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cancelar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <DocumentAppendDialog
        onClose={handleCloseAppendDialog}
        onExited={handleExitedDialog}
        afterSubmit={() => {}}
        uploaderApi={goalCustomeContestApi.uploadDocumentExcel}
        open={appendDialogOpen}
        typeDocument={[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel'
        ]}
        hoverDocument="Seleccione un archivo excel"
        titleDocument="Agregar cuota"
      />
    </Box>
  );
};

export default ContestQuotaForm;

ContestQuotaForm.propTypes = {
  initialValues: Proptypes.object,
  title: Proptypes.string,
  contestState: Proptypes.object,
  employeePositionList: Proptypes.array,
  listQuota: Proptypes.array,
  quotaByList: Proptypes.array,
  handleRefreshDetail: Proptypes.func
};

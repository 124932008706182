import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  DialogActions,
  Grid,
  InputLabel,
  LinearProgress,
  Slider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { ArrowLeft } from '../../../icons/arrow-left';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { useApp } from '../../../hooks/use-app';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { FormCustomerAwards } from './form-customer-awards';
import { RangeAwardsEdit } from './range-awards-edit';
import { RangeAwardsDetail } from './range-awards-detail';

export const RangeAwardsForm = ({
  showRange,
  setShowRange,
  rangeQuantity,
  valueMin,
  valueMax,
  handleChangeRange,
  valueRange,
  handleSetRange,
  onClose,
  setCreateRange,
  awardsValue = [],
  minDistance,
  typeOption,
  formikAwards,
  employeePositionList,
  configAward,
  setEditmode,
  selectAction
}) => {
  const { showError } = useApp();
  const generateArray = (n = 1, min, distance = 5) => {
    if (n < 2 || n === null || n === 0 || n === '0') {
      showError('El tamaño del array debe ser al menos 2');
      return [];
    }

    const arr = Array.from({ length: n }, (_, i) => min + i * distance);
    return arr;
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState(0);

  const [valueMinState, setValueMinState] = useState(valueMin);
  const [valueMaxState, setValueMaxState] = useState(valueMax);
  const [minDistanceState, setMinDistanceState] = useState(minDistance);
  const [awards, setAwards] = useState(Array(valueRange.length).fill(0));
  const [valueProrateado, setValueProrateado] = useState(Array(valueRange.length).fill(false));
  const [errors, setErrors] = useState(Array(valueRange.length).fill(true));
  const [rangeState, setRangeState] = useState(rangeQuantity);

  const [generarArrayState, setGenerarArrayState] = useState(
    generateArray(rangeState, valueMinState, minDistanceState)
  );
  const [arrayRefresh, setArrayRefresh] = useState(
    generateArray(rangeState, valueMinState, minDistanceState)
  );

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (awardsValue.length !== 0) {
      const awardsArray = awardsValue.map((item) => item.award);
      setAwards(awardsArray);

      const prorateadosArray = awardsValue.map((item) => item.prorateado);
      setValueProrateado(prorateadosArray);

      setErrors(Array(awardsValue.length).fill(false));
    }
  }, []);

  const handleValueInitialChange = (event) => {
    const newArrayState = [...arrayRefresh];
    setValueMinState(Number(event.target.value));
    newArrayState[0] = Number(event.target.value);
    setArrayRefresh(newArrayState);
  };

  const handleDistanceChange = (event) => {
    setMinDistanceState(Number(event.target.value));
  };

  const handleValueInitialChangeCard = (event, index) => {
    if (index === 0) {
      setValueMinState(Number(event.target.value));
    }
    const newArrayState = [...generarArrayState];
    newArrayState[index] = Number(event.target.value);
    setArrayRefresh(newArrayState);
  };

  const handleRefreshSliderMain = () => {
    setModalOpen(true);
  };

  const handleRefreshSlider = () => {
    setLoading(true);
    setTimeout(() => {
      const [newArrayRefresh, newAwards, newValueProrateado, newErrors] = sortArrayAndMaintainLink(
        arrayRefresh,
        awards,
        valueProrateado,
        errors
      );
      const maxVal = Math.max(...arrayRefresh);
      setValueMaxState(maxVal + 10);
      setArrayRefresh(newArrayRefresh);
      setAwards(newAwards);
      setValueProrateado(newValueProrateado);
      setErrors(newErrors);
      setGenerarArrayState(arrayRefresh);
      setValueMinState(newArrayRefresh[0]);
      setLoading(false);
    }, 1000);
  };

  const handleInputChange = (event, index) => {
    const newAwards = [...awards];
    newAwards[index] = Number(event.target.value);
    setAwards(newAwards);
    const newErrors = [...errors];
    newErrors[index] = !event.target.value || event.target.value === '' || newAwards[index] === 0;
    setErrors(newErrors);
  };

  const handleCheckBoxChange = (event, index) => {
    const newValuess = [...valueProrateado];
    newValuess[index] = event.target.checked;
    setValueProrateado(newValuess);
  };

  const handleAddRange = (index) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    const newArray = [...arrayRefresh];
    newArray.splice(index + 1, 0, arrayRefresh[index] + minDistanceState);

    if (newArray[index + 1] >= newArray[index + 2]) {
      // eslint-disable-next-line no-plusplus
      for (let i = index + 2; i < newArray.length; i++) {
        newArray[i] += minDistanceState;
      }
    }

    const newAwards = [...awards];
    newAwards.splice(index + 1, 0, 0);

    const newProrateado = [...valueProrateado];
    newProrateado.splice(index + 1, 0, false);

    const newErrors = [...errors];
    newErrors.splice(index + 1, 0, true);

    setAwards(newAwards);
    setValueProrateado(newProrateado);
    setErrors(newErrors);

    // console.log('newArray', newArray);
    setRangeState(rangeState + 1);
    setArrayRefresh(newArray);
    setGenerarArrayState(newArray);
  };
  const handleRemoveRange = (index) => {
    setIndexDelete(index);
    setModalOpenDelete(true);
  };

  const sortArrayAndMaintainLink = (array, ...linkedArrays) => {
    const indices = Array.from({ length: array.length }, (_, i) => i);
    indices.sort((a, b) => array[a] - array[b]);
    const sortedArray = indices.map((i) => array[i]);
    const sortedLinkedArrays = linkedArrays.map((linkedArray) =>
      indices.map((i) => linkedArray[i])
    );
    return [sortedArray, ...sortedLinkedArrays];

    // const indices = Array.from({ length: array.length }, (_, i) => i);
    // indices.sort((a, b) => array[a] - array[b]);
    // const sortedArray = [...array];
    // // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < sortedArray.length - 1; i++) {
    //   if (sortedArray[i] === sortedArray[i + 1]) {
    //     sortedArray[i + 1] += 5;
    //     if (i + 2 < sortedArray.length && sortedArray[i + 1] >= sortedArray[i + 2]) {
    //       sortedArray[i + 2] += 5;
    //     }
    //   }
    // }
    // const sortedLinkedArrays = linkedArrays.map((linkedArray) =>
    //   indices.map((i) => linkedArray[i])
    // );

    // return [sortedArray, ...sortedLinkedArrays];
  };

  const handleSaveRange = () => {
    const result = awards.map((award, index) => ({
      award,
      prorateado: valueProrateado[index]
    }));
    if (errors.some((error) => error === true)) {
      showError('Los premios son obligatorios');
    } else {
      handleSetRange(result, generarArrayState, minDistanceState, valueMinState);
      onClose(false);
      setCreateRange(true);
    }
  };

  const handleConfirm = () => {
    setLoading(true);
    setTimeout(() => {
      setGenerarArrayState(generateArray(rangeState, valueMinState, minDistanceState));
      setArrayRefresh(generateArray(rangeState, valueMinState, minDistanceState));
      const maxVal = Math.max(...arrayRefresh);
      setValueMaxState(maxVal + 10);
      setLoading(false);
    }, 1000);
    setModalOpen(false);
  };

  const handleConfirmDelete = () => {
    if (generarArrayState.length > 2) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      const newArray = [...generarArrayState];
      newArray.splice(indexDelete, 1);

      const newAwards = [...awards];
      newAwards.splice(indexDelete, 1);

      const newProrateado = [...valueProrateado];
      newProrateado.splice(indexDelete, 1);

      const newErrors = [...errors];
      newErrors.splice(indexDelete, 1);

      setAwards(newAwards);
      setValueProrateado(newProrateado);
      setErrors(newErrors);

      setRangeState(rangeState - 1);
      setGenerarArrayState(newArray);
      setArrayRefresh(newArray);
    } else {
      showError('Los rangos no pueden ser menor a 2');
    }
    setModalOpenDelete(false);
  };

  const handleCancelDialog = () => {
    setModalOpen(false);
  };

  const marks =
    typeOption === 'P'
      ? [
          {
            value: 0,
            label: '0%'
          }
        ]
      : [
          {
            value: 0,
            label: '0'
          }
        ];

  const handleCloseModal = () => {
    if (selectAction === 'edit-form') {
      setEditmode(false);
    }
    onClose(false);
  };

  return (
    <Box>
      <Button
        color="primary"
        startIcon={<ArrowLeft />}
        variant="text"
        sx={{
          mt: 2,
          mb: 2
        }}
        onClick={() => setShowRange(false)}
      >
        Regresar
      </Button>
      <InputLabel
        sx={{
          marginBottom: 8,
          marginLeft: 0,
          color: 'black',
          fontWeight: 'bold'
        }}
      >
        Rangos
      </InputLabel>

      <Grid item xs={12}>
        {showRange && (
          <>
            {loading ? (
              <LinearProgress />
            ) : (
              <Slider
                track={false}
                aria-labelledby="track-false-range-slider"
                defaultValue={generarArrayState}
                onChange={handleChangeRange}
                valueLabelDisplay="on"
                max={valueMaxState}
                marks={marks}
                sx={{
                  marginBottom: 4,
                  color: 'grey',
                  '&.Mui-disabled': {
                    color: '#d24040'
                  }
                }}
                disabled
              />
            )}

            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <Card sx={{ marginBottom: 2, width: '300px', padding: 2 }}>
                <FormCustomerAwards
                  formik={formikAwards}
                  employeePositionList={employeePositionList}
                  configAwardsState={configAward}
                />
              </Card>

              <Card sx={{ width: '180px', marginBottom: 2 }}>
                <PropertyListItem>
                  <InputField
                    type="number"
                    label="Valor inicial"
                    value={valueMinState}
                    onChange={(event) => handleValueInitialChange(event)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleRefreshSliderMain();
                      }
                    }}
                    sx={{ marginBottom: 2 }}
                  />
                  <InputField
                    type="number"
                    label="Distancia mínima"
                    value={minDistanceState}
                    onChange={(event) => handleDistanceChange(event)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleRefreshSliderMain();
                      }
                    }}
                  />
                </PropertyListItem>
              </Card>
            </Box>

            {loading ? (
              <LinearProgress />
            ) : (
              <Box sx={{ display: 'flex', gap: '2em', flexWrap: 'wrap', marginTop: 3 }}>
                {selectAction === 'edit-form' ? (
                  <RangeAwardsDetail
                    arrayRefresh={arrayRefresh}
                    setArrayRefresh={setArrayRefresh}
                    handleValueInitialChangeCard={handleValueInitialChangeCard}
                    handleRefreshSlider={handleRefreshSlider}
                    awards={awards}
                    handleInputChange={handleInputChange}
                    valueProrateado={valueProrateado}
                    handleCheckBoxChange={handleCheckBoxChange}
                    handleRemoveRange={handleRemoveRange}
                    handleAddRange={handleAddRange}
                    generarArrayState={generarArrayState}
                  />
                ) : (
                  <RangeAwardsEdit
                    arrayRefresh={arrayRefresh}
                    handleValueInitialChangeCard={handleValueInitialChangeCard}
                    handleRefreshSlider={handleRefreshSlider}
                    awards={awards}
                    handleInputChange={handleInputChange}
                    valueProrateado={valueProrateado}
                    handleCheckBoxChange={handleCheckBoxChange}
                    handleRemoveRange={handleRemoveRange}
                    handleAddRange={handleAddRange}
                  />
                )}
              </Box>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <DialogActions>
          <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
            <LoadingButton
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              variant="contained"
              type="submit"
              onClick={handleSaveRange}
            >
              Guardar
            </LoadingButton>
            <Button
              color="secondary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={handleCloseModal}
              sx={{
                marginLeft: 1
              }}
            >
              Cancelar
            </Button>
          </AllowedGuard>
        </DialogActions>
      </Grid>
      <ConfirmationDialog
        message="Se cambiaran los rangos, ¿Seguro que quieres continuar?"
        onCancel={handleCancelDialog}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
      <ConfirmationDialog
        message="Se eliminara este rango, ¿Seguro que quieres continuar?"
        onCancel={() => setModalOpenDelete(false)}
        onConfirm={handleConfirmDelete}
        open={modalOpenDelete}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

RangeAwardsForm.propTypes = {
  showRange: Proptypes.bool,
  setShowRange: Proptypes.func,
  rangeQuantity: Proptypes.number,
  valueMin: Proptypes.number,
  valueMax: Proptypes.number,
  handleChangeRange: Proptypes.func,
  valueRange: Proptypes.array,
  handleSetRange: Proptypes.func,
  setCreateRange: Proptypes.func,
  onClose: Proptypes.func,
  awardsValue: Proptypes.array,
  minDistance: Proptypes.number,
  typeOption: Proptypes.string,
  employeePositionList: Proptypes.array,
  configAward: Proptypes.object,
  formikAwards: Proptypes.shape({
    values: Proptypes.object.isRequired,
    setFieldValue: Proptypes.func.isRequired,
    handleChange: Proptypes.func.isRequired,
    touched: Proptypes.object.isRequired,
    errors: Proptypes.object.isRequired
  }),
  setEditmode: Proptypes.func,
  selectAction: Proptypes.string
};

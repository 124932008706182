import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Grid } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useApp } from '../../../hooks/use-app';
import SurveyForm from '../../../components/survey/questionnaire/survey-form';
import { ArrowLeft } from '../../../icons/arrow-left';

export const SurveyCreate = () => {
  const { title } = useApp();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> {title('Cuestionario')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4, gap: '1em' }}>
            <Box>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeft />}
                onClick={() => navigate(-1)}
                // to={`${appUrlBase}/survey/questionnaire`}
                variant="text"
              >
                Regresar
              </Button>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid
              container
              item
              lg={12}
              spacing={3}
              sx={{
                height: 'fit-content',
                order: {
                  md: 2,
                  xs: 1
                }
              }}
              xs={12}
              xl={12}
            >
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    padding: 2,
                    marginY: 2
                  }}
                  variant="outlined"
                >
                  <SurveyForm />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { MultiActionsMenu } from '../../multi-actions-menu';
import GadminActions from '../../../contexts/gadmin-actions';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';

export const SurveySurveyDetailFilter = (props) => {
  const {
    selectedDocuments = [],
    view = 'all',
    onRefresh,
    handleClearSelectedDetail,
    handleUpdateScore,
    btnDisabled
  } = props;
  const { id } = useParams();
  // console.log('id', id);
  const [menuActions, setMenuActions] = useState([]);
  const afterProcessHandler = () => {
    onRefresh?.();
    handleClearSelectedDetail();
  };

  useEffect(() => {
    const actions = [];
    actions.push({
      action: GadminActions.G2_SURVEY_UP,
      permission: GadminActions.G2_SURVEY_UP,
      label: 'Eliminar',
      prompt: '¿Está seguro que desea Eliminar?',
      apiHandler: goalCustomeSurveyApi.getSurveySurveyDeleteLine,
      idDetail: id
    });
    setMenuActions(actions);
  }, [view]);

  return (
    <>
      <div>
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <MultiActionsMenu
            disabled={btnDisabled}
            menuActions={menuActions}
            selectedItems={selectedDocuments}
            selectedCount={selectedDocuments.length}
            afterProcessHandler={afterProcessHandler}
            handleUpdateScore={handleUpdateScore}
            sx={{
              display: selectedDocuments.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          />
        </Box>
      </div>
    </>
  );
};

SurveySurveyDetailFilter.propTypes = {
  selectedDocuments: PropTypes.array,
  view: PropTypes.string,
  onRefresh: PropTypes.func,
  handleClearSelectedDetail: PropTypes.func,
  handleUpdateScore: PropTypes.func,
  btnDisabled: PropTypes.bool
};

import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useApp } from '../hooks/use-app';
import { Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const GoalButton = ({
  startIcon,
  onClick,
  label,
  loading = false,
  color = 'inherit',
  variant = 'contained',
  disabled = false,
  children,
  component = 'button',
  to,
  iconOnly = false,
  showText = false,
  ...rest
}) => {
  const { showHelp } = useApp();

  const componentProps = to ? RouterLink : component;

  const { sx } = rest;
  if (showText)
    return (
      <Tooltip title={label}>
        <LoadingButton
          color={color}
          startIcon={startIcon}
          loading={loading}
          size={showHelp ? 'small' : 'large'}
          sx={{ px: 2, py: 2, mx: 0.8, ...sx }}
          variant={variant}
          onClick={onClick}
          disabled={disabled}
          component={componentProps}
          to={to}
        >
          {children || label || '-'}
        </LoadingButton>
      </Tooltip>
    );
  return (
    <Tooltip title={label}>
      {iconOnly ? (
        <LoadingButton
          color={color}
          startIcon={startIcon}
          loading={loading}
          size={showHelp ? 'small' : 'large'}
          sx={{ px: 2, py: 2, mx: 0.8, ...sx }}
          variant={'text'}
          onClick={onClick}
          disabled={disabled}
          component={componentProps}
          to={to}
        >
          {showHelp || variant == 'text' || children ? children ?? label : ''}
        </LoadingButton>
      ) : (
        <LoadingButton
          color={color}
          startIcon={startIcon}
          loading={loading}
          size={showHelp ? 'small' : 'large'}
          sx={{ px: 2, py: 2, mx: 0.8, ...sx }}
          variant={variant}
          onClick={onClick}
          disabled={disabled}
          component={componentProps}
          to={to}
        >
          {showHelp || variant == 'text' || children ? children ?? label : ''}
        </LoadingButton>
      )}
    </Tooltip>
  );
};

GoalButton.propTypes = {
  startIcon: PropTypes.element,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.elementType,
  to: PropTypes.string,
  iconOnly: PropTypes.bool,
  showText: PropTypes.bool
};

export default GoalButton;
export { GoalButton };

import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// import toast from 'react-hot-toast';
// import { format } from 'date-fns';
import { Button, Card, CardHeader, Divider, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { CurrencyDollar as CashIcon } from '../../../icons/currency-dollar';
import { Trash as TrashIcon } from '../../../icons/trash';
import { documnetStatus } from '../../../api/purchase-definitions';
import { documentApi } from '../../../api/document';
import { RenderIf } from '../../render-if';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { useApp } from '../../../hooks/use-app';

const processOrder = {
  CANCEL: 'Cancelar',
  RETRY: 'Retry',
  INVOICE: 'Invoice',
  REACTIVATE: 'Reactive'
};
export const PromoAssignedDetails = (props) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDialogAction, setOpenConfirmDialogAction] = useState(null);
  const { promoAssigned, setEditMode } = props;
  const { currency, currencySymbol, status } = promoAssigned;
  const [editable, setEditable] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const { showNotify, appUrlBase } = useApp();

  useEffect(() => {
    setEditable(status === documnetStatus.Borrador);
  }, [status]);
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };
  const processOrderHandler = useCallback(async () => {
    let response = '';
    switch (openConfirmDialogAction) {
      case processOrder.CANCEL:
        response = await documentApi.cancelOrder(promoAssigned.IdPedido);
        break;
      case processOrder.RETRY:
        response = await documentApi.retryOrder(promoAssigned.IdPedido);
        break;
      case processOrder.INVOICE:
        response = await documentApi.invoiceOrder(promoAssigned.IdPedido);
        break;
      case processOrder.REACTIVATE:
        response = await documentApi.reactivateOrder(promoAssigned.IdPedido);
        break;
      default:
    }
    if (response.success) {
      showNotify(response.message);
    } else {
      showNotify(response.message, 'error');
    }
    setOpenConfirmDialog(false);
  }, [openConfirmDialogAction]);

  const handleCancelOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de cancelar la orden?');
    setOpenConfirmDialogAction(processOrder.CANCEL);
    setOpenConfirmDialog(true);
  };
  const handleRetryOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de reprocesar la orden?');
    setOpenConfirmDialogAction(processOrder.RETRY);
    setOpenConfirmDialog(true);
  };
  const handleInvoiceOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de facturar la orden?');
    setOpenConfirmDialogAction(processOrder.INVOICE);
    setOpenConfirmDialog(true);
  };

  const handleReactivateOrder = async () => {
    setConfirmDialogMessage('¿Está seguro de reactivar la orden?');
    setOpenConfirmDialogAction(processOrder.REACTIVATE);
    setOpenConfirmDialog(true);
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Información del pedido" />
      <Divider />
      <Grid container>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Cliente">
              <Link
                color="inherit"
                component={RouterLink}
                underline="hover"
                variant="subtitle2"
                to={`${appUrlBase}/busines-partners/customers/${promoAssigned.CardCode}`}
              >
                {promoAssigned.CardCode}
              </Link>
              <Typography color="textSecondary" variant="body2">
                {promoAssigned.CardName}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Término de pago" value={`#${promoAssigned.PaymentName}`} />
            <PropertyListItem
              label="Almacén"
              value={`${promoAssigned.WhsCode} - ${promoAssigned.WhsName}`}
            />
            <PropertyListItem
              label="Vendedor"
              value={`${promoAssigned.SlpCode}-${promoAssigned.SlpName}`}
            />
            <PropertyListItem
              label="Mesa"
              value={`${promoAssigned.CodigoMesa}-${promoAssigned.mesaName}`}
            />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Numero"
              value={`${promoAssigned.tipo}#${promoAssigned.IdPedido}`}
            />
            <PropertyListItem
              label="Fecha de Pedido"
              value={moment(promoAssigned.FechaPedido).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Registro"
              value={moment(promoAssigned.FechaRegistro).format('DD/MM/YYYY  HH:mm:ss')}
            />
            <PropertyListItem
              label="Fecha de proceso"
              value={moment(promoAssigned.FechaMigrado).format('DD/MM/YYYY HH:mm:ss')}
            />
            <PropertyListItem label="Comentario" value={`${promoAssigned.ObservacionError}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Sede/Zona/Canal"
              value={`${promoAssigned.CodigoSede}/${promoAssigned.CodigoZona}/${promoAssigned.CanalSede}`}
              align="right"
            />
            <PropertyListItem
              label="Total Digitado"
              value={numeral(promoAssigned.TotalDigitado).format(`${currencySymbol} 0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Total Pedido"
              value={numeral(promoAssigned.TotalPedido).format(`${currency}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Total Migrado"
              value={numeral(promoAssigned.TotalMigrado).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Descuento"
              value={numeral(promoAssigned.Descuento).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Estado"
              value={`${promoAssigned.EstadoMigrado}/${promoAssigned.GCount}/${promoAssigned.Retries}`}
              align="right"
            />
          </PropertyList>
        </Grid>
        <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
          <Button
            color="primary"
            size="large"
            startIcon={<PlusIcon />}
            onClick={() => {
              setEditMode(true);
            }}
            variant="contained"
            disabled={!editable}
          >
            Editar
          </Button>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO}>
          <RenderIf condition={promoAssigned.EstadoMigrado !== 'G'}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleRetryOrder}
              variant="contained"
            >
              Reprocesar
            </Button>
          </RenderIf>
          <RenderIf
            condition={
              promoAssigned.tipo !== 1 &&
              promoAssigned.EstadoMigrado === 'F' &&
              promoAssigned.EstadoMigrado !== 'G'
            }
          >
            <Button
              color="primary"
              size="large"
              startIcon={<CashIcon />}
              onClick={handleInvoiceOrder}
              variant="contained"
            >
              Facturar
            </Button>
          </RenderIf>
          <RenderIf
            condition={promoAssigned.EstadoMigrado === 'F' || promoAssigned.EstadoMigrado === 'T'}
          >
            <Button
              color="primary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={handleCancelOrder}
              variant="contained"
            >
              Anular
            </Button>
          </RenderIf>
          <RenderIf condition={promoAssigned.EstadoMigrado === 'A'}>
            <Button
              color="primary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={handleReactivateOrder}
              variant="contained"
            >
              Reactivar
            </Button>
          </RenderIf>
        </AllowedGuard>
      </Grid>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={onCancelConfirmationDialog}
        onConfirm={processOrderHandler}
        open={openConfirmDialog}
        title="Confirmación importante"
        variant="warning"
      />
    </Card>
  );
};

PromoAssignedDetails.propTypes = {
  promoAssigned: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired
};

import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { YesNo } from '../../yes-no';

export const ReceiptGRLinesTable = (props) => {
  const { receiptInfo } = props;
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell sx={{ minWidth: 30 }}>ID</TableCell>
              <TableCell>Numero</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Origen</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Chofer</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Referencia</TableCell>
              <TableCell>Declarado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receiptInfo.grs?.map((item) => (
              <TableRow key={item.docnum}>
                <TableCell>{numeral(item.LineNum).format('0')}</TableCell>
                <TableCell sx={{ minWidth: 30 }}>
                  <Box xl={2}>
                    {/* <Link
                      color="inherit"
                      component={RouterLink}
                      underline="hover"
                      variant="subtitle2"
                      to={`${appUrlBase}/inventory/products//${item.ItemCode}`}
                    > */}
                    {item.docnum}
                    {/* </Link> */}
                    <Typography color="textSecondary" variant="body2">
                      {item.Dscription}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.sersun}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.numsun}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{moment(item.docdate).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{item.grType}</TableCell>
                <TableCell>
                  <Typography color="textPrimary" variant="body3">
                    {item.endaddress}
                  </Typography>
                </TableCell>
                <TableCell>{item.CardNameCho}</TableCell>
                <TableCell>
                  <Typography color="textPrimary" variant="body3">
                    {item.CardName}
                  </Typography>
                </TableCell>
                <TableCell>{moment(item.refdate).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  <YesNo value={item.declared} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

ReceiptGRLinesTable.propTypes = {
  receiptInfo: PropTypes.object.isRequired
};

import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  Card,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { goalIncidentsApi } from '../../../api/goal/incidents/goal-incidents';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { InputField } from '../../input-field';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import { RenderIf } from '../../render-if';
import { X as XIcon } from '../../../icons/x';
import { MaintenceDetail } from '../maintenance-detail';
import { useMounted } from '../../../hooks/use-mounted';
import { useTreeViewData } from '../../../hooks/use-tree-view-data';
import { CommonTreeView } from '../../common/common-tree-view';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import Scrollbar from '../../Scrollbar';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { Trash as TrashIcon } from '../../../icons/trash';
import { Check as CheckIcon } from '../../../icons/check';
import { ConstantStatus } from '../../../api/application-enums';

export const MaintenanceIncidentCategoriesForm = ({
  title,
  initialValues = {},
  initialData = [],
  configState = {},
  referenceType = []
}) => {
  const { companyState, showNotify, showError, currentCompany } = useApp();
  const [codeExistError, setCodeExistError] = useState(false);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);
  const [selectAction, setSelectAction] = useState('');

  const [editMode, setEditmode] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [incidentsValue, setIncidentsValue] = useState(initialValues.incidents || []);
  const [input, setInput] = useState({
    reference_type: ''
  });
  //   const [inputReference, setInputReference] = useState({
  //     reference_type: ''
  //   });
  const [modalOpen, setModalOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const handleAfterSave = () => {
    departamentsActions.onLoadRoot({ companyId: currentCompany });
  };

  const mounted = useMounted();

  const [departamentsState, departamentsActions] = useTreeViewData({
    rootApi: goalIncidentsApi.getIncidentsList,
    childrenApi: goalIncidentsApi.getIncidentsContainer,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando incidencias',
    mounted,
    defaults: {}
  });

  useEffect(() => {
    const loadTreeRoot = async () => {
      departamentsActions.onLoadRoot({ companyId: currentCompany });
    };
    loadTreeRoot();
  }, [currentCompany]);

  const onNodeSelection = (item) => {
    departamentsActions.onSelectNode(item);
    setSelectedNodeDataItem(item);
    setSelectAction('edit-node');
    formik.setFieldValue('name', item.name);
    formik.setFieldValue('code', item.code);
    formik.setFieldValue('level', item.level);
    formik.setFieldValue('parent_id', item.parent_id);
    formik.setFieldValue('company_id', item.company_id);
  };

  const formik = useFormik({
    initialValues: {
      company_id: currentCompany || '',
      name: initialValues.name || '',
      code: initialValues.code || '',
      level: initialValues.level || '1',
      parent_id: initialValues.parent_id || null,
      status: initialValues.status || ConstantStatus.ACTIVE,
      referenceType: initialValues.referenceType || []
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      code: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async () => {
      if (selectAction === 'edit-node') {
        handleEditNode();
        return;
      }
      if (selectedNodeDataItem === null && selectAction === 'create-node') {
        handleAddMajor();
      } else {
        handleAddNode();
      }
    }
  });

  const handleEditNode = async () => {
    const response = await goalIncidentsApi.updateIncidentsForm(
      selectedNodeDataItem.id,
      formik.values
    );
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      handleAfterSave();
      handleCancel();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const handleAddNode = async () => {
    const codeExists = initialData.some((item) => item.code === formik.values.code);
    if (codeExists) {
      setCodeExistError(true);
      setTimeout(() => {
        setCodeExistError(false);
      }, 3000);
      return;
    }
    if (!configState.success) {
      showError(configState?.message);
      return;
    }
    if (
      (configState?.data['max-level'] > parseInt(selectedNodeDataItem.level, 10) &&
        configState?.data['max-level']) ||
      configState?.data['max-level'] === -1
    ) {
      const newLevel = (parseInt(selectedNodeDataItem.level, 10) + 1).toString();
      const newObj = {
        ...formik.values,
        level: newLevel,
        parent_id: selectedNodeDataItem.id,
        referenceType: incidentsValue
      };
      console.log('new obj', newObj);
      //   const response = await goalIncidentsApi.postIncidentsForm({
      //     values: newObj
      //   });
      //   if (response.success) {
      //     showNotify(`${response?.message}. Cambio exitoso `);
      //     handleAfterSave();
      //     handleCancel();
      //   } else {
      //     showError(`${response?.message}. No se proceso la acción `);
      //   }
    } else {
      showError(configState.success ? 'Level maximo alcanzado' : configState?.message);
    }
  };

  const handleAddMajor = async () => {
    const codeExists = initialData.some((item) => item.code === formik.values.code);
    if (codeExists) {
      setCodeExistError(true);
      setTimeout(() => {
        setCodeExistError(false);
      }, 3000);
      return;
    }
    const newArrayIncidents = incidentsValue.map((item) => item.reference_type);
    formik.values.referenceType = newArrayIncidents;
    console.log('formik.values', formik.values);
    if (selectAction === 'false') {
      const response = await goalIncidentsApi.postIncidentsForm({
        values: formik.values
      });
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handleAfterSave();
        handleCancel();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
  };

  const handleCancel = () => {
    setEditmode(false);
    setSelectAction('');
  };

  const handleAddNodeMajor = () => {
    formik.setFieldValue('name', '');
    formik.setFieldValue('code', '');
  };

  const handleInputChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setInput({ ...input, [e.target.name]: value });
  };

  const handleAddItem = () => {
    if (isEditing) {
      const newTodos = [...incidentsValue];
      newTodos[editIndex] = input;
      setIncidentsValue(newTodos);
      setIsEditing(false);
      setEditIndex(null);
    } else {
      setIncidentsValue([...incidentsValue, input]);
    }
    setInput({ reference_type: '' });
  };

  const handleDeleteItem = (index) => {
    const newTodos = [...incidentsValue];
    newTodos.splice(index, 1);
    setIncidentsValue(newTodos);
    setModalOpen(false);
  };

  const handleEditItem = (index) => {
    setIsEditing(true);
    setEditIndex(index);
    setInput(incidentsValue[index]);
  };

  const handleCanceled = () => {
    setIsEditing(false);
    setEditIndex(null);
    setInput({ reference_type: '' });
  };

  const handleOpenCornfirm = (index) => {
    setModalOpen(true);
    setIdDelete(index);
  };

  const renderReferenceType = () => (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <InputField
          select
          label="Tipo de referencia"
          name="reference_type"
          value={input.reference_type}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: '1em', minWidth: '150px' }}
        >
          {referenceType.map((item) => (
            <MenuItem key={item.code} value={item}>
              {item.name}
            </MenuItem>
          ))}
        </InputField>
        <Button
          size="medium"
          variant="contained"
          onClick={handleAddItem}
          disabled={!input.reference_type}
          startIcon={isEditing ? <CheckIcon /> : <PlusIcon />}
          sx={{
            marginTop: 2,
            maxHeight: '3em'
          }}
        />
        {isEditing && (
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            onClick={handleCanceled}
            startIcon={<XIcon />}
            sx={{
              marginTop: 2,
              maxHeight: '3em'
            }}
          />
        )}
      </Box>
      <Card>
        <Scrollbar>
          <Table sx={{ minWidth: 500, marginTop: 1, marginLeft: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Tipo de Referencia</TableCell>
                <TableCell> &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incidentsValue.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item?.reference_type?.name}</TableCell>
                  <TableCell>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditItem(index)}>
                      <PencilIcon color="secondary" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleOpenCornfirm(index)}
                    >
                      <TrashIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  );

  return (
    <Box
      sx={{
        minWidth: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingX: 5,
        marginBottom: 3
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item md={6} xs={12}>
            <PropertyList>
              <CommonTreeView
                expandedItems={expandedItems}
                setExpandedItems={setExpandedItems}
                title="Incidencias..."
                onSelectNode={onNodeSelection}
                data={departamentsState.data}
                setSelectedNodeDataItem={setSelectedNodeDataItem}
              />
            </PropertyList>
          </Grid>
          <Grid item md={6} xs={12}>
            <RenderIf
              condition={editMode}
              no={
                <MaintenceDetail
                  company={companyState?.data}
                  setEditMode={setEditmode}
                  item={selectedNodeDataItem}
                  setSelectAction={setSelectAction}
                  handleAddNodeMajor={handleAddNodeMajor}
                />
              }
            >
              <InputField
                label="Código"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                sx={{ marginBottom: '1em' }}
                // disabled={selectAction === 'edit-node'}
              />
              {codeExistError && (
                <Alert sx={{ marginBottom: 2 }} severity="warning">
                  Este Código ya existe
                </Alert>
              )}
              <CompanyInputDisabled currentCompany={currentCompany} />
              <InputField
                label="Nombre"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ marginBottom: '1em' }}
              />

              {/* {renderReferenceType()} */}

              {selectedNodeDataItem &&
                selectedNodeDataItem.level === 1 &&
                selectAction === 'create-node' &&
                renderReferenceType()}

              {selectedNodeDataItem &&
                selectedNodeDataItem.level === 2 &&
                selectAction === 'edit-node' &&
                renderReferenceType()}
            </RenderIf>
            <DialogActions>
              <AllowedGuard permission={GadminActions.gCC_TEAM_BRANDS_UP}>
                {editMode && selectAction !== 'create-node' && (
                  <>
                    <LoadingButton
                      color="primary"
                      size="large"
                      startIcon={<PencilIcon />}
                      variant="contained"
                      loading={formik.isSubmitting}
                      disabled={selectAction !== 'edit-node'}
                      type="submit"
                    >
                      Guardar
                    </LoadingButton>
                    <Button
                      color="secondary"
                      size="large"
                      startIcon={<XIcon />}
                      onClick={() => setEditmode(false)}
                      sx={{
                        marginLeft: 1
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                )}

                {selectAction === 'create-node' && (
                  <LoadingButton
                    color="primary"
                    size="large"
                    startIcon={<PlusIcon />}
                    variant="contained"
                    loading={formik.isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </LoadingButton>
                )}
                {selectAction === 'create-node' && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<XIcon />}
                    onClick={handleCancel}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cancelar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <ConfirmationDialog
        message="Estas seguro de eliminar esta referencia"
        onCancel={() => setModalOpen(false)}
        onConfirm={() => handleDeleteItem(idDelete)}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

MaintenanceIncidentCategoriesForm.propTypes = {
  initialValues: Proptypes.object,
  title: Proptypes.string,
  initialData: Proptypes.array,
  configState: Proptypes.object,
  referenceType: Proptypes.array
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import { Box, Dialog, ImageListItem } from '@mui/material';
import { appUrls } from '../../api/app-definitions';
import GoalButton from '../goal-button';
import { RenderIf } from '../render-if';
import LoadingContent from './loading-content';

export const CommonImageGallery = ({
  photos = [],
  sizeImage = '150px',
  canEdit = false,
  onSelectImage
}) => {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photosState, setPhotosState] = useState([]);
  useEffect(() => {
    const uniqueId = 'goal-image';
    const newUrlMini = `${appUrls.MEDIA_HOST}/q/${photos[0]?.image_code}.${uniqueId}-thumb`;
    const newUrlFullScreen = `${appUrls.MEDIA_HOST}/q/${photos[0]?.image_code}.${uniqueId}`;

    setPhotosState([
      {
        height: photos[0]?.height,
        id: photos[0]?.id,
        src: newUrlMini,
        src2: newUrlFullScreen,
        title: photos[0]?.title,
        width: photos[0]?.width
      }
    ]);
  }, [photos]);

  const openLightbox = () => {
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  if (!photos) return <LoadingContent message="No hay imágenes" />;

  return (
    <>
      <Box sx={{ maxWidth: sizeImage, maxHeight: sizeImage, objectFit: 'contain' }}>
        <Gallery photos={photosState} onClick={openLightbox} direction="row" margin={10} />
      </Box>
      <RenderIf condition={canEdit}>
        <GoalButton sx={{ marginTop: 1 }} variant="contained" onClick={() => onSelectImage(true)}>
          Cambiar imagen
        </GoalButton>
      </RenderIf>
      <Dialog open={viewerIsOpen} onClose={closeLightbox}>
        <ImageListItem key={'item.img'} cols={1} rows={1}>
          <img
            src={photosState[0]?.src2}
            style={{
              width: 'auto',
              height: '100%',
              objectFit: 'contain'
            }}
            alt="Preview"
          />
        </ImageListItem>
      </Dialog>
      {/* <ModalGateway>
        {viewerIsOpen && photos.length > 0 ? (
          <Modal onClose={closeLightbox}>
            <img
              src={photos[0].src2}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
              alt="Preview"
            />
          </Modal>
        ) : null}
      </ModalGateway> */}
    </>
  );
};

CommonImageGallery.propTypes = {
  photos: PropTypes.array,
  sizeImage: PropTypes.string,
  canEdit: PropTypes.bool,
  onSelectImage: PropTypes.func
};

import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Typography } from '@mui/material';
// import gtm from '../../lib/gtm';

import moment from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useApp } from '../../../hooks/use-app';
import { useQuery } from '../../../hooks/use-query';
import { HRInvoicingTable } from '../../../components/delivery/hr/hrs-open-table';
import { HRInvoicingStats } from '../../../components/delivery/hr/hrs-open-stats';

export const HRInvoicing = () => {
  const query = useQuery();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };
  const app = useApp();

  const [currentDate, setCurrentDate] = useState(queried.date ? moment(queried.date) : new Date());
  const handleChangeDate = (newValue) => {
    setCurrentDate(newValue);
  };
  // useEffect(() => {
  //   if (queried.query) handleSetController.setMainFilter({ queried });
  // }, [queried]);

  const handleDateChanged = (newValue) => {
    if (!newValue) return;
    setCurrentDate(newValue);
    // const current = moment(newValue).format('YYYY-MM-DD');
    // if (current !== controller.orderDate) {
    //   handleSetController.setMainFilter({ ...queried, orderDate: current });
    // }
    // console.log('currentDate', moment(newValue).format('YYYY-MM-DD'));
  };

  return (
    <>
      <Helmet>
        <title>{app.title('HR Facturando')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Facturando
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={currentDate}
                  onChange={handleChangeDate}
                  onAccept={handleDateChanged}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <HRInvoicingStats />
          <HRInvoicingTable />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

import { useCallback, useEffect, useState } from 'react';

export const useDataBrowser = ({ sourceApi, loadingMessage, mounted, onPostRefresh, defaults }) => {
  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    searchText: '',
    query: '',
    sort: 'desc',
    sortBy: 'ItemName',
    view: 'all',
    reload: true,
    refresh: true,
    ...defaults
  });

  const getDocument = useCallback(async () => {
    if (controller.refresh) {
      setDocumentState(() => ({
        ...documentState,
        isLoading: true,
        message: loadingMessage,
        displayLoading: true,
        displayError: false,
        displayUnavailable: false
      }));
      try {
        const result = await sourceApi({
          filters: controller.filters,
          page: controller.page,
          query: controller.query,
          sort: controller.sort,
          sortBy: controller.sortBy,
          view: controller.view,
          reload: controller.reload,
          apiData: controller.apiData,
          searchText: controller.searchText,
          apiResponse: controller.apiResponse,
          mainFilter: controller.mainFilter,
          id: controller.id,
          companyId: controller.companyId ?? 0,
          idsToExclude: controller.idsToExclude ?? ''
        });
        if (mounted.current) {
          setDocumentState(() => ({
            ...result,
            isLoading: false,
            reload: false,
            refresh: false,
            initialLoad: true,
            displayLoading: false,
            displayError: !result.success,
            displayUnavailable: result.success && !result.documents?.length
          }));
          if (controller.reload) {
            controller.apiData = result.apiData;
            controller.apiResponse = result.apiResponse;
          }
          onPostRefresh?.();
        }
      } catch (err) {
        console.error('useDataError', err);
        if (mounted.current) {
          setDocumentState(() => ({
            isLoading: false,
            error: err.message,
            real: true,
            displayError: true
          }));
        }
      }
      controller.refresh = false;
    }
  }, [controller]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);

  const handleRefresh = () => {
    setController({
      ...controller,
      filters: [],
      page: 0,
      query: '',
      sort: 'desc',
      sortBy: 'ItemName',
      view: defaults?.view || 'all',
      reload: true,
      refresh: true
    });
  };

  const handleViewChange = (newView) => {
    setController({
      ...controller,
      page: 0,
      view: newView,
      reload: true,
      refresh: true
    });
  };
  const handleQueryChange = (newQuery) => {
    if (documentState.initialLoad) {
      setController({
        ...controller,
        page: 0,
        query: newQuery,
        reload: false,
        refresh: true
      });
    }
  };
  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      reload: false,
      refresh: true
    });
  };
  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      reload: false,
      refresh: true
    });
  };
  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
      reload: false,
      refresh: true
    });
  };

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
      reload: false,
      refresh: true
    });
  };
  const handleSetController = (options) => {
    setController({
      ...controller,
      ...options,
      refresh: true
    });
  };

  const setMainFilter = (newFilter) => {
    handleSetController({ mainFilter: { ...controller.mainFilter, ...newFilter }, reload: true });
    // handleSetController({ mainFilter: newFilter });
  };

  const setSearchText = (newSearchText) => {
    handleSetController({ searchText: newSearchText, reload: true });
  };

  const onControllerChange = {
    setMainFilter,
    setSearchText
  };

  return [
    documentState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    onControllerChange
  ];
};

import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Button } from '@mui/material';

import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { useApp } from '../hooks/use-app';
import Logo from '../components/Logo';
import { useQuery } from '../hooks/use-query';
import CopyToClipboardButton from '../components/common/copy-to-clipboard';
import { useAuth } from '../hooks/use-auth';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default () => {
  const query = useQuery();
  const queried = {
    client_id: query.get('client_id'),
    redirect_uri: query.get('redirect_uri'),
    response_type: query.get('response_type'),
    session_state: query.get('session_state'),
    code: query.get('code')
  };
  const { title, authenticationUrl } = useApp();
  const { isLogged } = useAuth();

  const label = isLogged ? 'Dashboard' : 'Iniciar sesión';

  let content = (
    <MHidden width="smUp">
      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        Vamos a validar tu identidad
        <Link variant="subtitle2" component={RouterLink} to="/login">
          Iniciar sesión
        </Link>
      </Typography>
    </MHidden>
  );

  if ((!queried.client_id || !queried.redirect_uri || !queried.response_type) && !queried.code) {
    // return redirect('/login');
    //  content = <Navigate to="/login" />;
    content = null;
  }
  if (queried.code) {
    content = (
      <Stack sx={{ mb: 5 }}>
        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
          Excelente, ya puedes cerrar esta ventana. Tu código es:
        </Typography>
        <Typography variant="h4" gutterBottom align="center" sx={{ mt: 3 }}>
          {queried.code}
        </Typography>
        <CopyToClipboardButton text={queried.code} />
      </Stack>
    );
  }

  useEffect(() => {
    if (queried.client_id && queried.redirect_uri && queried.response_type && !queried.code) {
      const baseUrl = queried.redirect_uri;
      const uri = `${authenticationUrl}?client_id=${queried.client_id}&redirect_uri=${baseUrl}&response_type=${queried.response_type}`;
      document.location.href = uri;
    }
  }, []);

  return (
    <RootStyle title={title('Autorizar')}>
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} color="textSecondary">
            Autorización
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Logo minWidth={240} minHeight={120} />
          <Card sx={{ mt: 5, px: 4, py: 5 }}>{content}</Card>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            component={RouterLink}
            to="/login"
            sx={{ mt: 3 }}
          >
            {label}
          </Button>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../hooks/use-auth';
import { useApp } from '../hooks/use-app';

export const GuestGuard = ({ children }) => {
  const { isLogged } = useAuth();
  const { appUrlBase } = useApp();

  const location = useLocation();

  if (isLogged && location.pathname === '/login') {
    return <Navigate to={`${appUrlBase}/dashboard`} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';
import CustomerReturnTableHeader from './customer-return-table-header';
import CustomerReturnTableRow from './customer-return-table-row';
import CustomerReturnTableFooter from './customer-return-table-footer';

// const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 0,
    borderColor: '#bff0fd'
  }
});

const CustomerReturnLinesTable = ({ customerReturn }) => (
  <View style={styles.tableContainer}>
    <CustomerReturnTableHeader />
    <CustomerReturnTableRow lines={customerReturn.lines} />
    {/* <customerReturnTableBlankSpace rowsCount={tableRowsCount - customerReturn.lines.length} /> */}
    <CustomerReturnTableFooter lines={customerReturn.lines} />
  </View>
);

CustomerReturnLinesTable.propTypes = {
  customerReturn: PropTypes.object
};
export default CustomerReturnLinesTable;

import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Skeleton,
  Divider,
  TableSortLabel
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { RenderIf } from '../../render-if';
import { NumberColor } from '../../number-color';
import { HrMenu } from '../hr/hr-menu';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import { useApp } from '../../../hooks/use-app';
import { Pagination } from '../../pagination';

const columns = [
  {
    id: 'HR',
    disablePadding: true,
    label: 'HR'
  },
  {
    id: 'fecprog',
    label: 'F Programación'
  },
  {
    id: 'fecdsp',
    label: 'F Despacho'
  },
  {
    id: 'sede',
    label: 'Zona'
  },
  {
    id: 'plaveh',
    label: 'Placa'
  },
  {
    id: 'pendings',
    label: 'Pendientes'
  },
  {
    id: 'invoiced',
    label: 'Facturados'
  },
  {
    id: 'documents',
    label: 'Documentos'
  },
  {
    id: 'Estado',
    label: 'Estado'
  },
  {
    id: 'action',
    label: ''
  }
];

export const UploadTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const { appUrlBase } = useApp();
  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        maxHeight: 1000,
        overflow: 'auto'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((item, index) => (
              <TableRow
                hover
                key={index}
                selected={
                  !!selectedDocuments.find((selectedCustomer) => selectedCustomer === item.HR)
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find((selectedCustomer) => selectedCustomer === item.HR)
                    }
                    onChange={(event) => onSelect(event, item.HR)}
                  />
                </TableCell>
                <TableCell>{item.HR}</TableCell>
                <TableCell>{moment(item.fecprog).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{moment(item.fecdsp).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{item.sede}</TableCell>
                <TableCell>{item.plaveh}</TableCell>
                <TableCell>
                  <RenderIf
                    condition={item.pendings > 0}
                    no={<NumberColor value={item.pendings} minColor="success.main" format="0" />}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${appUrlBase}/${'delivery/invoicing/pendings/'}${item.HR}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      {item.pendings}
                    </Link>
                  </RenderIf>
                </TableCell>

                <TableCell>
                  <RenderIf
                    condition={item.invoiced > 0}
                    no={<NumberColor value={item.invoiced} minColor="success.main" format="0" />}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${appUrlBase}/${'delivery/invoicing/invoiced/'}${item.HR}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      {item.invoiced}
                    </Link>
                  </RenderIf>
                </TableCell>
                <TableCell>
                  <RenderIf
                    condition={item.documents > 0}
                    no={<NumberColor value={item.documents} minColor="success.main" format="0" />}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${appUrlBase}/${'delivery/invoicing/all/'}${item.HR}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      {item.documents}
                    </Link>
                  </RenderIf>
                </TableCell>
                <TableCell>{item.Estado}</TableCell>
                <TableCell>
                  <HrMenu id={item.HR} vehicle={item.plaveh} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

UploadTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

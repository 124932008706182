import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { CustomerOrderLinesTable } from './customer-order-lines-table';

export const CustomerOrderLines = (props) => {
  const { orderInfo } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Líneas" />
      <Divider />
      <CustomerOrderLinesTable orderInfo={orderInfo} />
    </Card>
  );
};

CustomerOrderLines.propTypes = {
  orderInfo: PropTypes.object.isRequired
};

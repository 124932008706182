import Proptypes from 'prop-types';
import { Button, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { documentStatusViews } from '../../../api/app-definitions';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { Status } from '../../status';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { StatusColor } from '../../contest/contest-definition';

export const SurveySumaryDetail = ({ item, setEditMode }) => (
  <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
    <CardHeader title="Detalle de la encuesta" />
    <PropertyListItem label="">
      <Status color={StatusColor(item.status)} label={item.statusName} />
    </PropertyListItem>
    <Divider />
    <Grid container>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem label="Empresa" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.company_id || '--'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Título" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.title || '--'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Fecha" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.date || '--'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Equipos de ventas" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.sales_team_info.name || '--'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Puntos" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.point || '--'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Ejecutados" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.requestUpdateBy || '--'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
    </Grid>
    <AllowedGuard permission={GadminActions.G2_SURVEY_UP}>
      <Button
        color="primary"
        size="large"
        startIcon={<PencilIcon />}
        onClick={() => {
          setEditMode(true);
        }}
        variant="contained"
        disabled={item.status !== documentStatusViews.VIEW_DRAFT}
      >
        Editar
      </Button>
    </AllowedGuard>
  </Card>
);

SurveySumaryDetail.propTypes = {
  item: Proptypes.object,
  setEditMode: Proptypes.func
};

import { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { Box, LinearProgress, Slider } from '@mui/material';

export const SliderCommon = ({ ranges, stateBool = true }) => {
  const [loading, setLoading] = useState(stateBool);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Box sx={{ marginTop: 5 }}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Slider
          track={false}
          aria-labelledby="track-false-range-slider"
          defaultValue={ranges}
          valueLabelDisplay="on"
          sx={{ marginTop: 2 }}
          max={ranges[ranges.length - 1]}
          disabled
        />
      )}
    </Box>
  );
};

SliderCommon.propTypes = {
  ranges: Proptypes.array,
  stateBool: Proptypes.bool
};

import Proptypes from 'prop-types';
// import numeral from 'numeral';
import { Link, Link as RouterLink } from 'react-router-dom';
import { v4 } from 'uuid';

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Divider,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import { Status } from '../status';
import Scrollbar from '../Scrollbar';
import { RenderIf } from '../render-if';
import { useApp } from '../../hooks/use-app';
// import Label from '../Label';

const columns = [
  { id: 'code', label: 'Codigo', alignRight: false },
  { id: 'name', label: 'Descpcion', alignRight: false },
  { id: 'type', label: 'Entidad', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'company', label: 'Empresa', alignRight: false },
  { id: '' }
];
const statusLines = [
  { color: 'info.main', label: 'Activo', value: 'Y' },
  { color: 'error.main', label: 'Inactivo', value: 'N' }
];

export const SearchTable = (props) => {
  const {
    error,
    items = [],
    itemsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedItems = [],
    sort = 'desc',
    sortBy = 'ItemName',
    documentState
  } = props;
  const { displayLoading, displayError, displayUnavailable } = documentState;
  const { appUrlBase } = useApp();

  const getLinkRoute = (objType, docEntry, code) => {
    // check if onjtype is any of the following array
    if (!['4', '2'].includes(objType)) return '#';
    if (objType === '2') return `${appUrlBase}/busines-partners/customers/${code}`;
    if (objType === '4') return `${appUrlBase}/inventory/products/${code}`;
    return '#';
  };
  const mediaUrl = process.env.REACT_APP_MEDIA_API_URL;

  return (
    <Card variant="outlined">
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <Scrollbar>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <RenderIf condition={!!onSelect} no="#">
                    <Checkbox
                      checked={items.length > 0 && selectedItems.length === items.length}
                      disabled={isLoading}
                      indeterminate={
                        selectedItems.length > 0 && selectedItems.length < items.length
                      }
                      onChange={onSelectAll}
                    />
                  </RenderIf>
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, index) => {
                const newId = v4();

                const statusLine = statusLines.find((variant) => variant.value === item.status);
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
                  >
                    <RenderIf condition={!!onSelect} no={<TableCell>{index + 1}</TableCell>}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
                          onChange={(event) => onSelect(event, item.id)}
                        />
                      </TableCell>
                    </RenderIf>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        underline="hover"
                        variant="subtitle2"
                        to={getLinkRoute(item.objType, item.docEntry, item.code)}
                      >
                        {item.code}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <RenderIf
                          condition={!!item.mediaCode}
                          no={
                            <Avatar
                              alt={item.name}
                              src={
                                item.objType === 2
                                  ? '/static/mock-images/avatars/avatar_default.jpg'
                                  : '/static/logo.png'
                              }
                            />
                          }
                        >
                          <Avatar
                            alt={item.name}
                            src={`${mediaUrl}/q/${item.mediaCode}.${newId}${'-thumb'}`}
                          />
                        </RenderIf>
                        <Typography variant="subtitle2" noWrap>
                          {item.name}
                        </Typography>
                      </Stack>
                      {item.Name}
                    </TableCell>
                    <TableCell align="left">{item.entity}</TableCell>
                    <TableCell align="left">
                      <Status color={statusLine?.color} label={statusLine?.label} />
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" noWrap>
                        {item.companyName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
        {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {displayUnavailable && (
          <ResourceUnavailable
            message={documentState.message}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={isLoading}
          onPageChange={onPageChange}
          page={page}
          rowsCount={itemsCount}
        />
      </Box>
    </Card>
  );
};

SearchTable.propTypes = {
  items: Proptypes.array,
  itemsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedItems: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  documentState: Proptypes.object
};

import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { fPercent } from '../utils/formatNumber';

function LinearProgressWithLabel({ value, color, ...props }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value * 100} color={color} {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {fPercent(value)}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

export default function LinearWithValueLabel({ value, color = 'primary', infinity = false }) {
  const [progress, setProgress] = React.useState(value);
  // console.log('value', value);

  React.useEffect(() => {
    if (infinity) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {infinity ? (
        <LinearProgress color={color} value={progress} />
      ) : (
        <LinearProgressWithLabel value={value} color={value > 0.9 ? 'secondary' : 'primary'} />
      )}
    </Box>
  );
}

LinearWithValueLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  infinity: PropTypes.bool
};

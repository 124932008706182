import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { Status } from '../status';
import { GetCompanyName } from '../common/get-company-name';
import { useApp } from '../../hooks/use-app';
import { CommonImageGallery } from '../common/common-image-gallery';
import { CommonImageSelector } from '../common/common-image-selector';
import { goalSalesTeamsApi } from '../../api/goal/sales-teams/goal-sales-teams';
import { useAuth } from '../../hooks/use-auth';
import { RenderIf } from '../render-if';

export const SalesTeamsSumaryDetail = ({ item, setEditMode, onRefresh }) => {
  const statusColorDefinitions = {
    D: 'info.main',
    P: 'warning.main',
    A: 'success.main',
    C: 'error.light',
    L: 'error.main',
    R: 'error.dark'
  };

  const { hasPermission } = useAuth();

  const canEdit = hasPermission(GadminActions.gCC_SALE_TEAMS_UP);
  const { companyState } = useApp();

  const [selectingImage, setSelectingImage] = useState(false);

  const StatusColor = (value) => statusColorDefinitions[value] || 'warning.main';

  const handleAfterImageUpload = async (image) => {
    const result = await goalSalesTeamsApi.postSalesTeamImage({
      id: item.id,
      companyId: item.company_id,
      image_code: image.code,
      company_id: item.company_id,
      name: item.name,
      code: item.code
    });
    if (result.success) {
      item.image_code = image.code;
      onRefresh();
    }
  };
  return (
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <CardHeader title="Información general del equipo de ventas" />
      <PropertyListItem label="">
        <Status color={StatusColor(item.status)} label={item.status === 'A' ? 'Activo' : '--'} />
      </PropertyListItem>
      <Divider />
      <Grid container>
        <Grid item md={6} xs={12}>
          <PropertyList>
            <PropertyListItem label="Empresa">
              <Typography color="textSecondary" variant="body2">
                {item.company_id} - {GetCompanyName(item.company_id, companyState)}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Nombre" value={item.name} />
            <PropertyListItem label="Prefijo">
              <Typography color="textSecondary" variant="body2">
                {item.prefix}
              </Typography>
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item md={6} xs={12}>
          <PropertyList>
            <PropertyListItem label="Canal preferido">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Lider">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="División">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Catálogo">
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <PropertyList>
            {item.image_code && !selectingImage ? (
              <>
                <CommonImageGallery
                  photos={[
                    {
                      id: item.id,
                      width: 1,
                      height: 1,
                      title: item.name,
                      image_code: item.image_code
                    }
                  ]}
                  canEdit={canEdit}
                  onSelectImage={setSelectingImage}
                />
              </>
            ) : (
              <>
                <RenderIf condition={canEdit}>
                  <CommonImageSelector afterUpload={handleAfterImageUpload} />
                  {item.image_code && (
                    <Button color="secondary" onClick={() => setSelectingImage(false)}>
                      Cancelar
                    </Button>
                  )}
                </RenderIf>
              </>
            )}
          </PropertyList>
        </Grid>

        <Grid item xs={12}>
          <AllowedGuard permission={GadminActions.gCC_SALE_TEAMS_UP}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={() => {
                setEditMode(true);
              }}
              variant="contained"
              disabled={item.status !== 'A'}
            >
              Editar
            </Button>
          </AllowedGuard>
        </Grid>
      </Grid>
    </Card>
  );
};

SalesTeamsSumaryDetail.propTypes = {
  item: PropTypes.object,
  setEditMode: PropTypes.func,
  onRefresh: PropTypes.func
};

import PropTypes from 'prop-types';
import { Button, Card, CardHeader, Dialog, Divider, FormHelperText, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { compareRecordChanged } from '../../../hooks/use-item-change';
import { customerReturnsApi } from '../../../api/customer-return';
import { RenderIf } from '../../render-if';
import { CustomerReturnStatusCode } from '../../../api/customer-return-definitions';

export const CustomerReturnLineEdit = (props) => {
  const { open, onClose, onExited, afterSubmit, lineState, refreshLine, document, ...other } =
    props;

  const handleChanges = async (values) => {
    let saved = { success: false };
    try {
      const changes = compareRecordChanged(lineState, values);
      await customerReturnsApi.upCustomerReturnLine({
        id: lineState.id,
        ...changes
      });
      refreshLine({ ...lineState, ...changes });
      saved = { ...saved, success: true };
    } catch (error) {
      saved = { ...saved, error };
    }
    return saved;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      objType: lineState?.objType || -1,
      docEntry: lineState?.docEntry || -1,
      lineMemo: lineState?.lineMemo || 'Recogido conforme',
      quantity: lineState?.quantity || 0,
      submit: null
    },
    validationSchema: Yup.object().shape({
      lineMemo: Yup.string()
        .min(10, 'Mínimo 10 characteres')
        .max(255)
        .required('Mensage es requerido'),
      quantity: Yup.number()
        .required('Mensage es requerido')
        .positive('Solo números positivos')
        .integer()
        .min(0)
        .max(lineState.openQuantity)
    }),
    onSubmit: async (values, helpers) => {
      try {
        const saved = await handleChanges(values);
        if (!saved.success) throw new Error(saved.error);
        toast.success(`Cuceso guardado`);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        afterSubmit();
        onClose();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 500,
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <Card variant="outlined">
        <CardHeader title="Modificar linea de OR" />
        <Divider />
        <form
          onSubmit={async (values, helpers) => {
            await formik.handleSubmit(values, helpers);
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6} xs={12}>
              <PropertyList>
                <PropertyListItem
                  label="Producto"
                  value={`${lineState.itemCode} - ${lineState.itemName}`}
                />
                <RenderIf
                  condition={document.procStatus === CustomerReturnStatusCode.IN_DELIVERY}
                  no={
                    <PropertyListItem label="Cantidad recogida" value={`${lineState.quantity}`} />
                  }
                >
                  <PropertyListItem label="Cantidad recogida">
                    <AllowedGuard permission={GadminActions.G2_CD_LINE_UP_QUANTITY}>
                      <InputField
                        error={Boolean(formik.touched.quantity && formik.errors.quantity)}
                        helperText={formik.touched.quantity && formik.errors.quantity}
                        name="quantity"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.quantity}
                      />
                    </AllowedGuard>
                  </PropertyListItem>
                </RenderIf>
                <PropertyListItem label="Comentario">
                  <InputField
                    error={Boolean(formik.touched.lineMemo && formik.errors.lineMemo)}
                    helperText={formik.touched.lineMemo && formik.errors.lineMemo}
                    name="lineMemo"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.lineMemo}
                  />
                </PropertyListItem>
                <PropertyListItem
                  label="Motivo"
                  value={`${lineState.reasonCode} - ${lineState.reasonName}`}
                />
              </PropertyList>
            </Grid>
            <Grid item md={8} xs={12}>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <AllowedGuard permission={GadminActions.G2_CD_LINE_UP}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                >
                  Guardar
                </Button>
              </AllowedGuard>
              <Button
                color="secondary"
                size="large"
                startIcon={<TrashIcon />}
                onClick={async () => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Dialog>
  );
};

CustomerReturnLineEdit.propTypes = {
  lineState: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  refreshLine: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired
  // setEditMode: PropTypes.func.isRequired
};

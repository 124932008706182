import { appDefinitions } from '../app-definitions';
import { dataLoader } from './../data-loader';

/* eslint class-methods-use-this:0 */

class UsersApi {
  async getUsers(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `auth/users`,
      cacheItem: 'users',
      apiParams: {
        companyId,
        type: 'list-users',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'first_name' },
        { type: 'string', name: 'username' },
        { type: 'string', name: 'full_name' },
        { type: 'string', name: 'sap_username' }
      ],
      ...options
    });
  }

  async getUser({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getRolesOfUser({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}/roles`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getUserInSap({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}/sb1-user`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getUserPermissionsByTheirRoles({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}/permissions`,
      params: { companyId: companyId, view: view, page: page, per_page: perPage, search: search }
    });
    return response;
  }
}

export const usersApi = new UsersApi();

import GadminActions from '../../../contexts/gadmin-actions';
import { GadminFileUpload } from '../../../services/gadmin';
import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalCustomerSalesgApi {
  async getContestList(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, id } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/contest/contests?company_id=${id}`,
      cacheItem: 'customer-contest-list',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'statusName' },
        { type: 'string', name: 'company_name' },
        { type: 'string', name: 'type_name' },
        { type: 'string', name: 'type_source_name' },
        { type: 'string', name: 'period_name' },
        { type: 'string', name: 'measure_name' },
        { type: 'number', name: 'min_coverage' },
        { type: 'number', name: 'min_customer' }
      ],
      ...options
    });
  }

  async postContestForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/contest/contests',
      changes: values
    });
    return response;
  }

  async updateContestForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/contest/contests/${id}`,
      changes: data
    });
    return response;
  }

  async deleteContestItem(id) {
    const response = await dataLoader.loadGoalDelete({
      action: `/contest/contests/${id}`
    });
    return response;
  }

  async getCompaniesList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/companies'
    });
    return response;
  }

  async getTypeList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/types'
    });
    return response;
  }

  async getSuppliersList(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/suppliers?companyId=${id}`
    });
    return response;
  }

  async getContestIdList(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}`
    });
    return response;
  }

  async getContestActivate(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/activate`
    });
    return response;
  }

  async getContestCancele(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/cancel`
    });
    return response;
  }

  async getContestClose(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/close`
    });
    return response;
  }

  async getContestValidate(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/validate`
    });
    return response;
  }

  async getContestReject(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/reject`
    });
    return response;
  }

  async getContestArchived(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/archive`
    });
    return response;
  }

  async getSourceTypeList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/source-types'
    });
    return response;
  }

  async getAccumulateList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/accumulate-by'
    });
    return response;
  }

  async getUnitMeasureList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/unit-measure'
    });
    return response;
  }

  async getBranchesList(id, type) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/branches?companyId=${id}&contestType=${type}`
    });
    return response;
  }

  async getExcludeTypeList(id, type) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/exclude-types?contestType=${id}&sourceType=${type}`
    });
    return response;
  }

  async getSelectListItem(id, type, typeSource, supplierId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/list-items?companyId=${id}&contestType=${type}&sourceType=${typeSource}&supplierId=${supplierId}`
    });
    return response;
  }

  async getSelectExcludeItem(id, type, typeSource, supplierId, excludeBy, items = '') {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/list-exclude-items?companyId=${id}&contestType=${type}&sourceType=${typeSource}&supplierId=${supplierId}&items=${items}&excludeType=${excludeBy}`
    });
    return response;
  }

  async getProductFavorite(id, type, typeSource, supplierId, excludeBy, items, excludeItems) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/selected-products?companyId=${id}&contestType=${type}&sourceType=${typeSource}&supplierId=${supplierId}&items=${items}&excludeType=${excludeBy}&excludeItems=${excludeItems}`
    });
    return response;
  }

  async getOwnerTypesList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/owner-types'
    });
    return response;
  }

  async getEvaluationFactorsList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/evaluation-factors'
    });
    return response;
  }

  async getEvaluateByList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/evaluate-by'
    });
    return response;
  }

  async getConfigAwards() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/awards-setup'
    });
    return response;
  }

  async getTypeGlobalQuota() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/global-quota-setup'
    });
    return response;
  }

  async getQuotaBy() {
    const response = await dataLoader.loadGoalGetData({
      action: '/contest/resources/quota-by'
    });
    return response;
  }

  async postImageForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/services/media-server/encoded',
      changes: values
    });
    return response;
  }

  async getEmployeePositions({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/employee-positions?companyId=${companyId}`
    });
    return response;
  }

  async getSalesTeams({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/resources/sales-teams?companyId=${companyId}`
    });
    return response;
  }

  async uploadDocumentExcel(files) {
    console.log('file', files);
    const response = await GadminFileUpload(
      GadminActions.G2_CONTESTS_UP,
      {
        type: 'list-service',
        payload: files,
        ...files
      },
      'list-service'
    );
    return response;
  }

  async getContestQuotas({ id, ...params }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/quotas`,
      params
    });
    return response;
  }
  async getContestQuotasDownload({ id, ...rest }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/contest/contests/${id}/quotas/download`,
      params: rest
    });
    return response;
  }

  // postContestQuotasUpload
  async postContestQuotasUpload({ id, ...rest }) {
    const response = await dataLoader.loadGoalPost({
      action: `/contest/contests/${id}/quotas/upload`,
      changes: rest
    });
    return response;
  }
}

export const goalCustomeContestApi = new GoalCustomerSalesgApi();

import { useState } from 'react';
import moment from 'moment';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { InputField } from './input-field';

export const useSelectInput = (initialValue, options) => {
  const [value, setValue] = useState(initialValue || '');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const SelectInput = (
    <InputField select value={value} onChange={handleChange} fullWidth>
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </InputField>
  );

  return { value, SelectInput };
};

export const useCheckBoxEdit = (initialValue) => {
  const [valueCheck, setValueCheck] = useState(initialValue || false);

  const handleChange = (event) => {
    setValueCheck(event.target.checked);
  };
  const CheckboxInput = (
    <FormControlLabel
      control={<Checkbox checked={valueCheck} onChange={handleChange} color="secondary" />}
      label={valueCheck ? 'Activado' : 'Desactivado'}
    />
  );

  return { valueCheck, CheckboxInput };
};

export const useInputEdit = (initialValue, type) => {
  const [valueInput, setValueInput] = useState(
    initialValue ? initialValue : type === 'number' ? 0 : ''
  );
  const handleChange = (event) => {
    setValueInput(event.target.value);
  };
  const InputTypeField = <InputField value={valueInput} onChange={handleChange} type={type} />;

  return { valueInput, InputTypeField };
};

export const useDateEdit = (initialValue, label, dataMin) => {
  const [valueInput, setValueInput] = useState(initialValue || '');
  const handleChange = (event) => {
    setValueInput(event.target.value);
  };

  let minDate = dataMin;
  if (dataMin === '') {
    minDate = moment().format('YYYY-MM-DD');
  }

  const InputTypeField = (
    <InputField
      label={label}
      type="date"
      value={valueInput}
      onChange={handleChange}
      fullWidth
      inputProps={{
        min: moment(minDate).add(1, 'day').format('YYYY-MM-DD')
      }}
    />
  );
  return { valueInput, InputTypeField };
};

import PropTypes from 'prop-types';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Query } from '../../query';
import { useApp } from '../../../hooks/use-app';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';

export const CustomerDeliveryPlanningFilter = (props) => {
  const {
    disabled,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = 78483,
    tabsList,
    branchsCode
  } = props;

  const { appUrlBase } = useApp();

  useEffect(() => {
    onViewChange(Number(branchsCode));
  }, []);

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={Number(view)}
            variant="scrollable"
          >
            {tabsList?.map((option, index) => (
              <Tab
                component={RouterLink}
                disabled={disabled}
                key={index}
                label={option.name}
                value={tabsList.length === 0 ? '' : option.account}
                to={`${appUrlBase}/delivery/planning/${Number(option.account)}`}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
        </Box>
      </div>
    </>
  );
};

CustomerDeliveryPlanningFilter.propTypes = {
  disabled: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.number,
  tabsList: PropTypes.array,
  branchsCode: PropTypes.string
};

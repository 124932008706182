import PropTypes from 'prop-types';
import { Box, FormControlLabel, IconButton, Menu, MenuItem, Switch } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { useState } from 'react';
import { InputField } from '../../../components/input-field';

export const MapItemMenu = (props) => {
  const {
    detail = false,
    setHidePolygon,
    hidePolygon,
    setHideCustomers,
    hideCustomers,
    setHideSellers,
    hideSellers,
    setZoomState,
    ...others
  } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [value, setValue] = useState('theDay');

  // console.log("item menu",items);
  const handleHidePolygon = () => {
    setHidePolygon(!hidePolygon);
    setZoomState(10);
  };

  const handleHideCustomer = () => {
    setHideCustomers(!hideCustomers);
    setZoomState(10);
  };

  const handleHideSellers = () => {
    setHideSellers(!hideSellers);
    setZoomState(10);
  };

  return (
    <>
      <Box onClick={handleOpen} ref={anchorRef} {...others}>
        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem key="polygon" disabled={detail}>
          <FormControlLabel
            control={<Switch checked={hidePolygon} onChange={handleHidePolygon} name="polygon" />}
            label={'Mostrar poligonos'}
          />
        </MenuItem>
        {hidePolygon && (
          <Box sx={{ paddingX: 3, paddingY: 1 }}>
            <InputField
              select
              label=""
              value={value}
              fullWidth
              onChange={(event) => setValue(event.target.value)}
            >
              <MenuItem value="theDay">Del día</MenuItem>
              <MenuItem value="all">Todos</MenuItem>
            </InputField>
          </Box>
        )}
        <MenuItem key="customer">
          <FormControlLabel
            control={
              <Switch checked={hideCustomers} onChange={handleHideCustomer} name="customer" />
            }
            label={'Mostrar clientes'}
          />
        </MenuItem>
        <MenuItem key="sellers">
          <FormControlLabel
            control={<Switch checked={hideSellers} onChange={handleHideSellers} name="sellers" />}
            label={'Mostrar vendedores'}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

MapItemMenu.propTypes = {
  detail: PropTypes.bool,
  setHidePolygon: PropTypes.func,
  hidePolygon: PropTypes.bool,
  setHideCustomers: PropTypes.func,
  hideCustomers: PropTypes.bool,
  setHideSellers: PropTypes.func,
  hideSellers: PropTypes.bool,
  setZoomState: PropTypes.func
};

import { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import { Box, Card, CardContent, Grid, List, ListItem, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { StatusBadge } from '../../status-badge';
import { queueApi } from '../../../api/managment/queue';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../common/loading-content';

export const QueuesStats = () => {
  const theme = useTheme();
  const mounted = useMounted();

  const [queueState, setqueueState] = useState({ isLoading: true, reload: true });

  const getQueueStatics = useCallback(async () => {
    setqueueState(() => ({ isLoading: true, message: 'Cargando estadísticas de colas' }));
    try {
      const result = await queueApi.getQueueStatics();
      if (mounted.current) {
        setqueueState(() => ({
          ...result,
          isLoading: false
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setqueueState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  useEffect(() => {
    getQueueStatics().catch(console.error);
  }, []);
  if (queueState.isLoading || queueState.error) {
    return <LoadingContent loadingText={queueState.message} error={queueState.error} />;
  }
  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: queueState?.data?.map((item) => item.color),
    dataLabels: {
      enabled: false
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    labels: queueState?.data?.map((item) => item.name),
    legend: {
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    }
  };

  const chartSeries = queueState?.data?.map((item) => item.data);

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: alpha(theme.palette.info.main, 0.1),
        mb: 4
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography color="textSecondary" variant="overline">
              Comprobantes desde
            </Typography>
            <Typography color="textPrimary" sx={{ mb: 3 }} variant="h4">
              01/10/2021
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Todos los comprobantes procesados <strong>.</strong>
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: {
                sm: 'row',
                xs: 'column'
              }
            }}
          >
            <Chart options={chartOptions} series={chartSeries} type="donut" width={150} />
            <List sx={{ flex: 1 }}>
              {queueState?.data?.map((item) => (
                <ListItem disableGutters key={item.name} sx={{ display: 'flex' }}>
                  <StatusBadge color={item.color} sx={{ mr: 1 }} />
                  <Typography color="textSecondary" variant="body2">
                    {item.name}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography color="textPrimary" variant="body2">
                    {numeral(item.data).format('0')}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import numeral from 'numeral';
import {
  Box,
  Checkbox,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
// import { Status } from '../../status';
// import { QueueMenu } from './queue-menu';
import { Status } from '../../status';
import { useApp } from '../../../hooks/use-app';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'worker',
    label: 'Proceso'
  },
  {
    id: 'Tipo',
    label: 'Tipo'
  },
  {
    id: 'docEntry',
    label: 'Identificador'
  },
  {
    id: 'canceled',
    label: 'Cancelado'
  },
  {
    id: 'procStatus',
    label: 'Estado'
  },
  {
    id: 'createdBy',
    label: 'Usuario'
  },
  {
    id: 'createProg',
    label: 'Aplicativo'
  },
  {
    id: 'lastProc',
    label: 'Último Proceso'
  },
  {
    id: 'closeDate',
    label: 'Fecha de cierre'
  },
  {
    id: 'message',
    label: 'Mensaje'
  },
  {
    id: 'retries',
    label: 'Intentos'
  }
];

// const statusVariants = [
//   { color: 'info.main', label: 'Borrador', value: 'D' },
//   { color: 'warning.main', label: 'Validado', value: 'V' },
//   { color: 'warning.main', label: 'Verificado - alm', value: 'N' },
//   { color: 'success.main', label: 'Recibido', value: 'R' },
//   { color: 'warning.main', label: 'Aprobado Contable', value: 'Y' },
//   { color: 'success.main', label: 'Facturado', value: 'F' },
//   { color: 'error.main', label: 'Anulado', value: 'A' },
//   { color: 'success.main', label: 'Confirmado', value: 'X' },
//   { color: 'warning.main', label: 'Para anular', value: 'Z' },
//   { color: 'error.main', label: 'Devuelto', value: 'T' }
// ];
const statusCancelledColumn = [
  { color: 'error.main', label: 'Si', value: 'Y' },
  { color: 'success.main', label: 'No', value: 'N' }
];
const statusColumn = [
  { color: 'warning.main', label: 'Abierto', value: 'O' },
  { color: 'error.main', label: 'Cerrado', value: 'C' }
];

export const QueuesTable = (props) => {
  const {
    error,
    queues = [],
    queuesCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedQueues = [],
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const { appUrlBase } = useApp();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !queues?.length);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={queues.length > 0 && selectedQueues.length === queues.length}
                  disabled={isLoading}
                  indeterminate={selectedQueues.length > 0 && selectedQueues.length < queues.length}
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {queues.map((queue) => {
              const statusProc = statusColumn.find((status) => status.value === queue.procStatus);
              const statusCancelled = statusCancelledColumn.find(
                (status) => status.value === queue.canceled
              );

              return (
                <TableRow
                  hover
                  key={queue.id}
                  selected={!!selectedQueues.find((selectedQueue) => selectedQueue === queue.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selectedQueues.find((selectedQueue) => selectedQueue === queue.id)}
                      onChange={(event) => onSelect(event, queue.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${appUrlBase}/managment/queues/${queue.id}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      #{queue.id}
                    </Link>
                  </TableCell>
                  <TableCell>{queue.worker}</TableCell>
                  <TableCell>
                    <Box>
                      <Typography color="inherit" variant="body2">
                        {queue.objType}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {queue.shortName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{queue.docEntry}</TableCell>
                  <TableCell>
                    <Status color={statusCancelled?.color} label={statusCancelled?.label} />
                  </TableCell>
                  <TableCell>
                    <Status color={statusProc?.color} label={statusProc?.label} />
                  </TableCell>
                  <TableCell>{queue.createdBy}</TableCell>
                  <TableCell>{queue.createdProg}</TableCell>
                  <TableCell>{format(new Date(queue.lastProc), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                  <TableCell>{format(new Date(queue.closeDate), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                  <TableCell>{queue.message}</TableCell>
                  <TableCell>{numeral(queue.retries).format(`0`)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={queuesCount}
      />
    </Box>
  );
};

QueuesTable.propTypes = {
  queues: Proptypes.array,
  queuesCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedQueues: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';

import { deliveryApi } from '../../../api/delivery';
import { useDataBrowser } from '../../../hooks/use-data-browser';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
// import { CustomerOrdersStats } from '../../../components/document/orders/customer-orders-stats';
import { AuxiliariesCurrentTable } from '../../../components/delivery/auxiliary/auxiliaries-current-table';
import { AuxiliariesCurrentFilter } from '../../../components/delivery/auxiliary/auxiliaries-current-filter';

export const AuxiliariesCurrent = () => {
  const mounted = useMounted();
  const app = useApp();

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
  ] = useDataBrowser({
    sourceApi: deliveryApi.getAuxiliariesCurrent,
    loadingMessage: 'Cargando auxiliares',
    mounted
    // onPostRefresh: handleClearSelected
  });

  const [selectedDocuments, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    returnsState.documents
  );

  useEffect(() => {
    handleClearSelected();
  }, [returnsState.documents]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{app.title('Planilla reparto')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Planilla reparto
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                >
                  Refresh
                </Button>
              </AllowedGuard>
            </Box>
          </Box>
          {/* <CustomerOrdersStats /> */}
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <AuxiliariesCurrentFilter
              disabled={returnsState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedDocuments={selectedDocuments}
              view={controller.view}
            />
            <Divider />
            <AuxiliariesCurrentTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
              onRefresh={handleRefresh}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

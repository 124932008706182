import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Typography
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import { LoadingTable } from '../../common/loading-table';
import { useDialog } from '../../../hooks/use-dialog';
import { TablesEditLine } from '../../sales-teams/tables-edit-line';
import { Pencil as PencilIcon } from '../../../icons/pencil';

export const BusinessPartnersTablesTable = ({ onRefresh, documentState, documents = [] }) => {
  const handleRefresh = () => {
    onRefresh?.();
  };
  const [itemData, setItemEdit] = useState(null);
  const [openActive, handleOpenActive, handleCloseActive] = useDialog();
  const [openFrequency, handleOpenFrequency, handleCloseFrequency] = useDialog();
  const [openChannel, handleOpenChannel, handleCloseChannel] = useDialog();

  const handleClickEditActive = (item) => {
    setItemEdit(item);
    handleOpenActive();
  };

  const handleClickEditVisit = (item) => {
    setItemEdit(item);
    handleOpenFrequency();
  };

  const handleClickEditSeller = (item) => {
    setItemEdit(item);
    handleOpenChannel();
  };

  const dataFrequency = [
    {
      value: 'SEMANAL',
      label: 'SEMANAL'
    },
    {
      value: 'MENSUAL',
      label: 'MENSUAL'
    },
    {
      value: 'QUINCENAL',
      label: 'QUINCENAL'
    },
    {
      value: 'TODOS LOS DIAS',
      label: 'TODOS LOS DIAS'
    },
    {
      value: '3 VECES X SEMANA',
      label: '3 VECES X SEMANA'
    }
  ];

  const dataChannel = [
    {
      value: 'MINORISTA',
      label: 'MINORISTA'
    }
  ];

  return (
    <Card
      variant="outlined"
      sx={{
        marginY: '1em'
      }}
    >
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Mesa" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell>#</TableCell>
              <TableCell>Domicilio</TableCell>
              <TableCell>Mesa</TableCell>
              <TableCell>Zona</TableCell>
              <TableCell>Ruta</TableCell>
              <TableCell>Vendedor</TableCell>
              <TableCell>Dia de visita</TableCell>
              <TableCell>Canal</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell>Frecuencia</TableCell>
              <TableCell>Almacen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {index}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.home}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.table}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.zone}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.routes}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.seller}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.visit_day}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.channel}
                  </Typography>
                </TableCell> */}
                <TableCell>
                  <Box
                    sx={{
                      minWidth: 150,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {openChannel && itemData?.id === item.id ? (
                      <>
                        <TablesEditLine
                          type="select"
                          item={item.channel}
                          data={dataChannel}
                          handleClose={handleCloseChannel}
                        />
                      </>
                    ) : (
                      <>
                        <Typography color="textSecondary" variant="body2">
                          {item.channel}
                        </Typography>
                        <PencilIcon
                          color="primary"
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => handleClickEditSeller(item)}
                        />
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      minWidth: 150,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {openActive && itemData?.id === item.id ? (
                      <>
                        <TablesEditLine
                          type="check"
                          item={item.active}
                          handleClose={handleCloseActive}
                        />
                      </>
                    ) : (
                      <>
                        <Typography color="textSecondary" variant="body2">
                          {item.active}
                        </Typography>
                        <PencilIcon
                          color="primary"
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => handleClickEditActive(item)}
                        />
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      minWidth: 150,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {openFrequency && itemData?.id === item.id ? (
                      <>
                        <TablesEditLine
                          type="select"
                          item={item.frequency}
                          data={dataFrequency}
                          handleClose={handleCloseFrequency}
                        />
                      </>
                    ) : (
                      <>
                        <Typography color="textSecondary" variant="body2">
                          {item.frequency}
                        </Typography>
                        <PencilIcon
                          color="primary"
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => handleClickEditVisit(item)}
                        />
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.warehouse}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <LoadingTable documentState={documentState} />
      </Scrollbar>
    </Card>
  );
};

BusinessPartnersTablesTable.propTypes = {
  documentState: PropTypes.object.isRequired,
  documents: PropTypes.array,
  onRefresh: PropTypes.func.isRequired
};

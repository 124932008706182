import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// import logo from '../../../../images/logo-roma.png';
import CustomerReturnHeader from './customer-return-header';
import CustomerReturnFooterMessage from './customer-return-message';
import CustomerReturnNumber from './customer-return-number';
import CustomerReturnLinesTable from './customer-return-table';
import CustomerReturnTitle from './customer-return-title';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 8,
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'row'
  },
  invoiceHeaderContainer: {
    flexDirection: 'row',
    marginTop: 3,
    justifyContent: 'space-between'
  }
});

const CustomerReturnReceipt = ({ customerReturn }) => (
  <Document>
    <Page size="A5" style={styles.page}>
      <View style={styles.invoiceHeaderContainer}>
        {/* <View>
          <Image style={styles.logo} src={logo} />
        </View> */}
        <View>
          <CustomerReturnTitle title="Orden de recojo" />
        </View>
      </View>
      <View style={styles.invoiceHeaderContainer}>
        <View>
          <CustomerReturnHeader customerReturn={customerReturn} />
        </View>
        <View>
          <CustomerReturnNumber customerReturn={customerReturn} />
        </View>
      </View>
      <CustomerReturnLinesTable customerReturn={customerReturn} />
      <CustomerReturnFooterMessage customerReturn={customerReturn} />
    </Page>
  </Document>
);

CustomerReturnReceipt.propTypes = {
  customerReturn: PropTypes.object.isRequired
};
export default CustomerReturnReceipt;

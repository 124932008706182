import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';

/* eslint class-methods-use-this:0 */

class DocumentApi {
  async getDocumentLog({ objType, docEntry }) {
    const response = await GadminRequest(GadminActions.gCC_DOC_SUCCESS, {
      type: 'get-log',
      payload: { objType, docEntry }
    });
    return response;
  }

  async getDocumentUploaLogByUser(payload) {
    const response = await GadminRequest(GadminActions.gCC_DOC_SUCCESS, {
      type: 'get-upoload-logs-user',
      payload
    });
    return response;
  }

  async getDocumentLogRecent(payload) {
    const response = await GadminRequest(GadminActions.gCC_DOC_SUCCESS, {
      type: 'get-log-recent',
      payload
    });
    return response;
  }

  async addDocumentLog({ objType, docEntry, message }) {
    const response = await GadminRequest(GadminActions.gCC_DOC_SUCCESS_ADD, {
      type: 'add-log',
      payload: { objType, docEntry, message }
    });
    return response;
  }

  async cancelOrder(id) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO, {
      type: 'cancel',
      payload: { id }
    });
    return response;
  }

  async cancelOrdersBulk(items) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO, {
      type: 'cancel-bulk',
      payload: { items }
    });
    return response;
  }

  async invoiceOrder(id) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO, {
      type: 'invoice',
      payload: { id }
    });
    return response;
  }

  async retryOrder(id) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO, {
      type: 'retry',
      payload: { id }
    });
    return response;
  }

  async reactivateOrder(id) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO, {
      type: 'reactivate',
      payload: { id }
    });
    return response;
  }

  async unautorizeOrder(id) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_RET, {
      type: 'unautorize',
      payload: { id }
    });
    return response;
  }

  async unautorizeOrdersBulk(items) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_RET, {
      type: 'unautorize-bulk',
      payload: { items }
    });
    return response;
  }

  async autorizeOrder(id) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_AUTORIZAR_PEDIDO_RET, {
      type: 'autorize',
      payload: { id }
    });
    return response;
  }

  async autorizeOrdersBulk(items) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_AUTORIZAR_PEDIDO_RET, {
      type: 'autorize-bulk',
      payload: { items }
    });
    return response;
  }

  async closeOrder(id) {
    const response = await GadminRequest(GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO, {
      type: 'close',
      payload: { id }
    });
    return response;
  }

  async receiptDetail(transid) {
    const response = await GadminRequest(GadminActions.gCC_DET_ORDER_FAC, {
      type: 'detail',
      payload: { transid }
    });
    return response;
  }

  async receiptDetailTransaction(payload) {
    const response = await GadminRequest(GadminActions.gCC_DET_ORDER_FAC, {
      type: 'transaction',
      payload
    });
    return response;
  }

  async receiptDetailXls(payload) {
    const response = await GadminRequest(GadminActions.gCC_DET_ORDER_FAC, {
      type: 'lines-xls',
      payload
    });
    return response;
  }

  async cancelReceipt(payload) {
    const response = await GadminRequest(GadminActions.gCC_DOCUMENT_CANCEL, {
      type: 'cancel',
      payload
    });
    return response;
  }

  async cloneReceipt(payload) {
    const response = await GadminRequest(GadminActions.gCC_DOCUMENT_CANCEL, {
      type: 'clone',
      payload
    });
    return response;
  }
}

export const documentApi = new DocumentApi();

import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
// import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import CodeReaderPlugin from '../../components/code-reader-plugin';
import InmediateLink from './inmediate-link';

export const ProductScanner = () => {
  const app = useApp();
  const [link, setLink] = useState(null);

  const handleRefresh = () => {
    // setController({ ...controller, reload: true });
  };
  const handleCancel = () => {
    // setController({ ...controller, cancel: true });
  };
  // const handleReaded = (decodedText, decodedResult) => {
  const handleReaded = (decodedText) => {
    setLink(decodedText);
  };
  if (link) {
    return link.startsWith('http') ? <InmediateLink to={link} /> : <Navigate to={link} />;
  }
  return (
    <>
      <Helmet>
        <title>{app.title('Scanear producto')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Producto
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <div id="reader" width="600px" />
            <CodeReaderPlugin
              fps={20}
              qrbox={300}
              disableFlip={false}
              qrCodeSuccessCallback={handleReaded}
            />
            <Button onClick={handleRefresh}>Iniciar</Button>
            <Button onClick={handleCancel}>Cancelar</Button>
          </Card>

          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

// material
import { Grid, Container } from '@mui/material';
import { AllowedGuard } from '../hooks/use-allowed';
// import { CustomerOrdersStats } from '../components/document/orders/customer-orders-stats';
import { CustomerReturnsStats } from '../components/document/returns/customer-returns-stats';
// import { Navigate } from 'react-router-dom';
// components
import Page from '../components/Page';
import {
  // AppTasks,
  // AppNewUsers,
  PreVentaOrderPendigs,
  // AppItemOrders,
  // AppNewsUpdate,
  // AppWeeklySales,
  // AppOrderTimeline,
  OrderOpenStatics,
  // AppWebsiteVisits,
  // AppTrafficBySite,
  // AppCurrentSubject,
  // AppConversionRates,
  ProductsStatics,
  CustomersStatics,
  AppLogRecent,
  PromoStatics
} from '../components/_dashboard/app';
// import { useAuth } from '../hooks/use-auth';
import { useApp } from '../hooks/use-app';
import GadminActions from '../contexts/gadmin-actions';

// ----------------------------------------------------------------------

const DashboardApp = () => {
  const { title } = useApp();

  return (
    <>
      <Page title={title('Dashboard')}>
        <Container maxWidth="zl">
          {/* <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Bienvenido {user?.name} </Typography>
          </Box> */}
          <Grid container spacing={3}>
            <AllowedGuard permission={GadminActions.gCC_PROC_LOAD_CIERRE_INVENTORY}>
              <Grid item xs={12} sm={6} md={3}>
                <PromoStatics />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ProductsStatics />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomersStatics />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <PreVentaOrderPendigs />
              </Grid>
            </AllowedGuard>
            <Grid item xs={12} md={6} lg={8}>
              <AppLogRecent />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <AllowedGuard permission={GadminActions.gCC_PROC_LOAD_CIERRE_INVENTORY}>
                <OrderOpenStatics />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <CustomerReturnsStats />
              </AllowedGuard>
              {/* <AllowedGuard permission={GadminActions.gCC_PROC_LOAD_CIERRE_INVENTORY}>
                <CustomerOrdersStats />
              </AllowedGuard> */}
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6}>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
            </Grid> */}
            {/*
            <Grid item xs={12} md={6} lg={8}>
              <AppConversionRates />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentSubject />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <AppNewsUpdate />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppOrderTimeline />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppTrafficBySite />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <AppTasks />
            </Grid> */}
          </Grid>
        </Container>
      </Page>
    </>
  );
};
export default DashboardApp;

import Proptypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import mapIcon from '@iconify/icons-eva/pin-fill';
import searchIcon from '@iconify/icons-eva/search-fill';
// import moment from 'moment';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Link
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Status } from '../../status';
import { CustomerDeliveryMap } from './customer-delivery-map';
import { NumberColor } from '../../number-color';
import { useApp } from '../../../hooks/use-app';

const columns = [
  {
    id: 'number',
    label: 'Documento / TPago'
  },
  {
    id: 'docTotal',
    label: 'S/Facturado'
  },
  {
    id: 'sumApply',
    label: 'S/Entregado'
  },
  {
    id: 'sumReturn',
    label: 'S/Devuelto'
  },
  {
    id: 'cardName',
    label: 'Cliente'
  },

  {
    id: 'documentStatusName',
    label: 'Estado / LIQ'
  },
  {
    id: 'partial',
    label: 'Permitir Parcial'
  },
  {
    id: 'location',
    label: 'Ubicación'
  },
  {
    id: 'detail',
    label: 'Detalles'
  },
  {
    id: 'sheetId',
    label: 'HR'
  }
];

const statusDocuments = [
  { color: '#1890FF', label: 'Abierto', value: 'O' },
  { color: '#FFB233', label: 'Cerrado', value: 'C' }
];

const liqDocuments = [
  { color: '#1890FF', label: 'Completo', value: 0 },
  { color: '#D84644', label: 'Anulado', value: 1 },
  { color: '#FFB233', label: 'Parcial', value: 2 }
];

export const CustomerDeliveryDocumentsTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;
  const { appUrlBase } = useApp();

  const [showComponent, setShowComponent] = useState(false);
  const [locationState, setLocationState] = useState({
    x: -13.4274296,
    y: -76.1567574
  });

  const handleClick = () => {
    setShowComponent(!showComponent);
  };

  const handleClickLocation = (lat, long) => {
    setShowComponent(true);
    setLocationState({
      x: long,
      y: lat
    });
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);
  const detailRoute = `${appUrlBase}/sales/receipts/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => {
              const liqDocument = liqDocuments.find(
                (variant) => variant.value === document.liquidationStatus
              );

              const statusDocument = statusDocuments.find(
                (variant) => variant.value === document.documentStatus
              );
              return (
                <TableRow
                  hover
                  key={document.number}
                  selected={
                    !!selectedDocuments.find(
                      (selectedCustomer) => selectedCustomer === document.docEntry
                    )
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        !!selectedDocuments.find(
                          (selectedCustomer) => selectedCustomer === document.id
                        )
                      }
                      onChange={(event) => onSelect(event, document.id)}
                    />
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    <Typography color="textSecondary" variant="body2">
                      {document.number}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {document.paymentGroupName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <NumberColor value={document.docTotal} />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={document.sumApply} />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={document.sumReturn} />
                  </TableCell>
                  <TableCell sx={{ minWidth: 280 }}>
                    <Typography color="textSecondary" variant="body2">
                      {document.cardName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 150 }}>
                    <Status color={statusDocument?.color} label={statusDocument?.label} />
                    <Status color={liqDocument?.color} label={liqDocument?.label} />
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.partial}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleClickLocation(document.refX, document.refY)}
                    >
                      <Icon
                        icon={mapIcon}
                        width={30}
                        height={30}
                        color={document.documentStatus === 'O' ? '#1890FF' : '#FFB233'}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${detailRoute}${document.numero}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      <Icon icon={searchIcon} width={30} height={30} color="#3493DA" />
                    </Link>
                    {/* <Typography
                      color="textSecondary"
                      variant="body2"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleClickLocation(document.refx, document.refy)}
                    >
                      <Icon icon={searchIcon} width={30} height={30} color="#3493DA" />
                    </Typography> */}
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.sheetId}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
      <Button
        color="primary"
        variant="outlined"
        sx={{
          mx: 5,
          my: 3
        }}
        onClick={handleClick}
      >
        {showComponent ? 'Ocultar mapa' : 'Ver mapa'}
      </Button>

      {showComponent && (
        <>
          <Divider />
          <Card sx={{ mt: 5, mb: 5 }}>
            <Typography color="textPrimary" variant="h4">
              Mapa
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <CustomerDeliveryMap
              locationState={locationState}
              documentsProp={documents}
              zoomMap={30}
            />
          </Card>
        </>
      )}
    </Box>
  );
};

CustomerDeliveryDocumentsTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography
} from '@mui/material';
import LoadingContent from '../../../common/loading-content';
import Scrollbar from '../../../Scrollbar';

export const ProductDialog = ({
  requestData: documentState,
  onSelect,
  onSelectAll,
  selectedItems,
  selectData,
  setSelectData,
  isLoading,
  error,
  handleClearSelected
}) => {
  if (isLoading || error) {
    return <LoadingContent loadingText="Cargando" error={error} />;
  }
  const handleSelect = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      onSelect(e, item.id);
      setSelectData([item]);
    } else {
      handleClearSelected();
      setSelectData([]);
    }
  };

  return (
    <>
      <Divider />
      <Scrollbar>
        {false && (
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      documentState.length > 0 && selectedItems.length === documentState.length
                    }
                    // disabled={isLoading}
                    disabled
                    indeterminate={
                      selectedItems.length > 0 && selectedItems.length < documentState.length
                    }
                    onChange={onSelectAll}
                  />
                </TableCell>
                {/* <TableCell>#</TableCell> */}
                <TableCell>ID</TableCell>
                <TableCell>CODIGO</TableCell>
                <TableCell>NOMBRE</TableCell>
                <TableCell>SEDE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentState?.map((item, index) => (
                <TableRow
                  key={item.id}
                  hover
                  selected={!!selectData.find((selectedItem) => selectedItem === item.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selectData.find((selectedItem) => selectedItem.id === item.id)}
                      onChange={(event) => handleSelect(event, item)}
                    />
                  </TableCell>
                  {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                  <TableCell>{index}</TableCell>
                  <TableCell>{item.company_id}</TableCell>
                  <TableCell>{item.company_name}</TableCell>
                  <TableCell>{item.vendor}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                gap: '2em',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                marginTop: 3
              }}
            >
              {documentState?.map((product, index) => (
                <Card
                  selected={!!selectData.find((selectedItem) => selectedItem === product.id)}
                  key={index}
                  sx={{
                    width: '20em',
                    maxHeight: '400px',
                    overflowY: 'auto'
                  }}
                >
                  <Checkbox
                    checked={!!selectData.find((selectedItem) => selectedItem.id === product.id)}
                    onChange={(event) => handleSelect(event, product)}
                  />
                  <CardMedia
                    sx={{ marginTop: 2, objectFit: 'contain' }}
                    component="img"
                    alt={product.company_name}
                    height="200"
                    width="200"
                    image="https://th.bing.com/th/id/OIP.Ut8OfEbADVrLVhgSXImFNwHaF2?w=244&h=193&c=7&r=0&o=5&pid=1.7"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {product.company_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      descripcion
                    </Typography>
                    <Typography variant="h6" color="textPrimary" component="p">
                      Precio: $50
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Scrollbar>
    </>
  );
};

ProductDialog.propTypes = {
  requestData: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedItems: PropTypes.array,
  selectData: PropTypes.array,
  setSelectData: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  handleClearSelected: PropTypes.func
};

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton, Menu } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { DocumetLogDialog } from '../../common/document-log-dialog';
import { PDFViewerdDialog } from '../../common/pdf-viewer-dialog';
import { XMLViewerdDialog } from '../../common/xml-viewer-dialog';

// import { invoiceApi } from '../../../api/invoice';
import { documentViews, mediaType } from '../../../api/purchase-definitions';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { RenderIf } from '../../render-if';
import { JournalPrintingDialog } from '../printing/journal-printing-dialog';
import { GoalMenu } from '../../menu/goal-menu';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import { useGoalDialog } from '../../../hooks/use-goal-dialog';
import {
  AppRegistration,
  Check,
  Code,
  PictureAsPdf,
  RemoveRedEye,
  WebStories
} from '@mui/icons-material';

export const InvoiceMenu = (props) => {
  const { invoice, detailPath, apiHandler, afterProcessHandler, containerView } = props;
  const navigate = useNavigate();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [logDialogController] = useGoalDialog({
    handleClose: handleClose,
    apiHandler: apiHandler.getInvoiceMedia,
    apiParams: { objType: invoice.objType, docEntry: invoice.docEntry, type: mediaType.PDF }
  });
  const [xmlDialogController] = useGoalDialog({
    handleClose: handleClose,
    apiHandler: apiHandler.getInvoiceMedia,
    apiParams: { objType: invoice.objType, docEntry: invoice.docEntry, type: mediaType.XML }
  });
  const [pdfDialogController] = useGoalDialog({
    handleClose: handleClose,
    apiHandler: apiHandler.getInvoiceMedia,
    apiParams: { objType: invoice.objType, docEntry: invoice.docEntry, type: mediaType.PDF }
  });
  const [journalDialogController] = useGoalDialog({
    handleClose: handleClose,
    apiHandler: apiHandler.getInvoiceMedia,
    apiParams: { objType: invoice?.objType, docEntry: invoice?.docEntry, transId: invoice?.transId }
  });
  const { showNotify, showError } = useApp();
  const [menuActions, setMenuActions] = useState([]);

  const detailPathWithId = `${detailPath}/${invoice?.id}`;
  const currentPath = useLocation().pathname;
  const isCurrentPath = currentPath === detailPathWithId;

  const handleAfterProcess = () => {
    afterProcessHandler?.();
  };

  const handleEdit = () => {
    handleClose();
    navigate(detailPathWithId);
  };

  const handleCheckCPE = async () => {
    const response = await apiHandler.checkCPE({ docEntry: invoice.docEntry || 0 });
    if (response.success) {
      showNotify(response.message);
      handleAfterProcess();
    } else {
      showError(response.message);
    }
  };

  const onConfirmConfirmationDialog = async ({ action }) => {
    try {
      const response = await apiHandler.processInvoice({
        type: action,
        payload: { id: invoice.id, view: containerView }
      });
      if (response.success) {
        showNotify(response.message);
        handleAfterProcess();
      } else {
        showError(`${response?.message}. Algunos registros no se procesaron `);
      }
    } catch (error) {
      showNotify(`Error al procesar  ${error}`, 'error');
      console.error(error);
    }
  };

  useEffect(() => {
    let actions = [];

    if (
      [
        documentViews.VIEW_DRAFT,
        documentViews.VIEW_VALIDATED,
        documentViews.VIEW_PROCESING
      ].includes(invoice.status)
    ) {
      actions.push({
        action: GadminActions.gCC_PURCHASE_FFEE_DISCARD,
        label: 'Descartar',
        prompt: '¿Está seguro que desea descartar los documentos seleccionados?'
      });
    }

    if ([documentViews.VIEW_DRAFT].includes(invoice.status)) {
      actions.push({
        action: GadminActions.gCC_PURCHASE_FFEE_CAB_VAL,
        label: 'Validar',
        prompt: '¿Está seguro que desea validar los documentos seleccionados?'
      });
    }
    if ([documentViews.VIEW_CANCELED].includes(invoice.status)) {
      actions.push({
        action: GadminActions.G2_PURCHASE_REACTIVE,
        label: 'Reactivar',
        prompt: '¿Está seguro que desea reactivar los documentos seleccionados?'
      });
    }
    if ([documentViews.VIEW_VALIDATED].includes(invoice.status)) {
      actions = [
        ...actions,
        {
          action: GadminActions.gCC_PURCHASE_FFEE_CONFIRM,
          label: 'Confirmar',
          prompt: '¿Está seguro que desea confirmar los documentos seleccionados?'
        },
        {
          action: GadminActions.gCC_PURCHASE_FFEE_NOCONFIRM,
          label: 'NO Confirmar',
          prompt: '¿Está seguro que desea NO confirmar los documentos seleccionados?'
        }
      ];
    }
    if ([documentViews.VIEW_PROCESING].includes(invoice.status)) {
      actions = [
        ...actions,
        {
          action: GadminActions.gCC_GPROC_QUEUE_UP,
          label: 'Encolar',
          prompt: '¿Está seguro que desea re-encolar los documentos seleccionados?'
        }
      ];
    }

    setMenuActions(actions);
  }, [invoice.status]);

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <DotsVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <RenderIf condition={!isCurrentPath}>
          <GoalMenu onClick={handleEdit} onClose={handleClose} icon={<RemoveRedEye />}>
            Detalle
          </GoalMenu>
        </RenderIf>
        <GoalMenuDialog
          onClose={handleClose}
          iconName={<WebStories />}
          controller={logDialogController}
          component={
            <DocumetLogDialog
              onClose={logDialogController.closeDialog}
              open={logDialogController.open}
              objType={invoice.objType || 0}
              docEntry={invoice.docEntry || 0}
            />
          }
        >
          Sucesos
        </GoalMenuDialog>
        <GoalMenuDialog
          onClose={handleClose}
          iconName={<PictureAsPdf />}
          controller={pdfDialogController}
          component={
            <PDFViewerdDialog
              onClose={pdfDialogController.closeDialog}
              open={pdfDialogController.open}
              apiHandle={pdfDialogController.apiHandler}
              apiParams={pdfDialogController.apiParams}
            />
          }
        >
          PDF
        </GoalMenuDialog>
        <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_DOWNLOAD_XML}>
          <GoalMenuDialog
            onClose={handleClose}
            iconName={<Code />}
            controller={xmlDialogController}
            component={
              <XMLViewerdDialog
                onClose={xmlDialogController.closeDialog}
                open={xmlDialogController.open}
                apiHandle={xmlDialogController.apiHandler}
                apiParams={xmlDialogController.apiParams}
              />
            }
          >
            XML
          </GoalMenuDialog>
        </AllowedGuard>

        <RenderIf condition={(invoice?.transId || -1) !== -1}>
          <GoalMenuDialog
            onClose={handleClose}
            iconName={<AppRegistration />}
            controller={journalDialogController}
            component={
              <JournalPrintingDialog
                onClose={journalDialogController.closeDialog}
                open={journalDialogController.open}
                entity={journalDialogController.apiParams}
              />
            }
          >
            Asiento
          </GoalMenuDialog>
        </RenderIf>

        <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
          <GoalMenu onClick={handleCheckCPE} onClose={handleClose} iconName={<Check />}>
            Validar en SUNAT
          </GoalMenu>
        </AllowedGuard>
        {menuActions.map((item, index) => (
          <AllowedGuard permission={item.permission || item.action} key={index}>
            <GoalMenu
              key={index}
              needConfirmation={true}
              confirmation={{
                message: item.prompt,
                title: item.label
              }}
              onClick={() => {
                onConfirmConfirmationDialog(item);
              }}
              onClose={handleClose}
            >{`${item.label}`}</GoalMenu>
          </AllowedGuard>
        ))}
      </Menu>
    </>
  );
};

InvoiceMenu.propTypes = {
  invoice: PropTypes.object,
  detailPath: PropTypes.string.isRequired,
  apiHandler: PropTypes.object.isRequired,
  afterProcessHandler: PropTypes.func,
  containerView: PropTypes.string.isRequired
};

//import moment from 'moment';
import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalCustomerDeliveryPlanning {
  async getCustomeDeliveryPlanning(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,

      mainFilter
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    // const dateNow = moment().format('YYYY-MM-DD');
    let action = `/simpli-route/plans-for-date?account=${view}`;
    if (mainFilter?.orderDate) {
      action += `&date=${mainFilter.orderDate}`;
    }

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action,
      cacheItem: 'customer-delivery-planning',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'color' }
      ],
      ...options
    });
  }

  async getStages({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/simpli-route/stages?companyId=${companyId}`
    });
    return response;
  }

  async getZonePlanning({ account }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/simpli-route/zones?account=${account}`
    });
    return response;
  }

  async postPlanningForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/simpli-route/upload-orders',
      changes: values
    });
    return response;
  }

  async generateRouteSheets(payload) {
    const response = await dataLoader.loadGoalPost({
      action: '/simpli-route/generate-routes',
      changes: payload
    });

    return response;
  }
}

export const goalCustomeDeleveryPlanningApi = new GoalCustomerDeliveryPlanning();

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Card, Divider, FormHelperText, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';

import { purchaseView } from '../../../api/purchase-definitions';
import { RenderIf } from '../../render-if';
import { NumberColor } from '../../number-color';
import { purchaseHelperApi } from '../../../api/purchase-helper';
import { useMounted } from '../../../hooks/use-mounted';
import { useData } from '../../../hooks/use-data';
import { invoiceApi } from '../../../api/invoice';
import { useFormChecker } from '../../../hooks/use-form-checker';
import { useFormSaver } from '../../../hooks/use-form-saver';

export const DocumentLineEdit = (props) => {
  const {
    currentLine = {},
    editable,
    onAfterSave,
    dialogMode = true,
    dialogSaving = false,
    setDialogSaving
  } = props;
  const mounted = useMounted();

  const [editAccount, setEditAccount] = useState(true);

  const [accountList] = useData({
    sourceApi: purchaseHelperApi.getServiceAccounts,
    apiParameter: { view: purchaseView.PURCHASE_SERVICE },
    loadingMessage: 'Cargando cuentas contables',
    mounted
  });
  const [costingCodeList] = useData({
    sourceApi: purchaseHelperApi.getServiceCostingCode,
    apiParameter: { view: purchaseView.PURCHASE_SERVICE },
    loadingMessage: 'Cargando centros de costos',
    mounted
  });
  // const [taxList, refresTaxList] = useData({
  //   sourceApi: purchaseHelperApi.getServiceTaxList,
  //   apiParameter: { view: purchaseView.PURCHASE_SERVICE },
  //   loadingMessage: 'Cargando cuentascontables',
  //   mounted
  // });
  useEffect(() => {
    setEditAccount(!!editable);
  }, [editable]);

  const handleAfterSave = () => {
    handleClose();
    onAfterSave?.();
  };
  const handleClose = () => {
    if (dialogMode) {
      setDialogSaving(false);
    }
  };
  const [handleUpdate] = useFormSaver({
    originalData: currentLine,
    apiUpdateHandler: invoiceApi.updateInvoiceLine,
    // id: currentLine.id,
    handleAfterSave
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      lineNum: currentLine?.lineNum || 0,
      itemCode: currentLine?.itemCode || '',
      Dscription: currentLine?.Dscription || '',
      unitMsr: currentLine?.unitMsr || 'ZZ',
      quantity: currentLine?.quantity || 1,
      taxCode: currentLine?.taxCode || 'IGV',
      OcrCode: currentLine?.OcrCode || '',
      account: currentLine?.account || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      Dscription: Yup.string().min(10).max(100).required('Descripción es requerido'),
      OcrCode: Yup.string().max(10).required('Centro de costo es requerido'),
      account: Yup.string().max(10).required('Código de cuenta es requerido')
    }),
    onSubmit: async (values, helpers) => {
      await handleUpdate({ values, helpers });
    }
  });
  const [checkForm] = useFormChecker({ formik, setSaving: setDialogSaving });
  useEffect(() => {
    if (dialogSaving && !formik.isSubmitting) {
      checkForm();
      formik.submitForm();
      if (dialogSaving) setDialogSaving(false);
    }
  }, [dialogSaving]);

  useEffect(() => {
    setDialogSaving(formik.isSubmitting);
  }, [formik.isSubmitting]);
  // const { checkForm } = useFormErrors(formik);
  return (
    <Card variant="outlined">
      {/* <CardHeader title="Detalle de comprobante" /> */}
      <Divider />
      <form
        onSubmit={async (values, helpers) => {
          checkForm();
          formik.handleSubmit(values, helpers);
        }}
      >
        <Grid container>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem label="Línea" value={`${currentLine.lineNum}`} align="right" />
              <PropertyListItem label="Código" value={`${currentLine.itemCode}`} align="right" />
              <PropertyListItem
                label="Descripción original"
                value={`${currentLine.sourceDescription}`}
              />

              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Descripción" value={`${currentLine.Dscription}`} />}
              >
                <PropertyListItem label="Descripción">
                  <InputField
                    error={Boolean(formik.touched.Dscription && formik.errors.Dscription)}
                    helperText={formik.touched.Dscription && formik.errors.Dscription}
                    name="Dscription"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.Dscription}
                    fullWidth
                    sx={{ minWidth: 400 }}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={
                  <PropertyListItem label="Cuenta contable" value={`${currentLine.accountName}`} />
                }
              >
                <PropertyListItem label="Cuenta">
                  <RenderIf
                    condition={!!accountList.data && accountList.data.length > 0}
                    no={accountList.message || 'Caragando cuentas contables...'}
                  >
                    <InputField
                      error={Boolean(formik.touched.account && formik.errors.account)}
                      helperText={formik.touched.account && formik.errors.account}
                      name="account"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      select
                      fullWidth
                      value={formik.values.account}
                    >
                      {accountList?.data?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.id}-${option.name}`}
                        </MenuItem>
                      ))}
                    </InputField>
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={
                  <PropertyListItem
                    label="Centro de costo"
                    value={`${currentLine.ocrName}`}
                    align="vertical"
                  />
                }
              >
                <PropertyListItem label="Centro de costo" align="vertical">
                  <RenderIf
                    condition={!!costingCodeList.data && costingCodeList.data.length > 0}
                    no={costingCodeList.message || 'Caragando centros de costo ...'}
                  >
                    <InputField
                      error={Boolean(formik.touched.OcrCode && formik.errors.OcrCode)}
                      helperText={formik.touched.OcrCode && formik.errors.OcrCode}
                      name="OcrCode"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      select
                      fullWidth
                      value={formik.values.OcrCode}
                    >
                      {costingCodeList?.data?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.id}-${option.name}`}
                        </MenuItem>
                      ))}
                    </InputField>
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Moneda"
                value={`${currentLine.currencySymbol}${currentLine.currency}`}
                align="right"
              />
              <PropertyListItem
                label="Unidad"
                value={`${currentLine.unitMsr} x ${currentLine.numPerMsr}`}
                align="right"
              />
              <PropertyListItem label="Cantidad" align="right">
                <NumberColor value={currentLine.quantity} format="0" />
              </PropertyListItem>
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Gravado"
                value={numeral(currentLine.lineTotal).format(`0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Inafecto"
                value={numeral(currentLine.sumInafecta).format(`0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Exonerado"
                value={numeral(currentLine.sumExonerada).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Gratuito"
                value={numeral(currentLine.sumGratuita).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="IGV"
                value={numeral(currentLine.vatSum).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Importe"
                value={numeral(currentLine.gTotal).format(`${'S/'}0,0.00`)}
                align="right"
              />
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}
            <RenderIf condition={!dialogMode}>
              <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={dialogSaving}
                >
                  Guardar
                </LoadingButton>
              </AllowedGuard>
            </RenderIf>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

DocumentLineEdit.propTypes = {
  currentLine: PropTypes.object,
  editable: PropTypes.bool,
  onAfterSave: PropTypes.func.isRequired,
  dialogMode: PropTypes.bool,
  dialogSaving: PropTypes.bool,
  setDialogSaving: PropTypes.func
};

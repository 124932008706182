import { useState } from 'react';
import Proptypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
  Typography
} from '@mui/material';
import { Icon } from '@iconify/react';
import circleIcon from '@iconify/icons-eva/checkmark-circle-2-fill';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { InputField } from '../input-field';
import { PropertyList } from '../property-list';
import { AutocompleteField } from '../autocomplete-field';
import { PropertyListItem } from '../property-list-item';
import { RenderIf } from '../render-if';
import { ContestAwardsDetail } from './awards/contest-awards-detail';
import { X } from '../../icons/x';
import { CustomerModal } from '../delivery/monitoring/customer-modal';
import { GenerateRangeForm } from './awards/generate-range-form';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import { SliderCommon } from '../common/slider-common';
import { ConfirmationDialog } from '../confirmation-dialog';

const ContestAwardsForm = ({
  title,
  initialValues = {},
  contestState,
  configAwardsState,
  typeState,
  employeePositionList,
  handleRefreshDetail,
  listAwards
}) => {
  const { showNotify, showError } = useApp();
  const [selectAction, setSelectAction] = useState('');
  const [selectItem, setSelectedItem] = useState(null);
  const [editMode, setEditmode] = useState(false);
  const [open, onClose] = useState(false);
  const [createRange, setCreateRange] = useState(false);
  const [selectCloneRange, setSelectCloneRange] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectItemAwards, setSelectItemAwards] = useState(null);

  const uniqueId = uuidv4();

  const filterType = typeState.find((item) => item.code === contestState.type);

  const formik = useFormik({
    initialValues: {
      value: initialValues.value || 0,
      employee_position: initialValues.employee_position || '',
      employee_position_code: initialValues.employee_position_code || '',
      type: initialValues.type || '',
      ranges: initialValues.ranges || [],
      active: initialValues.active || true,
      id: initialValues.uniqueId || uniqueId,
      awards: initialValues.awards || [],
      min_distance: initialValues.min_distance || 1
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required('Este campo es obligatorio'),
      ranges: Yup.array().min(2, 'Debe tener al menos 2 rangos').of(Yup.number())
    }),
    onSubmit: async (values) => {
      if (selectAction === 'edit-form') {
        console.log('update', values);
      } else {
        const createAwards = {
          ...contestState,
          awards: [...contestState.awards, values]
        };
        const response = await goalCustomeContestApi.postContestForm({ values: createAwards });
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleRefreshDetail();
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      }
    }
  });

  const handleUpdateApi = async (valueRange, awards, minDistance, valueMinState) => {
    const index = contestState.awards.findIndex((award) => award.id === formik.values.id);
    if (index !== -1) {
      contestState.awards[index] = formik.values;
    }
    formik.values.ranges = valueRange;
    formik.values.awards = awards;
    formik.values.min_distance = minDistance;
    formik.values.value = valueMinState;
    const response = await goalCustomeContestApi.updateContestForm(contestState._id, formik.values);
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      handleRefreshDetail();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const onSelection = (item) => {
    setEditmode(false);
    setSelectAction('edit-form');
    setSelectedItem(null);
    setTimeout(() => {
      formik.setFieldValue('id', item.id);
      formik.setFieldValue('value', item.value);
      formik.setFieldValue('employee_position_code', item.employee_position_code);
      formik.setFieldValue('employee_position', item.employee_position);
      formik.setFieldValue('type', item.type);
      formik.setFieldValue('ranges', item.ranges);
      formik.setFieldValue('awards', item.awards);
      formik.setFieldValue('min_distance', item.min_distance);
      setSelectedItem(item);
    }, 100);
  };

  const handleReset = () => {
    formik.setFieldValue('value', 0);
    formik.setFieldValue('employee_position_code', '');
    formik.setFieldValue('employee_position', '');
    formik.setFieldValue('type', '');
    formik.setFieldValue('ranges', []);
    formik.setFieldValue('awards', []);
    formik.setFieldValue('min_distance', 1);
    formik.setFieldValue('id', uniqueId);
    setSelectCloneRange('');
  };

  const handleCancel = () => {
    setEditmode(false);
    setSelectAction('');
  };

  const handleSetRange = (awards, valueRange, minDistance = 5, valueMinState) => {
    formik.setFieldValue('ranges', valueRange);
    formik.setFieldValue('awards', awards);
    formik.setFieldValue('min_distance', minDistance);
    formik.setFieldValue('value', valueMinState);
    if (selectAction === 'edit-form') {
      handleUpdateApi(valueRange, awards, minDistance, valueMinState);
    }
    setCreateRange(true);
  };

  const handleSetTypeRange = (type) => {
    formik.setFieldValue('type', type);
  };

  const handleEditRange = () => {
    onClose(!open);
    setCreateRange(false);
  };

  const handleSaveValue = (value) => {
    formik.setFieldValue('value', value);
  };

  const handleInitial = () => {
    setEditmode(false);
    setSelectedItem(null);
    setSelectAction('');
  };

  const handleConfirm = () => {
    setSelectCloneRange(`${selectItemAwards.value} - ${selectItemAwards.employee_position.name}`);
    formik.setFieldValue('ranges', selectItemAwards.ranges);
    formik.setFieldValue('awards', selectItemAwards.awards);
    formik.setFieldValue('min_distance', selectItemAwards.min_distance);
    formik.setFieldValue('value', selectItemAwards.value);
    setModalOpen(false);
    handleEditRange();
    // setEditmode(true);
    setSelectAction('clone-form');
  };

  const handleCloneRange = (item) => {
    setModalOpen(true);
    setSelectItemAwards(item);
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item md={6} xs={12}>
            <PropertyList>
              <Box
                sx={{
                  height: 'auto',
                  flexGrow: 1,
                  maxWidth: '100%',
                  overflowY: 'auto',
                  background: '#fff',
                  paddingX: 1,
                  paddingY: 4,
                  borderRadius: 2,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'neutral.300',
                  boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
                  marginTop: 5
                }}
                px={2}
              >
                <PropertyList>
                  <PropertyListItem
                    label=""
                    sx={{
                      px: 3,
                      py: 0
                    }}
                  >
                    <Typography
                      sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                      onClick={handleInitial}
                    >
                      Premios
                    </Typography>
                    <List>
                      {listAwards.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemButton onClick={() => onSelection(item)}>
                            <ListItemIcon>
                              <Icon icon={circleIcon} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                              primary={`${item.value} - ${item.employee_position.name}`}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </PropertyListItem>
                </PropertyList>
              </Box>
            </PropertyList>
          </Grid>

          <Grid item xs={12} sm={6}>
            <RenderIf
              condition={editMode}
              no={
                <ContestAwardsDetail
                  setEditMode={setEditmode}
                  item={selectItem}
                  setSelectAction={setSelectAction}
                  handleReset={handleReset}
                  contestState={contestState}
                  handleEditRange={handleEditRange}
                />
              }
            >
              <AutocompleteField
                label="Puesto"
                name="employee_position"
                placeholder="Seleccione origen"
                fullWidth
                options={employeePositionList || [{ name: 'Cargando', code: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('employee_position_code', value?.code);
                  formik.setFieldValue('employee_position', value);
                }}
                value={formik.values.employee_position}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.employee_position_code &&
                  Boolean(formik.errors.employee_position_code)
                }
                helperText={
                  formik.touched.employee_position_code && formik.errors.employee_position_code
                }
              />
              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: 2
                }}
              >
                <Grid item md={6} xs={12}>
                  <InputField
                    select
                    label="Tipo de rango"
                    name="type"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                    fullWidth
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    sx={{ marginBottom: '1em' }}
                  >
                    {configAwardsState['range-types'].map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </InputField>
                </Grid>
              </Grid>

              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                {filterType !== undefined && filterType['award-ranges'] === true && (
                  <Button
                    color="primary"
                    size="large"
                    variant="outlined"
                    onClick={handleEditRange}
                    sx={{ marginRight: 2, width: '150px' }}
                  >
                    Editar rangos
                  </Button>
                )}
                {filterType !== undefined && filterType['award-ranges'] === true && (
                  <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                    <InputField
                      select
                      label="Copiar los valores de: "
                      fullWidth
                      sx={{ marginBottom: '1em' }}
                      value={selectCloneRange}
                    >
                      {listAwards.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={`${item.value} - ${item.employee_position.name}`}
                          onClick={() => handleCloneRange(item)}
                        >
                          {`${item.value} - ${item.employee_position.name}`}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Box>
                )}
              </Box>

              {formik.touched.ranges && formik.errors.ranges && (
                <FormHelperText error>{formik.errors.ranges}</FormHelperText>
              )}

              {selectAction === 'edit-form' && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.active}
                      onChange={formik.handleChange}
                      name="active"
                    />
                  }
                  label={formik.values.active ? 'Activo' : 'Desactivado'}
                />
              )}

              {formik.values.ranges.length !== 0 && createRange && (
                <SliderCommon ranges={formik.values.ranges} stateBool />
              )}
            </RenderIf>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                {editMode && selectAction !== 'create-form' && (
                  <>
                    <LoadingButton
                      color="primary"
                      size="large"
                      startIcon={<PencilIcon />}
                      variant="contained"
                      type="submit"
                      loading={formik.isSubmitting}
                    >
                      Guardar
                    </LoadingButton>
                    <Button
                      color="secondary"
                      size="large"
                      startIcon={<X />}
                      onClick={() => setEditmode(false)}
                      sx={{
                        marginLeft: 1
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                )}
                {selectAction === 'create-form' && (
                  <LoadingButton
                    color="primary"
                    size="large"
                    startIcon={<PlusIcon />}
                    variant="contained"
                    loading={formik.isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </LoadingButton>
                )}
                {selectAction === 'create-form' && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<X />}
                    onClick={handleCancel}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cancelar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <CustomerModal open={open} onClose={onClose} permission={GadminActions.G2_CONTESTS_UP}>
        <GenerateRangeForm
          onClose={onClose}
          typeOption={formik.values.type}
          handleSetRange={handleSetRange}
          maxRange={configAwardsState['max-ranges']}
          configAwardsState={configAwardsState['range-types']}
          valueOption={formik.values.value}
          handleSetTypeRange={handleSetTypeRange}
          handleSaveValue={handleSaveValue}
          setCreateRange={setCreateRange}
          ranges={formik.values.ranges}
          awards={formik.values.awards}
          minDistance={formik.values.min_distance}
          createRange={createRange}
          selectAction={selectAction}
          formikAwards={formik}
          employeePositionList={employeePositionList}
          configAward={configAwardsState}
          setEditmode={setEditmode}
        />
      </CustomerModal>
      <ConfirmationDialog
        message="Se copiaran los valores de rangos del premio seleccionado, ¿Seguro que quieres continuar?"
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

export default ContestAwardsForm;

ContestAwardsForm.propTypes = {
  initialValues: Proptypes.object,
  title: Proptypes.string,
  contestState: Proptypes.object,
  configAwardsState: Proptypes.object,
  typeState: Proptypes.array,
  employeePositionList: Proptypes.array,
  listAwards: Proptypes.array,
  handleRefreshDetail: Proptypes.func
};

// import moment from 'moment';
import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalPresaleMonitoringApi {
  async getPresaleMonitoring(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,

      mainFilter,
      id
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    // const dateNow = moment().format('YYYY-MM-DD');
    // const savedDate = localStorage.getItem('currentDate');

    // const dateAdapter = savedDate ? moment(new Date(savedDate)).format('YYYY-MM-DD') : dateNow;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/pre-sale/monitoring/sellers?companyId=${id}&teamId=${view}`,
      cacheItem: 'customer-presale',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'number', name: 'team_id' },
        { type: 'string', name: 'name' }
        // { type: 'string', name: 'CodigoAlmacen' },
        // { type: 'string', name: 'slpName' },
        // { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  async getSalesTeam({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/pre-sale/monitoring/teams?companyId=${companyId}`
    });
    return response;
  }

  async getPreSaleCustomerLocations({ companyId, teamId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/pre-sale/monitoring/customer-locations?companyId=${companyId}&teamId=${teamId}`
    });
    return response;
  }

  async getPreSalePolygons({ companyId, teamId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/pre-sale/monitoring/polygons?companyId=${companyId}&teamId=${teamId}`
    });
    return response;
  }
}

export const goalPresaleMonitoringApi = new GoalPresaleMonitoringApi();

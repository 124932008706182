import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// import { format } from 'date-fns';
import { Button, Card, CardHeader, Divider, FormHelperText, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { DateField } from '../../date-field';
import { ncServiceApi as invoiceApi } from '../../../api/nc-service';
import {
  paymentList,
  warehouseList,
  documnetTypeList,
  documnetStatus,
  documentTypeConstants
} from '../../../api/purchase-definitions';
import { useFormChecker } from '../../../hooks/use-form-checker';
import { useFormSaver } from '../../../hooks/use-form-saver';
import { appFormats } from '../../../api/app-definitions';
import { RenderIf } from '../../render-if';

export const NCServiceEdit = (props) => {
  const { invoice, setEditMode, onAfterSave } = props;
  const { currency, currencySymbol } = invoice;
  const [editPaymentTerm, setEditPaymentTerm] = useState(true);
  const [editWarehouse, setEditWarehouse] = useState(true);
  const [editDocumentType, setEditDocumentType] = useState(true);
  const [isService, setIsService] = useState(false);
  const [editTaxDate, setEditTaxDate] = useState(true);
  const [isNote, setIsNote] = useState(false);
  const [editAccount, setEditAccount] = useState(true);
  const [accountCodes, setAccountCodes] = useState(null);
  const { backendDateFormat } = appFormats;

  useEffect(() => {
    const draftState = invoice.status === documnetStatus.Borrador;
    setEditWarehouse(draftState && invoice.docType === documentTypeConstants.Item);
    setEditDocumentType(draftState);
    setEditPaymentTerm(draftState);
    setEditTaxDate(draftState);
    setEditAccount(draftState);
    setIsNote(true);
    setIsService(invoice.docType === documentTypeConstants.Service);
  }, [invoice]);

  useEffect(() => {
    const loadAccountCodes = async () => {
      const codes = await invoiceApi.getAccountCodes();
      setAccountCodes(codes);
    };
    loadAccountCodes();
  }, []);

  const handleAfterSave = () => {
    onAfterSave?.();
    handleClose();
  };
  const handleClose = () => {
    setEditMode(false);
  };

  const [handleUpdate] = useFormSaver({
    originalData: invoice,
    apiUpdateHandler: invoiceApi.updateInvoice,
    id: 'docEntry',
    handleAfterSave
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupNum: invoice?.groupNum || '',
      whsCode: invoice?.whsCode || '',
      docType: invoice?.docType || '',
      comments: invoice?.comments || '',
      docDate: invoice?.docDate || null,
      account: invoice?.account || '',
      reason: invoice?.reason || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      groupNum: Yup.string().max(255).required('Término de pago es requerido'),
      docDate: Yup.date().required('Término de pago es requerido'),
      comments: Yup.string().min(10).max(255).required('Comentario es requerido'),
      whsCode: Yup.string().when('docType', {
        is: documentTypeConstants.item,
        then: Yup.string().required('Almacén es requerido')
      }),
      docType: Yup.string().oneOf(['I', 'S']).required('Tipo de documento es requerido'),
      account: Yup.string()
        .max(20)
        .when('docType', {
          is: documentTypeConstants.service,
          then: Yup.string().required('Cuenta es requerida')
        }),
      reason: Yup.string().when('docType', {
        is: documentTypeConstants.service,
        then: Yup.string().nullable().required('Motivo es requerido')
      })
    }),
    onSubmit: async (values, helpers) => {
      await handleUpdate({ values, helpers });
    }
  });
  const [checkForm] = useFormChecker({ formik, setSaving: formik.setSubmitting });

  const whsField = editWarehouse ? (
    <PropertyListItem>
      <InputField
        error={Boolean(formik.touched.whsCode && formik.errors.whsCode)}
        helperText={formik.touched.whsCode && formik.errors.whsCode}
        label="Almacén"
        name="whsCode"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        select
        value={formik.values.whsCode}
      >
        {warehouseList.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </InputField>
    </PropertyListItem>
  ) : (
    <PropertyListItem label="Almacén" value={`${invoice.whsCode} - ${invoice.whsName}`} />
  );
  const paymentField = editPaymentTerm ? (
    <PropertyListItem>
      <InputField
        error={Boolean(formik.touched.groupNum && formik.errors.groupNum)}
        helperText={formik.touched.groupNum && formik.errors.groupNum}
        label="Término de pago"
        name="groupNum"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        select
        value={formik.values.groupNum}
      >
        {paymentList.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </InputField>
    </PropertyListItem>
  ) : (
    <PropertyListItem label="Término de pago" value={`#${invoice.paymentName}`} />
  );
  return (
    <Card variant="outlined">
      <CardHeader title="Detalle de comprobante" />
      <Divider />
      <form
        onSubmit={async (values, helpers) => {
          checkForm();
          formik.handleSubmit(values, helpers);
        }}
      >
        <Grid container>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Proveedor"
                value={`${invoice.cardCode} - ${invoice.cardName}`}
              />

              {editDocumentType ? (
                <PropertyListItem>
                  <RenderIf condition={!!documnetTypeList} no="Cargando tipos de documento">
                    <InputField
                      error={Boolean(formik.touched.docType && formik.errors.docType)}
                      helperText={formik.touched.docType && formik.errors.docType}
                      label="Tipo de documento"
                      name="docType"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        setEditWarehouse(e.target.value === 'I');
                        formik.handleChange(e);
                      }}
                      select
                      value={formik.values.docType}
                    >
                      {documnetTypeList.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </InputField>
                  </RenderIf>
                </PropertyListItem>
              ) : (
                <PropertyListItem label="Tipo de documento" value={`#${invoice.paymentName}`} />
              )}
              {editAccount ? (
                <PropertyListItem>
                  <RenderIf condition={!!accountCodes} no="Cargando cuentas">
                    <InputField
                      error={Boolean(formik.touched.groupNum && formik.errors.groupNum)}
                      helperText={formik.touched.groupNum && formik.errors.groupNum}
                      label="Cuenta"
                      name="account"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      select
                      value={formik.values.account}
                      sx={{ minWidth: 300 }}
                    >
                      {accountCodes?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.id}-${option.name}`}
                        </MenuItem>
                      ))}
                    </InputField>
                  </RenderIf>
                </PropertyListItem>
              ) : (
                <PropertyListItem label="Término de pago" value={`#${invoice.paymentName}`} />
              )}
              {editAccount ? (
                <PropertyListItem>
                  <InputField
                    error={Boolean(formik.touched.reason && formik.errors.reason)}
                    helperText={formik.touched.reason && formik.errors.reason}
                    label="Motivo"
                    name="reason"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.reason}
                    sx={{ minWidth: 300 }}
                  />
                </PropertyListItem>
              ) : (
                <PropertyListItem label="Notes" value={`“${invoice.note}”`} />
              )}
              {isService ? '' : whsField}
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem label="Numero" value={`#${invoice.document}`} />
              <PropertyListItem
                label="Fecha de emisión"
                value={moment(invoice.taxDate).format('DD/MM/YYYY')}
              />

              {isNote ? '' : paymentField}
              {isNote ? (
                ''
              ) : (
                <PropertyListItem
                  label="Fecha de Vencimiento"
                  value={moment(invoice.docDueDate).format('DD/MM/YYYY')}
                />
              )}
              {editTaxDate ? (
                <PropertyListItem>
                  <DateField
                    error={Boolean(formik.touched.docDate && formik.errors.docDate)}
                    helperText={formik.touched.docDate && formik.errors.docDate}
                    label="Fecha de Contabilización"
                    name="docDate"
                    onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    onChange={(date) => {
                      formik.setFieldValue('docDate', moment(date).format(backendDateFormat));
                    }}
                    value={moment(formik.values.docDate).toDate()}
                  />
                </PropertyListItem>
              ) : (
                <PropertyListItem
                  label="Fecha de Contabilización"
                  value={moment(invoice.docDate).format('DD/MM/YYYY')}
                />
              )}
              {editAccount ? (
                <PropertyListItem>
                  <InputField
                    error={Boolean(formik.touched.comments && formik.errors.comments)}
                    helperText={formik.touched.comments && formik.errors.comments}
                    label="Glosa"
                    name="comments"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.comments}
                    sx={{ minWidth: 300 }}
                  />
                </PropertyListItem>
              ) : (
                <PropertyListItem label="Notes" value={`“${invoice.note}”`} />
              )}
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Gravado"
                value={numeral(invoice.sumGravada).format(`${currencySymbol} 0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Inafecto"
                value={numeral(invoice.sumInafecta).format(`${currency}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Exonerado"
                value={numeral(invoice.sumExonerada).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Gratuito"
                value={numeral(invoice.sumGratuita).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="IGV"
                value={numeral(invoice.sumIgv).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Importe"
                value={numeral(invoice.docTotal).format(`${'S/'}0,0.00`)}
                align="right"
              />
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}
            <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
              <LoadingButton
                color="primary"
                size="large"
                startIcon={<PlusIcon />}
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
              >
                Guardar
              </LoadingButton>
            </AllowedGuard>
            <Button
              color="secondary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={async () => {
                setEditMode(false);
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

NCServiceEdit.propTypes = {
  invoice: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func.isRequired
};

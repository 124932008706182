import Proptypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Link
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { Download as DownloadIcon } from '../../../icons/download';
import GadminActions from '../../../contexts/gadmin-actions';
import { useDialog } from '../../../hooks/use-dialog';

import { NumberColor } from '../../number-color';

import { DocumetLogDialog } from '../../common/document-log-dialog';
import { RenderIf } from '../../render-if';
import { PendingsMenu } from './pendings-menu';
import { documentApi } from '../../../api/document';
import { useApp } from '../../../hooks/use-app';

export const FEPendingsSAPTable = (props) => {
  const { data: dataProp, onRefresh, label = 'Documentos pendientes', onDownload, view } = props;

  const { appUrlBase } = useApp();
  // eslint-disable-next-line no-unused-vars
  const [currentLine, setCurrentLine] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const handleExitedDialog = () => {};

  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });

  const handleRefresh = () => {
    onRefresh?.();
  };

  useEffect(() => {
    setDocumentState(dataProp);
  }, [dataProp]);

  if (!documentState.data) {
    return (
      <LoadingContent
        loadingText={documentState.isLoading ? `Cargando ${label}` : `No hay ${label}`}
      />
    );
  }
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title={label} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
                <RenderIf condition={!!onDownload}>
                  <Button
                    onClick={onDownload}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                  />
                </RenderIf>
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Numero SAP</TableCell>
              <TableCell>Documento</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Nombre de Cliente</TableCell>
              <TableCell>RUC/DNI</TableCell>
              <TableCell>Importe</TableCell>
              <TableCell> </TableCell>
              <TableCell>Percepción</TableCell>
              <TableCell>Condición</TableCell>
              <TableCell>Comentarios</TableCell>
              <TableCell>Dirección</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState.data?.map((item) => (
              <TableRow key={item.docentry}>
                <TableCell>{item.docentry}</TableCell>
                <TableCell>{item.docnum}</TableCell>
                <TableCell>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    underline="hover"
                    variant="subtitle2"
                    to={`${appUrlBase}/sales/receipts/${item.transid}`}
                  >
                    {item.serie}-{item.number}
                  </Link>
                </TableCell>
                <TableCell>{item.Fecha}</TableCell>
                <TableCell>{item.cardcode}</TableCell>
                <TableCell>{item.cardname}</TableCell>
                <TableCell>{item.lictradnum}</TableCell>
                <TableCell>
                  <NumberColor value={item.doctotal} format="0,000.00" minValue={5000} />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.perceptionsum} format="0,000.00" />
                </TableCell>
                <TableCell>
                  <PendingsMenu
                    invoice={item}
                    detailPath={`${appUrlBase}/sales/receipts`}
                    apiHandler={documentApi}
                    containerView={view}
                    afterProcessHandler={onRefresh}
                  />
                </TableCell>
                <TableCell>{item.paymentterm}</TableCell>
                <TableCell>{item.comments}</TableCell>
                <TableCell>{item.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DocumetLogDialog
          onClose={handleCloseEditDialog}
          onExited={handleExitedDialog}
          open={editDialogOpen}
          objType={currentLine.objtype || 0}
          docEntry={currentLine.docentry || 0}
        />
      </Scrollbar>
    </Card>
  );
};

FEPendingsSAPTable.propTypes = {
  data: Proptypes.object,
  onRefresh: Proptypes.func,
  onDownload: Proptypes.func,
  label: Proptypes.string,
  view: Proptypes.string.isRequired
};

import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
import { dataLoader } from './data-loader';

/* eslint class-methods-use-this:0 */

class DeliveryVehicleDriverApi {
  async getDeliveryVehicleDrivers(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.gCC_DELIVERY_CHOFERES,
      cacheItem: 'delivery-vehicle-drivers',
      apiParams: {
        type: 'list',
        payload: { filters, sort, sortBy, page, query, view, reload }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'Code' },
        { type: 'string', name: 'Name' },
        { type: 'string', name: 'driverLicense' }
      ],
      ...options
    });
  }

  async bulkDeliveryVehicleDriversActions(props) {
    const response = await GadminRequest(GadminActions.gCC_DELIVERY_CHOFERES_UP, props);
    return response;
  }
}

export const deliveryVehicleDriverApi = new DeliveryVehicleDriverApi();

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { Button, Card, CardHeader, Divider, FormHelperText, Grid, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { DateField } from '../../date-field';
import { invoiceApi } from '../../../api/invoice';
import { purchaseHelperApi } from '../../../api/purchase-helper';
import { appFormats } from '../../../api/app-definitions';

import {
  documnetTypeList,
  documnetStatus,
  documentTypeConstants,
  purchaseView
} from '../../../api/purchase-definitions';
import { RenderIf } from '../../render-if';
import { useMounted } from '../../../hooks/use-mounted';
import { useData } from '../../../hooks/use-data';
import { useAuth } from '../../../hooks/use-auth';
import { AutocompleteField } from '../../autocomplete-field';
import { useFormSaver } from '../../../hooks/use-form-saver';
import { useFormChecker } from '../../../hooks/use-form-checker';

export const InvoiceEdit = (props) => {
  const { invoice, setEditMode, onAfterSave } = props;
  const { currency, currencySymbol } = invoice;
  const [editPaymentTerm, setEditPaymentTerm] = useState(true);
  const [editBranch, setEditBranch] = useState(true);
  const [editDocumentType, setEditDocumentType] = useState(true);
  const [editTaxDate, setEditTaxDate] = useState(true);
  const [editAccount, setEditAccount] = useState(true);
  const { backendDateFormat, frontDateFormat } = appFormats;

  // const [document, setDocument] = useState(invoice);
  const mounted = useMounted();
  const { hasPermission } = useAuth();
  useEffect(() => {
    const draftState = invoice.status === documnetStatus.Borrador;
    setEditBranch(draftState && invoice.docType === documentTypeConstants.Service);
    setEditDocumentType(draftState && hasPermission('purchase.edit'));
    setEditPaymentTerm(draftState);
    setEditTaxDate(draftState);
    setEditAccount(draftState);
  }, [invoice]);
  // const itemChanges = useItemChanges(invoice, changes);

  const [bpAccountList] = useData({
    sourceApi: purchaseHelperApi.getServiceBusinessPartnerAccounts,
    apiParameter: { view: purchaseView.PURCHASE_SERVICE },
    loadingMessage: 'Cargando cuentas contables para socio de negocio',
    mounted
  });

  const [accountList] = useData({
    sourceApi: purchaseHelperApi.getServiceAccounts,
    apiParameter: { view: purchaseView.PURCHASE_SERVICE },
    loadingMessage: 'Cargando cuentas contables',
    mounted
  });
  const [costingCodeList] = useData({
    sourceApi: purchaseHelperApi.getServiceCostingCode,
    apiParameter: { view: purchaseView.PURCHASE_SERVICE },
    loadingMessage: 'Cargando centros de costos',
    mounted
  });

  const [sedeConfigList] = useData({
    sourceApi: purchaseHelperApi.getSedesConfig,
    apiParameter: { view: purchaseView.PURCHASE_SERVICE },
    loadingMessage: 'Cargando sedes',
    mounted
  });

  const [paymentTermList] = useData({
    sourceApi: purchaseHelperApi.getServicePaymentTerm,
    apiParameter: { view: purchaseView.PURCHASE_SERVICE },
    loadingMessage: 'Cargando términos de pago',
    mounted
  });

  const handleAfterSave = () => {
    onAfterSave?.();
    handleClose();
  };
  const handleClose = () => {
    setEditMode(false);
  };
  const [handleUpdate] = useFormSaver({
    originalData: invoice,
    apiUpdateHandler: invoiceApi.updateInvoice,
    id: 'docEntry',
    handleAfterSave
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupNum: invoice?.groupNum || '',
      branch: invoice?.branch || '',
      docType: invoice?.docType || '',
      comments: invoice?.comments || '',
      docDate: invoice?.docDate || invoice?.taxDate || null,
      account: invoice?.account || '',
      ocrCode: invoice?.ocrCode || '',
      control_account: invoice?.control_account || '',
      docDueDate: invoice?.docDueDate || invoice?.taxDate || null,
      vehicle: invoice?.vehicle || '',
      seriesConfig: invoice?.seriesConfig || -1,
      submit: null
    },
    validationSchema: Yup.object().shape({
      groupNum: Yup.string().max(255).required('Término de pago es requerido'),
      branch: Yup.string().when('docType', {
        is: documentTypeConstants.Service,
        then: Yup.string().required('Sede es requerido')
      }),
      docType: Yup.string().oneOf(['I', 'S']).required('Tipo de documento es requerido'),
      comments: Yup.string().min(10).max(255).required('Comentario es requerido'),
      control_account: Yup.string()
        .min(5)
        .max(255)
        .required('Cuenta de socio de negocio es requerida'),
      docDate: Yup.date().required('Fecha de documento es requerido'),
      account: Yup.string()
        .max(20)
        .when('docType', {
          is: documentTypeConstants.Service,
          then: Yup.string().required('Cuenta es requerida')
        }),
      ocrCode: Yup.string().when('docType', {
        is: documentTypeConstants.Service,
        then: Yup.string().nullable().required('Centro de costo es requerido')
      }),
      docDueDate: Yup.date().required('Fecha de vencimiento es requerida')
    }),
    onSubmit: async (values, helpers) => {
      await handleUpdate({ values, helpers });
    }
  });
  const [checkForm] = useFormChecker({ formik, setSaving: formik.setSubmitting });

  return (
    <Card variant="outlined">
      <CardHeader title="Detalle de comprobante" />
      <Divider />
      <form
        onSubmit={async (values, helpers) => {
          checkForm();
          formik.handleSubmit(values, helpers);
        }}
      >
        <Grid container>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem
                label="Proveedor"
                value={`${invoice.cardCode} - ${invoice.cardName}`}
              />
              <RenderIf condition={editDocumentType}>
                <PropertyListItem label="Tipo de documento">
                  <InputField
                    error={Boolean(formik.touched.docType && formik.errors.docType)}
                    helperText={formik.touched.docType && formik.errors.docType}
                    name="docType"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      setEditBranch(e.target.value === 'I');
                      formik.handleChange(e);
                    }}
                    select
                    fullWidth
                    value={formik.values.docType}
                  >
                    {documnetTypeList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </InputField>
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editPaymentTerm}
                no={<PropertyListItem label="Término de pago" value={`#${invoice.groupNum}`} />}
              >
                <PropertyListItem label="Término de pago" isForm>
                  <RenderIf
                    condition={!!paymentTermList.data}
                    no={paymentTermList.message || 'Cargando'}
                  >
                    <AutocompleteField
                      error={Boolean(formik.touched.groupNum && formik.errors.groupNum)}
                      helperText={formik.touched.groupNum && formik.errors.groupNum}
                      options={paymentTermList.data || [{ label: 'Cargando', id: 'Cargando' }]}
                      name="groupNum"
                      placeholder="Seleccione un término de pago"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('groupNum', value?.id || '');
                      }}
                      label="Término de pago"
                      fullWidth
                      value={
                        paymentTermList.data?.find(
                          (item) => item.id === formik.values.groupNum
                        ) || {
                          label: 'Cargando nombre',
                          id: formik.values.groupNum
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Cuenta" value={`#${invoice.account}`} />}
              >
                <PropertyListItem label="Cuenta" isForm>
                  <RenderIf condition={!!accountList.data} no={accountList.message || 'Cargando'}>
                    <AutocompleteField
                      error={Boolean(formik.touched.account && formik.errors.account)}
                      helperText={formik.touched.account && formik.errors.account}
                      options={accountList.data || [{ label: 'Cargando', id: 'Cargando' }]}
                      name="account"
                      placeholder="Seleccione una cuenta contable"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('account', value?.id || '');
                      }}
                      label="Cuenta contable"
                      fullWidth
                      value={
                        accountList.data?.find((item) => item.id === formik.values.account) || {
                          label: 'Cargando nombre',
                          id: formik.values.account
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>

              <RenderIf
                condition={editAccount}
                no={
                  <PropertyListItem
                    label="Cuenta Socio de negocio"
                    value={`#${invoice.control_account}`}
                  />
                }
              >
                <PropertyListItem label="Cuenta Socio de negocio" isForm>
                  <RenderIf
                    condition={!!bpAccountList.data && bpAccountList.data.length > 0}
                    no={bpAccountList.message || 'Cargando'}
                  >
                    <AutocompleteField
                      error={Boolean(
                        formik.touched.control_account && formik.errors.control_account
                      )}
                      helperText={formik.touched.control_account && formik.errors.control_account}
                      options={bpAccountList.data || [{ label: 'Cargando', id: '' }]}
                      name="control_account"
                      label="Cuenta Socio de negocio"
                      placeholder="Seleccione una Cuenta Socio de negocio"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('control_account', value?.id || '');
                      }}
                      fullWidth
                      value={
                        bpAccountList.data?.find(
                          (item) => item.id === formik.values.control_account
                        ) || {
                          label: 'Cargando nombre',
                          id: formik.values.control_account
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>

              <RenderIf
                condition={editBranch}
                no={
                  <PropertyListItem
                    label="Centro de costo"
                    value={`${invoice.ocrCode} - ${invoice.ocrName}`}
                  />
                }
              >
                <PropertyListItem label="Centro de costo" isForm>
                  <RenderIf
                    condition={!!costingCodeList.data}
                    no={costingCodeList.message || 'Cargando'}
                  >
                    <AutocompleteField
                      error={Boolean(formik.touched.ocrCode && formik.errors.ocrCode)}
                      helperText={formik.touched.ocrCode && formik.errors.ocrCode}
                      options={costingCodeList.data || [{ label: 'Cargando', id: '' }]}
                      name="ocrCode"
                      placeholder="Seleccione un centro de beneficio"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('ocrCode', value?.id || '');
                      }}
                      label="Centro de costo"
                      fullWidth
                      value={
                        costingCodeList.data?.find((item) => item.id === formik.values.ocrCode) || {
                          label: 'Cargando nombre',
                          id: formik.values.ocrCode
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <PropertyListItem label="Numero" value={`#${invoice.document}`} />
              <PropertyListItem
                label="Fecha de emisión"
                value={moment(invoice.taxDate).format(frontDateFormat)}
              />

              <RenderIf
                condition={editTaxDate}
                no={
                  <PropertyListItem
                    label="Fecha de Contabilización"
                    value={moment(invoice.docDate).format(frontDateFormat)}
                  />
                }
              >
                <PropertyListItem label="Fecha de Contabilización">
                  <DateField
                    error={Boolean(formik.touched.docDate && formik.errors.docDate)}
                    helperText={formik.touched.docDate && formik.errors.docDate}
                    name="docDate"
                    onBlur={formik.handleBlur}
                    onChange={(date) => {
                      formik.setFieldValue('docDate', moment(date).format(backendDateFormat));
                    }}
                    value={moment(formik.values.docDate).toDate()}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editTaxDate}
                no={
                  <PropertyListItem
                    label="Fecha de Vencimiento"
                    value={moment(invoice.docDueDate).format(frontDateFormat)}
                  />
                }
              >
                <PropertyListItem label="Fecha de vencimiento">
                  <DateField
                    error={Boolean(formik.touched.docDueDate && formik.errors.docDueDate)}
                    helperText={formik.touched.docDueDate && formik.errors.docDueDate}
                    name="docDate"
                    onBlur={formik.handleBlur}
                    onChange={(date) => {
                      formik.setFieldValue('docDueDate', moment(date).format(backendDateFormat));
                    }}
                    value={moment(formik.values.docDueDate).toDate()}
                  />
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Notes" value={`“${invoice.note}”`} />}
              >
                <PropertyListItem label="Glosa" isForm>
                  <InputField
                    error={Boolean(formik.touched.comments && formik.errors.comments)}
                    helperText={formik.touched.comments && formik.errors.comments}
                    name="comments"
                    label="Glosa"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.comments}
                    multiline
                    maxRows={5}
                  />
                </PropertyListItem>
              </RenderIf>
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <RenderIf
                condition={editBranch}
                no={
                  <PropertyListItem
                    label="Sede"
                    value={`${invoice.branch} - ${invoice.branchName}`}
                  />
                }
              >
                <PropertyListItem label="sede" isForm>
                  <RenderIf
                    condition={!!sedeConfigList.data}
                    no={sedeConfigList.message || 'Cargando'}
                  >
                    <AutocompleteField
                      error={Boolean(formik.touched.seriesConfig && formik.errors.seriesConfig)}
                      helperText={formik.touched.seriesConfig && formik.errors.seriesConfig}
                      options={sedeConfigList.data || [{ label: 'Cargando', id: 'Cargando' }]}
                      name="seriesConfig"
                      placeholder="Seleccione una sede"
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        const paymentInfo = sedeConfigList.data?.find((i) => i.id === value?.id);
                        if (paymentInfo) {
                          formik.setFieldValue('cashAccount', paymentInfo.cashAccount);
                          formik.setFieldValue('series', paymentInfo.series);
                          formik.setFieldValue('branch', paymentInfo.sede);
                        }
                        formik.setFieldValue('seriesConfig', value?.id || -1);
                      }}
                      label="Sede"
                      fullWidth
                      value={
                        sedeConfigList.data?.find(
                          (item) => item.id === formik.values.seriesConfig
                        ) || {
                          label: 'Cargando nombre',
                          id: formik.values.seriesConfig
                        }
                      }
                    />
                  </RenderIf>
                </PropertyListItem>
              </RenderIf>
              <RenderIf
                condition={editAccount}
                no={<PropertyListItem label="Vehículo" value={`“${invoice.vehicle}”`} />}
              >
                <PropertyListItem label="Vehículo" isForm>
                  <InputField
                    error={Boolean(formik.touched.vehicle && formik.errors.vehicle)}
                    helperText={formik.touched.vehicle && formik.errors.vehicle}
                    name="vehicle"
                    label="Vehículo"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue('vehicle', (e.target.value || '').toUpperCase());
                    }}
                    value={formik.values.vehicle}
                  />
                </PropertyListItem>
              </RenderIf>

              <PropertyListItem
                label="Gravado"
                value={numeral(invoice.sumGravada).format(`${currencySymbol} 0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Inafecto"
                value={numeral(invoice.sumInafecta).format(`${currency}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Exonerado"
                value={numeral(invoice.sumExonerada).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Gratuito"
                value={numeral(invoice.sumGratuita).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="IGV"
                value={numeral(invoice.sumIgv).format(`${'S/'}0,0.00`)}
                align="right"
              />
              <PropertyListItem
                label="Importe"
                value={numeral(invoice.docTotal).format(`${'S/'}0,0.00`)}
                align="right"
              />
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}
            <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
              <LoadingButton
                color="primary"
                size="large"
                startIcon={<PlusIcon />}
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
              >
                Guardar
              </LoadingButton>
            </AllowedGuard>
            <Button
              color="secondary"
              size="large"
              startIcon={<TrashIcon />}
              onClick={async () => {
                setEditMode(false);
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

InvoiceEdit.propTypes = {
  invoice: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func.isRequired
};

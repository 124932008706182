import { useState } from 'react';
import Proptypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-eva/arrow-right-fill';
import arrowBottom from '@iconify/icons-eva/arrow-down-outline';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { makeStyles } from '@mui/styles';
import { Trash } from '../../icons/trash';
import { ConfirmationDialog } from '../confirmation-dialog';
import { capitalizeParagraph } from '../../hooks/use-split';
import Scrollbar from '../Scrollbar';

export const CommonTreeView = ({
  expandedItems,
  setExpandedItems,
  title = '',
  setSelectedNodeDataItem,
  onSelectNode,
  data,
  deleteAction = false,
  onDeleteNode = () => {}
}) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [idDelete, setIdDelete] = useState(null);

  const useStyles = makeStyles({
    selected: {
      backgroundColor: 'transparent !important'
    }
  });

  const classes = useStyles();

  const renderNodes = (items, level) =>
    items?.map((item) => {
      item.level = level;
      return (
        <TreeItem
          classes={{ selected: classes.selected }}
          key={item.id}
          nodeId={`${item.id}`}
          label={
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {capitalizeParagraph(item.name)}
              {selectedNode === item.id && !item.has_children && deleteAction && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenCornfirm(item);
                  }}
                >
                  <Trash />
                </IconButton>
              )}
            </Box>
          }
          onClick={() => {
            onSelectNode(item);
            setSelectedNode(item.id);
          }}
          sx={{ paddingLeft: level === 1 ? 3 : 1, marginTop: '5px' }}
          style={selectedNode === item.id ? { border: '2px solid #D24040' } : {}}
        >
          {item.children && renderNodes(item.children, level + 1)}
        </TreeItem>
      );
    });

  const handleOpenCornfirm = (item) => {
    setModalOpen(true);
    setIdDelete(item.id);
    setConfirmDialogMessage(`Estas seguro de eliminar este nodo - ${item.name}`);
  };

  const handleClickDelete = async (idDelete) => {
    onDeleteNode(idDelete);
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        height: 'auto',
        flexGrow: 1,
        maxWidth: '100%',
        overflowY: 'auto',
        background: '#fff',
        paddingX: 1,
        paddingY: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'neutral.300',
        boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)'
      }}
      px={2}
    >
      <Scrollbar style={{ height: 300 }}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<Icon icon={arrowBottom} fontSize="large" />}
          defaultExpandIcon={<Icon icon={arrowRight} />}
          expanded={expandedItems}
          onNodeToggle={(event, nodeIds) => setExpandedItems(nodeIds)}
          // multiSelect
        >
          <TreeItem
            nodeId="node_0"
            label={<Typography variant="h6">{title}</Typography>}
            // onClick={() => setSelectedNodeDataItem(null)}
            onClick={() => {
              setSelectedNodeDataItem(null);
              setSelectedNode('node_0');
              setExpandedItems([]);
            }}
            style={
              selectedNode === 'node_0' ? { backgroundColor: '#D24040', color: '#FFFFFF' } : {}
            }
          />
          {renderNodes(data, 1)}
        </TreeView>
      </Scrollbar>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => handleClickDelete(idDelete)}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

CommonTreeView.propTypes = {
  expandedItems: Proptypes.array,
  setExpandedItems: Proptypes.func,
  title: Proptypes.string,
  setSelectedNodeDataItem: Proptypes.func,
  data: Proptypes.array,
  onSelectNode: Proptypes.func,
  onDeleteNode: Proptypes.func,
  deleteAction: Proptypes.bool
};

// ----------------------------------------------------------------------

import { alpha, useTheme } from '@mui/material';

export default function Box() {
  const theme = useTheme();
  return {
    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.background.paper, 0.16),
          borderRadius: '8px',
          flexGrow: 1
        }
      }
    }
  };
}

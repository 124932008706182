import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Typography
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import { LoadingTable } from '../../common/loading-table';
import { useDialog } from '../../../hooks/use-dialog';
import { TablesEditLine } from '../../sales-teams/tables-edit-line';
import { Pencil as PencilIcon } from '../../../icons/pencil';

export const BusinessPartnersDivisionsTable = ({ onRefresh, documentState, documents = [] }) => {
  const handleRefresh = () => {
    onRefresh?.();
  };
  const [itemData, setItemEdit] = useState(null);
  const [openActive, handleOpenActive, handleCloseActive] = useDialog();

  const handleClickEditActive = (item) => {
    setItemEdit(item);
    handleOpenActive();
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginY: '1em'
      }}
    >
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Division" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell>#</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Division</TableCell>
              <TableCell>Canal</TableCell>
              <TableCell>Inicio</TableCell>
              <TableCell>Último</TableCell>
              <TableCell>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {index}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.client}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.divisions}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.channel}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.start}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.end}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      minWidth: 150,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {openActive && itemData?.id === item.id ? (
                      <>
                        <TablesEditLine
                          type="check"
                          item={item.status}
                          handleClose={handleCloseActive}
                        />
                      </>
                    ) : (
                      <>
                        <Typography color="textSecondary" variant="body2">
                          {item.status}
                        </Typography>
                        <PencilIcon
                          color="primary"
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => handleClickEditActive(item)}
                        />
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <LoadingTable documentState={documentState} />
      </Scrollbar>
    </Card>
  );
};

BusinessPartnersDivisionsTable.propTypes = {
  documentState: PropTypes.object.isRequired,
  documents: PropTypes.array,
  onRefresh: PropTypes.func.isRequired
};

import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Button, DialogActions, DialogTitle, Grid, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../../property-list';
import { InputField } from '../../input-field';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Pencil } from '../../../icons/pencil';
import { Trash as TrashIcon } from '../../../icons/trash';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { useApp } from '../../../hooks/use-app';
import { goalMaintenanceSalesApi } from '../../../api/goal/maintenance/goal-maintenance-sales';
import { AutocompleteField } from '../../autocomplete-field';
import { MaintenaceMap } from '../../maintenance/base/maintenace-map';
import { PropertyListItem } from '../../property-list-item';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { ConstantAddressType, ConstantAddressTypeName } from '../../../api/customer-form-enum';

export const BusinessPartnerAddressesForm = ({
  update = false,
  title,
  initialValues = {},
  onClose,
  ubigeoState = [],
  handleRefresh,
  setWidhtFormState,
  widhtFormState = 'xl'
}) => {
  const { currentCompany, showNotify, showError } = useApp();

  const { cardcode: cardcodeQuery } = useParams();

  const [showMap, setShowMap] = useState(update);
  const [territoriesData, setTerritoriesData] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  useEffect(() => {
    getApiData();
  }, []);
  const getApiData = async () => {
    const [territoriesData, bussinessTypeData] = await Promise.all([
      goalMaintenanceSalesApi.getTerritoriesList({
        companyId: currentCompany
      }),
      goalBaseBusinessPartnersApi.getBussinesPartnerBusinessTypes()
    ]);

    if (territoriesData.success && territoriesData.data.length !== 0) {
      setTerritoriesData(territoriesData.data);
    }
    if (bussinessTypeData.success && bussinessTypeData.data.length !== 0) {
      setBusinessType(bussinessTypeData.data);
    }
  };

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany,
      business_partner_id: initialValues.business_partner_id || cardcodeQuery,
      customer_code: initialValues.customer_code || cardcodeQuery,
      address_type: initialValues.address_type || ConstantAddressType.Shipping,
      code: initialValues.code || '',
      address: initialValues.address || '',
      territory_code: initialValues.territory_code || '',
      phone: initialValues.phone || '',
      coordinates: initialValues.coordinates || {
        latitude: -9.189967,
        longitude: -75.015152,
        altitude: 0
      },
      branchInfo: initialValues.branchInfo || '',
      branch_id: initialValues.branch_id || '',
      territoriesInfo: initialValues.territoriesInfo || '',
      territory_id: initialValues.territory_id || '',
      seller_id: initialValues.seller_id || '',
      ubigeo:
        {
          district_name: initialValues.district || '',
          province_name: initialValues.province || '',
          departament_name: initialValues.department || '',
          code: initialValues.ubigeo_code,
          name: update
            ? `${initialValues.district} - ${initialValues.province} - ${initialValues.department}`
            : ''
        } || '',
      ubigeo_code: initialValues.ubigeo_code || '',
      ubigeo_id: initialValues.ubigeo_id || '',
      postal_code: initialValues.postal_code || '',
      district: initialValues.district || '',
      province: initialValues.province || '',
      department: initialValues.department || '',
      cpo_code: initialValues.cpo_code || '',
      cpo_name: initialValues.cpo_name || '',
      cpo_info: initialValues.cpo_info || '',
      business_type_id: initialValues.business_type_id || '',
      business_type_info: initialValues.business_type_info || ''
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      const newValues = {};
      Object.entries(values).forEach(([key, value]) => {
        if (value === '') {
          newValues[key] = null;
        } else {
          newValues[key] = value;
        }
      });
      if (update) {
        const response = await goalBaseBusinessPartnersApi.updateAddressCustomer({
          id: initialValues.id,
          values: newValues
        });
        if (response.success) {
          showNotify(`${response?.message}. Creación exitoso `);
          handleRefresh();
          onClose(false);
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      } else {
        const response = await goalBaseBusinessPartnersApi.postAddressCustomer({
          companyId: currentCompany,
          values: newValues
        });
        if (response.success) {
          showNotify(`${response?.message}. Creación exitoso `);
          handleRefresh();
          onClose(false);
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      }
    }
  });

  const handleShowMap = () => {
    setShowMap(!showMap);
    setWidhtFormState(showMap ? 'md' : 'xl');
  };

  const handleCloseForm = () => {
    setWidhtFormState('md');
    onClose(false);
  };

  const dataAddress = {
    coordinates: formik.values.coordinates,
    name: formik.values.address
  };

  const handleLocation = (latitude, longitude) => {
    if (formik.values.address_type === ConstantAddressType.Shipping) {
      formik.setFieldValue('coordinates', {
        latitude,
        longitude
      });
    }
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2,
            padding: 5
          }}
        >
          <Grid item xs={12} md={widhtFormState === 'md' ? 12 : 6}>
            <PropertyList>
              {update && (
                <InputField
                  label="Código"
                  name="code"
                  value={formik.values.code}
                  fullWidth
                  sx={{ marginBottom: '1em' }}
                  disabled
                />
              )}

              {update && (
                <InputField
                  select
                  label="Tipo de dirección"
                  name="address_type"
                  value={formik.values.address_type}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.address_type && Boolean(formik.errors.address_type)}
                  helperText={formik.touched.address_type && formik.errors.address_type}
                  sx={{ marginBottom: '1em' }}
                  disabled
                >
                  <MenuItem value={ConstantAddressType.Shipping}>
                    {' '}
                    {ConstantAddressTypeName.Shipping}{' '}
                  </MenuItem>
                  <MenuItem value={ConstantAddressType.Billing}>
                    {ConstantAddressTypeName.Billing}
                  </MenuItem>
                </InputField>
              )}

              {update && (
                <InputField
                  label="Vendedor"
                  name="seller_id"
                  value={formik.values.seller_id}
                  fullWidth
                  sx={{ marginBottom: '1em' }}
                  disabled
                />
              )}
              <InputField
                label="Dirección"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="Localidad"
                name="ubigeo"
                placeholder="Seleccione localidad"
                fullWidth
                options={ubigeoState || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('ubigeo_code', value?.code);
                  formik.setFieldValue('ubigeo_id', value?.id);
                  formik.setFieldValue('district', value?.district_name);
                  formik.setFieldValue('province', value?.province_name);
                  formik.setFieldValue('department', value?.departament_name);
                  // formik.setFieldValue('address', value?.name);
                  formik.setFieldValue('ubigeo', value);
                  if (value === null) {
                    formik.setFieldValue('ubigeo_code', '');
                    formik.setFieldValue('ubigeo_id', '');
                    formik.setFieldValue('district', '');
                    formik.setFieldValue('province', value?.province_name);
                    formik.setFieldValue('department', value?.departament_name);
                    // formik.setFieldValue('address', '');
                  }
                }}
                value={formik.values.ubigeo}
                sx={{ marginBottom: '1em' }}
                error={formik.touched.ubigeo && Boolean(formik.errors.ubigeo)}
                helperText={formik.touched.ubigeo && formik.errors.ubigeo}
              />
              <InputField
                label="Código postal"
                name="postal_code"
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
                helperText={formik.touched.postal_code && formik.errors.postal_code}
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="Giro de negocio"
                name="business_type_info"
                placeholder="Seleccione un giro de negocio"
                fullWidth
                options={businessType || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('business_type_id', value?.id);
                  formik.setFieldValue('business_type_info', value);
                }}
                value={formik.values.business_type_info}
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="Territorio"
                name="territoriesInfo"
                placeholder="Seleccione un territorio"
                fullWidth
                options={territoriesData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('territory_id', value?.id);
                  formik.setFieldValue('territory_code', value?.code);
                  formik.setFieldValue('territoriesInfo', value);
                }}
                value={formik.values.territoriesInfo}
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="Sede"
                name="branchInfo"
                placeholder="Seleccione una sede"
                fullWidth
                options={territoriesData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('branch_id', value?.id);
                  formik.setFieldValue('branchInfo', value);
                }}
                value={formik.values.branchInfo}
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="Centro poblado"
                name="cpo_info"
                placeholder="Seleccione un centro poblado"
                fullWidth
                options={territoriesData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('cpo_code', value?.id);
                  formik.setFieldValue('cpo_name', value?.name);
                  formik.setFieldValue('cpo_info', value);
                }}
                value={formik.values.cpo_info}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                label="Teléfono"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                sx={{ marginBottom: '1em' }}
              />
              {formik.values.address_type !== ConstantAddressType.Billing && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleShowMap}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  {showMap ? 'Ocultar mapa' : 'Mostrar mapa'}
                </Button>
              )}
            </PropertyList>
          </Grid>
          <Grid item xs={12} md={widhtFormState === 'md' ? 12 : 6}>
            <PropertyList>
              {showMap && formik.values.address_type !== ConstantAddressType.Billing && (
                <>
                  <MaintenaceMap
                    locationState={dataAddress}
                    handleLocation={handleLocation}
                    draggableForm={formik.values.address_type === ConstantAddressType.Shipping}
                  />
                  <PropertyListItem label="Latitud" align="right">
                    <Typography color="textSecondary" variant="body2" align="right">
                      {formik.values.coordinates.latitude}
                    </Typography>
                  </PropertyListItem>
                  <PropertyListItem label="Longitud" align="right">
                    <Typography color="textSecondary" variant="body2" align="right">
                      {formik.values.coordinates.longitude}
                    </Typography>
                  </PropertyListItem>
                </>
              )}
            </PropertyList>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={update ? <Pencil /> : <PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={handleCloseForm}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  {update ? 'Cancelar' : 'Cerrar'}
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

BusinessPartnerAddressesForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  ubigeoState: Proptypes.array,
  handleRefresh: Proptypes.func,
  setWidhtFormState: Proptypes.func,
  widhtFormState: Proptypes.string
};

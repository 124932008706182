import Proptypes from 'prop-types';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import { productUnitMeasuresApi } from '../../api/goal/inventory/product-unit-measures-api';
import { useApp } from '../../hooks/use-app';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import LoadingContent from '../../components/common/loading-content';
import CloseIcon from '@mui/icons-material/Close';

function ProductUnitMeasuresForSelect({ handleClose, handleSelect, productId }) {
  const mounted = useMounted();
  const { currentCompany } = useApp();

  const [documentState] = useData({
    sourceApi: productUnitMeasuresApi.getProductUnitMeasuresForSelect,
    apiParameter: { companyId: currentCompany, productId },
    loadingMessage: 'Cargando Medidas unitarias del producto',
    mounted
  });

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ paddingY: 1 }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Selecciona la Unidad de Medida
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          paddingX: 3,
          paddingBottom: 3,
          backgroundColor: '#ffffffda'
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Unidad de Medida</TableCell>
              <TableCell>Seleccionar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState.data.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.product_id}</TableCell>
                <TableCell>{item.unit_measure.name}</TableCell>
                <TableCell>
                  <Button onClick={() => handleSelect(item)}>Seleccionar</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

ProductUnitMeasuresForSelect.propTypes = {
  handleClose: Proptypes.func.isRequired,
  handleSelect: Proptypes.func.isRequired,
  productId: Proptypes.number.isRequired
};

export default ProductUnitMeasuresForSelect;

import PropTypes from 'prop-types';
import { Box, Button, CardHeader, Chip, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { StatusColor } from '../../contest/contest-definition';
import { Status } from '../../status';

export const BusinessPartnersSumaryDetail = ({ setEditMode, item }) => (
  <Box>
    <CardHeader title="Detalle del cliente" />
    <Divider />
    <Grid container spacing={8}>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem label="Empresa">
            <Typography color="textSecondary" variant="body2">
              {item.company_id || '---'} - {item.companyInfo?.short_name || '---'} -
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Tipo de socio" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.businessPartnerGroupInfo?.name || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Tipo Persona" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.person_type || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Tipo de Documento" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.taxIdTypeInfo?.short_name || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Documento" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.tax_id || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Apellidos" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.last_name || '---'} - {item.last_name2 || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Nombres" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.first_name || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Nombre comercial" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.comercial_name || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Email" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.email || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Giro de Negocio" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.businessTypeInfo?.name || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem label="Grupo de cliente" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.businessPartnerGroupInfo?.code || '---'} -{' '}
              {item.businessPartnerGroupInfo?.name || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Código cliente" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.customer_code || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Termino de pago del cliente" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.paymentTermSellInfo?.name || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Canal Preferido" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.customerChannelInfo?.name || '---'}
            </Typography>
          </PropertyListItem>
          {/* <PropertyListItem label="Grupo de proveedor" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.businessPartnerGroupInfo?.code || '---'} -{' '}
              {item.businessPartnerGroupInfo?.name || '---'}
            </Typography>
          </PropertyListItem> */}
          <PropertyListItem label="Código proveedor" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.vendor_code || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Termino de pago del proveedor" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.paymentTermBuyInfo?.name || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem label="Número de empleados" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.employee_number || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Puestos de empleado" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.employee_position_id || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Departamento" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.departament_id || '--'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Sedes" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.branch_id || '--'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="# Licencia" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.license_number || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Estado cliente">
            <Status color={StatusColor(item.customer_status)} label="Activo" />
          </PropertyListItem>
          <PropertyListItem label="Estado empleado">
            <Status color={StatusColor(item.employee_status)} label="Activo" />
          </PropertyListItem>
          <Chip
            label="Es cliente"
            variant={item.is_customer === 'Y' ? 'filled' : 'outlined'}
            color={item.is_customer === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 2, marginBottom: 1 }}
          />
          <Chip
            label="Empleado"
            variant={item.is_employee === 'Y' ? 'filled' : 'outlined'}
            color={item.is_employee === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 2, marginBottom: 1 }}
          />
          <Chip
            label="Vendedor"
            variant={item.is_vendor === 'Y' ? 'filled' : 'outlined'}
            color={item.is_vendor === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 2, marginBottom: 1 }}
          />
          <Chip
            label="Empleado externo"
            variant={item.is_freelancer === 'Y' ? 'filled' : 'outlined'}
            color={item.is_freelancer === 'Y' ? 'primary' : 'default'}
            sx={{ marginLeft: 2, marginBottom: 1 }}
          />
        </PropertyList>
      </Grid>
    </Grid>
    <AllowedGuard permission={GadminActions.G2_CONTESTS_UP}>
      <Button
        color="primary"
        size="large"
        startIcon={<PlusIcon />}
        onClick={() => {
          setEditMode(true);
        }}
        variant="contained"
        sx={{
          marginLeft: 2
        }}
      >
        Editar
      </Button>
    </AllowedGuard>
  </Box>
);

BusinessPartnersSumaryDetail.propTypes = {
  setEditMode: PropTypes.func,
  item: PropTypes.object
};

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, CardHeader, Container, Divider, Typography } from '@mui/material';
import { useData } from '../../../../hooks/use-data';
import { useMounted } from '../../../../hooks/use-mounted';
import { goalInventoryApi } from '../../../../api/goal/inventory/goal-inventory';
import { RenderIf } from '../../../render-if';
import { ProductSumaryDetail } from './product-sumary-detail';
import LoadingContent from '../../../common/loading-content';
import { AllowedGuard } from '../../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../../icons/refresh';
import GadminActions from '../../../../contexts/gadmin-actions';
import { ProductForm } from '../../../../containers/inventory/product-form';
import { useApp } from '../../../../hooks/use-app';

const ProductResumenPanel2 = () => {
  const { itemcode } = useParams();
  const mounted = useMounted();
  const [editMode, setEditMode] = useState(false);

  const { currentCompany } = useApp();

  const [productState, handleRefresh] = useData({
    sourceApi: goalInventoryApi.getProductListId,
    apiParameter: { companyId: currentCompany, id: itemcode },
    loadingMessage: 'Cargando informacion del producto',
    mounted
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="zl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Resumen 2
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={() => handleRefresh({ companyId: currentCompany, id: itemcode })}
                variant="contained"
              />
            </AllowedGuard>
          </Box>
        </Box>
        <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
          <CardHeader title="Detalle del producto" />
          <Divider />
          {!productState.isLoading && productState.success ? (
            <RenderIf
              condition={editMode}
              no={
                <ProductSumaryDetail
                  itemcode={itemcode}
                  item={productState?.data}
                  setEditMode={setEditMode}
                />
              }
            >
              <ProductForm
                update
                onClose={setEditMode}
                initialValues={productState?.data}
                itemcode={itemcode}
                handleRefresh={() => handleRefresh({ companyId: currentCompany, id: itemcode })}
              />
            </RenderIf>
          ) : (
            <LoadingContent />
          )}
        </Card>
      </Container>
    </Box>
  );
};

export { ProductResumenPanel2 };

import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return `S/ ${numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00')}`;
}

export function fPercent(number) {
  return numeral(number).format('0.00%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fPercent2(number) {
  return numeral(number).format('0.0%');
}

export function fDecimal(number, decimals = 2) {
  // Validate if the number is valid
  if (isNaN(number)) {
    return 'Invalid number';
  }

  // Validar si los decimales son válidos y no negativos
  if (!Number.isInteger(decimals) || decimals < 0) {
    return 'Invalid decimal places';
  }

  // Formatear el número con la cantidad de decimales especificada
  return numeral(number).format(`0,0.${'0'.repeat(decimals)}`);
}

/**
 * fRound: Redondeo estándar (al más cercano)
 * Explicación: Redondea el número al valor más cercano según la cantidad de decimales especificada.
 * Ejemplo: fRound(2.4567, 2) devolverá 2.46.
 */
export function fRound(number, decimals = 2, formatted = true) {
  if (isNaN(number)) {
    return 'Invalid number';
  }

  if (!Number.isInteger(decimals) || decimals < 0) {
    return 'Invalid decimal places';
  }

  const factor = Math.pow(10, decimals);

  if (formatted) {
    return numeral(Math.round(number * factor) / factor).format(`0,0.${'0'.repeat(decimals)}`);
  } else {
    return Math.round(number * factor) / factor;
  }
}

/**
 * fCeil: Redondeo hacia arriba (siempre hacia el infinito)
 * Explicación: Redondea siempre hacia arriba (hacia el infinito), es decir, al número entero más próximo que sea mayor o igual al número original.
 * Ejemplo: fCeil(2.4567, 2) devolverá 2.46
 */
export function fCeil(number, decimals = 2) {
  if (isNaN(number)) {
    return 'Invalid number';
  }

  if (!Number.isInteger(decimals) || decimals < 0) {
    return 'Invalid decimal places';
  }

  const factor = Math.pow(10, decimals);
  return numeral(Math.ceil(number * factor) / factor).format(`0,0.${'0'.repeat(decimals)}`);
}

/**
 * fFloor: Redondeo hacia abajo (siempre hacia cero)
 * Explicación: Redondea siempre hacia abajo (hacia cero), es decir, al número entero más próximo que sea menor o igual al número original.
 * Ejemplo: fFloor(2.4567, 2) devolverá 2.45.
 */
export function fFloor(number, decimals = 2) {
  if (isNaN(number)) {
    return 'Invalid number';
  }

  if (!Number.isInteger(decimals) || decimals < 0) {
    return 'Invalid decimal places';
  }

  const factor = Math.pow(10, decimals);
  return numeral(Math.floor(number * factor) / factor).format(`0,0.${'0'.repeat(decimals)}`);
}

/**
 * fTruncate: Redondeo hacia el valor más cercano a cero
 * Explicación: Trunca el número, eliminando los decimales más allá de la cantidad especificada, sin importar el valor de esos decimales.
 * Ejemplo: fTruncate(2.4567, 2) devolverá 2.45.
 */
export function fTruncate(number, decimals = 2) {
  if (isNaN(number)) {
    return 'Invalid number';
  }

  if (!Number.isInteger(decimals) || decimals < 0) {
    return 'Invalid decimal places';
  }

  const factor = Math.pow(10, decimals);
  return numeral(Math.trunc(number * factor) / factor).format(`0,0.${'0'.repeat(decimals)}`);
}

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { useApp } from '../../hooks/use-app';
import { DotsVertical as DotsVerticalIcon } from '../../icons/dots-vertical';
import { ConfirmationDialog } from '../confirmation-dialog';
// import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import { Eye as EyeIcon } from '../../icons/eye';
import { documentStatusName, documentStatusViews } from '../../api/app-definitions';
import { DocumentDuplicate } from '../../icons/document-duplicate';
import { goalIncidentsApi } from '../../api/goal/incidents/goal-incidents';

export const IncidentsItemMenu = (props) => {
  const { clickedit, clickdelete, items, detail = false, handlerefresh, ...others } = props;
  const navigate = useNavigate();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenDuplicate, setModalOpenDuplicate] = useState(false);
  const { appUrlBase, showNotify, showError } = useApp();

  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [menuActions, setMenuActions] = useState([]);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);

  const handleEdit = () => {
    clickedit();
    handleClose();
  };

  const handleDelete = () => {
    clickdelete();
    handleClose();
  };

  const handleOpenConfirmationDialog = (menuItem) => {
    setCurrentMenuItem(menuItem);
    setConfirmDialogMessage(menuItem.prompt);
    setModalOpen(true);
    handleClose();
  };

  const handleConfirm = async () => {
    if (currentMenuItem.action === 'GadminActions.G2_CONTESTS_PENDING') {
      const response = await goalIncidentsApi.getIncidentsValidate(items._id);
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handlerefresh();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
    if (GadminActions.G2_CONTESTS_CANCEL === currentMenuItem.action) {
      const response = await goalIncidentsApi.getIncidentsCancele(items._id);
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handlerefresh();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
    if (GadminActions.G2_CONTESTS_CLOSE === currentMenuItem.action) {
      const response = await goalIncidentsApi.getIncidentsClose(items._id);
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handlerefresh();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
    if (GadminActions.G2_CONTESTS_ACTIVATE === currentMenuItem.action) {
      const response = await goalIncidentsApi.getIncidentsActivate(items._id);
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handlerefresh();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
    if (currentMenuItem.action === 'GadminActions.G2_CONTESTS_REJECT') {
      const response = await goalIncidentsApi.getIncidentsReject(items._id);
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handlerefresh();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }

    if (currentMenuItem.action === 'GadminActions.G2_CONTESTS_ARCHIVED') {
      const response = await goalIncidentsApi.getIncidentsArchived(items._id);
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handlerefresh();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }

    setModalOpen(false);
  };

  useEffect(() => {
    const actions = [];
    if ([documentStatusViews.VIEW_DRAFT].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_CONTESTS_CANCEL,
        label: 'Cancelar',
        prompt: '¿Está seguro que desea Cancelar?'
      });
    }
    if ([documentStatusViews.VIEW_DRAFT].includes(items.status)) {
      actions.push({
        action: 'GadminActions.G2_CONTESTS_PENDING',
        label: 'Validar',
        prompt: '¿Está seguro que desea Validar?'
      });
    }
    if ([documentStatusViews.VIEW_PENDING].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_CONTESTS_CANCEL,
        label: 'Cancelar',
        prompt: '¿Está seguro que desea Cancelar?'
      });
    }
    if ([documentStatusViews.VIEW_PENDING].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_CONTESTS_ACTIVATE,
        label: 'Activar',
        prompt: '¿Está seguro que desea Activar?'
      });
    }
    if ([documentStatusViews.VIEW_PENDING].includes(items.status)) {
      actions.push({
        action: 'GadminActions.G2_CONTESTS_REJECT',
        label: 'No validar',
        prompt: '¿Está seguro que desea No validar?'
      });
    }
    if ([documentStatusViews.VIEW_ACTIVE].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_CONTESTS_CLOSE,
        label: 'Cerrar',
        prompt: '¿Está seguro que desea Cerrar?'
      });
    }
    if ([documentStatusViews.VIEW_CANCELLED].includes(items.status)) {
      actions.push({
        action: 'GadminActions.G2_CONTESTS_ARCHIVED',
        label: 'Archivar',
        prompt: '¿Está seguro que desea Archivar?'
      });
    }

    setMenuActions(actions);
  }, [items.status]);

  const detailRoute = `${appUrlBase}/commercial/contest/${items._id}`;

  const handleNavigate = () => {
    handleClose();
    navigate(detailRoute);
  };

  const handleDuplicate = () => {
    setModalOpenDuplicate(true);
  };

  const handleConfirmDuplicate = async () => {
    delete items._id;
    delete items._rev;
    delete items.updated_at;
    delete items.created_at;
    delete items.created_by;
    delete items.created_by_user;
    delete items.requestUpdateBy;
    delete items.requestUpdateByUser;
    delete items.requestUpdateDate;

    // items.awards = [];
    // items.quotas = [];
    items.status = documentStatusViews.VIEW_DRAFT;
    items.statusName = documentStatusName.VIEW_DRAFT_NAME;

    const objetoModificado = {
      ...items,
      name: `${items.name} duplicado`
    };

    console.log('items', objetoModificado);
    const response = await goalCustomeContestApi.postContestForm({
      values: objetoModificado
    });
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      setModalOpenDuplicate(false);
      handlerefresh();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  return (
    <>
      <Box onClick={handleOpen} ref={anchorRef} {...others}>
        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {false && (
          <MenuItem key="detail" onClick={handleNavigate}>
            <EyeIcon />
            Detalle
          </MenuItem>
        )}
        {false && (
          <MenuItem key="duplicate" onClick={handleDuplicate}>
            <DocumentDuplicate />
            Duplicar concurso
          </MenuItem>
        )}
        <MenuItem key="edit" onClick={handleEdit}>
          Editar
        </MenuItem>
        {/* {menuActions.map((item, index) => (
          <AllowedGuard permission={item.permission || item.action} key={index}>
            <MenuItem
              key={index}
              onClick={() => {
                handleOpenConfirmationDialog(item);
              }}
            >{`${item.label}`}</MenuItem>
          </AllowedGuard>
        ))} */}
        {menuActions.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleOpenConfirmationDialog(item);
            }}
          >{`${item.label}`}</MenuItem>
        ))}
        {false &&
          items.status === documentStatusViews.VIEW_DRAFT && [
            <MenuItem key="edit" onClick={handleEdit}>
              Editar
            </MenuItem>,
            <MenuItem key="delete" onClick={handleDelete}>
              Eliminar
            </MenuItem>
          ]}
        {menuActions.length === 0 && detail && <MenuItem>No hay acciones</MenuItem>}
      </Menu>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
      <ConfirmationDialog
        message="Deseas duplicar este concurso, ¿Seguro que quieres continuar?"
        onCancel={() => setModalOpenDuplicate(false)}
        onConfirm={handleConfirmDuplicate}
        open={modalOpenDuplicate}
        title="Confirmación importante"
        variant="warning"
      />
    </>
  );
};

IncidentsItemMenu.propTypes = {
  items: PropTypes.object,
  clickedit: PropTypes.func,
  clickdelete: PropTypes.func,
  detail: PropTypes.bool,
  handlerefresh: PropTypes.func
};

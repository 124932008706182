import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { Pencil as PencilIcon } from '../../../icons/pencil';

export const RangeAwardsDetail = ({
  arrayRefresh,
  setArrayRefresh,
  handleValueInitialChangeCard,
  handleRefreshSlider,
  awards,
  handleInputChange,
  valueProrateado,
  handleCheckBoxChange,
  handleRemoveRange,
  handleAddRange,
  generarArrayState
}) => {
  const [editDetail, setEditDetail] = useState(true);
  const [selectIndex, setSelectIndex] = useState(null);

  const handleSelectItem = (index) => {
    setEditDetail(true);
    setSelectIndex(index);
  };

  const handleSaveAwards = () => {
    handleRefreshSlider();
    setEditDetail(false);
  };

  const handleCancelAwards = () => {
    setArrayRefresh(generarArrayState);
    setEditDetail(false);
  };

  return (
    <>
      {arrayRefresh.map((item, index) => (
        <Card
          sx={{
            width: ['100%', 'calc(50% - 1em)', 'calc(30% - 1em)'],
            display: 'flex',
            justifyContent: 'space-between'
          }}
          key={index}
        >
          {editDetail && index === selectIndex ? (
            <>
              <Box>
                <PropertyListItem>
                  <InputField
                    type="number"
                    label="Valor inicial"
                    value={item}
                    onChange={(event) => handleValueInitialChangeCard(event, index)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleRefreshSlider();
                      }
                    }}
                  />
                </PropertyListItem>
                <PropertyListItem>
                  <InputField
                    type="number"
                    label="Premio"
                    value={awards[index] || 0}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                  {awards[index] === 0 && <p style={{ color: 'red' }}>Agregue un premio</p>}
                </PropertyListItem>
                <PropertyListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={valueProrateado[index] || false}
                        onChange={(event) => handleCheckBoxChange(event, index)}
                        color="secondary"
                      />
                    }
                    label="Prorrateado"
                  />
                </PropertyListItem>
                <PropertyListItem>
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<PencilIcon />}
                    variant="contained"
                    onClick={handleSaveAwards}
                  >
                    Guardar
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    startIcon={<TrashIcon />}
                    onClick={handleCancelAwards}
                  >
                    Cancelar
                  </Button>
                </PropertyListItem>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginRight: 2
                }}
              >
                <Button
                  color="primary"
                  size="large"
                  startIcon={<TrashIcon />}
                  variant="contained"
                  onClick={() => handleRemoveRange(index)}
                />
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  onClick={() => handleAddRange(index)}
                  disabled={awards[index] === 0}
                />
              </Box>
            </>
          ) : (
            <>
              <Box>
                <PropertyListItem align="end" label="Valor mínimo">
                  <Typography color="textSecondary" variant="body2">
                    {item}
                  </Typography>
                </PropertyListItem>
                <PropertyListItem align="end" label="Premio">
                  <Typography color="textSecondary" variant="body2">
                    {awards[index] || 0}
                  </Typography>
                </PropertyListItem>
                <PropertyListItem>
                  <FormControlLabel
                    control={
                      <Checkbox checked={valueProrateado[index] || false} color="secondary" />
                    }
                    label="Prorrateado"
                  />
                </PropertyListItem>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  onClick={() => handleSelectItem(index)}
                >
                  Editar
                </Button>
              </Box>
              {/* <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginRight: 2
                }}
              >
                <Box />
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  onClick={() => handleSelectItem(index)}
                >
                  Editar
                </Button>
              </Box> */}
            </>
          )}
        </Card>
      ))}
    </>
  );
};

RangeAwardsDetail.propTypes = {
  arrayRefresh: PropTypes.array,
  generarArrayState: PropTypes.array,
  setArrayRefresh: PropTypes.func,
  handleValueInitialChangeCard: PropTypes.func,
  handleRefreshSlider: PropTypes.func,
  awards: PropTypes.array,
  handleInputChange: PropTypes.func,
  valueProrateado: PropTypes.array,
  handleCheckBoxChange: PropTypes.func,
  handleRemoveRange: PropTypes.func,
  handleAddRange: PropTypes.func
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from '@mui/material';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import ContestQuotaForm from './contest-quota-form';
import { useApp } from '../../hooks/use-app';

export const ContestQuota = ({ contestIdList = {}, handleRefreshDetail }) => {
  const { currentCompany } = useApp();
  const [quotaByList, setQuataBy] = useState([]);
  const [employeePosition, setEmployeePosition] = useState([]);

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [employeePositionList, quotaByList] = await Promise.all([
      goalCustomeContestApi.getEmployeePositions({ companyId: currentCompany }),
      goalCustomeContestApi.getQuotaBy()
    ]);
    if (employeePositionList.success && employeePositionList.data.length !== 0) {
      setEmployeePosition(employeePositionList.data);
    }
    if (quotaByList.success && quotaByList.data.length !== 0) {
      setQuataBy(quotaByList.data);
    }
  };

  return (
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <ContestQuotaForm
        title=""
        contestState={contestIdList}
        employeePositionList={employeePosition}
        quotaByList={quotaByList}
        listQuota={contestIdList.quotas}
        handleRefreshDetail={handleRefreshDetail}
      />
      <Divider />
    </Card>
  );
};
ContestQuota.propTypes = {
  contestIdList: PropTypes.object,
  handleRefreshDetail: PropTypes.func
};

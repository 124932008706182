import { Grid, IconButton, Input } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { XCircle } from '../icons/x-circle';

const TableSearchBar = (props) => {
  const { lines = [], setLines = () => {}, searchFields } = props;
  const [search, setSearch] = useState('');

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (search && search.length > 0) {
      const filtered = lines.filter((item) => {
        let found = false;
        searchFields.forEach((field) => {
          if (item[field]?.toLowerCase().includes(search.toLowerCase())) {
            found = true;
          }
        });
        return found;
      });
      setLines?.(filtered);
    } else {
      setLines?.(lines);
    }
  }, [search]);
  return (
    <Grid
      item
      xs={12}
      sm={12}
      sx={{ display: 'flex', alignItems: 'center', marginTop: 3, paddingLeft: 3, paddingRight: 5 }}
    >
      <Input
        fullWidth
        onChange={handleSearch}
        placeholder="Buscar en tabla"
        value={search}
        variant="outlined"
        sx={{
          paddingBottom: 2,
          paddingTop: 2,
          paddingLeft: 3,
          paddingRight: 3
        }}
      />
      <IconButton onClick={() => setSearch('')} color="secondary" aria-label="edit">
        <XCircle color="primary" />
      </IconButton>
    </Grid>
  );
};

TableSearchBar.propTypes = {
  lines: PropTypes.array,
  setLines: PropTypes.func,
  searchFields: PropTypes.array.isRequired
};

export { TableSearchBar };

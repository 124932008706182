import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';

export const ReturnsReasonTable = (props) => {
  const { reasons } = props;

  if (!reasons.success) {
    return (
      <LoadingContent
        style={{ padding: '20px' }}
        loadingText={reasons.message}
        error={reasons.message}
      />
    );
  }

  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reasons?.map((item) => (
              <TableRow key={item.codmotdev}>
                <TableCell>{numeral(item.codmotdev).format('00')}</TableCell>
                <TableCell>{numeral(item.codmotdev).format('00')}</TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.nombremotivodev}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.estado}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

ReturnsReasonTable.propTypes = {
  reasons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

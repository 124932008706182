import PropTypes from 'prop-types';
import { Box, Button, Card, Checkbox, FormControlLabel } from '@mui/material';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';

export const RangeAwardsEdit = ({
  arrayRefresh,
  handleValueInitialChangeCard,
  handleRefreshSlider,
  awards,
  handleInputChange,
  valueProrateado,
  handleCheckBoxChange,
  handleRemoveRange,
  handleAddRange
}) => (
  <>
    {arrayRefresh.map((item, index) => (
      <Card
        sx={{
          width: ['100%', 'calc(50% - 1em)', 'calc(30% - 1em)'],
          display: 'flex',
          justifyContent: 'space-between'
        }}
        key={index}
      >
        <Box>
          <PropertyListItem>
            <InputField
              type="number"
              label="Valor inicial"
              value={item}
              onChange={(event) => handleValueInitialChangeCard(event, index)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleRefreshSlider();
                }
              }}
            />
          </PropertyListItem>
          <PropertyListItem>
            <InputField
              type="number"
              label="Premio"
              value={awards[index] || 0}
              onChange={(event) => handleInputChange(event, index)}
            />
            {awards[index] === 0 && <p style={{ color: 'red' }}>Agregue un premio</p>}
          </PropertyListItem>
          <PropertyListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={valueProrateado[index] || false}
                  onChange={(event) => handleCheckBoxChange(event, index)}
                  color="secondary"
                />
              }
              label="Prorrateado"
            />
          </PropertyListItem>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: 2
          }}
        >
          <Button
            color="primary"
            size="large"
            startIcon={<TrashIcon />}
            variant="contained"
            onClick={() => handleRemoveRange(index)}
          />
          <Button
            color="primary"
            size="large"
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={() => handleAddRange(index)}
            disabled={awards[index] === 0}
          />
        </Box>
      </Card>
    ))}
  </>
);

RangeAwardsEdit.propTypes = {
  arrayRefresh: PropTypes.array,
  handleValueInitialChangeCard: PropTypes.func,
  handleRefreshSlider: PropTypes.func,
  awards: PropTypes.array,
  handleInputChange: PropTypes.func,
  valueProrateado: PropTypes.array,
  handleCheckBoxChange: PropTypes.func,
  handleRemoveRange: PropTypes.func,
  handleAddRange: PropTypes.func
};

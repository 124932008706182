import PropTypes from 'prop-types';
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Scrollbar from '../Scrollbar';

export const ContestQuotaSeller = ({ quotas = [] }) => {
  return (
    <Card sx={{ marginBottom: 4 }}>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>ID Vendedor</TableCell>
              <TableCell>COD. Vendedor</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Puesto</TableCell>
              <TableCell>Cuota</TableCell>
              <TableCell>Progreso</TableCell>
              <TableCell>Faltante</TableCell>
              <TableCell>Zona</TableCell>
              <TableCell>Mesa</TableCell>
              <TableCell>Usuario</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotas?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.seller_id}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.position}</TableCell>
                <TableCell>{item.quota}</TableCell>
                <TableCell> {item.progress}</TableCell>
                <TableCell>{item.remaining} </TableCell>
                <TableCell>{item.territory} </TableCell>
                <TableCell>{item.team} </TableCell>
                <TableCell>{item.teamid} </TableCell>
                <TableCell>{item.user} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

ContestQuotaSeller.propTypes = {
  quotas: PropTypes.array
};

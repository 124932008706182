import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as PlusIcon } from '../../../icons/refresh';
import { Chip as ChipIcon } from '../../../icons/chip';
import GadminActions from '../../../contexts/gadmin-actions';

import { LinkIf } from '../../link-if';
import { NumberColor } from '../../number-color';
import { RenderIf } from '../../render-if';

export const PendingsFaceleTable = ({
  data: dataProp,
  onRefresh,
  label = 'Documentos pendientes',
  onFix,
  linkTo
}) => {
  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });

  const handleRefresh = () => {
    onRefresh?.();
  };

  const handleFix = () => {
    onFix?.();
  };

  useEffect(() => {
    setDocumentState(dataProp);
  }, [dataProp]);

  if (!documentState.data) {
    return <LoadingContent loadingText="No hay documentos pendientes a FACELE..." />;
  }
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title={label} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
              <Box sx={{ mb: 2 }}>
                <RenderIf condition={!!onFix}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<ChipIcon />}
                    onClick={handleFix}
                    variant="contained"
                  />
                </RenderIf>

                <Button
                  color="primary"
                  size="medium"
                  startIcon={<PlusIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell>Fecha</TableCell>
              <TableCell>Factura</TableCell>
              <TableCell>Boletas</TableCell>
              <TableCell>NC Factura</TableCell>
              <TableCell>NC Boleta</TableCell>
              <TableCell>N Débito</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState.data?.map((item) => {
              const baseUrl = `${linkTo}?date=${moment(item.docdate).format('YYYY-MM-DD')}`;
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${baseUrl}`}
                      underline="hover"
                      variant="subtitle2"
                    >
                      {moment(item.docdate).format('DD/MM/YYYY')}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <LinkIf condition={item.Facturas != 0} to={`${baseUrl}&type=01`}>
                      <NumberColor value={item.Facturas} format="0" upper />
                    </LinkIf>
                  </TableCell>
                  <TableCell>
                    <LinkIf condition={item.Boletas != 0} to={`${baseUrl}&type=03`}>
                      <NumberColor value={item.Boletas} format="0" upper />
                    </LinkIf>
                  </TableCell>
                  <TableCell>
                    <LinkIf condition={item.NCFacturas != 0} to={`${baseUrl}&type=07&subtype=01`}>
                      <NumberColor value={item.NCFacturas} format="0" upper />
                    </LinkIf>
                  </TableCell>
                  <TableCell>
                    <LinkIf condition={item.NCBoletas !== 0} to={`${baseUrl}&type=07&subtype=03`}>
                      <NumberColor value={item.NCBoletas} format="0" upper />
                    </LinkIf>
                  </TableCell>
                  <TableCell>{item.NDebito}</TableCell>
                  <TableCell>{item.Total}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

PendingsFaceleTable.propTypes = {
  data: Proptypes.object,
  onRefresh: Proptypes.func,
  onFix: Proptypes.func,
  label: Proptypes.string,
  linkTo: Proptypes.string
};

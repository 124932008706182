import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography } from '@mui/material';
import { customerReturnsApi } from '../../api/customer-return';
// import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import LoadingContent from '../../components/common/loading-content';
import { ReturnsReasonTable } from '../../components/document/returns/returns-reason-table';

export const CustomerReturnsReasons = () => {
  const mounted = useMounted();
  const { title } = useApp();
  const [customerReturnsReasonsState, setCustomerReturnsReasonsState] = useState({
    isLoading: true
  });

  const getPromoAssigned = useCallback(async () => {
    setCustomerReturnsReasonsState(() => ({ isLoading: true }));
    try {
      const result = await customerReturnsApi.getReturnsReasons();
      if (mounted.current) {
        setCustomerReturnsReasonsState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setCustomerReturnsReasonsState(() => ({
          isLoading: false,
          error: err.message ? err.message : 'Error del servidor'
        }));
      }
    }
  }, []);

  useEffect(() => {
    getPromoAssigned().catch(console.error);
  }, []);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => {
    if (customerReturnsReasonsState.isLoading) {
      return <LoadingContent loadingText="Cargando motivos" />;
    }

    if (customerReturnsReasonsState.error || !customerReturnsReasonsState.data) {
      return <LoadingContent loadingText={customerReturnsReasonsState.error} />;
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              #{customerReturnsReasonsState.data?.document}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            />
          </Box>
          {/* <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={customerReturnsReasonsState.data?.statusName} />
          </Box> */}
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <ReturnsReasonTable reasons={customerReturnsReasonsState.data} />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de comprobante')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

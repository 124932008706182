import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';

import { customeOrderApi } from '../../../api/customer-order';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { CustomerOrderssTable } from '../../../components/document/orders/customer-orders-table';
import { CustomerOrdersCanceledFilter } from '../../../components/document/orders/customer-orders-canceled-filter';
import LoadingContent from '../../../components/common/loading-content';

export const CustomerOrdersAuthorized = () => {
  const mounted = useMounted();
  const app = useApp();
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    query: '',
    sort: 'desc',
    sortBy: 'createdAt',
    view: 'all',
    reload: true,
    refresh: true
  });
  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });
  const [selectedDocuments, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    documentState?.documents
  );

  const handleRefresh = () => {
    setController({
      ...controller,
      filters: [],
      page: 0,
      query: '',
      sort: 'desc',
      sortBy: 'createdAt',
      view: 'all',
      reload: true,
      refresh: true
    });
  };

  const getDocuments = useCallback(async () => {
    if (controller.refresh) {
      setDocumentState(() => ({
        ...documentState,
        isLoading: true,
        message: 'Cargando pedidos aulados'
      }));

      try {
        const result = await customeOrderApi.getCustomeOrdersCanceled({
          filters: controller.filters,
          page: controller.page,
          query: controller.query,
          sort: controller.sort,
          sortBy: controller.sortBy,
          view: controller.view,
          reload: controller.reload
        });
        if (mounted.current) {
          setDocumentState(() => ({
            ...result,
            isLoading: false,
            reload: false
          }));
          handleClearSelected();
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setDocumentState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      if (mounted.current) {
        setController(() => ({
          ...controller,
          refresh: false
        }));
      }
    }
  }, [controller]);

  useEffect(() => {
    getDocuments().catch(console.error);
  }, [controller]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleViewChange = (newView) => {
    setController({
      ...controller,
      page: 0,
      view: newView,
      refresh: true
    });
  };

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 0,
      query: newQuery,
      refresh: true
    });
  };

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value
    }));
    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      refresh: true
    });
  };

  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      refresh: true
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
      refresh: true
    });
  };

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
      refresh: true
    });
  };

  const renderContent = () => {
    if (documentState.isLoading || documentState.error) {
      return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Pedidos Anulados
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.gCC_BROWSE_VISITA_DIA}>
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              />
            </AllowedGuard>
          </Box>
        </Box>
        {/* <CustomerOrdersStats /> */}
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}
          variant="outlined"
        >
          <CustomerOrdersCanceledFilter
            disabled={documentState.isLoading}
            filters={controller.filters}
            onFiltersApply={handleFiltersApply}
            onFiltersClear={handleFiltersClear}
            onQueryChange={handleQueryChange}
            onViewChange={handleViewChange}
            query={controller.query}
            selectedDocuments={selectedDocuments}
            view={controller.view}
            enableSearch={false}
          />
          <Divider />
          <CustomerOrderssTable
            error={documentState.error}
            documents={documentState?.documents}
            documentsCount={documentState?.documentsCount}
            isLoading={documentState.isLoading}
            onPageChange={handlePageChange}
            onSelect={handleSelect}
            onSelectAll={handleSelectAll}
            onSortChange={handleSortChange}
            page={controller.page + 1}
            selectedDocuments={selectedDocuments}
            sort={controller.sort}
            sortBy={controller.sortBy}
          />
        </Card>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{app.title('Pedidos del cliente')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

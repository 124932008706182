import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Button, Box, Typography, Grid } from '@mui/material';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import { invoiceApi } from '../../../api/invoice';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../../components/common/loading-content';
import JournalReceipt from '../../../components/purchases/printing/JournalReceipt';
import { useApp } from '../../../hooks/use-app';

export const JournalReceiptPdf = () => {
  const { id } = useParams();

  const mounted = useMounted();
  const { appUrlBase } = useApp();

  const [journalState, setJournalState] = useState({ isLoading: true });

  const getJournal = useCallback(async () => {
    setJournalState(() => ({ isLoading: true }));
    try {
      const result = await invoiceApi.getJournalData({ transId: id });

      if (mounted.current) {
        setJournalState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setJournalState(() => ({
          isLoading: false,
          // error: err.message
          error: err.message ? err.message : 'Error del servidor'
        }));
      }
    }
  }, []);

  useEffect(() => {
    getJournal().catch(console.error);
  }, []);
  const styles = StyleSheet.create({
    // General Styles
    page: {
      width: '100vw',
      height: '55vh',
      border: 'none',
      textAlign: 'center',
      borderRadius: '8px'
    }
  });
  if (journalState.error || !journalState.data) {
    return <LoadingContent loadingText="Cargando reporte" />;
  }
  const journalPdf = !journalState.data ? '' : <JournalReceipt journal={journalState.data} />;
  const body = !journalState.data ? (
    'Sin contenido'
  ) : (
    <>
      <PDFViewer style={styles.page} className="app">
        {journalPdf}
      </PDFViewer>
      {/* <PDFDownloadLink document={journalPdf} fileName="invoice" style={{ textDecoration: 'none' }}>
        <Button color="primary" size="large" variant="contained">
          Descargar
        </Button>
      </PDFDownloadLink> */}
    </>
  );

  return (
    <>
      <Box sx={{ py: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Button
            color="primary"
            component={RouterLink}
            startIcon={<ArrowLeftIcon />}
            to={`${appUrlBase}/purchases`}
            variant="text"
          >
            comprobante
          </Button>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Typography color="textPrimary" variant="h4">
            Asiento #{id}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid
          container
          item
          lg={12}
          spacing={3}
          sx={{
            height: 'fit-content',
            order: {
              md: 2,
              xs: 1
            }
          }}
          xs={12}
          xl={12}
        >
          <Grid item xs={12}>
            {body}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default JournalReceiptPdf;

import { CircularProgress, ListItemIcon, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { GoalListIcon } from '../icon/goal-list-icon';
import { useState } from 'react';
import { RemoveRedEye } from '@mui/icons-material';

export const GoalMenuDialog = (props) => {
  const { children, icon = <RemoveRedEye />, controller, component } = props;

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    controller.openDialog();
  };

  return (
    <>
      {loading ? (
        <MenuItem disabled>
          <ListItemIcon>
            <CircularProgress />
          </ListItemIcon>
          Cargando...
        </MenuItem>
      ) : (
        <MenuItem onClick={handleClick}>
          <GoalListIcon icon={icon} />

          {children}
        </MenuItem>
      )}
      {component}
    </>
  );
};

GoalMenuDialog.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
  onClose: PropTypes.func,
  controller: PropTypes.object.isRequired,
  component: PropTypes.node
};

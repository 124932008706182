import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 16
  },
  billTo: {
    marginTop: 2,
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique'
  }
});

const JournalHeader = ({ journal }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>
      Fecha de contabilización : {moment(journal.refDate).format('DD/MM/YYYY')}
    </Text>
    <Text>Referencia 1: {journal.ref1}</Text>
    <Text>Referencia 2: {journal.ref2}</Text>
    <Text>Informacion detallada:</Text>
    <Text>{journal.memo}</Text>
    <Text>{journal.numAtCard}</Text>
  </View>
);

JournalHeader.propTypes = {
  journal: PropTypes.object
};
export default JournalHeader;

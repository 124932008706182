import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'flex-end'
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold'
  },
  label: {
    width: 60
  }
});
const JournalNumber = ({ journal }) => (
  <>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Trans.:</Text>
      <Text style={styles.invoiceDate}>{journal.transId}</Text>
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Asiento No:</Text>
      <Text style={styles.invoiceDate}>{journal.number}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Fecha: </Text>
      <Text>{moment(journal.currentDate).format('DD/MM/YYYY')}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Hora: </Text>
      <Text>{moment(journal.currentDate).format('HH:mm:ss')}</Text>
    </View>
  </>
);

JournalNumber.propTypes = {
  journal: PropTypes.object
};
export default JournalNumber;

import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { customeOrderApi } from '../../../api/customer-order';
// import { OrderInfoPayment } from '../components/orderInfos/orderInfo-payment';
// import { OrderInfoPaymentHistory } from '../components/orderInfos/orderInfo-payment-history';
// import { OrderInfoSendNotification } from '../components/orderInfos/orderInfo-send-notification';
import { Status } from '../../../components/status';
import { useMounted } from '../../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../../icons/exclamation-outlined';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
// import { OrderInfoEdit } from '../../../components/purchases/orderInfos/orderInfo-edit';
// import { InvoicReference } from '../../../components/purchases/orderInfos/orderInfo-reference';
// import { OrderInfoLog } from '../../../components/promo/assigned/assigned-log';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { RenderIf } from '../../../components/render-if';
import LoadingContent from '../../../components/common/loading-content';
import { DocumentLog } from '../../../components/purchases/common/document-log';
import { CustomerOrderDetails } from '../../../components/document/orders/customer-order-details';
import { CustomerOrderLines } from '../../../components/document/orders/customer-order-lines';

export const CustomerOrder = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();
  const [orderInfoState, setOrderInfoState] = useState({ isLoading: true });
  const [editMode, setEditmode] = useState(false);

  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!orderInfoState.data) return;
    if (['D'].includes(orderInfoState.data.status)) {
      setEditmode(true);
    }
  };
  const getOrderInfo = useCallback(async () => {
    setOrderInfoState(() => ({ isLoading: true }));
    try {
      const result = await customeOrderApi.getOrderDetail({ id });
      if (mounted.current) {
        setOrderInfoState(() => ({
          isLoading: false,
          data: result,
          hasPromo: !!result?.promo
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setOrderInfoState(() => ({
          isLoading: false,
          error: err.message ? err.message : 'Error del servidor'
        }));
      }
    }
  }, []);

  useEffect(() => {
    getOrderInfo().catch(console.error);
  }, []);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => {
    if (orderInfoState.isLoading) {
      return <LoadingContent loadingText="Cargando documento" />;
    }

    if (orderInfoState.error || !orderInfoState.data) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              {orderInfoState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to={`${appUrlBase}/sales/orders/`}
              variant="text"
            >
              Pedidos
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              # {orderInfoState.data?.IdPedido}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={orderInfoState.data?.statusName} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <CustomerOrderDetails orderInfo={orderInfoState.data} setEditMode={handleEdit} />
            </Grid>
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={['F', 'G', 'D'].includes(orderInfoState.data.EstadoMigrado)}>
                <Grid item xs={12}>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${appUrlBase}/${'promo/assigneds/'}${orderInfoState.data.IdPedido}`}
                    underline="none"
                    variant="subtitle2"
                  >
                    Ver detalle de promociones
                  </Link>
                </Grid>
              </RenderIf>
            </AllowedGuard>
            <RenderIf condition={!!orderInfoState.data.lines}>
              <Grid item xs={12}>
                <CustomerOrderLines orderInfo={orderInfoState.data} />
              </Grid>
            </RenderIf>
            <Grid item xs={12}>
              <DocumentLog document={{ objType: 124, docEntry: orderInfoState.data.IdPedido }} />
            </Grid>
            {/* <Grid item xs={12}>
              <InvoicReference orderInfo={orderInfoState.data} />
            </Grid> */}
            <Grid item xs={12}>
              {/* <OrderInfoPayment orderInfo={orderInfoState.data} /> */}
            </Grid>
            {/* <RenderIf condition={orderInfoState.data.lines}>
              <Grid item xs={12}>
                <OrderInfoOrderPreviewTable orderInfo={orderInfoState.data} />
              </Grid>
            </RenderIf> */}

            {/* 
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={orderInfoState.hasPromo}>
                <Grid item xs={12}>
                  <OrderInfoEvals orderInfo={orderInfoState.data.promo} />
                </Grid>
              </RenderIf>
            </AllowedGuard>
            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={orderInfoState.hasPromo}>
                <Grid item xs={12}>
                  <OrderInfoTiming orderInfo={orderInfoState.data.promo} />
                </Grid>
              </RenderIf>
            </AllowedGuard> */}
          </Grid>
          {/* <Grid
            container
            item
            lg={8}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <OrderInfoLog orderInfo={orderInfoState.data} />
            </Grid>
          </Grid> */}
          <Grid
            container
            item
            lg={4}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
          >
            <Grid item xs={12}>
              {/* <OrderInfoSendNotification orderInfo={orderInfoState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <OrderInfoPaymentHistory /> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de pedido del cliente')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

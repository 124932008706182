import PropTypes from 'prop-types';
import { Skeleton, Box } from '@mui/material';

import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';

export const LoadingTable = ({ documentState = {} }) => {
  const { displayLoading, displayError, displayUnavailable } = documentState;

  return (
    <>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={documentState.error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
    </>
  );
};

// prop type validation
LoadingTable.propTypes = {
  documentState: PropTypes.object
};

import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Typography } from '@mui/material';
import { goalCustomeContestApi } from '../../api/goal/contest/goal-customer-contest';
import LoadingContent from '../common/loading-content';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { ContestAwards } from './contest-awards';

export const ContestContainerAwards = () => {
  const { id } = useParams();

  const mounted = useMounted();
  const [detailAwardsState, handleRefreshDetail] = useData({
    sourceApi: goalCustomeContestApi.getContestIdList,
    apiParameter: id,
    loadingMessage: 'Cargando informacion del concurso',
    mounted
  });

  if (detailAwardsState.isLoading || detailAwardsState.error) {
    return (
      <LoadingContent loadingText={detailAwardsState.message} error={detailAwardsState.error} />
    );
  }
  if (!detailAwardsState.success) {
    return (
      <LoadingContent loadingText={detailAwardsState.message} error={detailAwardsState.message} />
    );
  }
  if (detailAwardsState.data === null || detailAwardsState.data.length === 0) {
    return <LoadingContent loadingText="No hay datos" error={detailAwardsState.error} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1,
        marginBottom: 3
      }}
    >
      <Container
        maxWidth="zl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Premios del concurso
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </Box>
        <ContestAwards
          contestIdList={detailAwardsState?.data}
          handleRefreshDetail={() => handleRefreshDetail(id)}
        />
        <Divider />
      </Container>
    </Box>
  );
};

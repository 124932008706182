export const capitalizeParagraph = (paragraph) => {
  if (paragraph === null || paragraph === undefined) {
    return '';
  }

  // Split the paragraph into words
  const words = paragraph.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      // If the word is a number, don't capitalize it
      if (!isNaN(word)) {
        return word;
      }

      // if word has number and letter,  capitalize the first letter after a number
      if (word.match(/\d/) && word.match(/[a-z]/i)) {
        // capitalize the first letter after a number
        const index = word.match(/\d/).index;
        return (
          word.substring(0, index + 1) + word[index + 1]?.toUpperCase() + word.substring(index + 2)
        );
      }

      return word[0].toUpperCase() + word.substring(1);
    } else {
      return word;
    }
  });

  // Join the words back into a paragraph
  return capitalizedWords.join(' ');
};

export const useSplit = (string, separator = '-') => {
  if (!string) return { code: '', sufix: '' };
  const codes = string.split(separator);
  return { code: codes[0], sufix: codes[1] ? codes[1] : '' };
};

import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';
import JournalTableHeader from './journal-table-header';
import JournalTableRow from './journal-table-row';
// import JournalTableBlankSpace from './journal-table-blank-space';
import JournalTableFooter from './journal-table-footer';

// const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd'
  }
});

const JournalLinesTable = ({ journal }) => (
  <View style={styles.tableContainer}>
    <JournalTableHeader />
    <JournalTableRow lines={journal.lines} />
    {/* <JournalTableBlankSpace rowsCount={tableRowsCount - journal.lines.length} /> */}
    <JournalTableFooter lines={journal.lines} />
  </View>
);

JournalLinesTable.propTypes = {
  journal: PropTypes.object
};
export default JournalLinesTable;

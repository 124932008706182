import Proptypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box
} from '@mui/material';

import moment from 'moment';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { Download as DownloadIcon } from '../../../icons/download';
import GadminActions from '../../../contexts/gadmin-actions';
import { useDialog } from '../../../hooks/use-dialog';

import { NumberColor } from '../../number-color';

import { DocumetLogDialog } from '../../common/document-log-dialog';
import { RenderIf } from '../../render-if';
import { appFormats } from '../../../api/app-definitions';
import { YesNo } from '../../yes-no';
import GoalRecordLink from '../../goal-record-link';

export const GREPendingsFACELETable = (props) => {
  const { data: dataProp, onRefresh, label = 'Documentos pendientes', onDownload } = props;

  // eslint-disable-next-line no-unused-vars
  const [currentLine, setCurrentLine] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const handleExitedDialog = () => {};

  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });

  const handleRefresh = () => {
    onRefresh?.();
  };

  useEffect(() => {
    setDocumentState(dataProp);
  }, [dataProp]);

  if (!documentState.data) {
    return (
      <LoadingContent
        loadingText={documentState.isLoading ? `Cargando ${label}` : `No hay ${label}`}
      />
    );
  }
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title={label} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
                <RenderIf condition={!!onDownload}>
                  <Button
                    onClick={onDownload}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                  />
                </RenderIf>
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Numero SAP</TableCell>
              <TableCell>Comprobante</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Nombre de Cliente</TableCell>
              <TableCell>GRE</TableCell>
              <TableCell>Importe</TableCell>
              <TableCell>Enviado</TableCell>
              <TableCell>Confirmado</TableCell>
              <TableCell>Respuesta</TableCell>
              <TableCell>Comentarios</TableCell>
              <TableCell>Dirección</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState.data?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.docnum}</TableCell>
                <TableCell>
                  <GoalRecordLink
                    to={`/sales/receipts/${item.receipt_code}`}
                    label={item.receipt_code}
                    title={'Ver  detalles por comprobante'}
                  />
                </TableCell>
                <TableCell>{moment(item.docdate).format(appFormats.frontDateFormat)}</TableCell>

                <TableCell>{item.cardcode}</TableCell>
                <TableCell>{item.cardname}</TableCell>
                <TableCell>
                  {item.serie}-{item.number}
                </TableCell>
                <TableCell>
                  <NumberColor value={item.doctotal} format="0,000.00" minValue={5000} />
                </TableCell>
                {/* <TableCell>
                  <PendingsMenu
                    invoice={item}
                    detailPath={`${appUrlBase}/sales/receipts`}
                    apiHandler={documentApi}
                    containerView={view}
                    afterProcessHandler={onRefresh}
                  />
                </TableCell> */}
                <TableCell>
                  <YesNo value={item.transfered} />
                </TableCell>
                <TableCell>
                  <YesNo value={item.confirmed} />
                </TableCell>
                <TableCell>{item.responseMsg}</TableCell>
                <TableCell>{item.comments}</TableCell>
                <TableCell>{item.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DocumetLogDialog
          onClose={handleCloseEditDialog}
          onExited={handleExitedDialog}
          open={editDialogOpen}
          objType={currentLine.objtype || 0}
          docEntry={currentLine.docentry || 0}
        />
      </Scrollbar>
    </Card>
  );
};

GREPendingsFACELETable.propTypes = {
  data: Proptypes.object,
  onRefresh: Proptypes.func,
  onDownload: Proptypes.func,
  label: Proptypes.string
};

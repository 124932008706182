import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest, GadminFileUpload } from '../services/gadmin';
import { invoiceActions } from './purchase-definitions';
import { dataLoader } from './data-loader';

/* eslint class-methods-use-this:0 */

class ReceiptFeeApi {
  async getInvoices(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.G2_PURCHASE_RRHH_LIST,
      cacheItem: 'receipts-fees',
      apiParams: {
        type: invoiceActions.RECEIPT_FEES_LIST,
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'number', name: 'docEntry' },
        { type: 'string', name: 'document' }
      ],
      ...options
    });
  }

  async getInvoiceStatics() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, { type: 'static' });
    const { data } = response;
    return Promise.resolve(data);
  }

  async getInvoice({ objtype, docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: invoiceActions.RECEIPT_FEES_DETAIL,
      payload: { objtype, docEntry }
    });
    return response;
  }

  async uploadInvoice(files) {
    const response = await GadminFileUpload(
      GadminActions.G2_PURCHASE_RRHH_UP,
      {
        type: invoiceActions.RECEIPT_FEES_UPLOAD,
        payload: files,
        ...files
      },
      invoiceActions.RECEIPT_FEES_UPLOAD
    );
    return response;
  }

  // async uploadInvoice(files) {
  //   const response = await GadminFileUpload(GadminActions.G2_PURCHASE_RRHH_UP, {
  //     type: 'load',
  //     payload: files,
  //     ...files
  //   });
  //   return Promise.resolve(response.data);
  // }

  async updateInvoice({ id, changes }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_UP, {
      type: invoiceActions.RECEIPT_FEES_SAVE_HEADER,
      payload: { id, changes }
    });
    return response;
  }

  async processInvoiceBulk(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_UP, {
      type: invoiceActions.RECEIPT_FEES_PROCESS_BULK,
      payload
    });
    return response;
  }

  async processInvoice(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_UP, {
      type: invoiceActions.RECEIPT_FEES_PROCESS_BULK,
      payload
    });
    return response;
  }

  async getInvoiceMedia({ type, docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'media',
      payload: { type, docEntry }
    });
    return response;
  }

  async checkCPE({ docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_UP, {
      type: invoiceActions.INVOICE_CPE_CHECK,
      payload: { docEntry }
    });
    return response;
  }

  async getInvoiceJournal({ docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'journal',
      payload: { docEntry }
    });
    return response;
  }

  async getJournalData({ transId }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'journal-data',
      payload: { transId }
    });
    return Promise.resolve(response.data);
  }

  async getOcrCodes() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'ocr-codes'
    });
    return response;
  }

  async getSedesConfig() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'sedes-config'
    });
    return response;
  }

  async getPaymentTerms() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'payment-terms'
    });
    return response;
  }

  async getAccountCodes() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'account-list'
    });
    return response;
  }

  async getStatMinDate() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_RRHH_LIST, {
      type: 'stats-min-date'
    });
    return Promise.resolve(response.data);
  }
}

export const receiptFeeApi = new ReceiptFeeApi();

import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { financialReportApi } from '../../../api/financial-reports';
import { Status } from '../../../components/status';
import { useMounted } from '../../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
// import { AllowedGuard } from '../../../hooks/use-allowed';
// import GadminActions from '../../../contexts/gadmin-actions';
import { RenderIf } from '../../../components/render-if';
import LoadingContent from '../../../components/common/loading-content';
import { useDownloadFile } from '../../../hooks/use-download-file';
import { JournalHeader } from '../../../components/financial/journal/journal-header';
import { JournalLines } from '../../../components/financial/journal/journal-lines';
import { Eye as EyeIcon } from '../../../icons/eye';
import { useDialog } from '../../../hooks/use-dialog';
import { JournalPrintingDialog } from '../../../components/purchases/printing/journal-printing-dialog';

export const Journal = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title } = useApp();
  const [receiptInfoState, setReceiptInfoState] = useState({ isLoading: true });
  const [
    journalPrintingDialogOpen,
    handleOpenJournalPrintingDialog,
    handleCloseJournalPrintingDialog
  ] = useDialog();

  const getReceiptInfo = useCallback(async () => {
    setReceiptInfoState(() => ({ isLoading: true, message: 'Cargando asiento' }));
    try {
      const result = await financialReportApi.journalDetail(id);
      if (mounted.current) {
        setReceiptInfoState(() => ({
          ...result,
          isLoading: false
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setReceiptInfoState(() => ({
          isLoading: false,
          error: err.message ? err.message : 'Error del servidor'
        }));
      }
    }
  }, []);

  useEffect(() => {
    getReceiptInfo().catch(console.error);
  }, []);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const navigate = useNavigate();

  const { download } = useDownloadFile({
    apiDefinition: financialReportApi.journalDetailXls,
    apiParameters: {
      transid: id
    }
  });

  const renderContent = () => {
    if (receiptInfoState.isLoading || receiptInfoState.error) {
      return (
        <LoadingContent loadingText={receiptInfoState.message} error={receiptInfoState.error} />
      );
    }
    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component="button"
              startIcon={<ArrowLeftIcon />}
              onClick={() => navigate(-1)}
              variant="text"
            >
              Regresar
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              # SAP {receiptInfoState.data?.SeriesName}-{receiptInfoState.data?.DocNum}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="primary"
                // component={RouterLink}
                size="large"
                startIcon={<EyeIcon />}
                onClick={handleOpenJournalPrintingDialog}
                variant="contained"
              >
                Asiento
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={receiptInfoState.data?.memo} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <JournalHeader journalInfo={receiptInfoState.data} />
            </Grid>
            {/* <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={['F', 'G', 'D'].includes(receiptInfoState.data.EstadoMigrado)}>
                <Grid item xs={12}>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${appUrlBase}/${'promo/assigneds/'}${receiptInfoState.data.IdPedido}`}
                    underline="none"
                    variant="subtitle2"
                  >
                    Ver detalle de promociones
                  </Link>
                </Grid>
              </RenderIf>
            </AllowedGuard> */}
            <RenderIf condition={!!receiptInfoState.data}>
              <Grid item xs={12}>
                <JournalLines journalInfo={receiptInfoState.data} onDownload={download} />
              </Grid>
            </RenderIf>
            {/* <Grid item xs={12}>
              <DocumentLog document={{ objType: 124, docEntry: receiptInfoState.data.IdPedido }} />
            </Grid> */}
          </Grid>
        </Grid>
        <JournalPrintingDialog
          onClose={handleCloseJournalPrintingDialog}
          open={journalPrintingDialogOpen}
          entity={{
            objType: receiptInfoState.data?.transType,
            docEntry: receiptInfoState.data?.createdBy,
            transId: id
          }}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Asiento Diario')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

import Proptypes from 'prop-types';
import { Box, Button, DialogActions, DialogTitle, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
// import { InputField } from '../../input-field';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { AutocompleteField } from '../../autocomplete-field';

export const SalesTeamsPriceForm = ({ title, initialValues = {}, onClose }) => {
  const { currentCompany } = useApp();

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany,
      sales_team_channel_id: initialValues.sales_team_channel_id || '',
      sales_team_channel_info: initialValues.sales_team_channel_info || '',
      active: initialValues.active || 'Y'
    },
    validationSchema: Yup.object().shape({
      sales_team_channel_id: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log('values', values);
    }
  });

  const salesTeamsData = [
    {
      id: 1,
      name: 'Data 1',
      code: 'data1'
    },
    {
      id: 2,
      name: 'Data 2',
      code: 'data2'
    },
    {
      id: 3,
      name: 'Data 3',
      code: 'data3'
    }
  ];

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2,
            padding: 5
          }}
        >
          <Grid item xs={12}>
            <PropertyList>
              <AutocompleteField
                label="Canal de equipos de ventas"
                name="sales_team_channel_info"
                placeholder="Seleccione un canal"
                fullWidth
                options={salesTeamsData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('sales_team_channel_id', value?.id);
                  formik.setFieldValue('sales_team_channel_info', value);
                }}
                value={formik.values.sales_team_channel_info}
                sx={{ marginBottom: '1em' }}
              />
            </PropertyList>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

SalesTeamsPriceForm.propTypes = {
  initialValues: Proptypes.object,
  //   update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string
};

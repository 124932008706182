import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';

export const PurchaseItemBulkActionsMenu = (props) => {
  const {
    disabled,
    onDiscard,
    onValidate,
    onConfirm,
    onNoConfirm,
    onAccounting,
    selectedCount,
    menuActions,
    ...other
  } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleDiscard = async () => {
    onDiscard?.();
    handleClose();
  };

  const handleValidate = async () => {
    await onValidate?.();
    handleClose();
  };
  const handleConfirm = async () => {
    onConfirm?.();
    handleClose();
  };
  const handleNoConfirm = async () => {
    onNoConfirm?.();
    handleClose();
  };
  const handleAccounting = async () => {
    onAccounting?.();
    handleClose();
  };

  return (
    <>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        startIcon={<ChevronDownIcon />}
        variant="outlined"
        {...other}
      >
        Acciones Masivas
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {menuActions?.includes(GadminActions.gCC_PURCHASE_FFEE_DISCARD) ? (
          <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_DISCARD}>
            <MenuItem onClick={handleDiscard}>{`Descartar (${selectedCount})`}</MenuItem>
          </AllowedGuard>
        ) : null}
        {menuActions?.includes(GadminActions.gCC_PURCHASE_FFEE_CAB_VAL) ? (
          <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_VAL}>
            <MenuItem onClick={handleValidate}>{`Validar (${selectedCount})`}</MenuItem>
          </AllowedGuard>
        ) : null}
        {menuActions?.includes(GadminActions.gCC_PURCHASE_FFEE_CONFIRM) ? (
          <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CONFIRM}>
            <MenuItem onClick={handleConfirm}>{`Confirmar (${selectedCount})`}</MenuItem>
          </AllowedGuard>
        ) : null}
        {menuActions?.includes(GadminActions.gCC_PURCHASE_FFEE_NOCONFIRM) ? (
          <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_NOCONFIRM}>
            <MenuItem onClick={handleNoConfirm}>{`No Confirmar (${selectedCount})`}</MenuItem>
          </AllowedGuard>
        ) : null}
        {menuActions?.includes(GadminActions.gCC_PURCHASE_FFEE_CONTA) ? (
          <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CONTA}>
            <MenuItem onClick={handleAccounting}>{`Contabilizar (${selectedCount})`}</MenuItem>
          </AllowedGuard>
        ) : null}
      </Menu>
    </>
  );
};

PurchaseItemBulkActionsMenu.propTypes = {
  disabled: PropTypes.bool,
  onDiscard: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onNoConfirm: PropTypes.func.isRequired,
  onAccounting: PropTypes.func.isRequired,
  menuActions: PropTypes.array.isRequired,
  selectedCount: PropTypes.number
};

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { useApp } from '../../../hooks/use-app';
import gtm from '../../../lib/gtm';
import GadminActions from '../../../contexts/gadmin-actions';
import { Refresh } from '../../../icons/refresh';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { SurveySurveyTable } from '../../../components/survey/survey/survey-survey-table';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Query } from '../../../components/query';
import { CustomerModal } from '../../../components/delivery/monitoring/customer-modal';
import { SurveySurveyForm } from '../../../components/survey/survey/survey-survey-form';

const SurveyContainer = () => {
  const query = useQuery();
  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const app = useApp();
  const [open, onClose] = useState(false);

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    ,
    handlePageChange,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomeSurveyApi.getSurveySurveyList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
    // onPostRefresh: handleClearSelected
  });

  const [selectedDocuments, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    returnsState.documents
  );

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    handleClearSelected();
  }, [returnsState.documents]);

  return (
    <>
      <Helmet>
        <title>{app.title('Encuesta')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Encuesta
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<Refresh />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.G2_SURVEY_ADD}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={() => onClose(!open)}
                  variant="contained"
                  disabled={!returnsState?.success || returnsState.isLoading}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Box sx={{ paddingX: 5 }}>
              <Query
                disabled={returnsState.isLoading}
                onChange={handleQueryChange}
                sx={{
                  marginTop: 2
                }}
                value={controller.query}
              />
            </Box>
            <SurveySurveyTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
            <Divider />
          </Card>
        </Container>
        <CustomerModal open={open} onClose={onClose} permission={GadminActions.G2_CONTESTS_ADD}>
          <SurveySurveyForm onClose={onClose} onRefreshList={handleRefresh} />
        </CustomerModal>
      </Box>
    </>
  );
};

export default SurveyContainer;

import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
// import { pink } from '@mui/material/colors';
import { useState, useEffect } from 'react';

const imgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
};
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
};
const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative'
};

const SelectedImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  selected,
  onSelect,
  onClick
}) => {
  const [isSelected, setIsSelected] = useState(selected);
  // calculate x,y scale
  const sx = (100 - (30 / photo.width) * 100) / 100;
  const sy = (100 - (30 / photo.height) * 100) / 100;
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

  if (direction === 'column') {
    cont.position = 'absolute';
    cont.left = left;
    cont.top = top;
  }

  const handleOnSelect = (e, index) => {
    onSelect?.(e, index);
  };

  const handleOnClick = () => {
    onClick?.(index);
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);
  const label = { inputProps: { 'aria-label': 'Seleccionado' } };

  return (
    <div
      style={{ margin, height: photo.height, width: photo.width, ...cont }}
      className={!isSelected ? 'not-selected' : ''}
    >
      <Checkbox
        {...label}
        checked={isSelected}
        // sx={{
        //   color: pink[800],
        //   '&.Mui-checked': {
        //     color: pink[600],
        //   },
        // }}
        onChange={(e) => handleOnSelect(e, index)}
      />
      <img
        alt={photo.title}
        style={isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }}
        {...photo}
        onClick={handleOnClick}
        onKeyPress={handleOnClick}
        onKeyDown={handleOnClick}
        onMouseDown={handleOnClick}
        onMouseUp={handleOnClick}
      />
      <style>{`.not-selected:hover{outline:2px solid #06befa}`}</style>
    </div>
  );
};

SelectedImage.propTypes = {
  index: PropTypes.number,
  photo: PropTypes.object.isRequired,
  margin: PropTypes.any,
  direction: PropTypes.any,
  top: PropTypes.any,
  left: PropTypes.any,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func
};

export default SelectedImage;

import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/use-query';

// import gtm from '../../lib/gtm';
import { Plus as PlusIcon } from '../../icons/plus';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';

import { customerApi } from '../../api/customer';
import { useMounted } from '../../hooks/use-mounted';
import { RenderIf } from '../../components/render-if';
import { CustomerProductPurchasesTable } from '../../components/busines-partner/customer/customer-product-purchases-table';
import { useSplit } from '../../hooks/use-split';
import { useData } from '../../hooks/use-data';

export const CustomerProductPurchases = () => {
  const app = useApp();
  const query = useQuery();

  const { cardcode: cardcodeQuery } = useParams();
  const queried = {
    itemcode: query.get('item'),
    datefrom: query.get('date')
  };

  const mounted = useMounted();

  const { code: cardcode } = useSplit(cardcodeQuery);

  const [documentState, handleRefresh] = useData({
    sourceApi: customerApi.getCustomerProductPurchases,
    apiParameter: { cardcode, ...queried },
    loadingMessage: 'Cargando compras del cliente del producto',
    mounted
  });

  const renderContent = () => (
    <>
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Typography color="textPrimary" variant="h4">
            {documentState?.data?.code}-{documentState?.data?.name}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <RenderIf condition={1 === 0}>
              <AllowedGuard permission={GadminActions.gCC_DOC_SUCCESS_ADD}>
                <Box sx={{ mb: 2 }}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    onClick={() => {}}
                    variant="contained"
                  />
                </Box>
              </AllowedGuard>
            </RenderIf>
          </Box>
        </Box>
      </Box>
      <CustomerProductPurchasesTable
        onRefresh={handleRefresh}
        documentState={documentState}
        purchases={documentState.data}
      />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
        variant="outlined"
      >
        <Divider />
      </Card>
    </>
  );
  return (
    <>
      <Helmet>
        <title>{app.title('Compras del cliente')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Chip,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { useApp } from '../../../hooks/use-app';
import { Eye as EyeIcon } from '../../../icons/eye';
import { Status } from '../../status';

const statusQueues = [
  { color: 'success', label: 'success', value: true },
  { color: 'error', label: 'no-success', value: false }
];

const BulkQueuesTable = ({ data }) => {
  const { appUrlBase } = useApp();
  const detailRoute = `${appUrlBase}/automations/queues/`;

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Nombre de objeto</TableCell>
              <TableCell>Código de objeto</TableCell>
              <TableCell>Procesada en</TableCell>
              <TableCell>Descartada?</TableCell>
              <TableCell>Creada en</TableCell>
              <TableCell>Actualizada en</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((queue, index) => {
              const statusQueue = statusQueues.find((status) => status.value == queue.success);
              return (
                <TableRow key={`queue-of-a-bulk-${index}`} hover>
                  <TableCell
                    sx={{
                      minWidth: 155
                    }}
                  >
                    <Typography color="textSecondary" variant="body2">
                      ...{queue._id.slice(-8)}
                      <Tooltip title="Ver detalle">
                        <IconButton>
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`${detailRoute}${queue._id}`}
                            underline="none"
                            variant="subtitle2"
                          >
                            <EyeIcon />
                          </Link>
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip label={statusQueue.label} color={statusQueue.color} variant="outlined" />
                  </TableCell>
                  <TableCell>{queue.ObjectName}</TableCell>
                  <TableCell>{queue.ObjectCode}</TableCell>
                  <TableCell>
                    {queue.processed == 1 ? (
                      queue.processed_at
                    ) : (
                      <Chip label="NO PROCESADO" color="warning" variant="outlined" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Status
                      color={queue.discarded == '1' ? 'error.main' : 'success.main'}
                      label={queue.discarded == '1' ? 'Si' : 'No'}
                    />
                  </TableCell>
                  <TableCell>{queue.created_at}</TableCell>
                  <TableCell>{queue.updated_at}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

BulkQueuesTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default BulkQueuesTable;

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 7,
    fontStyle: 'bold'
  },
  memo: {
    width: '25%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8
  },
  value: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8
  },
  account: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8
  },
  amount: {
    width: '10%',
    textAlign: 'right',
    paddingRight: 8
  }
});

const JournalTableRow = ({ lines }) => {
  const rows = lines.map((item) => (
    <View style={styles.row} key={item.line}>
      <Text style={styles.account}>{item.shortName}</Text>
      <Text style={styles.memo}>{item.bpName}</Text>
      <Text style={styles.amount}>{numeral(item.debit).format(`0,0.00`)}</Text>
      <Text style={styles.amount}>{numeral(item.credit).format(`0,0.00`)}</Text>
      <Text style={styles.account}>{item.profitCode}</Text>
      <Text style={styles.account}>{item.account}</Text>
      <Text style={styles.memo}>{item.lineMemo}</Text>
      <Text style={styles.account}>{moment(item.refDate).format('DD/MM/YYYY')}</Text>
    </View>
  ));
  return <>{rows}</>;
};

JournalTableRow.propTypes = {
  lines: PropTypes.array
};
export default JournalTableRow;

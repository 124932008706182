import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalSearchApi {
  async getSearchItems2(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,
      // mainFilter,
      searchText,
      companyId
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/search/entities/items`,
      cacheItem: 'search',
      apiParams: {
        type: 'diary',
        // filters,
        // sort,
        // sortBy,
        // page,
        // query,
        // reload,
        // orderDate: mainFilter?.orderDate,
        // view
        filters,
        sort,
        sortBy,
        page,
        query,
        view,
        reload,
        searchText,
        companyId
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'CodigoCliente' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardName' },
        { type: 'string', name: 'slpName' },
        { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  // async getSearchItems() {
  //   const response = await dataLoader.loadGoalGetData({
  //     action: '/search/entities/items?query=melgar&companyId=9&entityId=0'
  //   });
  //   return response;
  // }

  async getSearch() {
    const response = await dataLoader.loadGoalGetData({
      action: '/search/resources/entities'
    });
    return response;
  }
}

export const goalSearchApi = new GoalSearchApi();

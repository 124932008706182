import axios from 'axios';
import { getGoalKey } from './gadmin';
import { appDefinitions } from '../api/app-definitions';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const apiVersion = process.env.REACT_APP_API_BASE_VERSION;
const stage = process.env.REACT_APP_STAGE;

const TOKEN_KEY_GOAL = `${stage}_${process.env.REACT_APP_API_TOKEN_KEY_GOAL}`;
const TOKEN_KEY_KC = `${stage}_${process.env.REACT_APP_API_TOKEN_KEY_KC}`;

// create headers for axios  accept gzip and jsone2
const headers = {
  // 'Content-Type': 'application/json',
  Accept: 'application/json',
  // Authorization: `Bearer ${token}`,
  // "Accept-Encoding": "gzip",
  'Access-Control-Allow-Origin': '*' // Required for CORS support to work
};

// create axios instance and set base url as backendService
const backendService = axios.create({
  baseURL: `${baseURL}/${apiVersion}`,
  timeout: 1000 * 60 * 7,
  headers
});

// const initialize = (service) => {
backendService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem(TOKEN_KEY_GOAL);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const gcToken = window.localStorage.getItem(TOKEN_KEY_KC);
    if (gcToken) {
      config.headers.GcAuthorization = `Bearer ${gcToken}`;
    }

    // set x-app-name header
    config.headers['x-app-name'] = appDefinitions.name;
    // set x-app-version header
    config.headers['x-app-version'] = appDefinitions.version;
    // set x-app-stage header
    config.headers['x-app-stage'] = appDefinitions.stage;
    // set x-app-uid header
    config.headers['x-app-uid'] = appDefinitions.uid;
    // set x-app-instance header
    config.headers['x-app-instance'] = window.localStorage.getItem(getGoalKey());

    // dev_GOAL_SID

    return config;
  },
  (error) => Promise.reject(error)
);

backendService.interceptors.response.use(
  (response) => {
    const success = response.status >= 200 && response.status < 300;
    const backendSuccess = response.data?.success;
    if (backendSuccess) {
      return {
        ...response.data,
        message: response.data?.message || 'Ejecutado correctamente'
      };
    }
    return {
      success,
      ...response,
      message: response?.data?.message || 'Ejecutado correctamente'
    };
  },
  (error) => {
    const { response } = error;
    const errorMessage = response?.data?.message || error.message || 'Error del servidor';

    return {
      success: false,
      ...error,
      response: {
        ...response,
        success: false,
        data: null,
        errorData: response?.data
      },
      apiMessage: errorMessage,
      message: errorMessage,
      errors: response?.data?.errors || ''
    };
  }
);
export { backendService };

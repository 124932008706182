import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { NumberColor } from '../../number-color';
import { Sumary } from '../../sumary';

export const JournalLinesTable = (props) => {
  const { journalInfo } = props;
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Cuenta</TableCell>
              <TableCell sx={{ minWidth: 300 }}>Cuenta/Nombre de SN</TableCell>
              <TableCell>Débito</TableCell>
              <TableCell>Crédito</TableCell>
              <TableCell>C Costo</TableCell>
              <TableCell>Ref1</TableCell>
              <TableCell>Ref2</TableCell>
              <TableCell>F Conta</TableCell>
              <TableCell>F Doc</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {journalInfo.lines?.map((item) => (
              <TableRow key={item.line}>
                <TableCell>{numeral(item.line).format('0')}</TableCell>
                <TableCell>{numeral(item.account).format('0')}</TableCell>
                <TableCell sx={{ minWidth: 300 }}>
                  <Box xl={2}>
                    <Typography color="inherit" variant="body2">
                      {item.ShortName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.accountName}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <NumberColor value={item.debit} format="0.00" />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.credit} format="0.00" />
                </TableCell>
                <TableCell>{item.profitCode}</TableCell>
                <TableCell>{item.ref1}</TableCell>
                <TableCell>{item.ref2}</TableCell>
                <TableCell>{moment(item.refDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{moment(item.taxDate).format('DD/MM/YYYY')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Sumary values={journalInfo.lines} column="gTotal" />
      </Scrollbar>
    </div>
  );
};

JournalLinesTable.propTypes = {
  journalInfo: PropTypes.object.isRequired
};

import { useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Button, DialogActions, DialogTitle, Grid, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { InputField } from '../../input-field';
import { useApp } from '../../../hooks/use-app';
import { RenderIf } from '../../render-if';
import { RangeAwardsForm } from './range-awards-form';
import { PropertyList } from '../../property-list';

export const GenerateRangeForm = ({
  onClose,
  initialValues = {},
  typeOption,
  handleSetRange,
  maxRange,
  configAwardsState = [],
  handleSetTypeRange,
  setCreateRange,
  valueOption,
  handleSaveValue,
  ranges = [],
  selectAction,
  awards = [],
  minDistance,
  formikAwards,
  employeePositionList,
  configAward,
  setEditmode
}) => {
  const [showRange, setShowRange] = useState(
    selectAction === 'edit-form' || selectAction === 'clone-form'
  );
  const { showError } = useApp();

  const formik = useFormik({
    initialValues: {
      valueMin: initialValues.valueMin || valueOption,
      rangeQuantity: ranges.length || 2,
      employee_position: initialValues.employee_position || '',
      employee_position_code: initialValues.employee_position_code || '',
      type: typeOption || '',
      ranges: initialValues.ranges || [],
      active: initialValues.active || true,
      min_distance: initialValues.min_distance || minDistance
    },
    validationSchema: Yup.object().shape({
      valueMin: Yup.number()
        .min(1, 'El valor mínimo debe ser mayor a 0')
        .required('Valor mínimo es requerido'),
      rangeQuantity: Yup.number()
        .min(2, 'El valor mínimo del rango debe ser 2 o más')
        .max(maxRange, `El valor máximo debe ser ${maxRange} o menos`)
        .required('Este campo es obligatorio')
    }),
    onSubmit: async () => {
      // console.log(values);
      handleSaveValue(formik.values.valueMin);
      setShowRange(!showRange);
      setValueRange(
        generateArray(
          formik.values.rangeQuantity,
          formik.values.valueMin,
          formik.values.min_distance
        )
      );
    }
  });

  const [valueRange, setValueRange] = useState(selectAction === 'edit-form' ? ranges : []);

  const generateArray = (n = 1, min, distance = 5) => {
    if (n < 2 || n === null || n === 0 || n === '0') {
      showError('El tamaño del array debe ser al menos 2');
      return [];
    }

    const arr = Array.from({ length: n }, (_, i) => min + i * distance);
    return arr;
  };

  const handleChangeRange = (event, newValue) => {
    // console.log('newValue', newValue);
    setValueRange(newValue);
  };

  const handleCancelRange = () => {
    onClose(false);
    setCreateRange(true);
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <RenderIf
        condition={showRange}
        no={
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Rango</DialogTitle>
            <Grid
              container
              spacing={8}
              sx={{
                marginBottom: 2
              }}
            >
              <Grid item xs={12} md={4}>
                <PropertyList>
                  <InputField
                    select
                    label="Tipo"
                    name="type"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                    fullWidth
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    sx={{ marginBottom: '1em' }}
                  >
                    {configAwardsState.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.code}
                        onClick={() => handleSetTypeRange(item.code)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </InputField>
                  <InputField
                    name="valueMin"
                    label="Valor inicial"
                    type="number"
                    value={formik.values.valueMin}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.valueMin && Boolean(formik.errors.valueMin)}
                    helperText={formik.touched.valueMin && formik.errors.valueMin}
                    sx={{ marginBottom: '1em' }}
                  />
                  <InputField
                    name="min_distance"
                    label="Distancia mínima"
                    type="number"
                    value={formik.values.min_distance}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.min_distance && Boolean(formik.errors.min_distance)}
                    helperText={formik.touched.min_distance && formik.errors.min_distance}
                    sx={{ marginBottom: '1em' }}
                  />
                  <InputField
                    name="rangeQuantity"
                    label="Cantidad de Rangos"
                    type="number"
                    value={formik.values.rangeQuantity}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.rangeQuantity && Boolean(formik.errors.rangeQuantity)}
                    helperText={formik.touched.rangeQuantity && formik.errors.rangeQuantity}
                    sx={{ marginBottom: '1em' }}
                  />
                </PropertyList>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: 2
              }}
            >
              <Grid item xs={12}>
                <DialogActions>
                  <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                    <LoadingButton
                      color="primary"
                      size="large"
                      startIcon={<PlusIcon />}
                      variant="contained"
                      type="submit"
                      loading={formik.isSubmitting}
                    >
                      Generar
                    </LoadingButton>
                    <Button
                      color="secondary"
                      size="large"
                      startIcon={<TrashIcon />}
                      onClick={handleCancelRange}
                      sx={{
                        marginLeft: 1
                      }}
                    >
                      Cancelar
                    </Button>
                  </AllowedGuard>
                </DialogActions>
              </Grid>
            </Grid>
          </form>
        }
      >
        <RangeAwardsForm
          showRange={showRange}
          setShowRange={setShowRange}
          rangeQuantity={formik.values.rangeQuantity}
          valueMin={formik.values.valueMin}
          valueMax={formik.values.valueMin + 100}
          handleChangeRange={handleChangeRange}
          valueRange={valueRange}
          handleSetRange={handleSetRange}
          onClose={onClose}
          setCreateRange={setCreateRange}
          awardsValue={awards}
          minDistance={formik.values.min_distance}
          typeOption={formik.values.type}
          formikAwards={formikAwards}
          employeePositionList={employeePositionList}
          configAward={configAward}
          setEditmode={setEditmode}
          selectAction={selectAction}
        />
      </RenderIf>
    </Box>
  );
};

GenerateRangeForm.propTypes = {
  initialValues: Proptypes.object,
  onClose: Proptypes.func,
  typeOption: Proptypes.string,
  valueOption: Proptypes.number,
  handleSetRange: Proptypes.func,
  handleSetTypeRange: Proptypes.func,
  setCreateRange: Proptypes.func,
  maxRange: Proptypes.number,
  configAwardsState: Proptypes.array,
  handleSaveValue: Proptypes.func,
  ranges: Proptypes.array,
  selectAction: Proptypes.string,
  awards: Proptypes.array,
  minDistance: Proptypes.number,
  employeePositionList: Proptypes.array,
  configAward: Proptypes.object,
  formikAwards: Proptypes.shape({
    values: Proptypes.object.isRequired,
    setFieldValue: Proptypes.func.isRequired,
    handleChange: Proptypes.func.isRequired,
    touched: Proptypes.object.isRequired,
    errors: Proptypes.object.isRequired
  }),
  setEditmode: Proptypes.func
};

import { useEffect, useState } from 'react';
import { useApp } from './use-app';

export const useCacheData = (paramName, cacheBolean = true, companyId = false) => {
  const { currentCompany } = useApp();
  useEffect(() => {
    if (!cacheBolean) {
      clearCache();
    }
  }, []);

  const cachedIdFromLocalStorage = JSON.parse(localStorage.getItem(`cacheId-${paramName}`)) || [];
  const cachedDataFromLocalStorage =
    JSON.parse(localStorage.getItem(`cacheData-${paramName}`)) || [];
  const [cacheId, setCacheId] = useState(cachedIdFromLocalStorage);
  const [cacheArray, setCacheArray] = useState(cachedDataFromLocalStorage);

  const addToCache = async (item, setData, apiComponet) => {
    if (!cacheId.includes(item.id)) {
      setCacheId([...cacheId, item.id]);

      let nodeData;
      if (companyId) {
        nodeData = await apiComponet(item.id, currentCompany);
      } else {
        nodeData = await apiComponet(item.id);
      }
      // const nodeData = await apiComponet(item.id, currentCompany);
      if (nodeData.success) {
        setData(nodeData.data);
        setCacheArray((prevDataArray) => [...prevDataArray, ...nodeData.data]);
      }
    } else {
      const parentObjects = cacheArray.filter((cache) => cache.parent_id === item.id.toString());
      setData(parentObjects);
    }
  };

  const removeFromCache = (idToRemove) => {
    const updatedCacheId = cacheId.filter((id) => id !== idToRemove);
    setCacheId(updatedCacheId);
    const updatedCacheArray = cacheArray.filter(
      (cache) => cache.parent_id !== idToRemove.toString()
    );
    setCacheArray(updatedCacheArray);
  };

  useEffect(() => {
    localStorage.setItem(`cacheId-${paramName}`, JSON.stringify(cacheId));
  }, [cacheId]);

  useEffect(() => {
    localStorage.setItem(`cacheData-${paramName}`, JSON.stringify(cacheArray));
  }, [cacheArray]);

  const clearCache = () => {
    localStorage.removeItem(`cacheId-${paramName}`);
    localStorage.removeItem(`cacheData-${paramName}`);
    setCacheId([]);
    setCacheArray([]);
  };

  return { addToCache, clearCache, removeFromCache, cacheId, cacheArray };
};

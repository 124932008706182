import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Typography } from '@mui/material';
// import gtm from '../../lib/gtm';
import { useQuery } from '../../../hooks/use-query';
import { useApp } from '../../../hooks/use-app';
import { financialReportApi } from '../../../api/financial-reports';
import { useMounted } from '../../../hooks/use-mounted';
import { useDownloadFile } from '../../../hooks/use-download-file';
import LoadingContent from '../../../components/common/loading-content';
import { FePendingsView } from '../../../api/financial-definitions';
import { useData } from '../../../hooks/use-data';
import { despatchAdvice } from '../../../api/goal/electronic-documents/despatch-advice';
import { GREPendingsFACELETable } from '../../../components/accounting/fe/gre-pendings-facele-table';

export const GREPendings = () => {
  const query = useQuery();
  const { currentCompany } = useApp();

  const mounted = useMounted();
  const queried = {
    date: query.get('date'),
    type: query.get('type'),
    serie: query.get('serie')
  };

  const app = useApp();

  const [documentState, handleRefresh] = useData({
    sourceApi: despatchAdvice.getFacelePendingsList,
    apiParameter: {
      companyId: currentCompany,
      date: queried.date,
      type: queried.type,
      serie: queried.serie
    },
    loadingMessage: 'Cargando guias de remisión pendientes de envío a Facele',
    mounted
  });

  const { download } = useDownloadFile({
    apiDefinition: financialReportApi.getFEPendingsFaceleDetailXls,
    apiParameters: queried
  });

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <>
      <Helmet>
        <title>{app.title('GRE Pendientes de envío a FACELE')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Guias de remisión pendientes de envío a Facele
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Typography color="textSecondary" variant="h4">
                {queried.date} - {queried.type} - {queried.serie}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </Box>
          <GREPendingsFACELETable
            data={documentState}
            onRefresh={handleRefresh}
            label="GRE Pendientes de envío a Facele"
            onDownload={download}
            view={FePendingsView.VIEW_PENDING_FACELE}
          />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

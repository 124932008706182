import { useDialog } from './use-dialog';

const useGoalDialog = ({ handleClose, apiHandler, apiParams }) => {
  const [open, openDialog, closeDialog] = useDialog();

  const handleReport = () => {
    handleClose?.();
    openDialog();
  };

  const onClose = () => {
    handleClose?.();
    closeDialog();
  };
  return [
    {
      open,
      openDialog,
      closeDialog: onClose,
      onClick: handleReport,
      apiHandler,
      apiParams
    }
  ];
};
export { useGoalDialog };

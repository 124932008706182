import Chart from 'react-apexcharts';
import numeral from 'numeral';
import { Box, Card, CardContent, Grid, List, Link, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { StatusBadge } from '../../status-badge';
import { customerReturnsApi } from '../../../api/customer-return';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../common/loading-content';
import { useData } from '../../../hooks/use-data';
import { RenderIf } from '../../render-if';
import { useApp } from '../../../hooks/use-app';

export const CustomerReturnsStats = () => {
  const theme = useTheme();
  const mounted = useMounted();
  const [invoiceState] = useData({
    sourceApi: customerReturnsApi.getReturnsStats,
    loadingMessage: 'Cargando Estadísticas de Devoluciones...',
    mounted
  });
  const { appUrlBase } = useApp();
  if (invoiceState.isLoading || !invoiceState.success) {
    return <LoadingContent loadingText={invoiceState.message} error={invoiceState.error} />;
  }
  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: invoiceState.data?.stats?.map((item) => item.color),
    dataLabels: {
      enabled: false
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    labels: invoiceState.data?.stats?.map((item) => item.name),
    legend: {
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    }
  };

  const chartSeries = invoiceState?.data?.stats?.map((item) => item.data) || [];

  return (
    <Card
      elevation={0}
      sx={{
        // backgroundColor: alpha(theme.palette.info.main, 0.1),
        mb: 2
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography color="textSecondary" variant="overline">
              <Link
                to={`${appUrlBase}/sales/returns`}
                color="inherit"
                underline="hover"
                component={RouterLink}
              >
                Ordenes de recojo desde
              </Link>
            </Typography>
            <Typography color="textPrimary" sx={{ mb: 3 }} variant="h4">
              {invoiceState?.minDate}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Todas la OR procesadas <strong> {invoiceState?.counters?.all} </strong>
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: {
                sm: 'row',
                xs: 'column'
              }
            }}
          >
            <RenderIf condition={chartSeries && chartSeries.lenght > 0}>
              <Chart options={chartOptions} series={chartSeries} type="donut" width={150} />
            </RenderIf>
            <List sx={{ flex: 1 }}>
              {invoiceState?.data?.stats?.map((item) => (
                <ListItem disableGutters key={item.name} sx={{ display: 'flex' }}>
                  <StatusBadge color={item.color} sx={{ mr: 1 }} />
                  <Typography color="textSecondary" variant="body2">
                    {item.name}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography color="textPrimary" variant="body2">
                    {numeral(item.data).format('0')}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { useState, useEffect } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { appFormats } from '../api/app-definitions';
import { isNumeric } from './use-number';

export const useFormatValue = (value, format, isDate = false) => {
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    if (value !== undefined && format && !isDate) {
      const formatted = numeral(value).format(format);
      setFormattedValue(formatted);
    }

    if (isDate && format && value !== null) {
      const parsedDate = moment(value);
      const formattedDate = parsedDate.format(format);
      setFormattedValue(formattedDate);
    }
  }, [value, format]);

  return formattedValue;
};

export const formatValueFuntion = (value, format, isDate = false) => {
  if (value === undefined || value === null) return '';
  if (isDate) {
    const parsedDate = moment(value);
    const formattedDate = parsedDate.format(format ?? appFormats.date);
    return formattedDate;
  }

  if (isNumeric(value)) {
    const formatted = numeral(value).format(format ?? appFormats.number);
    return formatted;
  }

  return '';
};

export const formatDate = (value, format) => {
  return formatValueFuntion(value, format ?? appFormats.date, true);
};

import Pusher from 'pusher-js';
import { appDefinitions, pusherOptions } from '../api/app-definitions';

class WsPusherApi {
  _pusher = null;
  connection = null;
  //
  connect() {
    this._pusher = new Pusher(appDefinitions.appId, pusherOptions);
    this._pusher.connection.bind('connected', () => {
      console.log('connected');
    });
    this._pusher.connection.bind('disconnected', () => {
      console.log('disconnected');
    });
  }

  listen(channel, event, callback) {
    const _routeChannel = this._pusher.subscribe(channel);
    _routeChannel.bind(event, callback);
    this.connection = { channel, event, ...this.connection };
    return this.connection;
  }

  disconnect() {
    if (!this.connection) return;
    const { channel } = this.connection;
    if (!channel) return;
    this._pusher.unsubscribe(channel);
    this.connection = null;
    this._pusher.disconnect();
  }

  reconnect(channel, event, callback) {
    this.disconnect();
    this.connect();
    this.listen(channel, event, callback);
  }
}

export const wsPusherApi = new WsPusherApi();

import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import numeral from 'numeral';
import {
  Box,
  Checkbox,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
// import { Status } from '../../status';
import { InvoiceItemMenu } from './invoice-item-menu';
import { Status } from '../../status';
import { Code as CodeIcon } from '../../../icons/code';
import { Page as PageIcon } from '../../../icons/page';
import { Check as CheckIcon } from '../../../icons/check';
import { RenderIf } from '../../render-if';
import { useApp } from '../../../hooks/use-app';

const columns = [
  {
    id: 'docentry',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'documento',
    label: 'Comprobante'
  },
  {
    id: 'objname',
    label: 'SB1'
  },
  {
    id: 'CardName',
    label: 'Proveedor'
  },
  {
    id: 'issueDate',
    label: 'Fecha'
  },
  {
    id: 'totalAmount',
    label: 'Total'
  },
  {
    id: 'paymentMethod',
    label: 'Termino de Pago'
  },
  {
    id: 'status',
    label: 'Estado'
  },
  {
    id: 'actions',
    label: ''
  },
  {
    id: 'igv',
    label: 'IGV'
  },
  {
    id: 'gravado',
    label: 'Gravado'
  },
  {
    id: 'exonerado',
    label: 'Exonerado'
  },
  {
    id: 'whsname',
    label: 'Almacen'
  }
];

const statusVariants = [
  { color: 'info.main', label: 'Borrador', value: 'D' },
  { color: 'warning.main', label: 'Validado', value: 'V' },
  { color: 'warning.main', label: 'Verificado - alm', value: 'N' },
  { color: 'success.main', label: 'Recibido', value: 'R' },
  { color: 'warning.main', label: 'Aprobado Contable', value: 'Y' },
  { color: 'success.main', label: 'Facturado', value: 'F' },
  { color: 'error.main', label: 'Anulado', value: 'A' },
  { color: 'success.main', label: 'Confirmado', value: 'X' },
  { color: 'warning.main', label: 'Para anular', value: 'Z' },
  { color: 'error.main', label: 'Devuelto', value: 'T' }
];

export const InvoicesItemTable = (props) => {
  const {
    error,
    invoices = [],
    invoicesCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedInvoices = [],
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const { appUrlBase } = useApp();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !invoices?.length);
  const detailRoute = `${appUrlBase}/purchases/invoice-items/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={invoices.length > 0 && selectedInvoices.length === invoices.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedInvoices.length > 0 && selectedInvoices.length < invoices.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => {
              const statusVariant = statusVariants.find(
                (variant) => variant.value === invoice.status
              );

              return (
                <TableRow
                  hover
                  key={invoice.docEntry}
                  selected={
                    !!selectedInvoices.find(
                      (selectedCustomer) => selectedCustomer === invoice.docEntry
                    )
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        !!selectedInvoices.find(
                          (selectedCustomer) => selectedCustomer === invoice.docEntry
                        )
                      }
                      onChange={(event) => onSelect(event, invoice.docEntry)}
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${detailRoute}${invoice.docEntry}`}
                      underline="none"
                      variant="subtitle2"
                    >
                      <RenderIf condition={invoice.xmlCode}>
                        <CodeIcon />
                      </RenderIf>
                      <RenderIf condition={invoice.pdfCode}>
                        <PageIcon />
                      </RenderIf>
                      <RenderIf condition={invoice.cpe_status === 1}>
                        <CheckIcon />
                      </RenderIf>
                      <Typography color="inherit" variant="body2">
                        {invoice.docEntry}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {invoice.docType}-{invoice.seriesName}
                  </TableCell>
                  <TableCell>
                    {invoice.objName}-{invoice.targetRef}
                  </TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{invoice.cardName}</TableCell>
                  <TableCell>{format(new Date(invoice.docDate), 'yyyy-MM-dd')}</TableCell>
                  <TableCell>{numeral(invoice.docTotal).format(`${'S/'}0,0.00`)}</TableCell>
                  <TableCell>{invoice.paymentName}</TableCell>
                  <TableCell>
                    {/* {invoice.descrip} */}
                    <Status color={statusVariant?.color} label={statusVariant?.label} />
                  </TableCell>
                  <TableCell>
                    <InvoiceItemMenu invoice={invoice} />
                  </TableCell>
                  <TableCell>{numeral(invoice.sumIgv).format(`${'S/'}0,0.00`)}</TableCell>
                  <TableCell>{numeral(invoice.sumGravada).format(`${'S/'}0,0.00`)}</TableCell>
                  <TableCell>{numeral(invoice.sumExonerada).format(`${'S/'}0,0.00`)}</TableCell>
                  <TableCell>{invoice.whsCode}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={invoicesCount}
      />
    </Box>
  );
};

InvoicesItemTable.propTypes = {
  invoices: Proptypes.array,
  invoicesCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedInvoices: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

import { Button, Card, CardHeader, Divider, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

export const CustomerDeliveryTeamsAdd = () => {
  const initialValues = {
    additional: 'N',
    authorized_at: null,
    authorized_by: null,
    cardCode: '',
    cardName: '',
    confirmed: 'N',
    confirmed_at: null,
    confirmed_by: null,
    created_at: '',
    created_by: '',
    dispatchId: 0,
    documentNumber: '0',
    guard_name: '',
    id: 0,
    position: 1,
    positionName: '',
    type: 'I',
    typeName: 'Interno',
    updated_at: null,
    updated_by: null,
    value: '.00'
  };
  const validationSchema = Yup.object().shape({
    cardName: Yup.string().required('Required'),
    positionName: Yup.string().required('Required'),
    typeName: Yup.string().required('Required')
  });

  const onSubmit = async (values) => {
    console.log(values);
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Datos del Equipo" />
      <Divider />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formikProps) => (
          <Form>
            <Field
              as={TextField}
              name="cardName"
              label="Nombre"
              variant="outlined"
              margin="normal"
              style={{ width: '300px', marginLeft: '10px' }}
              helperText={formikProps.touched.cardName && formikProps.errors.cardName}
              error={!!(formikProps.touched.cardName && formikProps.errors.cardName)}
            />
            <Field
              as={TextField}
              name="positionName"
              label="Posicion"
              variant="outlined"
              margin="normal"
              style={{ width: '300px', marginLeft: '10px' }}
              helperText={formikProps.touched.positionName && formikProps.errors.positionName}
              error={!!(formikProps.touched.positionName && formikProps.errors.positionName)}
            />
            <Field
              as={TextField}
              name="cardCode"
              label="Codigo de empleado"
              variant="outlined"
              margin="normal"
              style={{ width: '300px', marginLeft: '10px' }}
            />
            <Field
              as={TextField}
              name="typeName"
              label="Tipo"
              variant="outlined"
              margin="normal"
              style={{ width: '300px', marginLeft: '10px' }}
              helperText={formikProps.touched.typeName && formikProps.errors.typeName}
              error={!!(formikProps.touched.typeName && formikProps.errors.typeName)}
            />
            <Field
              as={TextField}
              name="value"
              label="Nombre"
              variant="outlined"
              margin="normal"
              style={{ width: '300px', marginLeft: '10px' }}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ ml: 4, mt: 5 }}>
              Agregar Equipo
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

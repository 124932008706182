import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
import { dataLoader } from './data-loader';

/* eslint class-methods-use-this:0 */

class DeliveryApi {
  async getHRInvoicing() {
    const response = await GadminRequest(GadminActions.gCC_OPEN_ORDERS, {
      type: 'list'
    });
    return Promise.resolve(response.data);
  }

  async getHRUpload(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,

      mainFilter
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.gCC_OPEN_ORDERS,
      cacheItem: 'customer-delivery-monitoring',
      apiParams: {
        type: 'diary',
        payload: {
          filters,
          sort,
          sortBy,
          page,
          query,
          reload,
          orderDate: mainFilter?.orderDate,
          view
        }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'CodigoCliente' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardName' },
        { type: 'string', name: 'slpName' },
        { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  async getAuxiliariesCurrent(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.gCC_DELIVERY_PAR_APOYOREP,
      cacheItem: 'delivery-auxiliary-current',
      apiParams: {
        type: 'list-current',
        payload: { filters, sort, sortBy, page, query, view, reload }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'cardcode' },
        { type: 'string', name: 'cardname' },
        { type: 'string', name: 'placa' },
        { type: 'number', name: 'id' }
      ],
      viewField: 'sede',
      ...options
    });
  }

  async upAuxiliaryCurrent(props) {
    const response = await GadminRequest(GadminActions.gCC_DELIVERY_PAR_APOYOREP_UP, {
      type: 'update-current',
      payload: props
    });
    return Promise.resolve(response.data);
  }

  async getAuxiliariesHst(props) {
    if (props.type === 'placa') return this.getAuxiliariesHstByPlaca({ id: props.id });
    if (props.type === 'date') return this.getAuxiliariesHstByDate({ id: props.id });
    throw new Error('opcion no válida');
  }

  async getAuxiliariesHstByPlaca(props) {
    const response = await GadminRequest(GadminActions.gCC_DELIVERY_PAR_APOYOREP, {
      type: 'list-hst-placa',
      payload: props
    });
    return Promise.resolve(response.data);
  }

  async getAuxiliariesHstByDate(props) {
    const response = await GadminRequest(GadminActions.gCC_DELIVERY_PAR_APOYOREP, {
      type: 'list-hst-date',
      payload: props
    });
    return Promise.resolve(response.data);
  }

  async getPedidosHrPend(props) {
    const response = await GadminRequest(GadminActions.gCC_DELIVERY_HREP_ORD_PENDS, {
      type: props.type,
      payload: props
    });
    return Promise.resolve(response.data);
  }

  async getPedidosHrPendStats() {
    const response = await GadminRequest(GadminActions.gCC_DELIVERY_HREP_ORD_PENDS, {
      type: 'stats',
      payload: { id: 1 }
    });
    return response;
  }

  async getHrLoadPage(id) {
    const response = await GadminRequest(GadminActions.gCC_DET_HR, {
      type: 'load-page',
      payload: { id }
    });
    return response;
  }

  async getHrDeliveryPage(id) {
    const response = await GadminRequest(GadminActions.gCC_DET_HR, {
      type: 'delivery-page',
      payload: { id }
    });
    return response;
  }

  async getSumaryLoadPage(payload) {
    const response = await GadminRequest(GadminActions.gCC_DET_HR, {
      type: 'sumary-load-page',
      payload
    });
    return response;
  }

  async getDeliveryProgramedPages(payload) {
    const response = await GadminRequest(GadminActions.gCC_DET_HR, {
      type: 'delivery-vehicle-programed-pages',
      payload: { ...payload, id: -1 }
    });
    return response;
  }

  async generateDeliveryNote(payload) {
    const response = await GadminRequest(GadminActions.gCC_DELIVERI_GR_CREATE, {
      type: 'doc-note',
      payload: { ...payload, id: -1 }
    });
    return response;
  }
}

export const deliveryApi = new DeliveryApi();

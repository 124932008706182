import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { useMounted } from '../../../hooks/use-mounted';
import { useQuery } from '../../../hooks/use-query';
import { useApp } from '../../../hooks/use-app';
import { RenderIf } from '../../../components/render-if';
import { SurveySumaryDetail } from '../../../components/survey/survey/survey-summary-detail';
import { SurveySummaryDetailEdit } from '../../../components/survey/survey/survey-summary-detail-edit';
import { useSelection } from '../../../hooks/use-selection';
import { SurveyItemsTable } from '../../../components/survey/survey/survey-item-table';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { useData } from '../../../hooks/use-data';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { useDialog } from '../../../hooks/use-dialog';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { HRProgramedDialog } from '../../../components/delivery/hr/hr-programed-dialog';
import { QueryCustomerModal } from '../../promotions/query-customer-modal';
import { documentStatusViews } from '../../../api/app-definitions';
import { SurveySurveyDetailTable } from '../../../components/survey/survey/survey-survey-detial-table';
import { SurveySurveyDetailFilter } from '../../../components/survey/survey/survey-survey-detail-filter';
import LoadingContent from '../../../components/common/loading-content';
import { Refresh } from '../../../icons/refresh';

export const SurveyDetailContainer = () => {
  const { id } = useParams();
  const app = useApp();

  const [editMode, setEditmode] = useState(false);

  const [selectData, setSelectData] = useState([]);
  const [selectDataFilter, setSelectDataFilter] = useState([]);
  const [selectRender, setSelectRender] = useState(0);
  const { showNotify, showError } = useApp();

  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const [selectedItems, handleSelect, handleSelectAll, handleClearSelected] = useSelection();

  const [selectedDocuments, handleSelectDetail, handleSelectAllDetail, handleClearSelectedDetail] =
    useSelection([]);

  const handlePlusDialog = () => {
    if (selectRender === 0) {
      handleRefresh();
      setSelectRender(selectRender + 1);
    }
    handleOpenEditDialog();
  };

  const query = useQuery();
  const mounted = useMounted();
  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };
  useEffect(() => {
    if (queried.query) handleSetController.setSearchText({ queried });
  }, [queried]);

  const [detailSurveyState, handleRefreshDetail] = useData({
    sourceApi: goalCustomeSurveyApi.getSurveySurveyIdList,
    apiParameter: id,
    loadingMessage: 'Cargando informacion de la encuesta',
    mounted
  });

  const [
    returnsState,
    handleRefresh,
    ,
    ,
    ,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomeSurveyApi.getSurveyList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
  });

  useEffect(() => {
    if (returnsState.success) {
      const filteredData = returnsState.documents.filter((item) => {
        const idExistsInExclusion = detailSurveyState.data?.selectQuestions.some(
          (exclusionItem) => exclusionItem.id === item.id
        );
        return !idExistsInExclusion;
      });
      setSelectDataFilter(filteredData);
    }
  }, [returnsState.isLoading, selectRender]);

  if (detailSurveyState.isLoading || detailSurveyState.error) {
    return (
      <LoadingContent loadingText={detailSurveyState.message} error={detailSurveyState.error} />
    );
  }
  if (!detailSurveyState.success) {
    return (
      <LoadingContent loadingText={detailSurveyState.message} error={detailSurveyState.message} />
    );
  }
  if (detailSurveyState.data === null || detailSurveyState.data.length === 0) {
    return <LoadingContent loadingText="No hay datos" error={detailSurveyState.error} />;
  }

  const handleSetQuestions = (data) => {
    console.log('data', data);
  };

  const handleExitedDialog = () => {};

  const handleRefreshFilter = () => {
    handleRefresh();
    handleRefreshDetail(id);
    setSelectRender(selectRender + 1);
  };

  const handleSelectionConfirm = async () => {
    if (!!selectedItems && selectedItems.length > 0) {
      const newData = selectData.map((obj) => ({
        id: obj.id,
        _id: obj._id,
        _rev: obj._rev,
        title: obj.title,
        type_question: obj.type_question,
        question: obj.question,
        options: obj.options,
        correct: obj.correct,
        score: obj.score
        // apply_to: obj.apply_to
      }));
      const addScores = newData.reduce((suma, objeto) => suma + objeto.score, 0);
      const addSelectQuestions = {
        ...detailSurveyState.data,
        selectQuestions: [...detailSurveyState.data.selectQuestions, ...newData],
        point: detailSurveyState.data.point + addScores
      };
      const response = await goalCustomeSurveyApi.updateSurveySurveyForm(
        detailSurveyState.data._id,
        addSelectQuestions
      );
      if (response.success) {
        setSelectData([]);
        handleClearSelected();
        showNotify(`${response?.message}. Cambio exitoso `);
        handleRefreshFilter();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
      handleCloseEditDialog();
    } else if (!!selectData && selectData.length > 0) {
      handleCloseEditDialog();
    } else {
      showNotify('No se ha seleccionado filas', 'warning');
    }
  };

  const handleUpdateScore = async (itemDelete) => {
    const filteredQuestions = detailSurveyState.data.selectQuestions.filter(
      (item) => item.id !== itemDelete.id
    );
    const addSelectQuestions = {
      ...detailSurveyState.data,
      point: detailSurveyState.data.point - itemDelete.score,
      selectQuestions: filteredQuestions
    };
    const response = await goalCustomeSurveyApi.updateSurveySurveyForm(
      detailSurveyState.data._id,
      addSelectQuestions
    );
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      setSelectData([]);
      handleRefreshFilter();
      handleClearSelectedDetail();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const handleUpdateMultiScore = async (idsToExclude) => {
    const filteredQuestions = detailSurveyState.data.selectQuestions.filter(
      (item) => !idsToExclude.includes(item.id)
    );
    const totalScore = filteredQuestions.reduce((total, item) => total + item.score, 0);
    const deleteSelectQuestions = {
      ...detailSurveyState.data,
      point: totalScore,
      selectQuestions: filteredQuestions
    };
    const response = await goalCustomeSurveyApi.updateSurveySurveyForm(
      detailSurveyState.data._id,
      deleteSelectQuestions
    );
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      setSelectData([]);
      handleClearSelected();
      handleRefreshFilter();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  return (
    <>
      <Helmet>
        <title>{app.title('Detalle')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Encuesta
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<Refresh />}
                  onClick={() => handleRefreshDetail(id)}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <RenderIf
            condition={editMode}
            no={<SurveySumaryDetail item={detailSurveyState.data} setEditMode={setEditmode} />}
          >
            <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
              <SurveySummaryDetailEdit
                initialValues={detailSurveyState.data}
                onClose={setEditmode}
                onRefresh={() => handleRefreshDetail(id)}
              />
            </Card>
          </RenderIf>
          <Card variant="outlined" sx={{ pb: 3, mb: 3, mt: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_SURVEY_ADD}>
                <Button
                  color="primary"
                  size="medium"
                  onClick={handlePlusDialog}
                  startIcon={<PlusIcon />}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                  disabled={detailSurveyState.data?.status !== documentStatusViews.VIEW_DRAFT}
                />
              </AllowedGuard>
            </Box>
            <SurveySurveyDetailFilter
              disabled={detailSurveyState.isLoading}
              selectedDocuments={selectedDocuments}
              handleClearSelectedDetail={handleClearSelectedDetail}
              onRefresh={handleRefreshFilter}
              handleUpdateScore={handleUpdateMultiScore}
              btnDisabled={detailSurveyState.data?.status !== documentStatusViews.VIEW_DRAFT}
            />
            <SurveySurveyDetailTable
              error={false}
              documents={detailSurveyState.data.selectQuestions}
              isLoading={detailSurveyState.isLoading}
              onSelect={handleSelectDetail}
              onSelectAll={handleSelectAllDetail}
              selectedDocuments={selectedDocuments}
              idDetail={id}
              handleRefresh={handleRefreshFilter}
              handleUpdateScore={handleUpdateScore}
              btnDisabled={detailSurveyState.data?.status !== documentStatusViews.VIEW_DRAFT}
            />
          </Card>
          <HRProgramedDialog
            onClose={handleCloseEditDialog}
            onSelectionConfirm={handleSelectionConfirm}
            onExited={handleExitedDialog}
            open={editDialogOpen}
            title="Encuestas"
            helpText=""
          >
            <QueryCustomerModal
              disabled={returnsState.isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              handleRefresh={handleRefresh}
              title="Seleccionar preguntas"
            />
            <SurveyItemsTable
              requestData={selectDataFilter}
              disabled={returnsState.isLoading}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              selectedItems={selectedItems}
              setSelectData={setSelectData}
              selectData={selectData}
              handleClearSelected={handleClearSelected}
              isLoading={returnsState.isLoading}
              error={returnsState.error}
              handleSetQuestions={handleSetQuestions}
              plusMinusSelect
              detailSurveyState={detailSurveyState}
            />
          </HRProgramedDialog>
        </Container>
      </Box>
    </>
  );
};

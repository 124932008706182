import Proptypes from 'prop-types';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import Scrollbar from '../Scrollbar';
import { Eye as EyeIcon } from '../../icons/eye';
import { Status } from '../status';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'code',
    label: 'Codigo'
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'type',
    label: 'Tipo'
  },
  {
    id: 'permisson',
    label: 'Permiso'
  },
  {
    id: 'query',
    label: 'Query'
  },
  {
    id: 'parameters',
    label: 'Parametros'
  },
  {
    id: 'active',
    label: 'Estado'
  },
  {
    id: 'executions',
    label: 'Ejecuciones'
  },
  {
    id: 'last_execution',
    label: 'Ultima ejecucion'
  },
  {
    id: 'created_by',
    label: 'Creado por'
  },
  {
    id: 'updated_by',
    label: 'Actualizado por'
  },
  {
    id: 'updated_at',
    label: 'Actualizado'
  }
];
const statusLines = [
  { color: 'info.main', label: 'Activo', value: 'Y' },
  { color: 'error.main', label: 'Inactivo', value: 'N' }
];

export const DexReportsTable = (props) => {
  const {
    error,
    reports = [],
    reportsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedReports = [],
    sort = 'desc',
    sortBy = 'ItemName',
    setCurrentLine,
    handleOpenEditDialog
  } = props;
  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !reports?.length);
  const handleOpenDialog = (item) => {
    setCurrentLine?.(item);
    handleOpenEditDialog?.();
  };

  return (
    <Card variant="outlined">
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <Scrollbar>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={reports.length > 0 && selectedReports.length === reports.length}
                    disabled={isLoading}
                    indeterminate={
                      selectedReports.length > 0 && selectedReports.length < reports.length
                    }
                    onChange={onSelectAll}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {reports?.map((item) => {
                const statusline = statusLines.find((variant) => variant.value === item.active);

                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={
                      !!selectedReports.find((selectedVehicle) => selectedVehicle === item.id)
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          !!selectedReports.find((selectedVehicle) => selectedVehicle === item.id)
                        }
                        onChange={(event) => onSelect(event, item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component="button"
                        onClick={() => {
                          handleOpenDialog(item);
                        }}
                        underline="hover"
                        variant="subtitle2"
                      >
                        <EyeIcon />
                        {item.id.substring(0, 8)}
                      </Link>
                    </TableCell>

                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {item.type}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {item.typeName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body2">
                        {item.permission}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body2">
                        {
                          // if length is greater than 20, then show the first 20 characters and add '...'
                          (item.query ?? '').length > 20
                            ? `${item.query.substring(0, 20)}...`
                            : item.query
                        }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body2">
                        {item.parameter}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Status color={statusline?.color} label={statusline?.label} />
                    </TableCell>
                    <TableCell>{item.executions}</TableCell>
                    <TableCell>{item.last_execution}</TableCell>
                    <TableCell>{item.created_by}</TableCell>
                    <TableCell>{item.updated_by}</TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body2">
                        {item.updated_at}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
        {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {displayUnavailable && (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={isLoading}
          onPageChange={onPageChange}
          page={page}
          rowsCount={reportsCount}
        />
      </Box>
    </Card>
  );
};

DexReportsTable.propTypes = {
  reports: Proptypes.array,
  reportsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedReports: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  setCurrentLine: Proptypes.func,
  handleOpenEditDialog: Proptypes.func
};

import { useState } from 'react';
import { toBlob } from '../services/gadmin';
import { useApp } from './use-app';

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  // getFileName,
  apiParameters
}) => {
  const [url, setFileUrl] = useState();
  const [name, setFileName] = useState();
  const { showError } = useApp();

  const download = async () => {
    try {
      preDownloading?.();
      const result = await apiDefinition(apiParameters);
      if (result.success) {
        if (result.data === null) {
          showError('No hay datos');
          return;
        }
        const { content, filename, mimetype } = result.data;
        if (content) {
          const blob = toBlob(content, mimetype);
          const url = URL.createObjectURL(blob);
          setFileUrl(url);
          setFileName(filename);
          const link = document.createElement('a');
          link.download = filename;
          link.href = url;
          link.click();
          URL.revokeObjectURL(url);
        } else {
          onError?.(`No hay contenido. ${result.message}`);
        }
      } else {
        showError(result?.message ?? 'Error al descargar archivo');
        onError?.(result.message);
      }
      postDownloading?.();
    } catch (error) {
      console.error(error);
      onError?.();
    }
  };

  return { download, url, name };
};

import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Skeleton, Typography } from '@mui/material';
import { invoiceItemApi } from '../../api/invoice-item';
import { InvoiceDetails } from '../../components/purchases/invoices-service/invoice-details';
import { InvoiceLineItems } from '../../components/purchases/invoices-service/invoice-line-items';
// import { InvoicePayment } from '../components/invoices/invoice-payment';
// import { InvoicePaymentHistory } from '../components/invoices/invoice-payment-history';
// import { InvoiceSendNotification } from '../components/invoices/invoice-send-notification';
import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../icons/arrow-left';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../icons/exclamation-outlined';
import { Eye as EyeIcon } from '../../icons/eye';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { InvoiceEdit } from '../../components/purchases/invoices-service/invoice-edit';
import { DocumentReference } from '../../components/purchases/common/document-reference';
import { DocumentLog } from '../../components/purchases/common/document-log';
import { useDialog } from '../../hooks/use-dialog';
import { PDFViewerdDialog } from '../../components/common/pdf-viewer-dialog';
import { mediaType } from '../../api/purchase-definitions';
import { AllowedGuard } from '../../hooks/use-allowed';
import { useData } from '../../hooks/use-data';
import GadminActions from '../../contexts/gadmin-actions';
import { XMLViewerdDialog } from '../../components/common/xml-viewer-dialog';
import { JournalPrintingDialog } from '../../components/purchases/printing/journal-printing-dialog';

export const InvoiceItem = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();
  const [editMode, setEditmode] = useState(false);
  const [isNote, setIsNote] = useState(true);
  // const [appendDialogOpen, handleOpenAppendDialog, handleCloseAppendDialog] = useDialog();
  const [pdfViewerDialogOpen, handleOpenPdfViewerDialog, handleClosePdfViewerDialog] = useDialog();
  const [xmlViewerDialogOpen, handleOpenXmlViewerDialog, handleCloseXmlViewerDialog] = useDialog();
  const [
    journalPrintingDialogOpen,
    handleOpenJournalPrintingDialog,
    handleCloseJournalPrintingDialog
  ] = useDialog();

  const [invoiceState, handleRefresh] = useData({
    sourceApi: invoiceItemApi.getInvoice,
    apiParameter: { docEntry: id },
    loadingMessage: 'Cargando detalle del documento',
    mounted
  });
  useEffect(() => {
    setIsNote(false);
  }, []);
  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!invoiceState.data) return;
    if (['D'].includes(invoiceState.data.status)) {
      setEditmode(true);
    }
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => {
    if (invoiceState.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (invoiceState.error || !invoiceState.data) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              {invoiceState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to={`${appUrlBase}/purchases`}
              variant="text"
            >
              comprobante
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              #{invoiceState.data?.document}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="primary"
                // component={RouterLink}
                size="large"
                startIcon={<EyeIcon />}
                onClick={handleOpenPdfViewerDialog}
                variant="contained"
              >
                PDF
              </Button>

              {invoiceState.data?.transId !== -1 ? (
                <Button
                  color="primary"
                  // component={RouterLink}
                  size="large"
                  startIcon={<EyeIcon />}
                  onClick={handleOpenJournalPrintingDialog}
                  variant="contained"
                >
                  Asiento
                </Button>
              ) : (
                ''
              )}
              <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_DOWNLOAD_XML}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<EyeIcon />}
                  onClick={handleOpenXmlViewerDialog}
                  variant="contained"
                >
                  XML
                </Button>
              </AllowedGuard>
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              >
                Refresh
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={invoiceState.data?.statusName} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              {editMode ? (
                <InvoiceEdit invoice={invoiceState.data} setEditMode={setEditmode} />
              ) : (
                <InvoiceDetails invoice={invoiceState.data} setEditMode={handleEdit} />
              )}
            </Grid>
            <Grid item xs={12}>
              {isNote ? <DocumentReference invoice={invoiceState.data} /> : ''}
            </Grid>
            <Grid item xs={12}>
              {/* <InvoicePayment invoice={invoiceState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              <InvoiceLineItems invoice={invoiceState.data} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <DocumentLog
                document={{
                  objType: invoiceState.data.objType,
                  docEntry: invoiceState.data.docEntry
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={4}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
          >
            <Grid item xs={12}>
              {/* <InvoiceSendNotification invoice={invoiceState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <InvoicePaymentHistory /> */}
            </Grid>
          </Grid>
        </Grid>
        <PDFViewerdDialog
          onClose={handleClosePdfViewerDialog}
          open={pdfViewerDialogOpen}
          apiHandle={invoiceItemApi.getInvoiceMedia}
          apiParams={{
            objType: invoiceState.data?.objType,
            docEntry: invoiceState.data?.docEntry,
            type: mediaType.PDF
          }}
        />
        <XMLViewerdDialog
          onClose={handleCloseXmlViewerDialog}
          open={xmlViewerDialogOpen}
          apiHandle={invoiceItemApi.getInvoiceMedia}
          apiParams={{
            objType: invoiceState.data?.objType,
            docEntry: invoiceState.data?.docEntry,
            type: mediaType.XML
          }}
        />
        <JournalPrintingDialog
          onClose={handleCloseJournalPrintingDialog}
          open={journalPrintingDialogOpen}
          entity={{
            objType: invoiceState.data?.objType,
            docEntry: invoiceState.data?.docEntry,
            transId: invoiceState.data?.transId
          }}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de comprobante')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

import { useEffect } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useApp } from '../../../hooks/use-app';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import gtm from '../../../lib/gtm';
import { Helmet } from 'react-helmet-async';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import QueuesTable from '../../../components/automations/queues/queues-table';
import QueuesFilter from '../../../components/automations/queues/queues-filter';
import LoadingContent from '../../../components/common/loading-content';
import { bulksApi } from '../../../api/managment/bulks';

const QueuesContainer = () => {
  const mounted = useMounted();
  const { title, currentCompany } = useApp();

  const [
    queuesState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
  ] = useDataBrowser({
    sourceApi: bulksApi.getQueues,
    loadingMessage: 'Cargando Queues...',
    mounted,
    defaults: {
      view: 'all',
      companyId: currentCompany
    }
  });

  const [selectedQueues, , , handleClearSelected] = useSelection(queuesState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    handleClearSelected();
  }, [queuesState.documents]);

  const renderContent = () => {
    if (!controller.refresh && (queuesState.isLoading || queuesState.error)) {
      return <LoadingContent loadingText={queuesState.message} error={queuesState.error} />;
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Queues
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.G2_QUEUE_LIST}>
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              >
                Refresh
              </Button>
            </AllowedGuard>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <QueuesFilter
                disabled={queuesState.isLoading}
                filters={controller.filters}
                onFiltersApply={handleFiltersApply}
                onFiltersClear={handleFiltersClear}
                onQueryChange={handleQueryChange}
                onViewChange={handleViewChange}
                query={controller.query}
                selectedQueues={selectedQueues}
                view={controller.view}
              />
              <QueuesTable
                error={queuesState.error}
                queues={queuesState.documents}
                queuesCount={queuesState.documentsCount}
                isLoading={queuesState.isLoading}
                onPageChange={handlePageChange}
                onSortChange={handleSortChange}
                page={controller.page + 1}
                sort={controller.sort}
                sortBy={controller.sortBy}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Queues - Lista')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

export default QueuesContainer;

import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import numeral from 'numeral';
import { Box } from '@mui/system';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { NumberColor } from '../../number-color';

export const PromoAssignedHeadersTable = (props) => {
  const { promoAssigned } = props;
  if (!promoAssigned.headers) return <LoadingContent loadingText="No hay información" />;

  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Promoción</TableCell>
              <TableCell>Canal/Cliente</TableCell>
              <TableCell>Cada/Mayor</TableCell>
              <TableCell>Evaluar</TableCell>
              <TableCell>Disponible</TableCell>
              <TableCell>Calculado</TableCell>
              <TableCell>Bonificado</TableCell>
              <TableCell>Valor Mínimo</TableCell>
              <TableCell>Tipo Boni</TableCell>
              <TableCell>Valor Boni</TableCell>
              <TableCell>Prct Boni</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Comprado</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Excluido por</TableCell>
              <TableCell>Calculado</TableCell>
              <TableCell>Ambito</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promoAssigned.headers?.map((item) => (
              <TableRow key={item.promoId}>
                <TableCell>{numeral(item.promoId).format('0')}</TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.boniType}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.promoName}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.canal}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.cardCode}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.evalFactor}</TableCell>
                <TableCell>{item.evalType}</TableCell>
                <TableCell>
                  <NumberColor vaue={item.avalible} format="0.0" />
                </TableCell>
                <TableCell>
                  <NumberColor vaue={item.calculated} format="0.0" />
                </TableCell>
                <TableCell>
                  <NumberColor vaue={item.bonified} format="0.0" />
                </TableCell>
                <TableCell>
                  <NumberColor vaue={item.minValue} format="0.0" />
                </TableCell>
                <TableCell>{item.boniType}</TableCell>
                <TableCell>
                  <NumberColor vaue={item.bonified} format="0.00" />
                </TableCell>
                <TableCell>
                  <NumberColor vaue={item.boniPercent} format="0.00" />
                </TableCell>
                <TableCell>
                  <NumberColor vaue={item.price} format="0.00" />
                </TableCell>
                <TableCell>
                  <NumberColor vaue={item.Purchased} format="0.00" />
                </TableCell>
                <TableCell>{item.PromoType}</TableCell>
                <TableCell>{item.ExcludedBy}</TableCell>
                <TableCell>{item.ScopeCalculated}</TableCell>
                <TableCell>{item.BoniScope}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

PromoAssignedHeadersTable.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};

import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LoadingContent from '../loading-content';
import { mediaType } from '../../../api/purchase-definitions';
import PDFView from '../../pdf-viewer';

export const PDFBody = (props) => {
  const { fileName, fileType, content, loading = false } = props;

  if (loading) {
    return <LoadingContent loadingText="Cargando reporte" />;
  }
  if (!content || fileType !== mediaType.PDF) return <Box>Sin contenido</Box>;

  return <PDFView content={content} fileName={fileName} />;
};

PDFBody.propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  content: PropTypes.string,
  loading: PropTypes.bool
};

import PropTypes from 'prop-types';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Query } from '../../components/query';

export const SalesTeamsListFilter = (props) => {
  const {
    disabled,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = 'all'
  } = props;

  const PromotionsViews = [
    {
      label: 'Todos',
      value: 'all'
    },
    {
      label: 'Activos',
      value: 'A'
    },
    {
      label: 'Inactivos',
      value: 'I'
    }
  ];

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {PromotionsViews.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
        </Box>
      </div>
    </>
  );
};

SalesTeamsListFilter.propTypes = {
  disabled: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.string
};

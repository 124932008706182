import Proptypes from 'prop-types';
import { Box, Button, DialogActions, DialogTitle, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { InputField } from '../../input-field';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { AutocompleteField } from '../../autocomplete-field';

export const SalesTeamsZonesForm = ({ title, initialValues = {}, onClose }) => {
  const { currentCompany } = useApp();

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany,
      sales_team_id: initialValues.sales_team_id || '',
      territoryInfo: initialValues.territoryInfo || '',
      territory_id: initialValues.territory_id || '',
      name: initialValues.name || '',
      price_list_id: initialValues.price_list_id || '',
      active: initialValues.active || 'Y'
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log('values', values);
    }
  });

  const salesTeamsData = [
    {
      id: 1,
      name: 'Data 1',
      code: 'data1'
    },
    {
      id: 2,
      name: 'Data 2',
      code: 'data2'
    },
    {
      id: 3,
      name: 'Data 3',
      code: 'data3'
    }
  ];

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2,
            padding: 5
          }}
        >
          <Grid item xs={12}>
            <PropertyList>
              <InputField
                label="Nombre de zona"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                sx={{ marginBottom: '1em' }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <AutocompleteField
                label="Zona"
                name="territoryInfo"
                placeholder="Seleccione un territorio"
                fullWidth
                options={salesTeamsData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('territory_id', value?.id);
                  formik.setFieldValue('territoryInfo', value);
                }}
                value={formik.values.territoryInfo}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                select
                label="Lista de precios predetermida "
                name="price_list_id"
                value={formik.values.price_list_id}
                onChange={formik.handleChange}
                fullWidth
                sx={{ marginBottom: '1em' }}
              >
                {salesTeamsData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </InputField>
            </PropertyList>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

SalesTeamsZonesForm.propTypes = {
  initialValues: Proptypes.object,
  //   update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string
};

import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { bulksApi } from '../../../api/managment/bulks';
import { useApp } from '../../../hooks/use-app';
import { Helmet } from 'react-helmet-async';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Scrollbar from '../../../components/Scrollbar';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../../components/common/loading-content';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';

const statusLogs = [
  { color: 'info', value: 'info' },
  { color: 'warning', value: 'warning' },
  { color: 'success', value: 'success' },
  { color: 'error', value: 'error' }
];

const QueueDetailContainer = () => {
  const { id } = useParams();
  const { currentCompany, title } = useApp();
  const mounted = useMounted();

  const [data, setData] = useState(null);
  const [messages, setMessages] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const copyPayloadToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(data.payload));
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [documentState, handleRefresh] = useData({
    sourceApi: bulksApi.getQueue,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: 'Cargando detalle de procesos masivos',
    mounted
  });

  useEffect(() => {
    if (documentState.success) {
      setData(documentState.data);
      if (documentState.data.messages.length > 0) {
        setMessages(documentState.data.messages);
      }
    }
  }, [documentState]);

  if (documentState.isLoading || documentState.error || !documentState.data) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <>
      <Helmet>
        <title>{title('Detalle del Queue')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Detalle del Queue con id {id}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={() => handleRefresh({ companyId: currentCompany, id })}
                  variant="contained"
                >
                  Refresh
                </Button>
              </AllowedGuard>
            </Box>
          </Box>

          {data ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={6} xl={4}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h6">Detalle</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table>
                      <TableBody>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">ID bulk:</Typography>
                          </TableCell>
                          <TableCell>{data.bulkId}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">ID Company:</Typography>
                          </TableCell>
                          <TableCell>{data.companyId}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Nombre del objeto:</Typography>
                          </TableCell>
                          <TableCell>{data.ObjectName}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Código de objeto:</Typography>
                          </TableCell>
                          <TableCell>{data.ObjectCode}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Descripción:</Typography>
                          </TableCell>
                          <TableCell>{data.description}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Creado en:</Typography>
                          </TableCell>
                          <TableCell>{data.created_at}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Actualizado en:</Typography>
                          </TableCell>
                          <TableCell>{data.updated_at}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Creado por:</Typography>
                          </TableCell>
                          <TableCell>{data.created_by}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Creado por el usuario:</Typography>
                          </TableCell>
                          <TableCell>{data.created_by_user}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Job:</Typography>
                          </TableCell>
                          <TableCell>{data.job}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Nombre de usuario:</Typography>
                          </TableCell>
                          <TableCell>{data.UserName}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">ID de usuario:</Typography>
                          </TableCell>
                          <TableCell>{data.UserId}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Nombre de la aplicación:</Typography>
                          </TableCell>
                          <TableCell>{data.AppName}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Intentos:</Typography>
                          </TableCell>
                          <TableCell>{data.attemps}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Intentos máximos:</Typography>
                          </TableCell>
                          <TableCell>{data.max_attemps}</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell sx={{ paddingLeft: '8px' }}>
                            <Typography variant="subtitle1">Procesada:</Typography>
                          </TableCell>
                          <TableCell>{data.processed}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg={6} xl={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="logs-content"
                        id="logs-header"
                      >
                        <Typography variant="h6">Logs</Typography>
                      </AccordionSummary>
                      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <Scrollbar>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="subtitle1">Creado en:</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="subtitle1">Tipo:</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="subtitle1">Mensaje:</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {messages?.map((message, index) => {
                                const StatusType = statusLogs.find(
                                  (status) => status.value == message.type
                                );
                                return (
                                  <TableRow key={index} hover>
                                    <TableCell>{message.created_at}</TableCell>
                                    <TableCell>
                                      <Chip
                                        label={message.type}
                                        color={StatusType.color}
                                        variant="outlined"
                                      />
                                    </TableCell>
                                    <TableCell>{message.message}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Scrollbar>
                      </Box>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="payload-content"
                        id="payload-header"
                      >
                        <Typography variant="h6">Payload</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row-reverse' }}>
                          <Tooltip title="Copiar">
                            <IconButton onClick={copyPayloadToClipboard}>
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>

                          <Snackbar
                            open={openSnackbar}
                            autoHideDuration={3000}
                            onClose={handleCloseSnackbar}
                            message="Contenido copiado"
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                          <Scrollbar>{JSON.stringify(data.payload)}</Scrollbar>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography>Na hay registros</Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default QueueDetailContainer;

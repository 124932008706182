import orderListIcon from '@iconify/icons-ant-design/ordered-list';
// material
import { Box, Button, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { dashboardApi } from '../../../api/dashboard';
import LoadingContent from '../../common/loading-content';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { Widget } from '../../common/widget';
import { IconWrapper } from '../../common/icon-wrapper';

export default function PreVentaOrderPendigs() {
  const mounted = useMounted();

  const [openOrdersStatics, handleRefresh] = useData({
    sourceApi: dashboardApi.getOrdersPendings,
    loadingMessage: 'Cargando pedidos pendientes',
    mounted
  });

  if (openOrdersStatics.isLoading || openOrdersStatics.error || !openOrdersStatics.data) {
    return (
      <LoadingContent loadingText={openOrdersStatics.message} error={openOrdersStatics.error} />
    );
  }
  return (
    <Widget>
      <Box
        md={6}
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          }
        }}
      >
        {' '}
        <IconWrapper icon={orderListIcon} />
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Ordenes Pendientes
        </Typography>
        <Button
          color="primary"
          size="medium"
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          variant="text"
          sx={{ top: '0' }}
        />
      </Box>
      <Typography variant="h5">{`PV - ${fShortenNumber(
        openOrdersStatics.data?.presale || 0
      )}`}</Typography>
      <Typography variant="h5">{`VO - ${fShortenNumber(
        openOrdersStatics.data?.office || 0
      )}`}</Typography>
    </Widget>
  );
}

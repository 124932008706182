import Proptypes from 'prop-types';
import { Box } from '@mui/material';
import { useCheckBoxEdit, useInputEdit, useSelectInput } from '../edit-line-hook';
import { Check } from '../../icons/check';
import { XCircle } from '../../icons/x-circle';

export const TablesEditLine = ({
  type,
  item,
  data = [],
  handleClose,
  typeInput = 'text',
  handleEdit = () => {}
}) => {
  const { value, SelectInput } = useSelectInput(item, data);
  const { valueCheck, CheckboxInput } = useCheckBoxEdit(item === 'Y');
  const { valueInput, InputTypeField } = useInputEdit(item, typeInput);

  console.log('value', value);
  console.log('valueCheck', valueCheck);
  console.log('valueInput', valueInput);

  return (
    <>
      {type === 'select' && SelectInput}
      {type === 'check' && CheckboxInput}
      {type === 'input' && InputTypeField}
      <Box>
        <Check
          sx={{
            cursor: 'pointer'
          }}
          color="secondary"
          onClick={handleEdit}
        />
        <XCircle
          sx={{
            cursor: 'pointer'
          }}
          color="primary"
          onClick={handleClose}
        />
      </Box>
    </>
  );
};

TablesEditLine.propTypes = {
  type: Proptypes.string,
  item: Proptypes.oneOfType([Proptypes.string, Proptypes.number]).isRequired,
  data: Proptypes.array,
  handleClose: Proptypes.func,
  handleEdit: Proptypes.func,
  typeInput: Proptypes.string
};

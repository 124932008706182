import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { ReceiptFeesTable } from './receipt-fees-table';

export const ReceiptFeesLineItems = (props) => {
  const { invoice } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Líneas" />
      <Divider />
      <ReceiptFeesTable invoice={invoice} />
    </Card>
  );
};

ReceiptFeesLineItems.propTypes = {
  invoice: PropTypes.object.isRequired
};

import { useParams } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { useSplit } from '../../../hooks/use-split';
import { BusinessPartnersTablesTable } from '../component/business-partners-tables-table';

const BusinessPartnerTables = () => {
  const { cardcode: cardcodeQuery } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { code: cardcode } = useSplit(cardcodeQuery);

  const [documentState, handleRefresh] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getSummaryId,
    apiParameter: { companyId: currentCompany, id: cardcode },
    loadingMessage: 'Cargando detalle del resumen',
    mounted
  });
  const data = [
    {
      id: 1,
      home: '001',
      table: '029:GERMÁNICA',
      zone: 'NAZCA T',
      routes: 'VISTA ALEGRE 2T',
      seller: 'DE LA TORRE VILCARIMA, GIULIANO (1959)',
      visit_day: 'JUEVES',
      channel: 'MINORISTA',
      active: 'Y',
      frequency: 'SEMANAL',
      warehouse: '11ICPR1'
    },
    {
      id: 2,
      home: '002',
      table: '029:GERMÁNICA',
      zone: 'NAZCA T',
      routes: 'VISTA ALEGRE 2T',
      seller: 'DE LA TORRE VILCARIMA, GIULIANO (1959)',
      visit_day: 'JUEVES',
      channel: 'MINORISTA',
      active: 'N',
      frequency: 'MENSUAL',
      warehouse: '11ICPR1'
    }
  ];

  return (
    <BusinessPartnersTablesTable
      documentState={documentState}
      onRefresh={() => handleRefresh({ companyId: currentCompany, id: cardcode })}
      documents={data}
    />
  );
};

export { BusinessPartnerTables };

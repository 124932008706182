const FePendingsView = {
  VIEW_PENDING_SAP: 'pending-sap',
  VIEW_PENDING_FACELE: 'pending-facele',
  VIEW_PENDING_SUNAT: 'pending-sunat',
  VIEW_PENDING_SUNAT_REJECTED: 'pending-sunat-rejected',
  VIEW_PENDING_SUNAT_ACCEPTED: 'pending-sunat-accepted',
  VIEW_PENDING_NONE: 'none'
};

export { FePendingsView };

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../icons/adjustments';
import { deliveryVehicleApi } from '../../api/delivery-vehicle';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../utils/filter-operators';
import { Query } from '../query';
import { FilterDialog } from '../filter-dialog';
import GadminActions from '../../contexts/gadmin-actions';
import { DeliveryVehicleDriverActions } from '../../api/delivery-definitions';
import { BulkActionsMenu } from '../bulk-actions-menu';
import { deliveryVehicleDriverApi } from '../../api/delivery-vehicle-driver';

const views = [
  {
    label: 'Mostrar todos',
    value: 'all'
  }
];

const filterProperties = [
  {
    name: 'code',
    label: 'Código',
    type: 'string'
  },
  {
    name: 'name',
    label: 'Nombre',
    type: 'string'
  },
  {
    name: 'type',
    label: 'Typo',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const GexReportsFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    onRefresh,
    query,
    selectedReports = [],
    view = 'all'
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [menuActions, setMenuActions] = useState([]);
  // const afterProcessHandler = (menuItem, result) => {
  const afterProcessHandler = () => {
    // if (result) {
    //   if (menuItem.action === DeliveryVehicleActions.CANCEL) {
    //     onFiltersApply();
    //   }
    // }
    // }
    onRefresh();
  };
  useEffect(() => {
    setMenuActions([]);
    if (['all'].includes(view)) {
      setMenuActions((items) => [
        ...items,
        {
          action: DeliveryVehicleDriverActions.ACTIVATE_REPORTS,
          permission: GadminActions.gCC_DELIVERY_CHOFERES_UP,
          label: 'Activa vehículo',
          prompt: '¿Está seguro que desea activar los choferes seleccionados?',
          apiHandler: deliveryVehicleDriverApi.bulkDeliveryVehicleDriversActions
        },
        {
          action: DeliveryVehicleDriverActions.DEACTIVATE_REPORTS,
          permission: GadminActions.gCC_DELIVERY_CHOFERES_UP,
          label: 'Desactivar vehículo',
          prompt: '¿Está seguro que desea inactivar los choferes seleccionados?',
          apiHandler: deliveryVehicleDriverApi.bulkDeliveryVehicleDriversActions
        }
      ]);
    }
  }, [view]);

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedReports.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <BulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            selectedItems={selectedReports}
            selectedCount={selectedReports.length}
            afterProcessHandler={afterProcessHandler}
            apiHandler={deliveryVehicleApi.bulkDeliveryVehiclesActions}
            sx={{
              display: selectedReports.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          />
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

GexReportsFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onRefresh: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedReports: PropTypes.array,
  view: PropTypes.string
};

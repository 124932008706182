import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import UserReducer, { InitialtUser } from './user-reducer';

const UserContext = createContext();
const UserProvider = ({ children }) => {
  const [userState, userDispacher] = useReducer(UserReducer, InitialtUser);

  const { userInfo } = userState;
  return (
    <UserContext.Provider
      // value={{ name, idUser, email, avatar, inSession, registerCurrentUser }}
      value={{ userState, userDispacher, userInfo }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = { children: PropTypes.node.isRequired };

const WithUser = (Child) => (props) =>
  (
    <UserContext.Consumer>
      {(context, i) => <Child key={i} {...props} userContext={context} />}
    </UserContext.Consumer>
  );

export { UserProvider, WithUser, UserContext };

import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import { Plus as PlusIcon } from '../../icons/plus';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';

import LoadingContent from '../../components/common/loading-content';
import { productApi } from '../../api/product';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { useTabs } from '../../hooks/use-tabs';

const defaultTabs = [
  {
    href: '',
    label: 'Resumen',
    permission: GadminActions.gCC_INV_ITEM
  },
  {
    href: '/summary2',
    label: 'Resumen2',
    permission: GadminActions.gCC_INV_ITEM
  },
  {
    href: '/accounting',
    label: 'Contabilidad',
    permission: GadminActions.gCC_INV_ITEM_UP_CNT
  },
  {
    href: '/gallery',
    label: 'Galeria',
    permission: GadminActions.gCC_INV_ITEM
  },
  {
    href: '/prices',
    label: 'Precios',
    permission: GadminActions.gCC_ITEM_PRICE_LIST
  },
  {
    href: '/stock',
    label: 'Stock',
    permission: GadminActions.gCC_INV_AVAL
  },
  {
    href: '/costs',
    label: 'Costos',
    permission: GadminActions.gCC_MARGIN_DET
  },
  {
    href: '/measures',
    label: 'Presentaciones',
    permission: GadminActions.gCC_INV_ITEM_UP_CNT
  },
  {
    href: '/teams',
    label: 'Mesas',
    permission: GadminActions.gCC_INV_ITEM
  },
  {
    href: '/attributes',
    label: 'Atributos',
    permission: GadminActions.gCC_INV_ITEM
  }
];

export const Product = () => {
  const app = useApp();
  const navigate = useNavigate();

  const { itemcode } = useParams();

  const mounted = useMounted();
  const { appUrlBase } = useApp();

  const [documentState] = useData({
    sourceApi: productApi.getProductInfo,
    apiParameter: itemcode,
    loadingMessage: 'Cargando información del producto',
    mounted
  });

  const handleCreate = () => {
    navigate(`${appUrlBase}/inventory/products/new`, {
      replace: true
    });
  };

  const { renderTabs } = useTabs({
    basePath: `${appUrlBase}/inventory/products/${itemcode}`,
    tabs: defaultTabs
  });

  const renderContent = () => {
    if (documentState.isLoading || documentState.error || !documentState.data) {
      return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
    }
    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              {documentState.data.itemCode}-{documentState.data.itemName}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <AllowedGuard permission={GadminActions.gCC_DOC_SUCCESS_ADD}>
                <Box sx={{ mb: 2 }}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    onClick={handleCreate}
                    variant="contained"
                  />
                </Box>
              </AllowedGuard>
            </Box>
          </Box>
          {renderTabs()}
        </Box>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{app.title('Información del producto')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

const CustomerReturnBulkAction = {
  GENERATE: 'Generate',
  AUTHORIZE: 'Auhthorize',
  CANCEL: 'Anulados',
  PROGRAM: 'Program',
  LIQUIDATE: 'Liquidate',
  REACTIVATE: 'Reactivate',
  UNPROGRAM: 'UnProgram'
};

const CustomerReturnStatusCode = {
  DRAFT: 'D',
  AUTHORIZED: 'L',
  PROGRAMMED: 'F',
  IN_DELIVERY: 'W',
  LIQUIDED: 'Q',
  CLOSED: 'C',
  CANCELED: 'E',
  CONTABILIZED: 'T',
  PREPAID: 'R'
};

const CustomerReturnViews = {
  DRAFT: 'D',
  AUTHORIZED: 'L',
  PROGRAMMED: 'F',
  IN_DELIVERY: 'W',
  LIQUIDED: 'Q',
  CLOSED: 'C',
  CANCELED: 'E',
  CONTABILIZED: 'T',
  PREPAID: 'R',
  ALL: 'all'
};

export { CustomerReturnBulkAction, CustomerReturnStatusCode, CustomerReturnViews };

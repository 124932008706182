import { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import PropTypes from 'prop-types';
// import { useMounted } from '../hooks/use-mounted';

const qrcodeRegionId = 'html5qr-code-full-region';

const CodeReaderPlugin = ({
  qrCodeSuccessCallback,
  qrCodeErrorCallback,
  verbose,
  ...properties
}) => {
  // const mounted = useMounted();
  const createConfig = (props) => {
    const config = {};
    if (props.fps) {
      config.fps = props.fps;
    }
    if (props.qrbox) {
      config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
      config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
      config.disableFlip = props.disableFlip;
    }
    return config;
  };

  const config = createConfig(properties);

  // Suceess callback is required.
  if (!qrCodeSuccessCallback) {
    // eslint-disable-next-line no-throw-literal
    throw 'qrCodeSuccessCallback is required callback.';
  }

  let html5QrcodeScanner;
  useEffect(() => {
    html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose === true);
    html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error('Failed to clear html5QrcodeScanner. ', error);
      });
    };
  }, []);

  return <div id={qrcodeRegionId} />;
};
CodeReaderPlugin.propTypes = {
  verbose: PropTypes.bool,
  disableFlip: PropTypes.bool,
  qrCodeSuccessCallback: PropTypes.func,
  qrCodeErrorCallback: PropTypes.func,
  fps: PropTypes.number,
  qrbox: PropTypes.number,
  aspectRatio: PropTypes.number
};
export default CodeReaderPlugin;

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 7,
    fontStyle: 'bold'
  },
  memo: {
    width: '55%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8
  },
  value: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8
  },
  account: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 6,
    paddingRight: 8
  },
  amount: {
    width: '10%',
    textAlign: 'right',
    paddingRight: 8
  }
});

const CustomerReturnTableRow = ({ lines }) => {
  const rows = lines.map((item) => (
    <View style={styles.row} key={item.itemCode}>
      <Text style={styles.account}>{item.itemCode}</Text>
      <Text style={styles.memo}>{item.itemName}</Text>
      <Text style={styles.amount}>{numeral(item.quantity).format(`0,0`)}</Text>
      <Text style={styles.amount}>{numeral(item.priceAfterVat).format(`0,0.00`)}</Text>
      <Text style={styles.amount}>{numeral(item.gTotal).format(`0,0.00`)}</Text>
    </View>
  ));
  return <>{rows}</>;
};

CustomerReturnTableRow.propTypes = {
  lines: PropTypes.array
};
export default CustomerReturnTableRow;

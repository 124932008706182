import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Proptypes from 'prop-types';
import moment from 'moment';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Checkbox,
  TableSortLabel,
  Skeleton,
  Link
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { NumberColor } from '../../number-color';
import { useDialog } from '../../../hooks/use-dialog';
import { AuxiliaryCurrentEdit } from './auxiliary-edit';
import { useApp } from '../../../hooks/use-app';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'placa',
    label: 'Placa'
  },
  {
    id: 'carname',
    label: 'Jefe de reparto'
  },
  {
    id: 'sede',
    label: 'Zona'
  },
  {
    id: 'tiporeparto',
    label: 'División'
  },
  {
    id: 'nroauxpla',
    label: 'N auxiliares'
  },
  {
    id: 'pagoxapoyo',
    label: 'Costo'
  },
  {
    id: 'hojas',
    label: 'Hojas'
  },
  {
    id: 'clientes',
    label: 'Clientes'
  },
  {
    id: 'soles',
    label: 'Soles'
  },
  {
    id: 'fecha',
    label: 'Fecha'
  }
];

export const AuxiliariesCurrentTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'id',
    onRefresh
  } = props;
  const { appUrlBase } = useApp();

  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const [lineState, setLineState] = useState({});

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);
  const handleExitedDialog = () => {};

  const handleEdit = (item) => {
    setLineState(item);
    handleOpenEditDialog(true);
  };
  const detailRoute = `${appUrlBase}/delivery/auxiliary/hst-vehicle/`;
  const detailRouteDate = `${appUrlBase}/delivery/auxiliary/hst-date/`;

  if (!documents) {
    return <LoadingContent loadingText="No hay informacion del reparto..." />;
  }
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Planilla" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((item) => (
              <TableRow
                key={item.id}
                hover
                selected={!!selectedDocuments.find((selectedItem) => selectedItem === item.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={!!selectedDocuments.find((selectedItem) => selectedItem === item.id)}
                    onChange={(event) => onSelect(event, item.id)}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 90 }}>
                  <Link
                    color="inherit"
                    component="button"
                    onClick={() => {
                      handleEdit(item);
                    }}
                    underline="hover"
                    variant="body"
                  >
                    {item.id}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${detailRoute}${item.placa}`}
                    underline="none"
                    variant="body"
                  >
                    {item.placa}
                  </Link>
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.cardcode}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.cardname}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.sede}</TableCell>
                <TableCell>{item.tiporeparto}</TableCell>
                <TableCell>
                  <NumberColor value={item.nroauxpla} format="0" />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.pagoxapoyo} />
                </TableCell>
                <TableCell>{item.hojas}</TableCell>
                <TableCell>{item.clientes}</TableCell>
                <TableCell>
                  <NumberColor value={item.soles} format="0,000.00" minValue={5000} />
                </TableCell>
                <TableCell>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${detailRouteDate}${moment(item.fecha).format('YYYY-MM-DD')}`}
                    underline="none"
                    variant="body"
                  >
                    {moment(item.fecha).format('DD/MM/YYYY')}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
      <AuxiliaryCurrentEdit
        onClose={handleCloseEditDialog}
        onExited={handleExitedDialog}
        afterSubmit={onRefresh}
        open={editDialogOpen}
        lineState={lineState}
        refreshLine={setLineState}
        document={document}
      />
    </Card>
  );
};

AuxiliariesCurrentTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onRefresh: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

import { dataLoader } from '../../data-loader';

const syncSuppliers = async (params) => {
  const response = await dataLoader.loadGoalGetData({
    action: '/sync/master/suppliers',
    params
  });

  return response;
};

const syncAll = async ({ type, ...params }) => {
  if (!type || type === 'all') {
    return {
      success: false,
      message: 'No se puede sincronizar todo'
    };
  }

  const response = await dataLoader.loadGoalGetData({
    action: `/sync/master/${type}`,
    params
  });

  return response;
};

const cancelBulkSales = async () => {
  const response = await dataLoader.loadGoalGetData({
    action: '/automations/processors/cancel-bulk-sales'
  });

  return response;
};

export { syncSuppliers, cancelBulkSales, syncAll };

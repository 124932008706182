import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import { StatusBadge } from '../../status-badge';
import { customeOrderApi } from '../../../api/customer-order';
import { useMounted } from '../../../hooks/use-mounted';
import { CommonChart } from '../../common/common-chart';
import { useData } from '../../../hooks/use-data';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import barChartOutline from '@iconify/icons-eva/bar-chart-outline';
import { Icon } from '@iconify/react';

export const CustomerOrdersStats = ({ refresh }) => {
  const mounted = useMounted();
  const [dashBoards, setDashBoards] = useState([]);
  const [invoiceChart, setInvoiceChart] = useState(null);
  const [invoiceChartZone, setInvoiceChartZone] = useState(null);
  const [invoiceChartZoneOpen, setInvoiceChartZoneOpen] = useState(null);
  const [invoiceChartZoneNoInvoice, setInvoiceChartZoneNoInvoice] = useState(null);

  const [, handleRefresh] = useData({
    sourceApi: customeOrderApi.getOrdersStats,
    apiParameter: { type: 'status' },
    loadingMessage: 'Cargando estadísticas de pedidos',
    mounted,
    afterLoad: (result) => {
      setInvoiceChart(
        <CommonChart
          key={1}
          data={result?.data?.stats}
          isLoading={result.isLoading}
          error={result.error}
          message={result.message}
          title={`Pedidos digitados`}
          summary={result.data?.allCount}
        />
      );
    }
  });

  const [, handleRefreshZone] = useData({
    sourceApi: customeOrderApi.getOrdersStats,
    apiParameter: { type: 'zone' },
    loadingMessage: 'Cargando estadísticas de pedidos',
    mounted,
    afterLoad: (result) => {
      setInvoiceChartZone(
        <CommonChart
          key={2}
          data={result?.data?.stats}
          isLoading={result.isLoading}
          error={result.error}
          message={result.message}
          title={`Pedidos x Zona `}
          summary={result.data?.allCount}
        />
      );
    }
  });

  const [, handleRefreshZoneOpen] = useData({
    sourceApi: customeOrderApi.getOrdersStats,
    apiParameter: { type: 'zone-open' },
    loadingMessage: 'Cargando estadísticas de pedidos',
    mounted,
    afterLoad: (result) => {
      setInvoiceChartZoneOpen(
        <CommonChart
          key={3}
          data={result?.data?.stats}
          isLoading={result.isLoading}
          error={result.error}
          message={result.message}
          title={`Pendientes x zona`}
          summary={result.data?.allCount}
        />
      );
    }
  });

  const [, handleRefreshZoneNoInvoice] = useData({
    sourceApi: customeOrderApi.getOrdersStats,
    apiParameter: { type: 'zone-invoicing' },
    loadingMessage: 'Cargando estadísticas de pedidos',
    mounted,
    afterLoad: (result) => {
      setInvoiceChartZoneNoInvoice(
        <CommonChart
          key={4}
          data={result?.data?.stats}
          isLoading={result.isLoading}
          error={result.error}
          message={result.message}
          title={`Sin Facturar`}
          summary={result.data?.allCount}
        />
      );
    }
  });

  useEffect(() => {
    if (refresh) {
      handleRefresh();
      handleRefreshZone();
      handleRefreshZoneOpen();
      handleRefreshZoneNoInvoice();
    }
  }, [refresh]);

  useEffect(() => {
    setDashBoards(() => [
      invoiceChart,
      invoiceChartZone,
      invoiceChartZoneOpen,
      invoiceChartZoneNoInvoice
    ]);
  }, [invoiceChart, invoiceChartZone, invoiceChartZoneOpen, invoiceChartZoneNoInvoice]);

  return (
    <Card
      elevation={0}
      sx={{
        // backgroundColor: alpha(theme.palette.info.main, 0.1),
        mb: 2
      }}
    >
      <CardContent>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="orders-stats-content"
            id="orders-stats-content"
          >
            <Box sx={{ mr: 2 }}>
              <Icon icon={barChartOutline} />
            </Box>
            <Typography variant="h6">DashBoard</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {dashBoards.map((step, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  // xl={2}
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    flexDirection: {
                      sm: 'row',
                      xs: 'column'
                    },
                    justifyContent: 'center'
                    // alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      // height: 350,
                      // display: 'block',
                      // maxWidth: 400,
                      overflow: 'auto',
                      width: '100%'
                    }}
                  >
                    {step}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

CustomerOrdersStats.propTypes = {
  // onRefresh: PropTypes.func,
  refresh: PropTypes.bool
};

import PropTypes from 'prop-types';
import { Button, Card, CardHeader, Dialog, Divider, FormHelperText, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { compareRecordChanged } from '../../../hooks/use-item-change';
import { deliveryApi } from '../../../api/delivery';

export const AuxiliaryCurrentEdit = (props) => {
  const { open, onClose, onExited, afterSubmit, lineState, refreshLine, ...other } = props;

  const handleChanges = async (values) => {
    let saved = { success: false };
    try {
      const changes = compareRecordChanged(lineState, values);
      await deliveryApi.upAuxiliaryCurrent({
        id: lineState.id,
        ...changes
      });
      refreshLine({ ...lineState, ...changes });
      saved = { ...saved, success: true };
    } catch (error) {
      saved = { ...saved, error };
    }
    return saved;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: lineState?.id || -1,
      nroauxpla: lineState?.nroauxpla || 0,
      pagoxapoyo: lineState?.pagoxapoyo || 0,
      comments: lineState?.comments || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      comments: Yup.string().min(10, 'Mínimo 10 characteres').max(255),
      nroauxpla: Yup.number()
        .required('Mensage es requerido')
        .positive('Solo números positivos')
        .integer()
        .min(0)
        .max(lineState?.maxauxplae || 10),
      pagoxapoyo: Yup.number()
        .required('Mensage es requerido')
        .positive('Solo números positivos')
        .integer()
        .min(0)
        .max(100)
    }),
    onSubmit: async (values, helpers) => {
      try {
        const saved = await handleChanges(values);
        if (!saved.success) throw new Error(saved.error);
        toast.success(`Planilla actualizada`);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        afterSubmit();
        onClose();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 500,
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <Card variant="outlined">
        <CardHeader title={`Planilla de vehículo - ${lineState.placa}`} />
        <Divider />
        <form
          onSubmit={async (values, helpers) => {
            await formik.handleSubmit(values, helpers);
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6} xs={12}>
              <PropertyList>
                <PropertyListItem
                  label="Chofer"
                  value={`${lineState.cardcode} - ${lineState.cardname}`}
                />
                <AllowedGuard
                  permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP_UP}
                  component={
                    <PropertyListItem
                      label="# Auxiliares panilla"
                      value={`${lineState.nroauxpla}`}
                    />
                  }
                >
                  <PropertyListItem label="# Auxiliares panilla">
                    <InputField
                      error={Boolean(formik.touched.pagoxapoyo && formik.errors.nroauxpla)}
                      helperText={formik.touched.pagoxapoyo && formik.errors.pagoxapoyo}
                      name="nroauxpla"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nroauxpla}
                    />
                  </PropertyListItem>
                </AllowedGuard>
                <AllowedGuard
                  permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP_UP}
                  component={
                    <PropertyListItem label="Pago Aux Externo" value={`${lineState.nroauxpla}`} />
                  }
                >
                  <PropertyListItem label="Pago Aux Externo">
                    <InputField
                      error={Boolean(formik.touched.pagoxapoyo && formik.errors.pagoxapoyo)}
                      helperText={formik.touched.pagoxapoyo && formik.errors.pagoxapoyo}
                      name="pagoxapoyo"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.pagoxapoyo}
                    />
                  </PropertyListItem>
                </AllowedGuard>
                <PropertyListItem label="Comentario">
                  <InputField
                    error={Boolean(formik.touched.comments && formik.errors.comments)}
                    helperText={formik.touched.comments && formik.errors.comments}
                    name="comments"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.comments}
                  />
                </PropertyListItem>
                <PropertyListItem
                  label="Clientes / Soles"
                  value={`${lineState.clientes} / ${lineState.soles}`}
                />
              </PropertyList>
            </Grid>
            <Grid item md={8} xs={12}>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP_UP}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                >
                  Guardar
                </Button>
              </AllowedGuard>
              <Button
                color="secondary"
                size="large"
                startIcon={<TrashIcon />}
                onClick={async () => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Dialog>
  );
};

AuxiliaryCurrentEdit.propTypes = {
  lineState: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  refreshLine: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired
  // setEditMode: PropTypes.func.isRequired
};

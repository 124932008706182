import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { InputField } from '../../input-field';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import GadminActions from '../../../contexts/gadmin-actions';
import { Search } from '../../../icons/search';
import { useDialog } from '../../../hooks/use-dialog';
import { useApp } from '../../../hooks/use-app';
import { useSelection } from '../../../hooks/use-selection';
import { HRProgramedDialog } from '../hr/hr-programed-dialog';
import { GrReferenceTable } from './gr-reference-table';
import { GrItemsTable } from './gr-items-table';
import { GRDialog } from './gr-dialog';
import { goalCustomerPromotionsApi } from '../../../api/goal/promotions/goal-customer-promotions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { useMounted } from '../../../hooks/use-mounted';
import { QueryCustomerModal } from '../../../containers/promotions/query-customer-modal';
import { PropertyList } from '../../property-list';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import { RenderIf } from '../../render-if';
import { CustomerModal } from '../monitoring/customer-modal';
import BusinessPartnersForSelect from '../../busines-partner/component/business-partners-for-select';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const DeliveryCreateGrForm = ({ title = '', initialValues = {}, onClose }) => {
  const originList = [
    {
      id: 'HR',
      name: 'Hoja de reparto'
    },
    {
      id: 'E',
      name: 'Entrega'
    },
    {
      id: 'F',
      name: 'Factura'
    },
    {
      id: 'P',
      name: 'Pedido'
    },
    {
      id: 'T',
      name: 'Transferencia'
    },
    {
      id: 'L',
      name: 'Libre'
    }
  ];
  const brachList = [
    {
      id: 1,
      name: 'ICA'
    },
    {
      id: 2,
      name: 'LIMA'
    }
  ];
  const measurementList = [
    {
      id: 'UN',
      name: 'UNIDAD'
    },
    {
      id: 'K',
      name: 'KILOGRAMO'
    }
  ];
  const dataTable = {
    data: [
      {
        company_id: 1,
        id: 1,
        DocEntry: 1,
        CardCode: 'codigo 1',
        CardName: 'Nombre 1',
        DocDate: '2023-05-19',
        DocTotal: 1,
        DocStatus: 'estado',
        Address: 'direccion',
        Ubigeo: 'ubigeo',
        ObjType: 'tipo',
        ObjTypeName: 'Nombre Tipo'
      },
      {
        company_id: 2,
        id: 2,
        DocEntry: 2,
        CardCode: 'codigo 2',
        CardName: 'Nombre 2',
        DocDate: '2023-05-19',
        DocTotal: 2,
        DocStatus: 'estado',
        Address: 'direccion',
        Ubigeo: 'ubigeo',
        ObjType: 'tipo',
        ObjTypeName: 'Nombre Tipo'
      },
      {
        company_id: 3,
        id: 3,
        DocEntry: 3,
        CardCode: 'codigo 3',
        CardName: 'Nombre 3',
        DocDate: '2023-05-19',
        DocTotal: 3,
        DocStatus: 'estado',
        Address: 'direccion',
        Ubigeo: 'ubigeo',
        ObjType: 'tipo',
        ObjTypeName: 'Nombre Tipo'
      }
    ],
    isLoading: false,
    reload: false
  };

  const [selectData, setSelectData] = useState([]);
  const [disabledReference, setDisabledReference] = useState(true);
  const [valueForm, setValueForm] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    referenceError: null,
    rucError: null,
    startingError: null,
    endingError: null
  });
  const { showNotify, currentCompany } = useApp();
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const [editDialogOpen2, handleOpenEditDialog2, handleCloseEditDialog2] = useDialog();

  const [selectedItems, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    dataTable.data
  );
  const [selectedItemsRuc, handleSelectRuc, handleSelectAllRuc, handleClearSelectedRuc] =
    useSelection(dataTable.data);

  const [showInputBusinessPartner, setShowInputBusinessPartner] = useState(false);
  const [anchorElBusinessPartners, setAnchorElBusinessPartners] = useState(null);
  const menuOpenBusinessPartners = Boolean(anchorElBusinessPartners);
  const [openBusinessPartners, setOpenBusinessPartners] = useState(false);

  const handleExitedDialog = () => {};
  const handleExitedDialog2 = () => {};
  const handleSelectionConfirm = () => {
    if (!!selectedItems && selectedItems.length > 0) {
      console.log('selectedItems', selectedItems);
      console.log('selectData', selectData);
      handleCloseEditDialog();
    } else {
      showNotify('No se ha seleccionado filas', 'warning');
    }
  };
  const handleSelectionConfirm2 = () => {
    if (!!selectedItemsRuc && selectedItemsRuc.length > 0) {
      console.log('selectedItems', selectedItemsRuc);
      console.log('selectData', selectData);
      if (valueForm === 'ruc') {
        formik.setFieldValue('name', selectData[0].company_name);
        formik.setFieldValue(valueForm, selectData[0].company_id);
      }
      if (valueForm === 'ubigeo_starting') {
        formik.setFieldValue('address_starting', selectData[0].name);
        formik.setFieldValue(valueForm, selectData[0].vendorCode);
      }
      if (valueForm === 'ubigeo_ending') {
        formik.setFieldValue('address_ending', selectData[0].name);
        formik.setFieldValue(valueForm, selectData[0].vendorCode);
      }
      handleCloseEditDialog2();
    } else {
      showNotify('No se ha seleccionado filas', 'warning');
    }
  };

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany,
      // company_name: initialValues.company_name || '',
      origin: initialValues.origin || '',
      branch: initialValues.branch || '',
      ruc: initialValues.ruc || '',
      name: initialValues.name || '',
      placa: initialValues.placa || '',
      matricula: initialValues.matricula || '',
      dni: initialValues.dni || '',
      driver_name: initialValues.driver_name || '',
      ubigeo_starting: initialValues.ubigeo_starting || '',
      address_starting: initialValues.address_starting || '',
      ubigeo_ending: initialValues.ubigeo_ending || '',
      address_ending: initialValues.address_ending || '',
      reference: initialValues.reference || '',
      code_origin: initialValues.code_origin || '',
      name_origin: initialValues.name_origin || '',
      weight_origin: initialValues.weight_origin || 0,
      measurement_origin: initialValues.measurement_origin || '',
      amount_origin: initialValues.amount_origin || 0,
      business_partner_id: initialValues.business_partner_id || '',
      business_partner: initialValues.business_partner || {}
    },
    validationSchema: Yup.object().shape({
      company_id: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log(values);
    }
  });

  // const handleSearch = async () => {
  //   console.log(formik.values.ruc);
  // };

  const handleModalReference = () => {
    if (formik.values.reference.length > 3) {
      handleOpenEditDialog();
      handleClearSelected();
      setSelectData([]);
    } else {
      setErrorMessage({
        ...errorMessage,
        referenceError: 'Ingrese mas de 4 digitos'
      });
      setTimeout(() => {
        setErrorMessage({
          ...errorMessage,
          referenceError: null
        });
      }, 3000);
    }
  };

  const handleModalItem = (value, formikValue, errorNote) => {
    if (formikValue.length > 3) {
      setValueForm(value);
      handleOpenEditDialog2();
      handleClearSelectedRuc();
      handleRefresh();
      setSelectData([]);
    } else {
      if (errorNote === 'rucError') {
        setErrorMessage({
          ...errorMessage,
          rucError: 'Ingrese mas de 4 digitos'
        });
      }
      if (errorNote === 'startingError') {
        setErrorMessage({
          ...errorMessage,
          startingError: 'Ingrese mas de 4 digitos'
        });
      }
      if (errorNote === 'endingError') {
        setErrorMessage({
          ...errorMessage,
          endingError: 'Ingrese mas de 4 digitos'
        });
      }
      setTimeout(() => {
        setErrorMessage({
          ...errorMessage,
          rucError: null,
          startingError: null,
          endingError: null
        });
      }, 3000);
    }
    // console.log('value', value);
    // setValueForm(value);
    // handleOpenEditDialog2();
    // handleClearSelectedRuc();
    // setSelectData([]);
  };

  const renderCustomInput = (origin) => {
    switch (origin) {
      case '':
        return <InputField label="Referencia" fullWidth disabled />;
      case 'L':
        return (
          <>
            <InputField
              name="code_origin"
              label="Código"
              value={formik.values.code_origin}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.code_origin && Boolean(formik.errors.code_origin)}
              helperText={formik.touched.code_origin && formik.errors.code_origin}
              sx={{ marginBottom: '1em' }}
            />
            <InputField
              name="name_origin"
              label="Nombre"
              value={formik.values.name_origin}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.name_origin && Boolean(formik.errors.name_origin)}
              helperText={formik.touched.name_origin && formik.errors.name_origin}
              sx={{ marginBottom: '1em' }}
            />
            <InputField
              name="weight_origin"
              label="Peso"
              type="number"
              value={formik.values.weight_origin}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.weight_origin && Boolean(formik.errors.weight_origin)}
              helperText={formik.touched.weight_origin && formik.errors.weight_origin}
              sx={{ marginBottom: '1em' }}
            />
            <InputField
              select
              label="Unidad de medida"
              name="measurement_origin"
              onChange={formik.handleChange}
              value={formik.values.measurement_origin}
              fullWidth
              error={formik.touched.measurement_origin && Boolean(formik.errors.measurement_origin)}
              helperText={formik.touched.measurement_origin && formik.errors.measurement_origin}
              sx={{ marginBottom: '1em' }}
            >
              {measurementList.length === 0 ? (
                <MenuItem value="">No hay datos</MenuItem>
              ) : (
                measurementList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              )}
            </InputField>
            <InputField
              name="amount_origin"
              label="Cantidad"
              type="number"
              value={formik.values.amount_origin}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.amount_origin && Boolean(formik.errors.amount_origin)}
              helperText={formik.touched.amount_origin && formik.errors.amount_origin}
              sx={{ marginBottom: '1em' }}
            />
          </>
        );
      case 'HR':
      case 'E':
      case 'F':
      case 'P':
      case 'T':
        return (
          <>
            <InputField
              label="Referencia"
              name="reference"
              value={formik.values.reference}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.reference && Boolean(formik.errors.reference)}
              helperText={formik.touched.reference && formik.errors.reference}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                    <Search onClick={handleModalReference} />
                  </InputAdornment>
                )
              }}
              sx={{ marginBottom: '1em' }}
            />
            {errorMessage.referenceError && (
              <Alert severity="error">{errorMessage.referenceError}</Alert>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const query = useQuery();
  const mounted = useMounted();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };
  useEffect(() => {
    if (queried.query) handleSetController.setSearchText({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    ,
    ,
    ,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomerPromotionsApi.getPromotionsList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
  });

  const handleMenuCloseBusinessPartner = () => {
    setAnchorElBusinessPartners(null);
  };

  const handleOpenBusinessPartners = () => {
    setOpenBusinessPartners(true);
  };

  const handleCloseBusinessPartner = () => {
    setOpenBusinessPartners(false);
    handleMenuCloseBusinessPartner();
  };

  const handleSelectBusinessPartner = (value) => {
    setOpenBusinessPartners(false);
    if (value) {
      formik.setFieldValue('business_partner_id', value.id);
      formik.setFieldValue('business_partner', value);
      handleMenuCloseBusinessPartner();
    }
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información Guía de Remisión
            </InputLabel>
            <PropertyList>
              <CompanyInputDisabled currentCompany={currentCompany} />
              <InputField
                select
                label="Origen"
                name="origin"
                value={formik.values.origin}
                onChange={(event) => {
                  formik.setFieldValue('origin', event.target.value);
                  const optionsForBusinessPartner = ['E', 'F', 'P'];
                  if (!optionsForBusinessPartner.includes(event.target.value)) {
                    formik.setFieldValue('business_partner_id', '');
                    formik.setFieldValue('business_partner', {});
                  }
                  setShowInputBusinessPartner(
                    optionsForBusinessPartner.includes(event.target.value)
                  );
                }}
                fullWidth
                error={formik.touched.origin && Boolean(formik.errors.origin)}
                helperText={formik.touched.origin && formik.errors.origin}
                sx={{ marginBottom: '1em' }}
              >
                {originList.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  originList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </InputField>

              {showInputBusinessPartner && (
                <Grid>
                  <InputField
                    label="Socio de negocio"
                    name="business_partner"
                    value={formik.values.business_partner.full_name || ''}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton
                          aria-label="más opciones"
                          onClick={(event) => setAnchorElBusinessPartners(event.currentTarget)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )
                    }}
                    error={
                      formik.touched.business_partner_id &&
                      Boolean(formik.errors.business_partner_id)
                    }
                    helperText={
                      formik.touched.business_partner_id && formik.errors.business_partner_id
                    }
                    disabled
                  />

                  <Menu
                    anchorEl={anchorElBusinessPartners}
                    open={menuOpenBusinessPartners}
                    onClose={handleMenuCloseBusinessPartner}
                  >
                    <MenuItem onClick={handleOpenBusinessPartners}>Seleccionar</MenuItem>
                  </Menu>

                  <RenderIf condition={openBusinessPartners}>
                    <CustomerModal
                      open={openBusinessPartners}
                      onClose={handleCloseBusinessPartner}
                      permission={GadminActions.G2_CONTESTS_ADD}
                      widhtForm="md"
                    >
                      <BusinessPartnersForSelect
                        handleClose={handleCloseBusinessPartner}
                        handleSelect={handleSelectBusinessPartner}
                      />
                    </CustomerModal>
                  </RenderIf>
                </Grid>
              )}

              <InputField
                select
                label="Sede"
                name="branch"
                value={formik.values.branch}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.branch && Boolean(formik.errors.branch)}
                helperText={formik.touched.branch && formik.errors.branch}
                sx={{ marginBottom: '1em' }}
              >
                {brachList.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  brachList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </InputField>
            </PropertyList>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información Transportista
            </InputLabel>
            <PropertyList>
              <InputField
                label="RUC"
                name="ruc"
                value={formik.values.ruc}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.ruc && Boolean(formik.errors.ruc)}
                helperText={formik.touched.ruc && formik.errors.ruc}
                sx={{ marginBottom: '1em' }}
                // onKeyUp={handleModalItem}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                      <Search
                        onClick={() => handleModalItem('ruc', formik.values.ruc, 'rucError')}
                      />
                    </InputAdornment>
                  )
                }}
              />
              {errorMessage.rucError && (
                <Alert sx={{ marginBottom: '1em' }} severity="error">
                  {errorMessage.rucError}
                </Alert>
              )}
              <InputField
                label="Nombre"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ marginBottom: '1em' }}
              />
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black'
                }}
              >
                Partida
              </InputLabel>
              <InputField
                label="Ubigeo"
                name="ubigeo_starting"
                value={formik.values.ubigeo_starting}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.ubigeo_starting && Boolean(formik.errors.ubigeo_starting)}
                helperText={formik.touched.ubigeo_starting && formik.errors.ubigeo_starting}
                // onKeyUp={handleSearch}
                sx={{ marginBottom: '1em' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                      <Search
                        onClick={() =>
                          handleModalItem(
                            'ubigeo_starting',
                            formik.values.ubigeo_starting,
                            'startingError'
                          )
                        }
                      />
                    </InputAdornment>
                  )
                }}
              />
              {errorMessage.startingError && (
                <Alert sx={{ marginBottom: '1em' }} severity="error">
                  {errorMessage.startingError}
                </Alert>
              )}
              <InputField
                label="Dirección"
                name="address_starting"
                value={formik.values.address_starting}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.address_starting && Boolean(formik.errors.address_starting)}
                helperText={formik.touched.address_starting && formik.errors.address_starting}
                sx={{ marginBottom: '1em' }}
              />
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black'
                }}
              >
                Llegada
              </InputLabel>
              <InputField
                label="Ubigeo"
                name="ubigeo_ending"
                value={formik.values.ubigeo_ending}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.ubigeo_ending && Boolean(formik.errors.ubigeo_ending)}
                helperText={formik.touched.ubigeo_ending && formik.errors.ubigeo_ending}
                sx={{ marginBottom: '1em' }}
                // onKeyUp={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                      <Search
                        onClick={() =>
                          handleModalItem(
                            'ubigeo_ending',
                            formik.values.ubigeo_ending,
                            'endingError'
                          )
                        }
                      />
                    </InputAdornment>
                  )
                }}
              />
              {errorMessage.endingError && (
                <Alert sx={{ marginBottom: '1em' }} severity="error">
                  {errorMessage.endingError}
                </Alert>
              )}
              <InputField
                label="Dirección"
                name="address_ending"
                value={formik.values.address_ending}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.address_ending && Boolean(formik.errors.address_ending)}
                helperText={formik.touched.address_ending && formik.errors.address_ending}
                sx={{ marginBottom: '1em' }}
              />
              {formik.values.company_id === '' || formik.values.branch === '' ? (
                <InputField sx={{ marginBottom: '1em' }} label="Referencia" fullWidth disabled />
              ) : (
                renderCustomInput(formik.values.origin)
              )}
            </PropertyList>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Información Vehículo
            </InputLabel>
            <PropertyList>
              <InputField
                label="Placa"
                name="placa"
                value={formik.values.placa}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.placa && Boolean(formik.errors.placa)}
                helperText={formik.touched.placa && formik.errors.placa}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                label="Matricula"
                name="matricula"
                value={formik.values.matricula}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.matricula && Boolean(formik.errors.matricula)}
                helperText={formik.touched.matricula && formik.errors.matricula}
                sx={{ marginBottom: '1em' }}
              />
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Chofer
              </InputLabel>
              <InputField
                label="DNI"
                type="number"
                name="dni"
                value={formik.values.dni}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.dni && Boolean(formik.errors.dni)}
                helperText={formik.touched.dni && formik.errors.dni}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                label="Nombre"
                name="driver_name"
                value={formik.values.driver_name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.driver_name && Boolean(formik.errors.driver_name)}
                helperText={formik.touched.driver_name && formik.errors.driver_name}
                sx={{ marginBottom: '1em' }}
              />
            </PropertyList>
          </Grid>
        </Grid>
        {/* {renderCustomInput(formik.values.origin)} */}
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2,
            marginLeft: 1
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <GRDialog
        onClose={handleCloseEditDialog}
        onSelectionConfirm={handleSelectionConfirm}
        onExited={handleExitedDialog}
        open={editDialogOpen}
        data={dataTable}
        title="Referencia"
        helpText=""
        isDisabled={disabledReference}
      >
        <GrReferenceTable
          requestData={dataTable}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          selectedItems={selectedItems}
          setSelectData={setSelectData}
          selectData={selectData}
          setDisabledReference={setDisabledReference}
        />
      </GRDialog>
      <HRProgramedDialog
        onClose={handleCloseEditDialog2}
        onSelectionConfirm={handleSelectionConfirm2}
        onExited={handleExitedDialog2}
        open={editDialogOpen2}
        title="Ruc"
        helpText=""
      >
        <QueryCustomerModal
          disabled={returnsState.isLoading}
          onQueryChange={handleQueryChange}
          query={controller.query}
          handleRefresh={handleRefresh}
        />
        <GrItemsTable
          requestData={returnsState.documents}
          disabled={returnsState.isLoading}
          onSelect={handleSelectRuc}
          onSelectAll={handleSelectAllRuc}
          selectedItems={selectedItemsRuc}
          setSelectData={setSelectData}
          selectData={selectData}
          handleClearSelectedRuc={handleClearSelectedRuc}
          isLoading={returnsState.isLoading}
          error={returnsState.error}
          onQueryChange={handleQueryChange}
          query={controller.query}
          value={formik.values.ruc}
        />
      </HRProgramedDialog>
    </Box>
  );
};

DeliveryCreateGrForm.propTypes = {
  // update: Proptypes.bool,
  initialValues: Proptypes.object,
  onClose: Proptypes.func,
  title: Proptypes.string
  // handleRefresh: Proptypes.func
};

import Proptypes from 'prop-types';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  IconButton,
  Link
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Status } from '../../status';
import { Eye as EyeIcon } from '../../../icons/eye';
import { Link as RouterLink } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },

  {
    id: 'work_reference',
    label: 'Número'
  },

  {
    id: 'order_currency',
    label: 'Moneda'
  },
  {
    id: 'order_amount',
    label: 'Monto'
  },
  {
    id: 'work_namestatus',
    label: 'Estado'
  },
  {
    id: 'work_created',
    label: 'Creado'
  },
  {
    id: 'vehicle_name',
    label: 'Nombre'
  },
  {
    id: 'work_vendor',
    label: 'Proveedor'
  },
  {
    id: 'account_number',
    label: 'RUC '
  },
  {
    id: 'vehicle_type',
    label: 'Tipo '
  },
  {
    id: 'vehicle_brand',
    label: 'Marca '
  },
  {
    id: 'vehicle_model',
    label: 'Modelo '
  },
  {
    id: 'vehicle_year',
    label: 'Año '
  },
  {
    id: 'vehicle_color',
    label: 'Color'
  },
  {
    id: 'vehicle_namestatus',
    label: 'Estado Vehículo'
  },
  {
    id: 'vehicle_plate',
    label: 'Placa '
  }
];

export const DeliveryVehicleWorkOrdersTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const { appUrlBase } = useApp();
  const detailRoute = `${appUrlBase}/operations/vehicles/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1, marginLeft: 3 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>
                    {document.id}
                    <IconButton>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`${detailRoute}${document.vehicle_plate}/work-orders/${document.work_reference}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        <EyeIcon />
                      </Link>
                    </IconButton>
                  </TableCell>
                  <TableCell> {document.work_reference} </TableCell>
                  <TableCell> {document.order_currency} </TableCell>
                  <TableCell> {document.order_amount} </TableCell>
                  <TableCell>
                    <Status color="#24b821" label={document.work_namestatus} />
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.work_created}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    <Typography color="textSecondary" variant="body2">
                      {document.work_vendor}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    <Typography color="textSecondary" variant="body2">
                      {document.account_number}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_brand}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_model}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_year}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 100 }}>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_color}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: 100 }}>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_namestatus}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {document.vehicle_plate}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

DeliveryVehicleWorkOrdersTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

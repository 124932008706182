import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// import { format } from 'date-fns';
import { Button, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { RenderIf } from '../../render-if';

export const InvoiceDetails = (props) => {
  const { invoice, setEditMode, editable = false } = props;
  const { currency, currencySymbol } = invoice;

  return (
    <Card variant="outlined">
      <CardHeader title="Detalle de comprobante" />
      <Divider />
      <Grid container>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Proveedor">
              <Typography variant="body2" color="textPrimary">
                {`${invoice.cardCode} - ${invoice.cardName}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                RUC: {invoice.licTradNum}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Sede" value={`${invoice.branch} - ${invoice.branchName}`} />
            <PropertyListItem label="Cuenta" value={`${invoice.account}-${invoice.acctName}`} />
            <PropertyListItem
              label="Centro de costo"
              value={`${invoice.ocrCode} - ${invoice.ocrName}`}
            />
            <PropertyListItem label="Término de pago" value={`#${invoice.paymentName}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Numero" value={`#${invoice.document}`} />
            <PropertyListItem
              label="Fecha de emisión"
              value={moment(invoice.taxDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Vencimiento"
              value={moment(invoice.docDueDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Contabilización"
              value={moment(invoice.docDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem label="Vehículo" value={`${invoice.vehicle}`} />
            <PropertyListItem label="Glosa" value={`${invoice.comments}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Cuenta Socio de Negocio"
              value={invoice.control_account}
              align="right"
            />
            <PropertyListItem
              label="Gravado"
              value={numeral(invoice.sumGravada).format(`${currencySymbol} 0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Inafecto"
              value={numeral(invoice.sumInafecta).format(`${currency}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Exonerado"
              value={numeral(invoice.sumExonerada).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Gratuito"
              value={numeral(invoice.sumGratuita).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="IGV"
              value={numeral(invoice.sumIgv).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Importe"
              value={numeral(invoice.docTotal).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem label="Validación" value={invoice.cpe_memo} />
          </PropertyList>
        </Grid>
        <RenderIf condition={editable}>
          <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={() => {
                setEditMode(true);
              }}
              variant="contained"
              disabled={!editable}
            >
              Editar
            </Button>
          </AllowedGuard>
        </RenderIf>
      </Grid>
    </Card>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  editable: PropTypes.bool
};

/* eslint eqeqeq: ["error", "smart"] */
import { useEffect, useState, useRef } from 'react';

const isEmpty = (obj) => Object.keys(obj).length === 0;

const hasChanges = (initial, current) => {
  if (!initial || !current) return false;
  return JSON.stringify(initial) !== JSON.stringify(current);
};

const hasPropsChanges = (initial, current, includeNews = false) => {
  const changes = {};

  Object.keys(initial).map((prop) => {
    if (!!Object.getOwnPropertyDescriptor(current, prop) && initial[prop] !== current[prop]) {
      if (!(initial[prop] === null && !current[prop])) {
        changes[prop] = current[prop];
      }
    }
    return { ...prop };
  });
  if (includeNews) {
    Object.keys(current).map((prop) => {
      if (!Object.getOwnPropertyDescriptor(initial, prop)) {
        changes[prop] = current[prop];
      }
      return { ...prop };
    });
  }

  return changes;
};
const useItemChanges = (initial, current) => {
  const refInitial = useRef();
  const refCurrent = useRef();

  const [changed, setChanged] = useState({});
  useEffect(() => {
    refInitial.current = initial;
    refCurrent.current = current;
    setChanged({});
    if (hasChanges(refInitial.current, refCurrent.current) === true) {
      setChanged(hasPropsChanges(refInitial.current, refCurrent.current));
    }
  }, [initial, current]);
  return changed;
};

const compareRecordChanged = (initial, current, includeNewValues) => {
  if (hasChanges(initial, current) === true) {
    return hasPropsChanges(initial, current, includeNewValues);
  }
  return current;
};

const handleSaveChanges = async ({
  originalData,
  values,
  setSaving,
  apiHandler,
  showNotify,
  showError,
  id
}) => {
  let saved = { success: false };
  try {
    const changes = compareRecordChanged(originalData, values);
    if (!isEmpty(changes)) {
      setSaving(true);
      const response = await apiHandler({ id, changes });
      if (response.success) {
        showNotify(response.message);
        saved = { ...saved, success: true };
      } else {
        showError(`${response?.message}. No se procesaron `);
        saved = { ...saved, error: response.message };
      }
    } else {
      // throw new Error('No se detectaron cambios');
      saved = { ...saved, error: 'No se detectaron cambios' };
      showError(`${saved.error}`);
    }
  } catch (error) {
    saved = { ...saved, error };
  }
  return saved;
};

export default useItemChanges;
export { compareRecordChanged, isEmpty, handleSaveChanges };

import Proptypes from 'prop-types';
// import moment from 'moment';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Pagination } from '../../pagination';
import { Eye as EyeIcon } from '../../../icons/eye';
import { Status } from '../../status';
import { StatusColor } from '../../contest/contest-definition';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { SurveySurveyItemMenu } from './survey-survey-item-menu';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: '#'
  },
  {
    id: 'title',
    label: 'Titulo'
  },
  {
    id: 'status',
    label: 'Estado'
  },
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'sales_team',
    label: 'Equipo de ventas'
  },
  {
    id: 'point',
    label: 'Puntos'
  }
  // {
  //   id: 'apply_to',
  //   label: 'Aplicado'
  // }
];

export const SurveySurveyTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate',
    handleRefresh
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const { appUrlBase } = useApp();
  const detailRoute = `${appUrlBase}/survey/survey/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1, marginLeft: 2 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow hover key={document.id}>
                <TableCell
                  sx={{
                    minWidth: 150
                  }}
                >
                  <Typography color="textSecondary" variant="body2">
                    {index + 1}
                    <IconButton>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`${detailRoute}${document.id}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        <EyeIcon />
                      </Link>
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.title}
                  </Typography>
                </TableCell>
                <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Status color={StatusColor(document.status)} label={document.statusName} />
                  <AllowedGuard permission={GadminActions.G2_SURVEY_UP}>
                    <SurveySurveyItemMenu items={document} handleRefresh={handleRefresh} />
                  </AllowedGuard>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.date}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document?.sales_team_id} - {document?.sales_team_info?.name || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.point}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  {document.apply_to ? (
                    <>
                      {document.apply_to.map((item, index) => (
                        <Typography key={index} color="textSecondary" variant="body2">
                          {item?.name}
                        </Typography>
                      ))}
                    </>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      --
                    </Typography>
                  )}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

SurveySurveyTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  handleRefresh: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

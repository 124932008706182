import { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { useMounted } from '../../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import LoadingContent from '../../../components/common/loading-content';
import { goalMaintenanceBaseApi } from '../../../api/goal/maintenance/goal-maintenance-base';
import { ConstantStatus } from '../../../api/application-enums';
import PaymentTermsTable from '../../../components/maintenance/base/payment-terms-table';
import PaymentTermsFilter from '../../../components/maintenance/base/payment-terms-filter';
import { useSelection } from '../../../hooks/use-selection';
import { Plus as PlusIcon } from '../../../icons/plus';

export const MaintenancePaymentTerm = () => {
  const query = useQuery();
  const mounted = useMounted();
  const { title, currentCompany, appUrlBase } = useApp();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view')?.toUpperCase() || ConstantStatus.ACTIVE;

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    dataState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalMaintenanceBaseApi.getPaymentTerms,
    loadingMessage: 'Cargando términos de pago',
    mounted,
    defaults: {
      ...queried,
      view: view,
      companyId: currentCompany
    }
  });

  const [selectedItem, , ,] = useSelection(dataState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => {
    if (!controller.refresh && (dataState.isLoading || dataState.error)) {
      return <LoadingContent loadingText={dataState.message} error={dataState.error} />;
    }

    return (
      <Card>
        <PaymentTermsFilter
          disabled={dataState.isLoading}
          filters={controller.filters}
          onFiltersApply={handleFiltersApply}
          onFiltersClear={handleFiltersClear}
          onQueryChange={handleQueryChange}
          onViewChange={handleViewChange}
          query={controller.query}
          selectedItem={selectedItem}
          view={controller.view}
        />
        <Divider />
        <PaymentTermsTable
          error={dataState.error}
          data={dataState.isLoading ? [] : dataState.documents}
          dataCount={dataState.documentsCount}
          isLoading={dataState.isLoading}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          page={controller.page + 1}
          sort={controller.sort}
          sortBy={controller.sortBy}
          handleRefresh={handleRefresh}
          view={view}
        />
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Términos de pago')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Términos de pago
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                >
                  Refrescar
                </Button>
              </AllowedGuard>
              <RouterLink
                to={`${appUrlBase}/master-data/payment-terms/create`}
                style={{ textDecoration: 'none' }}
              >
                <Button color="primary" size="large" startIcon={<PlusIcon />} variant="contained">
                  Agregar
                </Button>
              </RouterLink>
            </Box>
          </Box>
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

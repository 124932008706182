import PropTypes from 'prop-types';
import { useApp } from '../hooks/use-app';
import GoalButton from './goal-button';
import StarIcon from '@mui/icons-material/Star';
import { Typography, Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const GoalRecordLink = (props) => {
  const {
    label = '-',
    title = 'Ver detalle',
    to,
    onClick,
    children,
    fullPath = false,
    target = '_self'
  } = props;
  const { appUrlBase } = useApp();
  const detailRoute = to ? `${appUrlBase}${to}` : null;

  if (fullPath && to) {
    return (
      <Tooltip title={label}>
        <Link
          color="inherit"
          component={RouterLink}
          underline="hover"
          variant="subtitle2"
          to={`${to}`}
          rel="noopener noreferrer"
          target={target}
        >
          <StarIcon fontSize="small" color="secondary" sx={{ fontSize: 12, mr: 1 }} />
          {label}
        </Link>
      </Tooltip>
    );
  }

  return (
    <GoalButton
      to={fullPath ? to : detailRoute}
      label={`${title ?? label}`}
      startIcon={<StarIcon fontSize="small" color="secondary" sx={{ fontSize: 1 }} />}
      variant="text"
      onClick={onClick}
      target={target}
    >
      {children ?? (
        <Typography variant="button" color="textPrimary">
          {label}
        </Typography>
      )}
    </GoalButton>
  );
};

export default GoalRecordLink;

GoalRecordLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  fullPath: PropTypes.bool,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top', 'framename'])
};

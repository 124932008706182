import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { useDownloadFile } from '../../../hooks/use-download-file';
import { BaselineArticle as BaselineArticledIcon } from '../../../icons/baseline-article';
import { ViewList as ViewListIcon } from '../../../icons/view-list';
import { deliveryApi } from '../../../api/delivery';
import { RenderIf } from '../../render-if';
import { useApp } from '../../../hooks/use-app';
import { HRProgramedDialog } from './hr-programed-dialog';
import { HRProgramedTable } from './hrs-programed-table';
import { useDialog } from '../../../hooks/use-dialog';
import { useSelection } from '../../../hooks/use-selection';

export const HrMenu = (props) => {
  const { id, vehicle } = props;
  // const navigate = useNavigate();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [currentId, setCurrentId] = useState(-1);
  const [vehicleSheetsState, setVehicleSheetsState] = useState({ isLoading: true, reload: true });
  const [currentVehicle, setCurrentVehicle] = useState('');

  const { showNotify } = useApp();

  useEffect(() => {
    setCurrentId(id);
  }, [id]);

  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();

  const [selectedItems, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    vehicleSheetsState.data
  );

  const handleExitedDialog = () => {};
  const handleSelectionConfirm = () => {
    if (!!selectedItems && selectedItems.length > 0) {
      downloadSumaryLoadPage();
      handleCloseEditDialog();
    } else {
      showNotify('No se ha seleccionado filas', 'warning');
    }
  };

  const downloadErrorHandle = (message) => {
    showNotify(message, 'error');
  };

  const { download } = useDownloadFile({
    apiDefinition: deliveryApi.getHrLoadPage,
    apiParameters: currentId,
    onError: downloadErrorHandle
  });

  const { download: downloadDelivery } = useDownloadFile({
    apiDefinition: deliveryApi.getHrDeliveryPage,
    apiParameters: currentId,
    onError: downloadErrorHandle
  });

  const { download: downloadSumaryLoadPage } = useDownloadFile({
    apiDefinition: deliveryApi.getSumaryLoadPage,
    apiParameters: { id: 1, vehicle, sheets: selectedItems },
    onError: downloadErrorHandle
  });

  const handleSelectedtVehicle = async (veh) => {
    setCurrentVehicle(veh);
    setVehicleSheetsState(() => ({
      ...vehicleSheetsState,
      isLoading: true,
      message: 'Cargando hojas para placa'
    }));

    const result = await deliveryApi.getDeliveryProgramedPages({ placa: veh });
    setVehicleSheetsState(() => ({
      ...result,
      isLoading: false,
      reload: false
    }));
    if (result.success) {
      handleClearSelected();
      handleOpenEditDialog();
    }
  };

  const handleDownloadConsolidate = async () => {
    await handleSelectedtVehicle(vehicle);
  };

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} {...props}>
        <DotsVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <RenderIf condition={!!download}>
          <MenuItem onClick={download}>
            <ListItemText>Hoja de carga</ListItemText>
            <ListItemIcon>
              <BaselineArticledIcon />
            </ListItemIcon>
          </MenuItem>
        </RenderIf>
        <RenderIf condition={!!downloadDelivery}>
          <MenuItem onClick={downloadDelivery}>
            <ListItemText>Hoja de reparto</ListItemText>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
          </MenuItem>
        </RenderIf>
        <RenderIf condition={!!downloadSumaryLoadPage}>
          <MenuItem onClick={handleDownloadConsolidate}>
            <ListItemText>Hoja consolidado</ListItemText>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
          </MenuItem>
        </RenderIf>
      </Menu>
      <HRProgramedDialog
        onClose={handleCloseEditDialog}
        onSelectionConfirm={handleSelectionConfirm}
        onExited={handleExitedDialog}
        open={editDialogOpen}
        data={vehicleSheetsState}
        // requestData={vehicleSheetsState}
        title={`Hojas de reparto para  vehículo ${currentVehicle}`}
        helpText="Seleccione una o varias hojas para fusionar"
      >
        <HRProgramedTable
          requestData={vehicleSheetsState}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          selectedItems={selectedItems}
        />
      </HRProgramedDialog>
    </>
  );
};

HrMenu.propTypes = {
  id: PropTypes.number.isRequired,
  vehicle: PropTypes.string.isRequired
};

import PropTypes from 'prop-types';
import { Link, Box } from '@mui/material';
import { mediaType } from '../../../api/purchase-definitions';

export const PDFLink = (props) => {
  const { fileName, fileType, content, loading = false } = props;

  if (loading) {
    return <Box> Cargando reporte</Box>;
  }
  if (!content || fileType !== mediaType.PDF) return <Box>No encontrado</Box>;

  return (
    <Link
      href={`data:${fileType};base64,${content}`}
      download={fileName}
      variant="body2"
      alt="Download"
    >
      {fileName}
    </Link>
  );
};

PDFLink.propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  content: PropTypes.string,
  loading: PropTypes.bool
};

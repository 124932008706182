import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
import { dataLoader } from './data-loader';

/* eslint class-methods-use-this:0 */

class ProductApi {
  async getProductInfo(itemcode) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'information',
      payload: { id: itemcode }
    });
    return response;
  }

  async getProductDetail(itemcode) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'detail',
      payload: { id: itemcode }
    });
    return response;
  }

  async getProductGallery(itemcode) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'gallery',
      payload: { id: itemcode }
    });
    return response;
  }

  async getProductStock(itemcode) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'stock',
      payload: { id: itemcode }
    });
    return response;
  }

  async getProductCosts(itemcode) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'costs',
      payload: { id: itemcode }
    });
    return response;
  }

  async getProductMeasures(itemcode) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'measures',
      payload: { id: itemcode }
    });
    return response;
  }

  async getProductPrices(itemcode) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'prices',
      payload: { id: itemcode }
    });
    return response;
  }

  async saveProductImage(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_MEDIA_UP, {
      type: 'save-image',
      payload
    });
    return response;
  }

  async deleteProductImage(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_MEDIA_UP, {
      type: 'delete-image',
      payload
    });
    return response;
  }

  // async getFEPendingsFaceleDetailXls(payload) {
  //   const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
  //     type: 'pending-facele-xls',
  //     payload
  //   });
  //   return response;
  // }

  async fixProductMargin(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_PRICE_UP_MANUAL, {
      type: 'margin-reset',
      payload
    });
    return response;
  }

  async updatePriceAll(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_UP_CNT, {
      type: 'update-price-all',
      payload
    });
    return response;
  }

  async upProductMeasure(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_UP_CNT, {
      type: 'measure-update',
      payload
    });
    return response;
  }

  async upProduct(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_UP, {
      type: 'product-update',
      payload
    });
    return response;
  }

  async addProduct(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_UP, {
      type: 'product-add',
      payload
    });
    return response;
  }

  async addProductMeasure(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_UP_CNT, {
      type: 'measure-add',
      payload
    });
    return response;
  }

  async deleteProductMeasure(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_UP_CNT, {
      type: 'measure-delete',
      payload
    });
    return response;
  }

  async updateProductPrice(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM_UP_CNT, {
      type: 'product-price-update',
      payload
    });
    return response;
  }

  async getVendorList(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'vendor-list',
      payload
    });
    return response;
  }

  async getQuoteCategoryList(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'quote-category-list',
      payload
    });
    return response;
  }

  async getComercialLineList(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'comercial-line-list',
      payload
    });
    return response;
  }

  async getComercialBrandList(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'comercial-brand-list',
      payload
    });
    return response;
  }

  async getProductTeams(payload) {
    const response = await GadminRequest(GadminActions.gCC_INV_ITEM, {
      type: 'product-teams',
      payload
    });
    return response;
  }

  async getProductAttributes({ id, companyId, view }) {
    const viewParam = view ? `&view=${view}` : ``;
    const response = await dataLoader.loadGoalGetData({
      action: `inventory/product/products/${id}/attributes?companyId=${companyId}${viewParam}`
    });
    return response;
  }
}

export const productApi = new ProductApi();

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { AllowedGuard } from '../hooks/use-allowed';
import { ConfirmationDialog } from './confirmation-dialog';
import { useApp } from '../hooks/use-app';

export const BulkActionsMenu = (props) => {
  const {
    disabled,
    selectedItems,
    selectedCount,
    menuActions: menuActionsProp,
    afterProcessHandler,
    apiHandler
  } = props;
  const { showNotify, showError, showWarning } = useApp();

  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [menuActions, setMenuActions] = useState([]);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');

  useEffect(() => {
    setMenuActions(menuActionsProp);
  }, [menuActionsProp]);

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    handleClose();
  };
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
    handleClose();
  };

  const onConfirmConfirmationDialog = async () => {
    try {
      const response = await apiHandler({
        type: currentMenuItem.action,
        payload: { lines: selectedItems, count: selectedCount }
      });
      if (response.success) {
        const { count } = response.data;
        if (count !== selectedItems.length) {
          showWarning(response.message);
        } else showNotify(response.message);
        if (count > 0) {
          afterProcessHandler();
        }
      } else {
        showError(`${response?.message}. Algunos registros no se procesaron `);
      }
    } catch (error) {
      showNotify(`Error al procesar  ${error}`, 'error');
      console.error(error);
    }
    handleCloseDialog();
  };

  const handleOpenConfirmationDialog = (menuItem) => {
    setCurrentMenuItem(menuItem);
    setConfirmDialogMessage(menuItem.prompt);
    setOpenConfirmDialog(true);
  };

  return (
    <>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        startIcon={<ChevronDownIcon />}
        variant="outlined"
        sx={{
          display: selectedCount > 0 ? 'flex' : 'none',
          order: {
            sm: 1,
            xs: 1
          }
        }}
      >
        Acciones Masivas
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {menuActions.map((item, index) => (
          <AllowedGuard permission={item.permission || item.action} key={index}>
            <MenuItem
              key={index}
              onClick={() => {
                handleOpenConfirmationDialog(item);
              }}
            >{`${item.label} (${selectedCount})`}</MenuItem>
          </AllowedGuard>
        ))}
      </Menu>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={onCancelConfirmationDialog}
        onConfirm={onConfirmConfirmationDialog}
        open={openConfirmDialog}
        title="Confirmación importante"
        variant="warning"
      />
    </>
  );
};

BulkActionsMenu.propTypes = {
  disabled: PropTypes.bool,
  menuActions: PropTypes.array.isRequired,
  afterProcessHandler: PropTypes.func.isRequired,
  apiHandler: PropTypes.func.isRequired,
  selectedCount: PropTypes.number,
  selectedItems: PropTypes.array
};

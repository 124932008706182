import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Trash as TrashIcon } from '../../icons/trash';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { ConfirmationButton } from '../common/confirmation-button';
import GoalButton from '../goal-button';

export const ImportArgumentsTable = ({ onEdit = () => {}, onDelete = () => {}, values = [] }) => {
  return (
    <Table sx={{ minWidth: 500, marginTop: 1, marginLeft: 3 }}>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Título</TableCell>
          <TableCell>Nombre</TableCell>
          <TableCell>Tipo de Dato</TableCell>
          <TableCell>Minimo</TableCell>
          <TableCell>Maximo</TableCell>
          <TableCell>Predeterminado</TableCell>
          <TableCell> &nbsp; </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item?.title}</TableCell>
            <TableCell>{item?.name}</TableCell>
            <TableCell>{item?.data_type}</TableCell>
            <TableCell>{item?.min || '--'}</TableCell>
            <TableCell>{item?.max || '--'}</TableCell>
            <TableCell>{item?.defaultValue || '--'}</TableCell>
            <TableCell>
              <GoalButton
                startIcon={<PencilIcon />}
                edge="end"
                aria-label="edit"
                label="Editar"
                variant="outlined"
                color={'secondary'}
                onClick={() => onEdit(index)}
              />
              <ConfirmationButton
                icon={<TrashIcon />}
                aria-label="delete"
                label="Eliminar Argumento"
                message={`Se va a eliminar el argumento  ${item?.name}`}
                title="Eliminar Argumento"
                onConfirm={() => onDelete(index)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

ImportArgumentsTable.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  values: PropTypes.array
};

import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { customerReturnsApi } from '../../../../api/customer-return';
import { useMounted } from '../../../../hooks/use-mounted';
import LoadingContent from '../../../common/loading-content';
import CustomerReturnReceipt from './customer-return-receipt';

export const CustomerReturnPrintingDialog = (props) => {
  const { open = false, onClose, onExited, entity, ...other } = props;
  const mounted = useMounted();

  const [customerReturnState, setCustomerReturnState] = useState({ isLoading: true });

  const { docEntry } = entity || {};
  const getJournal = useCallback(async () => {
    setCustomerReturnState(() => ({ isLoading: true }));
    try {
      const result = await customerReturnsApi.getCustomerReturnPrint(docEntry);

      if (mounted.current) {
        setCustomerReturnState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setCustomerReturnState(() => ({
          isLoading: false,
          // error: err.message
          error: err.message ? err.message : 'Error del servidor'
        }));
      }
    }
  }, [docEntry]);

  useEffect(() => {
    getJournal().catch(console.error);
  }, []);

  if (customerReturnState.error || !customerReturnState.data) {
    return <LoadingContent loadingText="Cargando reporte" />;
  }
  const styles = StyleSheet.create({
    // General Styles
    page: {
      width: '100%',
      height: '600px',
      border: 'none',
      borderRadius: '8px'
    }
  });
  const link = 'Link';
  const journalPdf = !customerReturnState.data ? (
    ''
  ) : (
    <CustomerReturnReceipt customerReturn={customerReturnState.data} />
  );
  const body = !customerReturnState.data ? (
    'Sin contenido'
  ) : (
    <>
      <PDFViewer style={styles.page} className="app">
        {journalPdf}
      </PDFViewer>
      <PDFDownloadLink document={journalPdf} fileName="invoice" style={{ textDecoration: 'none' }}>
        <Button color="primary" size="large" variant="contained">
          Descargar
        </Button>
      </PDFDownloadLink>
    </>
  );

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        minheight: '80vh',
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      {...other}
      maxWidth="sm"
    >
      <DialogTitle>Archivo {link} </DialogTitle>
      <DialogContent>
        {body}
        {/* <PDFDownloadLink
            document={<InvoicePDF invoice={invoiceState.data} />}
            fileName="invoice"
            style={{ textDecoration: 'none' }}
          >
            <Button color="primary" startIcon={<DownloadIcon />} size="large" variant="contained">
              Download
            </Button>
          </PDFDownloadLink> */}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomerReturnPrintingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  afterSubmit: PropTypes.func,
  onVariantsChange: PropTypes.func,
  entity: PropTypes.object
};

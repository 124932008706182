import { useState, useEffect } from 'react';

const useImageToBase64 = (image) => {
  const [base64Image, setBase64Image] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  const resetImage = () => {
    setPreviewImage(null);
    setBase64Image(null);
  };

  // console.log(image);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setBase64Image(reader.result);
        // console.log(reader);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  useEffect(() => {
    if (base64Image) {
      setPreviewImage(base64Image);
    }
  }, [base64Image]);

  return { base64Image, previewImage, resetImage };
};

export default useImageToBase64;

import PropTypes from 'prop-types';
import { Button, DialogActions, Grid, Typography } from '@mui/material';
import GadminActions from '../../../contexts/gadmin-actions';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Plus as PlusIcon } from '../../../icons/plus';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { documentStatusViews } from '../../../api/app-definitions';
import { SliderCommon } from '../../common/slider-common';

export const ContestAwardsDetail = ({
  setEditMode,
  item,
  setSelectAction,
  handleReset,
  contestState,
  handleEditRange
}) => {
  const handleAddButton = () => {
    setEditMode(true);
    setSelectAction('create-form');
    handleReset();
  };

  const handleEditBtn = () => {
    handleEditRange();
    setEditMode(true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PropertyList>
          <PropertyListItem align="end" label="Puesto">
            <Typography color="textSecondary" variant="body2">
              {item === null ? '---' : item.employee_position?.name}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Tipo">
            <Typography color="textSecondary" variant="body2">
              {item !== null ? (
                <Typography color="textSecondary" variant="body2">
                  {item.type === 'P' ? 'Porcentaje' : 'Valor'}
                </Typography>
              ) : (
                <Typography color="textSecondary" variant="body2">
                  ----
                </Typography>
              )}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Valor">
            <Typography color="textSecondary" variant="body2">
              {item === null ? '---' : item.value}
            </Typography>
          </PropertyListItem>
          {item !== null && item.ranges.length !== 0 && <SliderCommon ranges={item.ranges} />}
        </PropertyList>
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <AllowedGuard permission={GadminActions.G2_CONTESTS_UP}>
            <Button
              color="primary"
              size="large"
              startIcon={<PencilIcon />}
              onClick={handleEditBtn}
              variant="contained"
              sx={{ marginRight: 2 }}
              disabled={contestState.status !== documentStatusViews.VIEW_DRAFT}
            >
              Editar
            </Button>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleAddButton}
              variant="contained"
            >
              Agregar
            </Button>
          </AllowedGuard>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

ContestAwardsDetail.propTypes = {
  item: PropTypes.object,
  setEditMode: PropTypes.func,
  setSelectAction: PropTypes.func,
  handleReset: PropTypes.func,
  contestState: PropTypes.object,
  handleEditRange: PropTypes.func
};

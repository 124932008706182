import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { NumberColor } from '../../number-color';
import { Sumary } from '../../sumary';
import { useApp } from '../../../hooks/use-app';

export const ReceiptLinesTable = (props) => {
  const { receiptInfo } = props;
  const { appUrlBase } = useApp();

  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell sx={{ minWidth: 300 }}>Producto</TableCell>
              <TableCell>Factor Venta</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio Base</TableCell>
              <TableCell>Dscto (%)</TableCell>
              <TableCell>Precio Final</TableCell>
              <TableCell>SubTotal</TableCell>
              <TableCell>IGV</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Promo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receiptInfo.lines?.map((item) => (
              <TableRow key={item.LineNum}>
                <TableCell>{numeral(item.LineNum).format('0')}</TableCell>
                <TableCell sx={{ minWidth: 300 }}>
                  <Box xl={2}>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      underline="hover"
                      variant="subtitle2"
                      to={`${appUrlBase}/inventory/products/${item.ItemCode}`}
                    >
                      {item.ItemCode}
                    </Link>
                    <Typography color="textSecondary" variant="body2">
                      {item.Dscription}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.UnitCode}-{item.unitMsr2}
                    </Typography>
                    <NumberColor value={item.Factor} format="0.0" />
                  </Box>
                </TableCell>
                <TableCell>
                  <NumberColor value={item.SoldQuantity} format="0.0" />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.PriceBefDi} format="0.0" />
                </TableCell>
                <TableCell>
                  <Typography
                    color={item.promoCodes !== '' ? 'textSecondary' : 'textPrimary'}
                    variant="body3"
                  >
                    <NumberColor value={item.DiscPrcnt} format="0.00" upper minValue={0.01} />
                  </Typography>
                </TableCell>
                <TableCell>
                  <NumberColor value={item.price} format="0.0000" />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.linetotal} format="0.00" />
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  <NumberColor value={item.VatSum} format="0.00" />
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  <NumberColor value={item.GTotal} format="0.00" />
                </TableCell>
                <TableCell>{item.promoCodes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Sumary values={receiptInfo.lines} column="gTotal" />
      </Scrollbar>
    </div>
  );
};

ReceiptLinesTable.propTypes = {
  receiptInfo: PropTypes.object.isRequired
};

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const TokenValidation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        margin: 0,
        minHeight: '100%',
        overflow: 'hidden'
        // backgroundColor: 'rgba(0, 0, 0, 0.4)'
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
        <Box sx={{ mt: 2, color: '#fff' }}>Validando sesión...</Box>
      </Box>
    </Box>
  );
};

export default TokenValidation;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// import { format } from 'date-fns';
import { Card, CardHeader, Divider, Grid } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { CustomerReturnStatusCode } from '../../../api/customer-return-definitions';
import { RenderIf } from '../../render-if';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { customerReturnsApi } from '../../../api/customer-return';
import { useApp } from '../../../hooks/use-app';
import { ConfirmationButton } from '../../common/confirmation-button';
import useProcesor from '../../../hooks/use-procesor';
import { Icon } from '@iconify/react';
import GoalButton from '../../goal-button';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import checkmarkOutline from '@iconify/icons-eva/checkmark-outline';

export const CustomerReturnDetails = (props) => {
  const { document } = props;
  const { currencySymbol, procStatus } = document;
  const [editable, setEditable] = useState(false);
  const [isCancellable, setIsCancellable] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { showNotify } = useApp();
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelAction = async () => {
    try {
      const response = await customerReturnsApi.processCustomerReturn({
        type: GadminActions.G2_CD_CANCEL,
        payload: {
          lines: [document.id],
          count: 1
        }
      });
      const { count } = response;
      if (count !== 1) {
        showNotify(`procesado  ${response?.count}. Algunos registros no se procesaron `, 'warning');
      } else showNotify(`procesado  ${count}`);
    } catch (error) {
      showNotify(`Error al procesar  ${error}`, 'error');
      console.error(error);
    }
    setOpenConfirmDialog(false);
  };

  const { procesor: handleCancel, loading: processingCancel } = useProcesor({
    handler: handleCancelAction
  });

  const handleLiquidate = async () => {
    try {
      const response = await customerReturnsApi.processCustomerReturn({
        action: GadminActions.G2_CD_LIQ,
        items: [document.id]
      });
      const { count } = response;
      if (count !== 1) {
        showNotify(`procesado  ${response?.count}. Algunos registros no se procesaron `, 'warning');
      } else showNotify(`procesado  ${count}`);
    } catch (error) {
      showNotify(`Error al procesar  ${error}`, 'error');
      console.error(error);
    }
    setOpenConfirmDialog(false);
  };

  useEffect(() => {
    setEditable(procStatus === CustomerReturnStatusCode.IN_DELIVERY);
    setIsCancellable(procStatus !== CustomerReturnStatusCode.CANCELED);
  }, [procStatus]);

  return (
    <Card variant="outlined">
      <CardHeader title="Detalle de devolución" />
      <Divider />
      <Grid container>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Cliente"
              value={`${document.cardCode} - ${document.cardName}`}
            />
            <PropertyListItem label="Dirección" value={`${document.address}`} />
            <PropertyListItem label="Vendedor" value={`${document.slpName}`} />
            <PropertyListItem label="Almacen" value={`${document.whsCode}-${document.whsName}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Fecha de registro"
              value={moment(document.registerDate).format('DD/MM/YYYY')}
            />
            <RenderIf condition={!!document.programDate}>
              <PropertyListItem
                label="Fecha de programación"
                value={moment(document.programDate).format('DD/MM/YYYY')}
              />
            </RenderIf>
            <RenderIf condition={!!document.liqDate}>
              <PropertyListItem
                label="Fecha de liquidación"
                value={moment(document.liqDate).format('DD/MM/YYYY')}
              />
            </RenderIf>
            <RenderIf condition={!!document.closeDate}>
              <PropertyListItem
                label="Fecha de cierre"
                value={moment(document.closeDate).format('DD/MM/YYYY')}
              />
            </RenderIf>
            <RenderIf condition={!!document.cancelDate}>
              <PropertyListItem
                label="Fecha de cancelación"
                value={moment(document.cancelDate).format('DD/MM/YYYY')}
              />
            </RenderIf>
            <RenderIf condition={!!document.docDate}>
              <PropertyListItem
                label="Fecha de Contabilización"
                value={moment(document.docDate).format('DD/MM/YYYY')}
              />
            </RenderIf>
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Zona"
              value={`${document.codSede} - ${document.codZon} - ${document.zonName}`}
            />
            <PropertyListItem label="Mesa" value={`${document.codMesa}-${document.mesaName}`} />
            <PropertyListItem
              label="Importe Registrado"
              value={numeral(document.registeredValue).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Importe"
              value={numeral(document.docTotal).format(`${currencySymbol}0,0.00`)}
              align="right"
            />
            <RenderIf condition={!!document.plaVeh}>
              <PropertyListItem
                label="Programación"
                value={`${document.codProg}-${document.plaVeh}`}
                align="right"
              />
            </RenderIf>

            <RenderIf condition={!!document.jrName}>
              <PropertyListItem
                label="Jefe de Reparto"
                value={`${document.codJr}-${document.jrName}`}
                align="right"
              />
            </RenderIf>
            <RenderIf condition={!!document.emName}>
              <PropertyListItem
                label="Encargado de mercadería"
                value={`${document.codEm}-${document.emName}`}
                align="right"
              />
            </RenderIf>
          </PropertyList>
        </Grid>
        <RenderIf condition={editable}>
          <AllowedGuard permission={GadminActions.G2_CD_LIQ}>
            <GoalButton
              startIcon={<Icon icon={checkmarkOutline} />}
              onClick={() => {
                setOpenConfirmDialog(true);
              }}
              label="Liquidar"
              // variant="text"
            />
          </AllowedGuard>
        </RenderIf>
        <RenderIf condition={isCancellable}>
          <AllowedGuard permission={GadminActions.G2_CD_AUTH}>
            <ConfirmationButton
              label="Anular"
              icon={<Icon icon={trash2Outline} />}
              title="Anular Orden de recojo"
              onConfirm={handleCancel}
              message="Se proce a anular la orden de recojo"
              loading={processingCancel}
            />
          </AllowedGuard>
        </RenderIf>
      </Grid>
      <ConfirmationDialog
        message="Se va a liquidar la órden de recojo"
        onCancel={onCancelConfirmationDialog}
        onConfirm={handleLiquidate}
        open={openConfirmDialog}
        title="Confirmación importante"
        variant="warning"
      />
    </Card>
  );
};

CustomerReturnDetails.propTypes = {
  document: PropTypes.object.isRequired
  // setEditMode: PropTypes.func.isRequired
};

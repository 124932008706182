import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { goalCustomerPromotionsApi } from '../api/goal/promotions/goal-customer-promotions';
import { useApp } from '../hooks/use-app';
import { useParams } from 'react-router-dom';
import { useMounted } from '../hooks/use-mounted';

const PromotionContext = createContext();

export const usePromotion = () => {
  return useContext(PromotionContext);
};

export const PromotionProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const isMounted = useMounted();

  const [promotion, setPromotion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPromotion = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await goalCustomerPromotionsApi.getPromotion({
        companyId: currentCompany,
        id: id
      });
      if (response.success) {
        if (isMounted.current) {
          setPromotion(response.data);
        }
      } else {
        if (isMounted.current) {
          setError('Error al cargar los datos de la promoción');
        }
      }
    } catch (err) {
      if (isMounted.current) {
        setError('Ocurrió un error inesperado');
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchPromotion();
  }, [id]);

  const value = {
    promotion,
    setPromotion,
    fetchPromotion,
    loading,
    error
  };

  return <PromotionContext.Provider value={value}>{children}</PromotionContext.Provider>;
};

PromotionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  promotionId: PropTypes.string
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { format } from 'date-fns';
import {
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  // Link,
  Checkbox,
  DialogActions
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';

export const GrReferenceTable = ({
  requestData: documentState,
  onRefresh,
  onSelect,
  onSelectAll,
  selectedItems,
  selectData,
  setSelectData,
  setDisabledReference
}) => {
  const [showTable, setShowTable] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const { data: documents } = documentState;
  const handleRefresh = () => {
    onRefresh?.();
  };

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }
  const handleSelect = (e, item) => {
    const isChecked = e.target.checked;
    // onSelect(e, item.id);
    if (isChecked) {
      setSelectItem([item]);
    } else {
      setSelectItem([]);
    }
  };

  const handleSelectMultiple = (e, item) => {
    onSelect(e, item.id);
    if (!e.target.checked) {
      const updatedSelectData = selectData.filter((selectedItem) => selectedItem.id !== item.id);
      setSelectData(updatedSelectData);
      return;
    }
    setSelectData([...selectData, item]);
  };
  const handleNext = () => {
    setDisabledReference(false);
    setShowTable(!showTable);
  };
  const handleBack = () => {
    setDisabledReference(true);
    setShowTable(!showTable);
  };
  const dataTable = {
    data: [
      {
        company_id: 1,
        id: 1,
        DocEntry: 1,
        CardCode: 'codigo',
        CardName: 'documento1',
        DocDate: '2023-05-19',
        DocTotal: 1,
        DocStatus: 'estado',
        Address: 'direccion',
        Ubigeo: 'ubigeo',
        ObjType: 'tipo',
        ObjTypeName: 'Nombre Tipo'
      },
      {
        company_id: 2,
        id: 2,
        DocEntry: 2,
        CardCode: 'codigo',
        CardName: 'documento2',
        DocDate: '2023-05-19',
        DocTotal: 2,
        DocStatus: 'estado',
        Address: 'direccion',
        Ubigeo: 'ubigeo',
        ObjType: 'tipo',
        ObjTypeName: 'Nombre Tipo'
      },
      {
        company_id: 3,
        id: 3,
        DocEntry: 3,
        CardCode: 'codigo',
        CardName: 'documento3',
        DocDate: '2023-05-19',
        DocTotal: 3,
        DocStatus: 'estado',
        Address: 'direccion',
        Ubigeo: 'ubigeo',
        ObjType: 'tipo',
        ObjTypeName: 'Nombre Tipo'
      }
    ],
    isLoading: false,
    reload: false
  };

  return (
    <>
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title={showTable ? 'Documentos' : 'Buscador'} sx={{ minWidth: 800 }} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_LIST}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>
      <Divider />
      {!showTable && (
        <Scrollbar>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={documents.length > 0 && selectItem.length === documents.length}
                    //   disabled={documentState.isLoading}
                    disabled
                    indeterminate={selectItem.length > 0 && selectItem.length < documents.length}
                    onChange={onSelectAll}
                  />
                </TableCell>

                {/* <TableCell>#</TableCell> */}
                <TableCell>ID</TableCell>
                <TableCell>CODIGO</TableCell>
                <TableCell>NOMBRE</TableCell>
                <TableCell>FECHA</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>UBIGEO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentState.data?.map((item) => (
                <TableRow
                  key={item.id}
                  hover
                  selected={!!selectItem.find((selectedItem) => selectedItem === item.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selectItem.find((selectedItem) => selectedItem.id === item.id)}
                      onChange={(event) => handleSelect(event, item)}
                    />
                  </TableCell>
                  {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.CardCode}</TableCell>
                  <TableCell>{item.CardName}</TableCell>
                  <TableCell>{moment(item.DocDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{item.Address}</TableCell>
                  <TableCell>{item.Ubigeo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DialogActions sx={{ marginRight: 2 }}>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              onClick={handleNext}
              disabled={selectItem.length !== 1}
            >
              Buscar
            </Button>
          </DialogActions>
        </Scrollbar>
      )}
      {showTable && (
        <Scrollbar>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={dataTable.data.length > 0 && selectedItems.length === documents.length}
                    disabled={documentState.isLoading}
                    indeterminate={
                      selectedItems.length > 0 && selectedItems.length < documents.length
                    }
                    onChange={onSelectAll}
                  />
                </TableCell>

                {/* <TableCell>#</TableCell> */}
                <TableCell>COD EMPRESA</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>CODIGO</TableCell>
                <TableCell>NOMBRE</TableCell>
                <TableCell>FECHA</TableCell>
                <TableCell>TOTAL DOC</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>UBIGEO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.data?.map((item) => (
                <TableRow
                  key={item.id}
                  hover
                  selected={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
                      onChange={(event) => handleSelectMultiple(event, item)}
                    />
                  </TableCell>
                  {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                  <TableCell>{item.company_id}</TableCell>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.CardCode}</TableCell>
                  <TableCell>{item.CardName}</TableCell>
                  <TableCell>{moment(item.DocDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{item.DocTotal}</TableCell>
                  <TableCell>{item.DocStatus}</TableCell>
                  <TableCell>{item.Address}</TableCell>
                  <TableCell>{item.Ubigeo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DialogActions sx={{ marginRight: 2 }}>
            <Button color="primary" size="medium" variant="contained" onClick={handleBack}>
              Regresar
            </Button>
          </DialogActions>
        </Scrollbar>
      )}
    </>
  );
};

GrReferenceTable.propTypes = {
  requestData: PropTypes.object,
  onRefresh: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedItems: PropTypes.array,
  selectData: PropTypes.array,
  setSelectData: PropTypes.func,
  setDisabledReference: PropTypes.func
};

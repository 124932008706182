import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus as PlusIcon } from '../icons/plus';
import { QuestionMarkOutlined as QuestionMarkIcon } from '../icons/question-mark-outlined';

const ResourceUnavailableRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  // backgroundColor: theme.palette.neutral[100],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3)
}));

export const ResourceUnavailable = (props) => {
  const { onCreate = null, message = 'No hay contenido.', ...other } = props;

  return (
    <ResourceUnavailableRoot {...other}>
      <QuestionMarkIcon sx={{ color: 'text.secondary' }} />
      <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
        {message}
      </Typography>
      {onCreate && (
        <Button
          color="primary"
          onClick={onCreate}
          startIcon={<PlusIcon />}
          sx={{ mt: 2 }}
          variant="contained"
        >
          Create Object
        </Button>
      )}
    </ResourceUnavailableRoot>
  );
};

ResourceUnavailable.propTypes = {
  onCreate: PropTypes.func,
  message: PropTypes.string
};

// import PropTypes from 'prop-types';
import { Box, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../../../property-list';
import { PropertyListItem } from '../../../property-list-item';
import { Status } from '../../../status';
import { StatusColorDocumentGR } from '../../../contest/contest-definition';
import { formatValueFuntion } from '../../../../hooks/use-format-value';

export const GrTabDocument = () => {
  const item = {
    Id: 172351,
    ClientId: 8,
    ObjType: 3015,
    DocEntry: 172128,
    DocNum: 172128,
    BaseType: 13,
    BaseEntry: 9516282,
    TaxDate: '2023-08-25T00:00:00',
    DocStatus: 'O',
    Transfered: 'N',
    Confirmed: 'N',
    Validated: 'N',
    Printed: 'N',
    SignedDate: null,
    UploadDate: null,
    SignedHash: null,
    ResponseMsg: null,
    Comments: null,
    ReceiptType: '09',
    Serie: 'T003',
    Number: 27368,
    created_at: '2023-08-25T09:28:12.147',
    created_by: 'droma',
    updated_at: null,
    updated_by: null,
    guard_name: 'GOAL',
    ShortCode: '',
    Unsubcribe: 'N',
    rejected: 'N',
    retries: 0,
    deleted_at: null,
    unsubcribedate: null,
    ticket: null,
    ticketdate: null
  };

  return (
    <Box>
      <CardHeader title="Documento" />
      <PropertyListItem label="">
        <Status color={StatusColorDocumentGR(item.DocStatus)} label="Abierto" />
      </PropertyListItem>
      <Divider />
      <Grid container>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Empresa">
              <Typography color="textSecondary" variant="body2">
                {item.ClientId || '---'}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Número de documento">
              <Typography color="textSecondary" variant="body2">
                {item.DocEntry || '---'}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Tipo de base">
              <Typography color="textSecondary" variant="body2">
                {item.BaseType || '---'}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Fecha">
              <Typography color="textSecondary" variant="body2">
                {item.TaxDate || '---'}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Reintentos">
              <Typography color="textSecondary" variant="body2">
                {item.retries || '---'}
              </Typography>
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Transferido" align="right">
              <Status color={StatusColorDocumentGR(item.Transfered)} label="No" />
            </PropertyListItem>
            <PropertyListItem label="Confirmado" align="right">
              <Status color={StatusColorDocumentGR(item.Confirmed)} label="No" />
            </PropertyListItem>
            <PropertyListItem label="Validado" align="right">
              <Status color={StatusColorDocumentGR(item.Validated)} label="No" />
            </PropertyListItem>
            <PropertyListItem label="Impreso" align="right">
              <Status color={StatusColorDocumentGR(item.Printed)} label="No" />
            </PropertyListItem>
            <PropertyListItem label="Desuscribir" align="right">
              <Status color={StatusColorDocumentGR(item.Unsubcribe)} label="No" />
            </PropertyListItem>
            <PropertyListItem label="Rechazado" align="right">
              <Status color={StatusColorDocumentGR(item.rejected)} label="No" />
            </PropertyListItem>
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Creado por">
              <Typography color="textSecondary" variant="body2">
                {item.created_by || '---'}
              </Typography>
            </PropertyListItem>
            <PropertyListItem label="Fecha creada">
              <Typography color="textSecondary" variant="body2">
                {formatValueFuntion(item.created_at, 'DD/MM/YYYY', true)}
              </Typography>
            </PropertyListItem>
            {item.updated_by && (
              <PropertyListItem label="Actualizado por">
                <Typography color="textSecondary" variant="body2">
                  {item.updated_by || '---'}
                </Typography>
              </PropertyListItem>
            )}
            {item.updated_at && (
              <PropertyListItem label="Fecha actualizada">
                <Typography color="textSecondary" variant="body2">
                  {formatValueFuntion(item.updated_at, 'DD/MM/YYYY', true)}
                </Typography>
              </PropertyListItem>
            )}
            {item.deleted_at && (
              <PropertyListItem label="Fecha eliminada">
                <Typography color="textSecondary" variant="body2">
                  {item.deleted_at || '---'}
                </Typography>
              </PropertyListItem>
            )}
          </PropertyList>
        </Grid>
      </Grid>
    </Box>
  );
};

// GrTabDocument.propTypes = {
//   item: PropTypes.object
// };

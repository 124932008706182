import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalImporterApi {
  async getTypes({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/v1/importer/resources/types?companyId=${companyId}`
    });
    return response;
  }

  async getManagerTemplateList(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, id } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/dex/manager/templates?company_id=${id}`,
      cacheItem: 'customer-manager-templates',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'id' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async getManagerTemplateResourceList(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/dex/manager/templates/list?companyId=${id}`
    });
    return response;
  }

  async postManagerTemplate({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/dex/manager/templates',
      changes: values
    });
    return response;
  }

  async updateManagerTemplate(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/dex/manager/templates/${id}`,
      changes: data
    });
    return response;
  }

  async getManagerTemplateId(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/dex/manager/templates/${id}`
    });
    return response;
  }

  // async getResponseImport(data) {
  //   const formData = new FormData();
  //   console.log('data', data);

  //   Object.keys(data).forEach((key) => {
  //     if (key !== 'path') {
  //       formData.append(key, data[key]);
  //     }
  //   });

  //   console.log('formData', formData);

  //   const response = await dataLoader.loadGoalFormData({
  //     action: data.path,
  //     changes: formData
  //   });
  //   return response;
  // }

  async getResponseImport(data) {
    // const formData = new FormData();
    // console.log('data', data);

    // Object.keys(data).forEach((key) => {
    //   if (key !== 'path') {
    //     formData.append(key, data[key]);
    //   }
    // });

    // console.log('formData', formData);

    const response = await dataLoader.loadGoalPost({
      action: data.path,
      changes: data
    });
    return response;
  }
}
const goalImporterApi = new GoalImporterApi();

export { goalImporterApi };

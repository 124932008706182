import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { CustomerReturnSourceTable } from './customer-return-source-table';

export const CustomerReturnSourceLineItems = (props) => {
  const { document } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Digitado" />
      <Divider />
      <CustomerReturnSourceTable document={document} />
    </Card>
  );
};

CustomerReturnSourceLineItems.propTypes = {
  document: PropTypes.object.isRequired
};

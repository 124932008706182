import gifFilled from '@iconify/icons-ant-design/gift-fill';

// import windowsFilled from '@iconify/icons-ant-design/windows-filled';
// material
import { Box, Button, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { dashboardApi } from '../../../api/dashboard';
import LoadingContent from '../../common/loading-content';
import { Widget } from '../../common/widget';
import { IconWrapper } from '../../common/icon-wrapper';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { useMounted } from '../../../hooks/use-mounted';
import { useData } from '../../../hooks/use-data';

export default function PromoStatics() {
  const mounted = useMounted();

  const [openProductsStatics, handleRefresh] = useData({
    sourceApi: dashboardApi.getPromoStatics,
    loadingMessage: 'Cargando nuevas promociones',
    mounted
  });

  if (openProductsStatics.isLoading || openProductsStatics.error || !openProductsStatics.data) {
    return (
      <LoadingContent loadingText={openProductsStatics.message} error={openProductsStatics.error} />
    );
  }

  return (
    <Widget>
      <Box
        md={6}
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          }
        }}
      >
        <IconWrapper icon={gifFilled} />
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Promociones vigentes{' '}
        </Typography>
        {/* <CardHeader title="Clientes nuevos" /> */}
        <Button
          color="primary"
          size="medium"
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          variant="text"
        />
      </Box>

      <Typography variant="h5">{fShortenNumber(openProductsStatics.data?.all || 0)}</Typography>
    </Widget>
  );
}

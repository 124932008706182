/* eslint class-methods-use-this:0 */

import { dataLoader } from '../../data-loader';

class FeManagementApi {
  async excludeValidation({ companyId, objtype, docentry }) {
    const response = await dataLoader.goalPost({
      action: `/gfel/validations/exclude/`,
      data: {
        company_id: companyId,
        companyId,
        objtype,
        docentry
      }
    });
    return response;
  }
}

const feManagementApi = new FeManagementApi();

export { feManagementApi };

// import { format } from 'date-fns';
import { Card } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';

import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import { FePendingsView } from '../../../api/financial-definitions';
import { despatchAdvice } from '../../../api/goal/electronic-documents/despatch-advice';
import { useData } from '../../../hooks/use-data';
import { GREPendingsFaceleTable } from './gre-pendigs-facele-table';
import { GoalAccordion } from '../../goal-accordion';

export const GREPendingsFacele = () => {
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const [documentState, handleRefresh] = useData({
    sourceApi: despatchAdvice.getFacelePendingsSummary,
    apiParameter: {
      companyId: currentCompany
    },
    loadingMessage: 'Cargando guias de remisión pendientes de envío a Facele',
    mounted
  });

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <Card variant="outlined">
      <GoalAccordion
        title={'GRE Pendientes'}
        onRefresh={documentState.data?.count == 0 ? handleRefresh : null}
        defaultExpanded={true}
      >
        <Scrollbar>
          <GREPendingsFaceleTable
            data={documentState}
            label="GRE Pendientes de envío a facele.."
            onRefresh={handleRefresh}
            onFix={() => {}}
            linkTo={`/accounting/gre`}
            view={FePendingsView.VIEW_PENDING_FACELE}
          />
        </Scrollbar>
      </GoalAccordion>
    </Card>
  );
};

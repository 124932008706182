import { useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { documentApi } from '../../../api/document';
import { Status } from '../../../components/status';
import { useMounted } from '../../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { RenderIf } from '../../../components/render-if';
import LoadingContent from '../../../components/common/loading-content';
import { DocumentLog } from '../../../components/purchases/common/document-log';
import { ReceiptDetails } from '../../../components/document/receipt/receipt-details';
import { ReceiptLines } from '../../../components/document/receipt/receipt-lines';
import { useDownloadFile } from '../../../hooks/use-download-file';
import { ReceiptGRLines } from '../../../components/document/receipt/receipt-gr-lines';
import { useData } from '../../../hooks/use-data';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GoalButton from '../../../components/goal-button';

export const Receipt = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();

  const [receiptInfoState, handleRefresh] = useData({
    sourceApi: documentApi.receiptDetail,
    apiParameter: id,
    loadingMessage: 'Cargando Comprobante',
    mounted
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    handleRefresh(id);
  }, [id]);

  const navigate = useNavigate();

  const { download } = useDownloadFile({
    apiDefinition: documentApi.receiptDetailXls,
    apiParameters: {
      ObjType: receiptInfoState.data?.ObjType,
      DocEntry: receiptInfoState.data?.DocEntry
    }
  });

  const renderContent = () => {
    if (receiptInfoState.isLoading || receiptInfoState.error) {
      return (
        <LoadingContent loadingText={receiptInfoState.message} error={receiptInfoState.error} />
      );
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Box sx={{ mb: 2 }}>
              <GoalButton
                startIcon={<ArrowLeftIcon />}
                onClick={() => navigate(-1)}
                label="regresar"
                iconOnly={true}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <Typography color="textPrimary" variant="h4">
                  # SAP {receiptInfoState.data?.SeriesName}-{receiptInfoState.data?.DocNum}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                  sx={{
                    alignItems: 'right',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Status color="error.main" label={receiptInfoState.data?.statusName} />
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            <Button
              color="primary"
              size="large"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              variant="contained"
            >
              Refresh
            </Button>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <ReceiptDetails receiptInfo={receiptInfoState.data} onRefresh={handleRefresh} />
            </Grid>

            <AllowedGuard permission={GadminActions.gCC_PROMO_UP}>
              <RenderIf condition={['F', 'G', 'D'].includes(receiptInfoState.data.EstadoMigrado)}>
                <Grid item xs={12}>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${appUrlBase}/${'promo/assigneds/'}${receiptInfoState.data.IdPedido}`}
                    underline="none"
                    variant="subtitle2"
                  >
                    Ver detalle de promociones
                  </Link>
                </Grid>
              </RenderIf>
            </AllowedGuard>
            <Grid item xs={12}>
              <ReceiptGRLines receiptInfo={receiptInfoState.data} />
            </Grid>
            <RenderIf condition={!!receiptInfoState.data.lines}>
              <Grid item xs={12}>
                <ReceiptLines receiptInfo={receiptInfoState.data} onDownload={download} />
              </Grid>
            </RenderIf>
            <Grid item xs={12}>
              <DocumentLog document={{ objType: 124, docEntry: receiptInfoState.data.IdPedido }} />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de comprobante')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

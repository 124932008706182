import { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import { Box, Card, CardContent, Grid, List, ListItem, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { StatusBadge } from '../../status-badge';
import { receiptFeeApi as invoiceApi } from '../../../api/receipt-fee';
import { useMounted } from '../../../hooks/use-mounted';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../../icons/exclamation-outlined';

export const ReceiptsFeesStats = () => {
  const theme = useTheme();
  const mounted = useMounted();

  const [invoiceState, setinvoiceState] = useState({
    isLoading: true,
    reload: true,
    minDate: '2021-01-01'
  });

  const getInvoiceStatics = useCallback(async () => {
    setinvoiceState(() => ({ isLoading: true }));
    try {
      const minDate = await invoiceApi.getStatMinDate();
      const result = await invoiceApi.getInvoiceStatics();
      if (mounted.current) {
        setinvoiceState(() => ({
          isLoading: false,
          data: result,
          minDate
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setinvoiceState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  useEffect(() => {
    getInvoiceStatics().catch(console.error);
  }, []);

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: invoiceState?.data?.map((item) => item.color),
    dataLabels: {
      enabled: false
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    labels: invoiceState?.data?.map((item) => item.name),
    legend: {
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    }
  };

  const chartSeries = invoiceState?.data?.map((item) => item.data);
  if (invoiceState.isLoading || !chartSeries) {
    return (
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            p: 3
          }}
        >
          <ExclamationOutlinedIcon />
          <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
            {invoiceState.error}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: alpha(theme.palette.info.main, 0.1),
        mb: 4
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography color="textSecondary" variant="overline">
              NC Servicio desde
            </Typography>
            <Typography color="textPrimary" sx={{ mb: 3 }} variant="h4">
              {invoiceState?.minDate}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Todos los comprobantes procesados <strong>.</strong>
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: {
                sm: 'row',
                xs: 'column'
              }
            }}
          >
            <Chart options={chartOptions} series={chartSeries} type="donut" width={150} />
            <List sx={{ flex: 1 }}>
              {invoiceState?.data?.map((item) => (
                <ListItem disableGutters key={item.name} sx={{ display: 'flex' }}>
                  <StatusBadge color={item.color} sx={{ mr: 1 }} />
                  <Typography color="textSecondary" variant="body2">
                    {item.name}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography color="textPrimary" variant="body2">
                    {numeral(item.data).format('0')}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Button, DialogActions, DialogTitle, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { InputField } from '../../input-field';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { AutocompleteField } from '../../autocomplete-field';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { goalInventoryApi } from '../../../api/goal/inventory/goal-inventory';
import { goalSalesTeamsApi } from '../../../api/goal/sales-teams/goal-sales-teams';
import { ConstantStatus } from '../../../api/application-enums';

export const SalesTeamsForm = ({
  title,
  initialValues = {},
  onClose,
  update = false,
  handleRefresh
}) => {
  const { currentCompany, showNotify, showError } = useApp();
  const [divisionsData, setDivisionsData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [customerChannelData, setCustomerChannelData] = useState([]);

  const formik = useFormik({
    initialValues: {
      code: initialValues.code || null,
      company_id: initialValues.company_id || currentCompany,
      customer_channel_id: initialValues.customer_channel_id || '',
      customer_channels_info: initialValues.customer_channels_info || '',
      division_id: initialValues.division_id || '',
      division_code: initialValues.division_code || '',
      division_info: initialValues.division_info || '',
      has_children: initialValues.has_children || false,
      manager_id: initialValues.manager_id || '',
      manager_info: initialValues.manager_info || '',
      name: initialValues.name || '',
      parent_id: initialValues.parent_id || null,
      prefix: initialValues.prefix || '',
      status: initialValues.status || ConstantStatus.ACTIVE
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      prefix: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log('values', values);
      const newValues = {};
      Object.entries(values).forEach(([key, value]) => {
        if (value === '') {
          newValues[key] = null;
        } else {
          newValues[key] = value;
        }
      });
      newValues.code = values.prefix;
      console.log('newValues', newValues);

      if (update) {
        const response = await goalSalesTeamsApi.updateSalesTeamsForm(initialValues.id, newValues);
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleRefresh();
          onClose(false);
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      } else {
        const response = await goalSalesTeamsApi.postSalesTeamsForm({
          values: newValues,
          companyId: currentCompany
        });
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleRefresh();
          onClose(false);
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      }
    }
  });

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [divisionsData, managerData, customerChannelData] = await Promise.all([
      goalInventoryApi.getDivisionsResources(currentCompany),
      goalSalesTeamsApi.getManagerResources(currentCompany),
      goalSalesTeamsApi.getCustomerChannelsResources(currentCompany)
    ]);
    if (divisionsData.success && divisionsData.data.length !== 0) {
      setDivisionsData(divisionsData.data);
    }
    if (managerData.success && managerData.data.length !== 0) {
      setManagerData(managerData.data);
    }
    if (customerChannelData.success && customerChannelData.data.length !== 0) {
      setCustomerChannelData(customerChannelData.data);
    }
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2,
            padding: 5
          }}
        >
          <Grid item xs={update ? 4 : 12}>
            <PropertyList>
              {update && (
                <InputField
                  label="Código"
                  name="code"
                  value={formik.values.code}
                  fullWidth
                  sx={{ marginBottom: '1em' }}
                  disabled
                />
              )}
              <InputField
                label="Nombre"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                sx={{ marginBottom: '1em' }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <InputField
                label="Prefijo"
                name="prefix"
                value={formik.values.prefix}
                onChange={formik.handleChange}
                fullWidth
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="Canal preferido"
                name="customer_channels_info"
                placeholder="Seleccione un canal"
                fullWidth
                options={customerChannelData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('customer_channel_id', value?.id);
                  formik.setFieldValue('customer_channels_info', value);
                }}
                value={formik.values.customer_channels_info}
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="Lider"
                name="manager_info"
                placeholder="Seleccione un manager"
                fullWidth
                options={managerData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('manager_id', value?.id);
                  formik.setFieldValue('manager_info', value);
                }}
                value={formik.values.manager_info}
                sx={{ marginBottom: '1em' }}
              />
              <AutocompleteField
                label="División"
                name="customer_channels_info"
                placeholder="Seleccione una división"
                fullWidth
                options={divisionsData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('division_id', value?.id);
                  formik.setFieldValue('division_code', value?.code);
                  formik.setFieldValue('division_info', value);
                }}
                value={formik.values.division_info}
                sx={{ marginBottom: '1em' }}
              />
            </PropertyList>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={update ? <PencilIcon /> : <PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

SalesTeamsForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  handleRefresh: Proptypes.func
};

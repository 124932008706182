import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { goalMaintenancePayrollApi } from '../../../api/goal/maintenance/goal-maintenance-payroll';
import { Query } from '../../../components/query';
import { useData } from '../../../hooks/use-data';
import { MaintenanceEmployeePositionForm } from '../../../components/maintenance/Payroll/maintenance-employee-position-form';
import { ConstantStatus } from '../../../api/application-enums';

export const MaintenanceEmployeePosition = () => {
  const query = useQuery();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const { title, currentCompany } = useApp();

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    ,
    ,
    ,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalMaintenancePayrollApi.getEmployeePositionsApi,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: ConstantStatus.ACTIVE,
      id: currentCompany
    }
  });

  const [departamentState] = useData({
    sourceApi: goalMaintenancePayrollApi.getDepartamentsList,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    apiParameter: {
      companyId: currentCompany
    }
  });

  const [configState] = useData({
    sourceApi: goalMaintenancePayrollApi.getEmployeePositionsConfig,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    apiParameter: currentCompany
  });
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title('Puestos de empleados')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Puestos de empleados
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                >
                  Refrescar
                </Button>
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ paddingX: 5 }}>
              <Query
                disabled={returnsState.isLoading}
                onChange={handleQueryChange}
                sx={{
                  marginTop: 2
                }}
                value={controller.query}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column'
              }}
            >
              <MaintenanceEmployeePositionForm
                handleRefresh={handleRefresh}
                title=""
                initialData={returnsState.isLoading ? [] : returnsState?.documents}
                configState={configState.isLoading ? {} : configState}
                departamentState={departamentState.isLoading ? [] : departamentState?.data}
              />
            </Box>
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { RenderIf } from '../../render-if';
import { Refresh } from '../../../icons/refresh';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { CommonChart } from '../../common/common-chart';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { fPercent2 } from '../../../utils/formatNumber';
import { NumberColor } from '../../number-color';
import { ConfirmationButton } from '../../common/confirmation-button';
import useProcesor from '../../../hooks/use-procesor';
import { goalCustomeDeleveryMonitoringApi } from '../../../api/goal/monitoring/goal-customer-delivery-monitoring';

export const CustomerDdeliverySumaryData = ({ productInfo, handleRefresh, dispatchId }) => {
  const {
    canceledAmount,
    canceledCount,
    completedAmount,
    completedCount,
    documentAmount,
    documentCount,
    partialAmount,
    partialCount,
    pendingAmount,
    pendingCount,
    progressAmount,
    progressPercent,
    returnAmount,
    returnCount
  } = productInfo.data.statistic[0];

  const sumaryMonitoringCount = [
    {
      name: 'Completo',
      data: completedCount,
      amount: completedAmount,
      color: '#3493DA'
    },
    {
      name: 'Parciales',
      data: partialCount,
      amount: partialAmount,
      color: '#ECE61B'
    },
    {
      name: 'Anulados',
      data: canceledCount,
      amount: canceledAmount,
      color: '#ED2518'
    },
    {
      name: 'Pendientes',
      data: pendingCount,
      amount: pendingAmount,
      color: '#1C9717'
    }
    // {
    //   name: 'Devoluciones',
    //   data: returnCount,
    //   amount: returnAmount,
    //   color: '#173497'
    // }
  ];
  const sumaryMonitoringAmount = [
    {
      name: 'Completo',
      data: parseFloat(completedAmount),
      color: '#3493DA'
    },
    {
      name: 'Parciales',
      data: parseFloat(partialAmount),
      color: '#ECE61B'
    },
    {
      name: 'Anulados',
      data: parseFloat(canceledAmount),
      color: '#ED2518'
    },
    {
      name: 'Pendientes',
      data: parseFloat(pendingAmount),
      color: '#1C9717'
    }
    // {
    //   name: 'Devoluciones',
    //   data: parseFloat(returnAmount),
    //   color: '#173497'
    // }
  ];
  const sumaryMonitoringProgress = [
    {
      name: 'Progreso',
      data: parseFloat(progressAmount),
      color: '#3493DA'
    },
    {
      name: 'Faltante',
      data: parseFloat(documentAmount) - parseFloat(progressAmount),
      color: '#ED2518'
    }
  ];

  const sumaryMonitoringReturn = [
    {
      name: 'Facturado',
      data: parseFloat(documentAmount),
      color: '#3493DA'
    },
    {
      name: 'Devuelto',
      data: parseFloat(returnAmount),
      color: '#ED2518'
    }
  ];

  const app = useApp();

  const { procesor: handleRefreshDocuments, loading: refreshingDocuments } = useProcesor({
    handler: goalCustomeDeleveryMonitoringApi.refreshDispatchDocuments,
    payload: { id: dispatchId }
  });

  return (
    <>
      <Helmet>
        <title>{app.title('Resumen de despacho')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Resumen de despacho
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_HR_DESPACH_DATE}>
                <ConfirmationButton
                  label="Actualizar Documentos"
                  icon={<Refresh />}
                  title="Actualizar Documentos"
                  onConfirm={handleRefreshDocuments}
                  message="Se proce a actualizar los documentos para el despacho"
                  loading={refreshingDocuments}
                />
              </AllowedGuard>

              <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<Refresh />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            elevation={0}
            sx={{
              mb: 2
            }}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '30px',
                    flexDirection: {
                      sm: 'row',
                      xs: 'column'
                    }
                  }}
                >
                  <RenderIf condition={productInfo?.data?.statistic?.length > 0}>
                    <CommonChart
                      data={sumaryMonitoringCount}
                      isLoading={productInfo.isLoading}
                      error={productInfo.error}
                      message={productInfo.message}
                      title={`Pedidos ${documentCount}`}
                    />
                  </RenderIf>
                  <RenderIf condition={productInfo?.data?.statistic?.length > 0}>
                    <CommonChart
                      data={sumaryMonitoringAmount}
                      isLoading={productInfo.isLoading}
                      error={productInfo.error}
                      message={productInfo.message}
                      title={`Soles S/${documentAmount}`}
                      format="#,##0.00"
                    />
                  </RenderIf>
                  <RenderIf condition={productInfo?.data?.showProgressGraph === 'Y'}>
                    <CommonChart
                      data={sumaryMonitoringProgress}
                      isLoading={productInfo.isLoading}
                      error={productInfo.error}
                      message={productInfo.message}
                      title={`Progreso ${fPercent2(progressPercent)}`}
                      format="#,##0.00"
                    />
                  </RenderIf>
                  <RenderIf condition={productInfo?.data?.statistic?.length > 0}>
                    <CommonChart
                      data={sumaryMonitoringReturn}
                      isLoading={productInfo.isLoading}
                      error={productInfo.error}
                      message={productInfo.message}
                      title={`Devoluciones ${returnCount}`}
                      format="#,##0.00"
                    />
                  </RenderIf>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
            <CardHeader title="Información del despacho" />
            <Divider sx={{ mb: 3 }} />
            <Grid container>
              <Grid item md={4} xs={12}>
                <PropertyList>
                  <PropertyListItem label="Numero de despacho">
                    <Typography color="textSecondary" variant="body2">
                      {productInfo.data.dispatchId}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {productInfo.data.docTypeName}
                    </Typography>
                  </PropertyListItem>
                  <PropertyListItem label="Jefe de Reparto">
                    <Typography color="textSecondary" variant="body2">
                      {productInfo.data.agentName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {productInfo.data.agentCode}
                    </Typography>
                  </PropertyListItem>
                  <PropertyListItem label="Líder de Mercaderia">
                    <Typography color="textSecondary" variant="body2">
                      {productInfo.data.dispatchLeaderName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {productInfo.data.dispatchLeaderCode}
                    </Typography>
                  </PropertyListItem>
                  <PropertyListItem label="Vehículo" value={`${productInfo.data.vehicleName}`} />
                  {/* <PropertyListItem label="Tipo de documeno" value={productInfo.data.doctype} /> */}
                </PropertyList>
              </Grid>
              <Grid item md={4} xs={12}>
                <PropertyList>
                  <PropertyListItem label="Código de zona" value={productInfo.data.codZon} />
                  <PropertyListItem label="Fecha de creación" value={productInfo.data.createDate} />
                  <RenderIf condition={!!productInfo.data.dateEnd}>
                    <PropertyListItem
                      label="Despacho"
                      value={`desde ${productInfo.data.dateStart} hasta ${productInfo.data.dateEnd}`}
                    />
                  </RenderIf>
                  <RenderIf condition={!!productInfo.data.openJournalBy}>
                    <PropertyListItem
                      label="Apertura de Jornada"
                      value={`${productInfo.data.openJournalBy} - ${productInfo.data.openJournalAt}`}
                    />
                  </RenderIf>
                  <RenderIf condition={!!productInfo.data.closeJournalBy}>
                    <PropertyListItem
                      label="Cierre de Jornada"
                      value={`${productInfo.data.closeJournalBy} - ${productInfo.data.closeJournalAt}`}
                    />
                  </RenderIf>
                  <RenderIf condition={!!productInfo.data.closedAt}>
                    <PropertyListItem label="Fecha de cierre" value={productInfo.data.closedAt} />
                  </RenderIf>
                  <RenderIf condition={!!productInfo.data.liquidatedBy}>
                    <PropertyListItem
                      label="Liquidación"
                      value={`${productInfo.data.liquidatedBy} - ${productInfo.data.liquidatedAt}`}
                    />
                  </RenderIf>
                  <RenderIf condition={!!productInfo.data.invoicedBy}>
                    <PropertyListItem
                      label="Facturacion"
                      value={`${productInfo.data.invoicedBy} - ${productInfo.data.invoicedAt}`}
                    />
                  </RenderIf>
                  {/* <PropertyListItem
                    label="Fecha de Registro"
                    value={moment(orderInfo.FechaRegistro).format('DD/MM/YYYY  HH:mm:ss')}
                  /> */}
                </PropertyList>
              </Grid>
              <Grid item md={4} xs={12}>
                <PropertyList>
                  <PropertyListItem label="Importe Programado">
                    <NumberColor value={productInfo.data.programedAmount} />
                  </PropertyListItem>
                  <PropertyListItem label="Importe Entregado">
                    <NumberColor value={productInfo.data.deliveredAmount} />
                  </PropertyListItem>
                  <PropertyListItem label="Importe Parciales">
                    <NumberColor value={productInfo.data.partialsAmount} />
                  </PropertyListItem>
                  <PropertyListItem label="Importe Cancelado">
                    <NumberColor value={productInfo.data.canceledAmount} />
                  </PropertyListItem>
                  <PropertyListItem label="Importe Devuelto">
                    <NumberColor value={productInfo.data.returnedAmount} />
                  </PropertyListItem>
                  <PropertyListItem label="Importe Facturado">
                    <NumberColor value={productInfo.data.invoicedAmount} />
                  </PropertyListItem>
                  <PropertyListItem label="Importe Sobrante">
                    <NumberColor value={productInfo.data.creditAmount} />
                  </PropertyListItem>
                  <PropertyListItem label="Importe Faltante">
                    <NumberColor value={productInfo.data.debitAmount} />
                  </PropertyListItem>
                </PropertyList>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </>
  );
};

CustomerDdeliverySumaryData.propTypes = {
  productInfo: PropTypes.object,
  handleRefresh: PropTypes.func,
  dispatchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

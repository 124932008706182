import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// import moment from 'moment';
import numeral from 'numeral';
import {
  Box,
  Checkbox,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import ColorByRange from './customer-range';
import { useApp } from '../../../hooks/use-app';
// import { Status } from '../../status';
// import { NumberColor } from '../../number-color';
// import { statusVariants } from '../../../api/customer-order-definitions';
// import { RenderIf } from '../../render-if';
// import { CustomerReturnsMenu } from './customer-returns-menu';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID'
  },
  {
    id: 'cardname',
    label: 'JEFE DE REPARTO'
  },
  {
    id: 'codzon',
    label: 'ZONA'
  },
  {
    id: 'totaldoc',
    label: '#DOCS'
  },
  {
    id: 'montototal',
    label: 'TOTAL'
  },
  {
    id: 'avancesoles',
    label: 'S/ATENDIDOS'
  },
  {
    id: 'avance',
    label: 'AVANCE'
  },
  {
    id: 'entregados',
    label: '#ENT'
  },
  {
    id: 'soles_entregados',
    label: 'SOLES'
  },
  {
    id: 'anulados',
    label: '#ANUL'
  },
  {
    id: 'soles_anulados',
    label: 'SOLES'
  },
  {
    id: 'parciales',
    label: '#PAR'
  },
  {
    id: 'soles_parciales',
    label: 'SOLES'
  },
  {
    id: 'pendientes',
    label: '#PEND'
  },
  {
    id: 'soles_pendientes',
    label: 'SOLES'
  },
  {
    id: 'sname',
    label: 'ESTADO'
  }
];
/**
 * docTotal, registeredValue, programedValue, vatSum
 * Status
 * D Borrador
 * L Autorizado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 */

export const CustomerDeliveryMonitoringTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate',
    setLocationState
  } = props;

  const { appUrlBase } = useApp();
  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);
  const detailRoute = `${appUrlBase}/delivery/monitoring/`;

  const handleClickLocation = (lat, long) => {
    setLocationState({
      x: long,
      y: lat
    });
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        maxHeight: 1000,
        overflow: 'auto'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow
                hover
                key={document.codusu}
                selected={
                  !!selectedDocuments.find(
                    (selectedCustomer) => selectedCustomer === document.docEntry
                  )
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find(
                        (selectedCustomer) => selectedCustomer === document.id
                      )
                    }
                    onChange={(event) => onSelect(event, document.id)}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${detailRoute}${document.id}`}
                    underline="none"
                    variant="subtitle2"
                    target="_blank"
                  >
                    #{document.id}
                  </Link>
                </TableCell>
                <TableCell
                  sx={{ minWidth: 280, cursor: 'pointer' }}
                  onClick={() => handleClickLocation('-74.93721730000000', '-14.82371720000000')}
                >
                  <Typography color="textSecondary" variant="body2">
                    {document.cardname}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.codzon}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.totaldoc).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.montototal}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.avancesoles).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <ColorByRange
                    number={Number(numeral(document.avance * 100).format(`${'S/'}0,0.00`))}
                    range={[
                      { min: 0, max: 1, color: '#000' },
                      { min: 1, max: 20, color: '#d14100' },
                      { min: 20, max: 50, color: '#FF5733' },
                      { min: 50, max: 95, color: '#28b56e' },
                      { min: 95, max: 99, color: '#99CCFF' },
                      { min: 99, max: 100, color: '#67D015' }
                    ]}
                  />
                  <Typography
                    color={document.tdoyStatusName === 'Abierto' ? '#d14100' : '#67D015'}
                    variant="body2"
                  >
                    {document.tdoyStatusName}
                  </Typography>
                  {/* {parseFloat(document.avance).toFixed(2) * 100} */}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.entregados).format(`${'S/'}0`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.soles_entregados).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.anulados).format(`${'S/'}0`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.soles_anulados).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.parciales).format(`${'S/'}0`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.soles_parciales).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.pendientes).format(`${'S/'}0`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {numeral(document.soles_pendientes).format(`${'S/'}0,0.00`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.sname}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

CustomerDeliveryMonitoringTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  setLocationState: Proptypes.func
};

import Proptypes from 'prop-types';
import { useState } from 'react';
// import moment from 'moment';
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { NumberColor } from '../../number-color';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { useDialog } from '../../../hooks/use-dialog';
import { TablesEditLine } from '../../sales-teams/tables-edit-line';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: '#'
  },
  {
    id: 'cod',
    label: 'Código'
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'warehouse',
    label: 'Almacen'
  },
  {
    id: 'calculator',
    label: 'Calculado'
  },
  {
    id: 'not_dispatched',
    label: 'No despachado'
  },
  {
    id: 'counted',
    label: 'Contado'
  },
  {
    id: 'recounted',
    label: 'Re-contado'
  },
  {
    id: 'difference',
    label: 'Diferencia'
  },
  {
    id: 'amount',
    label: 'Importe'
  },
  {
    id: 'division',
    label: 'División'
  }
];

export const CustomerDeliveryLiquidationTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const [itemData, setItemEdit] = useState(null);
  const [openCounted, handleOpenCounted, handleCloseCounted] = useDialog();
  const [openRecounted, handleOpenRecounted, handleCloseRecounted] = useDialog();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const handleClickEditCounted = (item) => {
    setItemEdit(item);
    handleOpenCounted();
  };
  const handleClickEditRecounted = (item) => {
    setItemEdit(item);
    handleOpenRecounted();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow
                hover
                key={document.id}
                selected={
                  !!selectedDocuments.find(
                    (selectedCustomer) => selectedCustomer === document.docEntry
                  )
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find(
                        (selectedCustomer) => selectedCustomer === document.id
                      )
                    }
                    onChange={(event) => onSelect(event, document.id)}
                  />
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {index + 1}
                    {false && (
                      <IconButton color="primary" aria-label="edit">
                        <PencilIcon />
                      </IconButton>
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.cod}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.warehouse}
                  </Typography>
                </TableCell>
                <TableCell>
                  <NumberColor value={document.calculator} />
                </TableCell>
                <TableCell sx={{ minWidth: 160 }}>
                  <NumberColor value={document.not_dispatched} />
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      minWidth: 160,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {openCounted && itemData?.id === document.id ? (
                      <>
                        <TablesEditLine
                          type="input"
                          item={document.counted}
                          handleClose={handleCloseCounted}
                          typeInput="number"
                        />
                      </>
                    ) : (
                      <>
                        <NumberColor value={document.counted} />
                        <PencilIcon
                          color="primary"
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => handleClickEditCounted(document)}
                        />
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      minWidth: 160,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {openRecounted && itemData?.id === document.id ? (
                      <>
                        <TablesEditLine
                          type="input"
                          item={document.recounted}
                          handleClose={handleCloseRecounted}
                          typeInput="number"
                        />
                      </>
                    ) : (
                      <>
                        <NumberColor value={document.counted} />
                        <PencilIcon
                          color="primary"
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => handleClickEditRecounted(document)}
                        />
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <NumberColor value={document.difference} />
                </TableCell>
                <TableCell>
                  <NumberColor value={document.amount} />
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.division}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

CustomerDeliveryLiquidationTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Typography } from '@mui/material';

import { customerReturnsApi } from '../../api/customer-return';

import { useMounted } from '../../hooks/use-mounted';
import { useSelection } from '../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';

import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { CustomerReturnsFilter } from '../../components/document/returns/customer-returns-filter';
import { CustomerReturnsTable } from '../../components/document/returns/customer-returns-table';
import { CustomerReturnsStats } from '../../components/document/returns/customer-returns-stats';
import { CustomerReturnViews } from '../../api/customer-return-definitions';
import { useDataBrowser } from '../../hooks/use-data-browser';
import { GoalButton } from '../../components/goal-button';

export const CustomerReturns = () => {
  const mounted = useMounted();
  const app = useApp();

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
    // handleSetController
  ] = useDataBrowser({
    sourceApi: customerReturnsApi.getCustomeReturns,
    loadingMessage: 'Buscando devoluciones...',
    mounted,
    defaults: {
      view: CustomerReturnViews.DRAFT
    }
    // onPostRefresh: handleClearSelected
  });

  const [selectedDocuments, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    returnsState.documents
  );
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    handleClearSelected();
  }, [returnsState.documents]);

  return (
    <>
      <Helmet>
        <title>{app.title('Devoluciones del cliente')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Ordenes de recojo
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
                <GoalButton startIcon={<RefreshIcon />} onClick={handleRefresh} label="Refrescar" />
              </AllowedGuard>
            </Box>
          </Box>
          <CustomerReturnsStats />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <CustomerReturnsFilter
              disabled={returnsState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedDocuments={selectedDocuments}
              view={controller.view}
              onRefresh={handleRefresh}
            />
            <Divider />
            <CustomerReturnsTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

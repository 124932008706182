import { useState } from 'react';
import { useApp } from './use-app';

const useProcesor = ({ handler, payload }) => {
  const { showNotify, showError } = useApp();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const procesor = async (params) => {
    setLoading(true);
    const response = await handler({ ...payload, ...params });
    if (response.success) {
      setData(response.data);
      showNotify(response.message);
    } else {
      setError(response.message);
      showError(response.message);
    }
    setLoading(false);
  };

  return { data, loading, error, procesor };
};

export default useProcesor;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, Checkbox, LinearProgress, TextField } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { CommonHelpText } from './common/common-help-text';
import { XCircle } from '../icons/x-circle';

export const AutocompleteMultiselectField = (props) => {
  const {
    error,
    helperText,
    label,
    options,
    handleChange,
    placeholder,
    message = '',
    selectedOptions = [],
    statusLoading = false,
    bgColor = 'rgba(145, 158, 171, 0.12)',
    colorText = '#000',
    useCode = false,
    ...other
  } = props;

  const [inputValue, setInputValue] = useState('');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {statusLoading ? (
        <Box sx={{ width: '100%', marginBottom: '2em', marginTop: '1em' }}>
          <LinearProgress />
        </Box>
      ) : (
        <Autocomplete
          multiple
          fullWidth
          sx={{
            '& .MuiFilledInput-root .MuiFilledInput-input': {
              px: 1.5,
              py: 0.75
            }
          }}
          options={options}
          value={selectedOptions}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue, reason) => {
            if (reason !== 'reset' && reason !== 'clear') {
              setInputValue(newInputValue);
            }
          }}
          disableCloseOnSelect
          // isOptionEqualToValue={(option, value) => option.code === value.code}
          isOptionEqualToValue={(option, value) =>
            useCode ? option.code === value.code : option.id === value.id
          }
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option.name}
            </li>
          )}
          renderInput={(rest) => (
            <TextField
              {...rest}
              error={error}
              helperText={helperText}
              label={label}
              placeholder={placeholder}
              sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: 'background.paper',
                  // borderWidth: 1,
                  // borderStyle: 'solid',
                  // borderColor: 'neutral.300',
                  borderRadius: 1,
                  boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
                  '&.MuiAutocomplete-inputRoot': {
                    p: 0
                  },
                  transition: (theme) => theme.transitions.create(['border-color', 'box-shadow']),
                  '&:hover': {
                    backgroundColor: 'background.paper'
                  },
                  '&.Mui-focused': {
                    backgroundColor: 'background.paper',
                    boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
                  },
                  '& .MuiFilledInput-input': {
                    fontSize: 14,
                    height: 'unset',
                    lineHeight: 1.6,
                    px: 1.5,
                    py: 0.75
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'action.disabledBackground',
                    boxShadow: 'none',
                    borderColor: alpha('#D6DBE1', 0.5)
                  },
                  ':not(.MuiInputBase-adornedStart)': {
                    p: 0
                  }
                }
              }}
              variant="filled"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: 'text.primary',
                  fontSize: 14,
                  fontWeight: 500,
                  mb: 0.5,
                  position: 'relative',
                  transform: 'none'
                }
              }}
            />
          )}
          ChipProps={{
            variant: 'outlined',
            deleteIcon: <XCircle style={{ color: '#FE4E4E' }} />,
            sx: {
              '&.MuiChip-outlined': {
                backgroundColor: bgColor,
                fontWeight: '400',
                fontSize: '0.7em',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'neutral.300',
                color: colorText
              }
            }
          }}
          {...other}
        />
      )}
      <CommonHelpText message={message} />
    </Box>
  );
};

AutocompleteMultiselectField.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  selectedOptions: PropTypes.array,
  handleChange: PropTypes.func,
  statusLoading: PropTypes.bool,
  bgColor: PropTypes.string,
  colorText: PropTypes.string,
  useCode: PropTypes.bool
};

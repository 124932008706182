import PropTypes from 'prop-types';
import { useApp } from '../hooks/use-app';
import GoalButton from './goal-button';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const GoalRecordLink = (props) => {
  const {
    label = '',
    title = 'Ver detalle',
    to,
    onClick,
    children,
    fullPath = false,
    target = '_self',
    variant = 'text',
    editable = false
  } = props;
  const { appUrlBase } = useApp();
  const detailRoute = to ? `${appUrlBase}${to}` : null;
  const icon = editable ? (
    <EditIcon fontSize="small" color="primary" sx={{ fontSize: 12, mr: 1 }} />
  ) : (
    <StarIcon fontSize="small" color="secondary" sx={{ fontSize: 12, mr: 1 }} />
  );

  if (to && variant === 'text') {
    return (
      <Tooltip title={label}>
        <Link
          color="inherit"
          component={RouterLink}
          underline="hover"
          variant="subtitle2"
          to={fullPath ? to : detailRoute}
          rel="noopener noreferrer"
          target={target}
        >
          {icon}
          {children ??
            (label ? (
              <Typography variant="button" color="textPrimary">
                {label}
              </Typography>
            ) : null)}
        </Link>
      </Tooltip>
    );
  }

  return (
    <GoalButton
      to={fullPath ? to : detailRoute}
      label={`${title ?? label}`}
      startIcon={icon}
      variant={variant == 'button' ? 'contained' : variant}
      onClick={onClick}
      target={target}
    >
      {children ?? (
        <Typography variant="button" color="textPrimary">
          {label}
        </Typography>
      )}
    </GoalButton>
  );
};

export default GoalRecordLink;

GoalRecordLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  fullPath: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'filled', 'outlined', 'button']),
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top', 'framename']),
  editable: PropTypes.bool
};

import { appDefinitions } from '../app-definitions';
import { dataLoader } from './../data-loader';

/* eslint class-methods-use-this:0 */

class RolesApi {
  async getRoles(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `auth/roles`,
      cacheItem: 'roles',
      apiParams: {
        companyId,
        type: 'list-roles',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async getRole({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/roles/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getPermissionsOfRole({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/roles/${id}/permissions`,
      params: { companyId: companyId, view: view, page: page, per_page: perPage, search: search }
    });
    return response;
  }

  async addPermissionsToRole(id, values) {
    const response = await dataLoader.loadGoalPost({
      action: `/auth/roles/${id}/add-permissions`,
      changes: values
    });
    return response;
  }

  async getRoleUsers({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/roles/${id}/users`,
      params: { companyId: companyId, view: view, page: page, per_page: perPage, search: search }
    });
    return response;
  }

  async deleteRolePermission({ companyId, id, permissionId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/auth/roles/${id}/permissions/${permissionId}`,
      apiParams: { companyId }
    });
    return response;
  }
}

export const rolesApi = new RolesApi();

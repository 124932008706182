import { useState } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Link,
  IconButton
} from '@mui/material';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import Scrollbar from '../Scrollbar';
import GadminActions from '../../contexts/gadmin-actions';
// import { PromotionsForm } from './promotions-form';
import { Status } from '../status';
import { useApp } from '../../hooks/use-app';
import { CustomerModal } from '../delivery/monitoring/customer-modal';
import { IncidentsForm } from './incidents-form';
import { AllowedGuard } from '../../hooks/use-allowed';
import { IncidentsItemMenu } from './incidents-item-menu';
import { Eye as EyeIcon } from '../../icons/eye';

const columns = [
  {
    id: 'code',
    disablePadding: true,
    label: 'Código'
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'company_name',
    label: 'Empresa'
  },
  {
    id: 'status',
    label: 'Estado'
  },
  {
    id: 'resolved',
    label: 'Resuelto'
  },
  {
    id: 'type_incidents',
    label: 'Tipo de incidencia'
  },
  {
    id: 'employee_position_code',
    label: 'Puesto de empleado'
  },
  {
    id: 'description',
    label: 'Mensaje'
  }
  // {
  //   id: 'relational',
  //   label: 'Relacionado'
  // }
];

export const IncidentsTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate',
    handleRefresh
  } = props;

  const statusColorDefinitions = {
    D: 'info.main',
    P: 'warning.main',
    A: 'success.main',
    C: 'error.light',
    L: 'error.main',
    R: 'error.dark'
  };

  const { companyState, appUrlBase } = useApp();

  const getNameById = (id) => {
    const nameTableCompany = companyState?.data.find((item) => item.id === id);
    return nameTableCompany ? nameTableCompany.short_name : id;
  };

  const StatusColor = (value) => statusColorDefinitions[value] || 'warning.main';

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const [open, onClose] = useState(false);
  const [itemData, setitemEdit] = useState(null);

  const handleClickEditIcon = (item) => {
    setitemEdit(item);
    onClose(!open);
  };

  const detailRoute = `${appUrlBase}/incidents/incidents/`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1, marginLeft: 3 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow hover key={index}>
                <TableCell
                  sx={{
                    minWidth: 150
                  }}
                >
                  <Typography color="textSecondary" variant="body2">
                    {document?.code}
                    <IconButton>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`${detailRoute}${document.id}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        <EyeIcon />
                      </Link>
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {getNameById(parseFloat(document.company_id))}
                  </Typography>
                </TableCell>
                <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Status color={StatusColor(document.status)} label="Activo" />
                  <AllowedGuard permission={GadminActions.G2_CONTESTS_UP}>
                    <IncidentsItemMenu
                      items={document}
                      clickedit={() => handleClickEditIcon(document)}
                      handlerefresh={handleRefresh}
                      detail={false}
                    />
                  </AllowedGuard>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.resolved ? 'Si' : 'No'}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document?.type_incidents}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  {document?.employee_position.map((item, index) => (
                    <Typography key={index} color="textSecondary" variant="body2">
                      {item.name}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document?.description}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />

      <CustomerModal
        open={open}
        onClose={onClose}
        permission={GadminActions.G2_CONTESTS_UP}
        widhtForm="lg"
      >
        <IncidentsForm
          update
          onClose={onClose}
          handleRefresh={handleRefresh}
          title="Información de Incidencias"
          initialValues={itemData}
        />
      </CustomerModal>
    </Box>
  );
};

IncidentsTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  handleRefresh: Proptypes.func
};

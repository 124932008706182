import { useEffect } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { Box, Divider, FormHelperText, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { AllowedGuard } from '../../../../hooks/use-allowed';
import GadminActions from '../../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../../icons/plus';

import { RenderIf } from '../../../render-if';
import { useFormChecker } from '../../../../hooks/use-form-checker';
import { useFormSaver } from '../../../../hooks/use-form-saver';
import { customeDeleveryMonitoringApi } from '../../../../api/customer-delivery-monitoring';
import { useData } from '../../../../hooks/use-data';
import { useMounted } from '../../../../hooks/use-mounted';
import LoadingContent from '../../../common/loading-content';
import { AutocompleteField } from '../../../autocomplete-field';

export const AssignForm = (props) => {
  const {
    currentLine = {},
    onAfterSave,
    dialogMode = true,
    dialogSaving = false,
    setDialogSaving
  } = props;
  // console.log('currentLine', currentLine);
  const mounted = useMounted();

  const handleAfterSave = () => {
    handleClose();
    onAfterSave?.();
  };
  const handleClose = () => {
    if (dialogMode) {
      setDialogSaving(false);
    }
  };

  const [handleUpdate] = useFormSaver({
    originalData: currentLine,
    apiUpdateHandler: customeDeleveryMonitoringApi.saveAssign,
    id: 'id',
    handleAfterSave
  });

  const [employeesState, handleRefreshData] = useData({
    sourceApi: customeDeleveryMonitoringApi.getAssign,
    apiParameter: {
      id: currentLine.dispatchId,
      teamId: currentLine.id,
      position: currentLine.position
    },
    defaults: {
      noAutoLoad: true
    },
    loadingMessage: 'Cargando informacion de los motivos',
    mounted
  });

  useEffect(() => {
    handleRefreshData();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      position: currentLine.position || '',
      // name: currentLine.name || '',
      // code: currentLine.code || '',
      additional: currentLine.additional || '',
      authorized_at: currentLine.authorized_at || '',
      authorized_by: currentLine.authorized_by || '',
      canEdit: currentLine.canEdit || '',
      cardCode: currentLine.cardCode || '',
      cardName: currentLine.cardName || '',
      confirmed: currentLine.confirmed || '',
      confirmed_at: currentLine.confirmed_at || '',
      confirmed_by: currentLine.confirmed_by || '',
      created_at: currentLine.created_at || '',
      created_by: currentLine.created_by || '',
      dispatchId: currentLine.dispatchId || '',
      documentNumber: currentLine.documentNumber || '',
      guard_name: currentLine.guard_name || '',
      id: currentLine.id || '',
      positionName: currentLine.positionName || '',
      type: currentLine.type || '',
      typeName: currentLine.typeName || '',
      updated_at: currentLine.updated_at || '',
      updated_by: currentLine.updated_by || '',
      value: currentLine.value || '',
      employe: {
        code: currentLine.cardCode,
        name: currentLine.cardName
      }
    },
    validationSchema: Yup.object().shape({
      cardName: Yup.string().required('Este campo es obligatorio'),
      cardCode: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values, helpers) => {
      // console.log('values', values);
      // console.log('helpers', helpers);
      await handleUpdate({ values, helpers });
    }
  });
  const [checkForm] = useFormChecker({ formik, setSaving: setDialogSaving });

  useEffect(() => {
    if (dialogSaving && !formik.isSubmitting) {
      checkForm();
      formik.submitForm();
      if (dialogSaving) setDialogSaving(false);
    }
  }, [dialogSaving]);

  useEffect(() => {
    setDialogSaving(formik.isSubmitting);
  }, [formik.isSubmitting]);

  if (employeesState.isLoading || employeesState.displayError) {
    return (
      <LoadingContent
        style={{ padding: '20px' }}
        loadingText={employeesState.message}
        error={employeesState.displayError}
      />
    );
  }

  return (
    <Box>
      <Divider />
      <form
        onSubmit={async (values, helpers) => {
          checkForm();
          await formik.handleSubmit(
            // { ...values, cardCode: values.employe.code, cardName: values.employe.name },
            values,
            helpers
          );
        }}
        style={{ padding: '20px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AutocompleteField
              name="cardName"
              placeholder="Seleccione empleado"
              fullWidth
              options={employeesState?.data || [{ name: 'Cargando', code: 'Cargando' }]}
              getOptionLabel={(option) => option.name || 'sin Nombre'}
              // getOptionLabel={(option) => option || 'sin Nombre'}
              onChange={(e, value) => {
                // console.log('value', value);
                formik.setFieldValue('employe', value);
                formik.setFieldValue('cardCode', value?.code);
                formik.setFieldValue('cardName', value?.name);
              }}
              error={Boolean(formik.touched.employe?.code && formik.errors.employe?.code)}
              helperText={formik.touched.employe?.code && formik.errors.employe?.code}
              value={formik.values.employe}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              id="cardCode"
              label="Code"
              value={formik.values.cardCode}
              error={formik.touched.cardCode && Boolean(formik.errors.cardCode)}
              helperText={formik.touched.cardCode && formik.errors.cardCode}
              onBlur={formik.handleBlur}
              readOnly
              disabled
            />
          </Grid> */}
          {formik.errors.submit && (
            <Grid item xs={12}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Grid>
          )}
        </Grid>

        <RenderIf condition={!dialogMode}>
          <AllowedGuard permission={GadminActions.gCC_TDOY_TEAM_ASSIGN}>
            <LoadingButton
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              variant="contained"
              type="submit"
              loading={formik.isSubmitting}
            >
              Guardar
            </LoadingButton>
          </AllowedGuard>
        </RenderIf>
      </form>
    </Box>
  );
};

AssignForm.propTypes = {
  currentLine: PropTypes.object,
  // editable: PropTypes.bool,
  onAfterSave: PropTypes.func.isRequired,
  dialogMode: PropTypes.bool,
  dialogSaving: PropTypes.bool,
  setDialogSaving: PropTypes.func
};

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { goalIncidentsApi } from '../../../api/goal/incidents/goal-incidents';
import { Query } from '../../../components/query';
import { useData } from '../../../hooks/use-data';
import { MaintenanceIncidentCategoriesForm } from '../../../components/maintenance/incidents/maintenance-incident-categories-form';
import { ConstantStatus } from '../../../api/application-enums';

export const MaintenanceIncidentCategories = () => {
  const query = useQuery();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const { title, currentCompany } = useApp();

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    ,
    ,
    ,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalIncidentsApi.getIncidentsApi,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: ConstantStatus.ACTIVE,
      companyId: currentCompany
    }
  });

  const [configState] = useData({
    sourceApi: goalIncidentsApi.getIncidentsConfig,
    loadingMessage: 'Cargando configuración',
    mounted,
    apiParameter: currentCompany
  });

  const [referenceType] = useData({
    sourceApi: goalIncidentsApi.getIncidentsReferencesTypes,
    loadingMessage: 'Cargando tipos de referencias',
    mounted,
    apiParameter: { companyId: currentCompany }
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title('Categorías de incidencias')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Categorías de incidencias
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_TEAM_BRANDS}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ paddingX: 5 }}>
              <Query
                disabled={returnsState.isLoading}
                onChange={handleQueryChange}
                sx={{
                  marginTop: 2
                }}
                value={controller.query}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column'
              }}
            >
              <MaintenanceIncidentCategoriesForm
                handleRefresh={handleRefresh}
                title=""
                initialData={returnsState.isLoading ? [] : returnsState?.documents}
                configState={configState.isLoading ? {} : configState}
                referenceType={referenceType.isLoading ? [] : referenceType?.data}
              />
            </Box>
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

import { useCallback, useEffect, useState } from 'react';

export const useData = ({
  sourceApi,
  apiParameter,
  loadingMessage,
  mounted,
  defaults,
  afterLoad
}) => {
  const [documentState, setDocumentState] = useState({
    isLoading: !defaults?.noAutoLoad,
    reload: !defaults?.noAutoLoad
  });
  const [controller, setController] = useState({
    reload: !defaults?.noAutoLoad,
    parameters: apiParameter
  });
  const getDocument = useCallback(async () => {
    if (controller.reload) {
      if (mounted.current) {
        setDocumentState(() => ({
          ...documentState,
          isLoading: true,
          message: loadingMessage,
          displayLoading: true,
          displayError: false,
          displayUnavailable: false
        }));
      }
      try {
        // const result = await sourceApi(apiParameter);
        const result = await sourceApi(controller.parameters);
        if (mounted.current) {
          setDocumentState(() => ({
            ...result,
            isLoading: false,
            reload: false,
            isLoaded: true,
            displayLoading: false,
            displayError: !result.success,
            displayUnavailable: result.success && !result.data
          }));
          if (result.success && afterLoad) afterLoad(result);
        }
      } catch (err) {
        console.error('useDataError', err);
        if (mounted.current) {
          setDocumentState(() => ({
            isLoading: false,
            error: err.message,
            real: true,
            displayError: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);

  const handleRefresh = (parameters) => {
    // console.log('handleRefresh', parameters);
    // console.log('controller.parameters', controller.parameters);

    // if parameter is not SyntheticBaseEvent
    if (parameters && parameters._reactName === 'onClick') {
      setController({
        ...controller,
        reload: true
        // parameters
      });
    } else {
      setController({
        ...controller,
        reload: true,
        parameters:
          typeof controller.parameters === 'object'
            ? { ...controller.parameters, ...parameters }
            : controller.parameters
      });
    }
  };

  return [documentState, handleRefresh];
};

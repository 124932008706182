import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Refresh } from '../../../icons/refresh';
import { useApp } from '../../../hooks/use-app';
import { DeliveryVehicleDetail } from '../../../components/delivery/vehicle/delivery-vehicle-detail';
import { useData } from '../../../hooks/use-data';
import { vehicleApi } from '../../../api/goal/operations/vehicles/vehicle';
import { useMounted } from '../../../hooks/use-mounted';
import GoalButton from '../../../components/goal-button';

export const VehicleDetailContainer = () => {
  const { id } = useParams();
  const { title, currentCompany } = useApp();
  const mounted = useMounted();

  const [vehicleState, handleRefresh] = useData({
    sourceApi: vehicleApi.vehicleDetail,

    apiParameter: {
      id,
      companyId: currentCompany
    },
    mounted
  });

  return (
    <>
      <Helmet>
        <title>{title('Veh ' + id)} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <GoalButton
                  startIcon={<Refresh />}
                  onClick={handleRefresh}
                  variant="contained"
                  label={'Refresh'}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <DeliveryVehicleDetail dataVehicle={vehicleState.data} />
        </Container>
      </Box>
    </>
  );
};

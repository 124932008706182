import PropTypes from 'prop-types';
import { Button, DialogActions, Grid, Typography } from '@mui/material';
import GadminActions from '../../contexts/gadmin-actions';
import { AllowedGuard } from '../../hooks/use-allowed';
import { Plus as PlusIcon } from '../../icons/plus';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { documentStatusViews } from '../../api/app-definitions';
import { CommonHelpText } from '../common/common-help-text';

export const ContestQuotaDetail = ({
  setEditMode,
  item,
  setSelectAction,
  handleReset,
  // setSelectActionEdit,
  contestState
}) => {
  const handleAddButton = () => {
    setEditMode(true);
    setSelectAction('create-form');
    handleReset();
    // setSelectActionEdit(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PropertyList>
          <PropertyListItem align="end" label="Puesto">
            <Typography color="textSecondary" variant="body2">
              {item === null ? '---' : item.employee_position?.name}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Tipo">
            <Typography color="textSecondary" variant="body2">
              {item === null ? '---' : item.type}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Valor">
            <Typography color="textSecondary" variant="body2">
              {item === null ? '---' : item.value}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <AllowedGuard permission={GadminActions.G2_CONTESTS_UP}>
            <Button
              color="primary"
              size="large"
              startIcon={<PencilIcon />}
              // onClick={handleEditButton}
              onClick={() => {
                setEditMode(true);
              }}
              variant="contained"
              sx={{ marginRight: 2 }}
              disabled={contestState.status !== documentStatusViews.VIEW_DRAFT}
            >
              Editar
            </Button>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleAddButton}
              variant="contained"
            >
              Agregar
            </Button>
            <CommonHelpText message="El botón agregar, nos permite crear una cuota dentro del concurso, asignando un puesto de empleado y que tipo de cuota pertenece y asignandole un valor" />
          </AllowedGuard>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

ContestQuotaDetail.propTypes = {
  item: PropTypes.object,
  setEditMode: PropTypes.func,
  setSelectAction: PropTypes.func,
  handleReset: PropTypes.func,
  // setSelectActionEdit: PropTypes.func,
  contestState: PropTypes.object
};

import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { ConstantStatus } from '../../api/application-enums';
import { rolesApi } from '../../api/managment/roles';

const RolesContext = createContext();

const useRole = () => {
  return useContext(RolesContext);
};

const RolesProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const isMounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view')?.toUpperCase() || ConstantStatus.ACTIVE;

  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [addPermission, setAddPermission] = useState(false);

  const fetchRole = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await rolesApi.getRole({
        companyId: currentCompany,
        id,
        view
      });
      if (response.success) {
        if (isMounted.current) {
          setRole(response.data);
        }
      } else {
        if (isMounted.current) {
          setError('Error al cargar los datos del rol');
        }
      }
    } catch (err) {
      if (isMounted.current) {
        setError('Ocurrió un error inesperado');
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRole();
  }, [id]);

  const value = {
    role,
    setRole,
    fetchRole,
    loading,
    error,
    editMode,
    setEditMode,
    addPermission,
    setAddPermission
  };
  return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>;
};

RolesProvider.propTypes = { children: PropTypes.node.isRequired };

export { RolesProvider, useRole };

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 16
  },
  billTo: {
    marginTop: 2,
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique'
  }
});

const CustomerReturnHeader = ({ customerReturn }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>
      Fecha de registro : {moment(customerReturn.registerDate).format('DD/MM/YYYY')}
    </Text>
    <Text> {`Cliente: ${customerReturn.cardCode} - ${customerReturn.cardName} `}</Text>
    <Text>Direccion: {customerReturn.address}</Text>
    <Text>Informacion detallada:</Text>
    <Text>{`${customerReturn.plaVeh} - ${customerReturn.codJr} - ${customerReturn.jrName}  `}</Text>
  </View>
);

CustomerReturnHeader.propTypes = {
  customerReturn: PropTypes.object.isRequired
};
export default CustomerReturnHeader;

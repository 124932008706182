import { ConstantYesNo } from '../../application-enums';
import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalCustomerPromotionsApi {
  async getPromotionsList(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, id } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions?companyId=${id}`,
      cacheItem: 'customer-promotion-list',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'statusName' },
        { type: 'string', name: 'company_name' },
        { type: 'string', name: 'vendor' },
        { type: 'string', name: 'name' },
        { type: 'number', name: 'limit' },
        { type: 'number', name: 'assigned' },
        { type: 'number', name: 'remaining' },
        { type: 'string', name: 'typeName' },
        { type: 'string', name: 'bonificationTypeName' }
      ],
      ...options
    });
  }

  async getPromotions(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      companyId,
      idsToExclude = ''
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions`,
      cacheItem: 'list-promotions',
      apiParams: {
        companyId,
        idsToExclude,
        type: 'list-promotions',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'statusName' },
        { type: 'string', name: 'company_name' },
        { type: 'string', name: 'vendor' },
        { type: 'string', name: 'name' },
        { type: 'number', name: 'limit' },
        { type: 'number', name: 'assigned' },
        { type: 'number', name: 'remaining' },
        { type: 'string', name: 'typeName' },
        { type: 'string', name: 'bonificationTypeName' }
      ],
      ...options
    });
  }

  async getFindPromotions(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      companyId,
      idsToExclude = '',
      searchText = {}
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions/findPromotions`,
      cacheItem: 'find-promotions',
      apiParams: {
        companyId,
        idsToExclude,
        searchText,
        type: 'list-find-promotions',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'vendor' }
      ],
      ...options
    });
  }

  async getPromotion({ companyId, id }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}?companyId=${companyId}`
    });
    return response;
  }

  async postPromotionsForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/promotion/promotions',
      changes: values
    });
    return response;
  }

  async updatePromotionsForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/promotion/promotions/${id}`,
      changes: data
    });
    return response;
  }

  async deletePromotionsItem(id) {
    const response = await dataLoader.loadGoalDelete({
      action: `/promotion/promotions/${id}`
    });
    return response;
  }

  async getCompaniesList() {
    const response = await dataLoader.loadGoalGetData({
      action: '/promotion/resources/companies'
    });
    return response;
  }

  async getSuppliersList(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/suppliers?companyId=${id}`
    });
    return response;
  }

  async getPaymentGroupsList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/payment-groups?companyId=${companyId}`
    });
    return response;
  }

  async getCustomerGroupsList(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/customer-groups?companyId=${id}`
    });
    return response;
  }

  async getPromotionsCancele(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}/cancel`
    });
    return response;
  }

  async getPromotionsActivate(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}/activate`
    });
    return response;
  }

  async getPromotionsPause(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}/pause`
    });
    return response;
  }

  async getPromotionsClose(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}/close`
    });
    return response;
  }

  async getPromotionsValidate(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}/validate`
    });
    return response;
  }

  async getPromotionsReject(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}/reject`
    });
    return response;
  }

  async getPromotionsArchived(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/promotions/${id}/archive`
    });
    return response;
  }

  async getSourceTypeList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/source-types?companyId=${companyId}`
    });
    return response;
  }

  async getExcludeTypeList({ companyId, sourceType }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/exclude-types?companyId=${companyId}&sourceType=${sourceType}`
    });
    return response;
  }

  async getSelectListItem({ companyId, sourceType, supplierId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/list-items?companyId=${companyId}&sourceType=${sourceType}&supplierId=${supplierId}`
    });
    return response;
  }

  async getProductFavorite(props) {
    const { companyId, promotionType, sourceType, supplierId, excludeBy, items, excludeItems } =
      props;
    const params = `?companyId=${companyId}&promotionType=${promotionType}&sourceType=${sourceType}&supplierId=${supplierId}&items=${items}&excludeType=${excludeBy}&excludeItems=${excludeItems}`;
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/selected-products${params}`
    });
    return response;
  }

  async getSelectExcludeItem(props) {
    const { companyId, promotionType, sourceType, supplierId, excludeBy, items = '' } = props;
    const params = `?companyId=${companyId}&promotionType=${promotionType}&sourceType=${sourceType}&supplierId=${supplierId}&items=${items}&excludeType=${excludeBy}`;
    const response = await dataLoader.loadGoalGetData({
      action: `/promotion/resources/list-exclude-items${params}`
    });
    return response;
  }

  async getPromotionChannels(options) {
    const { filters, sort, sortBy, page, query, view, reload, id, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions/${id}/channels`,
      cacheItem: 'PromotionChannels',
      apiParams: {
        companyId,
        type: 'list-promotion-channels',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'branch_name' },
        { type: 'number', name: 'amount' },
        { type: 'string', name: 'independent' },
        { type: 'boolean', name: 'active' }
      ],
      ...options
    });
  }

  async getPromotionClients(options) {
    const { filters, sort, sortBy, page, query, view, reload, id, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions/${id}/clients`,
      cacheItem: 'PromotionClients',
      apiParams: {
        companyId,
        type: 'list-promotion-clients',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' }
        // { type: 'number', name: 'amount' },
        // { type: 'string', name: 'independent' },
        // { type: 'boolean', name: 'active' }
      ],
      ...options
    });
  }

  async getPromotionExcludedCustomers(options) {
    const { filters, sort, sortBy, page, query, view, reload, id, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions/${id}/excluded-customers`,
      cacheItem: 'PromotionClients',
      apiParams: {
        companyId,
        type: 'list-promotion-excluded-customers',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async updateChannelOfBranch({ promotionId, branchId, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/promotion/promotions/${promotionId}/channels/${branchId}`,
      changes
    });
    return response;
  }

  async getBranchsByDefault() {
    const branches = [
      { id: 1, code: 'CH', name: 'CHINCHA' },
      { id: 2, code: 'PS', name: 'Pisco' },
      { id: 3, code: 'IC', name: 'Ica' }
    ];

    const branchesJson = [];

    function randomDate(start, end) {
      return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
        .toISOString()
        .split('T')[0]; // Formato de fecha YYYY-MM-DD
    }

    branches.forEach((branch) => {
      const channels = [];

      for (let i = 1; i <= 20; i++) {
        const channelId = branch.id * 20 - 20 + i;
        const initialDate = randomDate(new Date(2022, 0, 1), new Date(2022, 11, 31));
        const finalDate = randomDate(new Date(2023, 0, 1), new Date(2023, 11, 31));
        const amount = Math.floor(Math.random() * 1000);
        const reference = Math.random() > 0.5 ? 'MIN' : 'MAY';

        const code = reference === 'MIN' ? '01' : '02';
        const name = reference === 'MIN' ? 'MINORISTA' : 'MAYORISTA';

        channels.push({
          id: channelId,
          reference: reference,
          name: `channel-${i}${branch.code}-${code} - ${name}`,
          independent: 'N',
          initialDate: initialDate,
          finalDate: finalDate,
          amount: amount,
          active: ConstantYesNo.NO
        });
      }

      branchesJson.push({
        id: branch.id,
        code: branch.code,
        name: branch.name,
        channels: channels
      });
    });

    return { success: true, data: branchesJson, message: 'Proceso realizado correctamente' };
  }

  async getPromotionTables(options) {
    const { filters, sort, sortBy, page, query, view, reload, id, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions/${id}/tables`,
      cacheItem: 'PromotionChannels',
      apiParams: {
        companyId,
        type: 'list-promotion-tables',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async getPromotionExcluded(options) {
    const { filters, sort, sortBy, page, query, view, reload, id, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/promotion/promotions/${id}/promotions-excluded`,
      cacheItem: 'PromotionChannels',
      apiParams: {
        companyId,
        type: 'list-promotions-excluded',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }
}

export const goalCustomerPromotionsApi = new GoalCustomerPromotionsApi();

import { ArrowLeft } from '@mui/icons-material';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { AllowedGuard } from './use-allowed';
import { Outlet, useLocation } from 'react-router-dom';
import { matchPath, Link as RouterLink } from 'react-router-dom';
import GoalButton from '../components/goal-button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from './use-auth';
import { paramsAsString } from '../utils/apply-filters';

export const useTabs = ({ tabs, defaultPath = '', basePath, params = '', backPath = null }) => {
  const location = useLocation();
  const { renderAllowed } = useAuth();
  const { pathname } = location;
  const [allowedTabs, setAllowedTabs] = useState(tabs);

  const paramsString = paramsAsString(params);

  useEffect(() => {
    setAllowedTabs(
      tabs.filter((tab) => !tab.hide && (!tab.permission || renderAllowed(tab.permission, tab)))
    );
  }, []);

  const selectedTabIndex = useMemo(() => {
    const selected = allowedTabs
      .filter((item) => item.href !== defaultPath)
      .find((item) => {
        const currentPath = `${basePath}${item.href}`;
        const match = matchPath({ path: currentPath, end: false }, pathname);
        if (match) return item;
      });
    return tabs.findIndex((item) => item.href === (selected ? selected.href : defaultPath));
  }, [tabs, defaultPath, basePath, pathname]);

  const renderTabs = useCallback(
    () => (
      <>
        <Box sx={{ py: 2 }}>
          <Box
            sx={{
              alignItems: 'center'
            }}
          >
            {backPath ? (
              <GoalButton
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeft />}
                to={backPath.path}
                variant="text"
                label={backPath.label || 'Volver'}
              />
            ) : null}

            <Tabs allowScrollButtonsMobile value={selectedTabIndex} variant="scrollable">
              {allowedTabs.map((option) => (
                <AllowedGuard key={option.label} permission={option.permission}>
                  <Tab
                    component={RouterLink}
                    key={option.label}
                    label={option.label}
                    to={`${basePath}${option.href}${paramsString ? `?${paramsString}` : ''}`}
                  />
                </AllowedGuard>
              ))}
            </Tabs>
          </Box>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Outlet />
      </>
    ),
    [backPath, selectedTabIndex, tabs, basePath]
  );

  return { renderTabs, selectedTabIndex };
};

export const GoalTabs = ({ tabs, defaultPath = '', basePath, params = '', backPath = null }) => {
  const { renderTabs } = useTabs({ tabs, defaultPath, basePath, params, backPath });
  return renderTabs();
};

import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Skeleton, Typography } from '@mui/material';
import { receiptFeeApi } from '../../api/receipt-fee';
import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../icons/arrow-left';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../icons/exclamation-outlined';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { DocumentLog } from '../../components/purchases/common/document-log';
import { purchaseView } from '../../api/purchase-definitions';
import { ReceiptFeesEdit } from '../../components/purchases/receipt-fees/receipt-fees-edit';
import { ReceiptFeesDetails } from '../../components/purchases/receipt-fees/receipt-fees-details';
import { ReceiptFeesLineItems } from '../../components/purchases/receipt-fees/receipt-fees-line-items';
import { useData } from '../../hooks/use-data';
import { InvoiceMenu } from '../../components/purchases/common/invoice-menu';

export const ReceiptFees = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();

  const [invoiceState, handleRefresh] = useData({
    sourceApi: receiptFeeApi.getInvoice,
    apiParameter: { docEntry: id },
    loadingMessage: 'Cargando detalle del documento',
    mounted
  });

  const [editMode, setEditmode] = useState(false);

  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!invoiceState.data) return;
    if (['D'].includes(invoiceState.data.status)) {
      setEditmode(true);
    }
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => {
    if (invoiceState.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (invoiceState.error || !invoiceState.data) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ExclamationOutlinedIcon />
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              {invoiceState.error}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to={`${appUrlBase}/purchases/receipts-fees/`}
              variant="text"
            >
              Rx Honorarios
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              #{invoiceState.data?.document}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              />
              <InvoiceMenu
                invoice={invoiceState.data}
                detailPath={`${appUrlBase}/purchases/receipts-fees`}
                apiHandler={receiptFeeApi}
                containerView={purchaseView.PURCHASE_FEES}
                afterProcessHandler={handleRefresh}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={invoiceState.data?.statusName} />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              {editMode ? (
                <ReceiptFeesEdit
                  invoice={invoiceState.data}
                  setEditMode={setEditmode}
                  onAfterSave={handleRefresh}
                />
              ) : (
                <ReceiptFeesDetails invoice={invoiceState.data} setEditMode={handleEdit} />
              )}
            </Grid>
            <Grid item xs={12}>
              {/* <InvoicePayment invoice={invoiceState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              <ReceiptFeesLineItems invoice={invoiceState.data} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <DocumentLog
                document={{
                  objType: invoiceState.data.objType,
                  docEntry: invoiceState.data.docEntry
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={4}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
          >
            <Grid item xs={12}>
              {/* <InvoiceSendNotification invoice={invoiceState.data} /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <InvoicePaymentHistory /> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de recibo por honorario')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

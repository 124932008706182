import PropTypes from 'prop-types';
import { Box, Button, Card, CardHeader, Divider } from '@mui/material';
import { Download as DownloadIcon } from '../../../icons/download';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { RenderIf } from '../../render-if';
import GadminActions from '../../../contexts/gadmin-actions';
import { ReceiptGRLinesTable } from './receipt-gr-lines-table';

export const ReceiptGRLines = (props) => {
  const { receiptInfo, onDownload } = props;

  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Guías de Remisión" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.gCC_DET_ORDER_FAC}>
              <Box sx={{ mb: 2 }}>
                <RenderIf condition={!!onDownload}>
                  <Button
                    onClick={onDownload}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                  />
                </RenderIf>
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>
      <Divider />
      <ReceiptGRLinesTable receiptInfo={receiptInfo} />
    </Card>
  );
};

ReceiptGRLines.propTypes = {
  receiptInfo: PropTypes.object.isRequired,
  onDownload: PropTypes.func
};

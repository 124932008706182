import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Typography,
  IconButton
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import { LoadingTable } from '../../common/loading-table';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { CustomerModal } from '../../delivery/monitoring/customer-modal';
import { CustomerArticleForm } from './customer-article-form';

export const CustomerArticleTable = ({ onRefresh, documentState, documents = [] }) => {
  const handleRefresh = () => {
    onRefresh?.();
  };

  const [open, onClose] = useState(false);
  const [itemData, setItemEdit] = useState(null);
  const handleClickEditIcon = (item) => {
    setItemEdit(item);
    onClose(!open);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginY: '1em'
      }}
    >
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Artículos" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.G2_CD_LIST}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell> &nbsp; </TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Inac.</TableCell>
              <TableCell>Compra</TableCell>
              <TableCell>Venta</TableCell>
              <TableCell>Movil</TableCell>
              <TableCell>Cod. Fabricante</TableCell>
              <TableCell>Grupo</TableCell>
              <TableCell>Disponible</TableCell>
              <TableCell>Prec. U</TableCell>
              <TableCell>Prec. P</TableCell>
              <TableCell>Percep?</TableCell>
              <TableCell>Min Percep</TableCell>
              <TableCell>% Percep</TableCell>
              <TableCell>Detrac?</TableCell>
              <TableCell>Min Detrac</TableCell>
              <TableCell>% Detrac</TableCell>
              <TableCell>Inventario</TableCell>
              <TableCell>Categoria</TableCell>
              <TableCell>Familia</TableCell>
              <TableCell>Marca</TableCell>
              <TableCell>Linea</TableCell>
              <TableCell>++</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {index}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    <IconButton
                      onClick={() => handleClickEditIcon(item)}
                      color="secondary"
                      aria-label="edit"
                    >
                      <PencilIcon color="primary" />
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.type}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.cod}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.inac}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.buy}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.sale}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.mobile}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.cod_maker}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.group}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.available}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.prec_u}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.prec_p}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.percep_exist}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 120 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.min_percep}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.percep}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.detrac_exist}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.min_detrac}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.detrac}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.inventory}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.category}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.family}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.brand}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.line}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.plus}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <LoadingTable documentState={documentState} />
      </Scrollbar>
      <CustomerModal open={open} onClose={onClose} permission={GadminActions.G2_CONTESTS_UP}>
        <CustomerArticleForm
          onClose={onClose}
          handleRefresh={handleRefresh}
          title="Artículo"
          initialValues={itemData}
          update
        />
      </CustomerModal>
    </Card>
  );
};

CustomerArticleTable.propTypes = {
  documentState: PropTypes.object.isRequired,
  documents: PropTypes.array,
  onRefresh: PropTypes.func.isRequired
};

import { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useApp } from '../../hooks/use-app';
import TokenValidation from '../../components/token-validation';

export const CompanyLayout = () => {
  const { company } = useParams();
  const {
    currentCompany,
    companyState,
    checkCompany,
    selectCompany,
    setUnauthorized,
    unauthorized,
    initializing,
    appUrlBase
  } = useApp();
  useEffect(() => {
    if (!initializing) {
      const checkedCompany = checkCompany(company > 0 ? company : currentCompany);
      if (checkedCompany) {
        selectCompany(checkedCompany);
      } else {
        setUnauthorized(true);
      }
    }
  }, [company, companyState, initializing]);

  if (!companyState || companyState.isLoading || initializing) {
    return <TokenValidation />;
  }
  // eslint-disable-next-line eqeqeq
  if (company == 0 && currentCompany > 0) {
    return <Navigate to={`${appUrlBase}`} />;
  }
  if (unauthorized) {
    return <Navigate to="/401" />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};

// import PropTypes from 'prop-types';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { Eye as EyeIcon } from '../../../icons/eye';
import { useDialog } from '../../../hooks/use-dialog';
import { PDFViewerdDialog } from '../../common/pdf-viewer-dialog';
import { invoiceApi } from '../../../api/invoice';

export const CustomerLiquidationItemMenu = () => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [pdfViewerDialogOpen, handleOpenPdfViewerDialog, handleClosePdfViewerDialog] = useDialog();
  const [pdfViewerDialogOpenDif, handleOpenPdfViewerDialogDif, handleClosePdfViewerDialogDif] =
    useDialog();

  const handleReportDev = () => {
    handleOpenPdfViewerDialog(true);
    handleClose();
  };
  const handleReportDif = () => {
    handleOpenPdfViewerDialogDif(true);
    handleClose();
  };

  return (
    <>
      <Box onClick={handleOpen} ref={anchorRef}>
        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem key="dev" onClick={handleReportDev}>
          <EyeIcon />
          Reporte Dev
        </MenuItem>
        <MenuItem key="dif" onClick={handleReportDif}>
          <EyeIcon />
          Reporte Dif
        </MenuItem>
      </Menu>

      <PDFViewerdDialog
        onClose={handleClosePdfViewerDialog}
        open={pdfViewerDialogOpen}
        apiHandle={invoiceApi.getInvoiceMedia}
        apiParams={{ objType: 729, docEntry: 13496, type: 'application/pdf' }}
      />
      <PDFViewerdDialog
        onClose={handleClosePdfViewerDialogDif}
        open={pdfViewerDialogOpenDif}
        apiHandle={invoiceApi.getInvoiceMedia}
        apiParams={{ objType: 729, docEntry: 13578, type: 'application/pdf' }}
      />
    </>
  );
};

import { LoadingButton } from '@mui/lab';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material';
import saveFill from '@iconify/icons-eva/save-fill';

import { Trash as TrashIcon } from '../../../icons/trash';
import { RenderIf } from '../../render-if';

export const DocumentLineDetailDialog = (props) => {
  const {
    open,
    onClose,
    onExited,
    onAfterSave,
    // documentState,
    Component,
    currentLine = {},
    helpText = '',
    title = 'Detalle de línea',
    editable = false,
    ...other
  } = props;
  const [saving, setSaving] = useState(false);
  const [closing, setClosing] = useState(false);
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    setClosing(!open);
  }, [open]);

  const handleClose = () => {
    setClosing(false);
    setSaving(false);
    onClose();
  };

  const handleSave = () => {
    setSaving(true);
  };

  const handleAfterSave = () => {
    setSaving(false);
    onAfterSave?.();
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      maxWidth="zl"
      TransitionProps={{
        onExited: () => {
          onExited?.();
          setClosing(true);
        }
      }}
      // {...other}
    >
      <DialogTitle id="scroll-dialog-title">
        <CardHeader title={title} />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {helpText}
        </DialogContentText>
        <Divider />
        <Component
          // documentState={documentState}
          onAfterSave={handleAfterSave}
          currentLine={currentLine}
          setDialogSaving={setSaving}
          dialogSaving={saving}
          closing={closing}
          editable={editable}
          dialogMode
          {...other}
        />
      </DialogContent>
      <DialogActions>
        <RenderIf condition={!!editable}>
          <LoadingButton
            color="primary"
            size="large"
            startIcon={<Icon icon={saveFill} />}
            variant="contained"
            type="submit"
            onClick={handleSave}
            loading={saving}
          >
            Guardar
          </LoadingButton>
        </RenderIf>
        <Button color="secondary" size="large" startIcon={<TrashIcon />} onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DocumentLineDetailDialog.propTypes = {
  // documentState: PropTypes.object.isRequired,
  currentLine: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func.isRequired,
  Component: PropTypes.func.isRequired,
  helpText: PropTypes.string,
  title: PropTypes.string,
  editable: PropTypes.bool
};

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../common/loading-content';
import { useApp } from '../../../hooks/use-app';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { BusinessPartnerSummary2 } from './business-parther-summary2';

const BusinessPartnerSummaryContainer = () => {
  const { cardcode: cardcodeQuery } = useParams();
  const mounted = useMounted();
  const { currentCompany, title } = useApp();
  const [summaryState] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getSummary,
    apiParameter: { companyId: currentCompany, user: cardcodeQuery },
    loadingMessage: 'Cargando detalle del resumen',
    mounted
  });

  if (summaryState.isLoading || summaryState.error) {
    return <LoadingContent loadingText={summaryState.message} error={summaryState.error} />;
  }
  if (!summaryState.success) {
    return <LoadingContent loadingText={summaryState.message} error={summaryState.message} />;
  }
  if (summaryState.data === null || summaryState.data === {}) {
    return <LoadingContent loadingText="No hay datos" error={summaryState.error} />;
  }
  return (
    <>
      <Helmet>
        <title>{title('Resumen')} </title>
      </Helmet>
      <BusinessPartnerSummary2 summaryState={summaryState.data} />
    </>
  );
};

export { BusinessPartnerSummaryContainer };

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { statusVariantsDefault } from '../../../api/app-definitions';
import { Status } from '../../status';
import { Eye as EyeIcon } from '../../../icons/eye';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import { Pagination } from '../../pagination';
import { useApp } from '../../../hooks/use-app';
import { ConstantStatus } from '../../../api/application-enums';
import { Restore as RestoreIcon } from '@mui/icons-material';
import { Trash as TrashIcon } from '../../../icons/trash';
import { useCallback, useState } from 'react';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { goalMaintenanceBaseApi } from '../../../api/goal/maintenance/goal-maintenance-base';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Nombre' },
  { id: 'days', label: 'Días' },
  { id: 'payment_term_group.name', label: 'Grupo' },
  { id: 'status', label: 'Estado' },
  { id: 'actions', label: '' }
];

const PaymentTermsTable = (props) => {
  const {
    error,
    data = [],
    dataCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'id',
    handleRefresh = () => {},
    view
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !data?.length);

  const { appUrlBase, currentCompany, showNotify, showError } = useApp();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [paymentTerm, setPaymentTerm] = useState({});

  const detailRoute = `${appUrlBase}/master-data/payment-terms/`;

  const handleOpenCornfirm = (item) => {
    setPaymentTerm(item);
    setConfirmDialogOpen(true);
    if (item.status === ConstantStatus.DELETED) {
      setConfirmDialogMessage(`Estas seguro de restaurar el registro "${item.name}"`);
    } else {
      setConfirmDialogMessage(`Estas seguro de eliminar el registro "${item.name}"`);
    }
  };

  const handleCloseCornfirm = () => {
    setPaymentTerm({});
    setConfirmDialogOpen(false);
  };

  // Function for handling delete or restore, memoized.
  const handleClickDeleteOrRestore = useCallback(
    async (item) => {
      const response =
        item.status === ConstantStatus.DELETED
          ? await goalMaintenanceBaseApi.restorePaymentTerm({
              id: item.id,
              companyId: currentCompany
            })
          : await goalMaintenanceBaseApi.deletePaymentTerm({
              id: item.id,
              companyId: currentCompany
            });
      if (response.success) {
        handleRefresh();
        showNotify(response.message);
      } else {
        showError(response.message);
      }
      handleCloseCornfirm();
    },
    [showNotify, showError, handleRefresh]
  );

  return (
    <Box>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={`attachment-group-column-${column.id}`}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              const statusVariant = statusVariantsDefault.find(
                (variant) => variant.value === item.status
              );
              return (
                <TableRow hover key={`item-payment-term-${item.id}`}>
                  <TableCell sx={{ minWidth: 90 }}>
                    <Typography color="textSecondary" variant="body2">
                      <Tooltip title="Ver detalle">
                        <IconButton>
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`${detailRoute}${item.id}?view=${view.toLowerCase()}`}
                            underline="none"
                            variant="subtitle2"
                          >
                            <EyeIcon />
                          </Link>
                        </IconButton>
                      </Tooltip>
                      {item.id}
                    </Typography>
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.days}</TableCell>
                  <TableCell>{item.payment_term_group.name}</TableCell>
                  <TableCell>
                    <Status color={statusVariant?.color} label={statusVariant?.label} />
                  </TableCell>
                  <TableCell sx={{ minWidth: 100 }}>
                    <Tooltip
                      title={item.status === ConstantStatus.DELETED ? 'Restaurar' : 'Eliminar'}
                    >
                      <IconButton
                        color={item.status === ConstantStatus.DELETED ? 'secondary' : 'primary'}
                        aria-label={
                          item.status === ConstantStatus.DELETED ? 'Restaurar' : 'Eliminar'
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenCornfirm(item);
                        }}
                      >
                        {item.status === ConstantStatus.DELETED ? <RestoreIcon /> : <TrashIcon />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={dataCount}
      />
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={() => handleCloseCornfirm()}
        onConfirm={() => handleClickDeleteOrRestore(paymentTerm)}
        open={confirmDialogOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

PaymentTermsTable.propTypes = {
  data: PropTypes.array,
  dataCount: PropTypes.number,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  page: PropTypes.number,
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  handleRefresh: PropTypes.func,
  view: PropTypes.string.isRequired
};

export default PaymentTermsTable;

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import GoalButton from './goal-button';
import { RenderIf } from './render-if';

export const GoalAccordion = ({
  children,
  title,
  defaultExpanded = false,
  icon = <AlignHorizontalLeftIcon />,
  onRefresh,
  onDownload
}) => {
  return (
    <Accordion defaultExpanded={defaultExpanded} sx={{ mb: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ mr: 2 }}>
          <Icon>{icon}</Icon>
        </Box>
        <Typography variant="h6"> {title}</Typography>

        <Box sx={{ flexGrow: 1 }} />
      </AccordionSummary>
      <AccordionActions>
        <Box
          sx={{
            alignItems: 'right',
            display: 'flex'
          }}
        >
          <RenderIf condition={!!onRefresh}>
            <GoalButton startIcon={<RefreshIcon />} onClick={onRefresh} color="primary" />
          </RenderIf>
          <RenderIf condition={!!onDownload}>
            <GoalButton onClick={onDownload} startIcon={<DownloadIcon />} />
          </RenderIf>
        </Box>
      </AccordionActions>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

GoalAccordion.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  icon: PropTypes.node,
  onRefresh: PropTypes.func,
  onDownload: PropTypes.func
};

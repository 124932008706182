// import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import Scrollbar from '../../../Scrollbar';

const columns = [
  {
    id: 'docentry',
    disablePadding: true,
    label: 'N. Documento'
  },
  {
    id: 'despatch_advice_id',
    label: 'ID Guia Remisión'
  },
  {
    id: 'clientid',
    label: 'Empresa'
  },
  {
    id: 'lineid',
    label: 'ID Linea'
  },
  {
    id: 'itemdescription',
    label: 'Descripción'
  },
  {
    id: 'quantity',
    label: 'Cantidad'
  },
  {
    id: 'unitmeasure',
    label: 'Unidad de medida'
  }
];

export const GrTabDetailTable = () => {
  const dataDetail = [
    {
      id: 797530,
      clientid: 8,
      objtype: 13,
      docentry: 9516282,
      lineid: 0,
      despatch_advice_id: 172351,
      quantity: 2.0,
      itemcode: 'DEP049030',
      sunatcode: null,
      itemdescription: 'Agua de Mesa S/Gas Pura Vida x3Lt. (PQTX4)',
      unitmeasure: 'PK',
      note: null,
      deleted_at: null,
      updated_at: '2023-08-25T09:28:12.267',
      created_at: '2023-08-25T09:28:12.267'
    },
    {
      id: 797532,
      clientid: 8,
      objtype: 13,
      docentry: 9516282,
      lineid: 0,
      despatch_advice_id: 172351,
      quantity: 2.0,
      itemcode: 'DEP049030',
      sunatcode: null,
      itemdescription: 'Agua de Mesa S/Gas Pura Vida x3Lt. (PQTX4)',
      unitmeasure: 'PK',
      note: null,
      deleted_at: null,
      updated_at: '2023-08-25T09:28:12.267',
      created_at: '2023-08-25T09:28:12.267'
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel>{column.label}</TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDetail.map((document, index) => (
              <TableRow hover key={index}>
                <TableCell
                  sx={{
                    minWidth: 150
                  }}
                >
                  <Typography color="textSecondary" variant="body2">
                    {document.docentry}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.despatch_advice_id}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.clientid}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.lineid}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.itemdescription}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.quantity}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.unitmeasure}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

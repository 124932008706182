import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useMounted } from '../../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../../icons/refresh';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { goalCustomeDeleveryMonitoringApi } from '../../../api/goal/monitoring/goal-customer-delivery-monitoring';
import { Plus as PlusIcon } from '../../../icons/plus';
import { DeliveryCreateGrTable } from '../../../components/delivery/create-gr/delivery-create-gr-table';
import { RenderIf } from '../../../components/render-if';
import { DeliveryCreateGrForm } from '../../../components/delivery/create-gr/delivery-create-gr-form';
import { CustomerModal } from '../../../components/delivery/monitoring/customer-modal';

export const DeliveryCreateGr = () => {
  const query = useQuery();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const app = useApp();
  const [open, onClose] = useState(false);

  const [currentDate, setCurrentDate] = useState(queried.date ? moment(queried.date) : new Date());
  const handleChangeDate = (newValue) => {
    setCurrentDate(newValue);
  };

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const handleDateChanged = (newValue) => {
    if (!newValue) return;
    setCurrentDate(newValue);
    const current = moment(newValue).format('YYYY-MM-DD');
    if (current !== controller.orderDate) {
      handleSetController.setMainFilter({ ...queried, orderDate: current });
    }
  };

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    ,
    handlePageChange,
    ,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomeDeleveryMonitoringApi.getCustomeDeliveryMonitoring,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{app.title('GR')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Guía de Remisión
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={currentDate}
                  onChange={handleChangeDate}
                  onAccept={handleDateChanged}
                  // renderInput={(params) => <TextField {...params} />}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              </LocalizationProvider>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={() => onClose(!open)}
                  variant="contained"
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
            <DeliveryCreateGrTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
            <Divider />
          </Card>
        </Container>
        <RenderIf condition={open}>
          <CustomerModal open={open} onClose={onClose} permission={GadminActions.G2_CONTESTS_ADD}>
            <DeliveryCreateGrForm title="" onClose={onClose} handleRefresh={handleRefresh} />
          </CustomerModal>
        </RenderIf>
      </Box>
    </>
  );
};

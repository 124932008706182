import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import GadminActions from '../../contexts/gadmin-actions';
import { useApp } from '../../hooks/use-app';
import { useTabs } from '../../hooks/use-tabs';

const defaultTabs = [
  {
    href: '',
    label: 'Detalles',
    permission: GadminActions.gCC_TDOY_SUMARY
  },
  {
    href: '/transactions',
    label: 'Transacciones',
    permission: GadminActions.gCC_TDOY_SUMARY
  }
];

export const IncidentsTab = () => {
  const { id } = useParams();
  const { appUrlBase, title } = useApp();

  const { renderTabs } = useTabs({
    basePath: `${appUrlBase}/incidents/incidents/${id}`,
    backPath: {
      path: `${appUrlBase}/incidents/incidents`,
      label: 'volver (Incidencias)'
    },
    tabs: defaultTabs
  });

  return (
    <>
      <Helmet>
        <title>{title('Información de la incidencia')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderTabs()}
        </Container>
      </Box>
    </>
  );
};

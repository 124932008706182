import PropTypes from 'prop-types';
import { Box, Button, InputLabel, Typography } from '@mui/material';
import { CommonImageGallery } from './common-image-gallery';

export const CommonImagePost = ({
  previewImageFile,
  handleImagenChange,
  selectedImage,
  resetImageFile,
  handlePostImage,
  imageLoading,
  currentImage
}) => (
  <Box>
    {!previewImageFile && !imageLoading && (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: '250px',
            height: '250px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: '#fff'
          }}
          style={{
            background: '#131313'
          }}
        >
          {!previewImageFile && (
            <>
              <p>Agregar imagen</p>
              <InputLabel
                htmlFor="fileUpload"
                style={{
                  color: '#55d6d6',
                  fontSize: '22px',
                  fontWeight: '500',
                  padding: '6px 12px',
                  cursor: 'pointer'
                }}
              >
                Elejir el archivo
              </InputLabel>
              <input
                accept="image/*"
                type="file"
                name="attacments"
                id="fileUpload"
                onChange={handleImagenChange}
                style={{
                  color: 'transparent',
                  position: 'absolute',
                  margin: 0,
                  padding: 0,
                  width: '250px',
                  height: '250px',
                  outline: 'none',
                  opacity: 0,
                  cursor: 'pointer'
                }}
              />
            </>
          )}
        </Box>
      </Box>
    )}

    {previewImageFile && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography color="textSecondary" variant="body2">
          {selectedImage.name}{' '}
        </Typography>
        <img
          src={previewImageFile}
          style={{
            width: '200px',
            height: '200px',
            objectFit: 'contain'
          }}
          alt="Preview"
        />
      </div>
    )}
    {previewImageFile && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Button onClick={() => resetImageFile(null)}>Eliminar imagen</Button>
        <Button variant="contained" onClick={handlePostImage}>
          Subir imagen
        </Button>
      </Box>
    )}
    {imageLoading && (
      <CommonImageGallery
        photos={[
          {
            id: currentImage?.id,
            width: 1,
            height: 1,
            title: currentImage?.file_name,
            image_code: currentImage?.code
          }
        ]}
      />
    )}
  </Box>
);

CommonImagePost.propTypes = {
  previewImageFile: PropTypes.string,
  handleImagenChange: PropTypes.func,
  resetImageFile: PropTypes.func,
  handlePostImage: PropTypes.func,
  selectedImage: PropTypes.object,
  imageLoading: PropTypes.bool,
  currentImage: PropTypes.object
};

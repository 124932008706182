const statusDefinitions = {
  N: 'Nuevo',
  A: 'Activo',
  L: 'Cancelado',
  C: 'Cerrado'
};

const statusColorDefinitions = {
  D: 'info.main',
  P: 'warning.main',
  A: 'success.main',
  C: 'error.light',
  L: 'error.main',
  R: 'error.dark'
};

const applyToDefinitions = {
  P: 'Compras',
  S: 'Ventas'
};

const measureDefinitions = {
  SO: 'Soles',
  UM: 'Unidades',
  PK: 'Paquetes',
  UN: 'Umes'
};

const periodDefinitions = {
  M: 'Mensual',
  B: 'Bimestral',
  T: 'Trimestral'
};

const accumulateByDefinitions = {
  P: 'Proveedor',
  S: 'Vendedor'
};

const statusColorDefinitionsGR = {
  O: 'info.main',
  A: 'success.main',
  C: 'error.light',
  N: 'error.main',
  R: 'error.dark'
};

export const StatusName = (value) => statusDefinitions[value] || 'Valor no encontrado';
export const ApplyToName = (value) => applyToDefinitions[value] || 'Valor no encontrado';
export const MeasureName = (value) => measureDefinitions[value] || 'Valor no encontrado';
export const PeriodName = (value) => periodDefinitions[value] || 'Valor no encontrado';
export const AccumulateByName = (value) => accumulateByDefinitions[value] || 'Valor no encontrado';
export const StatusColor = (value) => statusColorDefinitions[value] || 'warning.main';
export const StatusColorDocumentGR = (value) => statusColorDefinitionsGR[value] || 'error.main';

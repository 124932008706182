import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalDexApi {
  async getDexReportList(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/dex/reports',
      cacheItem: 'dex-report-list',
      apiParams: {
        type: 'list',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async postDexReportForm(data) {
    const response = await dataLoader.loadGoalPost({
      action: '/dex/reports',
      changes: { ...data.values }
    });
    // console.log(data);
    return response;
  }

  async updateDexReportForm({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/dex/reports/${id}`,
      changes
    });
    // console.log(data);
    return response;
  }

  async deleteDexReportForm(id) {
    const response = await dataLoader.loadGoalDelete({
      action: `/dex/reports/${id}`
    });
    return response;
  }

  async getDexReportTypes() {
    const response = await dataLoader.loadGoalGetData({
      action: '/dex/resources/report-types'
    });
    return response;
  }

  async getDexReportCompanies() {
    const response = await dataLoader.loadGoalGetData({
      action: '/dex/resources/companies'
    });
    return response;
  }
  async getDexReportConnecions() {
    const response = await dataLoader.loadGoalGetData({
      action: '/dex/resources/connections'
    });
    return response;
  }
}

export const goalDexApi = new GoalDexApi();

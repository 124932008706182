import Proptypes from 'prop-types';
import { FormControl, MenuItem, Box, Chip } from '@mui/material';
import { InputLabelField, SelectInputField } from '../select-field';
import { CommonHelpText } from './common-help-text';

const MultiSelectField = ({
  label,
  name,
  value,
  onChange,
  error,
  options,
  getOptionName,
  onDeleteOption,
  useId = false,
  message = ''
}) => {
  const isArray = Array.isArray(value);
  const finalValue = isArray ? value : [];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
      <FormControl fullWidth>
        <InputLabelField title={label} />
        <SelectInputField
          labelId={name}
          multiple
          name={name}
          value={finalValue}
          onChange={onChange}
          error={error}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '200px',
                width: '250px'
              }
            }
          }}
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5
              }}
            >
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={getOptionName(value)}
                  onDelete={() => onDeleteOption(value)}
                  onMouseDown={(event) => event.stopPropagation()}
                />
              ))}
            </Box>
          )}
        >
          {options.length === 0 ? (
            <MenuItem value="">No hay datos</MenuItem>
          ) : (
            options.map((option, index) => {
              if (
                !finalValue.some(
                  (selectedOption) =>
                    selectedOption[useId ? 'id' : 'code'] === option[useId ? 'id' : 'code']
                )
              ) {
                return (
                  <MenuItem key={index} value={option}>
                    {option.name}
                  </MenuItem>
                );
              }
              return null;
            })
          )}
        </SelectInputField>
      </FormControl>
      <CommonHelpText message={message} />
    </Box>
  );
};

export default MultiSelectField;

MultiSelectField.propTypes = {
  label: Proptypes.string.isRequired,
  name: Proptypes.string.isRequired,
  value: Proptypes.array.isRequired,
  onChange: Proptypes.func.isRequired,
  error: Proptypes.bool,
  options: Proptypes.array.isRequired,
  getOptionName: Proptypes.func.isRequired,
  onDeleteOption: Proptypes.func.isRequired,
  useId: Proptypes.bool,
  message: Proptypes.string,
  sx: Proptypes.object
};

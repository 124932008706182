import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Typography } from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import LoadingContent from '../../components/common/loading-content';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import { Refresh } from '../../icons/refresh';
import GadminActions from '../../contexts/gadmin-actions';
import { goalIncidentsApi } from '../../api/goal/incidents/goal-incidents';

export const IncidentsDetailContainer = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title } = useApp();

  const [incidentsState, handleRefresh] = useData({
    sourceApi: goalIncidentsApi.getIncidenceId,
    apiParameter: id,
    loadingMessage: 'Cargando informacion de la mesa',
    mounted
  });

  if (incidentsState.isLoading || incidentsState.error) {
    return <LoadingContent loadingText={incidentsState.message} error={incidentsState.error} />;
  }
  if (!incidentsState.success) {
    return <LoadingContent loadingText={incidentsState.message} error={incidentsState.message} />;
  }
  if (incidentsState.data === null || incidentsState.data.length === 0) {
    return <LoadingContent loadingText="No hay datos" error={incidentsState.error} />;
  }
  return (
    <>
      <Helmet>
        <title>{title('Detalle del incidente')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Detalle del incidente
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<Refresh />}
                  onClick={() => handleRefresh(id)}
                  variant="contained"
                />
              </AllowedGuard>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

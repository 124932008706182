import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Alert, Box, Button, DialogActions, DialogTitle, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { goalMaintenancePayrollApi } from '../../../api/goal/maintenance/goal-maintenance-payroll';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { InputField } from '../../input-field';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import { RenderIf } from '../../render-if';
import { X as XIcon } from '../../../icons/x';
import { useCacheData } from '../../../hooks/use-cache-data';
import { MaintenceDetail } from '../maintenance-detail';
import { useMounted } from '../../../hooks/use-mounted';
import { useTreeViewData } from '../../../hooks/use-tree-view-data';
import { CommonTreeView } from '../../common/common-tree-view';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import { ConstantStatus } from '../../../api/application-enums';

export const MaintenanceDepartmentsForm = ({
  title,
  initialValues = {},
  initialData = [],
  configState = {}
}) => {
  const { companyState, showNotify, showError, currentCompany } = useApp();
  const [codeExistError, setCodeExistError] = useState(false);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);
  const [selectAction, setSelectAction] = useState('');

  const [editMode, setEditmode] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const { removeFromCache, clearCache } = useCacheData('departaments');
  const handleAfterSave = () => {
    departamentsActions.onLoadRoot({ companyId: currentCompany });
  };

  const mounted = useMounted();

  const [departamentsState, departamentsActions] = useTreeViewData({
    rootApi: goalMaintenancePayrollApi.getDepartamentsList,
    childrenApi: goalMaintenancePayrollApi.getDepartamentsContainer,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando departamentos',
    mounted,
    defaults: {}
  });

  useEffect(() => {
    const loadTreeRoot = async () => {
      departamentsActions.onLoadRoot({ companyId: currentCompany });
    };
    loadTreeRoot();
  }, [currentCompany]);

  const onNodeSelection = (item) => {
    departamentsActions.onSelectNode(item);
    setSelectedNodeDataItem(item);
    setSelectAction('edit-node');
    formik.setFieldValue('name', item.name);
    formik.setFieldValue('code', item.code);
    formik.setFieldValue('level', item.level);
    formik.setFieldValue('parent_id', item.parent_id);
    formik.setFieldValue('company_id', item.company_id);
    formik.setFieldValue('email', item.email);
  };

  const formik = useFormik({
    initialValues: {
      company_id: currentCompany || '',
      name: initialValues.name || '',
      code: initialValues.code || '',
      level: initialValues.level || '1',
      parent_id: initialValues.parent_id || null,
      email: initialValues.email || '',
      status: initialValues.status || ConstantStatus.ACTIVE
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      code: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async () => {
      if (selectAction === 'edit-node') {
        handleEditNode();
        return;
      }
      if (selectedNodeDataItem === null && selectAction === 'create-node') {
        handleAddMajor();
      } else {
        handleAddNode();
      }
    }
  });

  const handleEditNode = async () => {
    const response = await goalMaintenancePayrollApi.updateDepartamentsForm(
      selectedNodeDataItem.id,
      formik.values
    );
    if (response.success) {
      clearCache();
      showNotify(`${response?.message}. Cambio exitoso `);
      handleAfterSave();
      handleCancel();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const handleAddNode = async () => {
    const codeExists = initialData.some((item) => item.code === formik.values.code);
    if (codeExists) {
      setCodeExistError(true);
      setTimeout(() => {
        setCodeExistError(false);
      }, 3000);
      return;
    }
    if (!configState.success) {
      showError(configState?.message);
      return;
    }
    if (
      (configState?.data['max-level'] > parseInt(selectedNodeDataItem.level, 10) &&
        configState?.data['max-level']) ||
      configState?.data['max-level'] === -1
    ) {
      const newLevel = (parseInt(selectedNodeDataItem.level, 10) + 1).toString();
      const newObj = {
        ...formik.values,
        level: newLevel,
        parent_id: selectedNodeDataItem.id
      };
      const response = await goalMaintenancePayrollApi.postDepartamentsForm({
        values: newObj
      });
      if (response.success) {
        removeFromCache(selectedNodeDataItem.id);
        showNotify(`${response?.message}. Cambio exitoso `);
        handleAfterSave();
        handleCancel();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    } else {
      showError(configState.success ? 'Level maximo alcanzado' : configState?.message);
    }
  };

  const handleAddMajor = async () => {
    const codeExists = initialData.some((item) => item.code === formik.values.code);
    if (codeExists) {
      setCodeExistError(true);
      setTimeout(() => {
        setCodeExistError(false);
      }, 3000);
      return;
    }
    const response = await goalMaintenancePayrollApi.postDepartamentsForm({
      values: formik.values
    });
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      handleAfterSave();
      handleCancel();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const handleCancel = () => {
    setEditmode(false);
    setSelectAction('');
  };

  const handleAddNodeMajor = () => {
    formik.setFieldValue('name', '');
    formik.setFieldValue('code', '');
    formik.setFieldValue('email', '');
  };

  const onDeleteNode = async (id) => {
    console.log('id node', id);
    const response = await goalMaintenancePayrollApi.deleteDepartamentsId(id);
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      handleAfterSave();
      handleCancel();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  return (
    <Box
      sx={{
        minWidth: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingX: 5,
        marginBottom: 3
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item md={6} xs={12}>
            <PropertyList>
              <CommonTreeView
                expandedItems={expandedItems}
                setExpandedItems={setExpandedItems}
                title="Departamentos..."
                onSelectNode={onNodeSelection}
                data={departamentsState.data}
                setSelectedNodeDataItem={setSelectedNodeDataItem}
                onDeleteNode={onDeleteNode}
                deleteAction
              />
            </PropertyList>
          </Grid>
          <Grid item md={6} xs={12}>
            <RenderIf
              condition={editMode}
              no={
                <MaintenceDetail
                  company={companyState?.data}
                  setEditMode={setEditmode}
                  item={selectedNodeDataItem}
                  setSelectAction={setSelectAction}
                  handleAddNodeMajor={handleAddNodeMajor}
                  emailExist
                />
              }
            >
              <InputField
                label="Código"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                sx={{ marginBottom: '1em' }}
                // disabled={selectAction === 'edit-node'}
              />
              {codeExistError && (
                <Alert sx={{ marginBottom: 2 }} severity="warning">
                  Este Código ya existe
                </Alert>
              )}
              <CompanyInputDisabled currentCompany={currentCompany} />
              <InputField
                label="Nombre"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ marginBottom: '1em' }}
              />
            </RenderIf>
            <DialogActions>
              <AllowedGuard permission={GadminActions.gCC_TEAM_BRANDS_UP}>
                {editMode && selectAction !== 'create-node' && (
                  <>
                    <LoadingButton
                      color="primary"
                      size="large"
                      startIcon={<PencilIcon />}
                      variant="contained"
                      loading={formik.isSubmitting}
                      disabled={selectAction !== 'edit-node'}
                      type="submit"
                    >
                      Guardar
                    </LoadingButton>
                    <Button
                      color="secondary"
                      size="large"
                      startIcon={<XIcon />}
                      onClick={() => setEditmode(false)}
                      sx={{
                        marginLeft: 1
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                )}

                {selectAction === 'create-node' && (
                  <LoadingButton
                    color="primary"
                    size="large"
                    startIcon={<PlusIcon />}
                    variant="contained"
                    loading={formik.isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </LoadingButton>
                )}
                {selectAction === 'create-node' && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<XIcon />}
                    onClick={handleCancel}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cancelar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

MaintenanceDepartmentsForm.propTypes = {
  initialValues: Proptypes.object,
  title: Proptypes.string,
  initialData: Proptypes.array,
  configState: Proptypes.object
};

import { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { deliveryApi } from '../../../api/delivery';
import { useMounted } from '../../../hooks/use-mounted';
import { CommonChart } from '../../common/common-chart';
import LoadingContent from '../../common/loading-content';

export const HRInvoicingStats = () => {
  const mounted = useMounted();

  const [invoiceState, setinvoiceState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({ reload: true });

  const handleRefresh = () => {
    setController({ ...controller, reload: true });
  };

  const getInvoiceStatics = useCallback(async () => {
    if (controller.reload) {
      setinvoiceState(() => ({ isLoading: true, message: 'Cargando estadísticas' }));
      try {
        const result = await deliveryApi.getPedidosHrPendStats();
        if (mounted.current) {
          setinvoiceState(() => ({
            ...result,
            isLoading: false
          }));
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setinvoiceState(() => ({
            isLoading: false,
            error: err.message
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller]);

  useEffect(() => {
    getInvoiceStatics().catch(console.error);
  }, [controller]);

  if (invoiceState.isLoading || invoiceState.error) {
    return <LoadingContent loadingText={invoiceState.message} error={invoiceState.error} />;
  }

  return (
    <Card
      elevation={0}
      sx={{
        // backgroundColor: alpha(theme.palette.info.main, 0.1),
        backgroundColor: 'background.default',
        mb: 4
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          {/* <Box sx={{ mb: 2 }}> */}
          {/* </Box> */}
          <Grid item md={3} xs={12}>
            <Typography color="textSecondary" variant="overline">
              Hojas de reparto desde
            </Typography>
            <Typography color="textPrimary" sx={{ mb: 3 }} variant="h4">
              {invoiceState?.minDate}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Todos los comprobantes procesados <strong>.</strong>
            </Typography>
            <Button
              color="primary"
              size="medium"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              variant="contained"
            />
          </Grid>
          <Grid
            item
            md={9}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: {
                sm: 'row',
                xs: 'column'
              }
            }}
          >
            <CommonChart
              data={invoiceState?.data?.stats?.documents}
              isLoading={invoiceState.isLoading}
              error={invoiceState.error}
              message={invoiceState.message}
              title="Doc todos"
            />
            <CommonChart
              data={invoiceState?.data?.stats?.invoices}
              isLoading={invoiceState.isLoading}
              error={invoiceState.error}
              message={invoiceState.message}
              title="Doc pendientes"
            />
            <CommonChart
              data={invoiceState?.data?.stats?.processed}
              isLoading={invoiceState.isLoading}
              error={invoiceState.error}
              message={invoiceState.message}
              title="Procesados"
            />
            <CommonChart
              data={invoiceState?.data?.stats?.hrs}
              isLoading={invoiceState.isLoading}
              message={invoiceState.message}
              error={invoiceState.error}
              title="Hojas de reprto"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

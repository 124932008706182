import PropTypes from 'prop-types';
import {
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import { LoadingTable } from '../../common/loading-table';
import { Eye as EyeIcon } from '../../../icons/eye';

export const BusinessPartnesAddressesTable = ({
  onRefresh,
  documents = [],
  onCloseEdit,
  setItemEdit
}) => {
  const handleRefresh = () => {
    onRefresh?.();
  };

  const handleDetial = (item) => {
    setItemEdit(item);
    onCloseEdit(true);
  };

  return (
    <Box
      variant="outlined"
      sx={{
        marginY: '1em'
      }}
    >
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Direcciones" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
              {/* {false && (
                <Box sx={{ mb: 2 }}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    onClick={() => onCloseCreate(!openCreate)}
                    variant="contained"
                  />
                </Box>
              )} */}
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell>Código</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Vendedor</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Sede</TableCell>
              <TableCell>Territorio</TableCell>
              <TableCell>Ubigeo</TableCell>
              <TableCell>Localidad</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.code || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.address || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.seller_id || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.phone || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.branch_id || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.territory_id || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.ubigeo_code || '--'}
                  </Typography>
                </TableCell>
                <TableCell sx={{ maxWidth: 150 }}>
                  <Typography color="textSecondary" variant="body2">
                    {item.district || '--'} - {item.province || '--'} - {item.department || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2" sx={{ cursor: 'pointer' }}>
                    <IconButton aria-label="edit" onClick={() => handleDetial(item.id)}>
                      <EyeIcon />
                    </IconButton>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <LoadingTable />
      </Scrollbar>
    </Box>
  );
};

BusinessPartnesAddressesTable.propTypes = {
  documents: PropTypes.array,
  onRefresh: PropTypes.func.isRequired,
  // ubigeoState: PropTypes.array,
  onCloseEdit: PropTypes.func,
  // setWidhtFormState: PropTypes.func,
  // widhtFormState: PropTypes.string,
  setItemEdit: PropTypes.func
};

import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { alpha, styled } from '@mui/material/styles';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

export const IconWrapper = ({ icon = null, width = 24, height = 24 }) => (
  <IconWrapperStyle>
    <Icon icon={icon} width={width} height={height} />
  </IconWrapperStyle>
);

IconWrapper.propTypes = {
  icon: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number
};

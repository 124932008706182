import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../icons/adjustments';
import { deliveryVehicleApi } from '../../api/delivery-vehicle';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../utils/filter-operators';
import { Query } from '../query';
import { FilterDialog } from '../filter-dialog';
import { BulkActionsMenu } from '../bulk-actions-menu';
import { RenderIf } from '../render-if';

const views = [
  {
    label: 'Mostrar todos',
    value: 'all'
  }
];

const filterProperties = [
  {
    name: 'Code',
    label: 'Código',
    type: 'string'
  },
  {
    name: 'Name',
    label: 'Nombre',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const SearchFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    onRefresh,
    query,
    selectedItems = [],
    view = 'all'
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [menuActions, setMenuActions] = useState([]);
  // const afterProcessHandler = (menuItem, result) => {
  const afterProcessHandler = () => {
    // if (result) {
    //   if (menuItem.action === DeliveryVehicleActions.CANCEL) {
    //     onFiltersApply();
    //   }
    // }
    // }
    onRefresh();
  };
  useEffect(() => {
    setMenuActions([]);
  }, [view]);

  return (
    <>
      <Box
        sx={{
          px: {
            sm: 3
          }
        }}
      >
        <Tabs
          onChange={(event, value) => onViewChange?.(value)}
          allowScrollButtonsMobile
          value={view}
          variant="scrollable"
        >
          {views.map((option) => (
            <Tab disabled={disabled} key={option.label} label={option.label} value={option.value} />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            sm: selectedItems.length > 0 ? 'auto 1fr auto' : '1fr auto',
            xs: 'auto'
          },
          justifyItems: 'flex-start',
          p: 3
        }}
      >
        <RenderIf condition={menuActions?.length > 0}>
          <BulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            selectedItems={selectedItems}
            selectedCount={selectedItems.length}
            afterProcessHandler={afterProcessHandler}
            apiHandler={deliveryVehicleApi.bulkDeliveryVehiclesActions}
            sx={{
              display: selectedItems.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          />
        </RenderIf>
        <Query
          disabled={disabled}
          onChange={onQueryChange}
          sx={{
            order: {
              sm: 2,
              xs: 1
            }
          }}
          value={query}
        />
        <Button
          color="primary"
          disabled={disabled}
          onClick={() => setOpenFilterDialog(true)}
          startIcon={<AdjustmentsIcon />}
          size="large"
          sx={{ order: 3 }}
          variant={filters.length ? 'contained' : 'text'}
        >
          Filtrar
        </Button>
      </Box>

      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

SearchFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onRefresh: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedItems: PropTypes.array,
  view: PropTypes.string
};

// Function to get client information
const getClientInformation = () => {
  var clientInfo = {
    userAgent: navigator.userAgent,
    os: getOS(),
    browser: getBrowser(),
    language: navigator.language
  };

  // Send the client information to the backend
  // sendToBackend(clientInfo)  ;
  return clientInfo;
};

// Function to get the operating system
const getOS = () => {
  var os = 'Unknown';
  var userAgent = navigator.userAgent;

  if (userAgent.indexOf('Win') !== -1) os = 'Windows';
  else if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
  else if (userAgent.indexOf('Linux') !== -1) os = 'Linux';

  return os;
};

// Function to get the browser
const getBrowser = () => {
  var browser = 'Unknown';
  var userAgent = navigator.userAgent;

  if (userAgent.indexOf('Chrome') !== -1) browser = 'Chrome';
  else if (userAgent.indexOf('Firefox') !== -1) browser = 'Firefox';
  else if (userAgent.indexOf('Safari') !== -1) browser = 'Safari';
  else if (userAgent.indexOf('Edge') !== -1) browser = 'Edge';
  else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) browser = 'IE';

  return browser;
};

export default getClientInformation;

import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import uploadFill from '@iconify/icons-eva/upload-fill';
import arrowheadUpOutline from '@iconify/icons-eva/arrowhead-up-outline';
import messageRoundOutline from '@iconify/icons-eva/message-circle-outline';
import { Link as RouterLink } from 'react-router-dom';
import activityOutline from '@iconify/icons-eva/activity-outline';
import questionMarkOutline from '@iconify/icons-eva/question-mark-outline';
import bookOpenOutline from '@iconify/icons-eva/book-open-outline';
import menuArrowOutline from '@iconify/icons-eva/menu-arrow-outline';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  FormControlLabel,
  Switch
} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
// import account from '../../_mocks_/account';
import { useAuth } from '../../hooks/use-auth';
import { useApp } from '../../hooks/use-app';
import GoalButton from '../../components/goal-button';
import LogoutIcon from '@mui/icons-material/Logout';
// ----------------------------------------------------------------------
const appUrlBase = '/app';
const MENU_OPTIONS = [
  // {
  //   label: 'Inicio',
  //   icon: homeFill,
  //   linkTo: '/'
  // },
  // {
  //   label: 'Perfil',
  //   icon: personFill,
  //   linkTo: '#'
  // },
  // {
  //   label: 'Configuración',
  //   icon: settings2Fill,
  //   linkTo: '#'
  // },
  {
    label: 'Importar',
    icon: uploadFill,
    linkTo: `${appUrlBase}/import`
  },
  {
    label: 'Archivos Cargados',
    icon: arrowheadUpOutline,
    linkTo: `${appUrlBase}/logs/document-uploads`
  },
  {
    label: 'Ultimos eventos',
    icon: activityOutline,
    linkTo: `${appUrlBase}/logs/gadmin-logs`
  },
  {
    label: 'Enviar un WhatsApp',
    icon: messageRoundOutline,
    linkTo: `${appUrlBase}/whatsapp`
  },
  // release notes
  {
    label: 'Notas de lanzamiento',
    icon: menuArrowOutline,
    linkTo: `${appUrlBase}/release-notes`
  },
  // about
  {
    label: 'Acerca de',
    icon: questionMarkOutline,
    linkTo: `${appUrlBase}/about`
  },
  // help
  {
    label: 'Ayuda',
    icon: bookOpenOutline,
    linkTo: `${appUrlBase}/help`
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { logout, account } = useAuth();
  const [open, setOpen] = useState(false);
  const { handleShowHelp, showHelp, currentCompanyInfo } = useApp();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSession = () => {
    logout();
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="h6" noWrap>
            {currentCompanyInfo?.short_name}
          </Typography>
          <Typography variant="subtitle1" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <Box sx={{ p: 1, pt: 1.5 }}>
          <FormControlLabel
            control={<Switch checked={showHelp} onChange={handleShowHelp} name="active" />}
            label="Ayuda interactiva"
          />
        </Box>
        <Box sx={{ p: 2, pt: 0.5 }}>
          <GoalButton
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleCloseSession}
            startIcon={<LogoutIcon />}
          >
            Cerrar sesión
          </GoalButton>
        </Box>
      </MenuPopover>
    </>
  );
}

import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../hooks/use-auth';
import { LoginForm } from './authentication/login';

export const AuthGuard = (props) => {
  const { children } = props;
  const { isLogged } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isLogged) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    const ContentStyle = styled('div')(({ theme }) => ({
      maxWidth: 480,
      margin: 'auto',
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(12, 0)
    }));

    return (
      <Container maxWidth="zl">
        <ContentStyle>
          <Box sx={{ pb: 7 }}>
            <Typography variant="h4" color={'secondary'}>
              Bienvenido
            </Typography>
          </Box>
          <LoginForm />
        </ContentStyle>
      </Container>
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

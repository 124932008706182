import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { useApp } from '../../../hooks/use-app';

export const CustomerReturnsMenu = (props) => {
  const { document } = props;
  const navigate = useNavigate();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { appUrlBase } = useApp();

  const handleEdit = () => {
    handleClose();
    navigate(`${appUrlBase}/purchases/${document?.id}`);
  };

  const handleReport = () => {
    handleClose();
    toast.error('This action is not available on demo');
  };

  const handleDelete = () => {
    handleClose();
    toast.error('This action is not available on demo');
  };

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} {...props}>
        <DotsVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleEdit}>Editar</MenuItem>
        <MenuItem onClick={handleReport}>PDF</MenuItem>
        <MenuItem onClick={handleReport}>XML</MenuItem>
        <MenuItem onClick={handleDelete}>Descartar</MenuItem>
      </Menu>
    </>
  );
};

CustomerReturnsMenu.propTypes = {
  document: PropTypes.object
};

import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalCustomerDeliveryMonitoringApi {
  async getCustomeDeliveryMonitoring(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/delivery/dispatch/dispatchs',
      cacheItem: 'customer-delivery-monitoring',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'CodigoCliente' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardname' },
        { type: 'string', name: 'slpName' },
        { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  async getCustomeDeliveryExpenses(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,
      id,
      mainFilter
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    // console.log(id);

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/delivery/dispatch/dispatchs/${id}/expenses`,
      cacheItem: 'customer-delivery-expenses',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'CodigoCliente' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardName' },
        { type: 'string', name: 'slpName' },
        { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  async postCustomeFormExpenses({ values }) {
    console.log(values);
    const response = await dataLoader.loadGoalPost({
      action: '/delivery/dispatch/dispatch-expenses',
      changes: values
    });

    return response;
  }

  async getCustomeDeliveryExpensesSuppliers(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/delivery/dispatch/resources/suppliers/${id}`
    });
    return response;
  }

  async deleteCustomeDeliveryExpenses(id) {
    const response = await dataLoader.loadGoalDelete({
      action: `/delivery/dispatch/dispatchs/${id}`
    });
    return response;
  }

  async refreshDispatchDates() {
    const response = await dataLoader.loadGoalGetData({
      action: `/delivery/dispatch/dispatchs/refresh-dates`
    });
    return response;
  }

  async refreshDispatchDocuments({ id }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/delivery/dispatch/dispatchs/${id}/refresh-documents`
    });
    return response;
  }

  async getCustomeDeliveryMonitoringById({ id }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/delivery/dispatch/dispatchs/${id}`
    });
    return response;
  }
}

export const goalCustomeDeleveryMonitoringApi = new GoalCustomerDeliveryMonitoringApi();

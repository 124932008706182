import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { customeOrderApi } from '../../../api/customer-order';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { CustomerOrdersFilter } from '../../../components/document/orders/customer-orders-filter';
import { CustomerOrderssTable } from '../../../components/document/orders/customer-orders-table';
import { CustomerOrdersStats } from '../../../components/document/orders/customer-orders-stats';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';

export const CustomerUnauthorizedOrders = () => {
  const query = useQuery();

  const queried = {
    orderDate: query.get('date'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const app = useApp();

  const [currentDate, setCurrentDate] = useState(queried.date ? moment(queried.date) : new Date());
  const handleChangeDate = (newValue) => {
    setCurrentDate(newValue);
  };

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const handleDateChanged = (newValue) => {
    if (!newValue) return;
    setCurrentDate(newValue);
    const current = moment(newValue).format('YYYY-MM-DD');
    if (current !== controller.orderDate) {
      handleSetController.setMainFilter({ ...queried, orderDate: current });
    }
  };

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: customeOrderApi.getCustomeOrders,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'W'
    }
    // onPostRefresh: handleClearSelected
  });

  const [selectedDocuments, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    returnsState.documents
  );

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    handleClearSelected();
  }, [returnsState.documents]);

  return (
    <>
      <Helmet>
        <title>{app.title('Pedidos del cliente')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Pedidos retenidos
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={currentDate}
                  onChange={handleChangeDate}
                  onAccept={handleDateChanged}
                  // renderInput={(params) => <TextField {...params} />}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              </LocalizationProvider>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_BROWSE_VISITA_DIA}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </AllowedGuard>
            </Box>
          </Box>
          <CustomerOrdersStats refresh={returnsState.isLoading} />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <CustomerOrdersFilter
              disabled={returnsState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedDocuments={selectedDocuments}
              view={controller.view}
              onRefresh={handleRefresh}
            />
            <Divider />
            <CustomerOrderssTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

import { Text, View, StyleSheet } from '@react-pdf/renderer';
// import PropTypes from 'prop-types';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    fontSize: 7
  },
  memo: {
    width: '55%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  value: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  account: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  amount: {
    width: '10%'
  }
});

const CustomerReturnTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.account}>Codigo</Text>
    <Text style={styles.memo}>Producto</Text>
    <Text style={styles.amount}>Cant</Text>
    <Text style={styles.amount}>Prec</Text>
    <Text style={styles.amount}>Sub Total</Text>
  </View>
);

// CustomerReturnTableHeader.propTypes = {
//   journal: PropTypes.object
// };
export default CustomerReturnTableHeader;

import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { permissionsApi } from '../../api/managment/permissions';
import { ConstantStatus } from '../../api/application-enums';

const PermissionsContext = createContext();

const usePermission = () => {
  return useContext(PermissionsContext);
};

const PermissionsProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const isMounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view')?.toUpperCase() || ConstantStatus.ACTIVE;

  const [permission, setPermission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const fetchPermission = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await permissionsApi.getPermission({
        companyId: currentCompany,
        id: id,
        view
      });
      if (response.success) {
        if (isMounted.current) {
          setPermission(response.data);
        }
      } else {
        if (isMounted.current) {
          setError('Error al cargar los datos del permiso.');
        }
      }
    } catch (err) {
      if (isMounted.current) {
        setError('Ocurrió un error inesperado');
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchPermission();
  }, [id]);

  const value = {
    permission,
    setPermission,
    fetchPermission,
    loading,
    error,
    editMode,
    setEditMode
  };
  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

PermissionsProvider.propTypes = { children: PropTypes.node.isRequired };

export { PermissionsProvider, usePermission };

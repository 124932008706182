import Proptypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { AutocompleteField } from '../../autocomplete-field';
import { InputField } from '../../input-field';

export const FormCustomerAwards = ({ formik, employeePositionList, configAwardsState }) => (
  <>
    <AutocompleteField
      label="Puesto"
      name="employee_position"
      placeholder="Seleccione origen"
      fullWidth
      options={employeePositionList || [{ name: 'Cargando', code: 'Cargando' }]}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option) => option.name || ''}
      onChange={(e, value) => {
        formik.setFieldValue('employee_position_code', value?.code);
        formik.setFieldValue('employee_position', value);
      }}
      value={formik.values.employee_position}
      sx={{ marginBottom: '1em' }}
      error={formik.touched.employee_position_code && Boolean(formik.errors.employee_position_code)}
      helperText={formik.touched.employee_position_code && formik.errors.employee_position_code}
    />
    <InputField
      select
      label="Tipo de rango"
      name="type"
      onChange={formik.handleChange}
      value={formik.values.type}
      fullWidth
      error={formik.touched.type && Boolean(formik.errors.type)}
      helperText={formik.touched.type && formik.errors.type}
      sx={{ marginBottom: '1em' }}
    >
      {configAwardsState['range-types'].map((item, index) => (
        <MenuItem key={index} value={item.code}>
          {item.name}
        </MenuItem>
      ))}
    </InputField>
  </>
);

FormCustomerAwards.propTypes = {
  formik: Proptypes.shape({
    values: Proptypes.object.isRequired,
    setFieldValue: Proptypes.func.isRequired,
    handleChange: Proptypes.func.isRequired,
    touched: Proptypes.object.isRequired,
    errors: Proptypes.object.isRequired
  }).isRequired,
  employeePositionList: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string.isRequired,
      code: Proptypes.string.isRequired
    })
  ),
  configAwardsState: Proptypes.object
  //   configAwardsState: Proptypes.objectOf(
  //     Proptypes.arrayOf(
  //       Proptypes.shape({
  //         code: Proptypes.string.isRequired,
  //         name: Proptypes.string.isRequired
  //       })
  //     )
  //   ).isRequired
};

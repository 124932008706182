import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { appDefinitions } from '../../../api/app-definitions';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'column',
    marginTop: 12
  },
  reportTitle: {
    fontSize: 8,
    textAlign: 'rigth'
  }
});

const JournalFooterMessage = ({ journal }) => {
  const { name: appName } = appDefinitions;
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>
        Impreso por {appName} - {journal.currentUser}{' '}
      </Text>
    </View>
  );
};
JournalFooterMessage.propTypes = {
  journal: PropTypes.object
};
export default JournalFooterMessage;

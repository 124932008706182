import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Box, Slide, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const types = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};

const Toasty = (props) => {
  const { queue, setQueue } = props;
  const [state, setState] = useState({
    vertical: 'top',
    horizontal: 'right',
    count: 0
  });
  const { vertical, horizontal } = state;

  const isInfo = (type) => {
    return [types.INFO, types.SUCCESS].includes(type);
  };

  const onClose = (selectedAction) => {
    setQueue(queue.filter((action) => action.key !== selectedAction.key));
    setState({ ...state, open: true });
  };

  useEffect(() => {
    if (queue.length > 0) {
      setState({ ...state, open: true }); // display the next message
    }
  }, [queue]);

  const toastyContent = (
    <Stack sx={{ position: 'absolute', top: 50, right: 0 }}>
      {queue.map((action, index) => (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={action.open}
          autoHideDuration={isInfo(action.type) ? 6000 : 10000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            onClose(action);
          }}
          sx={{ width: '100%', position: 'relative', backgroundColor: 'transparent' }}
          key={index}
          ContentProps={{
            sx: {
              backgroundColor: 'transparent',
              padding: '0px',
              margin: '6px 0px',
              boxShadow:
                '-8px 13px 18px 4px rgba(0,0,0,0.34), -webkit-box-shadow: -8px 13px 18px 4px rgba(0,0,0,0.34), -moz-box-shadow: -8px 13px 18px 4px rgba(0,0,0,0.34)'
            }
          }}
          action={
            <Box sx={{ marginLeft: '-16px' }}>
              <Alert
                onClose={(event, reason) => {
                  if (reason === 'clickaway') {
                    return;
                  }
                  onClose(action);
                }}
                severity={action.type}
                sx={{
                  width: '100%'
                }}
              >
                <Typography variant="h6">{action.message}</Typography>
                {action.detail && <Typography variant="body2">{action.detail}</Typography>}
              </Alert>
            </Box>
          }
          TransitionComponent={SlideTransition}
        />
      ))}
    </Stack>
  );

  return ReactDOM.createPortal(toastyContent, document.body);
};

const useToasty = ({ queue, setQueue }) => {
  const setValue = (message, type = 'success') => {
    if (typeof message === 'string' || message instanceof String) {
      setQueue([...queue, { open: true, message, type, key: new Date().getTime() }]);
    } else {
      setQueue([
        ...queue,
        {
          open: true,
          message:
            message.message ?? 'No se puede interpretar el mensaje ya que no es un texto válido',
          type,
          key: new Date().getTime(),
          detail: message.detail
        }
      ]);
    }
  };

  return [queue, setValue];
};

Toasty.propTypes = { queue: PropTypes.array.isRequired, setQueue: PropTypes.func.isRequired };

export { Toasty, useToasty };

import Proptypes from 'prop-types';
import { Box } from '@mui/material';
import HelpIcon from '../../icons/help-icon';
import { useApp } from '../../hooks/use-app';

export const CommonHelpText = ({ message = '', sx = {} }) => {
  const { showHelp, showNotify } = useApp();
  const handleClick = () => {
    if (message !== '') {
      showNotify(`${message} 🧏🏻‍♂️😊`, 'info');
    }
  };

  return (
    <Box sx={{ marginTop: 2, marginLeft: showHelp ? 1 : 0, ...sx }}>
      {showHelp && message !== '' && <HelpIcon sx={{ cursor: 'pointer' }} onClick={handleClick} />}
    </Box>
  );
};

CommonHelpText.propTypes = {
  message: Proptypes.string,
  sx: Proptypes.object
};

import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { DocumentLog } from '../purchases/common/document-log';

export const DocumetLogDialog = (props) => {
  const { open, onClose, onExited, objType, docEntry, ...other } = props;

  const handleClose = () => {
    onClose();
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          width: '100%',
          padding: '0px'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      fullWidth
      maxWidth="md"
      {...other}
    >
      <DialogTitle id="scroll-dialog-title">
        Log del documento..{objType} - {docEntry}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          Los sucesos mas recientes del documento se muestran primero.
        </DialogContentText>
        <DocumentLog
          document={{
            objType,
            docEntry
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

DocumetLogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  objType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  docEntry: PropTypes.number.isRequired
};

import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import { CommonHelpText } from './common/common-help-text';

export const ChipField = ({ label, variant, color, onClick, message = '', disabled = false }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
    <Chip label={label} variant={variant} color={color} onClick={onClick} disabled={disabled} />
    <CommonHelpText message={message} />
  </Box>
);

ChipField.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

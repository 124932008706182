import { useFormik } from 'formik';
import Proptypes from 'prop-types';
import * as Yup from 'yup';
import {
  Button,
  Grid,
  Typography,
  Box,
  MenuItem,
  FormControlLabel,
  FormControl,
  Checkbox,
  DialogActions,
  Chip,
  OutlinedInput
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { Trash } from '../../../icons/trash';
import { XCircle } from '../../../icons/x-circle';
import { InputField } from '../../input-field';
import { InputLabelField, SelectInputField } from '../../select-field';
import { useApp } from '../../../hooks/use-app';
import CustomInputComponent from '../survey-custom-input';

const SurveyForm = ({
  update = false,
  item = {},
  onClose,
  handleRefresh,
  subcategorylist,
  categorylist
}) => {
  useEffect(() => {
    getCustomerData();
    if (update && item.category) {
      getSubFind(item.category);
    }
  }, []);

  const { showNotify, showError, appUrlBase } = useApp();
  const navigate = useNavigate();

  const [applyData, setApplyData] = useState([]);
  const [applyDataOriginal, setApplyDataOriginal] = useState([]);
  const [categoryData, setCategoryData] = useState(update ? categorylist : []);
  const [subCategoryData, setSubCategoryData] = useState(update ? subcategorylist : []);
  const [dataSubCategory, setdataSubCategory] = useState(update ? subcategorylist : []);

  const getCustomerData = async () => {
    const applyData = await goalCustomeSurveyApi.getSurveyIssuers();
    const categoryData = await goalCustomeSurveyApi.getSurveyCategory();
    const subCategoryData = await goalCustomeSurveyApi.getSurveySubCategory();
    setCategoryData(categoryData.data);
    setSubCategoryData(subCategoryData.success ? subCategoryData.data : []);
    setApplyData(applyData.data);
    setApplyDataOriginal(applyData.data);
  };

  const handleAgregarOpcion = () => {
    const opciones = [...formik.values.options, ''];
    formik.setFieldValue('options', opciones);
  };

  const getSubFind = (id) => {
    const getSubCategory = subCategoryData.filter((item) => item.parent_id === id.toString());
    setdataSubCategory(getSubCategory);
  };

  const [selectAll, setSelectAll] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: item.title || '',
      category: item.category || '',
      subcategory: item.subcategory || '',
      type_question: item.type_question || '',
      question: item.question || '',
      options: item.options || [''],
      correct: item.correct || [''],
      score: item.score || 0,
      atack_image: item.atack_image || false,
      apply_to: item.apply_to || []
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Este campo es obligatorio'),
      category: Yup.string().required('Este campo es obligatorio'),
      subcategory: Yup.string().required('Este campo es obligatorio'),
      type_question: Yup.string().required('Este campo es obligatorio'),
      question: Yup.string().required('Este campo es obligatorio'),
      score: Yup.number()
        .required('Este campo es obligatorio')
        .test('is-positive', 'El número debe ser mayor a cero', (value) => value > 0),
      apply_to: Yup.array()
        .required('Debe seleccionar al menos una opción')
        .min(1, 'Debe seleccionar al menos una opción'),
      options: Yup.array().when('type_question', {
        is: (val) => val === 'range',
        then: Yup.array().of(
          Yup.number()
            .required('Campo requerido')
            .test('validate-min-max', 'El mínimo debe ser menor o igual al máximo', () => {
              const { options } = formik.values;
              const minValue = options[0];
              const maxValue = options[1];
              return !minValue || !maxValue || minValue <= maxValue;
            })
        )
      })
    }),

    onSubmit: async (values, { resetForm }) => {
      if (update) {
        const data = { ...values, _id: item._id, _rev: item._rev };
        const response = await goalCustomeSurveyApi.updateSurveyForm(item.id, data);
        if (response.success) {
          showNotify(`${response?.message}. Cambio exitoso `);
          handleRefresh();
          onClose(false);
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      } else {
        const response = await goalCustomeSurveyApi.postSurveyForm(values);
        if (response.success) {
          resetForm();
          showNotify(`${response?.message}. Cambio exitoso `);
          navigate(`${appUrlBase}/survey/questionnaire`, {
            replace: true
          });
        } else {
          showError(`${response?.message}. No se proceso la acción `);
        }
      }
    }
  });

  const handleSelectChange = (event) => {
    const data = event.target.value.map(({ id, name }) => ({ id, name }));
    formik.setFieldValue('apply_to', data);
    const updatedSelected = applyData.filter(
      (selectedValue) => !data.some((item) => item.id === selectedValue.id)
    );
    setApplyData(updatedSelected);
  };

  const getApplyToName = (applyId) => {
    const applyTo = applyDataOriginal.find((applyTo) => applyTo.id === applyId);
    return applyTo ? applyTo.name : '';
  };
  const handleSelectAllChange = (e) => {
    const allData = applyData.map((item) => item);
    const selectedOptions = e.target.checked ? allData : [];
    formik.setFieldValue('apply_to', selectedOptions);
    setSelectAll(e.target.checked);
  };

  const handleDelete = (value) => {
    const updatedSelected = formik.values.apply_to.filter(
      (selectedValue) => selectedValue !== value
    );
    formik.setFieldValue('apply_to', updatedSelected);
    const arrayActualizado = [value, ...applyData];
    setApplyData(arrayActualizado);
  };

  const handleResetAnswer = () => {
    formik.setFieldValue('correct', ['']);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 5
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{update ? 'Editar pregunta' : 'Crear pregunta'} </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="title"
              label="Titulo"
              value={formik.values.title}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>

          <Grid item xs={6}>
            <InputField
              select
              label="Categoria"
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
            >
              {categoryData.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  onClick={() => {
                    getSubFind(item.id);
                    formik.setFieldValue('subcategory', '');
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </InputField>
          </Grid>
          <Grid item xs={6}>
            <InputField
              select
              label="Sub categoria"
              name="subcategory"
              value={formik.values.subcategory}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.subcategory && Boolean(formik.errors.subcategory)}
              helperText={formik.touched.subcategory && formik.errors.subcategory}
            >
              {dataSubCategory.length === 0 ? (
                <MenuItem value="">No hay datos</MenuItem>
              ) : (
                dataSubCategory.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              )}
            </InputField>
          </Grid>
          <Grid item xs={6}>
            <InputField
              label="Pregunta"
              name="question"
              value={formik.values.question}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.question && Boolean(formik.errors.question)}
              helperText={formik.touched.question && formik.errors.question}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              select
              label="Tipo de Pregunta"
              name="type_question"
              value={formik.values.type_question}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.type_question && Boolean(formik.errors.type_question)}
              helperText={formik.touched.type_question && formik.errors.type_question}
            >
              <MenuItem value="boolean" onClick={handleResetAnswer}>
                Verdad o Falso
              </MenuItem>
              <MenuItem value="select" onClick={handleResetAnswer}>
                Seleccionar opción
              </MenuItem>
              <MenuItem value="multiselect" onClick={handleResetAnswer}>
                Selección multiple
              </MenuItem>
              <MenuItem value="text" onClick={handleResetAnswer}>
                Texto
              </MenuItem>
              <MenuItem onClick={handleAgregarOpcion} value="range">
                Rango
              </MenuItem>
            </InputField>
          </Grid>
          <Grid item xs={12}>
            <CustomInputComponent type={formik.values.type_question} formik={formik} />
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="number"
              label="Puntaje"
              name="score"
              value={formik.values.score}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.score && Boolean(formik.errors.score)}
              helperText={formik.touched.score && formik.errors.score}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              sx={{
                marginTop: 2
              }}
              control={
                <Checkbox
                  checked={formik.values.atack_image}
                  onChange={formik.handleChange}
                  name="atack_image"
                  color="secondary"
                />
              }
              label="¿Requiere Imagen?"
            />
          </Grid>
          <Grid item xs={update ? 12 : 6}>
            <FormControl fullWidth>
              <InputLabelField title="Aplicar" />
              <SelectInputField
                labelId="apply_to"
                multiple
                name="apply_to"
                value={applyDataOriginal.length === 0 ? [] : formik.values.apply_to}
                onChange={handleSelectChange}
                input={<OutlinedInput id="select-multiple-chip" label="Prooveedor" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                      width: '250px'
                    }
                  }
                }}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5
                    }}
                  >
                    {selected.map((value, index) => (
                      <Chip
                        key={index}
                        label={getApplyToName(value.id)}
                        onDelete={() => handleDelete(value)}
                        deleteIcon={<XCircle />}
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    ))}
                  </Box>
                )}
                fullWidth
                error={formik.touched.apply_to && Boolean(formik.errors.apply_to)}
              >
                {applyDataOriginal.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  applyData.map((option, index) => {
                    if (
                      !formik.values.apply_to.some(
                        (selectedOption) => selectedOption.id === option.id
                      )
                    ) {
                      return (
                        <MenuItem key={index} value={option}>
                          {option.name}
                        </MenuItem>
                      );
                    }
                    return null;
                  })
                )}
              </SelectInputField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              sx={{
                marginTop: 3
              }}
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  name="selectAll"
                  color="secondary"
                />
              }
              label="Seleccionar todos"
            />
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_QUESTIONARY_UP}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={update ? <PencilIcon /> : <PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                {update && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<Trash />}
                    onClick={() => onClose(false)}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cerrar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SurveyForm;

SurveyForm.propTypes = {
  update: Proptypes.bool,
  item: Proptypes.object,
  onClose: Proptypes.func,
  handleRefresh: Proptypes.func,
  subcategorylist: Proptypes.array,
  categorylist: Proptypes.array
};

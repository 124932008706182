import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';

export const PromoAssignedLog = (props) => {
  const { promoAssigned } = props;
  const { history } = promoAssigned || {};
  if (!history) {
    return <LoadingContent loadingText="No hay Suceesos" />;
  }
  return (
    <Card variant="outlined">
      <CardHeader title="Sucesos del Comprobante" />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell>ID</TableCell>
              <TableCell>Instancia</TableCell>
              <TableCell>Etapa</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Tiempo</TableCell>
              <TableCell>Mensaje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{numeral(item.id).format('0')}</TableCell>
                <TableCell>{item.instanceId}</TableCell>
                <TableCell>{item.step}</TableCell>
                <TableCell>{moment(item.createDate).format('hh:mm:ss DD/MM/YYYY')}</TableCell>
                <TableCell>{item.elapsed}</TableCell>
                <TableCell>{item.memo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

PromoAssignedLog.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};

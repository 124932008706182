import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Status } from './status';

const statusVariants = [
  { color: 'success.main', label: 'Si', value: 'Si' },
  { color: 'error.main', label: 'No', value: 'No' }
];

export const YesNo = ({ value = '', label = '' }) => {
  const [state, setState] = useState(value);
  const statusVariant = statusVariants.find((variant) => variant.value === state);

  useEffect(() => {
    // if is boolean
    if (value === null || value === undefined) setState('No');
    else if (typeof value === 'boolean') setState(value ? 'Si' : 'No');
    else {
      setState(
        ['y', '1', 'true', 'yes', 'on', 'Y'].includes(value.toString().toLowerCase()) ? 'Si' : 'No'
      );
    }
  }, [value]);
  const labelText = label ? `${statusVariant?.label} - ${label}` : `${statusVariant?.label}`;
  return <Status color={statusVariant?.color} label={labelText} />;
};

YesNo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string
};

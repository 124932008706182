import { dataLoader } from '../../data-loader';

class GoalOrdersApi {
  async enqueueOrder({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/pre-sale/orders/${id}/enqueue`,
      params: { companyId }
    });
    return response;
  }
}

export const goalOrdersApi = new GoalOrdersApi();

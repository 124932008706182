import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Box, Card, Container, Divider, Typography } from '@mui/material';

import { useMounted } from '../../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { DeliveryVehicleWorkOrdersTable } from '../../../components/delivery/vehicle/delivery-vehicle-work-orders-table';
import { goalCustomeVehicleApi } from '../../../api/goal/vehicle/goal-customer-vehicle';
import GoalButton from '../../../components/goal-button';
// import { QueryCustomerModal } from './query-customer-modal';

export const VehicleWorkOrders = () => {
  // get id from params
  const { id } = useParams();

  const mounted = useMounted();
  const { title } = useApp();

  const [
    documentState,
    handleRefresh,
    handleSortChange,
    ,
    handlePageChange,
    ,
    ,
    ,
    controller
    // handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomeVehicleApi.getVehicleWorkOrders,
    id,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      view: 'all',
      sort: 'desc',
      sortBy: 'work_created',
      id
    }
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const refreshPage = () => {
    handleRefresh({ vehicleId: id });
  };

  return (
    <>
      <Helmet>
        <title>{title('Ordenes de trabajo')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Ordenes de trabajo...
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <GoalButton
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={refreshPage}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                  label={'Refresh'}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
            <DeliveryVehicleWorkOrdersTable
              error={documentState.error}
              documents={documentState.documents}
              // documents={data}
              documentsCount={documentState.documentsCount}
              isLoading={documentState.isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

import { useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import Scrollbar from '../Scrollbar';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { useDialog } from '../../hooks/use-dialog';
import { TablesEditLine } from './tables-edit-line';

const columns = [
  {
    id: 'index',
    disablePadding: true,
    label: 'Código'
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'active',
    label: 'Activo'
  },
  {
    id: 'price_list',
    label: 'Lista de precio'
  }
];

export const SalesTeamsZonesTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;

  const [itemData, setitemEdit] = useState(null);
  const [openActive, handleOpenActive, handleCloseActive] = useDialog();

  const handleClickEditActive = (item) => {
    setitemEdit(item);
    handleOpenActive();
  };

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1, marginLeft: 3 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow hover key={index}>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.cod}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      minWidth: 150,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {openActive && itemData?.id === document.id ? (
                      <>
                        <TablesEditLine
                          type="check"
                          item={document.active}
                          handleClose={handleCloseActive}
                        />
                      </>
                    ) : (
                      <>
                        <Typography color="textSecondary" variant="body2">
                          {document.active === 'Y' ? 'Si' : 'No'}
                        </Typography>
                        <PencilIcon
                          color="primary"
                          sx={{
                            cursor: 'pointer'
                          }}
                          onClick={() => handleClickEditActive(document)}
                        />
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.price_list}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
    </Box>
  );
};

SalesTeamsZonesTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

import PropTypes from 'prop-types';
import { Box, Menu } from '@mui/material';

import { usePopover } from '../../hooks/use-popover';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GoalButton from '../goal-button';
import { Children, cloneElement } from 'react';

export const GoalMenu = ({ label = 'Acciones', children, iconOnly = false, startIcon }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  return (
    <>
      <Box onClick={handleOpen} ref={anchorRef}>
        {iconOnly && startIcon}
        {!iconOnly && (
          <GoalButton variant="outlined" startIcon={startIcon}>
            {label}
            <KeyboardArrowDownIcon />
          </GoalButton>
        )}
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {Children?.map(children, (child, index) => {
          // console.log('child', child);
          if (!child) return null;
          return cloneElement(child, {
            key: child.props.label || index,
            onClose: handleClose,
            onClick: async () => {
              await child.props?.onClick?.();
              handleClose();
            },
            onConfirm: async () => {
              await child.props?.onConfirm?.();
              handleClose();
            },
            onCancel: async () => {
              await child.props?.onCancel?.();
              handleClose();
            }
          });
        })}
      </Menu>
    </>
  );
};

GoalMenu.propTypes = {
  startIcon: PropTypes.node,
  children: PropTypes.node,
  label: PropTypes.string,
  iconOnly: PropTypes.bool
  // items: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     // Component: PropTypes.elementType.anyOf([ConfirmationMenuItem, GoalMenuItem]),
  //     Component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
  //     startIcon: PropTypes.node,
  //     helpText: PropTypes.string,
  //     onConfirm: (props, propName, componentName) => {
  //       if (props.Component === ConfirmationMenuItem && !props[propName]) {
  //         return new Error(
  //           `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
  //             `\`${propName}\` is required when \`Component\` is \`ConfirmationMenuItem\`.`
  //         );
  //       }
  //     },
  //     label: PropTypes.string.isRequired,
  //     confirmationMessage: (props, propName, componentName) => {
  //       if (props.Component === ConfirmationMenuItem && !props[propName]) {
  //         return new Error(
  //           `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
  //             `\`${propName}\` is required when \`Component\` is \`ConfirmationMenuItem\`.`
  //         );
  //       }
  //     },
  //     onCanceled: PropTypes.func
  //   })
  // )
};

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;
const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

export const ChartWrapper = ({ children }) => <ChartWrapperStyle>{children}</ChartWrapperStyle>;

ChartWrapper.propTypes = {
  //   width: PropTypes.number,
  //   height: PropTypes.number,
  children: PropTypes.node
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
// import { format } from 'date-fns';
import { Button, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { documnetStatus } from '../../../api/purchase-definitions';

export const NCServiceDetails = (props) => {
  const { invoice, setEditMode } = props;
  const { currency, currencySymbol, status } = invoice;
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setEditable(status === documnetStatus.Borrador);
  }, [status]);

  return (
    <Card variant="outlined">
      <CardHeader title="Detalle de comprobante" />
      <Divider />
      <Grid container>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Proveedor">
              <Typography variant="body2" color="textPrimary">
                {`${invoice.cardCode} - ${invoice.cardName}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                RUC: {invoice.licTradNum}
              </Typography>
            </PropertyListItem>

            <PropertyListItem label="Término de pago" value={`#${invoice.paymentName}`} />
            <PropertyListItem label="Almacén" value={`${invoice.whsCode} - ${invoice.whsName}`} />
            <PropertyListItem label="Cuenta" value={`${invoice.account}`} />
            <PropertyListItem label="Motivo" value={`${invoice.reason}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem label="Numero" value={`#${invoice.document}`} />
            <PropertyListItem
              label="Fecha de emisión"
              value={moment(invoice.taxDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Vencimiento"
              value={moment(invoice.docDueDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem
              label="Fecha de Contabilización"
              value={moment(invoice.docDate).format('DD/MM/YYYY')}
            />
            <PropertyListItem label="Glosa" value={`${invoice.comments}`} />
          </PropertyList>
        </Grid>
        <Grid item md={4} xs={12}>
          <PropertyList>
            <PropertyListItem
              label="Gravado"
              value={numeral(invoice.sumGravada).format(`${currencySymbol} 0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Inafecto"
              value={numeral(invoice.sumInafecta).format(`${currency}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Exonerado"
              value={numeral(invoice.sumExonerada).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Gratuito"
              value={numeral(invoice.sumGratuita).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="IGV"
              value={numeral(invoice.sumIgv).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem
              label="Importe"
              value={numeral(invoice.docTotal).format(`${'S/'}0,0.00`)}
              align="right"
            />
            <PropertyListItem label="CPE" value={invoice.cpe_memo} />
          </PropertyList>
        </Grid>
        <AllowedGuard permission={GadminActions.gCC_PURCHASE_FFEE_CAB_UP}>
          <Button
            color="primary"
            size="large"
            startIcon={<PlusIcon />}
            onClick={() => {
              setEditMode(true);
            }}
            variant="contained"
            disabled={!editable}
          >
            Editar
          </Button>
        </AllowedGuard>
      </Grid>
    </Card>
  );
};

NCServiceDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired
};

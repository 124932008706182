import PropTypes from 'prop-types';

import { Box, Button, Container, Typography } from '@mui/material';

import { useState, useEffect } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
import { Document, Page, pdfjs } from 'react-pdf';

// import './pdf.css';

export default function PDFView(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { content } = props;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  /* To Prevent right click on screen */

  useEffect(() => {
    const noContext = (event) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', noContext);
    return () => {
      document.removeEventListener('contextmenu', noContext);
    };
  }, []);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);
  const doc = (
    <Document file={`data:application/pdf;base64,${content}`} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} />
    </Document>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {doc}
          <Box sx={{ mb: 2 }}>
            <Typography color="textPrimary" sx={{ mb: 1.25 }} variant="subtitle2">
              Página {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </Typography>
            <Button
              color="primary"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              variant="text"
            >
              Anterior
            </Button>{' '}
            <Button
              color="primary"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              variant="text"
            >
              Siguiente
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}

PDFView.propTypes = {
  content: PropTypes.string
};

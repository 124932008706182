import Proptypes from 'prop-types';
import numeral from 'numeral';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import { Status } from '../../status';
import Scrollbar from '../../Scrollbar';

const columns = [
  {
    id: 'ItemCode',
    disablePadding: true,
    label: 'Codigo'
  },
  {
    id: 'ItenName',
    label: 'Descripción'
  },
  {
    id: 'CardName',
    label: 'Proveedor'
  },
  {
    id: 'lineName',
    label: 'Línea'
  },
  {
    id: 'price',
    label: 'Precio'
  },
  {
    id: 'validFor',
    label: 'Estado'
  }
];
const statusProducts = [
  { color: 'info.main', label: 'Activo', value: 'Y' },
  { color: 'error.main', label: 'Inactivo', value: 'N' }
];

export const ReturnsProductsTable = (props) => {
  const {
    error,
    products = [],
    productsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedProducts = [],
    sort = 'desc',
    sortBy = 'ItemName'
  } = props;
  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !products?.length);

  return (
    <Card variant="outlined">
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <Scrollbar>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={products.length > 0 && selectedProducts.length === products.length}
                    disabled={isLoading}
                    indeterminate={
                      selectedProducts.length > 0 && selectedProducts.length < products.length
                    }
                    onChange={onSelectAll}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((item) => {
                const statusProduct = statusProducts.find(
                  (variant) => variant.value === item.validFor
                );
                return (
                  <TableRow
                    hover
                    key={item.id}
                    selected={
                      !!selectedProducts.find((selectedProduct) => selectedProduct === item.id)
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          !!selectedProducts.find((selectedProduct) => selectedProduct === item.id)
                        }
                        onChange={(event) => onSelect(event, item.id)}
                      />
                    </TableCell>
                    <TableCell>{item.ItemCode}</TableCell>
                    <TableCell>{item.ItemName}</TableCell>
                    <TableCell>{`${item.CardCode}-  ${item.CardName}`}</TableCell>
                    <TableCell>{`${item.lineCode}-  ${item.lineName}`}</TableCell>
                    <TableCell>{numeral(item.price).format('0.00')}</TableCell>
                    <TableCell>
                      <Status color={statusProduct?.color} label={statusProduct?.label} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
        {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {displayUnavailable && (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={isLoading}
          onPageChange={onPageChange}
          page={page}
          rowsCount={productsCount}
        />
      </Box>
    </Card>
  );
};

ReturnsProductsTable.propTypes = {
  products: Proptypes.array,
  productsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedProducts: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

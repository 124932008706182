import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Chip, Grid, Typography, Link, InputLabel } from '@mui/material';
import { AllowedGuard } from '../../../../hooks/use-allowed';
import GadminActions from '../../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../../icons/plus';
import { StatusColor } from '../../../contest/contest-definition';
import { Status } from '../../../status';
import { formatValueFuntion } from '../../../../hooks/use-format-value';
import { CommonImageGallery } from '../../../common/common-image-gallery';
import { useApp } from '../../../../hooks/use-app';

export const ProductSumaryDetail = ({ setEditMode, item, itemcode }) => {
  const { appUrlBase } = useApp();
  const detailRoute = `${appUrlBase}/inventory/products/${itemcode}/gallery`;

  return (
    <Box>
      <Grid container rowSpacing={1} sx={{ padding: 3 }}>
        {/* Información Producto */}
        <Grid item xs={12} sm={12} md={12} lg={4} xl={5}>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item md={12} xs={12}>
              <InputLabel
                sx={{
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Información Producto
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Empresa
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.companyInfo?.id || '---'} - {item.companyInfo?.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Tipo
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.typeInfo?.id || '---'} - {item.typeInfo?.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Proveedor
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.supplierInfo?.code || '---'} - {item.supplierInfo?.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Código
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.code || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Nombre
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Division
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.divisionInfo?.id || '---'} - {item.divisionInfo?.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Marca
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.brandInfo?.id || '---'} - {item.brandInfo?.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Clasificación
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.productClasificationInfo?.id || '---'} -{' '}
                {item.productClasificationInfo?.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Categoria
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.productCategoryInfo?.id || '---'} - {item.productCategoryInfo?.name || '---'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Grupo de atributos
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.productAttributeGroupInfo?.id || '---'} -{' '}
                {item.productAttributeGroupInfo?.name || '---'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Información de Almacenamiento */}
        <Grid item xs={12} sm={12} md={6} lg={4} xl={5}>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item md={12} xs={12}>
              <InputLabel
                sx={{
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Información de Almacenamiento
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Factor
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.unitMeasureInfo?.name || '---'} x {item.factor_um || '--'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Almacenamiento
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.warehouseUnitMeasureInfo?.name || '---'} x {item.warehousing_factor || '--'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Unidad de compra
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.buy_product_unit_measure?.unit_measure.name || '--'}
                {` x ${item.buy_product_factor}` || '--'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Unidad de venta
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.sale_product_unit_measure?.unit_measure.name || '--'}
                {` x ${item.sell_product_factor}` || '--'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Código de barra
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.barcode || '---'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Precio
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatValueFuntion(item.price, `S/0,0.00`)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Costo
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatValueFuntion(item.cost, `S/0,0.00`)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Stock
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatValueFuntion(item.stock, `0`)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Stock máximo
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatValueFuntion(item.stock_max, `0`)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={4}>
              <Typography variant="subtitle2" gutterBottom>
                Stock mínimo
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatValueFuntion(item.stock_min, `0`)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={6} xl={4}>
              {item.thumbnail ? (
                <CommonImageGallery
                  photos={[
                    {
                      id: item?._id,
                      width: 1,
                      height: 1,
                      title: item.name,
                      image_code: item?.thumbnail
                    }
                  ]}
                />
              ) : (
                <Link
                  color="inherit"
                  component={RouterLink}
                  to={`${detailRoute}`}
                  underline="none"
                  variant="subtitle2"
                >
                  <Button color="primary" variant="contained">
                    Ir a galeria
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Dimensiones */}
        <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item md={12} xs={12}>
              <InputLabel
                sx={{
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Dimensiones
              </InputLabel>
            </Grid>

            <Grid item xs={6} sm={4} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Peso
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.weightUnitMeasureInfo?.code} {formatValueFuntion(item.weight, `S/0,0.00`)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Altura
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.dimensionUnitMeasureInfo?.code} {formatValueFuntion(item.height, `S/0,0.00`)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Ancho
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.dimensionUnitMeasureInfo?.code} {formatValueFuntion(item.width, `S/0,0.00`)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Largo
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.dimensionUnitMeasureInfo?.code} {formatValueFuntion(item.length, `S/0,0.00`)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Volumen
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.volumeUnitMeasureInfo?.code} {formatValueFuntion(item.volume, `S/0,0.00`)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Creado en
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatValueFuntion(item.created_at, 'DD/MM/YYYY', true)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={6}>
              <Typography variant="subtitle2" gutterBottom>
                Estado
              </Typography>
              <Status color={StatusColor(item.status)} label="Activo" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
              <Chip
                label="Compra"
                variant={item.for_purchase === 'Y' ? 'filled' : 'outlined'}
                color={item.for_purchase === 'Y' ? 'primary' : 'default'}
                sx={{ marginLeft: 0, marginBottom: 1 }}
              />
              <Chip
                label="Vender"
                variant={item.for_sale === 'Y' ? 'filled' : 'outlined'}
                color={item.for_sale === 'Y' ? 'primary' : 'default'}
                sx={{ marginLeft: 2, marginBottom: 1 }}
              />
              <Chip
                label="Inventariable"
                variant={item.for_inventory === 'Y' ? 'filled' : 'outlined'}
                color={item.for_inventory === 'Y' ? 'primary' : 'default'}
                sx={{ marginLeft: 2, marginBottom: 1 }}
              />
              <Chip
                label="Producción"
                variant={item.for_production === 'Y' ? 'filled' : 'outlined'}
                color={item.for_production === 'Y' ? 'primary' : 'default'}
                sx={{ marginLeft: 2, marginBottom: 1 }}
              />
            </Grid>
            {item.labels !== null && (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Características
                </Typography>
                {item.labels.map((item, index) => (
                  <Typography key={index} color="textSecondary" variant="body2">
                    {item.name}
                  </Typography>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <AllowedGuard permission={GadminActions.G2_CONTESTS_UP}>
              <Button
                color="primary"
                size="large"
                startIcon={<PlusIcon />}
                onClick={() => {
                  setEditMode(true);
                }}
                variant="contained"
                sx={{
                  marginLeft: 0
                }}
              >
                Editar
              </Button>
            </AllowedGuard>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ProductSumaryDetail.propTypes = {
  setEditMode: PropTypes.func,
  item: PropTypes.object,
  itemcode: PropTypes.string
};

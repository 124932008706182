import { dataLoader } from '../../data-loader';

class ProductClasificationsApi {
  async getProductClasificationsForAutocomplete({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `inventory/master/product-clasifications/autocomplete?companyId=${companyId}&search_by=level&search=3`
    });
    return response;
  }
}

export const productClasificationsApi = new ProductClasificationsApi();

import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { RenderIf } from '../../render-if';

export const CustomerOrderBulkActionsMenu = (props) => {
  const { disabled, onCancel, onAuthorize, onNoAuthorize, selectedCount, menuActions, ...other } =
    props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleCancel = async () => {
    onCancel?.();
    handleClose();
  };

  const handleAuthorize = async () => {
    onAuthorize?.();
    handleClose();
  };
  const handleNoAuthorize = async () => {
    onNoAuthorize?.();
    handleClose();
  };

  return (
    <>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleOpen}
        ref={anchorRef}
        size="large"
        startIcon={<ChevronDownIcon />}
        variant="outlined"
        {...other}
      >
        Acciones Masivas
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <AllowedGuard permission={GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO}>
          <RenderIf
            condition={menuActions?.includes(GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO)}
          >
            <MenuItem onClick={handleCancel}>{`Anular (${selectedCount})`}</MenuItem>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_SAVE_AUTORIZAR_PEDIDO_RET}>
          <RenderIf condition={menuActions?.includes(GadminActions.gCC_SAVE_AUTORIZAR_PEDIDO_RET)}>
            <MenuItem onClick={handleAuthorize}>{`Autorizar (${selectedCount})`}</MenuItem>
          </RenderIf>
        </AllowedGuard>
        <AllowedGuard permission={GadminActions.gCC_SAVE_AUTORIZAR_PEDIDO_RET}>
          <RenderIf condition={menuActions?.includes(GadminActions.gCC_SAVE_ANULAR_PEDIDO_RET)}>
            <MenuItem onClick={handleNoAuthorize}>{`NO Autorizar (${selectedCount})`}</MenuItem>
          </RenderIf>
        </AllowedGuard>
      </Menu>
    </>
  );
};

CustomerOrderBulkActionsMenu.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAuthorize: PropTypes.func.isRequired,
  onNoAuthorize: PropTypes.func.isRequired,
  menuActions: PropTypes.array.isRequired,
  selectedCount: PropTypes.number
};

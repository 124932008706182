import { useApp } from './use-app';
import { compareRecordChanged, isEmpty } from './use-item-change';

export const useFormSaver = ({
  originalData,
  apiUpdateHandler,
  id = 'id',
  handleAfterSave = null,
  apiAddHandler = null,
  hashField = null,
  includeNewValues = false,
  mandatoryFields = []
}) => {
  const { showNotify, showError } = useApp();

  const checkBeforeUpdate = (values, helpers) => {
    if (!apiUpdateHandler)
      throw new Error('No se ha implementado la función para actualizar registros ');
    if (!values) throw new Error('No hay Valores cambiados para actualizar el registro ');
    if (!helpers) throw new Error('No se encontraron los helpers de Form');
  };

  const checkBeforeAdd = (values, helpers) => {
    if (!apiAddHandler) throw new Error('No se ha implementado la función para agregar registros ');
    if (!values) throw new Error('No hay Valores cambiados para agregar el registro ');
    if (!helpers) throw new Error('No se encontraron los helpers de Form');
  };

  const handleUpdate = async ({ values, helpers }) => {
    let saved = { success: false };
    try {
      checkBeforeUpdate(values, helpers);
      const changes = compareRecordChanged(originalData, values, includeNewValues);

      // console.log('changes', changes);
      if (!isEmpty(changes)) {
        // add each mandatory field to the changes object
        mandatoryFields.forEach((field) => {
          if (values[field]) {
            changes[field] = originalData[field];
          }
        });
        helpers?.setSubmitting(true);
        // console.log(changes, 'useFormik');
        const response = await apiUpdateHandler({
          id: originalData[id],
          changes: { ...changes, hashValue: originalData[hashField] }
        });
        if (response.success) {
          showNotify(response.message);
          saved = { ...saved, success: true };
        } else {
          showError(response?.message || `No se procesaron `);
          saved = { ...saved, error: response.message };
        }
      } else {
        // throw new Error('No se detectaron cambios');
        saved = { ...saved, error: 'No hay cambios detectados' };
        showError(`${saved.error}`);
      }
    } catch (error) {
      showError(`${error}. No se procesaron `);
      // console.error(error);
      saved = { ...saved, error };
    }
    helpers?.setStatus({ success: saved.success });
    if (!saved.success) {
      console.error(saved.error);
      helpers?.setStatus({ success: false });
      helpers?.setErrors({ submit: saved.error });
    } else {
      handleAfterSave?.(saved);
    }

    helpers?.setSubmitting(false);

    return saved;
  };

  const handleAdd = async ({ values, helpers }) => {
    let saved = { success: false };
    try {
      checkBeforeAdd(values, helpers);
      helpers?.setSubmitting(true);
      const response = await apiAddHandler({ values });
      if (response.success) {
        showNotify(response.message);
        saved = response;
      } else {
        showError(response?.message || `No se agregó el registro`);
        saved = { ...saved, error: response.message };
      }
    } catch (error) {
      saved = { ...saved, error };
    }
    helpers?.setStatus({ success: saved.success });
    if (!saved.success) {
      console.error(saved.error);
      helpers?.setStatus({ success: false });
      helpers?.setErrors({ submit: saved.error });
    } else {
      handleAfterSave?.(saved);
    }

    helpers?.setSubmitting(false);
    return saved;
  };

  return [handleUpdate, handleAdd];
};

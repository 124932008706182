import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@mui/material';
import { ArrowRight as ArrowRighthIcon } from '../../../icons/arrow-right';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';

export const HRProgramedDialog = (props) => {
  const {
    open,
    onClose,
    onExited,
    onSelectionConfirm,
    // requestData: documentState,
    title,
    helpText,
    children,
    ...other
  } = props;
  const handleClose = () => {
    onClose();
  };
  const handleSelectionConfirm = () => {
    onSelectionConfirm?.();
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      fullWidth
      maxWidth="lg"
      {...other}
    >
      <DialogTitle id="scroll-dialog-title">
        <CardHeader title={title} />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {helpText}
        </DialogContentText>
        <Card variant="outlined">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6} xs={12}>
              {children}
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="medium"
          startIcon={<ArrowLeftIcon />}
          variant="contained"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSelectionConfirm}
          color="primary"
          size="medium"
          startIcon={<ArrowRighthIcon />}
          variant="contained"
        >
          Seleccionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

HRProgramedDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  onSelectionConfirm: PropTypes.func.isRequired,
  // requestData: PropTypes.object,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  children: PropTypes.node
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Query } from '../../query';
import { FilterDialog } from '../../filter-dialog';
import { RenderIf } from '../../render-if';
import GadminActions from '../../../contexts/gadmin-actions';
import {
  OrderOpennedViews as views,
  OrderFilterProperties as filterProperties,
  OrderOpennedView
} from '../../../api/customer-order-definitions';

import { CustomerOrderBulkActionsMenu } from './cursoter-order-bulk-actions-menu';
import { documentApi } from '../../../api/document';
import { useApp } from '../../../hooks/use-app';

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const CustomerOrdersOpennedFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = OrderOpennedView.VEW_ALL,
    enableSearch,
    onAfterBulkProcess
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [menuActions, setMenuActions] = useState([]);
  const { showNotify } = useApp();

  useEffect(() => {
    setMenuActions([]);
    if ([OrderOpennedView.VEW_PROGRAMMED].includes(view)) {
      setMenuActions((menuActions) => [
        ...menuActions,
        GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO
      ]);
    }
    if ([OrderOpennedView.VEW_NO_PROGRAMMED].includes(view)) {
      setMenuActions((menuActions) => [
        ...menuActions,
        GadminActions.gCC_SAVE_ANULAR_PEDIDO_PROCESADO
      ]);
    }
  }, [view]);

  const handleProcessCancel = async (items) => {
    try {
      const response = await documentApi.cancelOrdersBulk(items);
      const { count, message } = response.data;
      if (!count || count !== items.length) {
        showNotify(
          `procesado  ${response?.count}. Algunos registros no se procesaron.${message} `,
          'warning'
        );
      } else {
        onAfterBulkProcess?.();
        showNotify(`Solicitados  ${items.length}. ${message} `);
      }
    } catch (error) {
      showNotify(`Error al procesar  ${error}`, 'error');
      console.error(error);
    }
  };
  const handleProcessAuthorize = async () => {};
  const handleProcessUnAuthorize = async () => {};

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <CustomerOrderBulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            onCancel={async () => {
              await handleProcessCancel(selectedDocuments);
            }}
            onAuthorize={async () => {
              await handleProcessAuthorize(selectedDocuments);
            }}
            onNoAuthorize={async () => {
              await handleProcessUnAuthorize(selectedDocuments);
            }}
            selectedCount={selectedDocuments.length}
            sx={{
              display: selectedDocuments.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          />
          <RenderIf condition={enableSearch}>
            <Query
              disabled={disabled}
              onChange={onQueryChange}
              sx={{
                order: {
                  sm: 2,
                  xs: 1
                }
              }}
              value={query}
            />
          </RenderIf>
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

CustomerOrdersOpennedFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onAfterBulkProcess: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.string,
  enableSearch: PropTypes.bool
};

import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
/* eslint class-methods-use-this:0 */

class GProcApi {
  async getTimmingOrders() {
    const response = await GadminRequest(GadminActions.gCC_GPROC_MNGMT, {
      type: 'gproc-timming-orders'
    });
    const { data } = response;
    return Promise.resolve(data);
  }

  async getDefaults() {
    const response = await GadminRequest(GadminActions.gCC_GPROC_MNGMT, {
      type: 'gproc-defaults'
    });

    return response;
  }

  async getModules() {
    const response = await GadminRequest(GadminActions.gCC_GPROC_MNGMT, {
      type: 'gproc-modules'
    });

    return response;
  }

  async upDefaults(payload) {
    const response = await GadminRequest(GadminActions.gCC_GPROC_MNGMT, {
      type: 'gproc-defaults-update',
      payload
    });
    const { data } = response;
    return Promise.resolve(data);
  }

  async cleanPortfolio() {
    const response = await GadminRequest(GadminActions.gCC_GPROC_MNGMT, {
      type: 'gproc-clean-portfolio'
    });
    return response;
  }

  async grantAll(payload) {
    const response = await GadminRequest(GadminActions.gCC_GPROC_MNGMT, {
      ...payload
    });
    return response;
  }

  async reindex(payload) {
    const response = await GadminRequest(GadminActions.gCC_GPROC_MNGMT, payload);
    return response;
  }
}

export const gProcApi = new GProcApi();

import { useEffect, useState } from 'react';
import moment from 'moment';
import Proptypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  InputAdornment
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useSelection } from '../../../../hooks/use-selection';
import { useDialog } from '../../../../hooks/use-dialog';
import { useApp } from '../../../../hooks/use-app';
import { goalCustomerPromotionsApi } from '../../../../api/goal/promotions/goal-customer-promotions';
import { useDataBrowser } from '../../../../hooks/use-data-browser';
import { useQuery } from '../../../../hooks/use-query';
import { useMounted } from '../../../../hooks/use-mounted';
import GadminActions from '../../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../../icons/plus';
import { Trash as TrashIcon } from '../../../../icons/trash';
import { InputField } from '../../../input-field';
import { Search } from '../../../../icons/search';
import { HRProgramedDialog } from '../../hr/hr-programed-dialog';
import { QueryCustomerModal } from '../../../../containers/promotions/query-customer-modal';
import { AllowedGuard } from '../../../../hooks/use-allowed';
import { ProductDialog } from './product-dialog';

export const LiquidationForm = ({ title, onClose, initialValues = {} }) => {
  const [selectData, setSelectData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const query = useQuery();
  const mounted = useMounted();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };
  useEffect(() => {
    if (queried.query) handleSetController.setSearchText({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    ,
    ,
    ,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomerPromotionsApi.getPromotionsList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
  });

  const { showNotify } = useApp();
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const [selectedItems, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    returnsState.isLoading ? [] : returnsState.documents
  );

  const handleExitedDialog = () => {};
  const handleSelectionConfirm = () => {
    if (!!selectedItems && selectedItems.length > 0) {
      //   console.log('selectedItems', selectedItems);
      console.log('selectData', selectData);
      handleCloseEditDialog();
    } else {
      showNotify('No se ha seleccionado filas', 'warning');
    }
  };

  const formik = useFormik({
    initialValues: {
      cod: initialValues.cod || '',
      name: initialValues.name || '',
      warehouse: initialValues.warehouse || '',
      calculator: initialValues.calculator || 0,
      not_dispatched: initialValues.not_dispatched || 0,
      counted: initialValues.counted || 0,
      recounted: initialValues.recounted || 0,
      difference: initialValues.difference || 0,
      amount: initialValues.amount || 0,
      division: initialValues.division || 'tradicional'
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log(values);
    }
  });

  const handleModalProduct = () => {
    if (formik.values.name.length > 4) {
      handleOpenEditDialog();
      handleClearSelected();
      setSelectData([]);
    } else {
      setErrorMessage('Ingrese mas de 4 digitos');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Producto"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                    <Search onClick={handleModalProduct} />
                  </InputAdornment>
                )
              }}
            />
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Codigo"
              name="cod"
              value={formik.values.cod}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.cod && Boolean(formik.errors.cod)}
              helperText={formik.touched.cod && formik.errors.cod}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Almacen"
              name="warehouse"
              value={formik.values.warehouse}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.warehouse && Boolean(formik.errors.warehouse)}
              helperText={formik.touched.warehouse && formik.errors.warehouse}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Calculado"
              name="calculator"
              type="number"
              value={formik.values.calculator}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.calculator && Boolean(formik.errors.calculator)}
              helperText={formik.touched.calculator && formik.errors.calculator}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="No despachado"
              name="not_dispatched"
              type="number"
              value={formik.values.not_dispatched}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.not_dispatched && Boolean(formik.errors.not_dispatched)}
              helperText={formik.touched.not_dispatched && formik.errors.not_dispatched}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Contado"
              name="counted"
              type="number"
              value={formik.values.counted}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.counted && Boolean(formik.errors.counted)}
              helperText={formik.touched.counted && formik.errors.counted}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Re contado"
              name="recounted"
              type="number"
              value={formik.values.recounted}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.recounted && Boolean(formik.errors.recounted)}
              helperText={formik.touched.recounted && formik.errors.recounted}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Diferencia"
              name="difference"
              type="number"
              value={formik.values.difference}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.difference && Boolean(formik.errors.difference)}
              helperText={formik.touched.difference && formik.errors.difference}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputField
              label="Importe"
              name="amount"
              type="number"
              value={formik.values.amount}
              onChange={formik.handleChange}
              fullWidth
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2,
            marginLeft: 1
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <HRProgramedDialog
        onClose={handleCloseEditDialog}
        onSelectionConfirm={handleSelectionConfirm}
        onExited={handleExitedDialog}
        open={editDialogOpen}
        title="Producto"
        helpText=""
      >
        <QueryCustomerModal
          disabled={returnsState.isLoading}
          onQueryChange={handleQueryChange}
          query={controller.query}
          handleRefresh={handleRefresh}
          title="Seleccionar un producto"
        />
        <ProductDialog
          requestData={returnsState.documents}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          selectedItems={selectedItems}
          setSelectData={setSelectData}
          selectData={selectData}
          isLoading={returnsState.isLoading}
          error={returnsState.error}
          handleClearSelected={handleClearSelected}
        />
      </HRProgramedDialog>
    </Box>
  );
};

LiquidationForm.propTypes = {
  // update: Proptypes.bool,
  initialValues: Proptypes.object,
  onClose: Proptypes.func,
  title: Proptypes.string
  // handleRefresh: Proptypes.func
};

import PropTypes from 'prop-types';
import { Box, Button, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowLeft from '@iconify/icons-eva/arrow-left-fill';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { StatusColor } from '../../contest/contest-definition';
import { Status } from '../../status';

const BusinesPartnerAddressDetail = ({ setEditMode, item = {}, setItemEditDetail }) => (
  <Box>
    <Button
      color="primary"
      size="large"
      startIcon={<Icon icon={arrowLeft} />}
      onClick={() => {
        setItemEditDetail(false);
      }}
      sx={{
        marginLeft: 2
      }}
    >
      Regresar
    </Button>
    <CardHeader title="Detalle de dirección" />
    <Divider />
    <Grid container spacing={8}>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem label="Empresa">
            <Typography color="textSecondary" variant="body2">
              {item.company_id || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Código de cliente" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.customer_code || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Código de dirección" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.code || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Tipo de dirección" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.address_type === 'S' ? 'Dirección de entrega' : 'Dirección fiscal'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Dirección" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.address || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Giro de negocio" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.business_type_id || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Teléfono" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.phone || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem label="Vendedor" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.seller_id || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Sedes" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.branch_id || '---'}
            </Typography>
          </PropertyListItem>
          {/* <PropertyListItem label="Coordenadas" align="right">
              <Typography color="textSecondary" variant="body2" align="right">
                {item.coordinates || '---'}
              </Typography>
            </PropertyListItem> */}
          <PropertyListItem label="Territorio" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.territory_id || '---'} - {item.territory_code || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Código postal" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.postal_code || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Centro poblado" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.cpo_code || '---'} - {item.cpo_name || '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item md={4} xs={12}>
        <PropertyList>
          <PropertyListItem label="Ubigeo" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.ubigeo_code || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Distrito" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.district || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Provincia" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.province || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Departamento" align="right">
            <Typography color="textSecondary" variant="body2" align="right">
              {item.department || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem label="Estado">
            <Status color={StatusColor(item.status)} label="Activo" />
          </PropertyListItem>
        </PropertyList>
      </Grid>
    </Grid>

    <AllowedGuard permission={GadminActions.G2_CONTESTS_UP}>
      <Button
        color="primary"
        size="large"
        startIcon={<PlusIcon />}
        onClick={() => {
          setEditMode(true);
        }}
        variant="contained"
        sx={{
          marginLeft: 2
        }}
      >
        Editar
      </Button>
    </AllowedGuard>
  </Box>
);

export default BusinesPartnerAddressDetail;

BusinesPartnerAddressDetail.propTypes = {
  setEditMode: PropTypes.func,
  item: PropTypes.object,
  setItemEditDetail: PropTypes.func
};

import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalIncidentsApi {
  async getIncidencesList(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, id } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/incidence/incidences?company_id=${id}`,
      cacheItem: 'customer-Incidents-list',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' }
        // { type: 'string', name: 'statusName' },
        // { type: 'string', name: 'company_name' },
        // { type: 'string', name: 'type_name' },
        // { type: 'string', name: 'type_source_name' },
        // { type: 'string', name: 'period_name' },
        // { type: 'string', name: 'measure_name' },
        // { type: 'number', name: 'min_coverage' },
        // { type: 'number', name: 'min_customer' }
      ],
      ...options
    });
  }

  async getIncidenceId(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidences/${id}`
    });
    return response;
  }

  async postIncidenceForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/incidence/incidences',
      changes: values
    });
    return response;
  }

  async getTypeIncidents({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `incidence/resources/types?companyId=${companyId}`
    });
    return response;
  }

  async getIncidentsActivate(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidences/${id}/activate`
    });
    return response;
  }

  async getIncidentsCancele(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidences/${id}/cancel`
    });
    return response;
  }

  async getIncidentsClose(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidences/${id}/close`
    });
    return response;
  }

  async getIncidentsValidate(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidences/${id}/validate`
    });
    return response;
  }

  async getIncidentsReject(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidences/${id}/reject`
    });
    return response;
  }

  async getIncidentsArchived(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidences/${id}/archive`
    });
    return response;
  }

  async getIncidentsReferences(type = 'WAREHOUSE', companyId = 8) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/resources/references?companyId=${companyId}&type=${type}`
    });
    return response;
  }

  async getIncidentsReferencesTypes({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/resources/reference-types?companyId=${companyId}`
    });
    return response;
  }

  // Maintenance
  async getIncidentsApi(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/incidence/incidence-categories`,
      cacheItem: 'incidents',
      apiParams: {
        companyId,
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        view,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  async getIncidentsList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `incidence/incidence-categories?companyId=${companyId}`
    });
    return response;
  }

  async getIncidentsConfig(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/incidence/incidence-categories/config?companyId=${companyId}`
    });
    return response;
  }

  async getIncidentsContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `incidence/incidence-categories/${id}/incidence?companyId=${companyId}`
    });
    return response;
  }

  async postIncidentsForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/incidence/incidence-categories',
      changes: values
    });
    return response;
  }

  async updateIncidentsForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/incidence/incidence-categories/${id}`,
      changes: data
    });
    return response;
  }
}

export const goalIncidentsApi = new GoalIncidentsApi();

import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
// import moment from 'moment';
import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Icon } from '@iconify/react';
import searchIcon from '@iconify/icons-eva/search-fill';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { Pagination } from '../../pagination';
// import { Trash } from '../../icons/trash';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { CustomerModal } from '../../delivery/monitoring/customer-modal';
import SurveyForm from './survey-form';
import { SurveyItemMenu } from '../survey-item-menu';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { ConfirmationDialog } from '../../confirmation-dialog';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: '#'
  },
  {
    id: 'title',
    label: 'Titulo'
  },
  {
    id: 'category',
    label: 'Categoria'
  },
  {
    id: 'subcategory',
    label: 'Subcategoria'
  },
  {
    id: 'type_question',
    label: 'Tipo de Pregunta'
  },
  {
    id: 'question',
    label: 'Pregunta'
  },
  {
    id: 'options',
    label: 'Opciones'
  },
  {
    id: 'correct',
    label: 'Respuesta'
  },
  {
    id: 'score',
    label: 'Puntaje'
  },
  {
    id: 'action',
    label: ''
  }
];

export const SurveyTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate',
    handleRefresh
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const [modalOpen, setModalOpen] = useState(false);
  const [open, onClose] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [itemData, setitemEdit] = useState(null);
  // const handleClickEdit = (id) => {
  //   const item = documents.filter((obj) => obj.id === id);
  //   setitemEdit(item[0]);
  //   onClose(!open);
  // };

  const [idDelete, setIdDelete] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');

  const handleOpenCornfirm = (document) => {
    setModalOpen(true);
    setIdDelete(document.id);
    setConfirmDialogMessage(`Estas seguro de eliminar este cuestionario de ${document.title}`);
  };

  const handleClickDelete = async (id) => {
    await goalCustomeSurveyApi.deleteSurveyForm(id);
    setModalOpen(false);
    handleRefresh();
  };

  useEffect(() => {
    getCustomerData();
  }, []);

  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);

  const getCustomerData = async () => {
    const categoryData = await goalCustomeSurveyApi.getSurveyCategory();
    const subCategoryData = await goalCustomeSurveyApi.getSurveySubCategory();
    // console.log(categoryData.data);
    setCategoryData(categoryData.data);
    setSubCategoryData(subCategoryData.data);
  };
  // console.log(categoryData);

  const getNameByIdCategory = (id) => {
    const item = categoryData ? categoryData.find((item) => item.id === id) : [];
    return item ? item.name : null;
  };

  const getNameByIdSubCategory = (id) => {
    const item = subCategoryData ? subCategoryData.find((item) => item.id === id) : [];
    return item ? item.name : null;
  };

  // icon edit delete
  const handleClickEditIcon = (item) => {
    setitemEdit(item);
    onClose(!open);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow hover key={document.id}>
                <TableCell sx={{ minWidth: 100 }}>
                  {/* <Typography color="textSecondary" variant="body2">
                    {index + 1} -
                  </Typography> */}
                  {index + 1} -
                  <IconButton
                    onClick={() => handleClickEditIcon(document)}
                    color="secondary"
                    aria-label="edit"
                  >
                    <Icon icon={searchIcon} width={30} height={30} color="#3493DA" />
                  </IconButton>
                </TableCell>
                {/* <TableCell>
                  <IconButton
                    onClick={() => handleClickEdit(document.id)}
                    color="secondary"
                    aria-label="edit"
                  >
                    <Pencil />
                  </IconButton>
                </TableCell> */}
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.category} -{getNameByIdCategory(document.category)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.subcategory} - {getNameByIdSubCategory(document.subcategory)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.type_question}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.question}
                  </Typography>
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ||
                  document.type_question === 'select' ||
                  document.type_question === 'range' ? (
                    document.options.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ? (
                    document.correct.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {document.correct}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.score}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <IconButton
                    onClick={() => handleOpenCornfirm(document.id)}
                    color="primary"
                    aria-label="delete"
                  >
                    <Trash />
                  </IconButton>
                </TableCell> */}
                <TableCell>
                  <AllowedGuard permission={GadminActions.G2_QUESTIONARY_UP}>
                    <SurveyItemMenu
                      items={document}
                      clickEdit={() => handleClickEditIcon(document)}
                      clickDelete={() => handleOpenCornfirm(document)}
                    />
                  </AllowedGuard>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={handleCloseModal}
        onConfirm={() => handleClickDelete(idDelete)}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
      <CustomerModal open={open} onClose={onClose} permission={GadminActions.G2_QUESTIONARY_UP}>
        <SurveyForm
          update
          item={itemData}
          onClose={onClose}
          handleRefresh={handleRefresh}
          subcategorylist={subCategoryData}
          categorylist={categoryData}
        />
      </CustomerModal>
    </Box>
  );
};

SurveyTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  handleRefresh: Proptypes.func
};

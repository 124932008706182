import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography } from '@mui/material';
import { documentApi } from '../../../api/document';
import { useMounted } from '../../../hooks/use-mounted';
import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';
import LoadingContent from '../../../components/common/loading-content';

export const ReceiptNumber = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();
  const [receiptInfoState, setReceiptInfoState] = useState({ isLoading: true });

  const navigate = useNavigate();

  const getReceiptInfo = useCallback(async (payload) => {
    setReceiptInfoState(() => ({ isLoading: true, message: 'Cargando Comprobante' }));
    try {
      const result = await documentApi.receiptDetailTransaction(payload);

      if (mounted.current) {
        setReceiptInfoState(() => ({
          ...result,
          isLoading: false,
          redirect: !!result.data?.transId
        }));
      }
    } catch (err) {
      if (mounted.current) {
        setReceiptInfoState(() => ({
          isLoading: false,
          error: err.message ? err.message : 'Error del servidor'
        }));
      }
    }
  }, []);

  useEffect(() => {
    try {
      if (!id) throw new Error('no hay datos para buscar');
      const values = id.split('-');
      if (!values || values.length !== 2) throw new Error('Parámetros insuficientes');
      getReceiptInfo({
        serie: values[0],
        number: values[1]
      }).catch(console.error);
    } catch (error) {
      setReceiptInfoState(() => ({
        isLoading: false,
        message: 'No se puede encontrar el comprobante',
        error
      }));
    }
  }, []);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    if (mounted.current) {
      if (receiptInfoState.redirect && receiptInfoState.data?.transId) {
        navigate(`${appUrlBase}/sales/receipts/${receiptInfoState.data.transId}`);
      }
    }
  }, [receiptInfoState]);

  const renderContent = () => {
    if (receiptInfoState.isLoading || receiptInfoState.error) {
      return (
        <LoadingContent loadingText={receiptInfoState.message} error={receiptInfoState.error} />
      );
    }
    return (
      <Typography color="textPrimary" variant="h4">
        # Comprobante {id}
      </Typography>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Buscar comprobante')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
import { invoiceApi } from '../../../api/invoice';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Query } from '../../query';
import { FilterDialog } from '../../filter-dialog';
import GadminActions from '../../../contexts/gadmin-actions';
import { documentViews } from '../../../api/purchase-definitions';
import { BulkActionsMenu } from '../../bulk-actions-menu';

const views = [
  {
    label: 'Borrador',
    value: 'D'
  },
  {
    label: 'Validado',
    value: 'V'
  },
  {
    label: 'En proceso',
    value: 'X'
  },
  {
    label: 'Contabilizado',
    value: 'F'
  },
  {
    label: 'Mostrar todos',
    value: 'all'
  },
  {
    label: 'Anulados',
    value: 'E'
  }
];

const filterProperties = [
  {
    label: 'Invoice date',
    name: 'issueDate',
    type: 'date'
  },
  {
    label: 'Due date',
    name: 'dueDate',
    type: 'date'
  },
  {
    label: 'Total',
    name: 'totalAmount',
    type: 'string'
  },
  {
    label: 'Payment method',
    name: 'paymentMethod',
    type: 'string'
  },
  {
    label: 'Status',
    name: 'status',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const InvoicesFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    onRefresh,
    query,
    selectedInvoices = [],
    view = documentViews.VIEW_DRAFT
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [menuActions, setMenuActions] = useState([]);
  const afterProcessHandler = () => {
    // if (result) {
    //   if (menuItem.action === DeliveryVehicleActions.CANCEL) {
    //     onFiltersApply();
    //   }
    // }
    // }
    onRefresh?.();
  };
  useEffect(() => {
    let actions = [];

    if (
      [
        documentViews.VIEW_DRAFT,
        documentViews.VIEW_VALIDATED,
        documentViews.VIEW_PROCESING
      ].includes(view)
    ) {
      actions.push({
        action: GadminActions.gCC_PURCHASE_FFEE_DISCARD,
        label: 'Descartar',
        prompt: '¿Está seguro que desea descartar lod documentos seleccionados?'
      });
    }

    if ([documentViews.VIEW_DRAFT].includes(view)) {
      actions.push({
        action: GadminActions.gCC_PURCHASE_FFEE_CAB_VAL,
        label: 'Validar',
        prompt: '¿Está seguro que desea validar los documentos seleccionados?'
      });
    }
    if ([documentViews.VIEW_CANCELED].includes(view)) {
      actions.push({
        action: GadminActions.G2_PURCHASE_REACTIVE,
        label: 'Reactivar',
        prompt: '¿Está seguro que desea reactivar los documentos seleccionados?'
      });
    }
    if ([documentViews.VIEW_VALIDATED].includes(view)) {
      actions = [
        ...actions,
        {
          action: GadminActions.gCC_PURCHASE_FFEE_CONFIRM,
          label: 'Confirmar',
          prompt: '¿Está seguro que desea confirmar los documentos seleccionados?'
        },
        {
          action: GadminActions.gCC_PURCHASE_FFEE_NOCONFIRM,
          label: 'NO Confirmar',
          prompt: '¿Está seguro que desea NO confirmar los documentos seleccionados?'
        }
      ];
    }
    if ([documentViews.VIEW_PROCESING].includes(view)) {
      actions = [
        ...actions,
        {
          action: GadminActions.gCC_GPROC_QUEUE_UP,
          label: 'Encolar',
          prompt: '¿Está seguro que desea re-encolar los documentos seleccionados?'
        }
      ];
    }
    setMenuActions(actions);
  }, [view]);

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedInvoices.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <BulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            selectedItems={selectedInvoices}
            selectedCount={selectedInvoices.length}
            afterProcessHandler={afterProcessHandler}
            apiHandler={invoiceApi.processInvoiceBulk}
            sx={{
              display: selectedInvoices.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          />
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

InvoicesFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  onRefresh: PropTypes.func,
  query: PropTypes.string,
  selectedInvoices: PropTypes.array,
  view: PropTypes.string
};

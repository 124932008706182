import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Switch,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { goalMaintenancePayrollApi } from '../../../api/goal/maintenance/goal-maintenance-payroll';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { InputField } from '../../input-field';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import { RenderIf } from '../../render-if';
import { X as XIcon } from '../../../icons/x';
import { useMounted } from '../../../hooks/use-mounted';
import { useTreeViewData } from '../../../hooks/use-tree-view-data';
import { CommonTreeView } from '../../common/common-tree-view';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import { ConstantStatus, ConstantYesNo } from '../../../api/application-enums';
import EmployeePositionDetail from '../base/employee-position-detail';

export const MaintenanceEmployeePositionForm = ({
  title,
  initialValues = {},
  initialData = [],
  configState = {},
  departamentState = []
}) => {
  const { showNotify, showError, currentCompany } = useApp();
  const mounted = useMounted();

  const [codeExistError, setCodeExistError] = useState(false);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);
  const [selectAction, setSelectAction] = useState('');

  const [editMode, setEditmode] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const handleAfterSave = () => {
    formik.resetForm();
    employeePositionActions.onLoadRoot({ companyId: currentCompany });
  };

  const [employeePositionState, employeePositionActions] = useTreeViewData({
    rootApi: goalMaintenancePayrollApi.getEmployeePositionsList,
    childrenApi: goalMaintenancePayrollApi.getChildrenEmployeePosition,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando puesto de empleados',
    mounted,
    defaults: {}
  });

  useEffect(() => {
    const loadTreeRoot = async () => {
      employeePositionActions.onLoadRoot({ companyId: currentCompany });
    };
    loadTreeRoot();
  }, [currentCompany]);

  const onNodeSelection = (item) => {
    employeePositionActions.onSelectNode(item);
    setSelectedNodeDataItem(item);
    setSelectAction('edit-node');
    formik.setFieldValue('name', item.name);
    formik.setFieldValue('code', item.code);
    formik.setFieldValue('level', item.level);
    formik.setFieldValue('parent_id', item.parent_id);
    formik.setFieldValue('company_id', item.company_id);
    formik.setFieldValue('department_id', item.department_id);
    formik.setFieldValue('for_sale', (item.for_sale || ConstantYesNo.NO) === ConstantYesNo.YES);
    formik.setFieldValue('number_of_employees', item.number_of_employees);
    formik.setFieldValue('number_of_vacancies', item.number_of_vacancies);
    formik.setFieldValue('number_of_employees_required', item.number_of_employees_required);
    formik.setFieldValue('status', item.status);
  };

  const formik = useFormik({
    initialValues: {
      company_id: currentCompany || '',
      code: initialValues.code || '',
      name: initialValues.name || '',
      depends_on_id: initialValues.depends_on_id || null,
      for_sale: (initialValues.for_sale || ConstantYesNo.NO) === ConstantYesNo.YES,
      parent_id: initialValues.parent_id || null,
      level: initialValues.level || '1',
      department_id: initialValues.department_id || null,
      number_of_employees: initialValues.number_of_employees || 0,
      number_of_vacancies: initialValues.number_of_vacancies || 0,
      number_of_employees_required: initialValues.number_of_employees_required || 0,
      status: initialValues.status || ConstantStatus.ACTIVE
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      code: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      const for_sale = values.for_sale ? ConstantYesNo.YES : ConstantYesNo.NO;
      const newValues = { ...values, for_sale };
      if (selectAction === 'edit-node') {
        handleEditNode(newValues);
        return;
      }
      if (selectedNodeDataItem === null && selectAction === 'create-node') {
        handleAddMajor(newValues);
      } else {
        handleAddNode(newValues);
      }
    }
  });

  const handleEditNode = async (values) => {
    const response = await goalMaintenancePayrollApi.updateEmployeePositionsForm(
      selectedNodeDataItem.id,
      values
    );
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      handleAfterSave();
      handleCancel();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const handleAddNode = async (values) => {
    const codeExists = initialData.some((item) => item.code === values.code);
    if (codeExists) {
      setCodeExistError(true);
      setTimeout(() => {
        setCodeExistError(false);
      }, 3000);
      return;
    }
    if (!configState.success) {
      showError(configState?.message);
      return;
    }
    if (
      (configState?.data['max-level'] > parseInt(selectedNodeDataItem.level, 10) &&
        configState?.data['max-level']) ||
      configState?.data['max-level'] === -1
    ) {
      const newLevel = (parseInt(selectedNodeDataItem.level, 10) + 1).toString();
      const newObj = {
        ...values,
        level: newLevel,
        parent_id: selectedNodeDataItem.id
      };
      const response = await goalMaintenancePayrollApi.postEmployeePositionsForm({
        values: newObj,
        companyId: currentCompany
      });
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handleAfterSave();
        handleCancel();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    } else {
      showError(configState.success ? 'Level maximo alcanzado' : configState?.message);
    }
  };

  const handleAddMajor = async (values) => {
    const codeExists = initialData.some((item) => item.code === values.code);
    if (codeExists) {
      setCodeExistError(true);
      setTimeout(() => {
        setCodeExistError(false);
      }, 3000);
      return;
    }
    const response = await goalMaintenancePayrollApi.postEmployeePositionsForm({
      values,
      companyId: currentCompany
    });
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      handleAfterSave();
      handleCancel();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const handleCancel = () => {
    setEditmode(false);
    setSelectAction('');
  };

  const handleAddNodeMajor = () => {
    formik.setFieldValue('name', '');
    formik.setFieldValue('code', '');
    formik.setFieldValue('number_of_employees', 0);
    formik.setFieldValue('number_of_vacancies', 0);
    formik.setFieldValue('number_of_employees_required', 0);
    formik.setFieldValue('department_id', '');
  };

  const onDeleteNode = async (id) => {
    const response = await goalMaintenancePayrollApi.deleteEmployeePositionsId({
      id,
      companyId: currentCompany
    });
    if (response.success) {
      showNotify(response?.message || `Cambio exitoso `);
      handleAfterSave();
      handleCancel();
    } else {
      showError(response?.message || `No se proceso la acción `);
    }
  };

  return (
    <Box
      sx={{
        minWidth: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingX: 5,
        marginBottom: 3
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item md={6} xs={12}>
            <PropertyList>
              <CommonTreeView
                expandedItems={expandedItems}
                setExpandedItems={setExpandedItems}
                title="Puestos de empleados..."
                onSelectNode={onNodeSelection}
                data={employeePositionState.data}
                setSelectedNodeDataItem={setSelectedNodeDataItem}
                onDeleteNode={onDeleteNode}
                deleteAction
              />
            </PropertyList>
          </Grid>
          <Grid item md={6} xs={12}>
            <RenderIf
              condition={editMode}
              no={
                <EmployeePositionDetail
                  setEditMode={setEditmode}
                  item={selectedNodeDataItem}
                  setSelectAction={setSelectAction}
                  handleAddNodeMajor={handleAddNodeMajor}
                />
              }
            >
              <InputField
                label="Código"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                sx={{ marginBottom: '1em' }}
                // disabled={selectAction === 'edit-node'}
              />
              {codeExistError && (
                <Alert sx={{ marginBottom: 2 }} severity="warning">
                  Este Código ya existe
                </Alert>
              )}
              <CompanyInputDisabled currentCompany={currentCompany} />
              <InputField
                label="Nombre"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                select
                label="Departamento"
                name="department_id"
                value={formik.values.department_id}
                fullWidth
                onChange={formik.handleChange}
                sx={{ marginBottom: 2 }}
                error={formik.touched.department_id && Boolean(formik.errors.department_id)}
                helperText={formik.touched.department_id && formik.errors.department_id}
              >
                {departamentState.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </InputField>
              <Grid>
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  Venta
                </Typography>
                <Switch
                  checked={formik.values.for_sale}
                  onChange={formik.handleChange}
                  name="for_sale"
                  color="primary"
                />
              </Grid>
              <InputField
                label="Número de empleados"
                name="number_of_employees"
                value={formik.values.number_of_employees}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.number_of_employees && Boolean(formik.errors.number_of_employees)
                }
                helperText={formik.touched.number_of_employees && formik.errors.number_of_employees}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                label="Número de vacantes"
                name="number_of_vacancies"
                value={formik.values.number_of_vacancies}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.number_of_vacancies && Boolean(formik.errors.number_of_vacancies)
                }
                helperText={formik.touched.number_of_vacancies && formik.errors.number_of_vacancies}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                label="Número de empleados requerido"
                name="number_of_employees_required"
                value={formik.values.number_of_employees_required}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.number_of_employees_required &&
                  Boolean(formik.errors.number_of_employees_required)
                }
                helperText={
                  formik.touched.number_of_employees_required &&
                  formik.errors.number_of_employees_required
                }
                sx={{ marginBottom: '1em' }}
              />
            </RenderIf>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                {editMode && selectAction !== 'create-node' && (
                  <>
                    <LoadingButton
                      color="primary"
                      size="large"
                      startIcon={<PencilIcon />}
                      variant="contained"
                      loading={formik.isSubmitting}
                      disabled={selectAction !== 'edit-node'}
                      type="submit"
                    >
                      Guardar
                    </LoadingButton>
                    <Button
                      color="secondary"
                      size="large"
                      startIcon={<XIcon />}
                      onClick={() => setEditmode(false)}
                      sx={{
                        marginLeft: 1
                      }}
                    >
                      Cancelar
                    </Button>
                  </>
                )}

                {selectAction === 'create-node' && (
                  <LoadingButton
                    color="primary"
                    size="large"
                    startIcon={<PlusIcon />}
                    variant="contained"
                    type="submit"
                    loading={formik.isSubmitting}
                  >
                    Guardar
                  </LoadingButton>
                )}
                {selectAction === 'create-node' && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<XIcon />}
                    onClick={handleCancel}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cancelar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

MaintenanceEmployeePositionForm.propTypes = {
  initialValues: Proptypes.object,
  title: Proptypes.string,
  initialData: Proptypes.array,
  configState: Proptypes.object,
  departamentState: Proptypes.array
};

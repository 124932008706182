import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { useMounted } from '../../hooks/use-mounted';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { useSelection } from '../../hooks/use-selection';
import { useDataBrowser } from '../../hooks/use-data-browser';

import LoadingContent from '../../components/common/loading-content';
import { GexReportsFilter } from '../../components/gex/gex-reports-filter';
import { DexReportsTable } from '../../components/gex/dex-report-table';
import { DocumentLineDetailDialog } from '../../components/purchases/common/document-line-detail-dialog';
import { useDialog } from '../../hooks/use-dialog';
import { DexReportEdit } from '../../components/gex/dex-report-edit';
import { goalDexApi } from '../../api/goal/dex/goal-dex';
// import { useAuth } from '../../hooks/use-auth';

export const DexReports = () => {
  const mounted = useMounted();
  const { title } = useApp();
  const [editable, setEditable] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [currentLine, setCurrentLine] = useState({});
  // const { hasPermission } = useAuth();

  const [detailDialogOpen, handleOpenDetailDialog, handleCloseDetailDialog] = useDialog();

  const handleOpenDialogWithNew = () => {
    setCurrentLine?.({
      id: -1,
      name: 'Nuevo',
      value: '',
      code: 'NEW',
      type: 0,
      typeName: '',
      permission: '',
      companies: [],
      company: 0,
      companyName: '',
      query: '',
      fixedFileName: false,
      fileName: '',
      sheetName: '',
      tableName: '',
      parameter: ''
    });
    setEditable(true);
    setIsNew(true);
    handleOpenDetailDialog?.();
  };

  const handleExitedDetailDialog = () => {
    // if (selectedVariant) {
    //   setSelectedVariant(null);
    // }
  };
  const [
    documentState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller
  ] = useDataBrowser({
    sourceApi: goalDexApi.getDexReportList,
    loadingMessage: 'Cargando reportes',
    mounted
  });

  const [selectedReports, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    documentState.documents
  );
  useEffect(() => {
    setEditable(true);
    setIsNew(false);
    // setEditable(hasPermission(GadminActions.gCC_GEX_RESPORTS_UP));
    handleClearSelected();
  }, [documentState.documents]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  const renderContent = () => {
    if (!controller.refresh && (documentState.isLoading || documentState.error)) {
      return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
    }

    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Reportes Para Excel - DEX
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.gCC_GEX_RESPORTS_LIST}>
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              >
                Refresh
              </Button>
            </AllowedGuard>
            <AllowedGuard permission={GadminActions.gCC_GEX_RESPORTS_UP}>
              <Button
                color="primary"
                size="large"
                startIcon={<PlusIcon />}
                onClick={handleOpenDialogWithNew}
                variant="contained"
              >
                Nuevo
              </Button>
            </AllowedGuard>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          />
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              }
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <GexReportsFilter
                disabled={documentState.isLoading}
                filters={controller.filters}
                onFiltersApply={handleFiltersApply}
                onFiltersClear={handleFiltersClear}
                onQueryChange={handleQueryChange}
                onViewChange={handleViewChange}
                onRefresh={handleRefresh}
                query={controller.query}
                selectedReports={selectedReports}
                view={controller.view}
              />
              <DexReportsTable
                error={documentState.error}
                reports={documentState.documents}
                reportsCount={documentState.documentsCount}
                isLoading={documentState.isLoading}
                onPageChange={handlePageChange}
                onSelect={handleSelect}
                onSelectAll={handleSelectAll}
                onSortChange={handleSortChange}
                page={controller.page + 1}
                selectedReports={selectedReports}
                sort={controller.sort}
                sortBy={controller.sortBy}
                setCurrentLine={setCurrentLine}
                handleOpenEditDialog={handleOpenDetailDialog}
              />
            </Grid>
          </Grid>
        </Grid>
        <DocumentLineDetailDialog
          open={detailDialogOpen}
          onClose={handleCloseDetailDialog}
          onExited={handleExitedDetailDialog}
          currentLine={currentLine}
          onAfterSave={handleRefresh}
          Component={DexReportEdit}
          title={`Dex - ${isNew ? ' Nuevo reporte' : 'Editar reporte'}`}
          editable={editable}
          isNew={isNew}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Reportes Dex')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { PromoAssignedTimingTable } from './assigned-timing-table';

export const PromoAssignedTiming = (props) => {
  const { promoAssigned } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Cronometro" />
      <Divider />
      <PromoAssignedTimingTable promoAssigned={promoAssigned} />
    </Card>
  );
};

PromoAssignedTiming.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};

const RouteAction = {
  register: 'register',
  current: 'set-current',
  activeRoute: 'set-active-route',
  checkPermission: 'check-permission'
};

const InitialtRoute = {
  current: '/',
  routes: [],
  paths: [],
  userRoutes: [],
  sidebarRoutes: [],
  loginPath: '/auth/login-page/',
  logoutPath: '/auth/logout-page',
  homePath: '/admin/dashboard'
};

const registerSidebarRoutes = (routes, nodes) => {
  if (!nodes.fullpath) nodes.fullpath = `/${nodes.path.replace('/*', '/')}`;
  if (!nodes.fullpath.endsWith('/')) nodes.fullpath += '/';
  if (nodes.children) {
    const newChild = nodes.children.map((item) => ({
      ...item,
      fullpath: `${nodes.fullpath}${item.path}`,
      basepath: `${nodes.fullpath}`
    }));
    nodes.children = newChild.reduce(registerSidebarRoutes, []);
    routes.push({
      ...nodes,
      open: true,
      path: nodes.path,
      fullpath: nodes.fullpath,
      children: nodes.children,
      // name: nodes.fullpath,
      collapse: true
    });
  } else
    routes.push({
      ...nodes,
      open: true,
      path: nodes.path,
      fullpath: nodes.fullpath,
      basepath: nodes.basepath
      // name: nodes.fullpath
    });

  return routes;
};

const register = (routes, nodes) => {
  if (!nodes.fullpath) nodes.fullpath = `/${nodes.path.replace('/*', '/')}`;
  if (!nodes.fullpath.endsWith('/')) nodes.fullpath += '/';
  routes.push({ path: nodes.path, fullpath: nodes.fullpath });
  if (nodes.children) {
    const newChild = nodes.children.map((item) => ({
      ...item,
      fullpath: `${nodes.fullpath}${item.path}`
    }));
    return newChild.reduce(register, routes);
  }
  return routes;
};

const RouteReducer = (state, action) => {
  switch (action.type) {
    case RouteAction.register:
      return {
        ...state,
        routes: action.payload,
        paths: action.payload.reduce(register, []),
        sidebarRoutes: action.payload.reduce(registerSidebarRoutes, [])
      };
    case RouteAction.current:
      return { ...state, current: action.payload };
    case RouteAction.activeRoute:
      return { ...state, activeRoute: action.payload };
    case RouteAction.checkPermission:
      return state;
    default:
      return state;
  }
};

const useLayoutRoutes = (routes, path) => {
  const filtered = routes.find((prop) => prop.path.startsWith(`${path}/`));
  if (filtered) return filtered.children;
  return [];
};
const useCurrentRouteName = (routes, path) => {
  if (!routes || !path) return null;
  path += path.endsWith('/') ? '' : '/';
  const filtered = routes.find((prop) => prop.fullpath === path);
  if (filtered) return filtered.fullpath;
  return null;
};

const activeRoute = (routes, path) => {
  if (!routes || !path) return null;
  path += path.endsWith('/') ? '' : '/';
  const filtered = routes.find((prop) => prop.fullpath === path);
  if (filtered) return filtered.fullpath;
  return null;
};

export { InitialtRoute, RouteAction, useLayoutRoutes, useCurrentRouteName, activeRoute };
export default RouteReducer;

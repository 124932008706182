import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { PromoAssignedTable } from './assigned-table';

export const PromoAssignedLineItems = (props) => {
  const { promoAssigned } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Líneas" />
      <Divider />
      <PromoAssignedTable promoAssigned={promoAssigned} />
    </Card>
  );
};

PromoAssignedLineItems.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};

import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest, GadminFileUpload } from '../services/gadmin';
import { cachePages } from './app-definitions';
import { dataLoader } from './data-loader';

import { invoiceActions } from './purchase-definitions';

/* eslint class-methods-use-this:0 */

class InvoiceApi {
  async getInvoices(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;
    const { INVOICE_SERVICES: cacheItem } = cachePages;
    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.G2_PURCHASE_FFEE_SERVICE_LIST,
      cacheItem,
      apiParams: {
        type: invoiceActions.INVOICE_SERVICE_LIST,
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'docEntry' },
        { type: 'string', name: 'document' },
        { type: 'string', name: 'cardCode' },
        { type: 'string', name: 'cardName' }
      ],
      ...options
    });
  }

  async getInvoiceStatics() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_LIST, {
      type: 'static'
    });
    const { data } = response;
    return Promise.resolve(data);
  }

  async getInvoice({ objtype, docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_LIST, {
      type: invoiceActions.INVOICE_SERVICE_DETAIL,
      payload: { objtype, docEntry }
    });
    return response;
  }

  async uploadInvoice(files) {
    const response = await GadminFileUpload(
      GadminActions.G2_PURCHASE_FFEE_SERVICE_UP,
      {
        type: invoiceActions.INVOICE_SERVICE_UPLOAD,
        payload: files,
        ...files
      },
      invoiceActions.INVOICE_SERVICE_UPLOAD
    );
    return response;
  }

  async updateInvoice({ id, changes }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_UP, {
      type: invoiceActions.INVOICE_SERVICE_SAVE_HEADER,
      payload: { id, changes }
    });
    return response;
  }

  async updateInvoiceLine({ id, changes }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_UP, {
      type: invoiceActions.INVOICE_SERVICE_SAVE_LINE,
      payload: { id, changes }
    });
    return response;
  }

  async processInvoiceBulk(props) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_UP, {
      type: invoiceActions.INVOICE_SERVICE_PROCESS_BULK,
      payload: props
    });
    return response;
  }

  async processInvoice(props) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_UP, {
      type: invoiceActions.INVOICE_SERVICE_PROCESS,
      payload: props
    });
    return response;
  }

  async getInvoiceMedia({ type, docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_LIST, {
      type: 'media',
      payload: { type, docEntry }
    });
    return response;
  }

  async checkCPE({ docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_UP, {
      type: invoiceActions.INVOICE_CPE_CHECK,
      payload: { docEntry }
    });
    return response;
  }

  async getInvoiceJournal(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_LIST, {
      type: 'journal',
      payload
    });
    return response;
  }

  async getJournalData({ transId }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_SERVICE_LIST, {
      type: 'journal-data',
      payload: { transId }
    });
    return Promise.resolve(response.data);
  }
}

export const invoiceApi = new InvoiceApi();

import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Typography } from '@mui/material';
// import gtm from '../../lib/gtm';
import { useQuery } from '../../../hooks/use-query';
import { useApp } from '../../../hooks/use-app';
import { FEPendingsSAPTable } from '../../../components/accounting/fe/fe-pendings-sap-table';
import { financialReportApi } from '../../../api/financial-reports';
import { useMounted } from '../../../hooks/use-mounted';
import { useDownloadFile } from '../../../hooks/use-download-file';
import LoadingContent from '../../../components/common/loading-content';
import { FePendingsView } from '../../../api/financial-definitions';

export const FEPendingsFacele = () => {
  const query = useQuery();
  const mounted = useMounted();
  const queried = {
    date: query.get('date'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const app = useApp();
  const [controller, setController] = useState({ reload: true });
  const handleRefresh = () => {
    setController({ ...controller, reload: true });
  };
  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });

  const getDocument = useCallback(async () => {
    if (controller.reload) {
      setDocumentState(() => ({
        ...documentState,
        isLoading: true,
        message: 'Cargando pendings de envío a Facele'
      }));
      try {
        const result = await financialReportApi.getFEPendingsFaceleDetail(queried);
        if (mounted.current) {
          setDocumentState(() => ({
            ...result,
            isLoading: false,
            reload: false
          }));
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setDocumentState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);

  const { download } = useDownloadFile({
    apiDefinition: financialReportApi.getFEPendingsFaceleDetailXls,
    apiParameters: queried
  });

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <>
      <Helmet>
        <title>{app.title('Pendientes de envío a FACELE')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Pendientes a Facele
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </Box>
          <FEPendingsSAPTable
            data={documentState}
            onRefresh={handleRefresh}
            label="Pendientes de envío a Facele"
            onDownload={download}
            view={FePendingsView.VIEW_PENDING_FACELE}
          />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

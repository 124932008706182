import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import GadminActions from '../../../contexts/gadmin-actions';
import { useApp } from '../../../hooks/use-app';
import { useTabs } from '../../../hooks/use-tabs';

const defaultTabs = [
  {
    href: '',
    label: 'Resumen',
    permission: GadminActions.gCC_TDOY_SUMARY
  },
  {
    href: '/documents',
    label: 'Documentos',
    permission: GadminActions.gCC_TDOY_DET
  },
  {
    href: '/team',
    label: 'Equipo',
    permission: GadminActions.gCC_TDOY_TEAM_LIST
  },
  {
    href: '/sheets',
    label: 'Hojas',
    permission: GadminActions.gCC_TDOY_HR_LIST
  },
  {
    href: '/spenses',
    label: 'Gastos',
    permission: GadminActions.gCC_TDOY_EXPENSE_LIST
  },
  {
    href: '/note-credits',
    label: 'Nota de creditos',
    permission: GadminActions.gCC_TDOY_EXPENSE_LIST
  },
  {
    href: '/liquidation',
    label: 'Liquidacion',
    permission: GadminActions.gCC_TDOY_EXPENSE_LIST
  },
  {
    href: '/inventory',
    label: 'Mercaderia',
    permission: GadminActions.gCC_TDOY_EXPENSE_LIST
  },
  {
    href: '/invoice',
    label: 'Faltantes y sobrantes',
    permission: GadminActions.gCC_TDOY_EXPENSE_LIST
  },
  {
    href: '/pick-up',
    label: 'Recojos',
    permission: GadminActions.gCC_TDOY_EXPENSE_LIST
  }
];

export const DeliveryTab = () => {
  const app = useApp();
  const { id } = useParams();
  const { appUrlBase } = useApp();

  const { renderTabs } = useTabs({
    basePath: `${appUrlBase}/delivery/monitoring/${id}`,
    backPath: {
      path: `${appUrlBase}/delivery/monitoring`,
      label: 'volver (Seguimientos)'
    },
    tabs: defaultTabs
  });

  return (
    <>
      <Helmet>
        <title>{app.title('Información del Doc.')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderTabs()}
        </Container>
      </Box>
    </>
  );
};

import Proptypes from 'prop-types';
import { useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import { Icon } from '@iconify/react';
// import searchIcon from '@iconify/icons-eva/search-fill';
// import moment from 'moment';
// import numeral from 'numeral';
import {
  Box,
  Checkbox,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
  // Link
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { SurveyItemMenu } from '../../survey/survey-item-menu';
import { CustomerModal } from './customer-modal';
import GadminActions from '../../../contexts/gadmin-actions';
import ExpenseForm from './form/expense-form';
import { goalCustomeDeleveryMonitoringApi } from '../../../api/goal/monitoring/goal-customer-delivery-monitoring';
import { ConfirmationDialog } from '../../confirmation-dialog';
// import { YesNo } from '../../yes-no';
// import { Status } from '../../status';

const columns = [
  {
    id: 'codprog',
    label: 'id'
  },
  {
    id: 'type',
    label: 'Tipo de Gasto'
  },
  {
    id: 'descripcion',
    label: 'Descripción'
  },
  {
    id: 'despacho',
    label: 'Proveedor'
  },
  {
    id: 'vehiculo',
    label: 'Nombre'
  },
  {
    id: 'cardname',
    label: 'Fecha'
  },

  {
    id: 'encargado',
    label: 'Importe'
  },
  {
    id: 'cliente',
    label: 'Documento'
  },
  {
    id: 'action',
    label: ''
  }
];

export const CustomerDeliveryExpensiveTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSelect,
    onSelectAll,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate',
    handleRefresh
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);
  // const detailRoute = `${appUrlBase}/sales/receipts/';

  const [modalOpen, setModalOpen] = useState(false);
  const [open, onClose] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [itemData, setitemEdit] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');

  const handleOpenCornfirm = (document) => {
    setModalOpen(true);
    setIdDelete(document.id);
    setConfirmDialogMessage(
      `Estas seguro de eliminar este gasto con el proveedor de ${document?.cardName}`
    );
  };

  const handleClickDelete = async (id) => {
    await goalCustomeDeleveryMonitoringApi.deleteCustomeDeliveryExpenses(id);
    setModalOpen(false);
    handleRefresh();
  };

  const handleClickEditIcon = (item) => {
    setitemEdit(item);
    onClose(!open);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow
                hover
                key={document.id}
                selected={
                  !!selectedDocuments.find(
                    (selectedCustomer) => selectedCustomer === document.docEntry
                  )
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find(
                        (selectedCustomer) => selectedCustomer === document.id
                      )
                    }
                    onChange={(event) => onSelect(event, document.id)}
                  />
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.id}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.receiptType || '--'}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.description}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 140 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.licTradNum}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 140 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.cardName}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.created_at}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.docTotal}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.pdf_id || '--'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_EDIT}>
                    <SurveyItemMenu
                      items={document}
                      clickEdit={() => handleClickEditIcon(document)}
                      clickDelete={() => handleOpenCornfirm(document)}
                    />
                  </AllowedGuard>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={handleCloseModal}
        onConfirm={() => handleClickDelete(idDelete)}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
      <CustomerModal open={open} onClose={onClose} permission={GadminActions.gCC_TDOY_EXPENSE_EDIT}>
        <ExpenseForm
          onClose={onClose}
          initialValues={itemData}
          title="Editar Gasto"
          handleRefresh={handleRefresh}
          update
        />
      </CustomerModal>
    </Box>
  );
};

CustomerDeliveryExpensiveTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  handleRefresh: Proptypes.func
};

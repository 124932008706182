import { dataLoader } from '../../data-loader';
import axios from 'axios';

class GoalMediaApi {
  async submitImage({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/services/media-server/encoded',
      changes: values
    });
    return response;
  }

  // get image from url
  async getImage(url) {
    const service = axios.create({
      baseURL: url,
      timeout: 1000 * 120
    });
    const response = await service.get(url, { responseType: 'blob' });

    return response;
  }
}

export const goalMediaApi = new GoalMediaApi();

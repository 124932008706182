import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
// import { applyFilters } from '../utils/apply-filters';
// import { applyPagination } from '../utils/apply-pagination';
// import { applySort } from '../utils/apply-sort';
/* eslint class-methods-use-this:0 */

class PromoAssignedApi {
  async getPromoAssigned({ id }) {
    const response = await GadminRequest(GadminActions.gCC_PROMO_ASSIGNED_DETAIL, {
      type: 'detail',
      payload: { id }
    });
    return Promise.resolve(response.data);
  }
}

export const promoAssignedApi = new PromoAssignedApi();

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { useApp } from '../../hooks/use-app';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { useQuery } from '../../hooks/use-query';
import { useDataBrowser } from '../../hooks/use-data-browser';
import { Plus as PlusIcon } from '../../icons/plus';
import { Query } from '../../components/query';
import { ImportTable } from '../../components/importer/import-table';
import { CustomerModal } from '../../components/delivery/monitoring/customer-modal';
import { ImportForm } from '../../components/importer/import-form';
import { goalImporterApi } from '../../api/goal/importer/goal-importer-api';

export const ImportContainer = () => {
  const query = useQuery();
  const [openCreate, onCloseCreate] = useState(false);

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const { title, currentCompany } = useApp();

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    ,
    handlePageChange,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalImporterApi.getManagerTemplateList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all',
      id: currentCompany
    }
  });

  return (
    <>
      <Helmet>
        <title>{title('Importar')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Importar
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={() => onCloseCreate(!openCreate)}
                  variant="contained"
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Box sx={{ paddingX: 5 }}>
              <Query
                disabled={returnsState.isLoading}
                onChange={handleQueryChange}
                sx={{
                  marginTop: 2
                }}
                value={controller.query}
              />
            </Box>
            <ImportTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
            <Divider />
          </Card>
        </Container>
        <CustomerModal
          open={openCreate}
          onClose={onCloseCreate}
          permission={GadminActions.G2_CONTESTS_UP}
          widhtForm="lg"
        >
          <ImportForm title="Importar" onClose={onCloseCreate} handleRefresh={handleRefresh} />
        </CustomerModal>
      </Box>
    </>
  );
};

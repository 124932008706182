import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';

// import windowsFilled from '@iconify/icons-ant-design/windows-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { gProcApi } from '../../../api/gproc';
import LoadingContent from '../../common/loading-content';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.darker,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.darker, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function TimmingOrdersStat() {
  const [openProductsStatics, setOpenProductsStatics] = useState({
    all: 0
  });
  useEffect(() => {
    const loadOrderStatics = async () => {
      try {
        const stats = await gProcApi.getTimmingOrders();
        setOpenProductsStatics(stats);
      } catch (error) {
        console.error(error);
      }
    };
    loadOrderStatics();
  }, []);
  if (!openProductsStatics) return <LoadingContent />;

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={androidFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h5">{fShortenNumber(openProductsStatics.time)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        T Pedidos
      </Typography>
    </RootStyle>
  );
}

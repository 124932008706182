import Proptypes from 'prop-types';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
// import { InputField } from '../../input-field';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { AutocompleteField } from '../../autocomplete-field';

export const SalesTeamsChannelForm = ({ title, initialValues = {}, onClose }) => {
  const { currentCompany } = useApp();

  const formik = useFormik({
    initialValues: {
      //   name: initialValues.name || '',
      company_id: initialValues.company_id || currentCompany,
      sales_team_id: initialValues.sales_team_id || '',
      customer_channel_id: initialValues.customer_channel_id || '',
      customer_channels_info: initialValues.customer_channels_info || '',
      //   active: initialValues.active || 'Y',
      is_default: initialValues.is_default || 'N'
    },
    validationSchema: Yup.object().shape({
      customer_channel_id: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      console.log('values', values);
    }
  });

  const salesTeamsData = [
    {
      id: 1,
      name: 'Data 1',
      code: 'data1'
    },
    {
      id: 2,
      name: 'Data 2',
      code: 'data2'
    },
    {
      id: 3,
      name: 'Data 3',
      code: 'data3'
    }
  ];

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2,
            padding: 5
          }}
        >
          <Grid item xs={12}>
            <PropertyList>
              <AutocompleteField
                label="Canal"
                name="customer_channels_info"
                placeholder="Seleccione un canal"
                fullWidth
                options={salesTeamsData || [{ name: 'Cargando', id: 'Cargando' }]}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('customer_channel_id', value?.id);
                  formik.setFieldValue('customer_channels_info', value);
                }}
                value={formik.values.customer_channels_info}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.customer_channel_id && Boolean(formik.errors.customer_channel_id)
                }
                helperText={formik.touched.customer_channel_id && formik.errors.customer_channel_id}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.is_default === 'Y'}
                    value={formik.values.is_default}
                    onChange={(e) => {
                      const newValue = e.target.checked ? 'Y' : 'N';
                      formik.setFieldValue('is_default', newValue);
                    }}
                  />
                }
                label="Predeterminado"
              />
            </PropertyList>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<TrashIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

SalesTeamsChannelForm.propTypes = {
  initialValues: Proptypes.object,
  //   update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string
};

import { appDefinitions } from '../api/app-definitions';

export const applyPagination = (rows, page) => {
  if (page === null) {
    return rows;
  }

  const { pageSize } = appDefinitions;

  const paginated = rows.slice(page * pageSize, page * pageSize + pageSize);

  return paginated.length ? paginated : rows;
};

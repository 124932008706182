import { backendService } from '../../services/backend-service';
import getClientInformation from '../../services/client-info';

const goalLogin = async ({ token, ssid }) => {
  const clientInfo = getClientInformation();
  const response = backendService.post(`/oauth/token`, {
    grant_type: 'social',
    provider: 'keycloak',
    client_id: process.env.REACT_APP_API_BASE_CLIENT_ID,
    redirect: '/',
    client_secret: process.env.REACT_APP_API_BASE_CLIENT_SECRET,
    access_token: token,
    client_info: clientInfo,
    ssid
  });
  return response;
};

const goalLogout = async () => {
  const response = backendService.post(`/auth/authentication/logout`, {
    grant_type: 'social',
    provider: 'keycloak',
    client_id: process.env.REACT_APP_API_BASE_CLIENT_ID,
    redirect: '/',
    client_secret: process.env.REACT_APP_API_BASE_CLIENT_SECRET
  });
  return response;
};

const goalCurrentUser = async () => backendService.get(`/auth/users/current`);

export { goalLogin, goalCurrentUser, goalLogout };

import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import moment from 'moment';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Link
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';

import { useMounted } from '../../../hooks/use-mounted';
import { NumberColor } from '../../number-color';
import { RenderIf } from '../../render-if';
import { deliveryApi } from '../../../api/delivery';
import { HrMenu } from './hr-menu';
import { useApp } from '../../../hooks/use-app';

export const HRInvoicingTable = () => {
  const mounted = useMounted();
  const [documentLogState, setDocumentLogState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({ reload: true });
  const { appUrlBase } = useApp();

  const handleRefresh = () => {
    setController({ ...controller, reload: true });
  };

  const getDocument = useCallback(async () => {
    if (controller.reload) {
      setDocumentLogState(() => ({ ...documentLogState, isLoading: true }));
      try {
        const result = await deliveryApi.getHRInvoicing();
        if (mounted.current) {
          setDocumentLogState(() => ({
            isLoading: false,
            data: result,
            reload: false
          }));
        }
      } catch (err) {
        console.error(err);

        if (mounted.current) {
          setDocumentLogState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);

  if (!documentLogState.data) {
    return <LoadingContent loadingText="No hay hojas pendientes..." />;
  }
  return (
    <>
      <Card variant="outlined">
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <CardHeader title="Hojas de reparto facturando" />
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
                <Box sx={{ mb: 2 }}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<RefreshIcon />}
                    onClick={handleRefresh}
                    variant="contained"
                  />
                </Box>
              </AllowedGuard>
            </Box>
          </Box>
        </Box>

        <Divider />
        <Scrollbar>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                {/* <TableCell>#</TableCell> */}
                <TableCell>HR</TableCell>
                <TableCell>F Programación</TableCell>
                <TableCell>F Despacho</TableCell>
                <TableCell>Zona</TableCell>
                <TableCell>Placa</TableCell>
                <TableCell>Pendientes</TableCell>
                <TableCell>Facturados</TableCell>
                <TableCell>Documentos</TableCell>
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentLogState.data?.map((item) => (
                <TableRow key={item.HR}>
                  {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                  <TableCell>{item.HR}</TableCell>
                  <TableCell>{moment(item.fecprog).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{moment(item.fecdsp).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{item.sede}</TableCell>
                  <TableCell>{item.plaveh}</TableCell>
                  <TableCell>
                    <RenderIf
                      condition={item.pendings > 0}
                      no={<NumberColor value={item.pendings} minColor="success.main" format="0" />}
                    >
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`${appUrlBase}/${'delivery/invoicing/pendings/'}${item.HR}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        {item.pendings}
                      </Link>
                    </RenderIf>
                  </TableCell>

                  <TableCell>
                    <RenderIf
                      condition={item.invoiced > 0}
                      no={<NumberColor value={item.invoiced} minColor="success.main" format="0" />}
                    >
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`${appUrlBase}/${'delivery/invoicing/invoiced/'}${item.HR}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        {item.invoiced}
                      </Link>
                    </RenderIf>
                  </TableCell>
                  <TableCell>
                    <RenderIf
                      condition={item.documents > 0}
                      no={<NumberColor value={item.documents} minColor="success.main" format="0" />}
                    >
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`${appUrlBase}/${'delivery/invoicing/all/'}${item.HR}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        {item.documents}
                      </Link>
                    </RenderIf>
                  </TableCell>
                  <TableCell>{item.Estado}</TableCell>
                  <TableCell>
                    <HrMenu id={item.HR} vehicle={item.plaveh} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  );
};

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
// import moment from 'moment';

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'flex-end'
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold'
  },
  label: {
    width: 60
  }
});
const CustomerReturnNumber = ({ customerReturn }) => (
  <>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Número:</Text>
      <Text style={styles.invoiceDate}>{customerReturn.id}</Text>
    </View>
    {/* <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Fecha: </Text>
      <Text>{moment(customerReturn.registerDate).format('DD/MM/YYYY')}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Hora: </Text>
      <Text>{moment(customerReturn.registerDate).format('HH:mm:ss')}</Text>
    </View> */}
  </>
);

CustomerReturnNumber.propTypes = {
  customerReturn: PropTypes.object.isRequired
};
export default CustomerReturnNumber;

import { useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { RenderIf } from '../../render-if';
import LoadingContent from '../../common/loading-content';
import { BusinessPartnersSumaryDetail } from '../component/business-partners-summary-detail';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import { useApp } from '../../../hooks/use-app';
import { BusinessPartnersDetailForm } from '../component/business-partners-detail-form';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';

const BusinessPartnerSummary2 = ({ summaryState = {} }) => {
  const mounted = useMounted();
  const [editMode, setEditMode] = useState(false);
  const { currentCompany } = useApp();
  const [summaryIdState, handleRefresh] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getSummaryId,
    apiParameter: { companyId: currentCompany, id: summaryState.id },
    loadingMessage: 'Cargando detalle del resumen',
    mounted
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="zl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Resumen 2
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={() => handleRefresh({ companyId: currentCompany, id: summaryState.id })}
                variant="contained"
              />
            </AllowedGuard>
          </Box>
        </Box>
        <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
          {!summaryIdState.isLoading && summaryIdState.success ? (
            <RenderIf
              condition={editMode}
              no={
                <BusinessPartnersSumaryDetail
                  item={summaryIdState.data}
                  setEditMode={setEditMode}
                />
              }
            >
              <BusinessPartnersDetailForm
                update
                onClose={setEditMode}
                initialValues={summaryIdState.data}
                handleRefresh={() =>
                  handleRefresh({ companyId: currentCompany, id: summaryState.id })
                }
              />
            </RenderIf>
          ) : (
            <LoadingContent />
          )}
        </Card>
      </Container>
    </Box>
  );
};

export { BusinessPartnerSummary2 };

BusinessPartnerSummary2.propTypes = {
  summaryState: Proptypes.object
};

import { Helmet } from 'react-helmet-async';
import { Box, Card, Button, Container, Divider, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
// import gtm from '../../lib/gtm';
import { ArrowLeft as ArrowLeftIcon } from '../../../icons/arrow-left';
import { useApp } from '../../../hooks/use-app';
import { HRInvoicingDocumentsTable } from '../../../components/delivery/hr/hrs-open-documents-table';

export const HRInvoicingPendings = () => {
  const { id } = useParams();

  const { appUrlBase, ...app } = useApp();

  return (
    <>
      <Helmet>
        <title>{app.title('Pendientes de facturar')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box sx={{ mb: 2 }}>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to={`${appUrlBase}/delivery/invoicing/`}
                variant="text"
              >
                Hojas en proceso
              </Button>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Pendientes de facturar HR {id}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </Box>
          {/* <QueuesStats /> */}
          <HRInvoicingDocumentsTable id={id} type="pending" />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};

// import { useApp } from 'use-app';
import PropTypes from 'prop-types';
import { useAuth } from './use-auth';

const AllowedGuard = (props) => {
  const { children, permission, component, showWarning } = props;
  const noAccess = <div>No tienes acceso</div>;

  const { hasPermission } = useAuth();
  if (!hasPermission(permission)) {
    if (component) {
      return component;
    }
    if (showWarning) {
      return noAccess;
    }
    return null;
  }
  return children;
};

AllowedGuard.propTypes = {
  children: PropTypes.node,
  component: PropTypes.node,
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  showWarning: PropTypes.bool
};

export { AllowedGuard };

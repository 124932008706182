import { Button } from '@mui/material';
import propTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';

const CopyToClipboardButton = ({ text }) => (
  <CopyToClipboard text={text}>
    <Button variant="contained">Copiar</Button>
  </CopyToClipboard>
);

CopyToClipboardButton.propTypes = {
  text: propTypes.string.isRequired
};

export default CopyToClipboardButton;

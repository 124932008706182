import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import lastPageIcon from '@iconify/icons-eva/arrowhead-right-fill';
import firstPageIcon from '@iconify/icons-eva/arrowhead-left-fill';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
import { appDefinitions } from '../api/app-definitions';

const PaginationRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2)
}));

export const Pagination = (props) => {
  const {
    disabled = false,
    onPageChange,
    page = 1,
    pageSize = null,
    rowsCount = 1,
    ...other
  } = props;
  // NOTE: Usually, this should be received from the server
  const perPage = pageSize ?? appDefinitions.pageSize;
  const pagesCount = Math.ceil(rowsCount / perPage);
  const isFirstPage = page === 1;
  const isLastPage = page >= pagesCount;

  const handlePreviousPage = () => {
    onPageChange?.(page - 1);
  };

  const handleFirstPage = () => {
    onPageChange?.(1);
  };
  const handleLastPage = () => {
    onPageChange?.(pagesCount);
  };

  const handleNextPage = () => {
    onPageChange?.(page + 1);
  };

  return (
    <PaginationRoot {...other}>
      {pagesCount > 0 && (
        <Typography
          color="textSecondary"
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
          variant="body2"
          whiteSpace="nowrap"
        >
          Página{' '}
          <Typography color="textPrimary" component="span" sx={{ mx: 1 }} variant="subtitle2">
            {page}
          </Typography>
          de{' '}
          <Typography color="textPrimary" component="span" sx={{ ml: 1 }} variant="subtitle2">
            {pagesCount}
          </Typography>
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Typography color="textPrimary" component="span" sx={{ ml: 1 }} variant="subtitle2">
        Total filas {rowsCount}
      </Typography>

      <IconButton disabled={isFirstPage || disabled} onClick={handleFirstPage}>
        <Icon icon={firstPageIcon} width={20} height={20} />
      </IconButton>
      <IconButton disabled={isFirstPage || disabled} onClick={handlePreviousPage}>
        <ChevronLeftIcon />
      </IconButton>
      <IconButton disabled={isLastPage || disabled} onClick={handleNextPage}>
        <ChevronRightIcon />
      </IconButton>
      <IconButton disabled={isLastPage || disabled} onClick={handleLastPage}>
        <Icon icon={lastPageIcon} width={20} height={20} />
      </IconButton>
    </PaginationRoot>
  );
};

Pagination.propTypes = {
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  rowsCount: PropTypes.number
};

import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';

/* eslint class-methods-use-this:0 */

class CustomerApi {
  async getCustomerDetail(payload) {
    const response = await GadminRequest(GadminActions.gCC_PROC_CLIENT_EDIT, {
      type: 'detail',
      payload
    });
    return response;
  }

  async getCustomerSumary(payload) {
    const response = await GadminRequest(GadminActions.gCC_PROC_CLIENT_EDIT, {
      type: 'sumary',
      payload
    });
    return response;
  }

  async getCustomerSalesOrders(payload) {
    const response = await GadminRequest(GadminActions.gCC_PROC_CLIENT_EDIT, {
      type: 'sales-orders',
      payload
    });
    return response;
  }

  async getCustomerProductPurchases(payload) {
    const response = await GadminRequest(GadminActions.gCC_PROC_CLIENT_EDIT, {
      type: 'product-purchases',
      payload
    });
    return response;
  }

  // async getFEPendingsFaceleDetailXls(payload) {
  //   const response = await GadminRequest(GadminActions.gCC_FACELE_SEND, {
  //     type: 'pending-facele-xls',
  //     payload
  //   });
  //   return response;
  // }
}

export const customerApi = new CustomerApi();

import { invoiceActions } from './purchase-definitions';
import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest, GadminFileUpload } from '../services/gadmin';
import { applyFilters } from '../utils/apply-filters';
import { applyPagination } from '../utils/apply-pagination';
import { applySort } from '../utils/apply-sort';
import { cacheEnabled, cachePages } from './app-definitions';

/* eslint class-methods-use-this:0 */

class InvoiceItemApi {
  async getInvoices(options) {
    const { filters, sort, sortBy, page, query, view, reload, apiData } = options;

    const { INVOICE_ITEMS: cacheItem } = cachePages;

    let returnsData = null;
    if (reload) {
      if (cacheEnabled) {
        returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
      }

      if (!returnsData) {
        const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
          type: invoiceActions.INVOICE_ITEMS_LIST,
          payload: { filters, sort, sortBy, page, query, view }
        });
        const { data } = response;
        returnsData = data;
        if (cacheEnabled) {
          localStorage.setItem(cacheItem, JSON.stringify(returnsData));
        }
      }
    } else {
      returnsData = apiData;
    }

    if (!returnsData || !Array.isArray(returnsData))
      return {
        documents: [],
        documentsCount: 0
      };

    const queriedInvoices = returnsData.filter((_invoice) => {
      // If query exists, it looks only in customer id field
      if (
        !!query &&
        !(
          _invoice.docEntry?.toString().toLowerCase().includes(query.toLowerCase()) ||
          _invoice.document?.toLowerCase().includes(query.toLowerCase())
        )
      ) {
        return false;
      }

      // No need to look for any resource fields
      if (typeof view === 'undefined' || view === 'all') {
        return true;
      }
      if (view === 'no-whscode') {
        return !_invoice.whscode;
      }

      // In this case, the view represents the resource status
      return _invoice.status === view;
    });
    const filteredInvoices = applyFilters(queriedInvoices, filters);
    const sortedInvoices = applySort(filteredInvoices, sort, sortBy);
    const paginatedInvoices = applyPagination(sortedInvoices, page);

    return {
      documents: paginatedInvoices,
      documentsCount: filteredInvoices.length,
      apiData: returnsData
    };
  }

  async getInvoiceStatics() {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
      type: 'static'
    });
    const { data } = response;
    return Promise.resolve(data);
  }

  async getInvoice({ objtype, docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
      type: invoiceActions.INVOICE_ITEMS_DETAIL,
      payload: { objtype, docEntry }
    });
    return response;
  }

  async uploadInvoice(files) {
    const response = await GadminFileUpload(GadminActions.G2_PURCHASE_FFEE_ITEM_UP, {
      type: 'load',
      payload: files,
      ...files
    });
    return response;
  }

  async updateInvoice({ id, changes }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_UP, {
      type: 'save-header',
      payload: { id, changes }
    });
    return response;
  }

  async processInvoiceBulk({ action, items }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
      type: 'process',
      payload: { action, items }
    });
    return response;
  }

  async processInvoice({ action, items }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
      type: 'process',
      payload: { action, items }
    });
    return response;
  }

  async getInvoiceMedia({ type, docEntry }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
      type: 'media',
      payload: { type, docEntry }
    });
    return response;
  }

  async getInvoiceJournal(payload) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
      type: 'journal',
      payload
    });
    return response;
  }

  async getJournalData({ transId }) {
    const response = await GadminRequest(GadminActions.G2_PURCHASE_FFEE_ITEM_LIST, {
      type: 'journal-data',
      payload: { transId }
    });
    return response;
  }
}

export const invoiceItemApi = new InvoiceItemApi();

import { useParams } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { useSplit } from '../../../hooks/use-split';
import { BusinessPartnersDocumentsTable } from '../component/business-partners-documents-table';

const BusinessPartnerDocuments = () => {
  const { cardcode: cardcodeQuery } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { code: cardcode } = useSplit(cardcodeQuery);
  const [documentState, handleRefresh] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getSummaryId,
    apiParameter: { companyId: currentCompany, id: cardcode },
    loadingMessage: 'Cargando detalle del resumen',
    mounted
  });
  const data = [
    {
      id: 1,
      type: 'VEN',
      number: '66933038',
      date: '2023-07-13',
      serie: 'B033-03',
      nSunat: '00733038',
      domi: '001',
      status: 'O',
      status2: 'O',
      discount: '0.00',
      total: 52.93,
      balance: 52.93,
      imp: 8.07,
      hr: 150063,
      condition: 'Contado',
      venc: '2023-07-13',
      ref: 51561173,
      table: '008',
      channel: '11NZ01'
    },
    {
      id: 2,
      type: 'VEN',
      number: '66933038',
      date: '2023-07-13',
      serie: 'B033-03',
      nSunat: '00733038',
      domi: '002',
      status: 'O',
      status2: 'O',
      discount: '0.00',
      total: 52.93,
      balance: 52.93,
      imp: 8.07,
      hr: 150063,
      condition: 'Contado',
      venc: '2023-07-13',
      ref: 51561173,
      table: '008',
      channel: '11NZ01'
    }
  ];

  return (
    <BusinessPartnersDocumentsTable
      documentState={documentState}
      onRefresh={() => handleRefresh({ companyId: currentCompany, id: cardcode })}
      documents={data}
    />
  );
};

export { BusinessPartnerDocuments };

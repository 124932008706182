import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogTitle,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useData } from '../../../../hooks/use-data';
import { useMounted } from '../../../../hooks/use-mounted';
import { customeDeleveryMonitoringApi } from '../../../../api/customer-delivery-monitoring';
import LoadingContent from '../../../common/loading-content';
import { useApp } from '../../../../hooks/use-app';

const UnassignForm = ({ initialValues, title, dataModal, onClose, handleRefresh }) => {
  const mounted = useMounted();
  useEffect(() => {
    handleRefreshData();
  }, []);

  const { showError } = useApp();

  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      reasonId: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      // Lógica de envío de datos
      const response = await customeDeleveryMonitoringApi.saveUnassign({
        id: dataModal.dispatchId,
        teamId: dataModal.id,
        reasonId: values.reasonId
      });

      if (!response.success) {
        console.log(response);
        seterror(true);
        seterrorMessage(response.message);
        showError(response.message);
        return;
      }
      handleRefresh();
      onClose(false);
      console.log(response);
      console.log(values);
    }
  });

  const [reasonState, handleRefreshData] = useData({
    sourceApi: customeDeleveryMonitoringApi.getReason,
    apiParameter: {
      id: dataModal.dispatchId,
      teamId: dataModal.id,
      action: 'get-reasons'
    },
    defaults: {
      noAutoLoad: true
    },
    loadingMessage: 'Cargando informacion de los motivos',
    mounted
  });

  if (reasonState.isLoading || reasonState.displayError) {
    return (
      <LoadingContent
        style={{ padding: '20px' }}
        loadingText={reasonState.message}
        error={reasonState.displayError}
      />
    );
  }

  if (!reasonState.success && !reasonState.isLoading) {
    return (
      <LoadingContent
        style={{ padding: '20px' }}
        loadingText={reasonState.message}
        error={reasonState.displayError}
      />
    );
  }

  return (
    <>
      <Box sx={{ minWidth: '500px' }}>
        <form
          onSubmit={async (values) => {
            await formik.handleSubmit(values);
          }}
          style={{ padding: '20px' }}
        >
          <DialogTitle>{title}</DialogTitle>
          <Autocomplete
            id="name"
            name="names"
            options={reasonState?.data}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              formik.setFieldValue('reasonId', value ? value.id : '');
            }}
            value={reasonState?.data.find((option) => option.name === formik.values.name) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                name="name"
                label="Motivo"
                variant="outlined"
                margin="normal"
                error={formik.touched.reasonId && Boolean(formik.errors.reasonId)}
                helperText={formik.touched.reasonId && formik.errors.reasonId}
                onBlur={formik.handleBlur}
              />
            )}
          />
          <DialogActions>
            <Button onClick={() => onClose(false)}>Cancelar</Button>
            <Button type="submit" color="primary">
              {title}
            </Button>
          </DialogActions>
        </form>
      </Box>
      {error && <Alert severity="error">{errorMessage}</Alert>}
    </>
  );
};

export default UnassignForm;

UnassignForm.propTypes = {
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  title: PropTypes.string,
  dataModal: PropTypes.object,
  handleRefresh: PropTypes.func
};

import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalCustomerVehicleApi {
  async updateVehicleForm({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/delivery/master/vehicles/${id}`,
      changes
    });
    console.log(changes);
    return response;
  }

  async getVehicleWorkOrders(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,
      id,
      mainFilter
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    // console.log(id);

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/delivery/master/vehicles/${id}/work-orders`,
      cacheItem: 'vehicle-work-orders',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        orderDate: mainFilter?.orderDate,
        view,
        plate: id
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'work_vendor' },
        { type: 'string', name: 'work_reference' },
        { type: 'string', name: 'work_created' }
      ],
      ...options
    });
  }
  // get work order pdf
  async getWorkOrderPdf({ vehiclePlate, workOrderNumber }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/delivery/master/vehicles/${vehiclePlate}/work-orders/${workOrderNumber}/pdf`
    });

    return response;
  }
}

export const goalCustomeVehicleApi = new GoalCustomerVehicleApi();

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 0,
    alignItems: 'center',
    height: 24,
    // fontSize: 12,
    fontStyle: 'bold'
  },
  description: {
    width: '25%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 0,
    paddingRight: 8
  },
  total: {
    width: '25%',
    textAlign: 'center',
    paddingRight: 8
  }
});

const CustomerReturnTableFooter = ({ lines }) => {
  const gTotal = lines
    .map((item) => item.gTotal)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const units = lines
    .map((item) => item.quantity)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.description}>Unidades</Text>
        <Text style={styles.total}>{Number.parseFloat(units).toFixed(2)}</Text>
        <Text style={styles.description}>Importe</Text>
        <Text style={styles.total}>{Number.parseFloat(gTotal).toFixed(2)}</Text>
      </View>
    </>
  );
};

CustomerReturnTableFooter.propTypes = {
  lines: PropTypes.array
};
export default CustomerReturnTableFooter;

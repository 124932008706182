import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useApp } from '../../../hooks/use-app';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { RenderIf } from '../../render-if';
import { BusinessPartnerAddressesForm } from './business-partners-addresses-form';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../common/loading-content';
import BusinesPartnerAddressDetail from './business-partners-address-detail';

export const BusinessPartnerAddressesDetail = ({
  setItemEditDetail,
  ubigeoState = [],
  setWidhtFormState,
  itemData
}) => {
  const { currentCompany } = useApp();
  const [editMode, setEditMode] = useState(false);

  const mounted = useMounted();

  const [documentState, handleRefresh] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getAddressesList,
    apiParameter: { companyId: currentCompany, id: itemData },
    loadingMessage: 'Cargando informacion de direcciones',
    mounted
  });

  return (
    <Box>
      {!documentState.isLoading && documentState.success ? (
        <RenderIf
          condition={editMode}
          no={
            <BusinesPartnerAddressDetail
              item={documentState.data}
              setEditMode={setEditMode}
              setItemEditDetail={setItemEditDetail}
            />
          }
        >
          <BusinessPartnerAddressesForm
            update
            onClose={setEditMode}
            handleRefresh={handleRefresh}
            title=""
            initialValues={documentState.data}
            ubigeoState={ubigeoState}
            setWidhtFormState={setWidhtFormState}
          />
        </RenderIf>
      ) : (
        <LoadingContent />
      )}
    </Box>
  );
};

BusinessPartnerAddressesDetail.propTypes = {
  setItemEditDetail: PropTypes.func,
  setWidhtFormState: PropTypes.func,
  ubigeoState: PropTypes.array,
  itemData: PropTypes.number
};

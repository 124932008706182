import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'column',
    flexFlow: 'row wrap',
    marginTop: 2
  },
  companyContainer: {
    flexDirection: 'row',
    marginTop: 20
  },
  reportCompany: {
    color: '#61dafb',
    letterSpacing: 4,
    fontSize: 12,
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  reportTitle: {
    color: '#61dafb',
    letterSpacing: 4,
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
});
const JournalTitle = ({ title }) => (
  <>
    <View style={styles.titleContainer}>
      <Text style={styles.reportCompany}>DISTRIBUCIONES Y SERVICIOS ROMA SAC</Text>
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>{title}</Text>
    </View>
  </>
);

JournalTitle.propTypes = {
  title: PropTypes.string
};

export default JournalTitle;

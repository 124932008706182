import { useParams } from 'react-router-dom';
import { useApp } from '../../../hooks/use-app';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { useSplit } from '../../../hooks/use-split';
import { BusinessPartnersDivisionsTable } from '../component/business-partners-divisions-table';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';

const BusinessPartnerDivisions = () => {
  const { cardcode: cardcodeQuery } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { code: cardcode } = useSplit(cardcodeQuery);
  // const [documentState, handleRefresh] = useData({
  //   sourceApi: customerApi.getCustomerSalesOrders,
  //   apiParameter: { cardcode },
  //   loadingMessage: 'Cargando informacion del Socio de negocio',
  //   mounted
  // });
  const [documentState, handleRefresh] = useData({
    sourceApi: goalBaseBusinessPartnersApi.getSummaryId,
    apiParameter: { companyId: currentCompany, id: cardcode },
    loadingMessage: 'Cargando detalle del resumen',
    mounted
  });
  const data = [
    {
      id: 1,
      client: 'AGUILAR ALVARO, MIGUEL INOCENC',
      divisions: 'Helados',
      channel: 'MINORISTA',
      start: '',
      end: '',
      status: 'Y'
    },
    {
      id: 2,
      client: 'AGUILAR ALVARO, MIGUEL INOCENC',
      divisions: 'Tradicional',
      channel: 'MINORISTA',
      start: '',
      end: '',
      status: 'N'
    }
  ];

  return (
    <BusinessPartnersDivisionsTable
      documentState={documentState}
      onRefresh={() => handleRefresh({ companyId: currentCompany, id: cardcode })}
      documents={data}
    />
  );
};

export { BusinessPartnerDivisions };

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../../Scrollbar';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { useApp } from '../../../hooks/use-app';
import LoadingContent from '../../common/loading-content';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import { Pagination } from '../../pagination';
import { useMounted } from '../../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import gtm from '../../../lib/gtm';

const columns = [
  { id: 'id', label: 'Id.' },
  { id: 'full_name', label: 'Nombre completo' }
];

const BusinessPartnersForSelect = ({ handleClose, handleSelect }) => {
  const mounted = useMounted();
  const { currentCompany } = useApp();

  const [dataState, handleRefresh, , , handlePageChange, , , , controller] = useDataBrowser({
    sourceApi: goalBaseBusinessPartnersApi.getBussinesPartnersForSelect,
    loadingMessage: 'Cargando socios de negocio...',
    mounted,
    defaults: {
      companyId: currentCompany
    }
  });

  const displayLoading = dataState.isLoading;
  const displayError = Boolean(!dataState.isLoading && dataState.error);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => {
    if (dataState.isLoading || dataState.displayError) {
      return <LoadingContent loadingText={dataState.message} error={dataState.displayError} />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <Scrollbar>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={`business-partner-column-${column.id}`}>{column.label}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataState.documents.map((item) => {
                return (
                  <TableRow hover key={`item-business-partner-${item.id}`}>
                    <TableCell>
                      <Typography color="textSecondary" variant="body2">
                        {item.id}
                      </Typography>
                    </TableCell>
                    <TableCell>{item.full_name}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleSelect(item)}>Seleccionar</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
        {displayLoading && (
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        )}
        {displayError && (
          <ResourceError
            error={dataState.error}
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        {dataState.displayUnavailable && (
          <ResourceUnavailable
            sx={{
              flexGrow: 1,
              m: 2
            }}
          />
        )}
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={dataState.isLoading}
          onPageChange={handlePageChange}
          page={controller.page + 1}
          rowsCount={dataState.documentsCount}
        />
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Box sx={{ position: 'fixed', padding: '0px 10px' }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Selecciona el socio de negocio
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
                sx={{ marginRight: '0.5rem' }}
              >
                Refrescar
              </Button>
            </Box>
          </Box>
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

BusinessPartnersForSelect.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default BusinessPartnersForSelect;

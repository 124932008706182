import { appDefinitions } from '../../app-definitions';
import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalInventory {
  async getProductList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/products?companyId=${companyId}`
    });
    return response;
  }

  async getSearchProduct({
    companyId,
    query = [],
    notIn = [],
    queryText = '',
    perPage = appDefinitions.pageSize,
    page = 1
  }) {
    const queryString = JSON.stringify(query);
    const notInString = JSON.stringify(notIn);
    const params = `query=${queryString}&notIn=${notInString}&queryText=${queryText}&limit=${perPage}&page=${page}`;

    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/products/search?companyId=${companyId}&${params}`
    });
    return response;
  }

  async getProductListId({ companyId, id }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/products/${id}?companyId=${companyId}`
    });
    return response;
  }

  async postProductForm({ values, companyId }) {
    const response = await dataLoader.loadGoalPost({
      action: `/inventory/product/products?companyId=${companyId}`,
      changes: values
    });
    return response;
  }

  async updateProductForm({ id, values }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/inventory/product/products/${id}`,
      changes: values
    });
    return response;
  }

  async getSuppliersResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/suppliers?companyId=${companyId}`
    });
    return response;
  }

  async getBrandsResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/brands?companyId=${companyId}`
    });
    return response;
  }

  async getCategoriesResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/categories?companyId=${companyId}`
    });
    return response;
  }

  async getUnitsResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/units?companyId=${companyId}`
    });
    return response;
  }

  async getAttributesResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/attribute-groups?companyId=${companyId}`
    });
    return response;
  }

  async getDivisionsResources(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/divisions?companyId=${companyId}`
    });
    return response;
  }

  async getLabelsResources() {
    const response = await dataLoader.loadGoalGetData({
      action: `/inventory/product/product-resources/labels`
    });
    return response;
  }
}

export const goalInventoryApi = new GoalInventory();

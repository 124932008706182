import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class DespatchAdvice {
  async getFacelePendingsSummary({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/electronic-documents/despatch-advices/pendigs-facele-summary?companyId=${companyId}`
    });
    return response;
  }

  async getFacelePendingsList({ companyId, serie, type, date }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/electronic-documents/despatch-advices/pendigs-facele-list?companyId=${companyId}&serie=${serie}&type=${type}&date=${date}`
    });
    return response;
  }
  async getFaceleAllList({ companyId, serie, type, date }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/electronic-documents/despatch-advices/?companyId=${companyId}&serie=${serie}&type=${type}&date=${date}`
    });
    return response;
  }
}

export const despatchAdvice = new DespatchAdvice();

import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalMaintenanceBase {
  async getDivisions(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/base/master/divisions`,
      cacheItem: 'divisions',
      apiParams: {
        companyId,
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        view,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  async getDivisionsList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `base/master/divisions?companyId=${companyId}`
    });
    return response;
  }

  async getDivisionsContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `base/master/divisions/${id}/divisions?companyId=${companyId}`
    });
    return response;
  }

  async postDivision({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: `/base/master/divisions`,
      changes: values
    });
    return response;
  }

  async updateDivision({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/base/master/divisions/${id}`,
      changes
    });
    return response;
  }

  async getDivisionsConfig(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/master/divisions/config?companyId=${companyId}`
    });
    return response;
  }

  async deleteDivisions({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/base/master/divisions/${id}?companyId=${companyId}`
    });
    return response;
  }

  async restoreDivisions({ id, companyId }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/base/master/divisions/${id}/restore`,
      changes: { company_id: companyId }
    });
    return response;
  }

  // ******************
  async getBranchsApi(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, id } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/base/master/branchs?companyId=${id}`,
      cacheItem: 'branchs',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        view,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  async getBranchsList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `base/master/branchs?companyId=${companyId}`
    });
    return response;
  }

  async getBranchsContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `base/master/branchs/${id}/branchs?companyId=${companyId}`
    });
    return response;
  }

  async postBranchsForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/base/master/branchs',
      changes: values
    });
    return response;
  }

  async updateBranchsForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/base/master/branchs/${id}`,
      changes: data
    });
    return response;
  }

  async getBranchsConfig(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/base/master/branchs/config?companyId=${companyId}`
    });
    return response;
  }

  async getPaymentTerms(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `base/master/payment-terms`,
      cacheItem: 'payment-terms',
      apiParams: {
        companyId,
        type: 'list-payment-terms',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async getPaymentTerm({ id, companyId, view }) {
    const viewParam = view ? `&view=${view}` : '';
    const response = await dataLoader.loadGoalGetData({
      action: `base/master/payment-terms/${id}?companyId=${companyId}${viewParam}`
    });
    return response;
  }

  async createPaymentTerm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/base/master/payment-terms',
      changes: values
    });
    return response;
  }

  async updatePaymentTerm(prop) {
    const { id, changes } = prop;
    const response = await dataLoader.loadGoalUpdate({
      action: `/base/master/payment-terms/${id}`,
      changes
    });
    return response;
  }

  async restorePaymentTerm({ id, companyId }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/base/master/payment-terms/${id}/restore`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async deletePaymentTerm({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/base/master/payment-terms/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getPaymentTermGroupsAutocomplete({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `base/common/payment-term-groups/autocomplete?companyId=${companyId}`
    });
    return response;
  }
}

export const goalMaintenanceBaseApi = new GoalMaintenanceBase();

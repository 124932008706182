import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Grid, Typography } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
// import { customeDeleveryMonitoringApi } from '../../../api/customer-delivery-monitoring';
import { CustomerDeliveryMonitoringTable } from '../../../components/delivery/monitoring/customer-delivery-monitoring-table';
import { CustomerDeliveryMonitoringFilter } from '../../../components/delivery/monitoring/customer-delivery-monitoring-filter';
import { goalCustomeDeleveryMonitoringApi } from '../../../api/goal/monitoring/goal-customer-delivery-monitoring';
import { ConfirmationButton } from '../../../components/common/confirmation-button';
import useProcesor from '../../../hooks/use-procesor';
import { CustomerDeliveryMap } from '../../../components/delivery/monitoring/customer-delivery-map';

export const DeliveryMonitoring = () => {
  const query = useQuery();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const { title } = useApp();

  // const channel = pusher.subscribe('my-channel');
  // console.log('channel', channel);

  const [currentDate, setCurrentDate] = useState(queried.date ? moment(queried.date) : new Date());
  const handleChangeDate = (newValue) => {
    setCurrentDate(newValue);
  };

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const handleDateChanged = (newValue) => {
    if (!newValue) return;
    setCurrentDate(newValue);
    const current = moment(newValue).format('YYYY-MM-DD');
    if (current) {
      handleSetController.setMainFilter({ ...queried, orderDate: current });
    }
  };

  const { procesor: handleRefreshDates, loading: refreshingDates } = useProcesor({
    handler: goalCustomeDeleveryMonitoringApi.refreshDispatchDates
  });

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomeDeleveryMonitoringApi.getCustomeDeliveryMonitoring,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
    // onPostRefresh: handleClearSelected
  });

  // console.log(returnsState);

  const [selectedDocuments, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    returnsState.documents
  );

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    handleClearSelected();
  }, [returnsState.documents]);

  const [locationState, setLocationState] = useState({
    y: -74.9372173,
    x: -14.8237172
  });

  const data = [
    {
      sheetId: 147658,
      paymentGroup: -1,
      documentStatus: 'C',
      liquidationStatus: 0,
      docTotal: '171.69',
      sumApply: '171.69',
      sumReturn: '.00',
      cardCode: 'CL00938028',
      number: 'F032-01-00160034',
      partial: 'NO',
      cardName: 'YOLO TIENDA EXPRESS SOCIEDAD ANONIMA CERRADA-CL00938028',
      paymentGroupName: 'Contado',
      liquidationStatusName: 'Completo',
      documentStatusName: 'Cerrado',
      docentry: 9173907,
      objtype: 13,
      refX: '-74.93721730000000',
      refY: '-14.82371720000000'
    },
    {
      sheetId: 147658,
      paymentGroup: -1,
      documentStatus: 'C',
      liquidationStatus: 0,
      docTotal: '747.66',
      sumApply: '747.66',
      sumReturn: '.00',
      cardCode: 'CL00941970',
      number: 'F032-01-00160033',
      partial: 'NO',
      cardName: 'YU JING S.R.L.-CL00941970',
      paymentGroupName: 'Contado',
      liquidationStatusName: 'Completo',
      documentStatusName: 'Cerrado',
      docentry: 9173906,
      objtype: 13,
      refX: '-74.93777870000000',
      refY: '-14.82638120000000'
    },
    {
      sheetId: 147658,
      paymentGroup: -1,
      documentStatus: 'C',
      liquidationStatus: 0,
      docTotal: '112.96',
      sumApply: '112.96',
      sumReturn: '.00',
      cardCode: 'CL00942806',
      number: 'B033-03-00668725',
      partial: 'NO',
      cardName: 'VARGAS CARRASCO, LUCERO-CL00942806',
      paymentGroupName: 'Contado',
      liquidationStatusName: 'Completo',
      documentStatusName: 'Cerrado',
      docentry: 9173905,
      objtype: 13,
      refX: '-74.93890401243408',
      refY: '-14.82809599104552'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title('Monitoreo del cliente')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Seguimiento
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={currentDate}
                  onChange={handleChangeDate}
                  onAccept={handleDateChanged}
                  slotProps={{ textField: { variant: 'outlined' } }}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_HR_DESPACH_DATE}>
                <ConfirmationButton
                  icon={<RefreshIcon />}
                  label="Actualizar fechas"
                  title="Actualizar fechas de despacho"
                  onConfirm={handleRefreshDates}
                  message="Se proce a actualizar  las fechas de despacho de las hojas de reparto segun la fecha final de entrega"
                  loading={refreshingDates}
                />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </AllowedGuard>
            </Box>
          </Box>
          {/* <CustomerOrdersStats refresh={returnsState.isLoading} /> */}
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Grid
              container
              spacing={8}
              sx={{
                marginBottom: 2,
                padding: 5
              }}
            >
              <Grid item xs={12} md={5}>
                <CustomerDeliveryMonitoringFilter
                  disabled={returnsState.isLoading}
                  filters={controller.filters}
                  onFiltersApply={handleFiltersApply}
                  onFiltersClear={handleFiltersClear}
                  onQueryChange={handleQueryChange}
                  onViewChange={handleViewChange}
                  query={controller.query}
                  selectedDocuments={selectedDocuments}
                  view={controller.view}
                  onRefresh={handleRefresh}
                />
                <Divider />
                <CustomerDeliveryMonitoringTable
                  error={returnsState.error}
                  documents={returnsState.documents}
                  documentsCount={returnsState.documentsCount}
                  isLoading={returnsState.isLoading}
                  onPageChange={handlePageChange}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onSortChange={handleSortChange}
                  page={controller.page + 1}
                  selectedDocuments={selectedDocuments}
                  sort={controller.sort}
                  sortBy={controller.sortBy}
                  setLocationState={setLocationState}
                />
                <Divider />
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomerDeliveryMap
                  locationState={locationState}
                  documentsProp={data}
                  zoomMap={40}
                />
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </>
  );
};

import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import LinearProgressWithLabel from './LinearProgressWithLabel';

export default function ProgressIndicator({
  labelLeft,
  labelRight,
  valueLeft,
  valueRight,
  value,
  color
}) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography color="textSecondary" variant="body2">
          {labelLeft}
        </Typography>

        <Typography color="textSecondary" variant="body2">
          {labelRight}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <LinearProgressWithLabel value={value} color={color} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography color="textSecondary" variant="body2">
          {valueLeft}
        </Typography>

        <Typography color="textSecondary" variant="body2">
          {valueRight}
        </Typography>
      </Box>
    </>
  );
}

ProgressIndicator.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  valueLeft: PropTypes.string.isRequired,
  valueRight: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string
};

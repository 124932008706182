import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../icons/exclamation-outlined';
import { Cloud as CloudIcon } from '../../icons/cloud';

const LoadingContent = (props) => {
  const { loadingText = 'Cargando', error } = props;

  return (
    <Box sx={{ py: 4 }}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          p: 3
        }}
      >
        {error ? <ExclamationOutlinedIcon /> : <CloudIcon />}
        <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
          {loadingText || 'Cargando...'}
        </Typography>
        <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
          {error && 'Error al cargar los datos'}
        </Typography>
      </Box>
    </Box>
  );
};
LoadingContent.propTypes = {
  loadingText: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default LoadingContent;

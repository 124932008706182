import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import { invoiceApi } from '../../api/invoice';
import { InvoiceDetails } from '../../components/purchases/invoices-service/invoice-details';
import { InvoiceLineItems } from '../../components/purchases/invoices-service/invoice-line-items';

import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import { ArrowLeft as ArrowLeftIcon } from '../../icons/arrow-left';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { InvoiceEdit } from '../../components/purchases/invoices-service/invoice-edit';
import { DocumentLog } from '../../components/purchases/common/document-log';
import { useDialog } from '../../hooks/use-dialog';
import { documnetStatus, purchaseView } from '../../api/purchase-definitions';
import GadminActions from '../../contexts/gadmin-actions';
import { useData } from '../../hooks/use-data';
import { RenderIf } from '../../components/render-if';
import LoadingContent from '../../components/common/loading-content';
import { DocumentLineDetailDialog } from '../../components/purchases/common/document-line-detail-dialog';
import { DocumentLineEdit } from '../../components/purchases/invoices-service/invoice-line-edit';
import { useAuth } from '../../hooks/use-auth';
import { InvoiceMenu } from '../../components/purchases/common/invoice-menu';

export const Invoice = () => {
  const { id } = useParams();
  const mounted = useMounted();
  const { title, appUrlBase } = useApp();
  const [editMode, setEditmode] = useState(false);
  const [currentLine, setCurrentLine] = useState({});
  const [detailDialogOpen, handleOpenDetailDialog, handleCloseDetailDialog] = useDialog();

  const { hasPermission } = useAuth();

  const [invoiceState, handleRefresh] = useData({
    sourceApi: invoiceApi.getInvoice,
    apiParameter: { docEntry: id },
    loadingMessage: 'Cargando detalle del documento',
    mounted
  });
  const handleEdit = () => {
    if (editMode) {
      setEditmode(false);
      return;
    }
    if (!invoiceState.data) return;
    if ([documnetStatus.Borrador].includes(invoiceState.data.status)) {
      setEditmode(true);
    }
  };

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setEditable(
      invoiceState.data?.status === documnetStatus.Borrador &&
        hasPermission(GadminActions.gCC_PURCHASE_FFEE_CAB_UP)
    );
  }, [invoiceState.data]);

  const handleExitedDetailDialog = () => {
    // if (selectedVariant) {
    //   setSelectedVariant(null);
    // }
  };
  /*
   const { isNote } = result;
        if (isNote) {
          setIsNote(isNote === 'Y');
        } */

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleRowSelect = (item) => {
    setCurrentLine(item);
    handleOpenDetailDialog(true);
  };

  const renderContent = () => {
    if (invoiceState.isLoading || invoiceState.error) {
      return <LoadingContent loadingText={invoiceState.message} error={invoiceState.error} />;
    }
    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box sx={{ mb: 1 }}>
            <Button
              color="primary"
              component={RouterLink}
              startIcon={<ArrowLeftIcon />}
              to={`${appUrlBase}/purchases/invoices`}
              variant="text"
            >
              comprobante
            </Button>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              #{invoiceState.data?.document}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Button
                color="primary"
                size="large"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              />
              <InvoiceMenu
                invoice={invoiceState.data}
                detailPath={`${appUrlBase}/purchases/invoices`}
                apiHandler={invoiceApi}
                containerView={purchaseView.PURCHASE_SERVICE}
                afterProcessHandler={handleRefresh}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={invoiceState.data?.statusName} />
          </Box>
        </Box>
        <Divider />
        <Grid container spacing={3}>
          <Grid
            container
            item
            lg={12}
            spacing={3}
            sx={{
              height: 'fit-content',
              order: {
                md: 2,
                xs: 1
              },
              mb: 3
            }}
            xs={12}
            xl={12}
          >
            <Grid item xs={12}>
              <RenderIf
                condition={editMode && editable}
                no={
                  <InvoiceDetails
                    invoice={invoiceState.data}
                    setEditMode={handleEdit}
                    editable={editable}
                  />
                }
              >
                <InvoiceEdit
                  invoice={invoiceState.data}
                  setEditMode={setEditmode}
                  onAfterSave={handleRefresh}
                />
              </RenderIf>
              <Divider />
              <InvoiceLineItems invoice={invoiceState.data} onRowSelect={handleRowSelect} />
              <DocumentLog
                document={{
                  objType: invoiceState.data.objType,
                  docEntry: invoiceState.data.docEntry
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <DocumentLineDetailDialog
          open={detailDialogOpen}
          onClose={handleCloseDetailDialog}
          onExited={handleExitedDetailDialog}
          currentLine={currentLine}
          onAfterSave={handleRefresh}
          Component={DocumentLineEdit}
          title="Editar Linea de documento"
          editable={editable}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de comprobante')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

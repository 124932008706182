const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    yl: 1800,
    zl: 2600
  }
};

export default breakpoints;

const UserActions = {
  login: 'login',
  logout: 'logout',
  checked: 'checked'
};

const InitialtUser = {
  userInfo: { id: 0, name: 'Invitado', email: '' },
  logged: false,
  checked: false
};

const UserReducer = (state, action) => {
  switch (action.type) {
    case UserActions.login:
      return { ...state, userInfo: action.payload, logged: true };
    case UserActions.logout:
      return { ...InitialtUser };
    case UserActions.checked:
      return { ...state, checked: true };
    default:
      return state;
  }
};

export { InitialtUser, UserActions };
export default UserReducer;

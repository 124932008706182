import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// import logo from '../../../images/logo-roma.png';
import JournalTitle from './journal.title';
import JournalHeader from './journal-header';
import JournalNumber from './journal-number';
import JournalFooterMessage from './journal-footer-message';
import JournalLinesTable from './journal-lines-table';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'row'
  },
  invoiceHeaderContainer: {
    flexDirection: 'row',
    marginTop: 3,
    justifyContent: 'space-between'
  }
});

const JournalReceipt = ({ journal }) => (
  <Document>
    <Page key={1} size="A4" style={styles.page} pageNumber={1}>
      <View style={styles.invoiceHeaderContainer}>
        {/* <View>
          <Image style={styles.logo} src={logo} />
           
        </View> */}
        <View>
          <JournalTitle title="Registro en el diario" />
        </View>
      </View>
      <View style={styles.invoiceHeaderContainer}>
        <View>
          <JournalHeader journal={journal} />
        </View>
        <View>
          <JournalNumber journal={journal} />
        </View>
      </View>
      <JournalLinesTable journal={journal} />
      <JournalFooterMessage journal={journal} />
    </Page>
  </Document>
);

JournalReceipt.propTypes = {
  journal: PropTypes.object
};
export default JournalReceipt;

import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Button, Drawer, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
// import account from '../../_mocks_/account';
import { useAuth } from '../../hooks/use-auth';
import { useApp } from '../../hooks/use-app';
import { InputField } from '../../components/input-field';
import { ArrowLeft } from '../../icons/arrow-left';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('zl')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const theme = useTheme();

  const { permissions } = useAuth();

  const { currentCompany } = useApp();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const [navItems, setNavItems] = useState([]);
  const [navItemsOriginal, setNavItemsOriginal] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const sideBarItems = useMemo(
    () => sidebarConfig({ companyId: currentCompany, permissions }),
    [currentCompany, permissions]
  );

  useEffect(() => {
    setNavItems(sideBarItems);
    setNavItemsOriginal(sideBarItems);
  }, [sideBarItems]);

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);

  //   if (event.target.value === '') {
  //     setNavItems(navItemsOriginal);
  //   } else {
  //     const resultado = navItemsOriginal
  //       .flatMap((item) => item.children || [])
  //       .filter((child) => child.title.toLowerCase().includes(event.target.value.toLowerCase()));
  //     setNavItems(resultado);
  //   }
  // };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);

    if (event.target.value === '') {
      setNavItems(navItemsOriginal);
    } else {
      const resultado = navItemsOriginal
        .map((item) => {
          if (item.children) {
            const children = item.children.filter((child) =>
              child.title.toLowerCase().includes(event.target.value.toLowerCase())
            );

            if (children.length > 0) {
              return { ...item, children };
            }
          }
          return null;
        })
        .filter((item) => item !== null);
      setNavItems(resultado);
    }
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 3,
          backgroundColor: alpha(theme.palette.background.paper, 0.1)
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
        {/* <Button onClick={() => onCloseSidebar()}> <ArrowLeft /> </Button> */}
        <Button
          color="primary"
          component="button"
          startIcon={<ArrowLeft />}
          onClick={() => onCloseSidebar()}
          variant="text"
        />
      </Box>

      {/* <DashboardItemMenu /> */}

      <Box sx={{ px: 2, marginBottom: 2 }}>
        <InputField
          type="text"
          placeholder="Buscar en menu"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          // onChange={(event) => {
          //   handleSearchChange(event);
          //   handleSearch();
          // }}
        />
        {/* <Button onClick={handleSearch}>Buscar</Button> */}
      </Box>

      <NavSection navConfig={navItems} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 0.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 0.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />
          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              ¿Ayuda?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Whatsapp +51977456215
            </Typography>
          </Box>
          <Button
            fullWidth
            href="https://web.whatsapp.com/send?phone=51959798817&text=%C2%A1Hola!%20necesito%20ayuda"
            target="_blank"
            variant="contained"
          >
            ¡Auxilio!
          </Button>

          <Button fullWidth href="https://gadmin.dsroma.info" target="_blank" variant="contained">
            Gadmin Clasic
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="zlUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="zlDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

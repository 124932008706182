import { useCallback } from 'react';

const useFormikMultiSelect = (formik, useId = false) => {
  const handleMultiSelectChange = useCallback(
    (event, item, changeData, valid = false, dataApi) => {
      const { value: selectedValues } = event.target;

      const hasAllOption = selectedValues.some((option) => {
        if (useId) {
          return option.id === 0;
        }
        return option.code === 'ALL';
      });

      if (hasAllOption && selectedValues.length === 1) {
        const updatedData = dataApi.filter((option) => option[useId ? 'id' : 'code'] !== 'ALL');
        formik.setFieldValue(item, updatedData);
        if (valid) {
          const updatedSelected = [];
          changeData(updatedSelected);
        }
      } else {
        const filteredSelectedValues = selectedValues.filter(
          (option) => option[useId ? 'id' : 'code'] !== 'ALL'
        );

        const data = filteredSelectedValues.map((option) => ({
          [useId ? 'id' : 'code']: option[useId ? 'id' : 'code'],
          name: option.name
        }));
        formik.setFieldValue(item, data);
        if (valid) {
          const updatedSelected = dataApi.filter(
            (selectedValue) =>
              !data.some(
                (item) => item[useId ? 'id' : 'code'] === selectedValue[useId ? 'id' : 'code']
              )
          );
          changeData(updatedSelected);
        }
      }
    },
    [formik, useId]
  );

  const getOptionsName = useCallback(
    (item, options) => {
      const optionChip = options.find((option) => option[useId ? 'id' : 'code'] === item);
      return optionChip ? optionChip.name : '';
    },
    [useId]
  );

  const handleOptionsDelete = useCallback(
    (value, item, option, changeData, valid = false, data) => {
      const updatedSelected = option.filter((selectedValue) => selectedValue !== value);
      formik.setFieldValue(item, updatedSelected);

      if (valid) {
        const arrayActualizado = [value, ...data];
        changeData(arrayActualizado);
      }
    },
    [formik]
  );

  return { handleMultiSelectChange, getOptionsName, handleOptionsDelete };
};

export default useFormikMultiSelect;

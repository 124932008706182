import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const Loading = ({ message = 'Cargando' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
        // width: '100vw',
        margin: 0,
        minHeight: '100%',
        overflow: 'hidden'
        // backgroundColor: 'rgba(0, 0, 0, 0.4)'
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CircularProgress />
          <Box sx={{ flexGrow: 1, px: 2 }} />
          <Box>{message}...</Box>
        </Box>
      </Box>
    </Box>
  );
};

Loading.propTypes = {
  message: PropTypes.string
};
export default Loading;

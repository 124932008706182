import PropTypes from 'prop-types';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Link
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';
import { NumberColor } from '../../number-color';
import { YesNo } from '../../yes-no';
import { Status } from '../../status';

import { RenderIf } from '../../render-if';
import { lineStatusVariants } from '../../../api/app-definitions';
import { LoadingTable } from '../../common/loading-table';
import { useApp } from '../../../hooks/use-app';

export const CustomerProductPurchasesTable = ({ onRefresh, documentState, purchases = {} }) => {
  const { parameters } = purchases;
  const handleRefresh = () => {
    onRefresh?.();
  };
  const { appUrlBase } = useApp();

  return (
    <Card
      variant="outlined"
      sx={{
        marginY: '1em'
      }}
    >
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <RenderIf condition={!!parameters}>
            <CardHeader
              title={`Compras del producto ${parameters?.itemCode} - ${parameters?.itemName}  desde ${parameters?.dateStart} hasta ${parameters?.dateTo}`}
            />
          </RenderIf>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pedido</TableCell>
              <TableCell>Doc</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Importe</TableCell>
              <TableCell>Comprobante</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Total Linea</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Promocion</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {purchases.lines?.map((item) => {
              const statusVariant = lineStatusVariants.find(
                (variant) => variant.value === item.lineStatus
              );
              return (
                <TableRow key={item.idpedido}>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      underline="hover"
                      variant="subtitle2"
                      to={`${appUrlBase}/sales/orders/${item.idpedido}`}
                    >
                      {item.idpedido}
                    </Link>
                  </TableCell>
                  <TableCell>{item.receipttype}</TableCell>
                  <TableCell>{moment(item.docdate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <NumberColor value={item.doctotal} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <RenderIf condition={!!item.transId} no="-">
                      <NumberColor value={item.DocNum} format="0" />
                      <Link
                        color="inherit"
                        component={RouterLink}
                        underline="hover"
                        variant="subtitle2"
                        to={`${appUrlBase}/sales/receipts/${item.transId}`}
                      >
                        {item.SerieSunat}-{item.NumeroSunat}
                      </Link>
                    </RenderIf>
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.quantity} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.price} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <NumberColor value={item.gtotal} format="0.00" />
                  </TableCell>
                  <TableCell>
                    <Status color={statusVariant?.color} label={statusVariant?.label} />
                  </TableCell>
                  <TableCell>
                    <YesNo value={item.isPromo} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <LoadingTable documentState={documentState} />
    </Card>
  );
};

CustomerProductPurchasesTable.propTypes = {
  documentState: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired,
  purchases: PropTypes.object
};

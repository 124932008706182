import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { customerReturnsApi } from '../../api/customer-return';
// import { Status } from '../../components/status';
import { useMounted } from '../../hooks/use-mounted';
import gtm from '../../lib/gtm';
import { useApp } from '../../hooks/use-app';
import { ReturnsProductsTable } from '../../components/document/returns/returns-products-table';
import { CustomerReturnsProductsFilter } from '../../components/document/returns/returns-products-filter';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../icons/plus';
import { useSelection } from '../../hooks/use-selection';

export const CustomerReturnsProducts = () => {
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    query: '',
    sort: 'desc',
    sortBy: 'ItemName',
    view: 'all',
    reload: true,
    refresh: true
  });

  const mounted = useMounted();
  const { title } = useApp();
  const [productState, setProductState] = useState({
    isLoading: true,
    reload: true
  });
  const [selectedProducts, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    productState.data?.products
  );
  const handleRefresh = () => {
    setController({
      ...controller,
      filters: [],
      page: 0,
      query: '',
      sort: 'desc',
      sortBy: 'ItemName',
      view: 'all',
      reload: true,
      refresh: true
    });
  };

  const getProducts = useCallback(async () => {
    if (controller.refresh) {
      setProductState(() => ({ ...productState, isLoading: true }));

      try {
        const result = await customerReturnsApi.getReturnsProducts({
          filters: controller.filters,
          page: controller.page,
          query: controller.query,
          sort: controller.sort,
          sortBy: controller.sortBy,
          view: controller.view,
          reload: controller.reload
        });
        if (mounted.current) {
          setProductState(() => ({
            isLoading: false,
            data: result,
            reload: false
          }));
          handleClearSelected();
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setProductState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      controller.refresh = false;
    }
  }, [controller]);

  useEffect(() => {
    getProducts().catch(console.error);
  }, [controller]);

  const handleViewChange = (newView) => {
    setController({
      ...controller,
      page: 0,
      view: newView,
      refresh: true
    });
  };
  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 0,
      query: newQuery,
      refresh: true
    });
  };

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      refresh: true
    });
  };

  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      refresh: true
    });
  };
  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
      refresh: true
    });
  };

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
      refresh: true
    });
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const renderContent = () => (
    <>
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Typography color="textPrimary" variant="h4">
            Producto disponibles para devolución
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <AllowedGuard permission={GadminActions.G2_CD_LIST}>
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleRefresh}
              variant="contained"
            >
              Actualizar
            </Button>
          </AllowedGuard>
          {/* <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            /> */}
        </Box>
        {/* <Box sx={{ mt: 2 }}>
            <Status color="error.main" label={productState.data?.statusName} />
          </Box> */}
      </Box>
      <Grid container spacing={3}>
        <Grid
          container
          item
          lg={12}
          spacing={3}
          sx={{
            height: 'fit-content',
            order: {
              md: 2,
              xs: 1
            }
          }}
          xs={12}
          xl={12}
        />
        <Grid
          container
          item
          lg={12}
          spacing={3}
          sx={{
            height: 'fit-content',
            order: {
              md: 2,
              xs: 1
            }
          }}
          xs={12}
          xl={12}
        >
          <Grid item xs={12}>
            <CustomerReturnsProductsFilter
              disabled={productState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedProducts={selectedProducts}
              view={controller.view}
            />
            <ReturnsProductsTable
              error={productState.error}
              products={productState.data?.products}
              productsCount={productState.data?.productsCount}
              isLoading={productState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedProducts={selectedProducts}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Helmet>
        <title> {title('Productos para devolución')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { customeOrderApi } from '../../../api/customer-order';
import { Query } from '../../query';
import {
  ordersView,
  // OrderViews as views,
  orderBulkActions
} from '../../../api/customer-order-definitions';
import { BulkActionsMenu } from '../../bulk-actions-menu';
import GadminActions from '../../../contexts/gadmin-actions';
import { useApp } from '../../../hooks/use-app';
import { capitalizeParagraph } from '../../../hooks/use-split';

export const PresaleMonitoringFilter = (props) => {
  const {
    disabled,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = 'all',
    onRefresh,
    tabsList = [],
    teamCode
  } = props;
  const [menuActions, setMenuActions] = useState([]);
  const { appUrlBase } = useApp();
  const location = useLocation();
  const currentQuery = new URLSearchParams(location.search);
  const date = currentQuery.get('date');

  const afterProcessHandler = () => {
    onRefresh?.();
  };

  useEffect(() => {
    const actions = [];

    if (
      [
        ordersView.VIEW_DRAFT,
        ordersView.VIEW_AUTHORIZED,
        ordersView.VIEW_PENDING,
        ordersView.VIEW_SAP_ORDER
      ].includes(view)
    ) {
      actions.push({
        action: orderBulkActions.CANCEL,
        permission: GadminActions.gCC_BROWSE_ORDER_DIG,
        label: 'Anular',
        prompt: '¿Está seguro que desea anular lod documentos seleccionados?'
      });
    }
    setMenuActions(actions);
    onRefresh(false);
  }, [view]);

  useEffect(() => {
    onViewChange(teamCode);
  }, []);

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons
            value={view}
          >
            {tabsList.map((option, index) => (
              <Tab
                component={RouterLink}
                disabled={disabled}
                key={index}
                label={capitalizeParagraph(option.name)}
                value={option.code}
                to={
                  date
                    ? `${appUrlBase}/commercial/pre-sale/${option.code}?date=${date}`
                    : `${appUrlBase}/commercial/pre-sale/${option.code}`
                }
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <BulkActionsMenu
            disabled={disabled}
            menuActions={menuActions}
            selectedItems={selectedDocuments}
            selectedCount={selectedDocuments.length}
            afterProcessHandler={afterProcessHandler}
            apiHandler={customeOrderApi.processOrderBulk}
            sx={{
              display: selectedDocuments.length > 0 ? 'flex' : 'none',
              order: {
                sm: 1,
                xs: 2
              }
            }}
          />
          <Query
            disabled={disabled}
            onChange={onQueryChange}
            sx={{
              order: {
                sm: 2,
                xs: 1
              }
            }}
            value={query}
          />
        </Box>
      </div>
    </>
  );
};

PresaleMonitoringFilter.propTypes = {
  disabled: PropTypes.bool,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.string,
  onRefresh: PropTypes.func,
  tabsList: PropTypes.array,
  teamCode: PropTypes.string
};

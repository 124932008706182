import { useParams } from 'react-router-dom';
import { customeDeleveryMonitoringApi } from '../../../api/customer-delivery-monitoring';
import { CustomerDdeliverySumaryData } from '../../../components/delivery/monitoring/customer-delivery-sumary-data';
import LoadingContent from '../../../components/common/loading-content';
import { useMounted } from '../../../hooks/use-mounted';
import { useData } from '../../../hooks/use-data';

export const DeliverySummary = () => {
  const { id } = useParams();
  const mounted = useMounted();

  const [documentState, handleRefresh] = useData({
    sourceApi: customeDeleveryMonitoringApi.getSumaryMonitoring,
    apiParameter: {
      id,
      addStatistic: true
    },
    loadingMessage: 'Cargando informacion contable del producto',
    mounted
  });
  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }
  if (documentState.data.statistic === null || documentState.data.statistic.length === 0) {
    return <LoadingContent loadingText="No hay datos" error={documentState.error} />;
  }
  return (
    <CustomerDdeliverySumaryData
      dispatchId={id}
      productInfo={documentState}
      handleRefresh={() =>
        handleRefresh({
          id,
          addStatistic: true
        })
      }
    />
  );
};

import { ListItemIcon } from '@mui/material';
import PropTypes from 'prop-types';

export const GoalListIcon = ({ icon }) => {
  return (
    <ListItemIcon>
      {/* <GoalIcon iconName={iconName} width={width} height={height} {...others} /> */}
      {icon}
    </ListItemIcon>
  );
};

GoalListIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.element
};

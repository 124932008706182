import PropTypes from 'prop-types';
import numeral from 'numeral';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Eye as EyeIcon } from '../../../icons/eye';
import Scrollbar from '../../Scrollbar';

export const InvoiceTable = (props) => {
  const { invoice, onRowSelect } = props;
  return (
    <div>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>.</TableCell>
              <TableCell>#</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell>Unidad</TableCell>
              <TableCell>Cant x Unidad</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Dscto (%)</TableCell>
              <TableCell>SubTotal</TableCell>
              <TableCell>IGV</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Cuenta</TableCell>
              <TableCell>C Costo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.lines.map((item) => (
              <TableRow key={item.lineNum}>
                <TableCell sx={{ minWidth: 20 }}>
                  <Link
                    color="inherit"
                    component="button"
                    onClick={() => {
                      onRowSelect(item);
                    }}
                    underline="none"
                    variant="subtitle2"
                  >
                    <EyeIcon />
                  </Link>
                </TableCell>
                <TableCell>{numeral(item.lineNum).format('0')}</TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.itemCode}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.frgItem}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.itemName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.unitMsr}</TableCell>
                <TableCell>{numeral(item.numPerMsr).format('0')}</TableCell>
                <TableCell>{numeral(item.quantity).format('0.0')}</TableCell>
                <TableCell>{numeral(item.priceBefDi).format('0.0000')}</TableCell>
                <TableCell>{numeral(item.discPrcnt).format('0.00')}</TableCell>
                <TableCell>
                  {numeral(item.lineTotal).format(`${item.currencySymbol}0,0.00`)}
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {numeral(item.vatSum).format(`${item.currencySymbol}0,0.00`)}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.taxCode}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  {numeral(item.gTotal).format(`${item.currencySymbol}0,0.00`)}
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="body2">
                    {item.account}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {item.acctName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="body2">
                    {item.OcrCode}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {item.ocrName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

InvoiceTable.propTypes = {
  invoice: PropTypes.object.isRequired,
  onRowSelect: PropTypes.func
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Tab, Tabs } from '@mui/material';
import { Adjustments as AdjustmentsIcon } from '../../../icons/adjustments';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../../../utils/filter-operators';
import { Query } from '../../query';
import { FilterDialog } from '../../filter-dialog';
import { RenderIf } from '../../render-if';

const views = [
  {
    label: 'Mostrar todos',
    value: 'all'
  },
  {
    label: 'Sin Stock',
    value: 'S'
  },
  {
    label: 'Anulado',
    value: 'A'
  },
  {
    label: 'Cancelado',
    value: 'E'
  }
];

const filterProperties = [
  {
    label: 'Número pedido',
    name: 'id',
    type: 'number'
  },
  {
    label: 'Código Cliente',
    name: 'CodigoCliente',
    type: 'string'
  },
  {
    label: 'Fecha de pedido',
    name: 'FechaPedido',
    type: 'date'
  },
  {
    label: 'Fechas de despacho',
    name: 'fecdsp',
    type: 'date'
  },
  {
    label: 'Total Pedido',
    name: 'TotalPedido',
    type: 'string'
  },
  {
    label: 'Código Almacén',
    name: 'CodigoAlmacen',
    type: 'string'
  },
  {
    label: 'Código Zona',
    name: 'CodigoZona',
    type: 'string'
  },
  {
    label: 'Código Mesa',
    name: 'CodigoMesa',
    type: 'string'
  },
  {
    label: 'Almacén',
    name: 'whsName',
    type: 'string'
  },
  {
    label: 'Mesa',
    name: 'mesaName',
    type: 'string'
  },
  {
    label: 'Vendedor',
    name: 'slpName',
    type: 'string'
  },
  {
    label: 'Estado',
    name: 'EstadoMigrado',
    type: 'string'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];

export const CustomerOrdersCanceledFilter = (props) => {
  const {
    disabled,
    filters = [],
    onFiltersApply,
    onFiltersClear,
    onQueryChange,
    onViewChange,
    query,
    selectedDocuments = [],
    view = 'all',
    enableSearch
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  return (
    <>
      <div>
        <Box
          sx={{
            px: {
              sm: 3
            }
          }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: selectedDocuments.length > 0 ? 'auto 1fr auto' : '1fr auto',
              xs: 'auto'
            },
            justifyItems: 'flex-start',
            p: 3
          }}
        >
          <RenderIf condition={enableSearch}>
            <Query
              disabled={disabled}
              onChange={onQueryChange}
              sx={{
                order: {
                  sm: 2,
                  xs: 1
                }
              }}
              value={query}
            />
          </RenderIf>
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3 }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filtrar
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={filterProperties}
      />
    </>
  );
};

CustomerOrdersCanceledFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedDocuments: PropTypes.array,
  view: PropTypes.string,
  enableSearch: PropTypes.bool
};

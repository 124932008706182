import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { NumberColor } from './number-color';

export const Sumary = ({ values, column, label = 'Total', always = false }) => {
  const [sumary, setSumary] = useState(0);

  useEffect(() => {
    const lines = values?.map((item) => parseFloat(item[column]));
    const total = lines?.reduce((a, c) => a + c);
    setSumary(total);
  }, [values, column]);
  if (!sumary && !always) return null;
  return (
    <Box
      sx={{
        alignItems: 'right',
        display: 'flex'
      }}
    >
      <Typography
        sx={{
          color: 'main.success',
          ml: 1.75
        }}
        variant="h4. Heading"
        align="right"
      >
        {label}
      </Typography>
      <NumberColor value={sumary} format="0.00" />
    </Box>
  );
};

Sumary.propTypes = {
  values: PropTypes.array.isRequired,
  column: PropTypes.string,
  label: PropTypes.string,
  always: PropTypes.bool
};

import { useState } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import Scrollbar from '../Scrollbar';
import { Eye as EyeIcon } from '../../icons/eye';
import { useApp } from '../../hooks/use-app';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { CustomerModal } from '../delivery/monitoring/customer-modal';
import { SalesTeamsForm } from './form/sales-teams-form';
import GadminActions from '../../contexts/gadmin-actions';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: 'Cod. '
  },
  {
    id: 'name',
    label: 'Nombre'
  },
  {
    id: 'status',
    label: 'Activo'
  },
  {
    id: 'customer_channels_id',
    label: 'Canales'
  },
  {
    id: 'zones',
    label: 'Zonas'
  },
  {
    id: 'prices',
    label: 'Precios'
  },
  {
    id: 'routes',
    label: 'Rutas'
  },
  {
    id: 'sellers',
    label: 'Vendedores'
  },
  {
    id: 'providers',
    label: 'Proveedores'
  },
  {
    id: 'category',
    label: 'Categorias'
  },
  {
    id: 'brands',
    label: 'Marcas'
  },
  {
    id: 'products',
    label: 'Productos'
  },
  {
    id: 'wallet',
    label: 'Cartera'
  }
];

export const SalesTeamsTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    sort = 'desc',
    sortBy = 'issueDate'
  } = props;
  const [open, onClose] = useState(false);
  const [itemData, setitemEdit] = useState(null);

  const { appUrlBase } = useApp();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const detailRoute = `${appUrlBase}/commercial/sales-teams/`;

  const handleClickEditIcon = (item) => {
    setitemEdit(item);
    onClose(!open);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000, marginTop: 1, marginLeft: 3 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sort : 'asc'}
                    disabled={isLoading}
                    onClick={(event) => onSortChange(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow hover key={index}>
                <TableCell
                  sx={{
                    minWidth: 150
                  }}
                >
                  <Typography color="textSecondary" variant="body2">
                    {document.code}
                    <IconButton>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`${detailRoute}${document.id}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        <EyeIcon />
                      </Link>
                    </IconButton>
                    {false && (
                      <IconButton
                        onClick={() => handleClickEditIcon(document)}
                        color="secondary"
                        aria-label="edit"
                      >
                        <PencilIcon color="primary" />
                      </IconButton>
                    )}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.status}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.channel_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.zone_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.price_list_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.territory_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.salesman_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.salesman_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.supplier_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.brand_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.product_count}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document?.indicators?.portfolio_count}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={documentsCount}
      />
      <CustomerModal
        open={open}
        onClose={onClose}
        permission={GadminActions.G2_CONTESTS_UP}
        widhtForm="xs"
      >
        <SalesTeamsForm title="Mesa" onClose={onClose} update initialValues={itemData} />
      </CustomerModal>
    </Box>
  );
};

SalesTeamsTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string
};

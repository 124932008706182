import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { IconButton, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// components
import { useApp } from '../../hooks/use-app';

export default function PicPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const { appUrlBase } = useApp();

  return (
    <IconButton
      ref={anchorRef}
      onClick={handleOpen}
      component={RouterLink}
      to={`${appUrlBase}/pic-share`}
      sx={{
        padding: 0,
        width: 44,
        height: 44,
        ...(open && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
        })
      }}
    >
      <Box
        component="img"
        height={35}
        src="/static/images/pic-share-logo.svg"
        alt="Logo-Pic-Share"
        sx={{ borderRadius: '4px', objectFit: 'fill', p: 1 }}
      />
    </IconButton>
  );
}

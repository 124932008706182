import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import { useApp } from '../../../hooks/use-app';
import { useParams } from 'react-router-dom';
import GadminActions from '../../../contexts/gadmin-actions';
import { GoalTabs } from '../../../hooks/use-tabs';

export const BulkTab = () => {
  const { id } = useParams();
  const { appUrlBase, title } = useApp();
  const defaultTabs = [
    {
      href: '',
      label: 'Detalle',
      permission: GadminActions.G2_BULK_LIST
    },
    {
      href: '/queues',
      label: 'Queues',
      permission: GadminActions.G2_QUEUE_LIST
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title('Detalle del Bulk')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <GoalTabs
            basePath={`${appUrlBase}/automations/bulks/${id}`}
            backPath={{
              path: `${appUrlBase}/automations/bulks`,
              label: 'volver (Bulks)'
            }}
            tabs={defaultTabs}
          />
        </Container>
      </Box>
    </>
  );
};

import SvgIcon from '@mui/material/SvgIcon';

function HelpIcon(props) {
  return (
    <SvgIcon {...props}>
      <circle cx="10" cy="10" r="9" fill="#2F88FF" />
      <text x="10" y="14" fontSize="12" fontWeight="bold" textAnchor="middle" fill="#FFF">
        ?
      </text>
    </SvgIcon>
  );
}

export default HelpIcon;

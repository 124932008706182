import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest, GadminFileUpload } from '../services/gadmin';
import { applyFilters } from '../utils/apply-filters';
import { applyPagination } from '../utils/apply-pagination';
import { applySort } from '../utils/apply-sort';
import { dataLoader } from './data-loader';

/* eslint class-methods-use-this:0 */

class CustomeReturnApi {
  async getCustomeReturns(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.G2_CD_LIST,
      cacheItem: 'customer-returns',
      apiParams: {
        type: 'list',
        view,
        payload: { filters, sort, sortBy, page, query, reload }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'cardName' }
      ],
      ...options
    });
  }

  async getCustomerReturnsStatics() {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, { type: 'static' });
    const { data } = response;
    return Promise.resolve(data);
  }

  async syncCustomerReturn() {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'sync'
    });
    return Promise.resolve(response.data);
  }

  async getCustomerReturn(id) {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'detail',
      payload: { id }
    });
    return Promise.resolve(response.data);
  }

  async getCustomerReturnPrint(id) {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'detail',
      payload: { id }
    });
    return Promise.resolve(response.data);
  }

  async uploadCustomerReturn(files) {
    const response = await GadminFileUpload(GadminActions.G2_PURCHASE_RRHH_UP, {
      type: 'load',
      payload: files,
      ...files
    });
    return Promise.resolve(response.data);
  }

  async updateCustomerReturn({ id, changes }) {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'save-header',
      payload: { id, changes }
    });
    return Promise.resolve(response.data);
  }

  async processCustomerReturn(payload) {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'process',
      payload
    });
    return response;
  }

  async getInvoiceMedia({ type, docEntry }) {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'media',
      payload: { type, docEntry }
    });
    return Promise.resolve(response.data);
  }

  async getInvoiceJournal({ docEntry }) {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'journal',
      payload: { docEntry }
    });
    return Promise.resolve(response.data);
  }

  async getJournalData({ transId }) {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'journal-data',
      payload: { transId }
    });
    return Promise.resolve(response.data);
  }

  async getCustomeReturnsHst() {
    const response = await GadminRequest(GadminActions.G2_CD_LIST_HST, {
      type: 'list-hst'
    });
    return Promise.resolve(response.data);
  }

  async upCustomerReturnLine({ ...props }) {
    const response = await GadminRequest(GadminActions.G2_CD_LINE_UP, {
      type: 'update',
      payload: props
    });
    return Promise.resolve(response.data);
  }

  async getReturnsReasons() {
    const response = await GadminRequest(GadminActions.G2_CD_REASON_LIST, {
      type: 'list'
    });
    return Promise.resolve(response.data);
  }

  async getReturnsStats() {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'stats'
    });
    if (response.data?.stats) {
      response.data.stats = response.data.stats.map((item) => {
        if (item.name === 'Cancelado') {
          item.name = 'Anulado';
        }
        return item;
      });
    }
    return response;
  }

  async getStatMinDate() {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'stats-min-date'
    });
    return response;
  }

  async getStatAllCount() {
    const response = await GadminRequest(GadminActions.G2_CD_LIST, {
      type: 'stats-all-count'
    });
    return response;
  }

  async getReturnsProducts(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;
    const localStorageItem = 'return-avalible-products';
    let returnsData = JSON.parse(localStorage.getItem(localStorageItem)) || [];
    if (reload || !returnsData) {
      const response = await GadminRequest(GadminActions.G2_CD_LIST, {
        type: 'avalible-products'
      });
      const { data } = response;
      returnsData = data;
      localStorage.setItem(localStorageItem, JSON.stringify(returnsData));
    }

    const queriedProducts = returnsData.filter((_invoice) => {
      if (
        !!query &&
        !(
          _invoice.ItemCode?.toString().toLowerCase().includes(query.toLowerCase()) ||
          _invoice.ItemName?.toLowerCase().includes(query.toLowerCase())
        )
      ) {
        return false;
      }

      // No need to look for any resource fields
      if (typeof view === 'undefined' || view === 'all') {
        return true;
      }
      if (view === 'no-whscode') {
        return !_invoice.whscode;
      }

      // In this case, the view represents the resource status
      return _invoice.procStatus === view;
    });
    const filteredProducts = applyFilters(queriedProducts, filters);
    const sortedProducts = applySort(filteredProducts, sort, sortBy);
    const paginatedProducts = applyPagination(sortedProducts, page);

    return Promise.resolve({
      products: paginatedProducts,
      productsCount: filteredProducts.length
    });
  }
}

export const customerReturnsApi = new CustomeReturnApi();

import { useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { Trash as TrashIcon } from '../../../icons/trash';
// import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
// import { useApp } from '../../../hooks/use-app';

const columns = [
  {
    id: 'id',
    disablePadding: true,
    label: '#'
  },
  {
    id: 'title',
    label: 'Titulo'
  },
  {
    id: 'type_question',
    label: 'Tipo de Pregunta'
  },
  {
    id: 'question',
    label: 'Pregunta'
  },
  {
    id: 'options',
    label: 'Opciones'
  },
  {
    id: 'correct',
    label: 'Respuesta'
  },
  {
    id: 'score',
    label: 'Puntaje'
  },
  // {
  //   id: 'apply_to',
  //   label: 'Aplicado'
  // },
  {
    id: 'action',
    label: ''
  }
];

export const SurveySurveyDetailTable = (props) => {
  const {
    error,
    documents = [],
    isLoading,
    onSelect,
    onSelectAll,
    selectedDocuments = [],
    // idDetail,
    // handleRefresh,
    handleUpdateScore,
    btnDisabled
  } = props;

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

  const [modalOpen, setModalOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  // const { showNotify, showError } = useApp();

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenCornfirm = (document) => {
    setModalOpen(true);
    setIdDelete(document);
    setConfirmDialogMessage(`Estas seguro de eliminar esta pregunta - ${document.title}`);
  };

  const handleClickDelete = async (idDelete) => {
    handleUpdateScore(idDelete);
    // const response = await goalCustomeSurveyApi.getSurveySurveyDeleteLine(idDetail, idDelete.id);
    // if (response.success) {
    //   handleUpdateScore(idDelete);
    //   showNotify(`${response?.message}. Cambio exitoso `);
    //   handleRefresh();
    // } else {
    //   showError(`${response?.message}. No se proceso la acción `);
    // }
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedDocuments.length === documents.length}
                  disabled={isLoading}
                  indeterminate={
                    selectedDocuments.length > 0 && selectedDocuments.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel disabled={isLoading}>{column.label}</TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow
                hover
                key={index}
                selected={
                  !!selectedDocuments.find((selectedCustomer) => selectedCustomer === document.id)
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      !!selectedDocuments.find(
                        (selectedCustomer) => selectedCustomer === document.id
                      )
                    }
                    onChange={(event) => onSelect(event, document.id)}
                  />
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.type_question}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <Typography color="textSecondary" variant="body2">
                    {document.question}
                  </Typography>
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ||
                  document.type_question === 'select' ||
                  document.type_question === 'range' ? (
                    document.options.map((option, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {option}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {document.type_question === 'multiselect' ? (
                    document.correct.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {document.correct}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {document.score}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  {document.apply_to ? (
                    <>
                      {document.apply_to.slice(0, 5).map((item, index) => (
                        <Typography key={index} color="textSecondary" variant="body2">
                          {item?.name}
                        </Typography>
                      ))}
                      {document.apply_to.length > 5 && (
                        <Typography color="textSecondary" variant="body2">
                          ...
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      --
                    </Typography>
                  )}
                </TableCell> */}
                <TableCell>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    sx={{
                      marginRight: 1
                    }}
                    onClick={() => handleOpenCornfirm(document)}
                    disabled={btnDisabled}
                  >
                    <TrashIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}

      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={handleCloseModal}
        onConfirm={() => handleClickDelete(idDelete)}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

SurveySurveyDetailTable.propTypes = {
  documents: Proptypes.array,
  error: Proptypes.bool,
  isLoading: Proptypes.bool,
  onSelect: Proptypes.func,
  onSelectAll: Proptypes.func,
  selectedDocuments: Proptypes.array,
  // idDetail: Proptypes.string,
  // handleRefresh: Proptypes.func,
  handleUpdateScore: Proptypes.func,
  btnDisabled: Proptypes.bool
};

const purchaseView = {
  PURCHASE_SERVICE: 'purchase-service',
  PURCHASE_NC_SERVICE: 'purchase-nc-service',
  PURCHASE_FEES: 'purchase-fees',
  PURCHASE_ITEMS: 'purchase-items'
};

const purchaseOptions = {
  BP_ACCOUNT_LIST: 'bp-account-list',
  ACCOUNT_LIST: 'account-list',
  COSTING_CODE_LIST: 'costing-code-list',
  TAX_CODE_LIST: 'tax-code-list',
  BRANCH_LIST: 'branch-list',
  PAYMENT_TERM_LIST: 'payment-term-list',
  SEDE_CONFIG: 'sede-config'
};

const paymentList = [
  { id: -1, name: 'Contado' },
  { id: 1, name: 'Crédito' }
];
const warehouseList = [
  { id: '11CHPR1', name: 'AM CH' },
  { id: '11ICPR1', name: 'AM ICA' }
];
const documnetTypeList = [
  { id: 'S', name: 'Servicio' },
  { id: 'I', name: 'Mercadería' }
];

const measureUnits = [
  { id: 'BLS', name: 'Bolsa' },
  { id: 'CJA', name: 'Caja' },
  { id: 'PQT', name: 'Paquete' },
  { id: 'PZA', name: 'Pieza' }
];

const invoiceActions = {
  INVOICE_SERVICE_LIST: 'list-service',
  INVOICE_ITEMS_LIST: 'list-item',
  INVOICE_SERVICE_DETAIL: 'detail-service',
  INVOICE_ITEMS_DETAIL: 'detail-item',
  INVOICE_ITEMS_SAVE_HEADER: 'item-save-header',
  INVOICE_ITEMS_UPLOAD: 'item-upload',
  INVOICE_ITEMS_PROCESS: 'item-process',
  INVOICE_ITEMS_PROCESS_BULK: 'item-process-bulk',
  INVOICE_ITEMS_SAVE_LINE: 'item-save-line',
  INVOICE_SERVICE_SAVE_HEADER: 'service-save-header',
  INVOICE_SERVICE_SAVE_LINE: 'service-save-line',
  INVOICE_SERVICE_UPLOAD: 'service-upload',
  INVOICE_SERVICE_PROCESS: 'process',
  INVOICE_SERVICE_PROCESS_BULK: 'process-bulk',
  RECEIPT_FEES_LIST: 'list-receipt-fees',
  RECEIPT_FEES_DETAIL: 'detail-receipt-fees',
  RECEIPT_FEES_SAVE_LINE: 'receipt-fees-save-line',
  RECEIPT_FEES_SAVE_HEADER: 'receipt-fees-save-header',
  RECEIPT_FEES_UPLOAD: 'receipt-fees-upload',
  RECEIPT_FEES_PROCESS_BULK: 'receipt-fees-process-bulk',
  RECEIPT_FEES_PROCESS: 'receipt-fees-process',
  INVOICE_CPE_CHECK: 'cpe-check'

  // INVOICE_ITEMS_LIST: 'list-item',
  // INVOICE_ITEMS_DETAIL: 'detail-item',
  // INVOICE_ITEMS_SAVE_HEADER: 'item-save-header',
  // INVOICE_ITEMS_SAVE_LINE: 'item-save-line',
  // INVOICE_SERVICE_LIST: 'list-service',
  // INVOICE_SERVICE_DETAIL: 'detail-service',
  // INVOICE_SERVICE_SAVE_LINE: 'service-save-line',
  // INVOICE_SERVICE_SAVE_HEADER: 'service-save-header',
  // INVOICE_SERVICE_UPLOAD: 'service-upload',
  // INVOICE_SERVICE_PROCESS_BULK: 'process-bulk',
  // RECEIPT_FEES_LIST: 'list-receipt-fees',
  // RECEIPT_FEES_DETAIL: 'detail-receipt-fees',
  // RECEIPT_FEES_SAVE_LINE: 'receipt-fees-save-line',
  // RECEIPT_FEES_SAVE_HEADER: 'receipt-fees-save-header',
  // RECEIPT_FEES_UPLOAD: 'receipt-fees-upload',
  // RECEIPT_FEES_PROCESS_BULK: 'receipt-fees-process-bulk',
  // INVOICE_CPE_CHECK: 'cpe-check'
};

const ncActions = {
  NC_SERVICE_LIST: 'list-service',
  NC_ITEMS_LIST: 'list-item',
  NC_SERVICE_DETAIL: 'detail-service',
  NC_ITEMS_DETAIL: 'detail-item',
  NC_ITEMS_SAVE_HEADER: 'item-save-header',
  NC_ITEMS_SAVE_LINE: 'item-save-line',
  NC_SERVICE_SAVE_HEADER: 'service-save-header',
  NC_SERVICE_SAVE_LINE: 'service-save-line',
  NC_SERVICE_UPLOAD: 'service-upload',
  NC_SERVICE_STATIC: 'service-static',
  NC_PROCESS_BULK: 'process-bulk',
  NC_PROCESS: 'process',
  NC_CPE_CHECK: 'cpe-check'
};

const documnetStatus = {
  Borrador: 'D',
  Validado: 'V',
  VerificadoAlmacen: 'N',
  Recibido: 'R',
  AprobadoContable: 'Y',
  Facturado: 'F',
  Anulado: 'A',
  Confirmado: 'X',
  Paraanular: 'Z',
  Devuelto: 'T'
};

const mediaType = { XML_TEXT: 'application/xml', XML: 'application/xml', PDF: 'application/pdf' };

const documentTypeConstants = { Service: 'S', Item: 'I' };

const noteAccount = [
  { id: 6915102, name: 'Tercero mercadería bonificable propia' },
  { id: 7599050, name: 'Otros ingresos de gestión ' },
  { id: 7599080, name: 'Descuento obtenidos objetivos de meta rebates' }
];
const reciptFeesAccounts = [
  { id: 3324113, name: 'LOCAL LA INGRATA - ICA' },
  { id: 3731013, name: 'GASTOS DIFERIDOS' },
  { id: 6311100, name: 'DE CARGA DE MERCADERIA' },
  { id: 6311300, name: 'TRANSPORTE DE TERCEROS' },
  { id: 6321010, name: 'ASESOR. EMPRESARIAL  GESTION - CONSULTORIAS' },
  { id: 6329010, name: 'ASESORAMIENTO TECNICO' },
  { id: 6329040, name: 'ACTIVIDADES ADMINISTRATIVAS' },
  { id: 6343010, name: 'MANTENIMIENTO LOCAL' },
  { id: 6343020, name: 'MANTENIMIENTO DE  MUEBLES Y ENSERES' },
  { id: 6343040, name: 'MANTENIMIENTO DE EQUIPOS DIVERSOS' },
  { id: 6343050, name: 'MANTENIMIENTO UNIDADES MOVILES  OPERATIVAS' },
  { id: 6343090, name: 'MANTENIMIENTO ACTIVOS- CONGELADORAS' },
  { id: 6343110, name: 'MANTENIMIENTO MAQUINARIA Y EQUIPOS DE EXPLOTACION' },
  { id: 6371050, name: 'ESTUDIO DE MERCADO' },
  { id: 6371060, name: 'CAPACITACION DE MARKETING' },
  { id: 6371070, name: 'ESTRATEGIA PARA PROMOCION DE NUEVO PRODUCTO' },
  { id: 6393091, name: 'SERV. DIVERSOS REALIZADOS EN ALMACEN' },
  { id: 6566606, name: 'EQUIPOS DE PROTECCION PERSONAL' }
];

const documentViews = {
  VIEW_DRAFT: 'D',
  VIEW_VALIDATED: 'V',
  VIEW_PROCESING: 'X',
  VIEW_POSTED: 'F',
  VIEW_ALL: 'all',
  VIEW_CANCELED: 'E'
};

export {
  paymentList,
  warehouseList,
  documnetTypeList,
  documnetStatus,
  noteAccount,
  documentTypeConstants,
  mediaType,
  reciptFeesAccounts,
  purchaseOptions,
  purchaseView,
  invoiceActions,
  ncActions,
  documentViews,
  measureUnits
};

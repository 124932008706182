import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { AllowedGuard } from '../../../hooks/use-allowed';

export const CustomerModal = ({
  open,
  onClose,
  permission,
  children,
  widhtForm = 'xl',
  isFullScreen = false
}) => {
  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      {permission === 'all' ? (
        <Dialog open={open} onClose={handleClose}>
          {children}
        </Dialog>
      ) : (
        <AllowedGuard permission={permission}>
          <Dialog
            PaperProps={{
              sx: {
                width: '100%'
              }
            }}
            fullScreen={isFullScreen}
            maxWidth={widhtForm}
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={false}
          >
            {children}
          </Dialog>
        </AllowedGuard>
      )}
    </>
  );
};

CustomerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  permission: PropTypes.string,
  children: PropTypes.node,
  widhtForm: PropTypes.string,
  isFullScreen: PropTypes.bool
};

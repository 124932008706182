import PropTypes from 'prop-types';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../icons/dots-vertical';

export const SurveyItemMenu = (props) => {
  const { clickEdit, clickDelete, ...others } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  //   console.log(items);

  const handleEdit = () => {
    clickEdit();
    handleClose();
  };

  const handleDelete = () => {
    clickDelete();
    handleClose();
  };

  return (
    <>
      <Box onClick={handleOpen} ref={anchorRef} {...others}>
        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleEdit}>Editar</MenuItem>
        <MenuItem onClick={handleDelete}>Eliminar</MenuItem>
      </Menu>
    </>
  );
};

SurveyItemMenu.propTypes = {
  items: PropTypes.object,
  clickEdit: PropTypes.func,
  clickDelete: PropTypes.func
};

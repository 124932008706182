import PropTypes from 'prop-types';
import { Button, DialogActions, Grid, Typography } from '@mui/material';
import { AllowedGuard } from '../../hooks/use-allowed';
import { Plus as PlusIcon } from '../../icons/plus';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { Pencil as PencilIcon } from '../../icons/pencil';
import GadminActions from '../../contexts/gadmin-actions';

export const MaintenceDetail = ({
  setEditMode,
  item,
  setSelectAction,
  handleAddNodeMajor,
  companyExist = true,
  emailExist = false,
  btnEdit = true,
  company = []
}) => {
  const handleAddButton = () => {
    setEditMode(true);
    setSelectAction('create-node');
    handleAddNodeMajor();
  };

  const getNameById = (id) => {
    const nameTableCompany = company.find((item) => item.id === id);
    return nameTableCompany ? nameTableCompany.short_name : id;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PropertyList>
          <PropertyListItem align="end" label="Codigo">
            <Typography color="textSecondary" variant="body2">
              {item === null ? '---' : item.code}
            </Typography>
          </PropertyListItem>
          {companyExist && (
            <PropertyListItem align="end" label="Empresa">
              <Typography color="textSecondary" variant="body2">
                {item === null ? '---' : getNameById(parseFloat(item.company_id))}
              </Typography>
            </PropertyListItem>
          )}
          {emailExist && (
            <PropertyListItem align="end" label="Email">
              <Typography color="textSecondary" variant="body2">
                {item === null ? '---' : item.email}
              </Typography>
            </PropertyListItem>
          )}
          <PropertyListItem align="end" label="Nombre">
            <Typography color="textSecondary" variant="body2">
              {item === null ? '---' : item.name}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            {btnEdit && (
              <Button
                color="primary"
                size="large"
                startIcon={<PencilIcon />}
                onClick={() => {
                  setEditMode(true);
                }}
                variant="contained"
                sx={{ marginRight: 2 }}
              >
                Editar
              </Button>
            )}
            <Button
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={handleAddButton}
              variant="contained"
            >
              Agregar
            </Button>
          </AllowedGuard>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

MaintenceDetail.propTypes = {
  item: PropTypes.object,
  setEditMode: PropTypes.func,
  setSelectAction: PropTypes.func,
  handleAddNodeMajor: PropTypes.func,
  companyExist: PropTypes.bool,
  emailExist: PropTypes.bool,
  btnEdit: PropTypes.bool,
  company: PropTypes.array
};

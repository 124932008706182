import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold'
  },
  description: {
    width: '85%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8
  },
  total: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8
  }
});

const JournalTableFooter = ({ lines }) => {
  const debit = lines
    .map((item) => item.debit)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const credit = lines
    .map((item) => item.debit)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.description}>Débito</Text>
        <Text style={styles.total}>{Number.parseFloat(debit).toFixed(2)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Crédito</Text>
        <Text style={styles.total}>{Number.parseFloat(credit).toFixed(2)}</Text>
      </View>
    </>
  );
};

JournalTableFooter.propTypes = {
  lines: PropTypes.array
};
export default JournalTableFooter;

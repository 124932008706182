import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { usersApi } from '../../api/managment/users';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { ConstantStatus } from '../../api/application-enums';

const UsersContext = createContext();

const useUser = () => {
  return useContext(UsersContext);
};

const UsersProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const isMounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view')?.toUpperCase() || ConstantStatus.ACTIVE;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const fetchUser = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await usersApi.getUser({
        companyId: currentCompany,
        id,
        view
      });
      if (response.success) {
        if (isMounted.current) {
          setUser(response.data);
        }
      } else {
        if (isMounted.current) {
          setError('Error al cargar los datos del usuario.');
        }
      }
    } catch (err) {
      if (isMounted.current) {
        setError('Ocurrió un error inesperado');
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, [id]);

  const value = {
    user,
    setUser,
    fetchUser,
    loading,
    error,
    editMode,
    setEditMode
  };
  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

UsersProvider.propTypes = { children: PropTypes.node.isRequired };

export { UsersProvider, useUser };

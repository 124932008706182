import Proptypes from 'prop-types';
import GoalRecordLink from './goal-record-link';

export const LinkIf = (props) => {
  const { children, condition, ...other } = props;
  if (!condition) return children;
  return <GoalRecordLink {...other}>{children}</GoalRecordLink>;
};

LinkIf.propTypes = {
  children: Proptypes.node.isRequired,
  condition: Proptypes.bool.isRequired
};

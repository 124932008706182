import PropTypes from 'prop-types';
// import { format } from 'date-fns';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import numeral from 'numeral';
import { Box } from '@mui/system';
import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';

export const PromoAssignedTimingTable = (props) => {
  const { promoAssigned } = props;
  if (!promoAssigned.history) return <LoadingContent loadingText="No hay información" />;
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Etapa</TableCell>
              <TableCell>Fecha y Hora</TableCell>
              <TableCell>Milisegundos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promoAssigned.history?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{numeral(item.id).format('0')}</TableCell>
                <TableCell>
                  <Box>{item.memo}</Box>
                </TableCell>
                <TableCell>{moment(item.createDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                <TableCell>{item.elapsed} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

PromoAssignedTimingTable.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};

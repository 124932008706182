import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Scrollbar from '../../Scrollbar';

export const ReceiptFeesTable = (props) => {
  const { invoice } = props;
  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Codigo</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell>Unidad</TableCell>
              <TableCell>Cant x Unidad</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Dscto (%)</TableCell>
              <TableCell>SubTotal</TableCell>
              <TableCell>IGV</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Tipo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice?.lines?.map((item) => (
              <TableRow key={item.lineNum}>
                <TableCell>{numeral(item.lineNum).format('0')}</TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.itemCode}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.frgItem}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.itemName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.unitMsr}</TableCell>
                <TableCell>{numeral(item.numPerMsr).format('0')}</TableCell>
                <TableCell>{numeral(item.quantity).format('0.0')}</TableCell>
                <TableCell>{numeral(item.priceBefDi).format('0.0000')}</TableCell>
                <TableCell>{numeral(item.discPrcnt).format('0.00')}</TableCell>
                <TableCell>
                  {numeral(item.lineTotal).format(`${item.currencySymbol}0,0.00`)}
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {numeral(item.vatSum).format(`${item.currencySymbol}0,0.00`)}
                    </Typography>
                    {/* <Typography color="textSecondary" variant="body2"></Typography> */}
                  </Box>
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  {numeral(item.gTotal).format(`${item.currencySymbol}0,0.00`)}
                </TableCell>
                <TableCell>
                  {item.reasonCode} - {item.reasonName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

ReceiptFeesTable.propTypes = {
  invoice: PropTypes.object.isRequired
};

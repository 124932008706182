import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { invoiceApi } from '../../../api/invoice';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../common/loading-content';
import JournalReceipt from './JournalReceipt';
import { useData } from '../../../hooks/use-data';

export const JournalPrintingDialog = (props) => {
  const { open = false, onClose, onExited, entity, apiHandler, apiParameter, ...other } = props;
  const mounted = useMounted();
  const { objtype, docEntry, transId } = entity || {};
  const [journalState, handleRefresh] = useData({
    sourceApi: apiHandler || invoiceApi.getInvoiceJournal,
    apiParameter: apiParameter || { objtype, docEntry, transId },
    loadingMessage: 'Cargando PDF del asiento',
    mounted,
    defaults: { noAutoLoad: true }
  });
  useEffect(() => {
    if (open && mounted.current) handleRefresh();
  }, [entity]);

  const styles = StyleSheet.create({
    // General Styles
    page: {
      width: '100%',
      height: '600px',
      border: 'none',
      borderRadius: '8px'
    }
  });
  const content = () => {
    if (journalState.isLoading || journalState.error) {
      return <LoadingContent loadingText={journalState.message} error={journalState.error} />;
    }
    const journalPdf = !journalState.data ? '' : <JournalReceipt journal={journalState.data} />;

    return (
      <>
        <PDFViewer style={styles.page} className="app">
          {journalPdf}
        </PDFViewer>
        <PDFDownloadLink
          document={journalPdf}
          fileName={`Asiento-${transId}`}
          style={{ textDecoration: 'none' }}
        >
          <Button color="primary" size="large" variant="contained">
            Descargar
          </Button>
        </PDFDownloadLink>
      </>
    );
  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        minheight: '80vh',
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      {...other}
      maxWidth="sm"
    >
      <DialogTitle>Asiento {transId} </DialogTitle>
      <DialogContent>
        {content()}
        {/* <PDFDownloadLink
            document={<InvoicePDF invoice={invoiceState.data} />}
            fileName="invoice"
            style={{ textDecoration: 'none' }}
          >
            <Button color="primary" startIcon={<DownloadIcon />} size="large" variant="contained">
              Download
            </Button>
          </PDFDownloadLink> */}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

JournalPrintingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  afterSubmit: PropTypes.func,
  onVariantsChange: PropTypes.func,
  entity: PropTypes.object,
  apiHandler: PropTypes.func,
  apiParameter: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';
import { useApp } from '../../hooks/use-app';
import GadminActions from '../../contexts/gadmin-actions';
import { AllowedGuard } from '../../hooks/use-allowed';
import TimmingOrdersStat from '../../components/_dashboard/app/GProcTimmingOrders';
// import { usePusher } from '../../hooks/use-pusher';
// import { wsPresaleLocationsApi } from '../../ws-api/sale/presale-monitoring/presale-locations-api';
import { GoalManagementMenu } from '../../components/gproc/config/gproc-menu';
import { SyncMenu } from '../../components/gproc/sync/sync-menu';

export const GProcDashboard = () => {
  // useEffect(() => {
  //   // const privateRouteChannel = pusher.subscribe('private-routes.6');
  //   // console.log('private.routes', privateRouteChannel);
  //   // privateRouteChannel.bind('routes-created', (data) => {
  //   //   console.log('data private 6666', data);
  //   // });

  //   // const presenseRouteChannel = pusher.subscribe('presence-routes');
  //   // console.log('presence.routes', privateRouteChannel);
  //   // presenseRouteChannel.bind('routes-created', (data) => {
  //   //   console.log('data presense e 6666', data);
  //   // });
  //   const connection = wsPresaleLocationsApi.listenSellersLocations((data) => {
  //     console.log('data presense e 6666', data);
  //   });

  //   return () => {
  //     wsPresaleLocationsApi.disconnect(connection);
  //   };
  // }, []);

  // usePusher({
  //   api: wsPresaleLocationsApi.listenSellersLocations,
  //   callback: (data) => {
  //     console.log('data presense e 6666', data);
  //   }
  // });

  const app = useApp();

  return (
    <>
      <Helmet>
        <title>{app.title('Administración')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Administración
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <GoalManagementMenu />
              <SyncMenu />
            </Box>
          </Box>

          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>

          <AllowedGuard permission={GadminActions.gCC_GPROC_MNGMT}>
            <Grid item xs={12} sm={6} md={3}>
              <TimmingOrdersStat />
            </Grid>
          </AllowedGuard>
        </Container>
      </Box>
    </>
  );
};

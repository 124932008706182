import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { InputField } from '../input-field';
import { useApp } from '../../hooks/use-app';

export const CompanyInputDisabled = ({ currentCompany }) => {
  const { companyState } = useApp();
  const [companyArray, setCompanyArray] = useState([]);

  useEffect(() => {
    if (companyState.success) {
      setCompanyArray(companyState.data);
    }
  }, [companyState]);

  return (
    <InputField
      select
      label="Empresa"
      name="company_id"
      value={companyArray.length === 0 ? '' : currentCompany}
      fullWidth
      sx={{ marginBottom: 2 }}
      disabled
    >
      {companyArray.length === 0 ? (
        <MenuItem value="">No hay datos</MenuItem>
      ) : (
        companyArray.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))
      )}
    </InputField>
  );
};

CompanyInputDisabled.propTypes = {
  currentCompany: Proptypes.number
};

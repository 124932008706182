import PropTypes from 'prop-types';
import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import numeral from 'numeral';
import { Link as RouterLink } from 'react-router-dom';
import { capitalizeParagraph } from '../../../hooks/use-split';
import { useApp } from '../../../hooks/use-app';

export const InfoMapComponent = (props) => {
  const { selectedLocation } = props;
  const { appUrlBase } = useApp();

  return (
    <Box sx={{ maxWidth: '300px' }}>
      <Link
        color="inherit"
        component={RouterLink}
        underline="hover"
        variant="subtitle2"
        to={`${appUrlBase}/busines-partners/customers/${selectedLocation.CardCode}`}
      >
        {selectedLocation.code}
      </Link>
      <Typography color="textSecondary" variant="body2">
        {selectedLocation.name || selectedLocation.BoxName}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {selectedLocation.address}
      </Typography>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography color="textSecondary" variant="body2">
            Pedidos: {selectedLocation.ordersCount}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Compra: {numeral(selectedLocation.ordersSum).format(`${'S/'}0,0.00`)}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography color="textSecondary" variant="body2">
            No pedidos:{selectedLocation.noOrdersCount}
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography color="textSecondary" variant="body2">
          Vendedor : {capitalizeParagraph(selectedLocation.sellerName)} (
          {selectedLocation.sellerCode})
        </Typography>
      </Grid>
    </Box>
  );
};

InfoMapComponent.propTypes = {
  selectedLocation: PropTypes.object.isRequired
};

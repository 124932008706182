import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [name] = useState('Basic');
  const [color] = useState('red');
  const [buttonColor] = useState('primary');

  const [buttonColorDanger] = useState('danger');
  const [buttonColorSuccess] = useState('success');
  const [buttonColorWarning] = useState('warning');

  const [mode, setMode] = useState('ligth');
  const [backgroundImage, setbackgroundImage] = useState(null);

  const mobileWidth = useMediaQuery('(max-width:600px)');
  const mobileAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  const isMobile = mobileAgent || mobileWidth;

  return (
    <ThemeContext.Provider
      value={{
        name,
        color,
        mode,
        backgroundImage,
        buttonColor,
        buttonColorDanger,
        buttonColorSuccess,
        buttonColorWarning,
        setMode,
        setbackgroundImage,
        isMobile
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
ThemeProvider.propTypes = { children: PropTypes.node.isRequired };

const WithTheme = (Child) => (props) =>
  (
    <ThemeContext.Consumer>
      {(context, i) => <Child key={i} {...props} themeContext={context} />}
    </ThemeContext.Consumer>
  );

export { ThemeProvider, WithTheme, ThemeContext };

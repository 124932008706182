import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalUbigeos {
  async getUbigeos(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/location/ubigeos?companyId=${companyId}`
    });
    return response;
  }
}

export const goalUbigeosApi = new GoalUbigeos();

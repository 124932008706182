import PropTypes from 'prop-types';
import { Card, CardHeader, Divider } from '@mui/material';
import { PromoAssignedHeadersTable } from './assigned-headers-table';

export const PromoAssignedHeaders = (props) => {
  const { promoAssigned } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Promociones evaluadas" />
      <Divider />
      <PromoAssignedHeadersTable promoAssigned={promoAssigned} />
    </Card>
  );
};

PromoAssignedHeaders.propTypes = {
  promoAssigned: PropTypes.object.isRequired
};

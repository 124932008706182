import PropTypes from 'prop-types';

function ColorByRange(props) {
  const { number, range } = props;
  let color = '';

  range.forEach((rango) => {
    if (number >= rango.min && number <= rango.max) {
      color = rango.color;
    }
  });

  return (
    <span style={{ backgroundColor: color, color: '#fff', padding: '3px 15px' }}>{number}</span>
  );
}

export default ColorByRange;

ColorByRange.propTypes = {
  number: PropTypes.number.isRequired,
  range: PropTypes.array.isRequired
};

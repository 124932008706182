const wsPresaleLocationsApi = {
  listenSellersLocations: () => ({ channel: 'presence-routes', event: 'routes-created' })
};

const monitoringPresaleApi = {
  listenSellersLocations: (parameters) => ({
    channel: 'presence-presale-monitoring.' + parameters.team,
    event: 'publish-sellers-location'
  })
};

export { wsPresaleLocationsApi, monitoringPresaleApi };

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Modal, Box, Card } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useApp } from '../../hooks/use-app';

const GoalImageGallery = ({ images, sizeImage = '80px' }) => {
  const { getImageUrl, noImageUrl } = useApp();
  const [openImage, setOpenImage] = useState(null);

  const handleOpenImage = (imageUrl) => {
    setOpenImage(imageUrl);
  };

  const handleCloseImage = () => {
    setOpenImage(null);
  };

  const imageGrid = (
    <Grid container spacing={2}>
      <Card sx={{ maxWidth: sizeImage, maxHeight: sizeImage, objectFit: 'contain' }}>
        {images.map((image) => (
          <img
            key={image.image_code}
            src={getImageUrl({ imageCode: image.image_code, thumbMail: true })}
            alt={image.title || 'Image'}
            onClick={() => handleOpenImage(getImageUrl({ imageCode: image.image_code }))}
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop in case the fallback image also fails
              e.target.src = noImageUrl; // URL to your dummy or fallback image
            }}
          />
        ))}
      </Card>
    </Grid>
  );

  return (
    <>
      {imageGrid}
      <Modal open={openImage !== null} onClose={handleCloseImage}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4
          }}
        >
          <IconButton onClick={handleCloseImage} sx={{ position: 'absolute', top: 30, right: 30 }}>
            <CloseIcon />
          </IconButton>
          <img
            src={openImage}
            alt="Full Image"
            style={{ width: '100%' }}
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop in case the fallback image also fails
              e.target.src = noImageUrl; // URL to your dummy or fallback image
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
GoalImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      image_code: PropTypes.string.isRequired,
      title: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      id: PropTypes.number
    })
  ).isRequired,
  sizeImage: PropTypes.string
};

export default GoalImageGallery;
